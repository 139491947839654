import { Component, Input, OnInit, EventEmitter, Output, SimpleChanges, OnChanges, ChangeDetectorRef, TemplateRef, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { AnalogFunctions, EventTypesInput, FormMode, LocationEventType } from 'app/common/enums';
import { Device, DeviceCalibrationPoints, DeviceSettings } from 'app/models/device.model';
import { outputOptionsCount, auxilaryOptionCount, checkAnalogPortForValidSensor, canOptionCount } from 'app/common/globals';
import { TranslateService } from '@ngx-translate/core';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { DeviceType } from 'app/models/devicetype.model';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { DeviceService } from 'app/services/device/device.service';
import { CalibrationComponent } from './calibrationDetails.component';

@Component({
    selector: 'fh-devicesetting-details',
    templateUrl: 'deviceSettingsDetails.template.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DeviceSettingsDetailsComponent implements OnInit, OnChanges {
    @ViewChild('addModal') addModal: ModalDirective;
    @ViewChild('calibrationComponent') calibrationComponent: CalibrationComponent;
    @Input() device: Device = new Device();
    @Input() settings: DeviceSettings = new DeviceSettings();
    @Input() loading: boolean;
    @Input() loadingDeviceTypes: boolean;
    @Input() formMode: any = FormMode.read;
    @Output() form: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

    config = {
        backdrop: true,
        ignoreBackdropClick: true
    };

    sensor = {
        calibrationPoints: []
    };

    formModeCalibration = FormMode.edit;
    modalRef: BsModalRef;

    inputOptions = [];
    analogOptions = [];

    deviceOutputOptions: string[] = [];
    auxOptions: any[] = [];
    canOptions: any[] = [];

    deviceTypes: DeviceType[] = [];

    idlingOptions: string[] = [];

    calibrationOdo = 0;

    formGroup: FormGroup;

    editSensor: any;
    success: string;
    allowEdit: boolean;
    sensorValues: any;
    sensorNumber: number;

    loadingModal = false;
    sensorSuccess = null;
    sensorError = null;

    duplicateInputs: { [id: number]: number } = {};

    public updatedParams = [];

    constructor(private translateService: TranslateService, private deviceTypeService: DeviceTypeService, private cd: ChangeDetectorRef, private deviceService: DeviceService) {
        this.formGroup = new FormGroup({
            id: new FormControl(''),
            updatedParams: new FormArray([]),
            input1: new FormControl(''),
            input2: new FormControl(''),
            input3: new FormControl(''),
            input4: new FormControl(''),
            input5: new FormControl(''),
            input6: new FormControl(''),
            invertInput1: new FormControl(false),
            invertInput2: new FormControl(false),
            invertInput3: new FormControl(false),
            invertInput4: new FormControl(false),
            invertInput5: new FormControl(false),
            invertInput6: new FormControl(false),
            a1: new FormControl(''),
            a2: new FormControl(''),
            a3: new FormControl(''),
            a4: new FormControl(''),
            externalPower: new FormControl(''),
            ignition: new FormControl(''),
            deviceTypeField: new FormControl(false),
            deviceTypeId: new FormControl(),
            isStickyDriver: new FormControl(false),
            output1: new FormControl(''),
            output2: new FormControl(''),
            output3: new FormControl(''),
            output4: new FormControl(''),
            auxiliaryAttachements: new FormControl(''),
            canBusParameters: new FormControl(''),
            permittedIdlingInMinutes: new FormControl(''),
            idlingCalculationMethod: new FormControl(''),
            idlingRpmDuration: new FormControl(''),
            idlingRpmIsActive: new FormControl(''),
            idlingRpmValue: new FormControl(''),
            idlingInputDuration: new FormControl(''),
            idlingInputIsActive: new FormControl(''),
            idlingInputValue: new FormControl(''),
            idlingVoltageDuration: new FormControl(''),
            idlingVoltageIsActive: new FormControl(''),
            idlingVoltageValue: new FormControl(''),
            sensorValues: new FormControl(''),
            homeIntervalInSeconds: new FormControl(''),
            roamingIntervalInSeconds: new FormControl(''),
            notCommunicatingThresholdInMinutes: new FormControl(''),
            reportExternalPowerLostImmediately: new FormControl(false),
            // checkbox: new FormControl('', Validators.required),
        });

        // Set update params
        this.updatedParams['input1'] = { name: 'input1', value: 'input1' };
        this.updatedParams['input2'] = { name: 'input2', value: 'input2' };
        this.updatedParams['input3'] = { name: 'input3', value: 'input3' };
        this.updatedParams['input4'] = { name: 'input4', value: 'input4' };
        this.updatedParams['input5'] = { name: 'input5', value: 'input5' };
        this.updatedParams['input6'] = { name: 'input6', value: 'input6' };

        this.updatedParams['a1'] = { name: 'a1', value: 'a1' };
        this.updatedParams['a2'] = { name: 'a2', value: 'a2' };
        this.updatedParams['a3'] = { name: 'a3', value: 'a3' };
        this.updatedParams['a4'] = { name: 'a4', value: 'a4' };        

        this.updatedParams['output1'] = { name: 'output1', value: 'output1' };
        this.updatedParams['output2'] = { name: 'output2', value: 'output2' };
        this.updatedParams['output3'] = { name: 'output3', value: 'output3' };
        this.updatedParams['output4'] = { name: 'output4', value: 'output4' };

        this.updatedParams['externalPower'] = { name: 'externalPower', value: 'externalPower' };
        this.updatedParams['ignition'] = { name: 'ignition', value: 'ignition' };

        this.updatedParams['deviceTypeId'] = { name: 'deviceTypeId', value: 'deviceTypeId' };
        this.updatedParams['isStickyDriver'] = { name: 'isStickyDriver', value: 'isStickyDriver' };
        
        this.updatedParams['auxiliaryAttachements'] = { name: 'auxiliaryAttachements', value: 'auxiliaryAttachements' };
        this.updatedParams['canBusParameters'] = { name: 'canBusParameters', value: 'canBusParameters' };
        
        this.updatedParams['permittedIdlingInMinutes'] = { name: 'permittedIdlingInMinutes', value: 'permittedIdlingInMinutes' };
        this.updatedParams['idlingCalculationMethod'] = { name: 'idlingCalculationMethod', value: 'idlingCalculationMethod' };
        this.updatedParams['idlingRpmIsActive'] = { name: 'idlingRpmIsActive', value: 'idlingRpmIsActive' };
        this.updatedParams['idlingVoltageIsActive'] = { name: 'idlingVoltageIsActive', value: 'idlingVoltageIsActive' };
        this.updatedParams['idlingInputIsActive'] = { name: 'idlingInputIsActive', value: 'idlingInputIsActive' };

        this.updatedParams['homeIntervalInSeconds'] = { name: 'homeIntervalInSeconds', value: 'homeIntervalInSeconds' };
        this.updatedParams['roamingIntervalInSeconds'] = { name: 'roamingIntervalInSeconds', value: 'roamingIntervalInSeconds' };
        this.updatedParams['notCommunicatingThresholdInMinutes'] = { name: 'notCommunicatingThresholdInMinutes', value: 'notCommunicatingThresholdInMinutes' };
        this.updatedParams['reportExternalPowerLostImmediately'] = { name: 'reportExternalPowerLostImmediately', value: 'reportExternalPowerLostImmediately' };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.settings != null || changes.device != null) {
            this.setForm();

            this.setSensorValues();
        }

        this.cd.markForCheck();
    }

    checkAnalogPort(port) {

        const enabled = checkAnalogPortForValidSensor(port);
        this.cd.markForCheck();

        return enabled;
    }    

    setSensorValues() {
        if (this.device.id) {
            this.deviceService.getCalibrationPoints(this.device.id).subscribe(sensorValuesResult => {
                this.device.settings.sensorCalibrationPoints = sensorValuesResult;
                this.cd.markForCheck();
            });
        }
    }

    ngOnInit(): void {
        this.translateService.get('general.date').subscribe(data => {
            this.populateSelectOptions();
        });

        this.setForm();
    }


    onCheckChange(event) {
        const formArray: FormArray = this.formGroup.get('updatedParams') as FormArray;

        /* Selected */
        if (event.target.checked) {
            // Add a new control in the arrayForm
            formArray.push(new FormControl(event.target.value));
        }
        /* unselected */
        else {
            // find the unselected element
            let i: number = 0;

            formArray.controls.forEach((ctrl: FormControl) => {
                if (ctrl.value == event.target.value) {
                    // Remove the unselected element from the arrayForm
                    formArray.removeAt(i);
                    return;
                }

                i++;
            });
        }
    }

    setForm() {
        if (this.settings.inputPorts.length > 0) {

            this.formGroup = new FormGroup({
                id: new FormControl(this.device.id),
                updatedParams: new FormArray(this.settings.updatedParams),
                input1: new FormControl(this.settings.inputPorts[0].byte),
                input2: new FormControl(this.settings.inputPorts[1].byte),
                input3: new FormControl(this.settings.inputPorts[2].byte),
                input4: new FormControl(this.settings.inputPorts[3].byte),
                input5: new FormControl(this.settings.inputPorts[4].byte),
                input6: new FormControl(this.settings.inputPorts[5].byte),
                invertInput1: new FormControl(this.settings.inputPorts[0].inverted),
                invertInput2: new FormControl(this.settings.inputPorts[1].inverted),
                invertInput3: new FormControl(this.settings.inputPorts[2].inverted),
                invertInput4: new FormControl(this.settings.inputPorts[3].inverted),
                invertInput5: new FormControl(this.settings.inputPorts[4].inverted),
                invertInput6: new FormControl(this.settings.inputPorts[5].inverted),
                a1: new FormControl(this.settings.analogPorts[0].byte),
                a2: new FormControl(this.settings.analogPorts[1].byte),
                a3: new FormControl(this.settings.analogPorts[2].byte),
                a4: new FormControl(this.settings.analogPorts[3].byte),
                externalPower: new FormControl(this.settings.externalPower),
                ignition: new FormControl(this.settings.ignition),
                deviceTypeField: new FormControl(false),
                deviceTypeId: new FormControl(this.device.deviceTypeId),
                isStickyDriver: new FormControl(this.settings.isStickyDriver),
                output1: new FormControl(this.settings.outputPorts[0].byte),
                output2: new FormControl(this.settings.outputPorts[1].byte),
                output3: new FormControl(this.settings.outputPorts[2].byte),
                output4: new FormControl(this.settings.outputPorts[3].byte),
                auxiliaryAttachements: new FormControl(this.settings.deviceAuxiliary.map(x => x.id.toString())),
                canBusParameters: new FormControl(this.settings.canBusParameters.map(x => x.id.toString())),
                permittedIdlingInMinutes: new FormControl(this.settings.permittedIdlingInMinutes, Validators.required),
                idlingCalculationMethod: new FormControl(this.settings.idlingCalculationMethod),
                idlingRpmDuration: new FormControl(this.settings.idlingRpmDuration),
                idlingRpmIsActive: new FormControl(this.settings.idlingRpmIsActive),
                idlingRpmValue: new FormControl(this.settings.idlingRpmValue),
                idlingInputDuration: new FormControl(this.settings.idlingInputDuration),
                idlingInputIsActive: new FormControl(this.settings.idlingInputIsActive),
                idlingInputValue: new FormControl(this.settings.idlingInputValue),
                idlingVoltageDuration: new FormControl(this.settings.idlingVoltageDuration),
                idlingVoltageIsActive: new FormControl(this.settings.idlingVoltageIsActive),
                idlingVoltageValue: new FormControl(this.settings.idlingVoltageValue),
                sensorCalibrationPoints: new FormControl(this.settings.sensorCalibrationPoints),
                homeIntervalInSeconds: new FormControl(this.settings.homeIntervalInSeconds),
                roamingIntervalInSeconds: new FormControl(this.settings.roamingIntervalInSeconds),
                notCommunicatingThresholdInMinutes: new FormControl(this.settings.notCommunicatingThresholdInMinutes),
                reportExternalPowerLostImmediately: new FormControl(this.settings.reportExternalPowerLostImmediately),
                // checkbox: new FormControl('', Validators.required),
            });

            this.formGroup.valueChanges.subscribe(form => {
                this.duplicateInputs = {};

                const nonDuplicateList = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6', 'a1', 'a2', 'a3', 'a4', 'externalPower', 'ignition'];

                for (const key of nonDuplicateList) {
                    // Only check for 'Ignition On' and 'External Power On'
                    if (!(+form[key] === LocationEventType['Ignition On'] || +form[key] === LocationEventType['External Power On'])) {
                        continue;
                    }

                    this.duplicateInputs[form[key]] = (this.duplicateInputs[form[key]] ?? -1) + 1;
                    delete this.duplicateInputs[0];
                }

                this.device.invalidConfiguration = !(Object.values(this.duplicateInputs).every(x => x === 0));
            });

            this.form.emit(this.formGroup);
        }
    }

    fireFilter(event) {
    };

    getDeviceTypes() {
        this.loadingDeviceTypes = true;
        this.deviceTypeService.getDeviceTypes(false).subscribe((deviceTypes) => {
            this.loadingDeviceTypes = false;

            this.deviceTypes = deviceTypes.sort((a, b) => (a.modelName.localeCompare(b.modelName))).filter(d => d.modelName !== '');

            this.deviceTypes.forEach(deviceType => {
                deviceType.id = +deviceType.id;
            });

            this.cd.markForCheck();
        });

    };

    populateSelectOptions(): void {
        this.inputOptions.push({ id: '0', value: this.translateService.instant(('enums.locationEventType.0')), rank: 0 });

        Object.keys(EventTypesInput).forEach((key) => {
            const ioValue = BigInt(EventTypesInput[key][0]);

            // Check if number exceeds c# max int value
            if (ioValue > 9223372036854775807) {
                console.log('skipping due to exceeding', EventTypesInput[key][0]);
                return;
            }

            this.inputOptions.push({ id: ioValue.toString(), value: this.translateService.instant(('enums.locationEventType.' + ioValue)), rank: 1 });
        });

        this.analogOptions = Object.keys(AnalogFunctions)
            .filter(k => typeof AnalogFunctions[k] === 'string')
            .map(n => ({ id: n, value: this.translateService.instant(('enums.analogFunctions.' + n)), rank: Number(n !== '0') }));        

        for (let i = 0; i < outputOptionsCount; i++) {
            this.deviceOutputOptions.push(i + '');
        }

        const auxilaryStrings = [];

        for (let i = 0; i < auxilaryOptionCount; i++) {
            auxilaryStrings.push('enums.deviceAuxiliary.' + (2 ** i));
        }

        this.translateService.get(auxilaryStrings).subscribe(translations => {
            for (let i = 0; i < auxilaryOptionCount; i++) {
                this.auxOptions.push({
                    'id': (2 ** i).toString(),
                    'itemName': translations['enums.deviceAuxiliary.' + (2 ** i)]
                });
            }
        });

        const canStrings = [];

        for (let i = 0; i < canOptionCount; i++) {
            canStrings.push('enums.canBusParameters.' + (2 ** i));
        }

        this.translateService.get(canStrings).subscribe(translations => {
            for (let i = 0; i < canOptionCount; i++) {
                this.canOptions.push({
                    'id': (2 ** i).toString(),
                    'itemName': translations['enums.canBusParameters.' + (2 ** i)]
                });
            }
        });

        this.idlingOptions.push('0');
        this.idlingOptions.push('1');
        this.idlingOptions.push('2');

        //#region Tracker Types

        this.getDeviceTypes();
        //#endregion
    }

    // Adding
    showModal(analogPort) {
        this.sensorNumber = analogPort;

        this.addModal.show();
    }

    hideModal() {
        this.calibrationComponent.form.reset();
        this.addModal.hide();
    }
}
