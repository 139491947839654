import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';

import { Observable, forkJoin, of } from 'rxjs';
import { AuthenticationService } from '../../services/authentication/authentication.service';

// Moment timezone
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';

import { contains, getIconPath } from 'app/common/globals';
import { DriverService } from 'app/services/driver/driver.service';
import { Driver } from 'app/models/driver.model';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { getDefaultDpConfig } from 'app/common/gridhelper';
import { GanttEvent } from 'app/models/ganttEvent.model';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-drivers-dayview',
  templateUrl: 'dayview.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverDayViewViewComponent implements OnInit {
  token: string;
  countrySelect = false;

  excludingColumns = ['timeStamp', 'assetDateStart', 'assetDateEnd', 'lastCommunication', 'input1', 'input2', 'input3', 'input4', 'input5', 'input6', 'output1', 'output2'];
  loading = false;

  permissionName = 'Drivers_View';
  constructorName = 'DriverScoreViewComponent';

  selectedResellerId;
  selectedAccountId;
  selectedDriverGroups;

  error: any;
  success: any;
  warning: any;

  timezoneIana: string;
  languageLoaded: boolean;

  assignments: Driver[] = [];
  driverAssignments = [];

  // Pagination
  itemsPerPage = 20;
  currentPage = 1;
  stringFilter = '';
  totalItems: any[];

  orderBy = 'created';
  orderByReversed = false;
  status;

  // Datepicker
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  to: any;
  from: any;
  daterangepickerModel: any[];

  deferSearch$: Observable<void>;
  mySubscription: any;

  constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private driverService: DriverService, private deviceTypeService: DeviceTypeService) {

    const that = this;

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana()

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, 'weeks').startOf('day').toDate(),
      Moment().tz(this.timezoneIana).endOf('day').toDate()
    ];

    this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

    // Get all the date for dropdown boxes
    forkJoin([
      this.translateService.get('general.date'),
      this.deviceTypeService.getDeviceTypes()]
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.cd.markForCheck();

      },
      err => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  resellerChanged(event) {
    this.assignments = [];
    this.driverAssignments = [];
    this.selectedDriverGroups = [];

    this.selectedAccountId = null;
  }

  accountChanged(event) {
    this.assignments = [];
    this.driverAssignments = [];
    this.selectedDriverGroups = [];

    // this.getData();
  }

  ngOnInit() {
    this.deferSearch$ = new Observable(observer => {
      of(this.stringFilter).pipe(debounceTime(500), distinctUntilChanged()).subscribe((_) => {
        this.renderData();
        observer.next();
      });
    });
  }

  getIcon(iconId) {
    return getIconPath(iconId)[2];
  }

  filterDrivers() {
    this.driverAssignments = this.assignments;
    if (this.status) {
      // this.drivers = this.drivers.filter(x => x.deviceState === this.status);
    }

    // Filter groups
    if (this.selectedDriverGroups && this.selectedDriverGroups.length > 0) {
      this.driverAssignments = this.driverAssignments.filter(x => x.driverGroups.some(ag => this.selectedDriverGroups.includes(+ag.id)));
    }

    this.renderData();

    this.cd.markForCheck();
  }

  dateChanged(event) {
    const that = this;
    console.log('Changed date');
    if (event !== null) {
      this.getData();

    }
  }

  cancel() {
    this.mySubscription.unsubscribe();

    this.loading = false;

    this.warning = {};
    this.warning.warning = 'Call was cancelled.'
    this.warning.statusText = 'Warning';
  }

  getData() {
    this.loading = true;

    this.assignments = [];
    this.driverAssignments = [];

    if (this.selectedAccountId == null) {
      this.loading = false;
      return;
    }

    this.mySubscription = this.driverService.getDriverAssignmentsByAccountId(this.selectedAccountId, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(result => {
      this.assignments = result;
      this.error = null;

      this.filterDrivers();
      this.loading = false;
      this.cd.markForCheck();
    },
      err => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  renderData() {
    this.cd.markForCheck();

    const driverNames = [];
    const assetIconDictionary = {};
    const events = [] as GanttEvent[];
    const drivers = [];

    for (const driver of this.driverAssignments) {
      if (driver.assetName?.toLowerCase().indexOf(this.stringFilter?.toLowerCase()) === -1) {
        continue;
      }

      driverNames[driver.deviceId] = driver.assetName ?? driver.deviceId;
      assetIconDictionary[driver.deviceId] = driver.iconId;

      const start = driver.dateStart.toDate() > this.daterangepickerModel[0] ? driver.dateStart.toDate() : this.daterangepickerModel[0];
      const end = driver.dateEnd?.toDate() ?? new Date();

      events.push({
        name: `${driver.driverName} (${this.translateService.instant('enums.assetDriverSource.' + driver.source)})\u200E\n${driver.dateStart?.format('lll')} - ${driver.dateEnd ? driver.dateEnd?.format('lll') : '-'}`,
        start,
        end,
        location: driver.deviceId,
        stylePlain: 'margin: 8.5px 0; background-color: #555; color: #fff; border: none; box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 30%)',
        userData: { source: driver.source, driverId: driver.driverId },
      });
    }

    for (const property in driverNames) {
      if (!property) { continue };
      drivers.push({ id: +property, name: driverNames[property] });
    }

    this.totalItems = drivers;

    const slidedDrivers = drivers.slice((this.currentPage - 1) * this.itemsPerPage, ((this.currentPage - 1) * this.itemsPerPage) + this.itemsPerPage);
    const slicedEvents = events.filter(x => contains(slidedDrivers.map(y => y.id), x.location))

    $('#skedGantt')['skedTape']({
      caption: 'Assets',
      start: this.daterangepickerModel[0],
      end: this.daterangepickerModel[1],
      // showEventTime: true,
      // showEventDuration: true,
      scrollWithYWheel: true,
      locations: slidedDrivers,
      events: slicedEvents,
      // maxTimeGapHi: 60 * 1000, // 1 minute
      // minGapTimeBetween: 1 * 60 * 1000,
      snapToMins: 1,
      editMode: false,
      timeIndicatorSerifs: true,
      showIntermission: true,
      formatters: {
        date: function (date) {
          return $.fn['skedTape'].format.date(date, 'l', '.');
        },
      },
      canAddIntoLocation: function (location, event) {
        return true;
      },
      postRenderLocation: function ($el, location, canAdd) {
        this.constructor.prototype.postRenderLocation($el, location, canAdd);

        const imageTag = '<img loading="lazy" style="position: relative; margin-top: -10px; margin-bottom: -3px; padding-right:10px" src="' + getIconPath(assetIconDictionary[location.id])[1] + '">';
        $el.prepend(imageTag);

        $el.addClass('secondary link_bolder hand').click(function () {
          document.location.href = '#/DeviceDetails/Index/' + location.id;
        });
      },
      postRenderEvent: function ($el, event) {
        $el.attr('data-container', 'body');

        $el.css('cursor', 'pointer');
        $el.click(function () {
          document.location.href = '#/DriverDetails/Index/' + event.userData.driverId;
        });
      },
    });

    // $('div[data-container=body]')['tooltip']();

    this.cd.markForCheck();
  }
}
