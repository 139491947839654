<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/">{{'general.system' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.health' | translate}}</li>
            </ol>
        </nav>
    </div>
    <fh-system-navigation></fh-system-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">

            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-laptop-medical"></i>{{'general.health' | translate}}
                    </h5>
                    <ng-container>
                        <span class="float-right">
                            <i class="fas fa-fw fa-circle"
                                [style.color]="health?.status == 'Healthy' ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                        </span>
                    </ng-container>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <!-- Button -->
                    <table style="width:100%" class="table nowrap dtr-inline no-footer table-fixed">
                        <tbody>
                            <tr>
                                <td style="width: 120px; text-transform: capitalize;" class="elipses_overflow">
                                    Status
                                </td>
                                <td>
                                    <i class="fas fa-fw fa-circle" [title]="health?.totalDuration"
                                        [style.color]="health?.status == 'Healthy' ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                                    {{ health?.status }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div style=" clear:both "></div>
                </div>
            </div>
        </div>

        <div class="col-lg-12" *ngFor="let entry of health?.entries | keyvalue">
            <div class="ibox">
                <div class="ibox-title hand" (click)="flipEntry(entry.value)">
                    <h5>
                        <i class="fas fa-fw fa-laptop-medical"></i>{{ entry.key }}
                    </h5>
                    <ng-container *ngFor="let entry2 of entry.value | keyvalue">
                        <span class="float-right" *ngIf="entry2.key == 'status'">
                            <i class="fas fa-fw fa-circle"
                                [style.color]="(entry2.value | json).indexOf('Healthy') > 0 ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                        </span>
                    </ng-container>
                </div>
                <div *ngIf="checkEntry(entry.value)" class="ibox-content slick" style="position: relative">
                    <table style="width:100%" class="table nowrap dtr-inline no-footer table-fixed">
                        <tbody>
                            <ng-container *ngFor="let entry2 of entry.value | keyvalue">
                                <tr *ngIf="entry2.key == 'status'">
                                    <td style="width: 120px; text-transform: capitalize;" class="elipses_overflow">
                                        {{ (entry2.key).replace('"','').replace('"','') }}
                                    </td>
                                    <td style="white-space: break-spaces;">
                                        <i class="fas fa-fw fa-circle"
                                            [style.color]="(entry2.value | json).indexOf('Healthy') > 0 ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                                        {{ (entry2.value | json).replace('"','').replace('"','') }}
                                    </td>
                                </tr>

                                <tr *ngIf="entry2.key != 'tags' 
                                    && entry2.key != 'data' 
                                    && entry2.key != 'status' 
                                    && entry2.key != 'isOpen' 
                                    && entry2.key != 'duration'">
                                    <td style="width: 120px; text-transform: capitalize;"
                                        class="row_opening elipses_overflow">
                                        {{ (entry2.key).replace('"','').replace('"','') }}
                                    </td>
                                    <td style="white-space: break-spaces;">
                                        {{ (entry2.value | json).replace('"','').replace('"','') }}
                                    </td>
                                </tr>

                                <ng-container *ngIf="entry2.key == 'tags' && (entry2.value| keyvalue).length > 0">
                                    <tr>
                                        <td style="width: 120px; text-transform: capitalize;"
                                            class="row_opening elipses_overflow">
                                            {{ (entry2.key).replace('"','').replace('"','') }}
                                        </td>
                                        <td style="white-space: break-spaces;">
                                            <span *ngFor=" let entry3 of entry2.value | keyvalue">{{
                                                (entry3.value |
                                                json).replace('"','').replace('"','') }},</span>
                                        </td>
                                    </tr>
                                </ng-container>

                            </ng-container>
                            <ng-container *ngFor="let entry2 of entry.value | keyvalue">
                                <ng-container *ngIf="entry2.key == 'data' && (entry2.value| keyvalue).length > 0">
                                    <!-- <tr>
                                        <td colspan="2">- Data</td>
                                    </tr> -->
                                    <ng-container *ngFor=" let entry3 of entry2.value | keyvalue">
                                        <ng-container *ngFor="let entry4 of entry3.value | keyvalue">
                                            <tr>
                                                <td colspan="2" style="overflow: hidden;" [title]="entry4.key">
                                                    <i class="fas fa-fw fa-circle"
                                                        [style.color]="(entry4.value | json).indexOf('Healthy') > 0 ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                                                    {{ (entry4.key).replace('"','').replace('"','') }}
                                                </td>
                                            </tr>
                                            <!-- <tr>
                                                <td colspan="2" class="elipses_overflow">
                                                    {{ entry4.value | json }}
                                                </td>
                                            </tr> -->
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>