import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { localizeSystemGroupNames } from 'app/common/globals';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable, catchError, map } from 'rxjs';
import { AppUser, UpdatePasswordRequest } from '../../models/user.model';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { NotificationLog } from 'app/models/notificationlog.model';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import * as moment from 'moment-timezone';

window['moment'] = Moment;
mTZ()

@Injectable()
export class UserService {
    url = '';
    baseUrl = '';
    timezoneIana: string;

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService, private translateService: TranslateService) {
        this.url = authenticationService.getWebserviceURL('user');
        this.baseUrl = authenticationService.getWebserviceURL('');
        this.timezoneIana = authenticationService.getTimeZoneIana();
    }

    getPagingUrl() {
        this.url = this.authenticationService.getWebserviceURL('user');
        return this.url + 'Paging';
    }

    getUsers(): Observable<AppUser[]> {
        this.url = this.authenticationService.getWebserviceURL('user');
        console.log('getting users from service : ' + this.url);
        return this.http.get(this.url, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return this.parseResponse(data);
            }), catchError(this.handleError));
    }

    getUsersByAccountId(id: any): Observable<AppUser[]> {
        this.url = this.authenticationService.getWebserviceURL('user');
        console.log('getting users from service : ' + this.url);
        return this.http.get(this.url + 'Account/' + id, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return this.parseResponse(data);
            }), catchError(this.handleError));
    }

    getAssetGroupsbyViewerId(id: any, includeAllClients = false): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        console.log('getting assetGroups from service : ' + this.url);
        return this.http.get(this.url + id + '/AssetGroups?includeAllClients=' + includeAllClients, { headers: this.authenticationService.headers })
            .pipe(map((data: any[]) => {
                const result = [];
                data.forEach(item => {
                    const account = this.parseGroupResponseDetails(item);
                    result.push(account);
                });
                return result;
            }), catchError(this.handleError));
    }

    getDriversGroupsbyViewerId(id: any): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        console.log('getting driverGroups from service : ' + this.url);
        return this.http.get(this.url + id + '/DriverGroups', { headers: this.authenticationService.headers })
            .pipe(map((data: any[]) => {
                const result = [];
                data.forEach(item => {
                    const account = this.parseGroupResponseDetails(item);
                    result.push(account);
                });
                return result;
            }), catchError(this.handleError));
    }

    postAssetGroupsbyUserId({ id, groups }): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        console.log('getting assetGroups from service : ' + this.url);
        return this.http.post(this.url + id + '/AssetGroups', { id, assetGroups: groups }, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return data;
            }), catchError(this.handleError));
    }

    postDriverGroupsbyUserId({ id, groups }): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        console.log('getting driverGroups from service : ' + this.url);
        return this.http.post(this.url + id + '/DriverGroups', { id, driverGroups: groups }, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return data;
            }), catchError(this.handleError));
    }

    postUserAccountViewer(id: any, accountId: any): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        console.log('posting accountViewer to service : ' + this.url);
        return this.http.post(this.url + id + '/UserAccountViewer', { id, accountId }, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return data;
            }), catchError(this.handleError));
    }

    deleteUserAccountViewer(id: any, viewerId: any, accountId: any): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        console.log('posting accountViewer to service : ' + this.url);
        return this.http.delete(this.url + id + '/UserAccountViewer/' + viewerId + '?accountId=' + accountId, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return data;
            }), catchError(this.handleError));
    }

    getViewersbyUserId(id: any): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        console.log('getting viewers from service : ' + this.url);
        return this.http.get(this.url + id + '/Viewers', { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return data;
            }), catchError(this.handleError));
    }

    getViewersbyAccountId(id: any): Observable<any> {
        this.baseUrl = this.authenticationService.getWebserviceURL('');
        console.log('getting viewers from service : ' + this.url);
        return this.http.get(this.baseUrl + 'Account/' + id + '/Viewers', { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return data;
            }), catchError(this.handleError));
    }

    getSlimUsersByAccountId(id: any): Observable<AppUser[]> {
        this.url = this.authenticationService.getWebserviceURL('user');
        console.log('getting slim users from service : ' + this.url);
        return this.http.get(this.url + 'Slim/Account/' + id, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const result = this.parseResponse(data);
                return result.sort((a, b) => (a.fullName < b.fullName ? -1 : 1));
            }), catchError(this.handleError));
    }

    getUserById(id: any): Observable<AppUser> {
        this.url = this.authenticationService.getWebserviceURL('user');
        console.log('getting users from service');
        return this.http.get(this.url + id, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return this.parseResponseData(data);
            }), catchError(this.handleError));
    }

    getActiveUsers(): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        console.log('getting active users from service : ' + this.url);
        return this.http.get(this.url + 'Active', { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return (data);
            }), catchError(this.handleError));
    }

    getTokenForUser(id: any): Observable<any> {
        this.baseUrl = this.authenticationService.getWebserviceURL('');

        console.log('getting users from service');
        return this.http.get(this.baseUrl + 'authentication/impersonateToken/' + id, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return data as any;
            }), catchError(this.handleError));
    }

    getKPISCount(id: string): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        console.log('getting dashboard from service for user');
        return this.http.get(this.url + id + '/dashboard', { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = data;
                return parsedResponse;
            }), catchError(this.handleError));
    }

    getAuthTokens(id: string): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        return this.http.get(this.url + id + `/tokens`, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = data;
                return parsedResponse;
            }), catchError(this.handleError));
    }

    getRefreshTokens(id: string): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        return this.http.get(this.url + id + `/refreshtokens`, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = data;
                return parsedResponse;
            }), catchError(this.handleError));
    }

    deleteAuthToken(id: string, tokenId: string): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        return this.http.delete(this.url + id + `/deletetoken/` + tokenId, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = data;
                return parsedResponse;
            }), catchError(this.handleError));
    }

    createAuthToken(id: string): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        return this.http.get(this.url + id + `/createtoken`, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = data;
                return parsedResponse;
            }), catchError(this.handleError));
    }

    deleteRefreshToken(id: string, tokenId: string): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        return this.http.delete(this.url + id + `/deleteRefreshToken/` + tokenId, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = data;
                return parsedResponse;
            }), catchError(this.handleError));
    }

    createRefreshToken(id: string, name: string): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        return this.http.get(this.url + id + `/createrefreshtoken?name=${name}`, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = data;
                return parsedResponse;
            }), catchError(this.handleError));
    }

    getNotifications(id: string, start, end): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        return this.http.get(this.url + id + '/Notifications?start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .pipe(map((data: any) => {
                const notifications = this.parseNotificationResponse(data);
                return notifications;
            }), catchError(this.handleError));
    }

    resetPassword(id: any): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        return this.http.post(this.url + id + '/Reset', id, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    deleteUser(id: any): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        return this.http.delete(this.url + id, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    updateUserProfile(appUser: AppUser): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        appUser['Email'] = appUser.name; // Suggesting to rename this property
        return this.http.put(this.url + appUser.id, appUser, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    updateUserPassword(id: any, request: UpdatePasswordRequest): Observable<any> {
        const urlExtra = 'Password'
        this.url = this.authenticationService.getWebserviceURL('user');
        return this.http.put(this.url + id + '/Password', request, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    createTemporaryPassword(id: string): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        return this.http.get(this.url + id + `/temporaryPassword`, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = data;
                return parsedResponse;
            }), catchError(this.handleError));
    }

    createNewUserWelcome(id: string): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        return this.http.get(this.url + id + `/newUserWelcome`, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = data;
                return parsedResponse;
            }), catchError(this.handleError));
    }

    saveUser(appUser: AppUser): Observable<any> {
        this.url = this.authenticationService.getWebserviceURL('user');
        return this.http.post(this.url, appUser, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    parseResponse(json: any): AppUser[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Users.');

        const users: AppUser[] = [];

        json.forEach(item => {
            let user = new AppUser();
            user = this.parseResponseData(item);
            users.push(user);
        });

        return users;
    }

    unsubscribeFromEmailReport(userId: string): Observable<any> {
        return this.http.put(this.url + 'Unsubscribe/' + userId, userId, { headers: this.authenticationService.headers })
            .pipe(map((result) => {
                return result;
            })
                , catchError(this.handleError));
    }

    parseGroupResponseDetails(item) {
        item.groupName = localizeSystemGroupNames(item.groupName, this.translateService);
        return item;
    }

    parseResponseData(item: any): AppUser {

        const user = new AppUser();
        user.id = item.id;
        user.viewerId = item.viewerId;
        user.name = item.userName;
        user.userName = item.userName;
        user.firstName = item.firstName ? item.firstName : '';
        user.lastName = item.lastName ? item.lastName : '';
        user.fullName = user.firstName + ' ' + user.lastName;
        user.isActive = item.isActive;
        user.language = item.language;
        user.timezoneIana = item.timezoneIana;
        user.accountId = item.accountId;
        user.companyName = item.companyName;
        user.whitelabel = item.whiteLabel;
        user.userType = item.userType;
        user.userLevel = item.userLevel;
        user.referenceId = item.referenceId;
        user.createdDate = item.createdDate;
        user.email = item.email;
        user.cronTabPattern = item.cronTabPattern;
        user.deviceCount = item.deviceCount;
        user.isBackofficeOwner = item.isBackofficeOwner;
        user.isSetupUser = item.isSetupUser;
        user.created = item.createdDate;
        user.assetDisplayName = item.assetDisplayName;
        user.volumeUnit = item.volumeUnit;
        user.distanceUnit = item.distanceUnit;
        user.userRoleId = item.userRoleId;
        user.userRoleName = item.userRoleName;
        user.clientPermissionId = item.clientPermissionId;
        user.linkName = item.companyName;
        user.driverId = item.driverId;
        user.driverName = item.driverName;
        user.resellerId = item.resellerId;
        user.resellerDescription = item.resellerDescription;
        user.featureFlags = item.featureFlags;
        user.lastActivity = item.lastActivity;
        return user
    }

    private handleError(error: Response) {
        return throwError(error);
    }

    parseNotificationResponse(json: any): NotificationLog[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Logitems.');
        const that = this;

        const ident = 1;
        const auditlogs: NotificationLog[] = [];

        json.forEach(item => {
            const log = this.parseNotificationResponseDetails(item);
            auditlogs.push(log);
        });

        return auditlogs;
    }

    parseNotificationResponseDetails(item) {
        const log = new NotificationLog();
        log.id = item.id;
        log.deviceId = item.deviceId;
        log.dataTriggerId = item.dataTriggerId;
        log.recipient = item.recipient;
        log.notificationType = item.notificationType;
        log.responseCode = item.responseCode;
        log.notificationMethod = item.notificationMethod;
        log.timeStampSending = Moment.utc(item.timeStampSending)['tz'](this.timezoneIana);
        log.emailLoginName = item.emailLoginName;
        log.timeStampAcknowledged = item.timeStampAcknowledged;
        log.transmissionStatus = item.transmissionStatus;
        log.fkUserId = item.fkUserId;
        log.subject = item.subject;
        log.triggerId = item.triggerId;
        log.triggerName = item.triggerName;
        log.smsExternalMessageReference = item.smsExternalMessageReference;
        log.assetName = item.assetName;
        log.dataTriggerName = item.dataTriggerName;

        try {
            log.message = item.message && item.message.charAt(0) === '{' ? JSON.parse(item.message) : item.message;
            log.responseMessage = item.responseMessage && item.responseMessage.charAt(0) === '{' ? JSON.parse(item.responseMessage) : item.responseMessage;
        } catch (error) {
            console.log(error);
        }

        return log;
    }
}
