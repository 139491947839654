import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { getGridButtons, getGridLanguages, createSearch, createdCellCheckbox, getDefaultDpConfig } from 'app/common/gridhelper';

import { Observable, forkJoin } from 'rxjs';

import { GridBase360Directive } from 'app/common/360Grid.base';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { NotificationLogService } from 'app/services/notificationlog/notificationlog.service';
import { StorageHelper } from 'app/common/storagehelper';
import { ColorService } from 'app/services/common/color.service';

window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-notification-log',
    templateUrl: 'notificationLog.component.html'
})
export class NotificationLogComponent extends GridBase360Directive implements OnInit, OnDestroy {
    token: string;

    showActiveOnly = true;

    excludingColumns = ['createdDate'];

    loading = false;
    languageLoaded: boolean;
    permissions: {};

    selectedResellerId;
    selectedAccountId;

    permissionName = 'FleetManagement_Notifications';
    constructorName = 'NotificationLogComponent';
    timezoneIana: string;

    error;

    // Daterange
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    daterangepickerModel: Date[];

    constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private notificationLogService: NotificationLogService, protected storageHelper: StorageHelper, private colorService: ColorService) {
        super(storageHelper);

        this.token = this.authenticationService.getAuthToken();
        this.timezoneIana = this.authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(1, 'days').startOf('day').toDate(),
            Moment().add(0, 'days').endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

        // Get all the date for dropdown boxes
        forkJoin(
            this.translateService.get('general.date')
        ).subscribe(
            data => {

                this.languageLoaded = true;
                this.loading = false;

                this.initGrid();
            },
            err => console.error(err)
        );
    }

    ngOnInit() {
        this.permissions = this.authenticationService.permissions;
    }

    resellerChanged(event) {
        this.selectedAccountId = null;
        this.accountChanged(true);
    }

    accountChanged(event) {
        this.dateChanged(true);
    }

    dateChanged(event) {
        console.log('Date changed');

        const that = this;
        if (event !== null) {
            this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.ajax.url(that.notificationLogService.getPagingUrl(moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.selectedAccountId)).load();
            });
        }
    }

    initGrid(): void {
        const that = this;

        $.fn['dataTable'].ext.search.pop();

        this.loading = true;

        const notificationTypeOptions = [];
        for (let i = 0; i < 71; i++) {
            if ([32].indexOf(i) === -1) {
                notificationTypeOptions.push({ id: i, value: this.translateService.instant(('enums.notificationType.' + i)) });
            }
        }

        const notificationMethodOptions = [];
        for (let i = 1; i < 65; i = i * 2) {
            if ([4, 8].indexOf(i) === -1) {
                notificationMethodOptions.push({ id: i, value: this.translateService.instant(('enums.notificationMethod.' + i)) });
            }
        }

        this.columns = [
            {
                name: 'id',
                data: 'id',
                orderable: false,
                title: '<div class="hideDropdown"></div>',
                width: '20',
                render: function (data, type, row) {
                    return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/Notifications/LogDetails/Index/' + data + '\'><span class="d-none d-md-inline-flex" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
                }
            }, {
                name: 'id_export',
                data: 'id',
                title: this.translateService.instant('general.id'),
                visible: false,
            },
            {
                name: 'createdDate',
                data: 'timeStampSending',
                title: this.translateService.instant('general.createdDate'),
                render: function (data, type, row) {
                    const date = Moment.utc(data)['tz'](that.timezoneIana);
                    return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
                },
            }, {
                name: 'assetName',
                data: 'assetName',
                defaultContent: '-',
                title: this.translateService.instant('general.assetName')
            },
            {
                name: 'recipient',
                data: 'recipient',
                defaultContent: '-',
                title: this.translateService.instant('general.recipient')
            },
            {
                name: 'emailLoginName',
                data: 'emailLoginName',
                visible: false,
                defaultContent: '-',
                title: this.translateService.instant('general.emailLoginName')
            },
            {
                name: 'subject',
                data: 'subject',
                defaultContent: '-',
                title: this.translateService.instant('general.subject'),
                render: function (data, type, row) {
                    return data ? '<div class="truncate">' + data + '</div>' : ''
                }
            },
            {
                name: 'notificationType',
                data: 'notificationType',
                type: 'select',
                options: notificationTypeOptions.sort((a, b) => a.value.localeCompare(b.value)),
                title: this.translateService.instant('general.notificationType'),
                render: function (data, type, row) {
                    return that.translateService.instant(('enums.notificationType.' + data));
                },
            }, {
                name: 'notificationMethod',
                data: 'notificationMethod',
                type: 'select',
                options: notificationMethodOptions.sort((a, b) => a.value.localeCompare(b.value)),
                title: this.translateService.instant('general.notificationMethod'),
                render: function (data, type, row) {
                    return that.translateService.instant(('enums.notificationMethod.' + data));
                },
            }, {
                name: 'companyName',
                data: 'companyName',
                defaultContent: '-',
                title: this.translateService.instant('general.companyName')
            }, {
                name: 'accountId',
                data: 'accountId',
                defaultContent: '-',
                title: this.translateService.instant('general.accountId'),
                visible: false
            }, {
                name: 'resellerDescription',
                data: 'resellerDescription',
                defaultContent: '-',
                title: this.translateService.instant('general.resellerDescription'),
                visible: false
            },
            {
                name: 'responseCode',
                data: 'responseCode',
                defaultContent: '-',
                title: this.translateService.instant('general.responseCode'),
                visible: false
            },
            {
                name: 'responseMessage',
                data: 'responseMessage',
                defaultContent: '-',
                title: this.translateService.instant('general.response'),
                render: function (data, type, row) {
                    return data ? '<div class="truncate">' + data + '</div>' : ''
                },
                visible: false
            }
        ];

        this.dtOptions = {
            buttons: getGridButtons(this.commonExportOptions, 'notifications_overview', this.translateService.instant('menu.deviceoverview'), this.colorService),
            pagingType: 'simple_numbers',
            serverSide: true,
            processing: true,
            searchDelay: 500,
            deferRender: true,
            scrollX: true,
            colReorder: { fixedColumnsLeft: 1 },
            deferLoading: 0,
            stateSave: true,
            stateSaveCallback: function (settings, data) {
                that.saveState(that.constructorName, data);
            },
            stateLoadCallback: function (_, callback) {
                (async () => {
                    try {
                        const columnSettings = await that.loadState(that.constructorName);
                        that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
                        return columnSettings;
                    } catch (e) {
                        that.error = {};
                        that.error.error = e;
                        that.error.statusText = 'Error fetching column settings';

                        return null;
                    }
                })().then(result => {
                    callback(result);
                });
            },
            order: [[2, 'desc']],
            ajax: {
                beforeSend: () => {
                    that.drawFilterRow();

                    $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
                },
                url: that.notificationLogService.getPagingUrl(moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.selectedAccountId),
                type: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + that.token
                }
            },
            initComplete: function (settings, json) {
                console.log('init complete');
                that.checkFilters();
                that.drawFilterRow();
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
                exclude: ['id', 'id_export']
            },
            columns: this.columns,
            pageLength: 17,
            lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
            language: getGridLanguages(this.translateService),
        };
    }
}

