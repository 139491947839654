import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getDefaultDpConfig } from 'app/common/gridhelper';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { roundAsNumber, roundAsString, roundMinutes, roundSeconds } from 'app/common/globals';
import { Device } from 'app/models/device.model';
// Moment timezone
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { DeviceService } from 'app/services/device/device.service';
import { FhChartService } from 'app/services/charts/charts.service';
import { TranslateService } from '@ngx-translate/core';

import * as Highcharts from 'highcharts';
import { DistanceUnitService } from 'app/common/distanceunit.service';

window['moment'] = Moment;
mTZ()
@Component({
    providers: [FhChartService],
    selector: 'fh-device-utilization-details',
    templateUrl: 'utilizationDetails.template.html'
})
export class DeviceUtilizationDetailsViewComponent implements OnInit {
    Highcharts: typeof Highcharts = Highcharts;

    sub;
    loading = false;
    loadingUtilization = false;

    error: any;
    warning: any;
    success: any;

    device: Device;
    deviceId;
    utilization;

    // Daterange
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    daterangepickerModel: Date[];
    maxDate = new Date();

    loadingCount = false;
    distanceChartData = [];
    chartLocationCount: any;
    chartDistance: any;
    chartTrips: any;
    chartActiveDevices: any;
    timezoneIana: string;
    chartActivityCount: any;

    translatedKm: any = "km";
    translatedKmh: any = "km/h";

    constructor(private cd: ChangeDetectorRef, private distance: DistanceUnitService, private chartService: FhChartService, private authenticationService: AuthenticationService, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router, private translateService: TranslateService) {
        this.device = null;

        this.timezoneIana = this.authenticationService.getTimeZoneIana();

        this.translateService.get('general.date').subscribe(
            data => {
                this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
                this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
            }
        );

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(1, 'month').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).subtract(1, 'day').startOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);
    }

    ngOnInit() {
        this.loading = true;
        this.device = new Device;
        this.device.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.deviceId = id;
            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;
                if (!this.device.asset?.id) {
                    this.warning = {
                        statusText: 'Warning',
                        warning: 'There is no asset configured on this device.!'
                    };
                }

                this.fetchUtlization();
                this.getLocationCount();

                this.loading = false;
                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }
            });
        }, error => {
            this.error = error;
            this.error.statusText = 'Error fetching device';

            setTimeout(() => {
                this.router.navigate(['/Devices/Overview']);
            }, 3000);
        });
    }

    actualRound(value, decimals) {
        return roundAsNumber(value, decimals);
    }

    dateChanged(event) {
        this.fetchUtlization();
        this.getLocationCount();
    }

    actualRoundMinutes(value) {
        return roundMinutes(value);
    }

    actualRoundSeconds(value) {
        return roundSeconds(value);
    }

    fetchUtlization() {
        this.loadingUtilization = true;
        this.deviceService.getDeviceUtilization(this.device.id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(result => {
            this.utilization = result;

            this.loadingUtilization = false;
            this.cd.markForCheck();
        },
            err => {
                this.error = err;
                this.loadingUtilization = false;
                this.cd.markForCheck();
            });
    }


    getLocationCount() {
        this.loadingCount = true;

        this.deviceService.getLocationCount(this.device.id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(result => {
            this.loadingCount = false;
            result = result.sort((a, b) => (a.summaryDate < b.summaryDate ? -1 : 1));
            this.distanceChartData = result;
            this.generateChartLocationCountChart(this.distanceChartData);
        }, error => {
            this.loadingCount = false;
            this.error = error;
        });
    }

    // Generate chart locationcount
    generateChartLocationCountChart(data) {

        const theLocationDataIgnitionOn = [];
        const theLocationDataIgnitionOff = [];
        const theLocationDataEvents = [];
        const theDistanceData = [];
        const theTripData = [];
        const theCategories = [];
        const theNonCommunicatingCountData = [];
        const theComminicatingButNotMovingAssetCountData = [];
        const theMovingAssetCountData = [];

        // tslint:disable-next-line:forin
        data.forEach(value => {
            theLocationDataIgnitionOn.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.ignitionCount, clientData: value.customerId });
            theLocationDataIgnitionOff.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: (value.locationCount - value.ignitionCount), clientData: value.customerId });
            theLocationDataEvents.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.eventCount, clientData: value.customerId });
            theDistanceData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round((value.distanceInMeters + Number.EPSILON) / 1000), clientData: value.customerId });
            theTripData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round((value.tripCount + Number.EPSILON) * 100) / 100, clientData: value.customerId });
            theNonCommunicatingCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.assetCount - value.deviceCommunicatingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
            theComminicatingButNotMovingAssetCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.deviceCommunicatingCount - value.deviceMovingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
            theMovingAssetCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.deviceMovingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
        });

        const theChartDataLocationCount = [{
            name: 'Ignition on',
            data: theLocationDataIgnitionOn,
            type: 'area',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }, {
            name: 'Ignition off',
            data: theLocationDataIgnitionOff,
            type: 'area',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }, {
            name: 'Events',
            data: theLocationDataEvents,
            type: 'area',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }];

        const theChartDataTrip = [{
            name: 'Trips',
            data: theTripData,
            yAxis: 0,
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            type: 'areaspline'
        }];

        const theChartDataDistance = [{
            name: this.translateService.instant('general.distance'),
            data: theDistanceData,
            yAxis: 0,
            type: 'areaspline',
            fillOpacity: 0.5,
            opacity: 0.6,
        }];

        const theChartDataDeviceCount = [{
            name: 'Movement',
            data: theMovingAssetCountData,
            yAxis: 0,
            type: 'area',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }, {
            name: 'No movement',
            data: theComminicatingButNotMovingAssetCountData,
            yAxis: 0,
            type: 'area',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }, {
            name: 'Non communicating',
            data: theNonCommunicatingCountData,
            yAxis: 0,
            type: 'area',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }];

        this.chartLocationCount = this.chartService.generateColumnChartDates(theChartDataLocationCount, {}, theCategories, undefined, 'normal', true);
        this.chartDistance = this.chartService.generateColumnChartDates(theChartDataDistance, {}, theCategories, undefined, 'normal', false, ' km');
        this.chartTrips = this.chartService.generateColumnChartDates(theChartDataTrip, {}, theCategories, undefined, 'normal', false);
        this.chartActiveDevices = this.chartService.generateColumnChartDates(theChartDataDeviceCount, {}, theCategories, undefined, 'normal', true);
    }
}
