import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { Asset } from 'app/models/asset.model';
import { EntityType } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { WaslService } from 'app/services/wasl/wasl.service';
// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';

import { UserService } from 'app/services/users/user.service';
import { AppUser } from 'app/models/user.model';
import { FhChartService } from 'app/services/charts/charts.service';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { UserContactGroup, UserContactGroupItem } from 'app/models/userContactGroup.model';
import { UserContactGroupService } from 'app/services/users/userContactGroups.service';
import { UserContactService } from 'app/services/users/userContacts.service';
import { UserContactSelectorComponent } from 'app/views/shared/usercontrols/userContactSelector.component';

window['moment'] = Moment;
mTZ()

@Component({
    providers: [FhChartService],
    selector: 'fh-fh-user-contact-group-details',
    templateUrl: 'userContactGroupDetails.template.html'
})
export class UserContactGroupDetailsViewComponent implements OnInit {
    sub: any;
    userContactGroup: UserContactGroup;
    device: any;
    tagType = EntityType.UserContactGroup.valueOf();
    formMode = FormMode.read;

    @ViewChild(UserContactSelectorComponent) paginator: UserContactSelectorComponent;

    loading = false;
    loadingUserContacts = false;

    saving = false;
    loadingAssetWasl = false;

    filter;

    userContacts = [];

    error: any;
    success: any;

    permissions: {};

    permissionName = 'UserContactGroups_View';

    users: AppUser[];

    loadingDeviceCount = false;
    chartDeviceCount: any;

    // Pagination
    totalItems = 0;
    currentPage = 1;
    smallnumPages = 0;
    deviceCountData = [];
    distanceChartData = [];
    loadingCount = false;

    constructor(private router: Router, private userContactService: UserContactService, private cd: ChangeDetectorRef, private authentication: AuthenticationService, private userContactGroupService: UserContactGroupService, private route: ActivatedRoute, private userService: UserService) {
        this.permissions = this.authentication.permissions;
    }

    ngOnInit() {

        this.loading = true;
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.getUserContactGroupInfo(id);
        });
    }

    // Form
    onDelete() {
        this.loading = true;

        this.userContactGroupService.deleteUserContactGroup(this.userContactGroup).subscribe(result => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'User contact group is successfully deleted.'
            };

            setTimeout(() => {
                this.router.navigate(['/Notifications/UserContactGroups/Overview']);
            }, 3000);
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    onSave() {
        this.saving = true;
        this.loadingUserContacts = true;

        this.updateUserContactGroupItems();

        this.userContactGroupService.updateUserContactGroup(this.userContactGroup).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'User contact group is successfully updated.'
            };

            this.saving = false;
            this.loadingUserContacts = false;
            this.cd.markForCheck();

            this.setFormMode(FormMode.read);
        }, error => {
            this.loadingUserContacts = false;
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.paginator.currentPage = 1;
        }
        this.getUserContactGroupInfo();
    }

    getUserContactGroupInfo(id = 0) {
        this.loading = true;
        this.cd.markForCheck();

        if (id === 0) {
            id = this.userContactGroup.id;
        }

        this.userContactGroupService.getUserContactGroupById(id.toString()).subscribe(userContactGroup => {
            this.userContactGroup = userContactGroup;
            this.loading = false;
            this.cd.markForCheck();

            this.loadUserContacts();
        }, error => {
            this.success = null;
            this.error = error;
            this.error.statusText = 'Error fetching user contact group';
            this.cd.markForCheck();

            setTimeout(() => {
                this.router.navigate(['/Notifications/UserContactGroups/Overview']);
            }, 3000);
        });
    }

    loadUserContacts() {
        if (this.userContactGroup.accountId > 0) {
            this.loadingUserContacts = true;
            this.userContactService.getUserContacts(this.userContactGroup.accountId).subscribe(result => {

                this.userContacts = result;
                this.loadingUserContacts = false;
                this.cd.markForCheck();

                this.setUserContactNotificationMethodSettings();

                if (this.formMode === FormMode.read) {
                    this.userContacts = this.userContacts.filter(c => ((c.isEmailEnabled !== undefined && c.isEmailEnabled) || (c.isSmsEnabled !== undefined && c.isSmsEnabled) || (c.isPushEnabled !== undefined && c.isPushEnabled))).sort((a, b) => a.firstName.localeCompare(b.firstName));
                } else {
                    // First sort on notifications enabled, then alphabetically
                    const notificationEnabledUserContacts = this.userContacts.filter(c => ((c.isEmailEnabled !== undefined && c.isEmailEnabled) || (c.isSmsEnabled !== undefined && c.isSmsEnabled) || (c.isPushEnabled !== undefined && c.isPushEnabled)));
                    const notitificationDisabledUserCOntacts = this.userContacts.filter(c => !notificationEnabledUserContacts.includes(c));
                   
                    this.userContacts = notificationEnabledUserContacts.sort((a, b) => a.firstName?.localeCompare(b.firstName)).concat(notitificationDisabledUserCOntacts.sort((a, b) => a.firstName?.localeCompare(b.firstName)));
                }
            });
        } else {
            this.userContacts = [];
            this.loadingUserContacts = false;
            this.cd.markForCheck();
        }
    }

    // tslint:disable: no-bitwise
    setUserContactNotificationMethodSettings() {
        if (this.userContactGroup.items.length > 0) {
            this.userContacts.forEach(userContact => {
                const item = this.userContactGroup.items.find(ucgi => ucgi.userContactId === userContact.id);
                if (item != null) {
                    userContact.isEmailEnabled = (1 & item.notificationMethod) > 0;
                    userContact.isSmsEnabled = (2 & item.notificationMethod) > 0;
                    userContact.isPushEnabled = ((16 & item.notificationMethod) > 0 && (4 & userContact.userContactType) > 0);
                }
            })
            this.cd.markForCheck();
        }
    }

    updateUserContactGroupItems() {
        this.userContacts.forEach(userContact => {
            let notificationMethod = 0;
            // Only add new item when no item exists for userContact.
            const contactItem = this.userContactGroup.items.find(item => item.userContactId === userContact.id);
            if (contactItem === undefined) {

                if (userContact.isEmailEnabled) {
                    notificationMethod += 1;
                }
                if (userContact.isSmsEnabled) {
                    notificationMethod += 2;
                }
                if (userContact.isPushEnabled) {
                    notificationMethod += 16;

                    if ((4 & userContact.userContactType) === 0) {
                        userContact.userContactType += 4;
                    }
                }

                if (notificationMethod !== 0) {
                    const userContactGroupItem = new UserContactGroupItem();
                    userContactGroupItem.userContactId = userContact.id;
                    userContactGroupItem.notificationMethod = notificationMethod;
                    this.userContactGroup.items.push(userContactGroupItem);
                }
            } else {
                if (userContact.isEmailEnabled) {
                    notificationMethod += 1;
                }
                if (userContact.isSmsEnabled) {
                    notificationMethod += 2;
                }
                if (userContact.isPushEnabled) {
                    notificationMethod += 16;

                    if ((4 & userContact.userContactType) === 0) {
                        userContact.userContactType += 4;
                    }
                }

                // Remove userContactGroupItem if notificationMethod = 0;
                if (notificationMethod !== 0) {
                    contactItem.notificationMethod = notificationMethod;
                } else {
                    const index: number = this.userContactGroup.items.indexOf(contactItem, 0);
                    if (index > -1) {
                        this.userContactGroup.items.splice(index, 1);
                    }
                }
            }
        });
    }
}
