
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { TranslateService } from "@ngx-translate/core";
import { SignalrService } from 'app/services/signalr/signalr.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[fh-signal-r]',
    templateUrl: './signalr.component.html'
})
export class SignalRComponent implements OnInit, OnDestroy {

    batches = [];
    hubStatusMessage;
    hubUserStatusMessage;
    hubUserMessage;

    hubMessage: any;
    hubToast: any;
    hubBatchCreated: any;
    hubProcessPercentage: any;
    hubProcessMessage: any;
    hubErrorMessage: any;

    userIdSubscribed;
    isConnected = false;
    isUserConnected = false;
    connection: any;
    permissions: {};
    activeToasts = [];

    constructor(public signalrService: SignalrService, private toastr: ToastrService, private router: Router, private authenticationService: AuthenticationService, private translate: TranslateService,) {
        this.permissions = this.authenticationService.permissions;
        this.hubStatusMessage = 'Not connected';
        this.batches = this.signalrService.batches;
        this.connection = this.signalrService.connection;
        this.permissions = this.authenticationService.permissions;
    }

    isConntected() {
        return this.connection.state === signalR.HubConnectionState.Connected;
    }

    ngOnDestroy(): void {
        if (this.hubMessage !== undefined) { this.hubMessage.unsubscribe(); }
        if (this.hubUserMessage !== undefined) { this.hubUserMessage.unsubscribe(); }
        if (this.hubToast !== undefined) { this.hubToast.unsubscribe(); }
        if (this.hubBatchCreated !== undefined) { this.hubBatchCreated.unsubscribe(); }
        if (this.hubProcessPercentage !== undefined) { this.hubProcessPercentage.unsubscribe(); }
        if (this.hubProcessMessage !== undefined) { this.hubProcessMessage.unsubscribe(); }
        if (this.hubErrorMessage !== undefined) { this.hubErrorMessage.unsubscribe(); }
    }

    reconnect() {
        console.log('Reconnecting ' + this.connection.connectionId);
        this.signalrService.userIdSubscribed = null;
        this.signalrService.accountIdSubscribed = null;

        const userName = this.authenticationService.getId();
        const userId = +this.authenticationService.getUserId();
        const accountId = +this.authenticationService.getAccountId();

        this.connection.start().finally(() => {
            console.log('Getting status');
            this.signalrService.subscribeUser(userName, userId, accountId);
            this.getStatus();
        });
    }

    getStatus() {
        this.connection
            .invoke('StatusUser', +this.authenticationService.getUserId())
            , catchError(error => {
                console.log(`SignalrHub.Status() error: ${error}`);
                //  this.toastr.warning(error, 'SignalR Hub Error');

                setTimeout(() => {
                    console.log('Reconnect signalR');
                    this.reconnect();
                }, 10000);

                return null;
            });
    }

    sendTests() {
        this.connection
            .invoke('TestByUser', +this.authenticationService.getUserId(), +this.authenticationService.getAccountId())
            , catchError(error => {
                console.log(`SignalrHub.Status() error: ${error}`);
                return null;
            });
    }

    ngOnInit(): void {
        // Subscribe on user
        const userName = this.authenticationService.getId();
        const userId = +this.authenticationService.getUserId();
        const accountId = +this.authenticationService.getAccountId();

        this.signalrService.subscribeUser(userName, userId, accountId);

        this.getStatus();

        this.connection.onclose(e => {
            this.signalrService.userIdSubscribed = null;
            this.signalrService.accountIdSubscribed = null;

            this.isConnected = false;
            this.hubStatusMessage = 'Not connected';
            this.hubUserStatusMessage = 'Not connected';
        });

        this.hubMessage = this.signalrService.hubStatusMessage.subscribe((hubStatusMessage: string) => {
            if (hubStatusMessage) {
                this.isConnected = true;
                this.hubStatusMessage = hubStatusMessage;
            } else {
                this.isConnected = false;
                this.hubStatusMessage = 'Not connected';
            }
        });

        this.hubUserMessage = this.signalrService.hubUserStatusMessage.subscribe((hubUserStatusMessage: string) => {
            if (hubUserStatusMessage) {
                this.isUserConnected = true;
                this.hubUserStatusMessage = hubUserStatusMessage;
            } else {
                this.isUserConnected = false;
                this.hubUserStatusMessage = 'Not connected';
            }
        });

        this.hubToast = this.signalrService.hubToast.subscribe((message) => {
            if (message != null) {
                let header = message[0];
                let contents = message[1];
                const link = message[2];
                const reference = message[3];

                if (header.startsWith('lt.reporting')){
                    header = this.translate.instant(header);
                }

                if (contents.startsWith('lt.reporting')){
                    contents = this.translate.instant(contents);
                }

                // If an active toast has been found remove it from the toasts
                const referenceId = Object.values(this.activeToasts).find(obj => {
                    return obj.ref.toString() === reference.toString()
                })

                if (referenceId?.toastId > -1) {
                    console.log('remove ' + referenceId);
                    this.toastr.remove(referenceId.toastId);
                }

                if (header.toLowerCase().indexOf('error') > -1) {
                    var toast = this.toastr.error(contents, header);

                    if (reference) {
                        this.activeToasts.push({ toastId: toast.toastId, ref: reference });
                    }
                } else if (!link || link === '') {
                    var toast2 = this.toastr.success(contents, header);

                    if (reference) {
                        this.activeToasts.push({ toastId: toast2.toastId, ref: reference });
                    }
                } else {
                    var toast3 = this.toastr.success(contents, header, { 'progressBar': true, timeOut: 30000, });
                    toast3.onTap.subscribe((action) => this.router.navigate([link]));

                    if (reference) {
                        this.activeToasts.push({ toastId: toast3.toastId, ref: reference });
                    }
                }
            }
        });

        this.hubBatchCreated = this.signalrService.batchCreated.subscribe((batchId) => {
            this.batches = this.signalrService.batches;
        });

        this.hubProcessPercentage = this.signalrService.progressPercentage.subscribe((message) => {
            this.batches = this.signalrService.batches;
        });

        this.hubErrorMessage = this.signalrService.errorMessage.subscribe((message) => {
            this.batches = this.signalrService.batches;

            if (message != null) {
                const batchId = message[0];
                const progressMessage = message[1];
                const errorMessage = message[2];

                if (progressMessage !== null) {
                    this.toastr
                        .error(errorMessage, 'Error!', { 'progressBar': true, timeOut: 30000, })
                        .onTap.subscribe((action) => this.router.navigate(['/System/BatchDetails/Index/' + batchId]));
                }
            }
        });

        this.hubProcessMessage = this.signalrService.completeMessage.subscribe((message) => {
            this.batches = this.signalrService.batches;

            if (message != null) {
                const batchId = message[0];
                const progressMessage = message[1];

                if (progressMessage !== null) {
                    this.toastr
                        .success(progressMessage, 'Done processing!', { 'progressBar': true, timeOut: 30000, })
                        .onTap.subscribe((action) => this.router.navigate(['/System/BatchDetails/Index/' + batchId]));
                }
            }
        });
    }
}
