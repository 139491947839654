import { Component, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { BatchAction } from 'app/common/enums';
import { DeviceService } from 'app/services/device/device.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'fh-batch-rerun-trips',
    templateUrl: 'rerunTrips.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchRerunTripsComponent {
    step: number;
    @Output() onFinish = new EventEmitter();
    @Input() gridSelection = [];
    disabled = true;
    loadingDeviceDetails = false;
    logItem;

    assetForm = new FormGroup({
        checkbox: new FormControl('', Validators.required),
    });

    deviceForm: FormArray;

    startDate = new Date(new Date().setDate(new Date().getDate() - 7));

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    get formControls() {
        return this.deviceForm.controls as unknown as FormGroup[];
    }

    constructor(private cd: ChangeDetectorRef, private deviceService: DeviceService) {
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.isAnimated = true;
        this.step = 1;
    }

    initFinish() {
        // Fetch all data and make sure it can be saved
        console.log(1);
    }

    onCheckOutFinish() {
        this.onFinish.emit({ status: 'success', object: { startDate: this.startDate }, log: this.logItem })
        this.step = 1;
    }


    initForm() {
        this.step = 1;
        console.log('init tab');
    }
}
