import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EntityType, FormMode } from 'app/common/enums';
import { AccountInventory } from 'app/models/account.model';
import { TriggerSchedule } from 'app/models/triggerSchedule.model';
import { AccountService } from 'app/services/account/account.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { FhChartService } from 'app/services/charts/charts.service';
import { TriggerScheduleService } from 'app/services/triggerSchedule/triggerSchedule.service';
import { WorkingHoursSelectorComponent } from 'app/views/shared/usercontrols/workingHours.component';



@Component({
    providers: [FhChartService],
    selector: 'fh-fh-trigger-schedule-details',
    templateUrl: 'triggerScheduleDetails.template.html'
})
export class TriggerScheduleDetailsViewComponent implements OnInit {
    @ViewChild(WorkingHoursSelectorComponent, { static: false }) workingHoursVar: WorkingHoursSelectorComponent;

    sub;

    loading = false;
    saving = false;

    tagType = EntityType.TriggerSchedule.valueOf();

    error: any;
    success: any;

    formMode: FormMode = FormMode.read;

    triggerSchedule: TriggerSchedule;
    account: AccountInventory;

    hideTimezoneSelector = true;

    permissionName = 'Triggers_View';
    permissions: {};

    constructor(private triggerScheduleService: TriggerScheduleService, private cd: ChangeDetectorRef, private accountService: AccountService, private route: ActivatedRoute, private router: Router, private translateService: TranslateService, private authenticationService: AuthenticationService) {
        this.account = null;

        this.triggerSchedule = new TriggerSchedule();
        this.triggerSchedule.schedule = [];
    }

    ngOnInit(): void {
        this.loading = true;
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.getTriggerSchedule(id)
        });
    }

    getTriggerSchedule(triggerScheduleId = 0) {
        if (this.loading !== true) {
            this.loading = true;
        }

        if (triggerScheduleId === 0 ) {
            this.triggerScheduleService.getTriggerScheduleById(this.triggerSchedule.id).subscribe(triggerSchedule => {
                this.triggerSchedule = triggerSchedule;
                this.loading = false;
            });
        } else {
            this.triggerScheduleService.getTriggerScheduleById(triggerScheduleId).subscribe(triggerSchedule => {
                this.triggerSchedule = triggerSchedule;
                this.loading = false;
            });
        }
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
        }

        this.getTriggerSchedule()
    }

    onDelete() {
        this.loading = true;

        this.triggerScheduleService.deleteTriggerSchedule(this.triggerSchedule).subscribe(result => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Trigger schedule is successfully deleted.'
            };

            setTimeout(() => {
                this.router.navigate(['/Notifications/TriggerSchedules/Overview']);
            }, 3000);
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    onSave() {
        this.saving = true;
        this.loading = true;

        this.triggerScheduleService.updateTriggerSchedule(this.triggerSchedule).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Trigger schedule is successfully updated.'
            };

            this.saving = false;
            this.loading = false;
            this.cd.markForCheck();

            this.setFormMode(FormMode.read);
        }, error => {
            this.loading = false;
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }
}
