<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="">{{'general.settings' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.settingsgeneral' | translate}}</li>
            </ol>
        </nav>
    </div>
    <fh-settings-navigation></fh-settings-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-router"></i> {{'general.settings' | translate}}
                    </h5>
                </div>
                <div class="ibox-content">

                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-router"></i> {{'general.settings' | translate}}
                    </h5>
                </div>
                <div class="ibox-content">

                </div>
            </div>
        </div>
    </div>
</div>