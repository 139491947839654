<div class="ibox-content slick" style="position:relative;">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!driver">
            <tr>
                <td colspan="4">
                    {{ 'general.noDriver' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="driver">
            <ng-container *ngIf="formMode != 2">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder"
                            href="/#/ResellerDetails/Index/{{driver?.resellerId}}">{{driver?.resellerDescription }}</a>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ 'general.account' | translate}}
                    </td>
                    <td class="row_content padding-readonly-correct">
                        <a class="secondary link_bolder"
                            href="/#/AccountDetails/Index/{{driver?.accountId}}">{{driver?.companyName }}</a>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode == 2">
                <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position:relative; overflow: visible">
                        <fh-reseller-input name="reseller" #resellerVar="ngModel" (onChanged)="resellerChanged($event)"
                            required placeholder="{{ 'general.chooseReseller' | translate}}" [tableInline]="true"
                            [(ngModel)]="selectedResellerId">
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View'] && driver.accountId">
                    <td class="row_opening">
                        {{ 'general.account' | translate}}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position:relative; overflow: visible">
                        <fh-account-input name="account" #accountVar="ngModel" (onChanged)="accountChanged($event)"
                            required [selectedResellerId]="selectedResellerId" [tableInline]="true"
                            placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                            [(ngModel)]="driver.accountId">
                        </fh-account-input>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class=" row_opening">
                    {{ 'general.code' | translate}}
                    <span class="error" *ngIf="idTag.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="idTag" #idTag="ngModel"
                        placeholder="{{ 'placeholder.driverTag' | translate}}" class="form-control table-inline"
                        [(ngModel)]="driver.tag">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.name' | translate}}
                    <span class="error" *ngIf="(firstName.invalid || lastName.invalid) && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; float: left"> <input type="text" [readonly]="formMode == 1" name="firstName"
                            placeholder="{{ 'placeholder.firstName' | translate}}" class="form-control table-inline"
                            [(ngModel)]="driver.firstName" required minlength="2" placeholder="firstName"
                            #firstName="ngModel"></span>
                    <span style="width:50%; float: left; padding-left: 4px"> <input type="text"
                            [readonly]="formMode == 1" name="lastName" class="form-control table-inline"
                            placeholder="{{ 'placeholder.lastName' | translate}}" [(ngModel)]="driver.lastName" required
                            minlength="1" #lastName="ngModel"></span>

                </td>
            </tr>
            <tr class="alert alert-danger" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                <td colspan="2">
                    <div *ngIf="firstName.errors.minlength">
                        * FirstName must be at least 2 characters long.
                    </div>
                    <div *ngIf="firstName.errors.required">
                        * FirstName is required
                    </div>
                </td>
            </tr>
            <tr class="alert alert-danger" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                <td colspan="2">
                    <div *ngIf="lastName.errors.minlength">
                        * LastName must be at least 1 characters long.
                    </div>
                    <div *ngIf="lastName.errors.required">
                        * LastName is required
                    </div>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.dateOfBirth' | translate}}
                    <span class="error" *ngIf="dateOfBirth.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input [disabled]="formMode === 1" style="width:100%" [(ngModel)]="driver.dateOfBirth"
                        name="dateOfBirth" #dateOfBirth="ngModel" type="text" class="form-control table-inline"
                        autocomplete="off" placeholder="{{ 'placeholder.dateOfBirth' | translate}}"
                        [disabled]="formMode == 1" [bsConfig]="dpConfig" bsDatepicker>
                    <span *ngIf="formMode != 1" class="input-group-calendar">
                        <i class="fa fa-calendar-alt"></i>
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.mobilePhone' | translate}}
                    <span class="error" *ngIf="phone.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="mobilePhone" class="form-control table-inline"
                        placeholder="{{ 'placeholder.phone' | translate}}" [(ngModel)]="driver.mobilePhone"
                        #phone="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.officePhone' | translate}}
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="officePhone" class="form-control table-inline"
                        placeholder="{{ 'placeholder.phone' | translate}}" [(ngModel)]="driver.officePhone"
                        #phone="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.homePhone' | translate}}
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="homePhone" class="form-control table-inline"
                        placeholder="{{ 'placeholder.phone' | translate}}" [(ngModel)]="driver.homePhone"
                        #phone="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.email' | translate}}
                    <span class="error" *ngIf="email.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="email" [readonly]="formMode == 1" name="email" #email="ngModel"
                        placeholder="{{ 'placeholder.email' | translate}}" class="form-control table-inline"
                        [(ngModel)]="driver.email">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.driverLicence' | translate}} / {{ 'general.driverLicenceExpiration' | translate}}
                    <span class="error"
                        *ngIf="(driverLicence.invalid || driverLicenceExpiration.invalid) && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; float: left"> <input type="text" [readonly]="formMode == 1"
                            name="driverLicence" #driverLicence="ngModel" class="form-control table-inline"
                            placeholder="{{ 'placeholder.driverLicence' | translate}}"
                            [(ngModel)]="driver.driverLicense"></span>
                    <span style="width:50%; float: left; padding-left: 4px">
                        <input type="text" [disabled]="formMode == 1" name="driverLicenceExpiration"
                            #driverLicenceExpiration="ngModel" class="form-control table-inline"
                            [(ngModel)]="driver.driverLicenceExpirationDate"
                            placeholder="{{ 'placeholder.driverLicenceExpiration' | translate}}"
                            [disabled]="formMode == 1" autocomplete="off" [bsConfig]="dpConfig" bsDatepicker>
                        <span *ngIf="formMode != 1" class="input-group-calendar">
                            <i class="fa fa-calendar-alt"></i>
                        </span>
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.driverIdentification' | translate}} / {{ 'general.driverIdentificationExpiration' |
                    translate}}
                    <span class="error"
                        *ngIf="(driverIdentification.invalid || driverIdentificationExpiration.invalid) && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; float: left"> <input type="text" [readonly]="formMode == 1"
                            name="driverIdentification" #driverIdentification="ngModel"
                            placeholder="{{ 'placeholder.driverIdentification' | translate}}"
                            class="form-control table-inline" [(ngModel)]="driver.identificationCardNumber"></span>
                    <span style="width:50%; float: left; padding-left: 4px">
                        <input type="text" [disabled]="formMode == 1" name="driverIdentificationExpiration"
                            placeholder="{{ 'placeholder.driverIdentificationExpiration' | translate}}"
                            #driverIdentificationExpiration="ngModel" class="form-control table-inline"
                            [disabled]="formMode == 1" [(ngModel)]="driver.identificationCardExpirationDate"
                            autocomplete="off" [bsConfig]="dpConfig" bsDatepicker>
                        <span *ngIf="formMode != 1" class="input-group-calendar"><i class="fa fa-calendar-alt"></i>
                        </span>
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.qualification' | translate}}
                    <span class="error" *ngIf="qualification.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="qualification" #qualification="ngModel"
                        placeholder="{{ 'placeholder.qualification' | translate}}" class="form-control table-inline"
                        [(ngModel)]="driver.qualification">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.erpCode' | translate}}
                    <span class="error" *ngIf="reference.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="erpCode" class="form-control table-inline"
                        placeholder="{{ 'placeholder.erpCode' | translate}}" [(ngModel)]="driver.erpCode"
                        #reference="ngModel">
                </td>
            </tr>
            <ng-container *ngIf="permissions['Modules_HasPrivateFleet']">
                <tr *ngIf="formMode == 1">
                    <td class="row_opening">
                        {{ 'general.garage' | translate}}
                    </td>
                    <td class="row_content">
                        <input type="text" name="garage" disabled="disabled" class="form-control table-inline"
                            placeholder="{{ 'placeholder.garage' | translate}}" value="{{ driver.garageName }}">
                    </td>
                </tr>
                <tr *ngIf="formMode != 1">
                    <td class="row_opening">
                        {{ 'general.garage' | translate}}
                        <span class="error" *ngIf="driverGarageVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-garage-selector name="garages" #driverGarageVar="ngModel"
                            [placeholder]="'general.chooseGarage' | translate" [(ngModel)]="driver.garageId">
                        </fh-garage-selector>
                    </td>
                </tr>
            </ng-container>
            <tr *ngIf="formMode != 2">
                <td class="row_opening">
                    {{ 'general.createdDriver' | translate}}
                </td>
                <td class="row_content padding-readonly-correct" title="{{ driver?.created?.toLocaleString() }}">
                    {{driver?.created | amDateFormat: 'll'}}
                </td>
            </tr>
            <tr *ngIf="formMode != 2">
                <td class="row_opening" [class.text-danger]="driver?.isArchived">
                    {{ 'general.archived' | translate}}
                </td>
                <td [class.text-danger]="driver?.isArchived" class="row_content padding-readonly-correct">
                    {{driver?.isArchived}}
                </td>
            </tr>
            <tr>
                <td colspan="2" style="background-color: unset; border-bottom: none !important; padding:2px">
                    <textarea rows="4" [readonly]="formMode == 1" name="comments" #comments="ngModel"
                        class="noresize stickypad" placeholder="{{ 'placeholder.comments' | translate}}"
                        style="height:100px; width:100%; resize: none" [(ngModel)]="driver.comment">
                </textarea>
                </td>
            </tr>
        </tbody>
    </table>
</div>