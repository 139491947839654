import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DeviceType } from 'app/models/devicetype.model';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';

import { GridBase360Directive } from 'app/common/360Grid.base';
import { getDefaultDpConfig, getGridButtons, getGridLanguages } from 'app/common/gridhelper';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { roundAsNumber, roundAsString } from 'app/common/globals';
import { DashboardService } from 'app/services/dashboard/dashboard.service';
import { StorageHelper } from 'app/common/storagehelper';
import { FhChartService } from 'app/services/charts/charts.service';

window['moment'] = Moment;
mTZ();

import * as Highcharts from 'highcharts';
import { DistanceUnitService } from 'app/common/distanceunit.service';
import { ColorService } from 'app/services/common/color.service';

@Component({
    providers: [FhChartService],
    selector: 'fh-issues',
    templateUrl: 'issues.component.html',
})
export class IssuesViewComponent extends GridBase360Directive implements OnDestroy, OnInit {
    Highcharts: typeof Highcharts = Highcharts;

    loading = false;

    timezoneIana: string;
    token: string;
    permissions: {};

    languageLoaded = false;
    episodesLoaded = false;

    deviceTypes: DeviceType[];

    randomKey: number;

    permissionName = 'FleetManagement_Issues';
    constructorName = 'IssuesViewComponent';

    error;
    success;

    kpis;
    chartViolationsPerType;

    // Daterange
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    daterangepickerModel: Date[];

    constructor(private dashboardService: DashboardService, private distance: DistanceUnitService, private chartService: FhChartService, private translateService: TranslateService, private authenticationService: AuthenticationService, private deviceTypeService: DeviceTypeService, private cd: ChangeDetectorRef, protected storageHelper: StorageHelper, private colorService: ColorService) {
        super(storageHelper);

        this.permissions = authenticationService.permissions;
        this.timezoneIana = authenticationService.getTimeZoneIana();
        this.token = authenticationService.getAuthToken();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(7, 'days').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

        this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

        // Get all the date for dropdown boxes
        forkJoin([
            this.translateService.get('general.date'),
            this.deviceTypeService.getDeviceTypes()]
        ).subscribe(
            data => {
                this.languageLoaded = true;
                this.loading = false;
                this.cd.markForCheck();

                this.deviceTypes = data[1].filter(x => x.deviceCount > 0);
                this.deviceTypes = this.deviceTypes.sort((a, b) => (a.modelName > b.modelName ? 1 : -1));

                this.initGrid();
            },
            err => {
                this.error = err;
                this.languageLoaded = true;
                this.loading = false;
                this.cd.markForCheck();
            });
    }

    ngOnInit(): void {
    }

    dateChanged(event) {
        const that = this;
        if (event !== null) {
            this.episodesLoaded = true;

            this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.ajax.url(that.dashboardService.getIssuesUrl(this.randomKey, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')))
                    .load(() => this.episodesLoaded = false);
            });
        }
    }

    processData(data) {
        if (!data || data.length === 0) {
            return [];
        }

        const dataPoints = [];
        for (let i = 0; i < 18; i++) {
            const count = data.filter(x => x.issueType === i).length;
            if (count > 0) {
                dataPoints.push({
                    name: this.translateService.instant(('enums.issues.' + i)),
                    y: data.filter(x => x.issueType === i).length,
                });
            }
        }

        const dataViolationsPerType = [{
            name: this.translateService.instant('general.issues'),
            colorByPoint: true,
            minPointSize: 20,
            innerSize: '20%',
            zMin: 0,
            data: dataPoints,
        }];

        this.chartViolationsPerType = this.chartService.generateVarPieChartDevice(dataViolationsPerType, {});

        return data;
    }

    initGrid(): void {
        const that = this;

        const commonExportOptions = {
            modifier: {
                page: 'all',
                search: 'none'
            },
            columns: ['id_export:name', ':visible[tabindex]']
        };

        const deviceTypeOptions = [];
        this.deviceTypes.forEach(function (item, index) {
            if (item.modelName !== '') {
                deviceTypeOptions.push({ id: item.modelName, value: item.modelName + ' (' + item.deviceCount + ')' });
            }
        });

        let issueTypeOptions = [];

        if (this.permissions['FleetManagementIssues_HighVolume']) {
            issueTypeOptions.push({
                group: that.translateService.instant('general.issueGroup_HighVolume'), items: [
                    { id: 'HighMessageCount', value: that.translateService.instant(('enums.issues.2')) },
                    { id: 'HighEventCount', value: that.translateService.instant(('enums.issues.3')) },
                    { id: 'HighIgnitionOffMessageCount', value: that.translateService.instant(('enums.issues.6')) }
                ]
            })
        }

        if (this.permissions['FleetManagementIssues_Behaviour']) {
            issueTypeOptions.push({
                group: that.translateService.instant('general.issueGroup_Behaviour'), items: [
                    { id: 'ArchivedButSendingData', value: that.translateService.instant(('enums.issues.1')) },
                    { id: 'NoLocationsSendButActive', value: that.translateService.instant(('enums.issues.4')) },
                    { id: 'NoAssetButActive', value: that.translateService.instant(('enums.issues.5')) },
                    { id: 'GPSStuck', value: that.translateService.instant(('enums.issues.10')) },
                    { id: 'OutOfSequence', value: that.translateService.instant(('enums.issues.23')) }
                ]
            })
        }

        if (this.permissions['FleetManagementIssues_Installation']) {
            issueTypeOptions.push({
                group: that.translateService.instant('general.issueGroup_Installation'), items: [
                    { id: 'NoIgnitionOff', value: that.translateService.instant(('enums.issues.8')) },
                    { id: 'DistanceWithoutIgnitionOn', value: that.translateService.instant(('enums.issues.9')) },
                    { id: 'SpeedWhileIgnitionOff', value: that.translateService.instant(('enums.issues.13')) },
                    { id: 'NoAnalogWeight', value: that.translateService.instant(('enums.issues.20')) }
                ]
            })
        }

        if (this.permissions['FleetManagementIssues_Trips']) {
            issueTypeOptions.push({
                group: that.translateService.instant('general.trips'), items: [
                    { id: 'HighTripCount', value: that.translateService.instant(('enums.issues.7')) },
                    { id: 'NonConnectingTrips', value: that.translateService.instant(('enums.issues.11')) },
                    { id: 'MissingTrips', value: that.translateService.instant(('enums.issues.12')) }
                ]
            })
        }

        if (this.permissions['FleetManagementIssues_Canbus']) {
            issueTypeOptions.push({
                group: that.translateService.instant('general.canbus'), items: [
                    { id: 'NoCanRpm', value: that.translateService.instant(('enums.issues.18')) },
                    { id: 'NoCanWeight', value: that.translateService.instant(('enums.issues.19')) },
                    { id: 'NoCanOdo', value: that.translateService.instant(('enums.issues.21')) },
                    { id: 'NoCanFuel', value: that.translateService.instant(('enums.issues.22')) }
                ]
            })
        }

        this.columns = [
            {
                name: 'deviceId',
                data: 'deviceId',
                orderable: false,
                title: '<div class="hideDropdown"></div>',
                width: '20',
                render: function (data, type, row) {
                    return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/DeviceDetails/Index/' + data + '\'><span class="d-none d-md-inline-flex" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
                }
            },
            {
                name: 'name',
                data: 'name',
                title: this.translateService.instant('general.name'),
                render: function (data, type, row) {
                    return data ?? '';
                },
            },
            {
                name: 'unitId',
                data: 'unitId',
                title: this.translateService.instant('general.unitId'),
                visible: true
            },
            {
                name: 'deviceTypeName',
                data: 'deviceTypeName',
                defaultContent: '-',
                iconName: 'fas fa-fw fa-router',
                type: 'select',
                options: deviceTypeOptions.sort((a, b) => a.value.localeCompare(b.value)),
                title: this.translateService.instant('general.devicetype')
            },
            {
                name: 'manufacturer',
                data: 'manufacturer',
                defaultContent: '-',
                title: this.translateService.instant('general.manufacturer'),
                visible: false,
                render: function (data, type, row) {
                    return data ? data : '-';
                }
            },
            {
                name: 'issueType',
                data: 'issueType',
                title: this.translateService.instant('general.issueType'),
                iconName: 'fas fa-fw fa-exclamation-triangle',
                type: 'select',
                options: issueTypeOptions,
                render: function (data, type, row) {
                    return that.translateService.instant(('enums.issues.' + data));
                },
            },
            {
                name: 'code',
                data: 'code',
                visible: false,
                title: this.translateService.instant('general.code'),
                render: function (data, type, row) {
                    return data ?? '';
                },
            },
            {
                name: 'brand',
                data: 'brand',
                visible: false,
                title: this.translateService.instant('general.brand'),
                render: function (data, type, row) {
                    return data ?? '';
                },
            },
            {
                name: 'model',
                data: 'model',
                visible: false,
                title: this.translateService.instant('general.model'),
                render: function (data, type, row) {
                    return data ?? '';
                },
            },
            {
                name: 'beginDate',
                data: 'beginDate',
                title: this.translateService.instant('general.startDate'),
                render: function (data, type, row) {
                    const date = Moment.utc(data)['tz'](that.timezoneIana);
                    return data ? '<span>' + date.format('ll') + '</span>' : '';
                },
                visible: false
            },
            {
                name: 'endDate',
                data: 'endDate',
                title: this.translateService.instant('general.endDate'),
                render: function (data, type, row) {
                    const date = Moment.utc(data)['tz'](that.timezoneIana);
                    return data ? '<span>' + date.format('ll') + '</span>' : '';
                },
                visible: false
            },
            {
                name: 'tripCount',
                data: 'tripCount',
                type: 'num',
                title: this.translateService.instant('general.tripCount'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
            {
                name: 'messageCount',
                data: 'messageCount',
                type: 'num',
                title: this.translateService.instant('general.messageCount'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
            {
                name: 'tripMessageCount',
                data: 'tripMessageCount',
                type: 'num',
                title: this.translateService.instant('general.tripMessageCount'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
            {
                name: 'stopMessageCount',
                data: 'stopMessageCount',
                type: 'num',
                title: this.translateService.instant('general.stopMessageCount'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
            {
                name: 'ignitionCount',
                data: 'ignitionCount',
                type: 'num',
                title: this.translateService.instant('general.ignitionCount'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
            {
                name: 'speedCount',
                data: 'speedCount',
                type: 'num',
                title: this.translateService.instant('general.speedCount'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
            {
                name: 'uniqueCoordPercentage',
                data: 'uniqueCoordPercentage',
                type: 'num',
                visible: false,
                title: this.translateService.instant('general.uniqueCoordPercentage'),
                render: function (data, type, row) {
                    return data ? (roundAsNumber(data, 0)).toLocaleString() + ' %' : 0;
                },
            },
            {
                name: 'badTrips',
                data: 'badTrips',
                type: 'num',
                visible: false,
                title: this.translateService.instant('general.badTrips'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
            {
                name: 'eventCount',
                data: 'eventCount',
                type: 'num',
                title: this.translateService.instant('general.eventCount'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            }, {
                name: 'distanceInMeters',
                data: 'distanceInMeters',
                type: 'num',
                title: this.translateService.instant('general.distance'),
                render: function (data, type, row) {
                    return data && data > 0.1 ? (roundAsNumber(data / 1000, 1)).toLocaleString() + ' ' + that.translateService.instant(that.distance.getDistanceUnit()) : 0;
                },
            },
            {
                name: 'companyName',
                data: 'companyName',
                defaultContent: '-',
                title: this.translateService.instant('general.companyName')
            },
            {
                name: 'resellerDescription',
                data: 'resellerDescription',
                defaultContent: '-',
                title: this.translateService.instant('general.resellerDescription'),
                visible: false
            },
            {
                name: 'delayMoreThan10',
                data: 'delayMoreThan10',
                type: 'num',
                visible: false,
                title: this.translateService.instant('general.delayMoreThan10'),
                render: function (data, type, row) {
                    const totalDelay = row.delayMoreThan10 + row.delayMoreThan60 + row.delayMoreThan240 + row.delayMoreThan960;

                    return '<span title="' + (totalDelay) + ' / ' + row.messageCount + '">' +
                        (data ? (roundAsNumber(totalDelay / row.messageCount * 100, 0)).toLocaleString() : 0) + ' %'
                        + '</span>';
                },
            },
            {
                name: 'delayMoreThan60',
                data: 'delayMoreThan60',
                type: 'num',
                visible: false,
                title: this.translateService.instant('general.delayMoreThan60'),
                render: function (data, type, row) {
                    const totalDelay = row.delayMoreThan60 + row.delayMoreThan240 + row.delayMoreThan960;

                    return '<span title="' + (totalDelay) + ' / ' + row.messageCount + '">' +
                        (data ? (roundAsNumber(totalDelay / row.messageCount * 100, 0)).toLocaleString() : 0) + ' %'
                        + '</span>';
                },
            },
            {
                name: 'delayMoreThan240',
                data: 'delayMoreThan240',
                type: 'num',
                visible: false,
                title: this.translateService.instant('general.delayMoreThan240'),
                render: function (data, type, row) {
                    const totalDelay = row.delayMoreThan240 + row.delayMoreThan960;

                    return '<span title="' + (totalDelay) + ' / ' + row.messageCount + '">' +
                        (data ? (roundAsNumber(totalDelay / row.messageCount * 100, 0)).toLocaleString() : 0) + ' %'
                        + '</span>';
                },
            },
            {
                name: 'delayMoreThan960',
                data: 'delayMoreThan960',
                type: 'num',
                visible: false,
                title: this.translateService.instant('general.delayMoreThan960'),
                render: function (data, type, row) {
                    const totalDelay = row.delayMoreThan960;

                    return '<span title="' + (totalDelay) + ' / ' + row.messageCount + '">' +
                        (data ? (roundAsNumber(totalDelay / row.messageCount * 100, 0)).toLocaleString() : 0) + ' %'
                        + '</span>';
                },
            },
            {
                name: 'outOfSequenceCount',
                data: 'outOfSequenceCount',
                type: 'num',
                title: this.translateService.instant('general.outOfSequenceCount'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
        ];

        this.dtOptions = {
            buttons: getGridButtons(this.commonExportOptions, 'issues_overview', this.translateService.instant('menu.issueoverview'), this.colorService),
            pagingType: 'simple_numbers',
            serverSide: true,
            processing: true,
            // scrollY: 500,
            // scroller: {
            //     loadingIndicator: true
            // },
            searchDelay: 2000,
            deferRender: true,
            scrollX: true,
            colReorder: { fixedColumnsLeft: 2 },
            deferLoading: 0,
            stateSave: true,
            stateSaveCallback: function (settings, data) {
                that.saveState(that.constructorName, data);
            },
            stateLoadCallback: function (_, callback) {
                (async () => {
                    try {
                        const columnSettings = await that.loadState(that.constructorName);
                        that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
                        return columnSettings;
                    } catch (e) {
                        that.error = {};
                        that.error.error = e;
                        that.error.statusText = 'Error fetching column settings';

                        return null;
                    }
                })().then(result => {
                    callback(result);
                });
            },
            order: [[0, 'desc']],
            ajax: {
                beforeSend: () => {
                    that.drawFilterRow();

                    $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
                },
                url: that.dashboardService.getIssuesUrl(this.randomKey, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')),
                data: (d) => {
                    return d;
                },
                dataSrc: function (json) {
                    // does not work as only first page will be processed
                    //that.processData(json.data);
                    return json.data;
                },
                type: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + that.token
                }
            },
            initComplete: function (settings, json) {
                that.loading = false;
                that.episodesLoaded = false;

                console.log('init complete');
                that.checkFilters();
                that.drawFilterRow();
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
            },
            columns: this.columns,
            pageLength: 17,
            lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
            language: getGridLanguages(this.translateService),
        };
    }
}
