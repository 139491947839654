import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { getGridButtons, getGridLanguages, createSearch, createdCellCheckbox, getDefaultDpConfig } from 'app/common/gridhelper';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { Observable, forkJoin } from 'rxjs';

import { AuditLogService } from 'app/services/auditlog/auditlog.service';

import { GridBase360Directive } from 'app/common/360Grid.base';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { StorageHelper } from 'app/common/storagehelper';
import { ColorService } from 'app/services/common/color.service';

window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-audit-log',
    templateUrl: 'auditlog.component.html'
})
export class AuditLogComponent extends GridBase360Directive implements OnInit, OnDestroy {
    token: string;

    showActiveOnly = true;

    excludingColumns = ['createdDate'];

    loading = false;
    languageLoaded: boolean;
    permissions: {};

    permissionName = 'FleetManagement_Audit';
    constructorName = 'AuditLogComponent';
    timezoneIana: string;

    // Daterange
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    daterangepickerModel: Date[];

    error;

    constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private auditLogService: AuditLogService, protected storageHelper: StorageHelper, private colorService: ColorService) {
        super(storageHelper);

        this.token = this.authenticationService.getAuthToken();
        this.timezoneIana = this.authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(1, 'months').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

        // Get all the date for dropdown boxes
        forkJoin(
            this.translateService.get('general.date')
        ).subscribe(
            data => {

                this.languageLoaded = true;
                this.loading = false;

                this.initGrid();
            },
            err => console.error(err)
        );
    }

    ngOnInit() {
        this.permissions = this.authenticationService.permissions;
    }

    dateChanged(event) {
        console.log('Date changed');

        const that = this;
        if (event !== null) {
            this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.ajax.url(that.auditLogService.getPagingUrl(moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'))).load();
            });
        }
    }

    initGrid(): void {
        const that = this;

        $.fn['dataTable'].ext.search.pop();

        this.loading = true;

        const logTypeOptions = [];
        for (let i = 1; i < 110; i++) {
            logTypeOptions.push({ id: i, value: this.translateService.instant(('enums.logType.' + i)) });
        }

        const entityTypesOptions = [];
        for (let i = 1; i < 40; i++) {
            entityTypesOptions.push({ id: i, value: this.translateService.instant(('enums.entityTypes.' + i)) });
        }

        this.columns = [
            {
                name: 'id',
                data: 'id',
                orderable: false,
                title: '<div class="hideDropdown"></div>',
                width: '20',
                render: function (data, type, row) {
                    return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/System/AuditLogDetails/Index/' + data + '\'><span class="d-none d-md-inline-flex" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
                }
            }, {
                name: 'id_export',
                data: 'id',
                title: this.translateService.instant('general.id'),
                visible: false,
            },
            {
                name: 'createdDate',
                data: 'createdDate',
                title: this.translateService.instant('general.createdDate'),
                render: function (data, type, row) {
                    const date = Moment.utc(data)['tz'](that.timezoneIana);
                    return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
                },
            }, {
                name: 'userName',
                data: 'userName',
                title: this.translateService.instant('general.userName')
            }, {
                name: 'logType',
                data: 'logType',
                title: this.translateService.instant('general.logType'),
                type: 'select',
                options: logTypeOptions.sort((a, b) => a.value.localeCompare(b.value)),
                render: function (data, type, row) {
                    return that.translateService.instant(('enums.logType.' + data));
                },
            }, {
                name: 'entityType',
                data: 'entityType',
                title: this.translateService.instant('general.entityType'),
                type: 'select',
                options: entityTypesOptions.sort((a, b) => a.value.localeCompare(b.value)),
                render: function (data, type, row) {
                    return that.translateService.instant(('enums.entityTypes.' + data));
                },
            }, {
                name: 'description',
                data: 'description',
                defaultContent: '-',
                title: this.translateService.instant('general.description')
            }, {
                name: 'companyName',
                data: 'companyName',
                defaultContent: '-',
                title: this.translateService.instant('general.companyName')
            }, {
                name: 'accountId',
                data: 'accountId',
                defaultContent: '-',
                title: this.translateService.instant('general.accountId'),
                visible: false
            },
        ];

        this.dtOptions = {
            buttons: getGridButtons(this.commonExportOptions, 'resellers_overview', this.translateService.instant('menu.deviceoverview'), this.colorService),
            pagingType: 'simple_numbers',
            serverSide: true,
            processing: true,
            searchDelay: 500,
            deferRender: true,
            scrollX: true,
            colReorder: { fixedColumnsLeft: 1 },
            deferLoading: 0,
            stateSave: true,
            stateSaveCallback: function (settings, data) {
                that.saveState(that.constructorName, data);
            },
            stateLoadCallback: function (_, callback) {
                (async () => {
                    try {
                        const columnSettings = await that.loadState(that.constructorName);
                        that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
                        return columnSettings;
                    } catch (e) {
                        that.error = {};
                        that.error.error = e;
                        that.error.statusText = 'Error fetching column settings';

                        return null;
                    }
                })().then(result => {
                    callback(result);
                });
            },
            order: [[2, 'desc']],
            ajax: {
                beforeSend: () => {
                    that.drawFilterRow();

                    $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
                },
                url: that.auditLogService.getPagingUrl(moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')),
                type: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + that.token
                }
            },
            initComplete: function (settings, json) {
                console.log('init complete');
                that.checkFilters();
                that.drawFilterRow();
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
                exclude: ['id', 'id_export']
            },
            columns: this.columns,
            pageLength: 17,
            lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
            language: getGridLanguages(this.translateService),
        };
    }
}

