import {
    Component, AfterViewInit, Input, ElementRef, ViewChild, OnInit, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { MaintenanceTypesService } from 'app/services/maintenance/maintenanceTypes.service';

const noop = () => {
};

@Component({
    selector: 'fh-maintenance-type-input',
    templateUrl: './maintenanceTypeSelector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: MaintenanceTypeInputComponent }
    ]
})

export class MaintenanceTypeInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {

    @Input() placeholder: string;
    @Input() clearable = true;
    @Input() selectedAccountId;
    @Input() autoSelect = true;
    @Input() readonly = false;
    @Input() addTag = false;
    @Input() assetTypeId;

    @Output() onChanged = new EventEmitter();

    maintenanceTypes = []

    loadingMaintenanceTypes = false;

    @ViewChild('input') el: ElementRef;

    private _value: string;
    private _onChange: (_: any) => void = noop;

    get value(): any {
        return this._value;
    };

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this._onChange(v);
            this.cd.markForCheck();
        }
    }

    constructor(private maintenanceTypeService: MaintenanceTypesService, private cd: ChangeDetectorRef) {
    }

    assetTypeChanged() {
        this.onChanged.emit(this.value);
        this.loadingMaintenanceTypes = false;
        this.cd.markForCheck();
    }

    ngOnChanges() {
        setTimeout(() => {
            // Get accounts again
            this.loadAssetTypes();
        }, 0);
    }

    loadAssetTypes() {

        this.maintenanceTypes = [];
        this.cd.markForCheck();

        if (this.selectedAccountId) {

            this.loadingMaintenanceTypes = true;
            this.cd.markForCheck();

            if (this.selectedAccountId && this.selectedAccountId !== 0) {
                this.maintenanceTypeService.getMaintenanceTypesByAccount(this.selectedAccountId).subscribe(result => {
                    var maintenanceTypes = result;

                    if (this.assetTypeId) {
                        maintenanceTypes = maintenanceTypes.filter(x => x.assetTypeList?.indexOf(this.assetTypeId.toString()) > -1);
                    }

                    this.maintenanceTypes = maintenanceTypes;

                    // Autoselect
                    if (!this.readonly && this.maintenanceTypes.length === 1 && this.autoSelect) {
                        console.log('Setting default to ' + this.maintenanceTypes[0].id);
                        this.value = this.maintenanceTypes[0].id;
                        this.cd.markForCheck();
                    }

                    this.loadingMaintenanceTypes = false;
                    this.cd.markForCheck();
                });
            } else {
                this.loadingMaintenanceTypes = false;
                this.cd.markForCheck();
            }
        }
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        const __this = this;
    }

    writeValue(value: any) {
        this._value = value;
        this.cd.markForCheck();
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
        this.cd.markForCheck();
    }

    registerOnTouched(fn: any) {
        this.cd.markForCheck();
    }

}
