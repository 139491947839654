import { Geofence } from 'app/models/geofence.model';
import '../../../vendor/leaflet-mapbox-gl/leaflet-mapbox-gl.js';
import '../../../vendor/leaflet-layers/leaflet-layers.js';
import '../../../vendor/leaflet-basemaps/leaflet-basemaps.js';
import '../../../vendor/leaflet-corridor/leaflet-corridor.js';
import '../../../vendor/leaflet-contextmenu/leaflet-contextmenu.js';

declare const window;

export function getMapProviders(L, extraLayers = null) {

     const mapTiler = L.tileLayer('https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=YLfOtVnqZuWr7kkDLbI4', {
          tileSize: 512,
          maxZoom: 22,
          zoomOffset: -1,
          minZoom: 1,
          attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
          crossOrigin: true
     });

     const mapTilerVector = L.mapboxGL({
          accessToken: 'pk.eyJ1Ijoid2Rla29ydGUiLCJhIjoiY2szenY0NHpmMHprMzNtcWM3cnlmaDhsZCJ9.UvME9fYRq6oqgnEIyCzYNw',
          style: 'https://api.maptiler.com/maps/streets/style.json?key=YLfOtVnqZuWr7kkDLbI4'
     });


     const mapTilerHybrid = L.tileLayer('https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=YLfOtVnqZuWr7kkDLbI4', {
          tileSize: 512,
          maxZoom: 22,
          zoomOffset: -1,
          minZoom: 1,
          attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
          crossOrigin: true
     });

     const mapBoxV3 = L.mapboxGL({
          accessToken: 'pk.eyJ1Ijoid2Rla29ydGUiLCJhIjoiY2szenY0NHpmMHprMzNtcWM3cnlmaDhsZCJ9.UvME9fYRq6oqgnEIyCzYNw',
          style: 'mapbox://styles/wdekorte/clpqutds5015f01o91j99hm26',
          projection: 'mercator',
          attribution: 'Map data: © Mapbox',
     });

     const mapBoxNavigationDark = L.mapboxGL({
          accessToken: 'pk.eyJ1Ijoid2Rla29ydGUiLCJhIjoiY2szenY0NHpmMHprMzNtcWM3cnlmaDhsZCJ9.UvME9fYRq6oqgnEIyCzYNw',
          style: 'mapbox://styles/wdekorte/ckxafdtoebvkf14pa0nw7o3fq',
          projection: 'mercator',
          attribution: 'Map data: © Mapbox',
     });

     const mapBoxTrafficLight = L.mapboxGL({
          accessToken: 'pk.eyJ1Ijoid2Rla29ydGUiLCJhIjoiY2szenY0NHpmMHprMzNtcWM3cnlmaDhsZCJ9.UvME9fYRq6oqgnEIyCzYNw',
          style: 'mapbox://styles/wdekorte/cl5i7ncj8000n14ncwnmxbggl',
          projection: 'mercator',
     });

     const mapBoxTrafficDark = L.mapboxGL({
          accessToken: 'pk.eyJ1Ijoid2Rla29ydGUiLCJhIjoiY2szenY0NHpmMHprMzNtcWM3cnlmaDhsZCJ9.UvME9fYRq6oqgnEIyCzYNw',
          style: 'mapbox://styles/wdekorte/ck3zxj3tv06xn1cmr6543hb91',
          projection: 'mercator',
          attribution: 'Map data: © Mapbox',
     });

     const mapBoxNavigationLight = L.mapboxGL({
          accessToken: 'pk.eyJ1Ijoid2Rla29ydGUiLCJhIjoiY2szenY0NHpmMHprMzNtcWM3cnlmaDhsZCJ9.UvME9fYRq6oqgnEIyCzYNw',
          style: 'mapbox://styles/wdekorte/ckxafixicbw5q15s91ixqjawv',
          projection: 'mercator',
          attribution: 'Map data: © Mapbox',
     });

     const mapBoxVector = L.mapboxGL({
          accessToken: 'pk.eyJ1Ijoid2Rla29ydGUiLCJhIjoiY2szenY0NHpmMHprMzNtcWM3cnlmaDhsZCJ9.UvME9fYRq6oqgnEIyCzYNw',
          style: 'mapbox://styles/wdekorte/ckyx20k8i001a15l8m5mz2hcp',
          projection: 'mercator',
          attribution: 'Map data: © Mapbox',
     });

     const mapBoxVectorDark = L.mapboxGL({
          accessToken: 'pk.eyJ1Ijoid2Rla29ydGUiLCJhIjoiY2szenY0NHpmMHprMzNtcWM3cnlmaDhsZCJ9.UvME9fYRq6oqgnEIyCzYNw',
          style: 'mapbox://styles/wdekorte/ckmz1xygm1hlh17pfguk59irp',
          projection: 'mercator',
          attribution: 'Map data: © Mapbox',
     });

     const mapBoxHybrid = L.mapboxGL({
          accessToken: 'pk.eyJ1Ijoid2Rla29ydGUiLCJhIjoiY2szenY0NHpmMHprMzNtcWM3cnlmaDhsZCJ9.UvME9fYRq6oqgnEIyCzYNw',
          style: 'mapbox://styles/wdekorte/cl5i83lju005114pvtis2kfo7',
          projection: 'mercator',
          attribution: 'Map data: © Mapbox',
     });

     const darkmatter = L.tileLayer('https://api.maptiler.com/maps/darkmatter/{z}/{x}/{y}.png?key=YLfOtVnqZuWr7kkDLbI4', {
          tileSize: 512,
          zoomOffset: -1,
          minZoom: 1,
          attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
          crossOrigin: true,
     });

     const googleMaps = L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          attribution: 'Map data: © Google',
     });

     const googleHybrid = L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          attribution: 'Map data: © Google',
     });

     const googleSatellite = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          attribution: 'Map data: © Google',
     });

     const googleTerrain = L.tileLayer('https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          attribution: 'Map data: © Google',
     });

     const here = {
          apiKey: 'PunUDE9qd0mjSUF_OK6RwJVYeMwoLaC5yOclcIWxwME', // https://www.here.com/docs/bundle/map-image-developer-guide-v3/page/topics/styles.html
     }

     const hereMap = L.tileLayer(`https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?size=512&style=logistics.day&apiKey=${here.apiKey}`, {
          attribution: '&copy;2024 HERE Technologies',
          tileSize: 512,
          zoomOffset: -1,
          minZoom: 1,
     });

     const hereMapSatellite = L.tileLayer(`https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?size=512&style=logistics.satellite.day&apiKey=${here.apiKey}`, {
          attribution: '&copy;2024 HERE Technologies',
          tileSize: 512,
          zoomOffset: -1,
          minZoom: 1,
     });

     const maps = {
          'mapBoxV3': mapBoxV3,
          'mapTiler': mapTiler,
          'mapTilerHybrid': mapTilerHybrid,
          'mapTilerVector': mapTilerVector,
          'mapBoxHybrid': mapBoxHybrid,
          'mapBoxTrafficLight': mapBoxTrafficLight,
          'mapBoxTrafficDark': mapBoxTrafficDark,
          'mapBoxNavigationLight': mapBoxNavigationLight,
          'mapBoxNavigationDark': mapBoxNavigationDark,
          'mapBoxVector': mapBoxVector,
          'mapBoxVectorDark': mapBoxVectorDark,
          'dark': darkmatter,
          'Google': googleMaps,
          'Hybrid': googleHybrid,
          'Satellite': googleSatellite,
          'Terrain': googleTerrain,
          'Here': hereMap,
          'HereSatellite': hereMapSatellite
     };

     return maps;
}

export function getMapProvidersExtended(L, mapSelectionOptions, extraLayers = null) {

     const maps = getMapProviders(L, extraLayers);
     const mapSelectionOption = mapSelectionOptions ? mapSelectionOptions.toString() : '';

     let maps2 = [
          { name: 'MapTiler - Map', layer: maps.mapTiler, icon: './assets/images/maps/maptiler.png' },
          { name: 'MapTiler - Hybrid', layer: maps.mapTilerHybrid, icon: './assets/images/maps/maptilerHybrid.png' },
          { name: 'MapBox - Map', layer: maps.mapBoxVector, icon: './assets/images/maps/mapboxVector.png' },
          { name: 'MapBox - Hybrid', layer: maps.mapBoxHybrid, icon: './assets/images/maps/mapboxHybrid.png' },
          { name: 'Google - Map', layer: maps.Google, icon: './assets/images/maps/google.png' },
          { name: 'Google - Hybrid', layer: maps.Hybrid, icon: './assets/images/maps/hybrid.png' },
          { name: 'Here - Map', layer: maps.Here, icon: './assets/images/maps/mapboxVector.png' },
          { name: 'Here - Hybrid', layer: maps.HereSatellite, icon: './assets/images/maps/mapboxHybrid.png' },
     ];

     if (mapSelectionOption === '1') {
          maps2 = [
               { name: 'MapTiler - Map', layer: maps.mapTiler, icon: './assets/images/maps/maptiler.png' },
               { name: 'MapTiler - Hybrid', layer: maps.mapTilerHybrid, icon: './assets/images/maps/maptilerHybrid.png' },
               { name: 'MapTiler - Vector', layer: maps.mapTilerVector, icon: './assets/images/maps/maptilerVector.png' },
          ];
     }

     if (mapSelectionOption === '2') {
          maps2 = [
               { name: 'Mapbox - Map', layer: maps.mapBoxVector, icon: './assets/images/maps/mapboxVector.png' },
               { name: 'Mapbox - V3', layer: maps.mapBoxV3, icon: './assets/images/maps/mapboxV3.png' },
               { name: 'Mapbox - Hybrid', layer: maps.mapBoxHybrid, icon: './assets/images/maps/mapboxHybrid.png' },
               { name: 'Mapbox - Traffic', layer: maps.mapBoxTrafficLight, icon: './assets/images/maps/maptilerTrafficLight.png' },
               { name: 'Mapbox - Dark', layer: maps.mapBoxVectorDark, icon: './assets/images/maps/mapboxVectorDark.png' },
          ];
     }

     if (mapSelectionOption === '3') {
          maps2 = [
               { name: 'Google - Map', layer: maps.Google, icon: './assets/images/maps/google.png' },
               { name: 'Google - Hybrid', layer: maps.Hybrid, icon: './assets/images/maps/hybrid.png' },
               { name: 'Google - Satellite', layer: maps.Satellite, icon: './assets/images/maps/satellite.png' },
               { name: 'Google - Terrain', layer: maps.Terrain, icon: './assets/images/maps/terrain.png' },
          ];
     }
     return maps2;
}


export function getDefaultDpConfig() {
     return {
          containerClass: 'theme-default',
          dateInputFormat: 'll',
          rangeSeparator: ' - ',
          rangeInputFormat: 'll'
     };
}

export function setBounds(L, map) {
     const southWest = L.latLng(-89.98155760646617, -180),
          northEast = L.latLng(89.99346179538875, 180);

     const bounds = L.latLngBounds(southWest, northEast);

     map.setMaxBounds(bounds);
     map.on('drag', function () {
          map.panInsideBounds(bounds, { animate: false });
     });
}

export function createMapOptions(L, maps, translateService, geofenceFromTrips = false, showCompass = false) {

     const showCompassOption = showCompass.toString() == "true" ? true : false;

     const mapActions = [
          {
               text: translateService.instant('general.centerMap'),
               callback: (e) => { centerMap(e); }
          },
     ];

     if (!(this?.showSaveAsGeofence === false)) {
          mapActions.push({
               text: translateService.instant('general.saveLocation'),
               callback: (e) => { saveAsGeofence(e); }
          });
     }

     if (geofenceFromTrips) {
          mapActions.push(
               {
                    text: translateService.instant('general.saveStartAsGeofence'),
                    callback: (e) => { saveStartAsGeofence(e); }
               },
               {
                    text: translateService.instant('general.saveEndAsGeofence'),
                    callback: (e) => { saveEndAsGeofence(e); }
               },
               {
                    text: translateService.instant('general.saveRouteAsGeofence'),
                    callback: (e) => { saveRouteAsGeofence(e); }
               }
          );
     }

     let options = {
          layers: maps,
          zoom: 3,
          // zoomDelta: 0.25,
          // zoomSnap: 0,
          center: L.latLng(25, 20),
          minZoom: 3,
          maxZoom: 20,
          bearing: 0,
          rotate: false,
          rotateControl: null,
          touchRotate: true,
          contextmenu: true,
          contextmenuWidth: 250,
          contextmenuItems: [
               ...mapActions,
               '-',
               {
                    text: translateService.instant('general.zoomIn'),
                    icon: 'assets/images/icons/zoom-in.png',
                    callback: (e) => { zoomIn(e); }
               }, {
                    text: translateService.instant('general.zoomOut'),
                    icon: 'assets/images/icons/zoom-out.png',
                    callback: (e) => { zoomOut(e); }
               }
          ]
     };

     if (showCompassOption) {
          options = {
               ...options,
               rotate: showCompassOption,
               rotateControl: showCompassOption ? {
                    closeOnZeroBearing: false,
                    position: 'bottomright',
               } : {},
          }
     }

     return options;
}

export function drawGeofences(L, geofences, layer, color) {
     // Add geofences layer
     // const geofenceFeatureGroup = featureGroup();
     // map.addLayer(geofenceFeatureGroup);

     // parse geofences

     const geofenceMap = new Map<number, L.FeatureGroup>();

     geofences.forEach(item => {
          geofenceMap.set(item.id, drawGeofence(L, item, layer, color));
     });

     return geofenceMap;
}

export function drawGeofence(L, geofence: Geofence, geofenceFeatureGroup, color) {
     if (geofence.geoJson) {
          const geoJsonData = geofence.geoJson;

          const geofencePopup = `<div style="width:300px;overflow: auto;" class="leaflet-mappopup">
               <div class="header">${geofence.name}</div>
               <div class="content"><a href="/#/GeofenceDetails/Index/${geofence.id}">Details</a></div>`;

          return L.geoJSON(JSON.parse(geoJsonData), {
               style: function (feature): L.PathOptions {
                    return {
                         fillColor: color ? color : geofence.color,
                         color: color ? color : geofence.color,
                         opacity: 0.5,
                         fillOpacity: 0.2
                    };
               },
               pointToLayer: function (geoJsonPoint, latlng): L.Circle<any> {
                    return L.circle(latlng, geofence.radius, {
                         fillColor: color ? color : geofence.color,
                         color: color ? color : geofence.color,
                         weight: 5,
                         opacity: 0.5,
                         fillOpacity: 0.2
                    });
               },
          }).eachLayer(function (layer) {
               if (layer.feature.geometry?.type === 'LineString') {
                    const corridor = L.corridor(layer.getLatLngs(), {
                         corridor: Math.max(5, geofence.radius),
                         className: 'route-corridor',
                         opacity: '0.35',
                         color: color ?? geofence.color, // 'var(--svgcolor)',
                    });

                    corridor.on('mouseover', function () {
                         this.setStyle({
                              color: color ? color : geofence.color,
                              fillColor: color ? color : geofence.color,
                              opacity: 0.3,
                              fillOpacity: 0.1
                         });
                    });

                    corridor.on('mouseout', function () {
                         this.setStyle({
                              color: color ? color : geofence.color,
                              fillColor: color ? color : geofence.color,
                              opacity: 0.5,
                              fillOpacity: 0.2
                         });
                    });

                    corridor.addTo(geofenceFeatureGroup);
                    geofence.layerRef = corridor;
               } else {
                    layer.bindPopup(geofencePopup, {
                         closeButton: false
                    });

                    layer.on('mouseover', function () {
                         this.setStyle({
                              color: color ? color : geofence.color,
                              fillColor: color ? color : geofence.color,
                              opacity: 0.3,
                              fillOpacity: 0.1
                         });
                    });

                    layer.on('mouseout', function () {
                         this.setStyle({
                              color: color ? color : geofence.color,
                              fillColor: color ? color : geofence.color,
                              opacity: 0.5,
                              fillOpacity: 0.2
                         });
                    });

                    layer.addTo(geofenceFeatureGroup);
                    geofence.layerRef = layer;
               }

               // For all layers

          });
     }
}

export const colorMapper = (deviceState = 0) => ({
     0: 'grey',    // Unknown
     1: 'green',   // Ignition On
     2: 'red',     // Ignition Off
     3: 'orange',  // Idling
     4: 'blue',    // No communication
     5: 'black',   // External Power Loss
     6: 'violet',  // Gps fix lost
     7: 'brown'     // Archived
}[deviceState]);

// Options
function saveAsGeofence(e): void {
     window.my = window.my || {};
     window.my.namespace.saveAsGeofence(e.latlng.lat, e.latlng.lng);
}

function centerMap(e): void {
     console.log('Center map');
     window.my.namespace.panTo(e.latlng.lat, e.latlng.lng);
}

function zoomIn(e): void {
     window.my.namespace.zoomIn(e);
}

function zoomOut(e): void {
     window.my.namespace.zoomOut(e);
}

function saveStartAsGeofence(e): void {
     window.my = window.my || {};
     window.my.namespace.saveStartAsGeofence(e);
}

function saveEndAsGeofence(e): void {
     window.my = window.my || {};
     window.my.namespace.saveEndAsGeofence(e);
}

function saveRouteAsGeofence(e): void {
     window.my = window.my || {};
     window.my.namespace.saveRouteAsGeofence(e);
}
