import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColorService } from 'app/services/common/color.service';
// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { UserService } from '../../services/users/user.service';
import { CookieService } from 'ngx-cookie-service';
import { CacheService } from 'app/services/common/cache.service';

window['moment'] = Moment;
mTZ()

@Component({
    templateUrl: './redirect.template.html',
})
export class RedirectComponent {

    success;
    error;

    constructor(private route: ActivatedRoute, private cookieService: CookieService, private cacheService: CacheService, private colorService: ColorService, private router: Router, private authenticationService: AuthenticationService) {
        const that = this;

        this.checkRoute();
    }

    ngOnInit() {
        // this.authenticationService.stopImpersonation(false);
        // this.cookieService.delete('SsoToken', '/');
        // this.cookieService.delete('RefreshToken', '/');
        // this.cookieService.delete('Token', '/');
        // this.cacheService.clearCache();
        // this.authenticationService.clearToken(false);

        this.route.params.subscribe(params => {
            const token = params['token'];
            this.authenticate(token);
        });
    }

    checkRoute() {
        // Check if path is recogniced and force theme based on url.
        // When this is loaded it will overwrite the logged in theme and force the url theme
        const url = window.location.origin;

        let overwriteWhitelabel = '';
        overwriteWhitelabel = this.colorService.getOverwriteTheme(url);

        if (overwriteWhitelabel != '') {
            console.log('Overwriting whitelabel with path ' + url + ' setting theme ' + overwriteWhitelabel);

            localStorage.setItem(AuthenticationService.WHITELABEL, overwriteWhitelabel);

            this.colorService.getThemeColors(overwriteWhitelabel).subscribe(result => {
                this.colorService.addTheme(result);
            });
        }
    }

    authenticate(token: any) {
        this.authenticationService.LoginShareToken(token).subscribe(result => {
            console.log('result');
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Redirecting you to proper page'
            };

            setTimeout(() => {
                switch (result.type) {
                    case 1: // Report
                        this.router.navigate(['/ReportDetails/Shared/' + result.entityId], { skipLocationChange: true });
                        break;
                    case 2: // Device
                        this.router.navigate(['/DeviceDetails/Shared/' + result.entityId], { skipLocationChange: true });
                        break;
                    case 3: // Trips
                        this.router.navigate(['/DeviceDetails/SharedTrips/' + result.entityId], { skipLocationChange: true });
                        break;
                    case 4: // TripDetail
                        this.router.navigate(['/DeviceDetails/SharedTripDetails/' + result.entityId], { skipLocationChange: true });
                        break;

                    default:
                        this.error = { error: 'No valid results' }
                        break;
                }
            }, 20);

        }, error => {
            this.success = null;
            this.error = error;
        });
    }
}
