import { Pipe, PipeTransform } from '@angular/core';

/**
 * A simple string filter, since Angular does not yet have a filter pipe built in.
 */
@Pipe({
    name: 'stringFilter'
})
export class StringFilterPipe implements PipeTransform {

    transform(items: any[], search: any): any[] {
        if (!items) {
            return null;
        }

        if (!search) {
            return items;
        }

        search = search.toLowerCase();

        return items.filter(function (item) {
            return JSON.stringify(item)?.toLowerCase()?.includes(search);
        });
    }
}
