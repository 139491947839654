import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { Project } from 'app/models/project.model';
import { ProjectService } from 'app/services/project/project.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { AssetService } from 'app/services/asset/asset.service';
import { getDefaultDpConfig } from 'app/common/gridhelper';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-projects-add',
  templateUrl: 'add.template.html'
})
export class ProjectsAddViewComponent implements OnInit {
  token: string;
  loading = false;
  loadingGroups = false;
  project: Project;

  formMode: FormMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string; };
  selectedGroups = [];
  selectedGroupsSource = [];
  groupName;

  permissionName = 'Projects_Add';

  // Multiselect
  format = {
    add: 'Add', remove: 'Remove', all: 'All', none: 'None', draggable: true
  };

  display = 'name';

  source = [];
  confirmed = [];

  step = 1;

  errorItems = {};

  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();

  constructor(private translateServe: TranslateService, private projectService: ProjectService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private router: Router, private assetService: AssetService) {
    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();

    this.selectedGroups = [];
    this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

    this.dpConfig.containerClass = 'theme-default';
    this.dpConfig.dateInputFormat = 'lll';
    this.dpConfig.isAnimated = true;
            this.dpConfig.withTimepicker = true;
        this.dpConfig.keepDatepickerOpened = true;
  }

  ngOnInit() {
    this.project = new Project();
  }

  sendRequest(form) {
    const assets = [];
    // tslint:disable-next-line:forin
    for (const key in form.value) {
      if (!+key) {
        continue;
      }
      const item = form.value[key];
      const startDate = +(!!item.startDate ? item.startDate : form.form.get('assignmentStart')?.value) / 1000;
      const endDate = +(!!item.endDate ? item.endDate : form.form.get('assignmentEnd')?.value) / 1000;
      assets.push({ ...item, startDate, endDate });
    }

    this.project.assets = assets;

    this.loading = true;
    this.cd.markForCheck();

    this.formMode = FormMode.read;

    this.projectService.saveProject(this.project).subscribe((result) => {
      this.error = null;
      this.success = {
        statusText: result?.message ?? 'Success',
        success: 'Project is successfully added.',
      };

      this.project.id = result?.id;
      this.errorItems = result?.errorItems;
      this.loading = false;
    }, error => {
      this.success = null;
      this.error = error;

      this.errorItems = error?.errorItems;
      this.loading = false;
    });
  }

  onCheckOutFinish() {
    if (this.project.id) {
      this.router.navigate(['/ProjectDetails/Index/' + this.project.id]);
      return;
    }

    this.router.navigate(['/Projects']);
  }

  accountChanged(accountId) {
    this.confirmed = [];
    if (accountId !== null) {
      this.assetService.getAssetsByAccount(accountId).subscribe(result => {
        this.source = result.filter(x => x.isArchived !== true);
      });
    } else {
      this.source = [];
    }
  }

  onInsert() {
    this.loading = true;

    this.projectService.saveProject(this.project).subscribe(result => {

      this.success = {
        statusText: 'Success',
        success: this.translateServe.instant('general.saveSuccessRedirect', { entity: 'project' })
      };

      setTimeout(() => {
        this.router.navigate(['/ProjectDetails/Index/' + result.id]);
      }, 2000);

      this.loading = false;
    }, error => {
      this.error = error
      this.loading = false;
    });
  }
}
