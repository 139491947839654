<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Equipment">{{'general.equipment' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.dashboard' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-equipment-navigation> </fh-equipment-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class='col-lg-12'>
            <fh-error [error]="error" [success]="success" [warning]="warning"></fh-error>

            <!-- Custom filters -->
            <div class="ibox" [hidden]="!showFilter">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content slick_toppadding flex-container" style="position: relative;">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px; display:block">
                            <fh-reseller-input name="reseller" #resellerVar="ngModel"
                                (onChanged)="resellerChanged($event)" required
                                placeholder="{{ 'general.chooseReseller' | translate}}"
                                [(ngModel)]="selectedResellerId">
                            </fh-reseller-input>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px; display:block">
                            <fh-account-input name="account" #accountVar="ngModel" required
                                (onChanged)="accountChanged($event)" [selectedResellerId]="selectedResellerId"
                                [showEmptyAccountsButton]="false"
                                placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                [(ngModel)]="selectedAccountId">
                            </fh-account-input>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group input-group" style="margin-bottom: 0px;">
                            <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker" type="text"
                                [bsConfig]="dpConfig" class="form-control" bsDaterangepicker
                                [(ngModel)]="daterangepickerModel">
                            <span class="input-group-calendar-datatables">
                                <i class="fa fa-calendar-alt"></i>
                            </span>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                    <div style="clear:both"></div>
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px; display:block">
                            <fh-asset-group-input name="assetGroups" #assetGroupVar="ngModel" required
                                [selectedAccountId]="selectedAccountId" [tableInline]="false" [multiple]="false"
                                [autoSelect]="false"
                                placeholder="{{ (selectedAccountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                                [(ngModel)]="selectedAssetGroup">
                            </fh-asset-group-input>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px; display:block">
                            <ng-select placeholder="{{ 'general.chooseVehicleType' | translate}}" [clearable]="true"
                                class="form-control" name="vehicleType" required #vehicleType="ngModel"
                                [(ngModel)]="selectedVehicleType" style="width:100%">
                                <ng-option *ngFor="let vehicleType of vehicleTypes" [value]="vehicleType.id">
                                    {{ ('enums.vehicleType.' + vehicleType.id) | translate }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px; display:block">
                            <fh-asset-type-input name="assetTypes" #assetTypeVar [selectedAccountId]="selectedAccountId"
                                [tableInline]="false" [multiple]="false" [autoSelect]="false"
                                placeholder="{{ (selectedAccountId? 'general.chooseAssetTypes' : 'general.firstChooseAccount') | translate}}"
                                [(ngModel)]="selectedAssetType">
                            </fh-asset-type-input>
                        </div>
                    </div>
                    <div style="clear:both"></div>
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px;">
                            <fh-project-input name="project" #projectVar [selectedAccountId]="selectedAccountId"
                                [tableInline]="false" [multiple]="false" [autoSelect]="false"
                                placeholder="{{ (selectedAccountId? 'general.chooseProject' : 'general.firstChooseAccount') | translate}}"
                                [(ngModel)]="selectedProjectId">
                            </fh-project-input>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group input-group" style="margin-bottom: 0px; display:block">
                            <ng-select class="form-control" name="deviceTypes" class="form-control"
                                placeholder="{{ 'general.chooseDeviceType' | translate }}  ({{ deviceTypeOptions.length }})"
                                [(ngModel)]="selectedDeviceType" style="width:100%">
                                <ng-option *ngFor="let deviceType of deviceTypeOptions" [value]="deviceType.id">
                                    {{ deviceType.value }}</ng-option>
                            </ng-select>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="form-group input-group" style="margin-bottom: 6px; display:block">
                            <ng-select class="form-control" name="utilizationCalculationFactor" [clearable]="false"
                                placeholder="{{ 'general.utilizationCalculationFactor' | translate }}"
                                class="form-control" [(ngModel)]="utilizationCalculationFactor" style="width:100%">
                                <ng-option [value]=1>
                                    {{ 'enums.utilizationCalculationFactor.1' | translate}}
                                </ng-option>
                                <ng-option [value]=2>
                                    {{ 'enums.utilizationCalculationFactor.2' | translate}}
                                </ng-option>
                                <ng-option [value]=3>
                                    {{ 'enums.utilizationCalculationFactor.3' | translate}}
                                </ng-option>
                            </ng-select>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="form-group input-group" style="margin-bottom: 6px;" title="Filter on 0 values">
                            <span id="checkBox11" class="input-group-prepend hand" title="Filter on 0 values">
                                <i id="checkBox_11" class="auto_checkbox fa fa-fw"
                                    (click)="filterZeroValues = !filterZeroValues;"
                                    [ngClass]="filterZeroValues ? 'fa-check' : 'fa-times'" title="Filter on 0 values"
                                    [style.color]="filterZeroValues ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                            </span>
                            <input name="checkboxName_11" id="checkboxName_11"
                                style="width:100%; cursor: pointer!important;"
                                placeholder="{{'general.filterZeroValues' | translate }}" disabled="disabled"
                                class="form-control auto_search">
                        </div>
                    </div>
                    <div style="clear:both"></div>
                </div>
                <div class="ibox-content" style="position: relative; display:none">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div id="dynamicFilters" class="flex-container"></div>
                    <div style="clear:both"></div>
                </div>
                <div class="ibox-footer">
                    <button *ngIf="!loading" type="button" name="search" class="btn btn-primary"
                        (click)="dateChanged('hidden')" [disabled]="selectedAccountId == null">{{ 'general.fetchData' |
                        translate}}</button>

                    <button *ngIf="loading" type="button" name="cancel" class="btn btn-info" (click)="cancel()">
                        <i class="fas fa-fw fa-times"></i> {{ 'general.cancel' | translate}}</button>
                </div>
            </div>
            <!-- End default dynamic filters -->
        </div>
    </div>

    <div class="row" style="position: relative;" *ngIf="devices?.length > 0">
        <ngx-loading [show]="loading"></ngx-loading>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;" [routerLink]="['/Devices/Overview/']">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-car"></i>
                        {{kpis?.assetCountWithTrips?.toLocaleString()}} / {{kpis?.assetCount?.toLocaleString()}}

                    </h3>
                    <div>{{'general.activeAssets' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fa fa-fw fa-route"></i>
                        {{actualRound(kpis?.totalDistance,0)?.toLocaleString()}} km
                    </h3>
                    <div>{{'general.distance' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-route"></i> {{actualRound(kpis?.totalDistance /
                        kpis?.assetCount,0)?.toLocaleString()}} km
                    </h3>
                    <div>{{'general.avgDistance' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-clock"></i>
                        {{actualRoundMinutes(kpis?.totalDuration / 60)}} h
                    </h3>
                    <div>{{'general.workingHours' | translate }}</div>
                </div>
            </div>
        </div>
      
        <!-- Eqipment -->
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fa fa-fw  fa-truck-plow"></i>
                        {{actualRoundMinutes(kpis?.equipmentUtilization / 60)}} h
                    </h3>
                    <div>{{'general.equipmentUtilization' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fa fa-fw  fa-truck-plow"></i>
                        {{actualRound((kpis?.equipmentUtilization / kpis?.totalDuration) * 100, 2) }} %
                    </h3>
                    <div>{{'general.equipmentUtilization' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fa fa-fw fa-snooze"></i>
                        {{actualRoundMinutes(kpis?.totalEquipmentIdling / 60)}} h
                    </h3>
                    <div>{{'general.equipmentIdling' | translate }}</div>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fa fa-fw fa-snooze"></i>
                        {{actualRound((kpis?.totalEquipmentIdling / kpis?.totalDuration) * 100, 2) }} %
                    </h3>
                    <div>{{'general.equipmentIdling' | translate }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="devices?.length > 0">
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-engine"></i> {{'general.utilizationPerAsset' | translate }} %
                    </h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartUtilization">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <highcharts-chart *ngIf="chartUtilization" [runOutsideAngular]="true"
                        style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                        [options]="chartUtilization"></highcharts-chart>

                </div>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-truck-plow"></i> {{'general.equipmentUtilization' | translate }}
                    </h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartEquipmentUtilization">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loadingTrends"></ngx-loading>

                    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <highcharts-chart [runOutsideAngular]="true"
                                style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                                [options]="chartEquipmentUtilization"></highcharts-chart>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-truck-plow"></i> {{'general.equipmentIdling' | translate }}
                    </h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartEquipmentIdling">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loadingTrends"></ngx-loading>

                    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <highcharts-chart [runOutsideAngular]="true"
                                style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                                [options]="chartEquipmentIdling"></highcharts-chart>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-truck-plow"></i> {{'general.equipmentCrossover' | translate }}
                    </h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartEquipmentCrossover">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loadingTrends"></ngx-loading>

                    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <highcharts-chart [runOutsideAngular]="true"
                                style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                                [options]="chartEquipmentCrossover"></highcharts-chart>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-truck-plow"></i> {{ 'general.equipment' | translate }}</h5>
                    <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)"
                        [constructorName]="constructorName" (filterChanged)="showFilter = $event"
                        (presetLoaded)="onPresetLoaded($event)" (columnsChanged)="drawFilterRow()"></iboxseach>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <table style="width:100%; min-height:50px;" fh-datatable [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger" class="table  nowrap margin-bottom-0 dataTable dtr-inline">
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>