import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';



import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable, catchError, map } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { Trip } from 'app/models/trip.model';

import { getGeofenceActionResources, getIconPath, roundMinutes, roundSeconds } from 'app/common/globals';
import { GeofenceState } from 'app/models/geofenceState.model';
import { Episode } from 'app/models/episode.model';
import { TranslateService } from '@ngx-translate/core';
import { DistanceUnitService } from 'app/common/distanceunit.service';
import { parseEpisode } from '../common/episode.parser';

import * as L from 'leaflet';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { LeafletMapComponent } from 'app/views/shared/usercontrols/leafletMap.component';
import { FhChartService } from '../charts/charts.service';

window['moment'] = Moment;
mTZ()


@Injectable()
export class TripService {
    timestamp;
    baseUrl: string;
    url: string;
    timezoneIana: string;

    translatedKm: any = "km";
    translatedKmh;

    constructor(private translateService: TranslateService, private http: HttpClient, private loggingService: LoggingService, private distance: DistanceUnitService, private authenticationService: AuthenticationService, private translate: TranslateService) {
        this.url = authenticationService.getWebserviceURL('Trip');
        this.baseUrl = authenticationService.getWebserviceURL('');
        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.translateService.get('general.date').subscribe(
            data => {
                this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
                this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
            }
        );
    }


    getPagingUrl(accountId, start, end) {
        return this.url + 'Paging?accountId=' + accountId + '&startRange=' + start.unix() + '&endRange=' + end.unix();
    }

    getTripsForDevice(deviceId: string, start, end, shared = false): Observable<Trip[]> {
        let headers;
        if (shared) {
            headers = this.authenticationService.shareheaders;
        } else {
            headers = this.authenticationService.headers;
        }

        console.log('getting trips from service');


        return this.http.get(this.url + '?deviceId=' + deviceId + '&start=' + start.unix() + '&end=' + end.unix() + `&shared=${shared}`, { headers: headers })
            .pipe(map(data => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            }), catchError(this.handleError));
    }

    getTripsWithGapEpisodes(deviceId: string, driverId: string, start, end): Observable<Trip[]> {

        console.log('getting getTripsWithGapEpisodes from service');

        return this.http.get(this.url + 'TripsWithGapEpisodes/?deviceId=' + deviceId + '&driverId=' + driverId + '&start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .pipe(map((data: any) => {
                let ident = data.trips.length;

                const trips: Trip[] = [];

                data.trips.forEach(item => {
                    const trip = this.parseTripDetails(item);
                    trip.ident = ident;
                    trips.push(trip);

                    ident--;
                });

                data.gaps.forEach(item => {
                    const trip = this.parseTripDetails(item);
                    trip.ident = ident;
                    trips.push(trip);
                });

                return trips;
            }), catchError(this.handleError));
    }

    getGapsForDevice(deviceId: string, driverId: string, start, end): Observable<Trip[]> {

        console.log('getting stops from service');

        return this.http.get(this.url + 'Gaps/?deviceId=' + deviceId + '&driverId=' + driverId + '&start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            }), catchError(this.handleError));
    }

    getTripsForDriver(driverId: string, start, end): Observable<Trip[]> {
        console.log('getting trips from service');

        return this.http.get(this.url + '?driverId=' + driverId + '&start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            }), catchError(this.handleError));
    }

    getTripDetails(tripId: string, shared = false): Observable<any> {
        let headers;
        if (shared) {
            headers = this.authenticationService.shareheaders;
        } else {
            headers = this.authenticationService.headers;
        }

        console.log('getting locations for trip from service');
        return this.http.get(this.url + tripId + '/Details' + `?shared=${shared}`, { headers: headers })
            .pipe(map((data: any) => {
                const trip: any = this.parseTripDetails(data);

                trip.geofenceStates = this.parseGeofenceStates(data.geofenceStates);
                trip.messages = data.messages;

                trip.messages.forEach(message => {
                    message.speed = this.distance.calculateDistanceUnitFromKmFixed(message.speed);
                    message.odometer = this.distance.calculateDistanceUnitFromKmFixed(message.odometer);
                });

                trip.episodes = [];

                data.episodes?.forEach(item => {
                    const episode = parseEpisode(item, this.translate, Moment, this.timezoneIana, this.distance);
                    trip.episodes.push(episode);
                });

                trip.geofenceStates.forEach(geofenceState => {
                    geofenceState.geofence = geofenceState.geofenceId;

                    // Link to locations
                    const matchingLocation = trip.messages.filter(x => x.timestamp >= geofenceState.timestamp);
                    if (matchingLocation.length > 0) {
                        geofenceState.location = L.latLng(matchingLocation[0].latitude, matchingLocation[0].longitude);
                    }
                });

                trip.concatenatedEvents = [...trip.episodes, ...trip.geofenceStates].sort((a, b) => b.date - a.date);

                return trip;
            }), catchError(this.handleError));
    }

    updateTripById(tripId, isPrivate, kmPrivate, comment): Observable<any> {
        console.log('getting assetGroups from service : ' + this.baseUrl);

        const tripObject = {
            id: tripId,
            isPrivate: isPrivate,
            kmPrivate: kmPrivate,
            comment: comment,
        }

        return this.http.put(this.url + tripId, tripObject, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return data;
            }), catchError(this.handleError));
    }

    getMessageUrl(deviceId: string, limit: number = 500, source, start, end, key) {
        return this.baseUrl + 'Message/Paging?deviceId=' + deviceId + '&limit=' + limit + '&source=' + source + '&begin=' + start.unix() + '&end=' + end.unix() + '&rnd=' + key;
    }

    getEpisodeUrl(deviceId: string, triggerId: string, start, end, key) {
        return this.baseUrl + 'Episode/Paging?deviceId=' + deviceId + '&triggerId=' + triggerId + '&begin=' + start.unix() + '&end=' + end.unix() + '&rnd=' + key;
    }

    getEpisodes(deviceId: string, start, end) {
        const that = this;
        console.log('getting episodes states from service');

        return this.http.get(this.baseUrl + 'Device/' + deviceId + '/Episodes?start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .pipe(map((data: any) => {
                const returnItem = [];
                data.forEach(item => {
                    const episode = parseEpisode(item, this.translate, Moment, this.timezoneIana, this.distance);
                    returnItem.push(episode);
                });

                return returnItem;
            }), catchError(this.handleError));
    }

    getEpisodeDetailsById(episodeId: string) {
        const that = this;
        console.log('getting episodes states from service');
        return this.http.get(this.baseUrl + 'Episode/' + episodeId, { headers: this.authenticationService.headers })
            .pipe(map((data: any) => {
                return parseEpisode(data, this.translate, Moment, this.timezoneIana, this.distance);
            }), catchError(this.handleError));
    }

    getEpisodeDetailsByIdentifier(episodeGuid: string) {
        const that = this;
        console.log('getting episodes states from service');
        return this.http.get(this.baseUrl + 'Episode/' + episodeGuid + '/Identifier', { headers: this.authenticationService.headers })
            .pipe(map((data: any) => {
                return parseEpisode(data, this.translate, Moment, this.timezoneIana, this.distance);
            }), catchError(this.handleError));
    }

    deleteEpisodeById(episodeId: any) {
        return this.http.delete(this.baseUrl + 'Episode/' + episodeId, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    getGeofenceStatesUrl(deviceId: string, geofenceId: number, start, end, key) {
        return this.baseUrl + 'GeofenceState/Paging?deviceId=' + deviceId + '&geofenceId=' + geofenceId + '&begin=' + start.unix() + '&end=' + end.unix() + '&rnd=' + key;
    }

    getGeofenceEventsUrl(deviceId: string, geofenceId: number, start, end, key) {
        return this.baseUrl + 'GeofenceState/Events/Paging?deviceId=' + deviceId + '&geofenceId=' + geofenceId + '&begin=' + start.unix() + '&end=' + end.unix() + '&rnd=' + key;
    }

    getGeofenceEpisodesUrl(deviceId: string, geofenceId: number, start, end, key) {
        return this.baseUrl + 'GeofenceState/Episodes/Paging?deviceId=' + deviceId + '&geofenceId=' + geofenceId + '&begin=' + start.unix() + '&end=' + end.unix() + '&rnd=' + key;
    }

    getGeofenceStates(deviceId: string, start, end) {
        console.log('getting geofence states from service');
        return this.http.get(this.baseUrl + 'GeofenceState?deviceId=' + deviceId + '&start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .pipe(map((data: any) => {
                return this.parseGeofenceStates(data);
            }), catchError(this.handleError));
    }

    parseGeofenceStates(data) {
        const returnItem = [];

        data?.forEach(item => {

            const geofenceState = new GeofenceState();

            geofenceState.display = true;
            geofenceState.rtcBasedTimestamp = item.stateChangeDateTime;
            geofenceState.startUtc = Moment.utc(item.stateChangeDateTime)['tz'](this.timezoneIana);
            geofenceState.header = Moment.utc(item.stateChangeDateTime)['tz'](this.timezoneIana).format('HH:mm');
            geofenceState.date = Moment.utc(item.stateChangeDateTime)['tz'](this.timezoneIana).unix();
            geofenceState.geofenceId = item.geoFenceId;
            geofenceState.assetId = item.assetId;

            const { icon, iconFontColor, markerColor, description }: { [key: string]: any } = getGeofenceActionResources(item, this.translate);

            geofenceState.id = item.Id;

            geofenceState.icon = icon;

            geofenceState.iconFontColor = iconFontColor;
            geofenceState.markerColor = markerColor;
            geofenceState.description = description;

            geofenceState.duration = item.durationInSeconds || 0;

            geofenceState.iconBackgroundClass = 'grey';
            geofenceState.iconUrl = '';

            returnItem.push(geofenceState);
        });

        return returnItem;
    }

    getMessagesForDevice(deviceId: string, start, end, key, source = 1): Observable<any> {

        console.log('getting messages from service');
        return this.http.get(this.baseUrl + 'Message?deviceId=' + deviceId + '&start=' + start.unix() + '&end=' + end.unix() + '&source=' + source + '&rnd=' + key, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = data;
                return parsedResponse;
            }), catchError(this.handleError));
    }

    parseResponse(json: any): Trip[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' schedules.');

        let ident = json.length;
        const trips: Trip[] = [];

        json.forEach(item => {

            const trip = this.parseTripDetails(item);
            trip.ident = ident;
            trips.push(trip);

            ident--;
        });

        this.timestamp = new Date().getTime();
        return trips;
    }

    parseTripDetails(item) {
        const trip = new Trip();
        trip.id = item.id;
        trip.assetId = item.assetId;
        trip.deviceId = item.deviceId;
        trip.driverId = item.driverId;
        trip.driverName = item.driverName;

        trip.beginDateTime = Moment.utc(item.beginTS)['tz'](this.timezoneIana);

        trip.beginLabel = item.BeginLabel;
        trip.beginCity = item.beginCity;
        trip.beginAddress = item.beginAddress;
        trip.beginAddressFull = item.beginAddressFull;

        trip.beginLatitude = item.beginLatitude;
        trip.beginLongitude = item.beginLongitude;
        trip.beginLocation = L.latLng(item.beginLatitude, item.beginLongitude);

        trip.endDateTime = Moment.utc(item.endTS)['tz'](this.timezoneIana);

        const duration = Moment.duration((new Date(item.endTS).getTime() - new Date(item.beginTS).getTime()), 'millisecond');
        trip.duration = roundSeconds(duration.asSeconds());
        trip.durationHumanized = duration.humanize();

        trip.endLabel = item.EndLabel;
        trip.endCity = item.endCity;
        trip.endAddress = item.endAddress;
        trip.endAddressFull = item.endAddressFull;

        trip.endLatitude = item.endLatitude;
        trip.endLongitude = item.endLongitude;
        trip.endLocation = L.latLng(item.endLatitude, item.endLongitude);

        trip.calibratedOdoValueEnd = this.distance.calculateDistanceUnitFromKmFixed(item.calibratedOdoValueEnd ? item.calibratedOdoValueEnd / 1000 : 0);
        trip.boundingDistanceInMetres = this.distance.calculateDistanceUnitFromKmFixed(item.boundingDistanceInMetres, 2);
        trip.segmentDistanceInKilometers = this.distance.calculateDistanceUnitFromKmFixed(item.segmentDistanceInKilometers, 2);

        trip.isPrivate = item.isPrivate;
        trip.comment = item.comment;
        trip.kmPrivate = item.kmPrivate ?? 0;

        trip.maxSpeed = this.distance.calculateDistanceUnitFromKmFixed(item.maxSpeed, 0);
        trip.isHidden = item.isHidden;
        trip.tripMethod = item.tripMethod;
        trip.tripType = item.tripType;
        trip.source = item.source;

        trip.isStop = item.isStop;
        trip.hasEpisodes = item.hasEpisodes;

        trip.totalConsumptionMixed = item.totalConsumptionMixed;
        trip.totalEmissionCO2 = item.totalEmissionCO2;
        trip.totalEmissionParticlesHeavy = item.totalEmissionParticlesHeavy;
        trip.totalEmissionParticlesLight = item.totalEmissionParticlesLight;


        if (item.isHidden) {
            trip.beginAddress = '-';
            trip.beginAddressFull = '-';
            trip.endAddress = '-';
            trip.endAddressFull = '-';
        } else {
            trip.beginAddress = item.beginAddress;
            trip.beginAddressFull = item.beginAddressFull;
            trip.endAddress = item.endAddress;
            trip.endAddressFull = item.endAddressFull;
            trip.locations = item.locations;
        }

        return trip;
    }

    private handleError(error: Response) {
        return throwError(error);
    }


    // Trip drawing
    drawTrip(trip, ident, colorArray, isOngoing, icon) {
        const locations = trip.messages.sort((a, b) => b.timestamp - a.timestamp);

        const tripFeatureGroup = this.renderFeatureGroup(locations, ident, colorArray, isOngoing, icon);
        return tripFeatureGroup;
    }

    renderFeatureGroup(locations, ident, colorArray, isOngoing, icon): L.FeatureGroup<any> {
        const pointList = [];

        locations.forEach(location => {
            if (location.latitude !== 0) {
                pointList.push(new L.LatLng(location.latitude, location.longitude));
            }
        });

        if (pointList.length == 0) {
            return;
        }

        const color = colorArray[ident % colorArray.length];

        const startIcon = new L['NumberMarker'].Icon({
            backgroundColor: color,
            className: 'm360',
            color: '#fff',
            number: ident,
        });

        const startMarker = L.marker(new L.LatLng(pointList[0].lat, pointList[0].lng), { icon: startIcon });
        // this.leafletMapComponent.oms.addMarker(this.startMarker);

        const finishIcon = L.icon({
            iconUrl: 'assets/images/icons/end.png',
            className: 'markerEnd',
        });

        const iconPath = getIconPath(icon)[1];

        const theAssetIcon = L.icon({
            iconUrl: iconPath,
            // className: 'markerPlayTrip',
            iconAnchor: [16, 16],
        });

        const endMarker = L.marker(new L.LatLng(pointList[pointList.length - 1].lat, pointList[pointList.length - 1].lng), { icon: isOngoing ? theAssetIcon : finishIcon });

        const tripPolyLine = new L.Polyline(pointList, {
            color,
            weight: 2,
            opacity: 1,
            smoothFactor: 1,
            dashArray: '10, 5'
        });

        const tripPolyLine2 = new L.Polyline(pointList, {
            color: '#fff',
            weight: 6,
            opacity: 0.8,
        });

        const tripPolyLine3 = new L.Polyline(pointList, {
            color: '#000',
            weight: 9,
            opacity: 0.3,
        });

        const decoratorLine = L['polylineDecorator'](tripPolyLine, {
            patterns: [
                { offset: 24, repeat: 100, symbol: L['Symbol']['arrowHead']({ pixelSize: 15, pathOptions: { fillOpacity: 0.9, color, weight: 0, stroke: true } }) }
            ]
        });

        return L.featureGroup([startMarker, endMarker, tripPolyLine3, tripPolyLine2, tripPolyLine, decoratorLine]);
    }

    generateChart(trips: any, translateService: TranslateService, leafletMapComponent: LeafletMapComponent, chartService: FhChartService, iconId) {
        console.log('dwaring chart');
        let flattenedLocations = [];

        Object.values(trips).forEach(trip => {
            const locations = Object.values(trip)

            if (locations.length > 3) {
                // Copy last location and set it to offset 0 to reset trip
                const lastLocation = Object.assign({}, locations[locations.length - 1]);
                lastLocation.odometerOffset = 0;
                lastLocation.timestamp = lastLocation.timestamp;
                lastLocation.timestamp = lastLocation.timestamp;
                locations.push(lastLocation);

                flattenedLocations = flattenedLocations.concat(locations);
            }
        });

        console.log('done pushing location');

        return this.generateChartWithLocations(flattenedLocations, translateService, leafletMapComponent, chartService, iconId);
    }

    generateChartWithLocations(flattenedLocations: any, translateService: TranslateService, leafletMapComponent: LeafletMapComponent, chartService: FhChartService, iconId, showLegend = true) {
        const iconPath = getIconPath(iconId)[1];

        const that = this;

        const theIcon = L.icon({
            iconUrl: iconPath,
            // className: 'markerPlayTrip',
            iconAnchor: [16, 16],
        });

        const plotLines = [];
        const plotBands = []

        // The speed gauge
        // data, km, fuel percentage, deviation, symbol
        const theChartDataDistance = [];
        const theChartDataIgnition = [];
        const theChartDataSpeed = [];
        const theChartDataExternalPower = [];
        const theChartDataGpsFix = [];
        const theChartFuelLevel = [];
        const theChartDataWeight = [];
        const theChartDataHumidity = [];
        const theChartDataTemperature = [];
        const theChartDataTemperature2 = [];
        const theChartDataTemperature3 = [];
        const theChartDataTemperature4 = [];
        const theChartDataExternalPowerVoltage = [];

        const theChartDataAnalog1 = [];
        const theChartDataAnalog2 = [];
        const theChartDataAnalog3 = [];
        const theChartDataAnalog4 = [];

        const theChartFuelConsumed = [];

        const startOdometer = flattenedLocations.length && flattenedLocations[0].odometerOffset;
        const startFuelConsumed = (flattenedLocations.length && flattenedLocations[0].fuelConsumed) ?? 0;

        let lastlocation = null;

        const cachedDistanceOffset = flattenedLocations.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))[0]?.odometerOffset;

        $.each(flattenedLocations.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1)), function (index, value) {
            const distance = (value.odometerOffset - cachedDistanceOffset);

            if (distance > 10000000 || distance < 0) {
                return
            }

            const dateTime = Moment.utc(value.timestamp)['tz'](that.timezoneIana).unix() * 1000;
            const coordinate = [value.latitude, value.longitude];

            if (value.latitude !== 0) {
                const theDistance = Math.max(0, distance / 1000);
                const location = { x: dateTime, y: theDistance, suffix: that.translatedKm, latlon: coordinate };
                lastlocation = location;
                theChartDataDistance.push(location);
            }

            theChartDataIgnition.push({ x: dateTime, y: value.ignition ? 1 : 0, latlon: coordinate });
            theChartDataExternalPower.push({ x: dateTime, y: value.externalPower ? 1 : 0, latlon: coordinate });
            theChartDataGpsFix.push({ x: dateTime, y: value.hasGpsFix ? 1 : 0, latlon: coordinate });

            if (value.speed !== undefined) {
                theChartDataSpeed.push({ x: dateTime, y: value.speed, suffix: that.translatedKmh, latlon: coordinate });
            }

            if (value.externalBatteryLevelInVoltage !== undefined) {
                theChartDataExternalPowerVoltage.push({ x: dateTime, y: value.externalBatteryLevelInVoltage, suffix: ' V', latlon: coordinate });
            }

            if (value.fuelLevel !== undefined && (value.fuelLevel !== 0)) {
                theChartFuelLevel.push({ x: dateTime, y: value.fuelLevel, suffix: '%', latlon: coordinate });
            }

            if (value.axleWeightInKg1 !== undefined && (value.axleWeightInKg1 !== 0)) {
                theChartDataWeight.push({ x: dateTime, y: value.axleWeightInKg1, suffix: 'kg', latlon: coordinate });
            }

            if (value.humidityInPercent1 !== undefined && (value.humidityInPercent1 !== 0)) {
                theChartDataHumidity.push({ x: dateTime, y: value.humidityInPercent1, suffix: '%', latlon: coordinate });
            }

            if (value.temperature !== undefined && (value.temperature !== 0)) {
                theChartDataTemperature.push({ x: dateTime, y: value.temperature, suffix: '°C', latlon: coordinate });
            }

            if (value.temperature2 !== undefined && (value.temperature2 !== 0)) {
                theChartDataTemperature2.push({ x: dateTime, y: value.temperature2, suffix: '°C', latlon: coordinate });
            }

            if (value.temperature3 !== undefined && (value.temperature3 !== 0)) {
                theChartDataTemperature3.push({ x: dateTime, y: value.temperature3, suffix: '°C', latlon: coordinate });
            }

            if (value.temperature4 !== undefined && (value.temperature4 !== 0)) {
                theChartDataTemperature4.push({ x: dateTime, y: value.temperature4, suffix: '°C', latlon: coordinate });
            }

            if (value.analogInput1 !== undefined && (value.analogInput1 !== 0)) {
                theChartDataAnalog1.push({ x: dateTime, y: value.analogInput1, suffix: ' V', latlon: coordinate });
            }

            if (value.analogInput2 !== undefined && (value.analogInput2 !== 0)) {
                theChartDataAnalog2.push({ x: dateTime, y: value.analogInput2, suffix: ' V', latlon: coordinate });
            }

            if (value.analogInput3 !== undefined && (value.analogInput3 !== 0)) {
                theChartDataAnalog3.push({ x: dateTime, y: value.analogInput3, suffix: ' V', latlon: coordinate });
            }

            if (value.analogInput4 !== undefined && (value.analogInput4 !== 0)) {
                theChartDataAnalog4.push({ x: dateTime, y: value.analogInput4, suffix: ' V', latlon: coordinate });
            }

            if (value.fuelConsumed !== undefined && (value.fuelConsumed !== 0)) {
                theChartFuelConsumed.push({ x: dateTime, y: (value.fuelConsumed - startFuelConsumed), suffix: 'L', latlon: coordinate });
            }
        });

        let theData = [];

        theData = [{
            name: translateService.instant('general.distance'),
            type: 'area',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 0.6,
            color: '#ccc',
            zIndex: 5,
            yAxis: 1,
            data: theChartDataDistance
        }, {
            name: translateService.instant('general.speed'),
            type: 'spline',
            color: '#5AB867',
            yAxis: 0,
            marker: {
                enabled: false,
                lineWidth: 2,
                symbol: 'square'
            },
            zIndex: 5,
            data: theChartDataSpeed
        }];

        if (theChartDataIgnition.some(x => x.y)) {
            theData.push(
                {
                    name: 'Ignition',
                    type: 'line',
                    color: '#00E0C6',
                    visible: false,
                    step: 'left',
                    marker: {
                        enabled: false,
                        lineWidth: 2,
                        symbol: 'square'
                    },
                    yAxis: 3,
                    zIndex: 3,
                    data: theChartDataIgnition
                });
        };

        if (theChartDataExternalPower.some(x => x.y)) {
            theData.push({
                name: 'ExternalPower',
                type: 'line',
                color: '#00E0C6',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 4,
                zIndex: 3,
                data: theChartDataExternalPower
            });
        };

        if (theChartDataExternalPowerVoltage.some(x => x.y)) {
            theData.push({
                name: 'Voltage',
                type: 'line',
                color: '#00E0C6',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 6,
                zIndex: 3,
                data: theChartDataExternalPowerVoltage
            });
        };

        if (theChartDataAnalog1.some(x => x.y)) {
            theData.push({
                name: 'Analog1',
                type: 'line',
                dashStyle: 'ShortDashDot',
                color: '#00E0C6',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 6,
                zIndex: 3,
                data: theChartDataAnalog1
            });
        };

        if (theChartDataAnalog2.some(x => x.y)) {
            theData.push({
                name: 'Analog2',
                type: 'line',
                dashStyle: 'ShortDashDot',
                color: '#00E0C6',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 6,
                zIndex: 3,
                data: theChartDataAnalog2
            });
        };

        if (theChartDataGpsFix.some(x => x.y == false)) {
            theData.push({
                name: translateService.instant('chart.GPSFix'),
                type: 'line',
                color: '#1A4467',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 5,
                zIndex: 3,
                data: theChartDataGpsFix
            });
        };

        if (theChartFuelLevel.some(x => x.y)) {
            theData.push({
                name: 'FuelLevel',
                type: 'spline',
                color: '#7589FF',
                dashStyle: 'ShortDot',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 2,
                zIndex: 3,
                data: theChartFuelLevel
            });
        };

        if (theChartFuelConsumed.some(x => x.y)) {
            theData.push({
                name: 'Fuel Consumed',
                type: 'spline',
                color: '#A982FF',
                dashStyle: 'LongDash',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 12,
                zIndex: 3,
                data: theChartFuelConsumed
            });
        };

        if (theChartDataTemperature.some(x => x.y)) {
            theData.push({
                name: 'Temperature',
                type: 'areaspline',
                color: '#0000ff',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 7,
                zIndex: 3,
                data: theChartDataTemperature
            });
        };

        if (theChartDataTemperature2.some(x => x.y)) {
            theData.push({
                name: 'Temperature2',
                type: 'areaspline',
                color: '#0000ff',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 7,
                zIndex: 3,
                data: theChartDataTemperature2
            });
        };

        if (theChartDataTemperature3.some(x => x.y)) {
            theData.push({
                name: 'Temperature3',
                type: 'areaspline',
                color: '#0000ff',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 7,
                zIndex: 3,
                data: theChartDataTemperature3
            });
        };

        if (theChartDataTemperature4.some(x => x.y)) {
            theData.push({
                name: 'Temperature4',
                type: 'areaspline',
                color: '#0000ff',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 7,
                zIndex: 3,
                data: theChartDataTemperature4
            });
        };

        if (theChartDataWeight.some(x => x.y)) {
            theData.push({
                name: 'Weight',
                type: 'areaspline',
                color: '#FF0015',
                dashStyle: 'Dash',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 9,
                zIndex: 3,
                data: theChartDataWeight
            });
        };

        if (theChartDataHumidity.some(x => x.y)) {
            theData.push({
                name: 'Humidity',
                type: 'areaspline',
                color: '#FF0090',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 8,
                zIndex: 3,
                data: theChartDataHumidity
            });
        };

        // theData = this.chartService.simplifyPathDouglasPecker(theData, 1);

        const speedChart = chartService.generateMapChart(theData, plotLines, plotBands, leafletMapComponent, theIcon, null, true, showLegend);
        return speedChart;
    }
}
