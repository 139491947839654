import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import exportFromJSON from 'export-from-json'
import { roundAsNumber, roundSeconds } from 'app/common/globals';
import { ReportService } from 'app/services/reports/report.service';
import { FhChartService } from 'app/services/charts/charts.service';
import { ReportDisplayDetailsComponent } from './reportDisplay.component';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from '../shared/usercontrols/confirmationModal.component';
import { forkJoin, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/internal/operators/first';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { ReportTemplate } from 'app/models/reporting.model';

window['moment'] = Moment;
mTZ()

@Component({
    selector: 'fh-reporting--shared-details',
    templateUrl: 'reportingSharedDetails.template.html',
    providers: [FhChartService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingSharedDetailsViewComponent implements OnInit {
    @ViewChild('deleteModal') deleteModal: ConfirmationModalComponent;
    @ViewChild(ReportDisplayDetailsComponent, { static: false }) reportDisplayComponent: ReportDisplayDetailsComponent;

    @ViewChild('inputWrapper') inputWrapper;

    copyCompleted = false;
    copyTimeout;

    reportTemplates = [];

    loading: boolean;
    loadingDetails: boolean;
    sub: any;

    activeReport;
    showJson = false;
    showColumnSelector = false;

    columnSuccess;

    success;
    error;

    filter;

    selectedReportTemplateId;

    // Pagination
    currentPage = 1;

    timezoneIana: string;
    reportData: any;

    template: ReportTemplate = new ReportTemplate();

    defaultSourceColumns = [];
    permissions: {};

    reportDataCollected = new ReplaySubject<boolean>();

    userId: number;
    activeReportJson: any;

    constructor(private router: Router, private cd: ChangeDetectorRef, private translate: TranslateService, private authenticationService: AuthenticationService, private route: ActivatedRoute, private reportService: ReportService) {
        this.timezoneIana = this.authenticationService.getTimeZoneIana();
        this.permissions = this.authenticationService.permissions;
    }

    actualRound(value, decimals) {
        return roundAsNumber(value, decimals);
    }

    actualRoundSeconds(value) {
        return roundSeconds(value);
    }

    ngOnInit() {
        this.loading = true;
        this.loadingDetails = true;
        this.template = new ReportTemplate();

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.reportService.getReportById(id, true).subscribe(res => {

                if (res == null) {
                    this.loadingDetails = false;
                    this.loading = false;

                    this.success = null;
                    this.error = {
                        statusText: 'Error',
                        error: 'Unable to fetch report'
                    };

                    this.loadingDetails = false;
                    this.cd.markForCheck();
                    return;
                }

                this.activeReport = res;

                this.activeReport.periodStart = Moment.utc(this.activeReport.periodStart)['tz'](this.timezoneIana);
                this.activeReport.periodEnd = Moment.utc(this.activeReport.periodEnd)['tz'](this.timezoneIana);

                this.activeReport.executedTimestamp = Moment.utc(this.activeReport.executedTimestamp)['tz'](this.timezoneIana);

                this.activeReport.ReportType = this.activeReport?.selectedReportType;
                this.activeReport.ReportName = this.activeReport.name;

                this.activeReport.selectedReportType = this.translate.instant('enums.reportType.' + this.activeReport?.selectedReportType);

                this.activeReport.timestamp = Moment.utc(this.activeReport.timestamp)['tz'](this.timezoneIana);

                this.template = this.activeReport.template;
                if (!this.template.orientation) {
                    this.template.orientation = 0;
                }

                this.activeReport.reportData = [];
                this.activeReportJson = { ...this.activeReport };

                if (this.activeReport && this.activeReport.isSuccessful) {
                    this.loading = false;
                    this.loadingDetails = true;
                    this.cd.markForCheck();

                    this.reportService.getReportDetailsById(id, this.activeReport.accountIdentifier, true).subscribe(details => {

                        this.activeReport.reportData = details.reportData;
                        this.reportData = this.activeReport.reportData;
                        this.loadingDetails = false;

                        this.cd.markForCheck();

                        // Check if we need to download
                        this.sub = this.route.queryParams.subscribe(queryParams => {
                            const { downloadPdf, downloadExcel } = queryParams;

                            switch ('true') {
                                case downloadPdf:
                                    setTimeout(() => {
                                        console.log('downloading pdf');
                                        this.reportDisplayComponent.downloadPdf(true, true);
                                    }, 100);
                                    break;
                                case downloadExcel:
                                    setTimeout(() => {
                                        console.log('downloading excel');
                                        this.reportDisplayComponent.downloadXls(false);
                                    }, 100);
                                    break;
                                default:
                                    break;
                            }

                        });
                    }, error => {
                        this.success = null;
                        this.error = error;
                        this.loadingDetails = false;
                        this.cd.markForCheck();
                    });
                } else {
                    this.loadingDetails = false;
                    this.loading = false;

                    this.success = null;
                    this.error = {
                        statusText: 'Error',
                        error: 'Error loading report'
                    };

                    this.cd.markForCheck();
                }
            });
        }, error => {
            this.success = null;
            this.error = error;
            this.loadingDetails = false;
            this.loading = false;

            this.cd.markForCheck();
        });
    }


    format(template) {
        console.log('Formatting data');
        if (this.activeReport.template) {
            this.activeReport.template.name = template.name;
        }
        this.reportDisplayComponent.formatData(template ?? this.template);
    }

    async downloadPdf(includeCharts = true, includeData = true, includeMaps = false) {
        this.loading = true;
        const that = this;
        setTimeout(async () => {
            try {
                await this.reportDisplayComponent.downloadPdf(includeCharts, includeData, includeMaps).then(function () {
                    that.loading = false;
                    that.cd.markForCheck();
                });
            } catch (error) {
                this.error = error;
                this.loading = false;
                this.cd.markForCheck();
            }
        }, 10);
    }

    async downloadXls(formatXlsExport) {
        this.loading = true;
        this.cd.markForCheck();

        setTimeout(() => {
            this.reportDisplayComponent.downloadXls(false, formatXlsExport);

            this.loading = false;
            this.cd.markForCheck();
        }, 10);
    }

    downloadCsv() {
        this.loading = true;
        this.cd.markForCheck();

        const that = this;

        setTimeout(() => {
            try {
                const result = this.reportDisplayComponent.downloadXls(true);
                this.loading = false;
                this.cd.markForCheck();
            } catch (error) {
                this.error = error;
                this.loading = false;
                this.cd.markForCheck();
            }
        }, 10);
    }

    downloadJson() {
        exportFromJSON({
            data: this.reportData,
            fileName: 'reportdata',
            exportType: exportFromJSON.types.json,
            beforeTableEncode: rows => rows.sort((p, c) => p['Data']?.Name.localeCompare(c['Data']?.Name)),
        })
    }

    textToClipboard(text) {
        const value = text ? text : $(this.inputWrapper.nativeElement.getElementsByTagName('input'))?.val();

        if (!value) {
            return;
        }

        clearTimeout(this.copyTimeout);

        const input = document.createElement('textarea');
        input.innerHTML = value;
        input.value = String(value)?.trim();
        input.setAttribute('readonly', '');
        input.style.position = 'absolute';
        input.style.left = '-9999px';
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, 9999999);
        document.execCommand('copy');
        document.body.removeChild(input);
        this.copyCompleted = true;
        this.copyTimeout = setTimeout(() => { this.copyCompleted = false }, 2000);
    }
}
