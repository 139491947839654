import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { colorArray2, roundAsString } from 'app/common/globals';
import { FhChartService } from 'app/services/charts/charts.service';
import { FleetOverviewStoreService } from 'app/services/fleetoverview/fleetoverview-store.service';
import { TripService } from 'app/services/trip/trip.service';

import * as Moment from 'moment';

import * as Highcharts from 'highcharts';
import { MapService } from 'app/services/common/map.service';

@Component({
    selector: 'fh-overview-navigation-history',
    providers: [FhChartService],
    templateUrl: 'overviewNavigationHistory.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetOverviewNavigationHistoryComponent implements OnInit, OnChanges {
    Highcharts: typeof Highcharts = Highcharts;

    @Input() asset: KeyValue<any, any>;

    @Input() openTrip: (x: any, y: number) => void;
    @Input() openStop: (x: any, y: number, z: any) => void;
    @Input() changeTripVisibility: (x: any, y: number, z?: boolean) => void;
    @Input() displayLocation: (asset: any, x: any, y?: any, z?: any) => void;
    @Input() playTrip: (x: any) => void;

    @Input() driversMap = new Map<number, string>();

    currentDate: number;
    colorArray = colorArray2;

    isPlayingTrip = false;
    displayChart = false;

    constructor(
        private cd: ChangeDetectorRef,
        private fleetOverviewStoreService: FleetOverviewStoreService,
        private translateService: TranslateService,
        private chartService: FhChartService,
        private mapService: MapService,
        private tripService: TripService) {
    }

    ngOnChanges(): void {
    }

    ngOnInit(): void {
        this.fleetOverviewStoreService.isTripPlaying$.subscribe(_isPlayingTrip => {
            this.isPlayingTrip = _isPlayingTrip;
            this.cd.detectChanges();
        });
    }

    flipChart(trip) {
        if (!this.displayChart) {
            // Generate chart
            trip.speedChart = this.tripService.generateChartWithLocations(trip.locations, this.translateService, this.mapService.leafletMapComponent, this.chartService, this.asset?.value?.iconId, false)
        }

        trip.displayChart = !trip.displayChart;
        this.cd.detectChanges();
    }

    actualRound(value, decimals) {
        return roundAsString(value, decimals);
    }

    renderTrips(trips: any[]) {
        this.currentDate = null;

        return trips;
    }

    isNewDate(trip) {
        const checkDate = Moment(trip.beginDateTime).date();
        if (checkDate === this.currentDate) {
            return false;
        } else {
            this.currentDate = checkDate;
            return true;
        }
    }

    openMultipleTrips(trips: any[], beginDateTime: string, newVisibility) {
        const date = Moment(beginDateTime).date();

        const filteredTrips = trips.filter(x => Moment(x.beginDateTime).date() === date);

        for (let index = 0; index < filteredTrips.length; index++) {
            const trip = filteredTrips[index];

            if (trip.ident === 0) {
                continue;
            }

            this.changeTripVisibility(trip, trip.ident, newVisibility);
        }

        this.cd.detectChanges();
    }

    isShowingMultiple(trips: any[], beginDateTime: string) {
        const date = Moment(beginDateTime).date();

        const filteredTrips = trips.filter(x => Moment(x.beginDateTime).date() === date);

        return !filteredTrips.every(x => x.plotted !== true);
    }

    stopTrip() {
        this.fleetOverviewStoreService.isTripPlaying.next(false);
    }

    get isTripPlaying() {
        return this.fleetOverviewStoreService.isTripPlaying$;
    }
}
