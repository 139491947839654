import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { getGridButtons, getGridLanguages, createSearch, setTableStars, setSelection, setSelectionRows, createdCellCheckbox, getDefaultDpConfig } from 'app/common/gridhelper';

import { AuthenticationService } from '../../services/authentication/authentication.service';

import { forkJoin } from 'rxjs';
import { EntityType } from 'app/common/enums';

import { ActivatedRoute } from '@angular/router';
import { GridBase360Directive } from 'app/common/360Grid.base';

// Moment timezone
import { StorageHelper } from 'app/common/storagehelper';
import { roundAsNumber, roundAsString } from 'app/common/globals';
import { TripService } from 'app/services/trip/trip.service';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { DistanceUnitService } from 'app/common/distanceunit.service';
import { ColorService } from 'app/services/common/color.service';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-trips-overview',
  templateUrl: 'tripsOverview.template.html'
})
export class DevicesTripsOverViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  token: string;

  excludingColumns = ['id', 'id_export', 'isActive'];

  timezoneIana: string;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  permissionName = 'FleetManagement_Trips';
  constructorName = 'TripsOverviewViewComponent';

  error: any;
  success: any;
  warning: any;

  enableSelecting = true;

  entityTypes = EntityType.Geofence;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  selectedAccountId: any;
  selectedResellerId;
  orderBy: any;
  start: any;
  end: any;
  accountId: any;

  constructor(private route: ActivatedRoute, private translateService: TranslateService, private distance: DistanceUnitService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private tripService: TripService, protected storageHelper: StorageHelper, private colorService: ColorService) {
    super(storageHelper);

    this.permissions = this.authenticationService.permissions;

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(2, 'days').startOf('day').toDate(),
      Moment().tz(this.timezoneIana).endOf('day').toDate()
    ];

    this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

    // Get all the date for dropdown boxes
    forkJoin(
      this.translateService.get('general.date')
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      err => console.error(err)
    );
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true;
      this.initGrid();
    });

    this.route.queryParams.subscribe(params => {
      this.orderBy = params['orderBy'];
      this.start = params['start'];
      this.end = params['end'];
      this.accountId = params['accountId'];

      if (this.start && this.end) {
        this.daterangepickerModel = [
          Moment(this.start).tz(this.timezoneIana).startOf('day').toDate(),
          Moment(this.end).tz(this.timezoneIana).endOf('day').toDate()
        ];

        console.log(this.daterangepickerModel);
      }

      if (this.accountId) {
        this.selectedAccountId = this.accountId;
      }
    });
  }


  resellerChanged(event) {
    this.selectedAccountId = null;
    this.accountChanged(null);
  }

  accountChanged(accountId) {
    this.selectedAccountId = accountId;
    
    this.error = null;
    this.warning = null;

    if (accountId) {
        this.dateChanged(true);
    }
  }

  // Check route to apply filters
  checkRoute() {
    this.route.queryParams.subscribe(params => {
      const companyName = params['companyName'];
      const resellerDescription = params['resellerDescription'];

      const orderBy = params['orderBy'];
      let requireDraw = false;

      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

        if (orderBy == 'maxSpeed') {
          dtInstance.column('maxSpeed:name').order('desc');
        }

        if (companyName) {
          dtInstance.column('resellerDescription:name').search('');
          dtInstance.column('companyName:name').search(companyName);
          dtInstance.column('name:name').search('');
          requireDraw = true;
        }

        if (resellerDescription) {
          dtInstance.column('resellerDescription:name').search(resellerDescription);
          dtInstance.column('companyName:name').search('');
          dtInstance.column('name:name').search('');
          requireDraw = true;
        }
        // Fire the request
        if (requireDraw) {
          dtInstance.draw();
        }
      });
    });
  }

  dateChanged(event) {
    console.log('Date changed');

    const that = this;
    if (event !== null) {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.url(that.tripService.getPagingUrl(this.selectedAccountId, Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'))).load();
      });
    }
  }

  cancel() {
    // this.mySubscription.unsubscribe();

    this.loading = false;

    this.warning = {};
    this.warning.warning = 'Call was cancelled.'
    this.warning.statusText = 'Warning';
  }


  initGrid(): void {
    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    const excludedColumns = ['id', 'id_export', 'isSelected', 'form'];
    let selectorVisible = true;

    if (!this.permissions['FleetManagement_Actions']) {
      selectorVisible = false;
    }

    this.columns = [{
      name: 'id',
      data: 'id',
      className: 'noVis',
      orderable: false,
      title: '<div class="hideDropdown"></div>',
      width: '20',
      render: function (data, type, row) {
        return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/DeviceDetails/Trips/' + row.deviceId + '?tripId=' + data + '\'><span class="d-none d-md-inline-flex" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
      }
    }, {
      name: 'id_export',
      data: 'id',
      className: 'noVis',
      title: this.translateService.instant('general.id'),
      visible: false,
    },
    {
      name: 'assetName',
      data: 'assetName',
      defaultContent: '-',
      title: this.translateService.instant('general.assetName')
    },
    {
      name: 'beginTS',
      data: 'beginTS',
      defaultContent: '-',
      title: this.translateService.instant('general.start'),
      render: function (data, type, row) {
        const date = Moment.utc(data)['tz'](that.timezoneIana);
        return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '-';
      },
    },
    {
      name: 'endTS',
      data: 'endTS',
      defaultContent: '-',
      title: this.translateService.instant('general.end'),
      render: function (data, type, row) {
        const date = Moment.utc(data)['tz'](that.timezoneIana);
        return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '-';
      },
    },
    {
      name: 'segmentDistanceInKilometers',
      data: 'segmentDistanceInKilometers',
      defaultContent: '-',
      title: this.translateService.instant('general.distance'),
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 2) + ' ' + that.translateService.instant(that.distance.getDistanceUnit()) : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'calibratedOdoValueEnd',
      data: 'calibratedOdoValueEnd',
      defaultContent: '-',
      title: this.translateService.instant('general.odo'),
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString((data / 1000), 0) + ' ' + that.translateService.instant(that.distance.getDistanceUnit()) : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'maxSpeed',
      data: 'maxSpeed',
      defaultContent: '-',
      title: this.translateService.instant('general.maxSpeed'),
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '  ' + that.translateService.instant(that.distance.getDistanceUnitPerHour()) : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'beginAddressFull',
      data: 'beginAddressFull',
      defaultContent: '-',
      title: this.translateService.instant('general.start')
    }, {
      name: 'endAddressFull',
      data: 'endAddressFull',
      defaultContent: '-',
      title: this.translateService.instant('general.end')
    }, {
      name: 'accountId',
      data: 'accountId',
      defaultContent: '-',
      title: this.translateService.instant('general.accountId'),
      visible: false
    },
    {
      name: 'resellerDescription',
      data: 'resellerDescription',
      defaultContent: '-',
      title: this.translateService.instant('general.resellerDescription'),
      visible: false
    }];

    this.commonExportOptions = {
      // orthogonal: 'export',
      // format: {
      //   body: function (data, row, column, node) {
      //     return data;
      //   }
      // },
      modifier: {
        page: 'all',
        search: 'none'
      },
      columns: ['id_export:name', 'accountId:name', ':visible[tabindex]']
    };

    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'geofence_overview', this.translateService.instant('menu.geofenceoverview'), this.colorService),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 2 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => {
          try {
            const columnSettings = await that.loadState(that.constructorName);
            that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            that.error = {};
            that.error.error = e;
            that.error.statusText = 'Error fetching column settings';

            return null;
          }
        })().then(result => {
          callback(result);
        });
      },
      order: [[that.orderBy == 'maxSpeed' ? 6 : 3, 'desc']],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.tripService.getPagingUrl(this.selectedAccountId, Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')),
        data: (d) => {
          return d;
        },
        dataSrc: function (json) {
          return json.data;
        },
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        that.checkRoute();
        that.checkFilters();
        that.setEvents();
        that.drawFilterRow();
        $('#grid_select_or').appendTo($('th > #selectorHeader'));
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: excludedColumns
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
    };
  }
}
