import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { getGridButtons, getGridLanguages, createSearch, createdCellCheckbox } from 'app/common/gridhelper';

import { AuthenticationService } from '../../../services/authentication/authentication.service';

import { Observable, forkJoin } from 'rxjs';
import { MaintenanceTypesService } from 'app/services/maintenance/maintenanceTypes.service';
import { GridBase360Directive } from 'app/common/360Grid.base';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { StorageHelper } from 'app/common/storagehelper';
import { ColorService } from 'app/services/common/color.service';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-maintenance-types',
  templateUrl: 'types.template.html'
})
export class MaintenanceTypesViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  token: string;

  showActiveOnly = true;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  permissionName = 'MaintenanceTypes_View'
  constructorName = 'MaintenanceTypesViewComponent';
  timezoneIana: string;

  error;

  constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private maintenanceTypesService: MaintenanceTypesService, protected storageHelper: StorageHelper, private colorService: ColorService) {
    super(storageHelper);

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    // Get all the date for dropdown boxes
    forkJoin(
      this.translateService.get('general.date')
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      err => console.error(err)
    );
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log('Fire update');
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

        if (this.showActiveOnly === true) {
          dtInstance.column('isActive:name').search('true');
        } else {
          dtInstance.column('isActive:name').search('@ignore');
        }

        // Fire the request
        dtInstance.draw();
      });
    }
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  // Check custom filters from grid save
  checkFilters() {

    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      this.showActiveOnly = dtInstance.column('isActive:name').search() !== '@ignore';
    });

    this.fireFilter(1);
  }

  initGrid(): void {
    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: 'id',
        data: 'id',
        className: 'noVis',
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: '20',
        render: function (data, type, row) {
          return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/Maintenances/MaintenanceTypeDetails/Index/' + data + '\'><span class="d-none d-md-inline-flex" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
        }
      }, {
        name: 'id_export',
        data: 'id',
        className: 'noVis',
        title: this.translateService.instant('general.id'),
        visible: false,
      }, {
        name: 'typeName',
        data: 'typeName',
        defaultContent: '-',
        title: this.translateService.instant('general.typeName')
      }, {
        name: 'assetTypes',
        data: 'assetTypes',
        defaultContent: '-',
        title: this.translateService.instant('general.assetTypes')
      }, {
        name: 'occurrenceCount',
        data: 'occurrenceCount',
        defaultContent: '-',
        title: this.translateService.instant('general.occurrenceCount')
      }, {
        name: 'assetCount',
        data: 'assetCount',
        defaultContent: '-',
        width: '40',
        type: 'num',
        title: this.translateService.instant('general.assetCount')
      }, {
        name: 'daysThreshold',
        data: 'daysThreshold',
        title: this.translateService.instant('general.daysThreshold')
      }, {
        name: 'kilometersThreshold',
        data: 'kilometersThreshold',
        title: this.translateService.instant('general.kilometersThreshold')
      }, {
        name: 'companyName',
        data: 'companyName',
        defaultContent: '-',
        title: this.translateService.instant('general.companyName')
      }, {
        name: 'accountId',
        data: 'accountId',
        defaultContent: '-',
        title: this.translateService.instant('general.accountId'),
        visible: false
      }, {
        name: 'resellerDescription',
        data: 'resellerDescription',
        defaultContent: '-',
        title: this.translateService.instant('general.resellerDescription')
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'maintenanceTypes_overview', this.translateService.instant('menu.deviceoverview'), this.colorService),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => { try {
          const columnSettings = await that.loadState(that.constructorName);
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          that.error = {};
          that.error.error = e;
          that.error.statusText = 'Error fetching column settings';

          return null;
        }})().then(result => {
          callback(result);
        });
      },
      order: [[2, 'asc']],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.maintenanceTypesService.getPagingUrl(),
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        console.log('init complete');
        that.checkFilters();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
       exclude: ['id', 'id_export']
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
    };
  }
}
