<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Dashboard">{{'general.dashboard' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.costs' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-dashboard-navigation> </fh-dashboard-navigation>
</div>


<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class='col-lg-12'>
            <fh-error [error]="error" [success]="success" [warning]="warning"></fh-error>

            <!-- Custom filters -->
            <div class="ibox" [hidden]="!showFilter">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content slick_toppadding flex-container" style="position: relative">
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px;">
                            <fh-reseller-input name="reseller" #resellerVar="ngModel"
                                (onChanged)="resellerChanged($event)" required [tableInline]="false"
                                placeholder="{{ 'general.chooseReseller' | translate}}"
                                [(ngModel)]="selectedResellerId">
                            </fh-reseller-input>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px;">
                            <fh-account-input name="account" #accountVar="ngModel" (onChanged)="accountChanged($event)"
                                required [activeOnly]=true [selectedResellerId]="selectedResellerId"
                                [tableInline]="false" [showEmptyAccountsButton]="false"
                                placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                [(ngModel)]="selectedAccountId">
                            </fh-account-input>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-prepend">
                                <i class="fa fa-fw fa-calendar"></i>
                            </span>
                            <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker" type="text"
                                [bsConfig]="dpConfig" class="form-control" bsDaterangepicker
                                (onHidden)="dateChanged('hidden')" [(ngModel)]="daterangepickerModel">
                            <span class="input-group-calendar-datatables">
                                <i class="fa fa-calendar-alt"></i>
                            </span>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group input-group" style="margin-bottom: 6px;">
                            <span class="input-group-prepend" title="Yearly Deprecation">
                                <i class="fa fa-fw fa-percent"></i>
                            </span>
                            <input name="yearlyDeprecation" id="yearlyDeprecation" style="width:100%"
                                [(ngModel)]="yearlyDeprecation" (ngModelChange)="dateChanged('hidden')"
                                placeholder="{{'general.yearlyDeprecation' | translate }}" class="form-control">
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group input-group" style="margin-bottom: 6px;">
                            <span class="input-group-prepend" title="Fuel cost petrol">
                                <i class="fa fa-fw fa-gas-pump"></i>
                            </span>
                            <input name="fuelCostPetrol" id="fuelCostPetrol" style="width:100%"
                                (ngModelChange)="dateChanged('hidden')" [(ngModel)]="fuelCostPetrol"
                                placeholder="{{'general.fuelCostPetrol' | translate }}" class="form-control">
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group input-group" style="margin-bottom: 6px;">
                            <span class="input-group-prepend" title="Fuel cost diesel">
                                <i class="fa fa-fw fa-gas-pump"></i>
                            </span>
                            <input name="fuelCostDiesel" id="fuelCostDiesel" style="width:100%"
                                (ngModelChange)="dateChanged('hidden')" [(ngModel)]="fuelCostDiesel"
                                placeholder="{{'general.fuelCostDiesel' | translate }}" class="form-control">
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group input-group" style="margin-bottom: 6px;">
                            <span class="input-group-prepend" title="Fuel cost electricity">
                                <i class="fa fa-fw fa-bolt"></i>
                            </span>
                            <input name="fuelCostElectricity" id="fuelCostElectricity" style="width:100%"
                                [(ngModel)]="fuelCostElectricity" (ngModelChange)="dateChanged('hidden')"
                                placeholder="{{'general.fuelCostElectricity' | translate }}" class="form-control">
                        </div>
                    </div>
                    <div class="ibox-content" style="position: relative; display:none">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div id="dynamicFilters" class="flex-container"></div>
                        <div style="clear:both"></div>
                    </div>
                </div>
                <!-- End default dynamic filters -->
                <div class="ibox-footer">
                    <button *ngIf="!loading" type="button" name="search" class="btn btn-primary"
                        (click)="dateChanged('hidden')" [disabled]="selectedAccountId == null">{{ 'general.fetchData' |
                        translate}}</button>

                    <button *ngIf="loading" type="button" name="cancel" class="btn btn-info" (click)="cancel()">
                        <i class="fas fa-fw fa-times"></i> {{ 'general.cancel' | translate}}</button>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-dollar"></i> {{'general.costPerAsset' | translate }}
                    </h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartDistanceCompare">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <highcharts-chart *ngIf="chartDistanceCompare" [runOutsideAngular]="true"
                        style="display: block; width: 100%; height:178px" [Highcharts]="Highcharts"
                        [options]="chartDistanceCompare"></highcharts-chart>

                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-dollar"></i> {{'general.costPerType' | translate }}
                    </h5>
                    <span class="float-right">
                        <ibox-chart-export [chartObject]="chartCostPerType">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <highcharts-chart *ngIf="chartCostPerType" [runOutsideAngular]="true"
                        style="display: block; width: 100%; height:178px" [Highcharts]="Highcharts"
                        [options]="chartCostPerType"></highcharts-chart>

                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-sack-dollar"></i> {{ 'general.costs' | translate }}</h5>
                    <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)"
                        [constructorName]="constructorName" (filterChanged)="showFilter = $event"
                        (presetLoaded)="onPresetLoaded($event)" (columnsChanged)="drawFilterRow()"></iboxseach>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <table style="width:100%; min-height:50px;" fh-datatable [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger" class="table  nowrap margin-bottom-0 dataTable dtr-inline">
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>