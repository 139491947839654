<div *ngIf="isModalShown" [config]="{ show: true, backdrop: true, ignoreBackdropClick: true }" (onHidden)="onHidden()"
    bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left"><i class="fas fa-gavel"></i> {{'general.projects' | translate }}
                </h5>
                <button type="button" class="close float-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table style="width:100%; margin-bottom: 0;" class="table nowrap dtr-inline no-footer">
                    <tbody>
                        <tr>
                            <td class="row_opening" style="vertical-align: middle;">
                                {{ 'general.asset' | translate}}
                            </td>
                            <td class="row_content" colspan="2" style="overflow:visible">
                                {{ device?.asset?.name }}
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening" style="vertical-align: middle;">
                                {{ 'general.project' | translate}}
                                <span class="error" *ngIf=" projectSelector?.invalid">*</span>
                            </td>
                            <td class="row_content" colspan="2" style="overflow:visible">
                                <fh-project-input name="project" #projectSelector="ngModel"
                                    [selectedAccountId]="device.asset.accountId" required
                                    placeholder="{{ (device.asset.accountId ? 'general.chooseProject' : 'general.firstChooseAccount') | translate}}"
                                    [(ngModel)]="selected.id">
                                </fh-project-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening" style="vertical-align: middle;">
                                <div class="fa_checkbox">
                                    <input [checked]="true" [disabled]="true" id="checkbox_1" type="checkbox"
                                        name="checkbox_1" />
                                    <label for="checkbox_1">
                                        {{ 'general.from' | translate}}
                                    </label>
                                </div>
                            </td>
                            <td colspan="2" class="row_content">
                                <input [(ngModel)]="selected.from" name="selected.from" #from="ngModel" type="text"
                                    autocomplete="off" class="form-control table-inline" [bsConfig]="dpConfig"
                                    bsDatepicker>
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening" style="vertical-align: middle;">
                                <div class="fa_checkbox">
                                    <input id="checkbox_2" #checkbox_2="ngModel" type="checkbox" [(ngModel)]="toEnabled"
                                        name="checkbox_2" />
                                    <label for="checkbox_2">
                                        {{ 'general.to' | translate}}
                                    </label>
                                </div>
                            </td>
                            <td class="row_content">
                                <input [(ngModel)]="selected.to" name="to" #to="ngModel" [required]="checkbox_2.value"
                                    type="text" autocomplete="off" class="form-control table-inline"
                                    [bsConfig]="dpConfig" (onHidden)="onToDate('hidden')" bsDatepicker>
                            </td>
                            <td style="width: 20px; padding: 6px;">
                                <i *ngIf="selected.to" (click)="resetToDate()" title="{{'general.clear' | translate }}"
                                    style="display: flex; align-items: center; padding-top: 10px;"
                                    class="fas fa-fw fa-times"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" [disabled]="!selected.from || to.invalid || !selected.id"
                    (click)="finish()"><i class="fas fa-fw fa-check"></i>
                    {{'general.assign' | translate }}</button>

                <button class="btn btn-danger" (click)="close()"><i class="fas fa-fw fa-times"></i>
                    {{'general.cancel' | translate }}</button>
            </div>
        </div>
    </div>
</div>