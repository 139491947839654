import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getGridButtons, getGridLanguages, createSearch, setTableStars, setSelection, setSelectionRows, getDefaultDpConfig, createdCellCheckbox } from 'app/common/gridhelper';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { GridBase360Directive } from 'app/common/360Grid.base';
import { SignalrService } from 'app/services/signalr/signalr.service';
import { ColorService } from "app/services/common/color.service";
import { ActivatedRoute } from '@angular/router';

import { ReportService } from 'app/services/reports/report.service';

import { StorageHelper } from 'app/common/storagehelper';

import { DashboardService } from 'app/services/dashboard/dashboard.service';
import { slideInOut } from 'app/common/animations';
import { FhChartService } from 'app/services/charts/charts.service';
import Highcharts from 'highcharts';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';


window['moment'] = Moment;
mTZ();
@Component({
  selector: 'fh-reporting-dashboard',
  templateUrl: 'dashboard.template.html',
  providers: [FhChartService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    slideInOut
  ]
})
export class ReportingDashboardComponent {
  Highcharts: typeof Highcharts = Highcharts;

  token: string;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  availableReports = {};

  excludingColumns = ['timestamp'];

  success;
  error;

  randomKey;

  constructorName = 'ReportingViewComponent';
  permissionName = 'FleetManagement_Reporting';
  timezoneIana: string;

  loadingReportUsage = false;
  chartReportUsage;

  loadingExpensiveReports = false;
  expensiveReports = [];

  loadingRecentReports = false;
  recentReports = [];

  loadingScheduledReports = false;
  scheduledReports = [];

  constructor(private dashboardService: DashboardService,
    private chartService: FhChartService,
    public signalrService: SignalrService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private reportService: ReportService,
    protected storageHelper: StorageHelper) {

    const that = this;
    this.loading = true;

    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.loadingReportUsage = true;
    this.dashboardService.getReportCount().subscribe(result => {
      this.loadingReportUsage = false;
      this.generateReportCount(result);
      this.cd.markForCheck();
    }, error => {
      this.loadingReportUsage = false;
      this.error = error;
      this.cd.markForCheck();
    });

    this.loadingExpensiveReports = true;
    this.reportService.getExpensiveReports().subscribe(result => {
      this.loadingExpensiveReports = false;

      this.expensiveReports = result;

      this.cd.markForCheck();
    }, error => {
      this.loadingExpensiveReports = false;
      this.error = error;
      this.cd.markForCheck();
    });

    this.loadingRecentReports = true;
    this.reportService.getRecentReports().subscribe(result => {
      this.loadingRecentReports = false;

      this.recentReports = result;

      this.cd.markForCheck();
    }, error => {
      this.loadingRecentReports = false;
      this.error = error;
      this.cd.markForCheck();
    });

    this.loadingScheduledReports = true;
    this.reportService.getScheduledReports().subscribe(result => {
      this.loadingScheduledReports = false;

      this.scheduledReports = result;

      this.cd.markForCheck();
    }, error => {
      this.loadingScheduledReports = false;
      this.error = error;
      this.cd.markForCheck();
    });

  }

  // Generate chart report
  generateReportCount(source) {
    const theReportCountData = [];
    const theSSRSReportCountData = [];

    source.forEach(item => {
      theReportCountData.push({ x: moment.utc(item.summaryDate).toDate().getTime(), y: Math.round((item.reportCount + Number.EPSILON) * 100) / 100 });
      theSSRSReportCountData.push({ x: moment.utc(item.summaryDate).toDate().getTime(), y: Math.round((item.ssrsReportCount + Number.EPSILON) * 100) / 100 });
    });

    const theCategories = [];

    const theChartData = [{
      name: this.translateService.instant('general.reports'),
      data: theReportCountData,
      type: 'areaspline',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    },
    {
      name: this.translateService.instant('general.ssrsReports'),
      data: theSSRSReportCountData,
      type: 'areaspline',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }];

    this.chartReportUsage = this.chartService.generateColumnChartDates(theChartData, {}, theCategories, undefined, 'normal', true);

    this.cd.markForCheck();
  }
}
