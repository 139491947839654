import { LocationStrategy } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WizardComponent } from 'app/modules/wizard/wizard';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { ShareTokenService } from 'app/services/sharetoken/shareToken.service';
import moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'fh-share-link',
    templateUrl: 'shareLink.template.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ShareLinkComponent {
    @ViewChild('addModal') addModal: ModalDirective;

    @Input() type;
    @Input() entityId;

    step = 1;
    logItem;

    dateTo;

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    assetForm = new FormGroup({
        dateTo: new FormControl(moment().add('day', 14).endOf('day').toDate(), Validators.required),
    });

    token: any;
    accountIdentifier: string;
    timezoneIana: string;

    loadingShareLink = false;
    baseUrl: string;
    whitelabel: string;

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private shareTokenService: ShareTokenService, private locationStrategy: LocationStrategy) {
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.withTimepicker = true;
        this.dpConfig.keepDatepickerOpened = true;
        this.dpConfig.adaptivePosition = true;
        this.dpConfig.isAnimated = true;

        this.accountIdentifier = this.authenticationService.getAccountIdentifier();
        this.timezoneIana = this.authenticationService.getTimeZoneIana();

        this.baseUrl = location.origin + this.locationStrategy.getBaseHref();

        this.whitelabel = this.authenticationService.getWhitelabel();
    }

    resetAction(event) {
        this.addModal.hide();
    }

    share() {
        this.initForm();

        this.addModal.show();
    }

    hideModal() {
        this.addModal.hide();
    }

    initForm() {
        console.log('init tab');

        this.step = 1;
    }

    requestShareLink() {
        console.log('Requesting sharelink');
        // Do a call to create a sharelink and retreive the url

        const shareToken = {
            entityId: this.entityId,
            type: this.type,
            ExpiryTimestamp: moment(this.assetForm.value.dateTo).tz(this.timezoneIana),
            ShareFrom: null,
            ShareTo: null,
            AccountIdentifier: this.accountIdentifier,
            WhitelabelName: this.whitelabel
        }

        this.loadingShareLink = true;

        this.shareTokenService.addShareToken(shareToken).subscribe(result => {
            this.token = result;

            this.loadingShareLink = false;
            this.cd.markForCheck();
        });
    }

    process(event) {
        // do a call to api when a mail should be send

        this.addModal.hide();
    }

}
