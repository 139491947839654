import { Driver } from './driver.model';

export class RegisterOperatingAsset {
    public referenceKey = '';
    public overrideAccountId;

    public sequenceNumber = '';
    public plateType = '';
    public vehiclePlateNumber = '';
    public vehiclePlateLeftLetter = '';
    public vehiclePlateMiddleLetter = '';
    public vehiclePlateRightLetter = '';
    public storingCategory = '';

    public inquiryStatus: WaslInquiry;

    public registerDateWasl: any;
    public registerDateSfda: any;
    public registerDateTow: any;
    public registerDateSpecialityTransport: any;
    public registerDateBusRental: any;
    public registerDateEducationalTransport: any;
}

export class WaslInquiry {
    public inquiryTime: any;
    public isVehicleValid: any
    public vehicleRejectionReason = ''
    public registrationTime: any;
    public vehicleStatus = ''
    public actualTime: any;
    public receivedTime: any;
}

export class Properties {
    public wasl = new RegisterOperatingAsset();
    public custom: any = [];
    public dashcams: any = [];
}

export class VehiclePlate {
    public number: string;
    public leftLetter: string;
    public middleLetter: string;
    public rightLetter: string;
}

export class WaslAsset {
    public vehiclePlate: VehiclePlate = new VehiclePlate();
    public sequenceNumber: string;
    public plateType: string;
    public imeiNumber: string;
    public referenceKey = '';
    public storingCategory: string;

    public registerDateWasl: any;
    public registerDateSfda: any;
    public registerDateTow: any;
}

export class SimpleAsset {
    public id: string;
    public name: string;
    public dateStart;
    public dateEnd;
    public color: string;
    public model: string;
    public plateNumber: string;
    public code: string;
}

export class DmvAsset {
    public message: any;
    public brand: any;
    public model: any;
    public color: any;
    public year: any;
    public vehicleType: any;
    public safetyCertificateExpirationDate: Date;
    public dateAcquired: Date;
    public clientAssetCategory: any;
    public purchasePrice: number;
    public fuelType: any;
    public emissionCO2: any;
    public emissionParticlesLight: any;
    public emissionParticlesHeavy: any;
    public urbanConsumption: any;
    public extraUrbanConsumption: any;
    public mixedConsumption: any;
}

export class Asset {
    public id: any;
    public deviceId: any;
    public accountId: any;
    public unitId: any;
    public name: string;
    public assignmentStart;
    public assignmentEnd
    public code: any;
    public plateNumber: any;
    public year: any;
    public vin: any;
    public brand: any;
    public model: any;
    public vehicleType: any;
    public icon: any;
    public comment: any;
    public isCommentWarning: boolean;
    public resellerDescription: any;
    public resellerId: any;
    public companyName: any;
    public assetTypeId: any;
    public assetTypeName: any;
    public calibrationPoints: any;
    public calibratedOdo: any;
    public calibrationOdoOffset: any;
    public calibrationOdoOffsetInKm: any;
    public lastCalibration: any;
    public driverAssignmentStart: any;
    public driverAssignmentEnd: any;
    public driver: Driver;

    public owner: any;
    public clientAssetCategory: any;
    public clientAssetSubCategory: any;
    public ownership: any;
    public color: any;
    public fuelType: any;
    public capacity: any;
    public bodyCode: any;
    public engineSerialNumber: any;
    public engineType: any;
    public costCenter: any;
    public madeIn: any;
    public plateMunicipality: any;
    public dateAcquired: Date;
    public dealerName: any;
    public purchasePrice: number;
    public monthlyInsurance: number;
    public monthlyRoadTax: number;
    public currentValue: number;
    public totalDepreciation: number;
    public dateDepreciation: Date;
    public dateSold: Date;
    public soldTo: any;
    public soldPrice: number;
    public insurancePolicy: any;
    public dateExpireInsurance: Date;
    public registrationNumber: any;
    public dateExpireRegistration: Date;
    public permitOne: any;
    public permitOneExpirationDate: Date;
    public permitTwo: any;
    public permitTwoExpirationDate: Date;
    public safetyCertificate: any;
    public safetyCertificateExpirationDate: Date;
    public safetyTestedBy: any;
    public erpCode: any;
    public branding: string;
    public equipment: string;

    public emissionCO2: any;
    public emissionParticlesLight: any;
    public emissionParticlesHeavy: any;
    public urbanConsumption: any;
    public extraUrbanConsumption: any;
    public mixedConsumption: any;

    public garageName: string;
    public garageId: number;

    public assetGroups: any[] = [];
    public assetGroupIds: any[] = [];
    public properties = new Properties();
    public assetSearchName: any;
    public isArchived: any;
    public assignedScheduleId: any;

    // Engine hours
    public engineHourCalibrationOdoOffset: any;
    public tripEngineHourInSeconds: any;
    public engineHourCalibrationPoints: any;
    public engineHourLastCalibration: Date;

    public currentAddress: {
        address: any;
        city: any;
        country: any;
    };

    public activeProject: {
        id: any;
        name: any;
    };
    
    isAssetTracker: boolean;
}

export class AssetDevice {
    public deviceId: string;
    public assetId: string;
    public startDate: string;
    public endDate: string;
    public deviceName: any;
    public isActive: boolean;
}
