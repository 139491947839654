import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError, map, throwError } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { ArchiveRetentionRule } from 'app/models/archiveretentionrule.model';

@Injectable()
export class ArchiveRetentionRuleService {
    url = '';
    base_url = '';
    inventoryUrl = '';
    ArchiveRetentionRules: ArchiveRetentionRule[] = [];

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = this.authenticationService.getWebserviceURL('archiveRetentionRule');
        this.base_url = this.authenticationService.getWebserviceURL('');
    }

    getPagingUrl() {
        return this.url + `Paging`;
    }

    getArchiveRetentionRules(): Observable<ArchiveRetentionRule[]> {
        console.log('getting archiveRetentionRule from service');
        return this.http.get(this.url, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return this.parseResponse(data);
            }), catchError(this.handleError));
    }

    // getTriggerById(id: number): Observable<Trigger> {
    //     console.log('Fetch trigger by id ' + id);
    //     return this.http.get(this.url + id, { headers: this.authenticationService.headers })
    //         .pipe(map(data => {
    //             return this.parseReponseDetails(data);
    //         })
    //         ,catchError(this.handleError));
    // }

    // saveTrigger(trigger: Trigger): Observable<any> {
    //     console.log('save trigger', trigger);

    //     return this.http.post(this.url, trigger, { headers: this.authenticationService.headers })
    //         ,catchError(this.handleError));
    // }

    // updateTrigger(trigger: Trigger): Observable<any> {
    //     console.log('update trigger', trigger);

    //     return this.http.put(this.url, trigger, { headers: this.authenticationService.headers })
    //         ,catchError(this.handleError));
    // }

    // deleteTrigger(trigger: Trigger): Observable<any> {
    //     console.log('delete trigger', trigger);

    //     return this.http.delete(this.url + trigger.id, { headers: this.authenticationService.headers })
    //         ,catchError(this.handleError));
    // }

    private handleError(error: Response) {
        console.log(error);
        return throwError(error);
    }

    parseResponse(json: any): ArchiveRetentionRule[] {
        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' ArchiveRetentionRules.');

        const ident = 1;
        const archiveRetentionRules: ArchiveRetentionRule[] = [];

        json.forEach(item => {
            const archiveRetentionRule = this.parseReponseDetails(item);
            archiveRetentionRules.push(archiveRetentionRule);
        });

        return archiveRetentionRules;
    }

    parseReponseDetails(item) {
        const archiveRetentionRule = new ArchiveRetentionRule();
        archiveRetentionRule.id = item.id;
        archiveRetentionRule.ruleType = item.ruleType;
        archiveRetentionRule.keyValue = item.keyValue;
        archiveRetentionRule.retentionInMonths = item.retentionInMonths;
        archiveRetentionRule.entity = item.entity;

        return archiveRetentionRule;
    }
}
