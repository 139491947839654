import { FuelService } from 'app/services/fuel/fuel.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { getGridButtons, getGridLanguages, createSearch, createdCellCheckbox, getDefaultDpConfig } from 'app/common/gridhelper';

import { AuthenticationService } from '../../services/authentication/authentication.service';

import { Observable, forkJoin } from 'rxjs';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { GridBase360Directive } from 'app/common/360Grid.base';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { StorageHelper } from 'app/common/storagehelper';
import { ColorService } from 'app/services/common/color.service';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-fuel-thresholds',
  templateUrl: 'fuelThresholds.template.html'
})
export class FuelThresholdsViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  token: string;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  constructorName = 'FuelThresholdsViewComponent';
  timezoneIana: string;

  // Daterange
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  datePickerModel: Date;

  error;

  constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private fuelService: FuelService, protected storageHelper: StorageHelper, private colorService: ColorService) {
    super(storageHelper);

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    // Get all the date for dropdown boxes
    forkJoin(
      this.translateService.get('general.date')
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      err => console.error(err)
    );
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  dateChanged(event) {
    const that = this;
    if (event !== null) {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.url(that.fuelService.getPagingThresholdsUrl()).load();
      });
    }
  }

  initGrid(): void {
    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: 'assetName',
        data: 'assetName',
        defaultContent: '-',
        title: this.translateService.instant('general.assetName'),
        render: function (data, type, row) {
          return '<a class="link_bolder" href="/#/DeviceDetails/Fuel/' + row.deviceId + '">' + data + '</a>';
        },
      },
      {
        name: 'id',
        data: 'id',
        defaultContent: '-',
        title: this.translateService.instant('general.assetId'),
        visible: false
      },
      {
        name: 'deviceId',
        data: 'deviceId',
        defaultContent: '-',
        title: this.translateService.instant('general.DeviceId'),
        visible: false
      },
      {
        name: 'fuelTankCapacityInLiters',
        data: 'fuelTankCapacityInLiters',
        defaultContent: '-',
        title: this.translateService.instant('general.fuelCapacity'),
        render: function (data, type, row) {
          if (data != null) {
            return (row.fuelTank1CapacityInLiters != null && row.fuelTank2CapacityInLiters != null) ? data.toLocaleString() + ' L (' + row.fuelTank1CapacityInLiters.toLocaleString() + ' L + ' + row.fuelTank2CapacityInLiters.toLocaleString() + ' L)' : data.toLocaleString() + ' L'
          } else {
            return '-'
          }
        }
      },
      {
        name: 'fillupThresholdPercentage',
        data: 'fillupThresholdPercentage',
        defaultContent: '-',
        title: this.translateService.instant('general.fillupThresholds'),
        render: function (data, type, row) {
          if (data != null) {
            return (row.fuelTankCapacityInLiters != null) ? data.toLocaleString() + '% (' + Math.round((data / 100) * row.fuelTankCapacityInLiters) + ' L)' : data.toLocaleString() + ' L'
          } else {
            return (row.fuelTankCapacityInLiters != null) ? '5% (' + Math.round((5 / 100) * row.fuelTankCapacityInLiters) + ' L)' : '-'
          }
        }
      },
      {
        name: 'theftThresholdPercentage',
        data: 'theftThresholdPercentage',
        defaultContent: '-',
        title: this.translateService.instant('general.theftThresholds'),
        render: function (data, type, row) {
          if (data != null) {
            return (row.fuelTankCapacityInLiters != null) ? data.toLocaleString() + '% (' + Math.round((data / 100) * row.fuelTankCapacityInLiters) + ' L)' : data.toLocaleString() + ' L'
          } else {
            return (row.fuelTankCapacityInLiters != null) ? '5% (' + Math.round((5 / 100) * row.fuelTankCapacityInLiters) + ' L)' : '-'
          }
        }
      },
      {
        name: 'theftCount',
        data: 'theftCount',
        defaultContent: '-',
        title: this.translateService.instant('general.theftCount')
      },
      {
        name: 'fillUpCount',
        data: 'fillUpCount',
        defaultContent: '-',
        title: this.translateService.instant('general.fillUpCount')
      },
      {
        name: 'companyName',
        data: 'companyName',
        defaultContent: '-',
        title: this.translateService.instant('general.companyName')
      },
      {
        name: 'resellerDescription',
        data: 'resellerDescription',
        defaultContent: '-',
        title: this.translateService.instant('general.resellerDescription'),
        visible: false
      }
    ];

    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'fuel_thresholds_overview', this.translateService.instant('menu.deviceoverview'), this.colorService),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => { try {
          const columnSettings = await that.loadState(that.constructorName);
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          that.error = {};
          that.error.error = e;
          that.error.statusText = 'Error fetching column settings';

          return null;
        }})().then(result => {
          callback(result);
        });
      },
      order: [[2, 'asc']],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.fuelService.getPagingThresholdsUrl(),
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        console.log('init complete');
        that.checkFilters();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id', 'id_export']
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
    };
  }
}
