
export class NotificationLog {

    public id: number;
    deviceId: any;
    dataTriggerId: any;
    recipient: any;
    notificationType: any;
    message: any;
    responseMessage: any;
    responseCode: any;
    notificationMethod: any;
    timeStampSending: any;
    timeStampAcknowledged: any;
    transmissionStatus: any;
    fkUserId: any;
    subject: any;
    smsExternalMessageReference: any;
    assetName: any;
    dataTriggerName: any;
    emailLoginName: any;
    triggerId: any;
    triggerName: any;
}

