import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { DeviceService } from '../../services/device/device.service';
import { FormMode } from 'app/common/enums';
import { WaslService } from 'app/services/wasl/wasl.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

@Component({
    selector: 'fh-device-wasl',
    templateUrl: 'wasl.template.html'
})
export class DeviceWaslViewComponent implements OnInit {
    sub;
    loading = false;

    error: any;
    warning: any;
    success: any;

    formMode = FormMode.read;
    device: Device;
    deviceId;
    permissions: {};

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private waslService: WaslService, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router) {
        this.device = null;
        this.permissions = this.authenticationService.permissions;
    }

    ngOnInit() {
        this.loading = true;
        this.device = new Device;
        this.device.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.deviceId = id;
            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;
                if (!this.device.asset?.id) {
                    this.warning = {
                        statusText: 'Warning',
                        warning: 'There is no asset configured on this device.!'
                    };
                }
                this.loading = false;
                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }
            });
        }, error => {
            this.error = error;
            this.error.statusText = 'Error fetching device';

            setTimeout(() => {
                this.router.navigate(['/Devices/Overview']);
            }, 3000);
        });
    }

    getAccountInfo() {
        if (this.loading !== true) {
            this.loading = true;
        }

        this.deviceService.getDeviceById(this.device.id).subscribe(device => {
            this.device = device;
            this.loading = false;
        });
    }

    // Wasl
    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getAccountInfo();
        }
    }

    onSave() {

    }

    updateStoringCategory() {
        this.loading = true;

        this.waslService.updateStoringCategory(this.device.asset?.id, this.device.asset.properties.wasl.storingCategory).subscribe(registerResult => {

            this.loading = false;
            if (registerResult.isSuccess) {
                this.success = {
                    statusText: 'Success',
                    json: JSON.parse(registerResult.entity.json),
                    success: registerResult.message
                };
                this.error = null;
            } else if (registerResult.isWarning) {
                this.warning = {
                    statusText: 'Warning',
                    success: registerResult.message
                };
                this.error = null;
            } else {
                this.error = {
                    statusText: 'Error',
                    error: registerResult.message
                };
            }
            this.getAccountInfo();
        }, error => {
            console.log(error);
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    inquire(company) {
        this.loading = true;

        this.waslService.getInquireVehicle(this.device.asset?.id, company).subscribe(registerResult => {

            this.loading = false;
            if (registerResult.isSuccess) {
                this.success = {
                    statusText: 'Success',
                    json: JSON.parse(registerResult.entity.json),
                    success: registerResult.message
                };
                this.error = null;
            } else if (registerResult.isWarning) {
                this.warning = {
                    statusText: 'Warning',
                    success: registerResult.message
                };
                this.error = null;
            } else {
                this.error = {
                    statusText: 'Error',
                    error: registerResult.message
                };
            }
            this.getAccountInfo();
        }, error => {
            console.log(error);
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    register(company) {
        this.loading = true;

        this.waslService.registerAsset(this.device.asset?.id, this.device.unitId, this.device.asset.properties.wasl, company).subscribe(registerResult => {

            this.loading = false;
            if (registerResult.isSuccess) {
                this.success = {
                    statusText: 'Success',
                    success: registerResult.message
                };
                this.error = null;
            } else {
                this.error = {
                    statusText: 'Error',
                    error: registerResult.message
                };
            }
            this.getAccountInfo();
        }, error => {
            console.log(error);
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    unRegister(company) {
        this.loading = true;

        this.waslService.unregisterAsset(this.device.asset?.id, this.device.asset.properties.wasl.overrideAccountId, company).subscribe(unregisterResult => {
            console.log(unregisterResult);
            this.loading = false;
            if (unregisterResult.isSuccess) {
                this.success = {
                    statusText: 'Success',
                    success: unregisterResult.message
                };
                this.error = null;
            } else {
                this.error = {
                    statusText: 'Error',
                    error: unregisterResult.message
                };
            }
            this.getAccountInfo();
        }, error => {
            console.log(error);
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }
}
