<div class="masonry-with-columns">
    <ng-container *ngFor="let permission of permissionsMap">
        <div [@enter]
            *ngIf="permission.value.length !== 0 && (permissions['Platform_IsAdministrator'] || (permissions[permission.name] && (!permission.parent || permissions[permission.parent])))"
            class="ibox" style="position:relative"
            [ngClass]="{ibox_disabled: (permission.parent != null && !hasPermission(permission.parent)) }">
            <ngx-loading [show]="loading"></ngx-loading>
            <div class="ibox-title">
                <h5>
                    {{permission.name}}
                </h5>
                <span class="float-right" style="padding-right: 6px;">
                    <i *ngIf="permission.info != null && permission.info != ''" tooltip="{{ permission.info }}"
                        class="fas fa-fw fa-question hand"></i>
                </span>
            </div>
            <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px">
                <tbody>
                    <ng-container *ngFor="let subPermission of permission.value">
                        <tr *ngIf="permissions['Platform_IsAdministrator'] || permissions[subPermission.name]">
                            <td class="row_opening" style="width:100%">
                                <div class="fa_checkbox" style="float:left">
                                    <input [id]="'checkbox_' + permission.name + '_' + subPermission.name"
                                        type="checkbox"
                                        [name]="'checkbox_' + permission.name + '_' + subPermission.name"
                                        [disabled]="formMode == 1" [(ngModel)]="subPermission.value" />
                                    <label [for]="'checkbox_' + permission.name + '_' + subPermission.name">
                                        {{ subPermission.name.split('_')[1] }}
                                    </label>
                                </div>
                                <span class="float-right" style="padding-right: 4px;">
                                    <i *ngIf="subPermission.info != null && subPermission.info != ''"
                                        tooltip="{{ subPermission.info }}" container="body" class="fas fa-fw fa-question hand"></i>
                                </span>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>