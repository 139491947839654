import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { FhChartService } from '../../services/charts/charts.service';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from 'app/services/dashboard/dashboard.service';

import { SearchService } from 'app/services/search/search.service';
import { Router } from '@angular/router';
import { slideInOut } from 'app/common/animations';
import { AccountService } from 'app/services/account/account.service';
import { DeviceService } from 'app/services/device/device.service';
import { Device } from 'app/models/device.model';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
declare var L;

import { AccountInventory } from 'app/models/account.model';
import { BOUND_CHECK, MAX_LATITUDE, MAX_LONGITUDE, roundAsNumber, roundAsString, roundMinutes, roundSeconds } from 'app/common/globals';
import { Observable, debounceTime, distinctUntilChanged, forkJoin, of, timer } from 'rxjs';
import { getDefaultDpConfig } from 'app/common/gridhelper';
import { AuditLogService } from 'app/services/auditlog/auditlog.service';
import { AuditLog } from 'app/models/auditlog.model';
import { NotificationLogService } from 'app/services/notificationlog/notificationlog.service';
import { UserService } from 'app/services/users/user.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { DeviceType } from 'app/models/devicetype.model';
import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';
import { LocationService } from 'app/services/locations/locations.service';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import * as moment from 'moment-timezone';
window['moment'] = Moment;
mTZ();

import * as Highcharts from 'highcharts';
import { StorageHelper } from 'app/common/storagehelper';
import { StorageType } from 'app/common/enums';
import { DistanceUnitService } from 'app/common/distanceunit.service';

@Component({
  selector: 'fh-home',
  templateUrl: 'home.template.html',
  providers: [FhChartService, SearchService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    slideInOut
  ]
})
export class HomeComponent implements OnDestroy, OnInit {
  @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

  Highcharts: typeof Highcharts = Highcharts;

  permissionName = 'FleetManagement_Dashboard';
  constructorName = 'DashboardClientComponent';
  timezoneIana: string;
  permissions: {};
  loading = false;

  activeDevices = 0;
  activeGeofences = 0;
  activeDrivers = 0;
  firstAssetDate: any;
  error: any;
  announcements = [];
  isImpersonated = false;
  impersonationId: string;

  devices = [];
  loadingDevices = false;
  includeAssetsOtherAccounts = false;
  skipIncludingGroupColors = false;
  geofences = [];

  locationData = [];
  loadingAccount: boolean;
  account: AccountInventory;

  mapHeight = 250;

  loadingUtilization = false;
  daterangepickerModel: Date[];
  utilization: any;
  accountUtilization;
  dpConfig: any;

  loadingAuditLog = false;
  auditLogs: AuditLog[] = [];
  currentPageAuditLog;
  filterAuditLog;

  loadingNotifications = false;
  notifications: AuditLog[] = [];
  currentPageNotifications;
  filterNotifications;

  loadingCount: boolean;
  distanceChartData: any;
  chartLocationCount;
  chartDistance;
  chartActiveDevices;
  chartActivity;
  chartEngineHours;
  culture;

  // Impersonation
  asyncSelected: any;
  typeaheadLoading: boolean;

  autoComplete$: Observable<string[]>;
  autoCompleteSearchTerm: string;
  deviceTypes: DeviceType[] = [];

  // mapping
  search;
  status;
  selectedAssetGroups;
  selectedAccountId;
  selectedResellerId;
  loadingDeviceTypes = false;
  deviceType;
  deviceTypeOptions = [];

  // Location updates
  isSpinning = false;
  locationSubscription;
  statusText = 'Init';
  previousLookupTimestamp;
  previousLookupDisplay: Moment.Moment;

  storageType = StorageType.LocalStorage;

  // trends
  loadingEngineHours: boolean;
  groupBy = 1;
  trends: any;

  translatedKmh: any = "km/h";
  translatedKm: any = "km";
  timerid;

  public constructor(
    private router: Router,
    private deviceTypeService: DeviceTypeService,
    private userService: UserService,
    private searchService: SearchService,
    private locationService: LocationService,
    private chartService: FhChartService,
    private deviceService: DeviceService,
    private distance: DistanceUnitService,
    private storageHelper: StorageHelper,
    private dashboardService: DashboardService, private accountService: AccountService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private translateService: TranslateService) {
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
    this.permissions = this.authenticationService.permissions;
    this.impersonationId = this.authenticationService.getId();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(14, 'days').startOf('day').toDate(),
      Moment().tz(this.timezoneIana).subtract(0, 'days').startOf('day').toDate()
    ];

    this.deviceTypeService.getDeviceTypes().subscribe(result => {
      this.deviceTypes = result;
    });

    this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

    this.storageHelper.loadStoreState(this.storageType, 'settings_', 'includeAssetsOtherAccounts').subscribe((result) => {
      this.includeAssetsOtherAccounts = JSON.parse(result) === true;
    });

    this.storageHelper.loadStoreState(this.storageType, 'settings_', 'skipIncludingGroupColors').subscribe((result) => {
      this.skipIncludingGroupColors = JSON.parse(result) === true;
    });

    this.translateService.get('general.date').subscribe(
      data => {
        this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
        this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());

        this.cd.markForCheck();
      }
    );

    const culture = this.authenticationService.getCultureLang();
    switch (culture) {
      case 'en':
        this.culture = 'en-US';
        break;
      case 'nl':
        this.culture = 'nl-NL';
        break;
      case 'ar':
        this.culture = 'ar-AE';
        break;
      default:
        this.culture = 'en-US';
        break;
    }
  }

  ngOnInit(): void {

    const that = this;

    this.loading = true;
    this.loadingAccount = true;
    this.loadingDevices = true;

    this.loadingDeviceTypes = true;

    this.deviceTypeService.getDeviceTypes(false, false, true).subscribe(deviceTypes => {
      this.loadingDeviceTypes = false;
      deviceTypes.sort((a, b) => (a.modelName > b.modelName ? 1 : -1)).forEach(function (item, index) {
        if (item.modelName !== '') {
          that.deviceTypeOptions.push({ id: item.id, value: item.modelName + ' (' + item.deviceCount + ')' });
        }
        that.cd.markForCheck();
      });
    });

    this.dashboardService.getKPISCount().subscribe(kpiResults => {
      this.activeDevices = kpiResults.deviceCount;
      this.activeGeofences = kpiResults.geofenceCount;
      this.activeDrivers = kpiResults.driverCount;
      this.firstAssetDate = moment(kpiResults.firstAssetDate).toDate();

      this.dateChanged(true);

      let startDate = Moment().subtract(14, 'days').startOf('day').toDate();
      if (this.firstAssetDate > startDate) {
        startDate = this.firstAssetDate;
      }

      this.daterangepickerModel = [
        startDate,
        Moment().tz(this.timezoneIana).subtract(0, 'days').startOf('day').toDate()
      ];

      this.loading = false;
      this.cd.markForCheck();

      this.dashboardService.getAccouncements().subscribe(result => {
        this.announcements = result;
        that.cd.markForCheck();
      }, error => {
        this.error = error;
      });
    });

    this.dateChanged(true);

    this.isImpersonated = this.authenticationService.getIsImpersonated();

    this.autoComplete$ = Observable.create((observer: any) => {
      this.actualSearch(this.asyncSelected).subscribe((result: any) => {
        observer.next(result);
      })
    });
  }

  // includeAssetsOtherAccounts
  flipIncludeAssetsOtherAccounts() {
    this.includeAssetsOtherAccounts = !this.includeAssetsOtherAccounts;
    this.storageHelper.saveStoreState(this.storageType, 'settings_', 'includeAssetsOtherAccounts', this.includeAssetsOtherAccounts.toString());
  }

  getDeviceCount(index) {
    if (index === 6) {
      const markers = this.leafletMapComponent?.markerList?.filter(x => x.filteredWithoutState == false && x.position != null && !((x.data.location?.locationType & 2) > 0));
      return markers?.length ?? '-';
    } else {
      return this.leafletMapComponent?.markerList?.filter(x => x.filteredWithoutState == false && x.category === index.toString()).length ?? '-';
    }
  }

  getLocations() {
    this.loadingDevices = true;
    this.loadingAccount = true;

    if (!this.selectedAccountId) {
      this.loadingDevices = false;
      this.loadingAccount = false;
      this.cd.markForCheck();

      return;
    }

    this.setLocationUpdates();

    forkJoin(
      this.accountService.getAccountById(this.selectedAccountId),
      this.deviceService.getDevicesWithLocation(this.includeAssetsOtherAccounts ? null : this.selectedAccountId, true, !this.skipIncludingGroupColors, true),
      this.accountService.getGeofencesByAccount(this.selectedAccountId)
    ).subscribe(
      data => {
        this.account = data[0];
        this.devices = data[1].filter(x => x.isActive === true && x.isArchived === false);
        this.geofences = data[2];

        this.loadingDevices = false;
        this.loadingAccount = false;

        this.drawLocations(this.devices);

        // Do the initial location states
        this.locationService.getDeviceStates(this.devices.map(x => x.id), null, this.previousLookupTimestamp, 0).subscribe(result => {
          this.parseLocationUpdates(result);
        });
      });
  }

  setLocationUpdates() {
    const that = this;
    this.statusText = 'Getting locations';

    console.log('Subscribe to updates for ' + that.devices.map(x => x.id).length + ' devices');
    if (this.locationSubscription !== undefined) {
      console.log('Unsubscribe updates locations');
      this.locationSubscription.unsubscribe();
    }

    this.locationSubscription = timer(0, 30000).pipe(
      mergeMap(() => {
        // Start the spinner
        this.isSpinning = true;
        this.cd.markForCheck();

        if (that.devices.length == 0) {
          console.log('Halt get state');
          return of(null);
        }

        return that.locationService.getDeviceStates(that.devices.map(x => x.id), null, that.previousLookupTimestamp, 0);
      })
    ).subscribe(result => {
      this.parseLocationUpdates(result);
    });
  }

  parseLocationUpdates(result) {
    const that = this;

    if (result == null) {
      return;
    }

    // Stop the spinner
    setTimeout(() => { this.isSpinning = false; this.cd.markForCheck(); }, 500);

    this.loading = false;

    if (result?.deviceStates.length > 0) {
      this.statusText = 'Last updated: ';

      console.log('Updating ' + result?.deviceStates.length + ' locations');

      this.cd.markForCheck();

      this.leafletMapComponent.markerList.forEach(theMarker => {
        const deviceState = result.deviceStates.find(x => x.id === theMarker.data.deviceId);

        if (deviceState && deviceState?.currentPosition) {
          if (BOUND_CHECK(deviceState?.currentPosition?.latitude, MAX_LATITUDE)
            && BOUND_CHECK(deviceState?.currentPosition?.longitude, MAX_LONGITUDE)) {
            theMarker.Move(deviceState?.currentPosition?.latitude, deviceState?.currentPosition?.longitude);
            theMarker.data.forceIconRedraw = true;
            theMarker.data.deviceState = deviceState?.calculatedDeviceState?.deviceState ?? 0;
            theMarker.data.lastCommunication = Moment.utc(deviceState?.communicationState?.updateTimestamp)['tz'](this.timezoneIana);
            theMarker.data.location.locationType = deviceState?.communicationState?.locationType;
            theMarker.category = Math.ceil(deviceState?.calculatedDeviceState?.deviceState).toString();
          }
        } else {
          // dont update
        }
      });

      if (this.leafletMapComponent.pruneCluster) {
        if (typeof this.leafletMapComponent.pruneCluster.ProcessView === "function") {
          this.leafletMapComponent.pruneCluster.ProcessView();
        }
        if (typeof this.leafletMapComponent.pruneCluster.RedrawIcons === "function") {
          this.leafletMapComponent.pruneCluster.RedrawIcons();
        }
      }

      that.previousLookupTimestamp = new Date(result.timestamp);
      this.previousLookupDisplay = Moment.utc(result.timestamp)['tz'](this.timezoneIana);
      this.cd.markForCheck();
    } else {
      console.log('No data updated');
      that.previousLookupTimestamp = new Date(result.timestamp);
      this.previousLookupDisplay = Moment.utc(result.timestamp)['tz'](this.timezoneIana);
      this.cd.markForCheck();
    }
  }

  actualSearch(search) {
    return this.searchService.searchUsers(search).pipe(debounceTime(300), distinctUntilChanged());
  }

  onSubmit() {
    console.log('Search on');
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    console.log('Selected value: ', e);

    const user = this.userService.getUserById(e.item.id).subscribe(appUser => {
      this.userService.getTokenForUser(appUser.id).subscribe(result => {
        this.authenticationService.setImpersonationToken(result);
      });
    });
  }

  resellerChanged(resellerId) {
    this.selectedAccountId = null;

    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    this.selectedAccountId = accountId;
    this.selectedAssetGroups = null;

    this.error = null;

    if (this.selectedAccountId) {
      this.dateChanged(true);
    }
  }

  dateChanged(renewLocations = false) {
    if (!this.selectedAccountId) {
      console.log('No account selcted');
      this.loadingDevices = false;
      this.loadingAccount = false;
      this.cd.markForCheck();
      return;
    }

    // Debounce changes
    if (this.timerid !== null) {
      clearTimeout(this.timerid);
    }
    this.timerid = setTimeout(() => {
      this.getData(renewLocations);
      this.timerid = null;
    }, 500);
  }

  getData(renewLocations = false) {
    console.log('Getting data');

    this.getUtilization();
    this.getLocationCount();
    this.getEngineHours();

    if (renewLocations) {
      this.getLocations();
    }
  }

  getUtilization() {
    this.loadingUtilization = true;
    this.accountService.getAccountUtilization(this.selectedAccountId, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.includeAssetsOtherAccounts, null, null, null, null, this.selectedAssetGroups, false).subscribe(
      utilization => {
        if (utilization.length > 0) {
          this.utilization = utilization.filter(x => x.accountId?.toString() === this.selectedAccountId.toString() || this.includeAssetsOtherAccounts === true);

          let maxSpeedDeviceId;

          let maxSpeed = -1;

          for (const assetUtilization of this.utilization) {
            if (maxSpeed > assetUtilization.maxSpeed) {
              continue;
            }

            maxSpeed = assetUtilization.maxSpeed;
            maxSpeedDeviceId = assetUtilization.deviceId;
          }

          this.accountUtilization = {
            assetCount: this.utilization.length,
            assetCountWithTrips: this.utilization.filter(x => x.tripCount > 0).length,
            totalDurationInSeconds: this.utilization.reduce((partial_sum, d) => partial_sum + d.totalDurationInSeconds, 0),
            tripCount: this.utilization.reduce((partial_sum, d) => partial_sum + d.tripCount, 0),
            tripCountPrivate: this.utilization.reduce((partial_sum, d) => partial_sum + d.tripCountPrivate, 0),
            tripCountBusiness: this.utilization.reduce((partial_sum, d) => partial_sum + d.tripCountBusiness, 0),
            totalDistance: this.distance.calculateDistanceUnitFromKmFixed(this.utilization.reduce((partial_sum, d) => partial_sum + d.segmentsDistance, 0), 0),
            totalDistancePrivate: this.distance.calculateDistanceUnitFromKmFixed(this.utilization.reduce((partial_sum, d) => partial_sum + d.segmentsDistancePrivate, 0), 0),
            totalDistanceBusiness: this.distance.calculateDistanceUnitFromKmFixed(this.utilization.reduce((partial_sum, d) => partial_sum + d.segmentsDistanceBusiness, 0), 0),
            totalWorkingHoursInSeconds: this.utilization.reduce((partial_sum, d) => partial_sum + d.workingHoursInSeconds, 0),
            totalIdlingDurationInSeconds: this.utilization.reduce((partial_sum, d) => partial_sum + d.idlingDurationInSeconds, 0),
            totalPureDrivingDurationInSeconds: this.utilization.reduce((partial_sum, d) => partial_sum + d.pureDrivingDurationInSeconds, 0),
            totalConsumptionMixed: this.utilization.reduce((partial_sum, d) => partial_sum + d.totalConsumptionMixed, 0),
            totalEmissionCO2: this.utilization.reduce((partial_sum, d) => partial_sum + d.totalEmissionCO2, 0),
            totalEmissionParticlesHeavy: this.utilization.reduce((partial_sum, d) => partial_sum + d.totalEmissionParticlesHeavy, 0),
            totalEmissionParticlesLight: this.utilization.reduce((partial_sum, d) => partial_sum + d.totalEmissionParticlesLight, 0),
            maxSpeed: this.distance.calculateDistanceUnitFromKmFixed(maxSpeed, 0),
            maxSpeedDeviceId: maxSpeedDeviceId,
          }

          this.generateActivityReport(this.accountUtilization);
        }


        this.loadingUtilization = false;
        this.cd.markForCheck();
      }, error => {
        this.error = error;
        // this.error = 'Error getting utilization';
        this.loadingUtilization = false;
        this.cd.markForCheck();
      });
  }

  getEngineHours() {
    this.loadingEngineHours = true;
    this.accountService.getAccountEngineHours(this.selectedAccountId, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'),
      null,
      null,
      null,
      null,
      this.selectedAssetGroups).subscribe(resp => {
        this.trends = resp.sort((a, b) => (a.startDate < b.startDate ? -1 : 1));;

        this.generateChartEngineHours(resp);
        this.loadingEngineHours = false;
      }, error => {
        this.loadingEngineHours = false;
        this.error = error;
      });
  }


  stopImpersonation() {
    this.router.navigate(['/']).then(response => {
      this.authenticationService.stopImpersonation();
    });
  }

  ngOnDestroy(): any {
    if (this.locationSubscription !== undefined) {
      console.log('Unsubscribe updates locations');
      this.locationSubscription.unsubscribe();
    }
    if (this.timerid !== null) {
      clearTimeout(this.timerid);
    }

    this.timerid = null;
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  actualRoundSeconds(value) {
    return roundSeconds(value);
  }

  actualRoundMinutes(value) {
    return roundMinutes(value);
  }

  drawLocations(devices: Device[]) {
    const localLocationData = [];
    this.leafletMapComponent.clearLocations();
    this.locationData = [];

    let resellerDefaultAssetIcon;
    if (this.account?.resellerDefaultAssetIcon > 999) {
      resellerDefaultAssetIcon = this.account.resellerDefaultAssetIcon;
    }

    devices.forEach(device => {
      if (device.deviceState === null) {
        return;
      }

      let latitude = device.deviceState.currentPosition?.latitude ?? null;
      let longitude = device.deviceState.currentPosition?.longitude ?? null;
      let lastCommunication = Moment.utc(device.deviceState.currentPosition?.updateTimestamp)['tz'](this.timezoneIana);

      // tslint:disable-next-line:no-bitwise
      const hasGpsFix = (device.deviceState.communicationState?.locationType & 2) > 0;

      if (device.deviceState.cellPosition && ((latitude === null && longitude === null) || !hasGpsFix)) {
        latitude = device.deviceState.cellPosition.latitude ?? null;
        longitude = device.deviceState.cellPosition.longitude ?? null;
        lastCommunication = Moment.utc(device.deviceState.cellPosition.updateTimestamp)['tz'](this.timezoneIana);
      }

      if (latitude === null || longitude === null) {
        return;
      }

      if (!BOUND_CHECK(latitude, MAX_LATITUDE) || !BOUND_CHECK(longitude, MAX_LONGITUDE)) {
        console.log('No valid bounds for device ' + device.id + ' latlng: ' + latitude + ':' + longitude);
        return;
      }

      const location = {
        assetName: device.asset?.name ?? device.name,
        assetBrand: device?.asset?.brand,
        assetModel: device?.asset?.model,
        assetCode: device?.asset?.code,
        assetPlateNumber: device?.asset?.plateNumber,
        companyName: device?.companyName,
        icon: device.asset?.icon,
        deviceState: device.deviceState?.calculatedDeviceState?.deviceState ?? 0,
        stateChangedTimestamp: Moment.utc(device.deviceState?.calculatedDeviceState?.stateChangedTimestamp)['tz'](this.timezoneIana),
        headingInDegrees: device.deviceState.currentPosition?.heading,
        speed: device.deviceState.currentPosition?.speed,
        latitude: latitude,
        longitude: longitude,
        unitId: device.unitId,
        deviceId: device.id,
        deviceTypeId: device.deviceTypeId,
        lastCommunication: lastCommunication,
        radiusInMeters: device.deviceState.currentPosition?.radiusInMeters,
        assetGroupIds: device.asset?.assetGroupIds,
        assetGroups: device.asset?.assetGroups,
        locationType: device.deviceState.communicationState?.locationType,
      };

      localLocationData.push(location);
    });

    this.leafletMapComponent.clearLocations();
    this.locationData = localLocationData;

    this.cd.markForCheck();
  }

  getLocationCount() {
    this.loadingCount = true;

    this.accountService.getLocationCount(this.includeAssetsOtherAccounts === true ? null : this.selectedAccountId, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), null, null, null, null, this.selectedAssetGroups).subscribe(result => {
      this.loadingCount = false;
      result = result.sort((a, b) => (a.summaryDate < b.summaryDate ? -1 : 1));
      this.distanceChartData = result;
      this.generateChartLocationCountChart(this.distanceChartData);

      this.cd.markForCheck();
    }, error => {
      this.loadingCount = false;
      this.error = error;
      this.cd.markForCheck();
    });
  }

  // Generate chart locationcount
  generateChartLocationCountChart(data) {

    const theLocationDataIgnitionOn = [];
    const theLocationDataIgnitionOff = [];
    const theLocationDataEvents = [];
    const theDistanceData = [];
    const theCategories = [];
    const theNonCommunicatingCountData = [];
    const theComminicatingButNotMovingAssetCountData = [];
    const theMovingAssetCountData = [];
    const theDelayMoreThan10Data = [];

    // tslint:disable-next-line:forin
    data.forEach(value => {
      if (value.assetCount < value.deviceCommunicatingCount) {
        value.assetCount = value.deviceCommunicatingCount;
      }

      theLocationDataIgnitionOn.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.ignitionCount, clientData: value.customerId });
      theLocationDataIgnitionOff.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: (value.locationCount - value.ignitionCount), clientData: value.customerId });
      theLocationDataEvents.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.eventCount, clientData: value.customerId });
      theDistanceData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round((value.distanceInMeters + Number.EPSILON) / 1000), clientData: value.customerId });
      theNonCommunicatingCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.assetCount - value.deviceCommunicatingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
      theComminicatingButNotMovingAssetCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.deviceCommunicatingCount - value.deviceMovingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
      theMovingAssetCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.deviceMovingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
      theDelayMoreThan10Data.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.delayMoreThan10, clientData: value.customerId });
    });

    const theChartDataLocationCount = [{
      name: this.translateService.instant('enums.locationEventType.32768'),
      data: theLocationDataIgnitionOn,
      type: 'column',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      color: undefined,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('enums.locationEventType.65536'),
      data: theLocationDataIgnitionOff,
      type: 'column',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.events'),
      data: theLocationDataEvents,
      type: 'column',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }];

    if (this.permissions['Platform_IsReseller']) {
      theChartDataLocationCount.push({
        name: this.translateService.instant('general.delayMoreThan10'),
        data: theDelayMoreThan10Data,
        type: 'line',
        dashStyle: 'dash',
        fillOpacity: 0.5,
        opacity: 1,
        color: '#971c24',
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: 'square'
        },
      });
    }

    const theChartDataDistance = [{
      name: this.translateService.instant('general.distance'),
      data: theDistanceData,
      type: 'areaspline',
      fillOpacity: 0.5,
      opacity: 1,
    }];

    const theChartDataDeviceCount = [{
      name: this.translateService.instant('general.movement'),
      data: theMovingAssetCountData,
      yAxis: 0,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.nomovement'),
      data: theComminicatingButNotMovingAssetCountData,
      yAxis: 0,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.notcommunicating'),
      data: theNonCommunicatingCountData,
      yAxis: 0,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }];

    this.chartLocationCount = this.chartService.generateColumnChartDates(theChartDataLocationCount, {}, theCategories, undefined, 'normal', false);
    this.chartDistance = this.chartService.generateColumnChartDates(theChartDataDistance, {}, theCategories, undefined, 'normal', false, ' Km');
    this.chartActiveDevices = this.chartService.generateColumnChartDates(theChartDataDeviceCount, {}, theCategories, undefined, 'normal', false, '', null, true);

    this.cd.markForCheck();
  }

  // Generate chart engineHours
  generateChartEngineHours(data) {

    const theCategories = [];
    const theIdlingData = [];
    const theMovingData = [];

    // tslint:disable-next-line:forin
    data.forEach(value => {
      theIdlingData.push({ x: moment.utc(value.startDate).toDate().getTime(), y: Math.round(((value.idlingDurationInMinutes / 60) + Number.EPSILON) * 10) / 10, clientData: value.customerId });
      theMovingData.push({ x: moment.utc(value.startDate).toDate().getTime(), y: Math.round(((value.pureDrivingInMinutes / 60) + Number.EPSILON) * 10) / 10, clientData: value.customerId });
    });

    const theChartDataEngineHours = [{
      name: this.translateService.instant('general.idling'),
      data: theIdlingData,
      yAxis: 0,
      type: 'column',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      color: '#EE9234',
      opacity: 1,
      labels: {
        format: '{value} H'
      },
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.moving'),
      data: theMovingData,
      yAxis: 0,
      type: 'column',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      color: '#008E43',
      opacity: 1,
      labels: {
        format: '{value} H'
      },
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }];

    this.chartEngineHours = this.chartService.generateColumnChartDates(theChartDataEngineHours, {}, theCategories, null, true, false, ' H', null, true)

    this.cd.markForCheck();
  }


  // Generate chart engineHours
  generateActivityReport(data) {

    const drivingValue = this.accountUtilization.totalPureDrivingDurationInSeconds / 3600;
    const idlingValue = this.accountUtilization.totalIdlingDurationInSeconds / 3600;
    const ignitionOffValue = (this.accountUtilization.totalDurationInSeconds - this.accountUtilization.totalPureDrivingDurationInSeconds) / 3600;

    const moving = this.actualRound(drivingValue, 0);
    const idling = this.actualRound(idlingValue, 0);
    const ignitionOff = this.actualRound(ignitionOffValue, 0);

    const dataActivity = [{
      name: this.translateService.instant('general.activity'),
      colorByPoint: true,
      minPointSize: 20,
      innerSize: '20%',
      zMin: 0,
      data: [{
        name: this.translateService.instant('enums.locationEventType.65536'),
        y: ignitionOff,
        color: '#971C24',
      }, {
        name: this.translateService.instant('enums.locationEventType.32768'),
        y: moving,
        color: '#008E43',
      }, {
        name: this.translateService.instant('general.idling'),
        y: idling,
        color: '#EE9234',
      }],
    }];

    this.chartActivity = this.chartService.generateVarPieChartDevice(dataActivity, {}, null, false)

    this.cd.markForCheck();
  }


  // Mapping

  filterMarkers(centerMap) {
    console.log('filtering markers');
    // filter the events on map

    this.leafletMapComponent.markerList.forEach(theMarker => {

      if (theMarker) {
        theMarker.filtered = false;
        theMarker.filteredWithoutState = false;

        if (this.search) {
          theMarker.filtered = true;
          if (theMarker.data?.title?.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || theMarker.data?.companyName?.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
            theMarker.filtered = false;
          }
        }

        if (this.selectedAssetGroups && this.selectedAssetGroups.length > 0) {
          const found = theMarker.data.assetGroupIds?.some(ag => this.selectedAssetGroups.includes(ag));
          if (!found) {
            theMarker.filtered = true;
          }
        } else if (this.selectedAssetGroups && this.selectedAssetGroups > 0) {
          const found = theMarker.data.assetGroupIds?.some(ag => this.selectedAssetGroups === ag);
          if (!found) {
            theMarker.filtered = true;
          }
        }

        if (this.deviceType && this.deviceType > 0) {
          if (theMarker.data.deviceTypeId.toString() !== this.deviceType.toString()) {
            theMarker.filtered = true;
          }
        }

        theMarker.filteredWithoutState = theMarker.filtered;

        // Filter states
        if (this.status) {
          if (this.status === 6) {
            if ((theMarker.data.location?.locationType & 2) > 0) {
              theMarker.filtered = true;
            }
          } else {
            if (theMarker.category.toString() !== this.status.toString()) {
              theMarker.filtered = true;
            }
          }
        }
      }
    });

    this.centerMap();

    if (typeof this.leafletMapComponent.pruneCluster.ProcessView === "function") {
      this.leafletMapComponent.pruneCluster.ProcessView();
    }

    this.cd.markForCheck();
  }

  centerMap() {
    const arrBounds = [];
    this.leafletMapComponent.markerList.forEach(theMarker => {
      if (theMarker.data && theMarker.data.location) {
        if (BOUND_CHECK(theMarker.data.location.latitude, MAX_LATITUDE)
          && BOUND_CHECK(theMarker.data.location.longitude, MAX_LONGITUDE)) {

          if (!theMarker.filtered) {
            arrBounds.push(new L.LatLng(theMarker.data.location.latitude, theMarker.data.location.longitude));
          }
        }
      }
    });

    const bounds = L.latLngBounds(arrBounds);
    if (bounds) {
      if (bounds.isValid()) {
        if (this.leafletMapComponent.map) {
          this.leafletMapComponent.map.fitBounds(bounds, { padding: [50, 50], maxZoom: 15 });
        }
      }
    }
  }
}

