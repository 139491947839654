import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';




import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable, catchError, map, of } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class SearchService {
    token: string;
    url: string;

    constructor(private translateService: TranslateService, private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('search');
        this.token = authenticationService.getAuthToken();
    }

    searchEntries(term): Observable<any> {

        if (term) {

            term = encodeURIComponent(term);

            console.log('Search for ' + term);

            this.token = this.authenticationService.getAuthToken();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

            return this.http.get(this.url + term, { headers: headers })
                .pipe(map(data => {
                    const parsedResponse = this.parseResponse(data);
                    console.log('Return list ' + parsedResponse.length);
                    return parsedResponse;
                }), catchError((error: any) => throwError(error || 'Server error')));
        } else {
            return of([]);
        }
    }

    searchUsers(term): Observable<any> {
        term = term.replace('+', '£');

        if (term) {

            console.log('Search for ' + term);

            this.token = this.authenticationService.getAuthToken();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

            return this.http.get(this.url + 'Users/' + term, { headers: headers })
                .pipe(map(data => {
                    const parsedResponse = this.parseResponse(data);
                    console.log('Return list ' + parsedResponse.length);
                    return parsedResponse;
                }), catchError((error: any) => throwError(error || 'Server error')));
        } else {
            return of([]);
        }
    }

    searchDevices(term): Observable<any> {

        if (term) {

            console.log('Search for ' + term);

            this.token = this.authenticationService.getAuthToken();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

            return this.http.get(this.url + 'Devices/' + term, { headers: headers })
                .pipe(map(data => {
                    const parsedResponse = this.parseResponse(data);
                    console.log('Return list ' + parsedResponse.length);
                    return parsedResponse;
                }), catchError((error: any) => throwError(error || 'Server error')));
        } else {
            return of([]);
        }
    }

    parseResponse(data) {
        const responseArray = [];
        data.forEach(item => {
            const response = { id: item.id, name: item.name, group: item.searchResultType, groupname: this.translateService.instant('enums.searchresulttypes.' + item.searchResultType) };
            responseArray.push(response);
        });
        return responseArray;
    }
}
