import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
// Translation module
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// Chart module
import { HighchartsChartModule } from 'highcharts-angular';
import { CustomMissingTranslationHandler } from 'app/common/missingTransLationHandler';

import { CookieService } from 'ngx-cookie-service';
// Notifications
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { AuthGuard } from './auth-guard';
import './common/rxjs-operators';

// Modules
import { ICheckModule } from './modules/icheck/icheck.module';
import { NotificationsModule, NotificationsService } from './modules/notification';
import { WizardModule } from './modules/wizard/wizard.module';
import { AssetService } from './services/asset/asset.service';
import { AssetTypeService } from './services/asset/assetTypes.service';
import { AuditLogService } from './services/auditlog/auditlog.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { CacheService } from './services/common/cache.service';
import { ColorService } from './services/common/color.service';
import { TranslatorService } from './services/common/translator.service';
import { DashboardService } from './services/dashboard/dashboard.service';
import { DeviceService } from './services/device/device.service';
import { DeviceSettingChangeService } from './services/device/deviceSettingChange.service';
import { DeviceTypeService } from './services/devicetypes/devicetypes.service';
import { DriverService } from './services/driver/driver.service';
import { EventService } from './services/events/events.service';
import { InfoService } from './services/info/info.service';
import { JobsService } from './services/jobs/jobs.service';
import { ErrorLogService } from './services/logging/error-log.service';
import { LoggingErrorHandlerOptions, LoggingErrorHandlerProviders } from './services/logging/logging-error-handler';
import { LoggingService } from './services/logging/logging.service';
import { DeviceSensorsService } from './services/sensors/deviceSensors.service';
import { SensorService } from './services/sensors/sensors.service';
import { SensorsTypesService } from './services/sensors/sensorTypes.service';
import { TagService } from './services/tag/tag.service';
import { TripService } from './services/trip/trip.service';
import { UserService } from './services/users/user.service';
import { WaslService } from './services/wasl/wasl.service';
// App views
import { AppviewsModule } from './views/appviews.module';
// App modules/components
import { SharedLayoutsModule } from './views/shared/sharedlayouts.module';
import { GeofenceService } from './services/geofence/geofence.service';
import { AccountService } from './services/account/account.service';
import { LocationService } from './services/locations/locations.service';
import { DriverGroupsService } from './services/driver/driverGroups.service';
import { GeofenceGroupsService } from './services/geofence/geofenceGroups.service';
import { AssetGroupsService } from './services/asset/assetGroups.service';
import { BatchService } from './services/batch/batch.service';
import { ResellerService } from './services/resellers/resellers.service';
import { MaintenanceService } from './services/maintenance/maintenance.service';
import { FuelService } from './services/fuel/fuel.service';
import { TriggerService } from './services/triggers/triggers.service';
import { ArchiveRetentionRuleService } from './services/archiveretentionrule/archiveretentionrule.service';
import { MaintenanceTypesService } from './services/maintenance/maintenanceTypes.service';
import { CountriesService } from './services/countries/countries.service';
import { UserContactGroupService } from './services/users/userContactGroups.service';
import { UserContactService } from './services/users/userContacts.service';
import { DownloadService } from './services/common/download.service';
import { SignalrService } from './services/signalr/signalr.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DistanceUnitService } from './common/distanceunit.service';
import { UserRoleService } from './services/users/userRole.service';
import { TrailerService } from './services/trailer/trailer.service';
import { WorkingHoursService } from './services/workinghours/workingHours.service';
import { WarehouseService } from './services/warehouse/warehouse.service';
import { ngxLoadingAnimationTypes } from './modules/loading/ngx-loading-config';
import { NgxLoadingModule } from './modules/loading/ngx-loading.module';
import { ExportService } from './services/export/export.service';
import { ReportService } from './services/reports/report.service';
import { NotificationLogService } from './services/notificationlog/notificationlog.service';
import { ProjectService } from './services/project/project.service';
import { TriggerScheduleService } from './services/triggerSchedule/triggerSchedule.service';
import { ServiceAnnouncementService } from './services/announcements/serviceAnnouncements';
import { ClientAnnouncementService } from './services/announcements/clientAnnouncements';
import { SensorTemplateService } from './services/sensorTemplate/sensorTemplate.service';
import { StateService } from './services/state/state.service';
import { DeviceRoutingConfigurationService } from './services/deviceroutingconfiguration/deviceroutingconfiguration.service';
import { WebhookEndpointService } from './services/webhookendpoints/webhookEndpoint.service';
import { CarSharingService } from './services/carsharing/carsharing.service';
import { HttpRequestInterceptor } from './services/authentication/authentication.interceptor';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AuthGuardSharing } from './auth-guard-sharing';
import { ShareTokenService } from './services/sharetoken/shareToken.service';
import { CustomCommandService } from './services/customcommand/customcommand.service';

// Export functions
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// todo : https://www.intertech.com/Blog/angular-4-tutorial-handling-refresh-token-with-new-httpinterceptor/

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    NgSelectModule,
    NgOptionHighlightModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    HighchartsChartModule,
    SharedLayoutsModule,
    AppviewsModule,
    WizardModule,
    ICheckModule.forRoot(),
    RouterModule.forRoot(ROUTES, { onSameUrlNavigation: 'reload' }),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(255,255,255,0.7)',
      backdropBorderRadius: '4px',
      primaryColour: '#DC8139',
      secondaryColour: '#DC8139',
      tertiaryColour: '#DC8139'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
    }),
    HttpClientModule,
    NotificationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    ErrorLogService,
    LoggingErrorHandlerProviders,
    {
      provide: LoggingErrorHandlerOptions,
      useValue: {
        rethrowError: false,
        unwrapError: false
      }
    },
    AuthGuard,
    AuthGuardSharing,
    DownloadService,
    TranslatorService,
    CountriesService,
    AuthenticationService,
    BatchService,
    AuditLogService,
    NotificationLogService,
    DeviceService,
    DeviceSettingChangeService,
    MaintenanceService,
    MaintenanceTypesService,
    TriggerService,
    TriggerScheduleService,
    ArchiveRetentionRuleService,
    DistanceUnitService,
    FuelService,
    StateService,
    AssetGroupsService,
    ResellerService,
    DeviceTypeService,
    AssetTypeService,
    AssetService,
    EventService,
    DashboardService,
    JobsService,
    DriverService,
    DriverGroupsService,
    TrailerService,
    ProjectService,
    GeofenceService,
    GeofenceGroupsService,
    WaslService,
    TripService,
    UserService,
    UserRoleService,
    AccountService,
    CookieService,
    LoggingService,
    DeviceSensorsService,
    LocationService,
    SensorService,
    SensorsTypesService,
    TagService,
    CacheService,
    NotificationsService,
    ColorService,
    InfoService,
    UserContactService,
    UserContactGroupService,
    WorkingHoursService,
    WarehouseService,
    ExportService,
    ServiceAnnouncementService,
    ClientAnnouncementService,
    SensorTemplateService,
    ShareTokenService,
    ReportService,
    SignalrService,
    DeviceRoutingConfigurationService,
    WebhookEndpointService,
    CarSharingService,
    CustomCommandService,
    {
      provide: APP_INITIALIZER,
      useFactory: (signalrService: SignalrService) => () => signalrService.initiateSignalrConnection(),
      deps: [SignalrService],
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
