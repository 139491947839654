import { DeviceFuelThreshold } from '../../models/device.model';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { FhChartService } from '../../services/charts/charts.service';
import { DeviceService } from '../../services/device/device.service';
import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { getDefaultDpConfig } from 'app/common/gridhelper';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

import { marker, LatLngExpression, icon } from 'leaflet';
import * as L from 'leaflet';
import { colorArray, colorArray2, getIconPath } from 'app/common/globals';
import { AnalogFunctions, DeviceAuxiliary, DeviceOutput, FormMode, StorageType } from 'app/common/enums';
import { AccountService } from 'app/services/account/account.service';
import { FuelService } from 'app/services/fuel/fuel.service';
import { Fuel } from 'app/models/fuel.model';

import * as Highcharts from 'highcharts';

// Moment timezone
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { StorageHelper } from 'app/common/storagehelper';
import { TripService } from 'app/services/trip/trip.service';

window['moment'] = Moment;
mTZ()
@Component({
    selector: 'fh-device-fuel',
    templateUrl: 'fuel.template.html',
    providers: [FhChartService]
})

export class DeviceFuelViewComponent implements OnInit, OnDestroy {
    Highcharts: typeof Highcharts = Highcharts;

    @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;
    @ViewChild('addModal') addModal: ModalDirective;
    // Form
    formModeFuel: FormMode = FormMode.read;

    // FormAdd
    formModeAdd: FormMode = FormMode.add;
    fuel = new Fuel;

    permissions = {};

    options;
    selectedRow: any;
    selected: any;
    theTrips = [];
    filteredFuelEvents = [];
    timeoutHandler;
    chartFuel: any;
    sensors;
    sub;
    device: Device;
    deviceId;

    loading = false;
    loadingSensors = false;
    loadingGeofences = false;
    geofences = [];
    maps: any;
    theMarker;

    success;
    error;
    warning;

    mapHeight = 250;

    filter;
    currentPage;
    colorArray = colorArray2;

    loadingFuel = false;
    fuelEvents = [];
    tripLocations = [];
    tripFeatureGroup: L.FeatureGroup<any>;
    eventsFeatureGroup: any;

    // Daterange
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    daterangepickerModel: Date[];
    maxDate = new Date();

    certainty = 50;

    timezoneIana;
    trips = {};
    selectedEvent: any;
    fuelThresholds: DeviceFuelThreshold;

    hasFuelEnabled = false;
    hasVepamonEnabled = false;
    storageType = StorageType.LocalStorage;
    showMapOnSide = false;

    permissionName = 'FuelEvents_View';
    sensorCalibrationPoints = [];

    constructor(private chartService: FhChartService, private tripService: TripService, private translate: TranslateService, private fuelService: FuelService, private accountService: AccountService, private http: HttpClient, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService, private storageHelper: StorageHelper) {
        this.device = null;
        this.sensors = [];
        this.theTrips = [];
        this.fuelThresholds = new DeviceFuelThreshold;

        this.permissions = this.authenticationService.permissions;

        this.timezoneIana = this.authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(3, 'month').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, this.authenticationService);

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'showMapOnSide').subscribe((result) => {
            this.showMapOnSide = JSON.parse(result) === true;

            if (this.showMapOnSide) { this.mapHeight = 330; }
        });

        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.isAnimated = true;
        this.dpConfig.withTimepicker = true;
        this.dpConfig.keepDatepickerOpened = true;
    }

    ngOnInit() {
        this.device = new Device;
        this.loading = true;
        this.device.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.deviceId = id;
            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;
                // this.device.customFields = JSON.parse(this.device.customFields);
                this.loading = false;

                this.getDeviceFuelSettings();

                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }
            });

            this.loadingFuel = true;
            this.fuelService.getFuelEventsByDevice(id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.certainty).subscribe(fuelEvents => {
                this.filteredFuelEvents = fuelEvents;
                this.loadingFuel = false;
                this.loading = false;
            });
        }, error => {
            this.error = error;
            this.error.statusText = 'Error fetching device';

            setTimeout(() => {
                this.router.navigate(['/Devices/Overview']);
            }, 3000);
        });
    }

    dateChanged(event) {
        const that = this;
        if (event !== null) {
            this.loadingFuel = true;
            this.fuelService.getFuelEventsByDevice(this.device.id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.certainty).subscribe(fuelEvents => {
                this.filteredFuelEvents = fuelEvents;

                this.loadingFuel = false;
                this.loading = false;
            });
        }
    }

    setFormModeFuel(mode) {
        this.formModeFuel = mode;

        if (this.formModeFuel === FormMode.read) {
            this.loading = true;
            this.getDeviceFuelSettings();
        }
    }

    onSaveFuelThresholds() {
        this.loading = true;

        this.fuelThresholds.fillupThreshold = this.fuelThresholds.fillupThreshold;
        this.fuelThresholds.fuelTank1Capacity = this.fuelThresholds.fuelTank1Capacity;
        this.fuelThresholds.fuelTank2Capacity = this.fuelThresholds.fuelTank2Capacity;
        this.fuelThresholds.theftThreshold = this.fuelThresholds.theftThreshold;

        this.fuelThresholds.fuelCalibrations = this.sensorCalibrationPoints;

        this.deviceService.updateFuelThresholds(this.device.id, this.fuelThresholds).subscribe(result => {
            this.loading = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Thresholds are successfull updated'
            };

            this.getDeviceFuelSettings();
        }, error => {
            this.error = error;
            this.loading = false;
        });

    }

    // Adding
    showModal() {
        this.addModal.show();
    }

    hideModal() {
        this.addModal.hide();

        this.fuel = new Fuel();
        this.fuel.timestamp = new Date();
    }

    onInsertFuel() {
        this.addModal.hide();

        this.loadingFuel = true;

        this.fuel.assetId = this.device.asset?.id;
        this.fuel.deviceId = this.device.id;

        this.fuelService.saveFuelEvent(this.fuel).subscribe(result => {

            this.success = {
                statusText: 'Success',
                success: 'Fuel event has been successfully added.'
            };

            this.fuel = new Fuel();
            this.fuel.timestamp = new Date();

            setTimeout(() => {
                this.dateChanged(true);
            }, 3000);

            this.loadingFuel = false;
        }, error => {
            this.success = null;
            this.error = error;
            this.loadingFuel = false;
        });
    }

    onDeleteFuel() {
        this.loadingFuel = true;

        this.fuelService.deleteFuelEvent(this.fuel.id).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Fuel is successfully deleted.'
            };

            this.dateChanged(true);

            this.loadingFuel = false;
        }, error => {
            this.success = null;
            this.error = error;
            this.loadingFuel = false;
        });
    }

    getDeviceFuelSettings() {
        this.loading = false;
        this.hasVepamonEnabled = false;

        const analogPorts = this.device.settings.analogPorts;
        const outputPorts = this.device.settings.inputPorts;
        const aux = this.device.settings.deviceAuxiliary;

        // tslint:disable:no-bitwise
        if (aux.filter(x => (x.id & BigInt(DeviceAuxiliary.FuelSensorFlags)) > 0).length > 0) {
            this.hasFuelEnabled = true;
        } else if (analogPorts.filter(x => (BigInt(x.id) & BigInt(AnalogFunctions.FuelSensorFlags)) > 0).length > 0) {
            this.hasFuelEnabled = true;
        } else {
            this.warning = {
                statusText: 'Warning',
                warning: 'There is no fuel sensor configured on this device. Fuel settings can not be configured!'
            };
        }

        if (this.hasFuelEnabled) {
            if (aux.filter(x => (x.id & BigInt(DeviceAuxiliary.VepamonFuelSensorFlags)) > 0).length > 0) {
                console.log('fetch vepamon fuel sensor');

                this.hasVepamonEnabled = true;
            };
        }
        // tslint:enable:no-bitwise

        this.loadingGeofences = true;
        this.accountService.getGeofencesByAccount(this.device.accountId).subscribe(geofences => {
            this.loadingGeofences = false;
            this.geofences = geofences;
        });

        this.deviceService.getFuelThresholdsById(this.device.id).subscribe(fuelThresholds => {
            this.fuelThresholds = fuelThresholds;
        });

        this.deviceService.getVepamonCalibrationsById(this.device.id).subscribe(vepamonCalibrations => {
            this.sensorCalibrationPoints = vepamonCalibrations;
        });
    }

    ngOnDestroy(): void {
        clearTimeout(this.timeoutHandler);
    }

    groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
        list.reduce((previous, currentItem) => {
            const group = getKey(currentItem);
            if (!previous[group]) { previous[group] = []; }
            previous[group].push(currentItem);
            return previous;
        }, {} as Record<K, T[]>);


    // Chart
    fetchLocations(fuelEvent) {
        this.loadingFuel = true;
        this.selectedEvent = fuelEvent;

        const hoursBeforeAndAfter = 6;

        this.tripService.getMessagesForDevice(this.device.id, Moment.utc(fuelEvent.eventTimeStamp).add(-1 * hoursBeforeAndAfter, 'hours'), Moment.utc(fuelEvent.eventTimeStamp).add(hoursBeforeAndAfter, 'hours'), '', 2).subscribe(locations => {
            const locations2 = locations.filter(x => x.latitude !== '0' && x.latitude !== 0).sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));
            this.parseLocations(locations2);
            this.loadingFuel = false;
        }, error => {
            this.error = error;
            this.loadingFuel = false;
        });


        if (this.eventsFeatureGroup) {
            this.leafletMapComponent.eventsLayer.removeLayer(this.eventsFeatureGroup);
        }

        this.eventsFeatureGroup = L.featureGroup();
        this.eventsFeatureGroup.addTo(this.leafletMapComponent.eventsLayer);


        if (fuelEvent.latitude !== '0' && fuelEvent.latitude !== 0) {

            let faIcon = '';
            let iconColor = '';

            if (fuelEvent.refuelVsTheft === 'Theft') {
                iconColor = 'orange';
                faIcon = 'fa-fingerprint';
            }

            if (fuelEvent.refuelVsTheft === 'Refuel') {
                iconColor = 'green';
                faIcon = 'fa-charging-station';
            }

            const markerIcon = L['StatusMarker'].icon({
                iconSize: [20, 20],
                iconAnchor: [10, 10],
                shadowSize: [0, 0],
                shadowAnchor: [0, 0],
                icon: faIcon,
                prefix: 'fa',
                iconColor: iconColor,
                className: 'm360_12',
            });

            const customPopup = `<div style="width:300px; overflow: auto;" class="leaflet-mappopup">
                    <div class="header">` + 'Location' + `</div>
                    <div class="content">` + 'Event' + `:</div><div class="content">` + fuelEvent.refuelVsTheft + `</div>
                </div>`;

            if (fuelEvent.latitude) {
                const locationMarker = L.marker(new L.LatLng(fuelEvent.latitude, fuelEvent.longitude), { icon: markerIcon });
                locationMarker.bindPopup(L.popup().setContent(customPopup), {
                    closeButton: false
                });
                locationMarker.addTo(this.eventsFeatureGroup);
                this.leafletMapComponent.oms.addMarker(locationMarker);
            }
        }
    }

    parseLocations(locations) {
        this.fuelEvents = locations;
        this.loadingFuel = false;

        this.tripLocations = locations.filter(x => x.latitude !== 0);

        // Fix timezone
        this.tripLocations.forEach(location => {
            location.timestamp = Moment.utc(location.timestamp)['tz'](this.timezoneIana)
        });

        this.trips = {};
        this.trips = this.groupBy(this.tripLocations, i => i.sourceId);

        this.drawChart(this.tripLocations);
        this.drawTrips(this.trips);
    }

    drawChart(tripLocations) {
        const iconPath = getIconPath(this.device.asset?.icon)[1];

        const theIcon = L.icon({
            iconUrl: iconPath,
            // className: 'markerPlayTrip',
            iconAnchor: [16, 16],
        });

        // The speed gauge
        // data, km, fuel percentage, deviation, symbol

        const theChartDataDistance = [];
        const theChartDataLevel = [];
        const theChartFueling = [];
        const theChartFuelingEvents = [];
        const theChartDataIgnition = [];
        const theChartDataSpeed = [];
        const theChartDataRpm = [];

        const that = this;

        // get first odo
        const cachedDistanceOffset = tripLocations.filter(x => x.odoValue > 0).sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))[0]?.odoValue;

        $.each(this.tripLocations, function (index, value) {
            const dateTime = value.timestamp.unix() * 1000;
            const coordinate = [value.latitude, value.longitude];

            if (value.latitude !== '0' && value.latitude !== 0 && value.odoValue > 0) {
                const distance = (value.odoValue - cachedDistanceOffset) / 1000;
                theChartDataDistance.push({ x: dateTime, y: distance, latlon: coordinate });
            }

            if (value.fuelLevel && value.fuelLevel !== 0) {
                theChartDataLevel.push({ x: dateTime, y: value.fuelLevel, latlon: coordinate });
            }

            theChartDataSpeed.push({ x: dateTime, y: value.speedInKph > 0 ? 1 : 0, latlon: coordinate });
            theChartDataIgnition.push({ x: dateTime, y: value.ignition ? 1 : 0, latlon: coordinate });

            theChartDataRpm.push({ x: dateTime, y: value.rpm, latlon: coordinate });

        });

        theChartFuelingEvents.push({
            x: this.selectedEvent.eventTimeStamp,
            y: this.selectedEvent.endFuelEventFuelLevelInLiters,
            text: 'x',
            title: this.selectedEvent.refuelVsTheft == 1 ? 'Theft' : 'Refuel',
            latlon: [this.selectedEvent.latitude, this.selectedEvent.longitude]
        });

        theChartFueling.push({
            x: this.selectedEvent.eventTimeStamp,
            y: this.selectedEvent.endFuelEventFuelLevelInLiters,
            latlon: [this.selectedEvent.latitude, this.selectedEvent.longitude]
        });

        let theData = [];

        theData = [{
            name: 'Distance',
            type: 'area',
            turboThreshold: 0,
            threshold: null,
            fillOpacity: 0.5,
            opacity: 0.5,
            color: '#ccc',
            marker: {
                enabled: false,
                lineWidth: 1,
            },
            yAxis: 0,
            data: theChartDataDistance
        }, {
            name: 'Events',
            type: 'flags',
            color: '#5AB867',
            enableMouseTracking: true,
            marker: {
                enabled: true,
                lineWidth: 4,
                symbol: 'square'
            },
            zIndex: 5,
            yAxis: 1,
            data: theChartFuelingEvents
        }, {
            name: 'Speed',
            type: 'spline',
            color: '#39DD93',
            visible: false,
            yAxis: 3,
            step: 'left',
            zIndex: 4,
            marker: {
                enabled: false,
                lineWidth: 2,
                radius: 5,
                symbol: 'square'
            },
            data: theChartDataSpeed
        }, {
            name: 'Ignition',
            type: 'line',
            color: '#00E0C6',
            visible: false,
            yAxis: 4,
            step: 'left',
            zIndex: 4,
            marker: {
                enabled: false,
                lineWidth: 2,
                radius: 5,
                symbol: 'square'
            },
            data: theChartDataIgnition
        }, {
            name: 'Fuel level',
            type: 'spline',
            color: '#7589FF',
            dashStyle: 'ShortDot',
            fillOpacity: 0.2,
            visible: true,
            step: 'left',
            marker: {
                enabled: true,
                lineWidth: 0.5,
            },
            yAxis: 1,
            zIndex: 3,
            data: theChartDataLevel
        }, {
            name: 'RPM',
            type: 'spline',
            dashStyle: 'ShortDashDot',
            color: '#ff00f0',
            visible: false,
            step: 'left',
            marker: {
                enabled: false,
                lineWidth: 2,
                symbol: 'square'
            },
            yAxis: 6,
            zIndex: 3,
            data: theChartDataRpm
        }];

        // Plotlines
        const filteredEvents = theChartFueling;

        const plotLines = [];
        const plotBands = [];

        plotLines.push({
            color: '#b81313',
            dashStyle: 'dash',
            width: 2,
            value: (Moment.utc(this.selectedEvent.eventTimeStamp).unix() * 1000),
        });

        filteredEvents.forEach(event => {

            if (event[0] !== (Moment.utc(this.selectedEvent.eventTimeStamp).unix() * 1000)) {
                plotLines.push({
                    color: '#ddd',
                    dashStyle: 'dash',
                    width: 1,
                    opacity: 0.5,
                    value: event[0],
                });
            }
        });

        // tripStarts.forEach(event => {
        //     plotBands.push({
        //         color: event.color,
        //         from: event.x,
        //         to: event.z,
        //         opacity: 0.1
        //     });
        // });


        const map = this.leafletMapComponent.map; // this.leafletMapComponent.map

        this.chartFuel = this.chartService.generateFuelChart(theData, plotLines, plotBands, map, theIcon);
    }

    drawTrips(trips) {

        if (this.tripFeatureGroup) {
            this.leafletMapComponent.tripLayer.removeLayer(this.tripFeatureGroup);
        }

        this.tripFeatureGroup = L.featureGroup();

        let ident = 0;

        Object.keys(trips).forEach(tripKey => {
            ident++;
            const trip = trips[tripKey];

            const pointList = [];

            const color = this.colorArray[ident % this.colorArray.length];

            if (trip.length > 0) {

                const startIcon = new L['NumberMarker'].Icon({
                    backgroundColor: color,
                    className: 'm360',
                    color: '#fff',
                    number: ident,
                });

                const startMarker = L.marker(new L.LatLng(trip[0].latitude, trip[0].longitude), { icon: startIcon });
                this.leafletMapComponent.oms.addMarker(startMarker);
                startMarker.addTo(this.tripFeatureGroup);

                // const endIcon = L.icon({
                //     iconUrl: 'assets/images/icons/end.png',
                //     className: 'markerEnd',
                // });

                // const endMarker = L.marker(new L.LatLng(trip[trip.length - 1].latitude, trip[trip.length - 1].longitude), { icon: endIcon });
                // this.leafletMapComponent.oms.addMarker(endMarker);
                // endMarker.addTo(this.tripFeatureGroup);


                trip.forEach(location => {
                    if (location.latitude !== '0' && location.latitude !== 0) {
                        pointList.push(new L.LatLng(location.latitude, location.longitude));
                    }
                });

                const tripPolyLine = new L.Polyline(pointList, {
                    color,
                    weight: 2,
                    opacity: 0.4,
                    smoothFactor: 1,
                    dashArray: '10, 5'
                }).addTo(this.tripFeatureGroup);

                const decoratorLine = L['polylineDecorator'](tripPolyLine, {
                    patterns: [
                        { offset: 24, repeat: 100, symbol: L['Symbol']['arrowHead']({ pixelSize: 15, pathOptions: { fillOpacity: 0.5, color, weight: 0, stroke: true } }) }
                    ]
                }).addTo(this.tripFeatureGroup);

                this.tripFeatureGroup.addTo(this.leafletMapComponent.tripLayer);

            };

        });

        const bounds = this.leafletMapComponent.tripLayer.getBounds();

        if (bounds.isValid()) {
            this.leafletMapComponent.map.fitBounds(bounds, { padding: [15, 15], animate: true, duration: 0.5 });
        }
    }

    onMapReady(map) {
        setTimeout(() => {
            this.leafletMapComponent.invalidateSize();
        }, 10);
    }
}

