import {
    Component, AfterViewInit, Input, ElementRef, ViewChild, OnInit, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AssetGroup } from 'app/models/group.model';
import { AssetGroupsService } from 'app/services/asset/assetGroups.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

const noop = () => {
};

@Component({
    selector: 'fh-asset-group-input',
    templateUrl: './assetGroupSelector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: AssetGroupInputComponent }
    ]
})

export class AssetGroupInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
    @ViewChild('input', { static: false }) selector: NgSelectComponent;

    @Input() placeholder: string;
    @Input() clearable = true;
    @Input() selectedAccountId;
    @Input() autoSelect = true;
    @Input() readonly = false;
    @Input() multiple = true;
    @Input() tableInline;
    @Input() activeOnly = false;
    @Input() canAddNew = false;
    @Input() hideSystemGroups = false;
    @Input() hideEmptyGroups = true;

    @Output() onChanged = new EventEmitter();

    assetGroups = []

    loadingAssetGroups = false;

    serverResponses = {};
    createdItems = [];

    @ViewChild('input') el: ElementRef;

    private _value: any[];
    private _onChange: (_: any) => void = noop;

    get value(): any {
        return this._value;
    };

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this._onChange(v);
            this.cd.markForCheck();
        }
    }

    constructor(private assetGroupService: AssetGroupsService, private authenticationService: AuthenticationService, private cd: ChangeDetectorRef) {
    }

    createNew(displayName: string) {
        this.loadingAssetGroups = true;
        this.serverResponses[displayName] = false;

        this.cd.markForCheck();

        const itemId = this.createdItems.length;
        this.createdItems[itemId] = -1;

        const assetGroup = new AssetGroup();
        assetGroup.accountId = this.selectedAccountId;
        assetGroup.ownerId = this.authenticationService.getUserId();
        assetGroup.name = displayName;

        this.assetGroupService.saveAssetGroup(assetGroup).subscribe(result => {
            if (result?.isSuccess) {
                this.serverResponses[displayName] = false;
                this.createdItems[itemId] = result.id;
            }

            this.selector['_updateNgModel']();
            this.loadingAssetGroups = false;
            this.cd.markForCheck();
        }, error => {
            this.serverResponses[displayName] = error.error;

            this.selector['_updateNgModel']();
            this.loadingAssetGroups = false;
            this.cd.markForCheck();
        });

        const self = this;
        return { get id(): any { return self.createdItems[itemId] }, itemCount: 0, displayName };
    }

    updateGroups() {

    }

    assetGroupChanged() {
        this.onChanged.emit(this.value);
        this.cd.markForCheck();
    }

    ngOnChanges() {
        setTimeout(() => {
            // Get accounts again
            this.loadAssetGroups();
            this.cd.markForCheck();
        }, 0);
    }

    showAll(event, assetgroup) {
        assetgroup.showAll = !assetgroup.showAll;
        event.stopPropagation();
    }

    loadAssetGroups(callback = () => {}) {
        this.assetGroups = [];
        this.cd.markForCheck();

        if (this.selectedAccountId) {
            this.loadingAssetGroups = true;
            this.cd.markForCheck();

            if (this.selectedAccountId && this.selectedAccountId !== 0) {
                this.assetGroupService.getAssetGroups(this.selectedAccountId, true).subscribe(result => {
                    this.assetGroups = result.filter(x => !(x.emailLoginName.startsWith('_DEL_') || (this.hideEmptyGroups && x.itemCount === 0)));

                    if (this.activeOnly) {
                        this.assetGroups = this.assetGroups.filter(x => x.groupType !== 14 && x.groupType !== 15);
                    }

                    if (this.hideSystemGroups) {
                        this.assetGroups = this.assetGroups.filter(x => x.groupType !== 3);
                    }

                    // Autoselect
                    if (this.value == null && !this.readonly && this.assetGroups.length > 0 && this.autoSelect) {
                        console.log('Setting default to ' + this.assetGroups[0].id);
                        this.value = this.multiple ? [this.assetGroups[0].id] : this.assetGroups[0].id;
                    }

                    this.assetGroups = this.assetGroups.sort((a, b) => {
                        return a.displayName.localeCompare(b.displayName, 'en', { sensitivity: 'base' });
                    });

                    this.loadingAssetGroups = false;
                    this.cd.markForCheck();

                    callback();
                });
            } else {
                this.loadingAssetGroups = false;
                this.cd.markForCheck();
            }
        }
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        const __this = this;
    }

    writeValue(value: any) {
        this._value = value;
        this.cd.markForCheck();
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
        this.cd.markForCheck();
    }

    registerOnTouched(fn: any) {
        this.cd.markForCheck();
    }
}
