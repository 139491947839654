<div class="col-lg-12" style="position: absolute; top: 200px;z-index: 2000;width: 50%;">
    <fh-error [error]="error"></fh-error>
</div>

<!-- Navigation -->
<fh-overview-navigation></fh-overview-navigation>
<!-- End Navigation -->

<!-- Start map-->
<div id="mapOverviewContainer" class="shadow-inner">
    <ngx-loading [show]="loading"></ngx-loading>

    <div *ngIf="isSpinning" style="position: absolute; z-index: 1400; bottom: 15px; left: 15px;"> <i
            tooltip="{{'general.updated' | translate }}" style="margin-left: 3px;"
            class="fa fa-fw fa-spinner fa-spin eye-active green"></i>
    </div>

    <div id="map" style="height: 100%; width: 100%; border-radius: 5px;" leaflet [leafletOptions]="options"
        (leafletMapReady)="onMapReady($event)">
    </div>

    <!-- Sidebar -->
    <div *ngIf="fleetOverviewMode !== 'History'" [@slideInOut]="menuState" class="slideMenu sidebar-bg">
        <div class="closeButton" (click)="deselectDevice()">
            <i class="fa fa-fw fa-times"></i>
        </div>
        <div (click)="deselectDevice()" class="slideMenuControl">
        </div>
        <fh-map-sidebar [selectedDevice]="selectedDevice" [loadingSidebar]="loadingSidebar"
            [selectedDeviceExtended]="selectedDeviceExtended"
            [selectedDeviceUtilization]="selectedDeviceUtilization"></fh-map-sidebar>
    </div>
</div>