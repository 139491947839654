
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// `wait` milliseconds.
const debounce = (func, wait) => {
     let timeout;

     return function executedFunction(...args) {
          const later = () => {
               timeout = null;
               func(...args);
          };

          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
     };
};

function isInt(value) {
     const x = parseFloat(value);
     // tslint:disable-next-line:no-bitwise
     return !isNaN(value) && (x | 0) === x;
}

export function createSearch(dtInstance, dynamicFilterContainer, excluding = [], createSelectBoxes = false, columns = [], dtOptions): void {
     dynamicFilterContainer.html('');

     // Remove search blocks
     $('div#filter-labels').remove();
     const filterRows = $('<div/>', { id: 'filter-labels' }).insertBefore($('table[fh-datatable]').closest('.dataTables_wrapper').parent());
     filterRows.hide();

     dtInstance.columns().every(function () {
          const that = this;

          const headerName = that.header().textContent;

          const column = columns.length > -0 && columns.find(x => x.title === headerName);

          const returnedFunction = debounce(function (textBox) {
               that.search(textBox['value'], false).draw();
          }, 800);

          const returnedSmartFunction = debounce(function (textBox, selectBox) {
               that.search(textBox.val() !== '' ? selectBox.val() + ' ' + textBox.val() : '', true, true).draw();
          }, 800);

          if (((that.visible() || that['column'](that.index()).search()) && headerName !== '' && column.name && excluding.indexOf(column.name) === -1)) {

               // Create colum
               const divColumn = $('<div/>', {
                    id: 'search' + that.index(),
                    'class': 'col-sm-6',
                    title: ''
               });

               // Create group
               const divColumnInner = $('<div/>', {
                    id: 'search' + that.index(),
                    'class': 'input-group ' + (that['column'](that.index()).search() !== '' ? 'input-active' : ''),
                    'style': 'margin-bottom: 5px;',
                    title: headerName
               });

               // Create icon

               let iconName = column.iconName !== undefined ? column.iconName : 'fa fa-fw fa-pen';
               if (column.name.toLowerCase().includes('date') || column.name.toLowerCase().includes('time')) {
                    iconName = 'fa fa-fw fa-calendar-alt';
               }
               if (column.name.toLowerCase().includes('phone')) {
                    iconName = 'fa fa-fw fa-phone';
               }
               if (column.name.toLowerCase().includes('account') || column.name.toLowerCase().includes('company')) {
                    iconName = 'fa fa-fw fa-address-card';
               }
               if (column.type === 'select') {
                    iconName = 'fa fa-fw fa-list-dropdown';
               }

               const icon = $('<i/>', {
                    id: 'search_' + that.index(),
                    'class': iconName,
                    title: headerName
               });

               const iconDiv = $('<div/>', {
                    id: 'search_' + that.index(),
                    'class': 'input-group-prepend',
                    title: headerName
               });

               icon.appendTo(iconDiv);

               // Create the inputs
               switch (column.type) {
                    case 'select': {
                         // Add icon
                         iconDiv.appendTo(divColumnInner);

                         const select = $('<select class="form-control auto_search"><option value="">' + dtOptions.language['choose'] + ' ' + headerName + '</option></select>')
                              .on('change', function () {
                                   const val = $(this).val();

                                   if (isInt(val)) {
                                        // Use regex
                                        that.column(that.index())
                                             .search(val ? '= ' + val : '', true, false)
                                             .draw();
                                   } else {
                                        // Use text based search
                                        that.column(that.index())
                                             .search(val, false, false)
                                             .draw();
                                   }
                              });

                         const value = that['column'](that.index()).search();

                         column.options.forEach(o => {
                              if (o.group != null) {
                                   select.append('<optgroup label="' + o.group + '">');

                                   if (o.items != null) {
                                        o.items.forEach(i => {
                                             const isSelected = value.toString().replace('= ', '') === i.id.toString();
                                             select.append('<option value="' + i.id + '" ' + (isSelected ? 'selected=selected' : '') + '>' + i.value + '</option>')
                                        });
                                   }

                                   select.append('</optgroup>');
                              } else {
                                   const isSelected = value.toString().replace('= ', '') === o.id.toString();
                                   select.append('<option value="' + o.id + '" ' + (isSelected ? 'selected=selected' : '') + '>' + o.value + '</option>')
                              }
                         });

                         select.appendTo(divColumnInner);
                         break;
                    }
                    case 'checkBox': {

                         const checkboxIcon = $('<i/>', {
                              id: 'checkBox_' + that.index(),
                              'class': 'auto_checkbox fa fa-fw fa-th',
                              title: headerName
                         });

                         if (that['column'](that.index()).search() === 'true') {
                              checkboxIcon.removeClass('fa-th');
                              checkboxIcon.addClass('fa-check');
                              checkboxIcon.css('color', 'rgba(0, 142, 67, 0.8)');
                         }

                         if (that['column'](that.index()).search() === 'false') {
                              checkboxIcon.removeClass('fa-th');
                              checkboxIcon.addClass('fa-times');
                              checkboxIcon.css('color', 'rgba(151, 28, 36, 0.8)');
                         }

                         const checkboxIconSpan = $('<span/>', {
                              id: 'checkBox' + that.index(),
                              'class': 'input-group-prepend hand',
                              title: headerName
                         });

                         checkboxIcon.appendTo(checkboxIconSpan);

                         checkboxIconSpan.appendTo(divColumnInner);

                         const textBox = $('<input/>', {
                              name: 'checkboxName_' + that.index(),
                              id: 'checkboxName_' + that.index(),
                              style: 'width:100%',
                              placeholder: headerName,
                              disabled: 'true',
                              class: 'form-control auto_search'
                         });

                         divColumnInner.on('click', function () {
                              const value = that['column'](that.index()).search();

                              if (value === 'true') {
                                   that.search('false').draw();
                                   checkboxIcon.addClass('fa-times');
                                   checkboxIcon.css('color', 'rgba(151, 28, 36, 0.8)');
                                   checkboxIcon.removeClass('fa-check');
                              } else if (value === 'false') {
                                   that.search('').draw();
                                   checkboxIcon.removeClass('fa-times');
                                   checkboxIcon.addClass('fa-th');
                                   checkboxIcon.css('color', '');
                              } else {
                                   that.search('true').draw();
                                   checkboxIcon.removeClass('fa-th');
                                   checkboxIcon.css('color', 'rgba(0, 142, 67, 0.8)');
                                   checkboxIcon.addClass('fa-check');
                              }
                         });

                         textBox.appendTo(divColumnInner);
                         break;
                    }
                    case 'date': {
                         iconDiv.appendTo(divColumnInner);

                         // Add icon
                         const numSelectSpan = $('<span/>', {
                              id: 'checkBox' + that.index(),
                              'class': 'input-group-prepend hand',
                              'style': 'padding: 0',
                              title: headerName
                         });

                         const selectBox = $('<select style="width: 40px; border: 0; height: 32px; border-radius: 0" class="form-control auto_search"></select>');

                         const selection = that['column'](that.index()).search().charAt(0);
                         selectBox.append('<option value="=" ' + (selection === '=' ? 'selected=selected' : '') + '>=</option>')
                         selectBox.append('<option value="<" ' + (selection === '<' ? 'selected=selected' : '') + '><</option>')
                         selectBox.append('<option value=">" ' + (selection === '>' ? 'selected=selected' : '') + '>></option>')

                         selectBox.appendTo(numSelectSpan);

                         numSelectSpan.appendTo(divColumnInner);

                         const filteredValue = that['column'](that.index()).search().replace('<', '').replace('>', '').replace('=', '').replace(' ', '');

                         // tslint:disable
                         const datePicker = $('<input/>', {
                              name: 'date_' + that.index(),
                              id: 'date_' + that.index(),
                              style: 'width: 100%;',
                              placeholder: headerName,
                              value: filteredValue,
                              class: 'form-control auto_search'
                         });
                         // tslint:enable

                         datePicker['datepicker']({ dateFormat: 'yy-mm-dd' });

                         selectBox.on('keyup change', function () {
                              const combinedValue = datePicker.val() !== '' ? selectBox.val() + ' ' + datePicker.val() : '';
                              if (that.search() !== combinedValue) {
                                   that.search(combinedValue, true, true).draw();
                              }
                         });

                         datePicker.on('keyup change', function () {
                              const combinedValue = datePicker.val() !== '' ? selectBox.val() + ' ' + datePicker.val() : '';
                              if (that.search() !== combinedValue) {
                                   returnedSmartFunction(datePicker, selectBox);
                              }
                         });

                         const calendarIconSpan = $('<span/>', {
                              id: 'calendarIconSpan_' + that.index(),
                              'class': 'input-group-calendar-datatables',
                              'style': 'padding: 0',
                         });

                         const checkboxIcon = $('<i/>', {
                              id: 'calendarIcon_' + that.index(),
                              'class': 'fa fa-calendar-alt',
                         });

                         checkboxIcon.appendTo(calendarIconSpan);

                         calendarIconSpan.appendTo(divColumnInner);

                         datePicker.appendTo(divColumnInner);
                         break;
                    }
                    case 'num': {

                         iconDiv.appendTo(divColumnInner);

                         // Add icon
                         const numSelectSpan = $('<span/>', {
                              id: 'checkBox' + that.index(),
                              'class': 'input-group-prepend hand',
                              'style': 'padding: 0',
                              title: headerName
                         });

                         const selectBox = $('<select style="width: 40px; border: 0; height: 32px; border-radius: 0" class="form-control auto_search"></select>');

                         const selection = that['column'](that.index()).search().charAt(0);
                         selectBox.append('<option value="=" ' + (selection === '=' ? 'selected=selected' : '') + '>=</option>')
                         selectBox.append('<option value="<" ' + (selection === '<' ? 'selected=selected' : '') + '><</option>')
                         selectBox.append('<option value=">" ' + (selection === '>' ? 'selected=selected' : '') + '>></option>')

                         selectBox.appendTo(numSelectSpan);

                         numSelectSpan.appendTo(divColumnInner);

                         const filteredValue = that['column'](that.index()).search().replace('<', '').replace('>', '').replace('=', '').replace(' ', '');

                         const textBox = $('<input />', {
                              type: "number",
                              name: 'num_' + that.index(),
                              id: 'num_' + that.index(),
                              style: 'width: 100%;',
                              placeholder: headerName,
                              value: filteredValue,
                              class: 'form-control auto_search'
                         });

                         selectBox.on('keyup change', function () {
                              const combinedValue = textBox.val() !== '' ? selectBox.val() + ' ' + textBox.val() : '';
                              if (that.search() !== combinedValue) {
                                   that.search(combinedValue, true, true).draw();
                              }
                         });

                         textBox.on('keyup change', function () {
                              const combinedValue = textBox.val() !== '' ? selectBox.val() + ' ' + textBox.val() : '';
                              if (that.search() !== combinedValue) {
                                   returnedSmartFunction(textBox, selectBox);
                              }
                         });

                         textBox.appendTo(divColumnInner);
                         break;
                    }
                    default: {
                         // Add icon
                         iconDiv.appendTo(divColumnInner);

                         const textBox = $('<input/>', {
                              name: 'text_' + that.index(),
                              id: 'text_' + that.index(),
                              style: 'width:100%',
                              placeholder: headerName,
                              value: that['column'](that.index()).search(),
                              class: 'form-control auto_search'
                         }).on('keyup change', function () {
                              if (that.search() !== this['value']) {
                                   const that2 = this;
                                   returnedFunction(that2);
                              }
                         });

                         textBox.appendTo(divColumnInner);
                         break;
                    }
               }

               divColumnInner.appendTo(divColumn);
               divColumn.appendTo(dynamicFilterContainer);
          }


          // Add search blocks
          if (that['column'](that.index()).search() !== '') {
               const filterLabel = $(`<span/>`, {
                    title: headerName,
                    html: `${headerName}: <strong>${that['column'](that.index()).search()}</strong>`,
                    class: 'filter-label'
               });

               const checkboxIcon = $('<i/>', {
                    id: 'remove_filter_' + that.index(),
                    class: 'fa fa-times fa-fw',
                    style: 'padding:5px; cursor: pointer; color: rgba(151, 28, 36, 0.8)',
               });

               checkboxIcon.appendTo(filterLabel);

               checkboxIcon.on('click', function () {
                    that.column(that.index()).search('', false, true).draw();
               });

               filterLabel.appendTo(filterRows);

               filterRows.show();

          }
     });


}

export function getGridLanguages(translateService) {
     return {
          'lengthMenu': translateService.instant('grid.lengthmenu'),
          'zeroRecords': translateService.instant('grid.empty'),
          'info': translateService.instant('grid.info'),
          'infoEmpty': translateService.instant('grid.infoempty'),
          'infoFiltered': translateService.instant('grid.infofiltered'),
          'search': translateService.instant('grid.search'),
          'infoThousands': '.',
          'processing': '<div class="backdrop" style="width: 100%; height: 100%; position: relative; top: 0; bottom: 0; left: 0; right: 0; border-radius: 4px; border-radius: 4px;"><div class="sk-spinner sk-spinner-wave"><div class="sk-rect1"></div><div class="sk-rect2"></div><div class="sk-rect3"></div><div class="sk-rect4"></div><div class="sk-rect5"></div></div></div>',
          // 'processing': '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
          'loadingRecords': translateService.instant('grid.loadingrecords'),
          'paginate': {
               'first': translateService.instant('grid.first'),
               'last': translateService.instant('grid.last'),
               'next': translateService.instant('grid.next'),
               'previous': translateService.instant('grid.previous'),
          },
          'aria': {
               'sortAscending': translateService.instant('grid.sortasc'),
               'sortDescending': translateService.instant('grid.sortdesc'),
          },
          'choose': translateService.instant('general.choose')
     };
}

export const createdCellBackgroundColor = function (td, cellData, rowData, row, col) {
     const cell = $(td);

     if (cell.text() === 'true') {
          cell.text('');
          $(cell).css('padding', '2px');
          $(cell).css('width', '70px');

          const newDiv = document.createElement('div');
          $(newDiv).css('padding', '6px');
          $(newDiv).css('width', '100%');
          $(newDiv).css('height', '100%');
          $(newDiv).text('True');
          $(newDiv).css('background-color', 'rgba(0, 142, 67, 0.8)');
          $(newDiv).css('color', '#fff');
          cell.append(newDiv);

     } else if ($(td).text() === 'false') {

          cell.text('');
          $(cell).css('padding', '2px');
          $(cell).css('width', '70px');

          const newDiv = document.createElement('div');
          $(newDiv).css('padding', '6px');
          $(newDiv).css('width', '100%');
          $(newDiv).css('height', '100%');
          $(newDiv).text('False');
          $(newDiv).css('background-color', 'rgba(151, 28, 36, 0.8)');
          $(newDiv).css('color', '#fff');
          cell.append(newDiv);
     } else {
          cell.text('');
          $(cell).css('padding', '2px');
          $(cell).css('width', '70px');

          const newDiv = document.createElement('div');
          $(newDiv).css('padding', '6px');
          $(newDiv).css('width', '100%');
          $(newDiv).css('height', '100%');
          $(newDiv).text('-');
          $(newDiv).css('background-color', 'rgba(100, 100, 100, 0.8)');
          $(newDiv).css('color', '#fff');
          cell.append(newDiv);
     }
};

export const createdCellCheckbox = function (td, cellData, rowData, row, col) {
     const cell = $(td);

     if (cell.text() === 'true') {
          cell.text('');
          $(cell).css('width', '30px');

          const newDiv = document.createElement('i');
          $(newDiv).attr('class', 'fa fa-check fa-fw ');
          $(newDiv).css('color', 'rgba(0, 142, 67, 1)');
          cell.append(newDiv);

     } else if ($(td).text() === 'false') {

          cell.text('');
          $(cell).css('width', '30px');

          const newDiv = document.createElement('i');
          $(newDiv).attr('class', 'fa fa-times fa-fw ');
          $(newDiv).css('color', 'rgba(151, 28, 36, 1)');
          cell.append(newDiv);
     } else {
          cell.text('');
          $(cell).css('width', '30px');

          const newDiv = document.createElement('i');
          $(newDiv).attr('class', 'fa fa-times fa-fw ');
          $(newDiv).css('color', 'rgba(100, 100, 100, 0.3)');
          cell.append(newDiv);
     }
};


export const createdCellCheckboxWithId = function (td, cellData, rowData, row, col) {
     const cell = $(td);

     if (cell.text() === 'true') {
          cell.text('');
          $(cell).css('width', '30px');

          const newDiv = document.createElement('i');
          $(newDiv).attr('id', 'flag_' + rowData.id);
          $(newDiv).attr('class', 'fa fa-check fa-fw ');
          $(newDiv).css('color', 'rgba(0, 142, 67, 1)');
          cell.append(newDiv);

     } else if ($(td).text() === 'false') {

          cell.text('');
          $(cell).css('width', '30px');

          const newDiv = document.createElement('i');
          $(newDiv).attr('id', 'flag_' + rowData.id);
          $(newDiv).attr('class', 'fa fa-times fa-fw ');
          $(newDiv).css('color', 'rgba(151, 28, 36, 1)');
          cell.append(newDiv);
     } else {
          cell.text('');
          $(cell).css('width', '30px');

          const newDiv = document.createElement('i');
          $(newDiv).attr('id', 'flag_' + rowData.id);
          $(newDiv).attr('class', 'fa fa-times fa-fw ');
          $(newDiv).css('color', 'rgba(100, 100, 100, 0.3)');
          cell.append(newDiv);
     }
};


export const createdCellMaxWidth = function (maxWidth) {

     return function (td, cellData, rowData, row, col) {

          const cell = $(td);
          $(cell).css('max-width', maxWidth);
          $(cell).css('overflow', 'hidden');
          $(cell).css('overflow', 'hidden');
          $(cell).prop('title', cell.text());
          $(cell).attr('class', 'elipses_overflow');
     }

}

// tslint:disable
export const serverSideExportAction = function (_e, dt, button, config) {
     const self = this;
     const oldStart = dt.settings()[0]._iDisplayStart;

     dt.one('preXhr', function (e, s, data) {
          // Set the limit to -less
          data.start = 0;
          data.length = 2147483647;
          data.isExport = true;

          dt.one('preDraw', function (e, settings) {
               // Call the original action function
               if (button[0].className.indexOf('buttons-excel') >= 0) {
                    $.fn.dataTable.ext['buttons']['excelHtml5'].action.call(self, e, dt, button, config);
               } else if (button[0].className.indexOf('buttons-csv') >= 0) {
                    $.fn.dataTable.ext['buttons']['csvHtml5'].action.call(self, e, dt, button, config);
               } else if (button[0].className.indexOf('buttons-pdf') >= 0) {
                    $.fn.dataTable.ext['buttons']['pdfHtml5'].action.call(self, e, dt, button, config);
               } else if (button[0].className.indexOf('buttons-print') >= 0) {
                    $.fn.dataTable.ext['buttons']['print'].action(e, dt, button, config);
               }

               dt.one('preXhr', function (e, s, data) {
                    // DataTables thinks the first item displayed is index 0, but we're not drawing that.
                    // Set the property to what it was before exporting.
                    settings._iDisplayStart = oldStart;
                    data.start = oldStart;
               });

               // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
               setTimeout(dt.ajax.reload, 0);

               // Prevent rendering of the full data to the DOM
               return false;
          });
     });

     // Requery the server with the new one-time export settings
     dt.ajax.reload();
};

export const clientSideExportAction = function (_e, dt, button, config) {

     console.log('Client side export');

     const self = this;

     if (button[0].className.indexOf('buttons-excel') >= 0) {
          $.fn.dataTable.ext['buttons']['excelHtml5'].action.call(self, _e, dt, button, config);
     } else if (button[0].className.indexOf('buttons-csv') >= 0) {
          $.fn.dataTable.ext['buttons']['csvHtml5'].action.call(self, _e, dt, button, config);
     } else if (button[0].className.indexOf('buttons-pdf') >= 0) {
          $.fn.dataTable.ext['buttons']['pdfHtml5'].action.call(self, _e, dt, button, config);
     } else if (button[0].className.indexOf('buttons-print') >= 0) {
          $.fn.dataTable.ext['buttons']['print'].action(_e, dt, button, config);
     }
};
// tslint:enable

export function getGridButtons(commonExportOptions, fileName, header, colorService, exportAction = serverSideExportAction) {
     let logo = colorService?.theme?.logoLoginBinary?.url;

     return [{
          extend: 'csv',
          text: '<i class="fa fa-fw fa-file-csv"></i> Download CSV',
          exportOptions: commonExportOptions,
          charset: 'utf-8',
          extension: '.csv',
          // fieldSeparator: ';',
          // fieldBoundary: '',
          bom: true,
          action: exportAction,
          filename: fileName
     }, {
          extend: 'excel',
          text: '<i class="fa fa-fw fa-file-excel"></i> Download XLS',
          exportOptions: commonExportOptions,
          action: exportAction,
          filename: fileName
     }, {
          extend: 'pdf',
          text: '<i class="fa fa-fw fa-file-pdf"></i> Download PDF',
          exportOptions: commonExportOptions,
          orientation: 'landscape',
          action: exportAction,
          customize: function (doc) {
               
               doc.defaultStyle.fontSize = 9;
               doc.pageMargins = [20, 10, 20, 20];
               doc.styles.title.fontSize = 12;
               doc.styles.title.alignment = 'left';
               doc.content[0].margin = [0,15,0,12];

               const tblBody = doc.content[1].table.body;
               // ***
               // This section creates a grid border layout
               // ***
               doc.content[1].layout = {
                    hLineWidth: function (i, node) {
                         return (i === 0 || i === node.table.body.length) ? 2 : 1;
                    },
                    vLineWidth: function (i, node) {
                         return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                    },
                    hLineColor: function (i, node) {
                         return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                    },
                    vLineColor: function (i, node) {
                         return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                    }
               };
               // ***
               // This section loops thru each row in table looking for where either
               // the second or third cell is empty.
               // If both cells empty changes rows background color to '#FFF9C4'
               // if only the third cell is empty changes background color to '#FFFDE7'
               // ***
               $('#tableID').find('tr').each(function (ix, row) {
                    const index = ix;
                    const rowElt = row;
                    $(row).find('td').each(function (ind, elt) {
                         if (tblBody[index][1].text === '' && tblBody[index][2].text === '') {
                              delete tblBody[index][ind].style;
                              tblBody[index][ind].fillColor = '#FFF9C4';
                         } else {
                              if (tblBody[index][2].text === '') {
                                   delete tblBody[index][ind].style;
                                   tblBody[index][ind].fillColor = '#FFFDE7';
                              }
                         }
                    });
               });

               doc.content.splice(1, 0, {
                    margin: [0, -33, 0, 12],
                    alignment: 'right',
                    // tslint:disable-next-line:max-line-length
                    image: 'data: image/png;base64,' + logo,
                    fit: [125, 125]
               });
          },
          filename: fileName
     }, {
          extend: 'print',
          text: '<i class="fa fa-fw fa-print"></i> Print',
          exportOptions: commonExportOptions,
          action: exportAction,
     }, {
          extend: 'colvis',
          columns: ':not(.noVis)',
          text: '<i class="fa fa-fw fa-list-check"></i> Columns',
          collectionLayout: 'fixed columns',
          collectionTitle: 'Column visibility control',
          postfixButtons: ['colvisRestore']
     }, {
          text: '<i class="fa fa-fw fa-recycle"></i> Reload data',
          action: function (e, dt, node, config) {
               dt.ajax.reload();
          }
     }];
}

export function getDropdownSettings(groupBy = true, badgeShowLimit = 3, showCheckbox = true) {
     return {
          classes: 'form-control multiselector_fix',
          enableSearchFilter: true,
          lazyLoading: false,
          badgeShowLimit: badgeShowLimit,
          showCheckbox: showCheckbox,
          groupBy: groupBy ? 'category' : ''
     };
}

export function getTranslatedDropdownSettings(translateService, name, groupBy = true, badgeShowLimit = 3, showCheckbox = true) {
     return {
          singleSelection: false,
          text: translateService.instant(('general.' + name)),
          selectAllText: translateService.instant('general.selectAll'),
          unSelectAllText: translateService.instant('general.deSelectAll'),
          searchPlaceholderText: translateService.instant('general.search'),
          noDataLabel: translateService.instant('general.noDataFound'),
          classes: 'form-control multiselector_fix',
          enableSearchFilter: true,
          lazyLoading: false,
          badgeShowLimit: badgeShowLimit,
          showCheckbox: showCheckbox,
          groupBy: groupBy ? 'category' : ''
     };
}

export function getDefaultDpConfig(moment, authenticationService) {

     const now = moment().tz(authenticationService.getTimeZoneIana()).endOf('day').toDate();
     const startOfWeek = () => moment().tz(authenticationService.getTimeZoneIana()).startOf('week').endOf('day');
     const startOfYear = () => moment().tz(authenticationService.getTimeZoneIana()).startOf('year').startOf('day');

     return {
          displayMonths: 1,
          containerClass: 'theme-default',
          dateInputFormat: 'll',
          rangeSeparator: ' - ',
          rangeInputFormat: 'll',
          withTimepicker: false,
          keepDatepickerOpened: true,
          showMeridian: false,
          adaptivePosition: true,
          ranges: [
               {
                    value: [
                         moment().tz(authenticationService.getTimeZoneIana()).startOf('day').toDate(),
                         now
                    ],
                    label: 'Today'
               },
               {
                    value: [
                         moment().tz(authenticationService.getTimeZoneIana()).subtract(1, 'day').startOf('day').toDate(),
                         moment().tz(authenticationService.getTimeZoneIana()).subtract(1, 'day').endOf('day').toDate(),
                    ],
                    label: 'Yesterday'
               }, {
                    value: [
                         moment().tz(authenticationService.getTimeZoneIana()).startOf('week').startOf('day').toDate(),
                         now
                    ],
                    label: 'This week'
               }, {
                    value: [
                         moment().tz(authenticationService.getTimeZoneIana()).subtract(1, 'weeks').startOf('week').startOf('day').toDate(),
                         moment().tz(authenticationService.getTimeZoneIana()).subtract(1, 'weeks').endOf('week').endOf('day').toDate()
                    ],
                    label: 'Last week'
               }, {
                    value: [
                         moment().tz(authenticationService.getTimeZoneIana()).startOf('months').startOf('day').toDate(),
                         now
                    ],
                    label: 'This month'
               }, {
                    value: [
                         moment().tz(authenticationService.getTimeZoneIana()).subtract(1, 'months').startOf('months').startOf('day').toDate(),
                         moment().tz(authenticationService.getTimeZoneIana()).subtract(1, 'months').endOf('months').endOf('day').toDate()
                    ],
                    label: 'Last month'
               },
               {
                    value: [
                         moment().tz(authenticationService.getTimeZoneIana()).subtract(1, 'quarters').startOf('quarters').startOf('day').toDate(),
                         moment().tz(authenticationService.getTimeZoneIana()).subtract(1, 'quarters').endOf('quarters').endOf('day').toDate()
                    ],
                    label: 'Last quarter'
               },
               {
                    value: [
                         moment().tz(authenticationService.getTimeZoneIana()).subtract(1, 'years').startOf('years').startOf('day').toDate(),
                         moment().tz(authenticationService.getTimeZoneIana()).subtract(1, 'years').endOf('years').endOf('day').toDate()
                    ],
                    label: 'Last year'
               },
          ]
     };
}


export function getSensorDpConfig(moment, authenticationService) {

     const today = moment().tz(authenticationService.getTimeZoneIana()).endOf('day').toDate();
     const startOfWeek = () => moment().tz(authenticationService.getTimeZoneIana()).startOf('week').endOf('day');
     const startOfYear = () => moment().tz(authenticationService.getTimeZoneIana()).startOf('year').startOf('day');

     return {
          displayMonths: 1,
          containerClass: 'theme-default',
          dateInputFormat: 'll',
          rangeSeparator: ' - ',
          rangeInputFormat: 'll',
          maxDateRange: 7,
          withTimepicker: false,
          keepDatepickerOpened: true,
          adaptivePosition: true,
          showMeridian: false,
          ranges: [
               {
                    value: [
                         moment().tz(authenticationService.getTimeZoneIana()).startOf('day').add(0, 'days').toDate(),
                         today
                    ],
                    label: 'Today'
               },
               {
                    value: [
                         moment().tz(authenticationService.getTimeZoneIana()).startOf('day').add(0 - 2, 'days').toDate(),
                         today
                    ],
                    label: 'Last 2 days'
               },
               {
                    value: [
                         moment().subtract(1, 'weeks').startOf('day').toDate(),
                         moment().endOf('day').toDate()
                    ],
                    label: 'Last 7 days'
               }
          ]
     };
}

export const mapMultiDropdown = ((keys) => keys.map(key => ({
     'id': key.id, 'itemName': key.name
})));

export function setTableStars(tableIds) {
     if (tableIds) {
          tableIds.forEach(id => {
               const star = $('#star_' + id);
               star.click(() => {
                    if (star.hasClass('fas')) {
                         star.removeClass('fas').addClass('far');
                    } else {
                         star.removeClass('far').addClass('fas');
                    }
                    // table.changeFlag(id, star.hasClass('fas'));
               });
          });
     };
}

export function setSelection(tableIds, gridSelection) {
     if (tableIds) {
          tableIds.forEach(id => {
               const checkbox = $('#selection_' + id.toString());
               const parentRow = $(checkbox).closest('tr');

               // checkbox.click(() => {
               parentRow.click(() => {

                    // console.log('click');
                    if (checkbox.hasClass('fa-square')) {
                         gridSelection.push(id.toString());
                         checkbox.removeClass('fa-square').addClass('fa-check-square');
                         parentRow.addClass('selected');
                    } else {
                         const index = gridSelection.indexOf(id.toString(), 0);
                         if (index > -1) {
                              gridSelection.splice(index, 1);
                         }
                         checkbox.removeClass('fa-check-square').addClass('fa-square');
                         parentRow.removeClass('selected');
                    }
               });
          });
     };
}

export function setSelectionRows(table, row, data, gridSelection) {
     if ($.inArray(data.id.toString(), gridSelection) !== -1) {
          $(row).addClass('selected');
     }
}

