import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { createMapOptions, getMapProviders, colorMapper, setBounds, getMapProvidersExtended, drawGeofences } from 'app/common/leafletGlobals';
import { Device } from 'app/models/device.model';
import { icon, Map, marker, TileLayer } from 'leaflet';
import 'leaflet-easybutton';
import { MapService } from '../../services/common/map.service';
import { DeviceService } from '../../services/device/device.service';
import { timer } from 'rxjs/internal/observable/timer';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { LocationService } from '../../services/locations/locations.service';
import { BOUND_CHECK, getIconPath, MAX_LATITUDE, MAX_LONGITUDE, roundAsNumber, roundAsString, roundSeconds } from 'app/common/globals';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DeviceType } from 'app/models/devicetype.model';
import { AccountService } from 'app/services/account/account.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { UserService } from 'app/services/users/user.service';
import { AppUser } from 'app/models/user.model';

import '../../../../vendor/leaflet-activearea/leaflet-activearea.js';
import '../../../../vendor/leaflet-extramarkers/js/leaflet.extra-markers.js';

import { TranslateService } from '@ngx-translate/core';
import { AssetDisplayName, StorageType } from 'app/common/enums';
import { StorageHelper } from 'app/common/storagehelper';
import { Router } from '@angular/router';

declare var L;
declare var HeatmapOverlay;
declare var PruneCluster;
declare var PruneClusterForLeaflet;
declare const window;

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { DriverService } from 'app/services/driver/driver.service';

window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-devices-map',
    templateUrl: 'map.template.html',
    styleUrls: ['map.template.css'],
    providers: [MapService, LocationService],
    animations: [
        trigger('slideInOut', [
            state('in', style({
                transform: 'translate3d(0, 0, 0)',
                opacity: 1
            })),
            state('out', style({
                transform: 'translate3d(calc(100% - 10px), 0, 0)',
                opacity: 0
            })),
            transition('in => out', animate('400ms ease-in-out')),
            transition('out => in', animate('400ms ease-in-out'))
        ]),
    ]
})
export class DevicesMapViewComponent implements OnInit, OnDestroy {
    pruneCluster: any;
    selectedDevice: Device;
    selectedDeviceExtended: Device;
    deviceId: string;
    loadingSidebar = false;

    loading = false;
    markers: any;
    radiusLayer: any;
    circleMarker;
    streets: any;
    osm: any;
    cities: any;
    map: Map;
    options;
    devices: Device[] = [];
    menuState = 'out';
    statusText = '';
    selectedAccountId;
    selectedAssetGroups;

    maps = [];
    locationSubscription: any;
    deviceSubscription: any;
    previousLookupTimestamp: Date;
    selectedLocation: any;

    search;
    deviceType;
    status;

    error;
    success;

    deviceTypeOptions = [];
    loadingDeviceTypes = false;
    deviceTypes: DeviceType[] = [];
    permissions: {};

    daterangepickerModel: any[];

    loadingUtilization = false;
    selectedDeviceUtilization: any;

    permissionName = 'Devices_View';
    constructorName = 'DevicesMapViewComponent';
    timezoneIana: string;

    geofences = [];
    geofenceLayer;
    lastBounds: any;

    user: AppUser = new AppUser();

    storageType = StorageType.LocalStorage;
    skipClustering: boolean = false;
    hideLabels: boolean = false;
    skipIncludingGroupColors: boolean = false;
    showScale: boolean = false;

    isSpinning = false;

    constructor(private ngZone: NgZone,
        private cd: ChangeDetectorRef,
        private accountService: AccountService,
        private router: Router,
        private locationService: LocationService,
        private deviceTypeService: DeviceTypeService,
        private theMapService: MapService,
        private zone: NgZone,
        private http: HttpClient,
        private deviceService: DeviceService,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private storageHelper: StorageHelper,
        private userService: UserService) {

        // Get settings
        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'skipClustering').subscribe((result) => {
            this.skipClustering = JSON.parse(result) === true;
        });

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideLabels').subscribe((result) => {
            this.hideLabels = JSON.parse(result) === true;
        });

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'showScale').subscribe((result) => {
            this.showScale = JSON.parse(result) === true;
        });

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'skipIncludingGroupColors').subscribe((result) => {
            this.skipIncludingGroupColors = JSON.parse(result) === true;
        });

        this.initMap();

        this.permissions = this.authenticationService.permissions;

        this.selectedAccountId = this.authenticationService.getAccountId();

        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(1, 'week').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).endOf('day').toDate()
        ];

        this.deviceTypeService.getDeviceTypes().subscribe(result => {
            this.deviceTypes = result;
        });

        this.accountService.getGeofencesByAccount(this.selectedAccountId).subscribe(geofences => {
            this.geofences = geofences;

            drawGeofences(L, this.geofences, this.geofenceLayer, null);
            this.cd.markForCheck();
        });
    }

    toggleMenu() {
        console.log('Toggle menu');

        if (this.menuState === 'in') {
            this.filterMarkers(false);
        }

        if (this.circleMarker) {
            this.map.removeLayer(this.circleMarker);
        }

        // 1-line if statement that toggles the value:
        this.menuState = this.menuState === 'out' ? 'in' : 'out';
    }

    ngOnDestroy(): void {
        if (this.deviceSubscription !== undefined) {
            this.deviceSubscription.unsubscribe();
        }
        if (this.locationSubscription !== undefined) {
            this.locationSubscription.unsubscribe();
        }

        this.devices = [];
        this.map = null;
        this.markers = [];
        this.radiusLayer = L.featureGroup();
        this.pruneCluster = L.featureGroup();
    }

    setFocus() {
        setTimeout(() => {
            // Set offset center
            this.map['setActiveArea']({
                position: 'absolute',
                top: '0px',
                left: '0px',
                right: '420px',
                height: '100%'
            });

            this.map.setView([this.selectedDevice.theMarker.data.deviceState?.currentPosition?.latitude, this.selectedDevice.theMarker.data.deviceState?.currentPosition?.longitude], 15, { animate: true, duration: 0.5 });

            // Set offset center
            this.map['setActiveArea']({
                position: 'absolute',
                top: '0px',
                left: '0px',
                right: '0px',
                height: '100%'
            });

        }, 300);
    }

    saveViewPort() {
        this.lastBounds = this.map.getBounds();
    }

    resetViewPort() {
        if (this.lastBounds) {
            this.map.fitBounds(this.lastBounds);
        }
    }

    selectDevice(accountId, deviceId: string, latitude, longitude, radiusInMeters): any {
        this.loadingSidebar = true;
        const that = this;

        this.selectedDevice = that.devices.find(x => x.id === deviceId);

        this.selectedDevice.deviceTypeNameFull = this.deviceTypes.find(x => x.id === this.selectedDevice.deviceTypeId)?.description;

        this.setFocus();

        this.filterAllMarkers(deviceId);

        this.deviceService.getDeviceById(deviceId).subscribe(
            device => {
                this.selectedDeviceExtended = device;
                // this.selectedLocation = location;
                that.loadingSidebar = false;
            }
        );

        if (this.circleMarker) {
            this.map.removeLayer(this.circleMarker);
        }

        if (radiusInMeters) {
            this.circleMarker = L.circle([latitude, longitude], {
                color: '#e100ff',
                opacity: 0.4,
                fillOpacity: 0.1,
                dashArray: '10, 10',
                radius: radiusInMeters
            });
            this.radiusLayer.addLayer(this.circleMarker);
        }

        this.loadingUtilization = true;
        this.deviceService.getDeviceUtilization(deviceId, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(
            utilization => {
                this.selectedDeviceUtilization = utilization;
                // this.selectedLocation = location;
                that.loadingUtilization = false;
            }
        );
    }

    async ngOnInit(): Promise<void> {
        this.deviceTypeOptions = [];
        const that = this;
        this.loadingDeviceTypes = true;

        this.deviceTypeService.getDeviceTypes(false, false, true).subscribe(deviceTypes => {
            this.loadingDeviceTypes = false;
            deviceTypes.sort((a, b) => (a.modelName > b.modelName ? 1 : -1)).forEach(function (item, index) {
                if (item.modelName !== '') {
                    that.deviceTypeOptions.push({ id: item.id, value: item.modelName + ' (' + item.deviceCount + ')' });
                }
            });
        });

        window.my = window.my || {};
        window.my.namespace = window.my.namespace || {};

        window.my.namespace.saveAsGeofence = this.saveAsGeofence.bind(this);
        window.my.namespace.panTo = this.panToPublic.bind(this);
        window.my.namespace.zoomIn = this.zoomInPublic.bind(this);
        window.my.namespace.zoomOut = this.zoomOutPublic.bind(this);

        this.user = await this.userService.getUserById(this.authenticationService.getUserId()).toPromise();
    }

    getDeviceCount(index) {
        if (index === 6) {
            // tslint:disable-next-line:no-bitwise
            return this.devices?.filter(x => x.theMarker?.filteredWithoutState === false && x.theMarker?.position != null && !((x.theMarker?.data.deviceState?.communicationState?.locationType & 2) > 0)).length ?? '-';
        } else {
            return this.devices?.filter(x => x.theMarker?.filteredWithoutState === false && x.theMarker?.category === index.toString()).length ?? '-';
        }
    }

    saveAsGeofence(latitude: number, longitude: number): void {
        const circle: L.Circle = L.circle([latitude, longitude], 50);

        this.zone.run(() => this.router.navigateByUrl('/Geofences/Add', {
            state: {
                newGeofence: circle.toGeoJSON(),
            }
        }));
    }

    zoomInPublic(e): void {
        this.ngZone.run(() => this.map.zoomIn());
    }

    zoomOutPublic(e): void {
        this.ngZone.run(() => this.map.zoomOut());
    }

    panToPublic(latitude, longitude): void {
        this.ngZone.run(() => this.map.panTo([latitude, longitude]));
    }

    filterAllMarkers(deviceId) {
        console.log('filtering markers');
        // filter the events on map

        this.devices.forEach(device => {
            const theMarker = device.theMarker;

            if (theMarker) {
                theMarker.filtered = true;
                theMarker.filteredWithoutState = false;
            }
        });

        const activeDevice = this.devices.find(x => x.id === deviceId);
        activeDevice.theMarker.filtered = false;
        activeDevice.theMarker.filteredWithoutState = false;

        this.pruneCluster.ProcessView();
    }

    filterMarkers(centerMap) {
        console.log('filtering markers');
        // filter the events on map

        this.devices.forEach(device => {
            const theMarker = device.theMarker;

            if (theMarker) {
                theMarker.filtered = false;
                theMarker.filteredWithoutState = false;

                if (this.search) {
                    theMarker.filtered = true;
                    if (device.assetSearchName?.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || device.companyName?.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
                        theMarker.filtered = false;
                    }
                }

                if (this.selectedAssetGroups && this.selectedAssetGroups.length > 0) {
                    const found = device.asset?.assetGroupIds.some(ag => this.selectedAssetGroups.includes(ag));
                    if (!found) {
                        theMarker.filtered = true;
                    }
                } else if (this.selectedAssetGroups && this.selectedAssetGroups > 0) {
                    const found = device.asset?.assetGroupIds.some(ag => this.selectedAssetGroups === ag);
                    if (!found) {
                        theMarker.filtered = true;
                    }
                }

                if (this.deviceType && this.deviceType > 0) {
                    if (device.deviceTypeId !== this.deviceType) {
                        theMarker.filtered = true;
                    }
                }

                theMarker.filteredWithoutState = theMarker.filtered;

                if (this.status) {
                    if (this.status === 6) {
                        // Filter out devices with gps
                        // tslint:disable-next-line:no-bitwise
                        if ((device.theMarker.data.deviceState?.communicationState?.locationType & 2) > 0) {
                            theMarker.filtered = true;
                        }
                    } else {
                        if (device.theMarker.data.deviceState?.calculatedDeviceState?.deviceState.toString() !== this.status.toString()) {
                            theMarker.filtered = true;
                        }
                    }
                }
            }
        });

        if (centerMap) {
            this.centerMap();
        }

        this.pruneCluster.ProcessView();
    }

    createIcon(data) {

        const location = data.deviceState;

        const iconPath = getIconPath(data.iconId)[1];

        const [markerIcon, heading] =
            (location.calculatedDeviceState?.deviceState === 6) ? ['fa-rss', 0] :
                (location.calculatedDeviceState?.deviceState === 1 && location.currentPosition.heading > 0) ? ['fa-arrow-circle-up', location.currentPosition.heading] :
                    (location.calculatedDeviceState?.deviceState === 2) ? ['fa-stop-circle', 0] :
                        (location.calculatedDeviceState?.deviceState === 3) ? ['fa-pause-circle', 0] :
                            (location.calculatedDeviceState?.deviceState === 4) ? ['fa-signal', 0] :
                                (location.calculatedDeviceState?.deviceState === 5) ? ['fa-power-off', 0] :
                                    (location.calculatedDeviceState?.deviceState === 0) ? ['fa-question-circle', 0] : ['fa-play-circle', 0];

        return L['StatusMarker'].icon({
            iconUrl: iconPath,
            icon: markerIcon,
            markerColor: colorMapper(location.calculatedDeviceState?.deviceState),
            rotate: heading,
            shape: 'circle',
            prefix: 'fas'
        });
    }

    // Leaflet
    initMap() {
        forkJoin([
            this.storageHelper.loadStoreState(StorageType.LocalStorage, 'settings_', 'mapSelectionOptions'),
            this.storageHelper.loadStoreState(StorageType.LocalStorage, 'Map_', 'GeofenceLayerEnabled'),
            this.storageHelper.loadStoreState(StorageType.LocalStorage, 'settings_', 'showCompass')
        ]).subscribe(([mapSelectionOptions, geofenceLayerEnabled, showCompass]) => {
            this.maps = getMapProvidersExtended(L, mapSelectionOptions);

            this.markers = L.featureGroup();
            this.radiusLayer = L.featureGroup();
            this.pruneCluster = new PruneClusterForLeaflet();
            this.geofenceLayer = L.featureGroup();

            let mapType = this.theMapService.getLeafletMapType();

            if (!mapType) {
                mapType = this.maps[0].name;
                this.theMapService.setLeafletMapType(mapType);
            }

            const defaultLayers = [];

            let defaultMap = this.maps.find(x => x.name.toString() === mapType.toString())
            if (!defaultMap) {
                console.log('Falling back to default map');
                defaultMap = this.maps[0];
            }

            defaultLayers.push(defaultMap.layer);

            defaultLayers.push(this.markers);
            defaultLayers.push(this.pruneCluster);
            defaultLayers.push(this.radiusLayer);

            if (geofenceLayerEnabled) {
                defaultLayers.push(this.geofenceLayer);
            }

            console.log(showCompass);
            const mapOptions = createMapOptions(L, defaultLayers, this.translateService, false, showCompass ?? false);

            this.options = {
                ...mapOptions
            };

            const that = this;

            this.loading = true;

            // Select event

            that.theMapService.setPruneCluster(that.pruneCluster);

            if (this.skipClustering) {
                this.pruneCluster.Cluster.Size = .000001;
                this.pruneCluster.Cluster.Margin = .000001;
            }

            that.pruneCluster.PrepareLeafletMarker = function (theMarker, data, category) {
                // parse data to icon

                that.theMapService.addLabel(theMarker, data, !that.hideLabels, that.skipIncludingGroupColors);

                that.theMapService.createPopup(theMarker, data, category);

                theMarker.setIcon(that.createIcon(data));

                theMarker.off('click');

                theMarker.on('click', (evt: any) => {

                    that.zone.run(() => {
                        that.menuState = 'in';
                        console.log('select');
                        that.saveViewPort();
                        that.selectDevice(data.accountId, data.deviceState.id, data.deviceState?.currentPosition?.latitude, data.deviceState?.currentPosition?.longitude, data.deviceState?.currentPosition?.radiusInMeters);
                    });
                });

                // tslint:disable-next-line:no-bitwise
                data.deviceState.hasCellFix = (data.deviceState?.communicationState?.locationType & 1) > 0;

                // tslint:disable-next-line:no-bitwise
                data.deviceState.hasGpsFix = (data.deviceState?.communicationState?.locationType & 2) > 0;

                let markerEvents = {};

                // This code manages showing outdated position(s) on the map
                if (!data.deviceState.hasGpsFix && (data.deviceState.cellPosition?.latitude && data.deviceState.cellPosition?.longitude && data.deviceState.currentPosition)) {
                    const cellPosition = new L.LatLng(data.deviceState.cellPosition.latitude, data.deviceState.cellPosition.longitude);
                    const gpsPosition = new L.LatLng(data.deviceState.currentPosition.latitude, data.deviceState.currentPosition.longitude);
                    const distanceLargeEnough = cellPosition.distanceTo(gpsPosition) > 25;

                    if (distanceLargeEnough) {
                        const pointList = [cellPosition, gpsPosition];

                        const polyline = L.polyline(pointList, {
                            color: 'red',
                            weight: 2,
                            opacity: 0.8,
                            dashArray: '10,10',
                            smoothFactor: 0
                        });

                        const smallIcon = L.ExtraMarkers.icon({
                            icon: 'fa-thumbtack',
                            markerColor: 'blue-dark',
                            rotate: 0,
                            shape: 'circle',
                            prefix: 'fas'
                        });

                        const gpsMarker = L.marker(gpsPosition, { icon: smallIcon });

                        markerEvents = {
                            mouseover: function ({ target }): void {
                                target._map.addLayer(polyline);
                                target._map.addLayer(gpsMarker);
                            },
                            mouseout: function ({ target }): void {
                                target._map.removeLayer(polyline);
                                target._map.removeLayer(gpsMarker);
                            }
                        };
                    }
                }

                theMarker.on(markerEvents);
            };

            setTimeout(() => {
                this.fillDevices();
            }, 100);
        });
    }

    onMapReady(map: Map) {
        this.map = map;

        this.map.on('baselayerchange', (event) => {
            this.theMapService.setLeafletMapType(event?.['name']);
        });

        setBounds(L, map);

        const that = this;

        const overlayMaps = {
            'Markers': this.markers
        };

        this.map.on('click', (evt: any) => {

            this.zone.run(() => {
                this.menuState = 'out';
                that.resetViewPort();
                that.filterMarkers(false);

                if (that.circleMarker) {
                    that.map.removeLayer(this.circleMarker);
                }
            });
        });

        // overlayMaps
        // L.control.layers(this.maps, overlayMaps, { position: 'topleft' }).addTo(map);
        if (this.showScale) {
            L.control.scale().addTo(map);
        }

        new L.basemapsSwitcher(this.maps, { position: 'topright' }).addTo(this.map);

        // Easybutton
        L.easyButton({
            id: 'fit map button',
            position: 'topleft',
            states: [{
                stateName: 'add-markers',
                icon: 'fa-arrows-to-eye',
                title: 'Fit map',
                onClick: function (control) {
                    that.centerMap();
                }
            }]
        }).addTo(this.map);

        // Add geofence button
        this.storageHelper.loadStoreState(StorageType.LocalStorage, 'Map_', 'GeofenceLayerEnabled').subscribe((geofenceLayerEnabled) => {
            const geofenceToggle = L.easyButton({
                id: 'animated-geofences-toggle',
                position: 'topright',
                states: [{
                    stateName: 'add-geofences',
                    icon: 'fa-draw-polygon',
                    title: 'Show geofences',
                    onClick: function (control) {
                        that.map.addLayer(that.geofenceLayer);
                        control.state('remove-geofences');
                        that.storageHelper.saveStoreState(StorageType.LocalStorage, 'Map_', 'GeofenceLayerEnabled', true);
                    }
                }, {
                    stateName: 'remove-geofences',
                    title: 'Remove geofences',
                    icon: 'fa-vector-polygon',
                    onClick: function (control) {
                        that.map.removeLayer(that.geofenceLayer);
                        control.state('add-geofences');
                        that.storageHelper.saveStoreState(StorageType.LocalStorage, 'Map_', 'GeofenceLayerEnabled', false);
                    }
                }]
            });

            if (geofenceLayerEnabled) {
                geofenceToggle.state('remove-geofences');
            }

            geofenceToggle.addTo(this.map);
        });

        this.map.invalidateSize();
    }

    fillDevices() {
        // Getting devices for account
        this.statusText = 'Fetching devices';

        this.deviceSubscription = this.deviceService.getDevicesLimited(null, null, true, this.skipIncludingGroupColors, true).subscribe(devices => {
            this.devices = devices.filter(x => x.isActive === true && x.isArchived === false);
            this.loading = true;

            let markerCount = 0;
            this.devices.forEach(device => {

                let assetDisplayLabel: string;

                switch (this.user.assetDisplayName) {
                    case AssetDisplayName['Asset Code']:
                        assetDisplayLabel = `${device.asset?.code || device.asset?.name}`;
                        break;
                    case AssetDisplayName['Plate Number']:
                        assetDisplayLabel = `${device.asset?.plateNumber || device.asset?.name}`;
                        break;
                    case AssetDisplayName['Device Name']:
                        assetDisplayLabel = `${device.asset?.name ?? device.name}`;
                        break;
                    case AssetDisplayName['Client Handle: Asset Code']:
                        assetDisplayLabel = `${device.companyName}: ${device.asset?.code || device.asset?.name}`;
                        break;
                    case AssetDisplayName['Client Handle: Plate Number']:
                        assetDisplayLabel = `${device.companyName}: ${device.asset?.plateNumber || device.asset?.name}`;
                        break;
                    case AssetDisplayName['Client Handle: Device Name']:
                        assetDisplayLabel = `${device.companyName}: ${device.asset?.name}`;
                        break;
                    default:
                        assetDisplayLabel = `${device.asset?.name ?? device.name}`;
                        break;
                }

                device.assetDisplayLabel = assetDisplayLabel;
                device.assetSearchName += ' ' + Array.from(device.asset?.assetGroups.values() ?? []).map(x => x.name).join('');

                if (device.theMarker) {
                    device.theMarker.filtered = false;
                    this.pruneCluster.RegisterMarker(device.theMarker);
                    markerCount++;
                }
            });

            // when reload
            if (markerCount > 0) {
                this.loading = false;
                this.pruneCluster.ProcessView();

                // Firstload
                if (this.previousLookupTimestamp == null) {
                    this.centerMap();
                }
            }

            this.statusText = 'Recovered locations from memory';


            this.getLocationUpdates();
        });
    }

    getLocationUpdates() {
        const that = this;
        this.statusText = 'Getting locations';

        this.locationSubscription = timer(0, 60000).pipe(
            mergeMap(() => {
                // Start the spinner
                this.isSpinning = true;
                this.cd.markForCheck();

                return that.locationService.getDeviceStates(that.devices.map(x => x.id), null, that.previousLookupTimestamp, 0);
            })
        ).subscribe(result => {

            // Stop the spinner
            setTimeout(() => {
                this.isSpinning = false;
                this.cd.markForCheck();
            }, 500);

            this.loading = false;

            if (result?.deviceStates.length > 0) {
                this.statusText = 'Last updated: ';

                console.log('Updating ' + result?.deviceStates.length + ' locations');

                result.deviceStates.forEach(deviceState => {
                    const device = that.devices.find(x => +x.id === deviceState.id);

                    deviceState.markerColor = colorMapper(deviceState.calculatedDeviceState?.deviceState);

                    // tslint:disable-next-line:no-bitwise
                    const hasGpsFix = (deviceState.communicationState?.locationType & 2) > 0;

                    let latitude = deviceState.currentPosition?.latitude ?? null;
                    let longitude = deviceState?.currentPosition?.longitude ?? null;

                    if (deviceState.cellPosition && ((latitude === null && longitude === null) || !hasGpsFix)) {
                        latitude = deviceState.cellPosition.latitude ?? null;
                        longitude = deviceState.cellPosition.longitude ?? null;
                    }

                    if (latitude !== null && longitude !== null && !(latitude === 0 && longitude === 0)) {
                        if (device.theMarker) {
                            device.theMarker.Move(latitude, longitude);
                            device.theMarker.data.forceIconRedraw = true;
                            device.theMarker.data.deviceState = deviceState;
                            device.theMarker.data.lastCommunication = deviceState.communicationState?.updateTimestamp;
                            device.theMarker.category = Math.ceil(deviceState.calculatedDeviceState?.deviceState ?? 0).toString();

                            // Try to see if we need to move
                            if (this.selectedDevice && +this.selectedDevice.id === deviceState.id) {
                                this.setFocus();
                            }
                        } else {
                            const theMarker = new PruneCluster.Marker(latitude, longitude, { title: device.assetDisplayLabel, iconId: device.asset?.icon });

                            theMarker.filtered = false;
                            theMarker.filteredWithoutState = false;

                            theMarker.category = Math.ceil(deviceState.calculatedDeviceState?.deviceState ?? 0).toString();
                            theMarker.data.accountId = device.accountId;
                            theMarker.data.popup = device.name;
                            theMarker.data.deviceId = device.id;
                            theMarker.data.imei = device.unitId;
                            theMarker.data.lastCommunication = deviceState.communicationState?.updateTimestamp ?? new Date();
                            theMarker.data.deviceState = deviceState;
                            theMarker.data.deviceTypeId = device.deviceTypeId;

                            theMarker.data.assetDisplayLabel = `<b>${device.assetDisplayLabel}</b>`;
                            theMarker.data.summary = device.asset?.name ?? device.name;
                            theMarker.data.assetGroups = device.asset?.assetGroups;

                            device.theMarker = theMarker;
                            that.pruneCluster.RegisterMarker(theMarker);
                        }
                    }
                });

                this.pruneCluster.ProcessView();

                this.cd.markForCheck();

                // Firstload
                if (that.previousLookupTimestamp == null) {
                    this.centerMap();
                }

                this.cd.markForCheck();

                that.previousLookupTimestamp = new Date(result.timestamp);
            } else {
                console.log('No data updated');

                this.cd.markForCheck();

                that.previousLookupTimestamp = new Date(result.timestamp);
            }
        });

        this.cd.markForCheck();
    }

    centerMap() {
        this.invalidateSize();
        this.cd.markForCheck();

        const assetsList = Object.values(this.devices);
        if (assetsList.length === 0) {
            const clusterBound = this.pruneCluster.ComputeGlobalBounds();
            if (clusterBound) {
                this.map.fitBounds(new L.LatLngBounds(new L.LatLng(clusterBound.minLat, clusterBound.maxLng), new L.LatLng(clusterBound.maxLat, clusterBound.minLng)), { padding: [50, 50] });
            }
        }

        const arrBounds = [];
        assetsList.forEach(asset => {
            if (asset.theMarker && asset.theMarker.data && asset.theMarker.data.deviceState) {
                if (BOUND_CHECK(asset.theMarker.data.deviceState?.currentPosition?.latitude, MAX_LATITUDE)
                    && BOUND_CHECK(asset.theMarker.data.deviceState?.currentPosition?.longitude, MAX_LONGITUDE)) {

                    if (!asset.theMarker.filtered) {
                        arrBounds.push(new L.LatLng(asset.theMarker.data.deviceState?.currentPosition?.latitude, asset.theMarker.data.deviceState?.currentPosition?.longitude));
                    }
                }
            }
        });

        const bounds = L.latLngBounds(arrBounds);
        if (bounds) {
            if (bounds.isValid()) {
                if (this.map) {
                    this.map.fitBounds(bounds, { padding: [50, 50], maxZoom: 15 });
                }
            }
        }
    }

    invalidateSize() {
        this.map.invalidateSize();
    }
}
