import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getDefaultDpConfig, getGridLanguages, getGridButtons, createdCellCheckbox } from 'app/common/gridhelper';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { BsDatepickerConfig, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { GridBase360Directive } from 'app/common/360Grid.base';
import { Observable, of } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { AccountService } from 'app/services/account/account.service';
import '../../../../vendor/jquery-skedTape/jquery.skedTape.js';

// Moment timezone
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { getIconPath, roundAsNumber, roundAsString, roundMinutes, roundSeconds, contains } from 'app/common/globals';

window['moment'] = Moment;
mTZ();

import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { StorageHelper } from 'app/common/storagehelper';
import { TriggerScheduleService } from 'app/services/triggerSchedule/triggerSchedule.service';
import { AssetGroupInputComponent } from 'app/modules/customInputs/assetGroupSelector.component';
import { GanttEvent } from 'app/models/ganttEvent.model';
import { GanttStatistic } from 'app/models/ganttStatistic.model';
import { VehicleType } from 'app/common/enums';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DriverService } from 'app/services/driver/driver.service';

@Component({
    selector: 'fh-account-equipment-dayview',
    templateUrl: 'dayview.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentDayViewViewComponent implements OnInit {
    @ViewChild('assetGroupVar') el: AssetGroupInputComponent;

    token: string;
    countrySelect = false;

    excludingColumns = ['timeStamp', 'assetDateStart', 'assetDateEnd', 'lastCommunication', 'input1', 'input2', 'input3', 'input4', 'input5', 'input6', 'output1', 'output2'];
    loading = false;
    loadingData = false;

    permissionName = 'Drivers_View';
    constructorName = 'DriverScoreViewComponent';

    selectedResellerId;
    selectedAccountId;
    selectedAssetGroup;
    selectedAssetType;
    selectedProjectId;
    selectedVehicleType;
    selectedDeviceType;

    error: any;
    success: any;
    warning: any;

    timezoneIana: string;
    languageLoaded: boolean;

    trips = [];
    selectedAssets = [];
    hideEmptyRows = true;
    workingSchedulesForAccount = [];

    idlingPercentage = '-';
    equipmentIdlingPercentage = '-';
    engineOnPercentage = '-';
    workingPercentage = '-';
    crossoverPercentage = '-';

    orderBy = 'created';
    orderByReversed = false;
    status;

    // Datepicker
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    to: any;
    from: any;
    daterangepickerModel: any[];

    // Pagination
    itemsPerPage = 20;
    currentPage = 1;
    stringFilter = '';
    totalItems: any[];

    vehicleTypes: { id: string; name: any; }[];
    deviceTypeOptions = [];
    deviceTypes = [];

    deferSearch$: Observable<void>;
    driverAssignmentSource: any;

    showActualHours = false;

    engineOnHours: string;
    idlingHours: string;
    equipmentIdlingHours: string;
    workingHours: string;
    crossoverHours: string;
    mySubscription: any;

    constructor(private cd: ChangeDetectorRef, private driverService: DriverService, private triggerScheduleService: TriggerScheduleService, private accountService: AccountService, private deviceTypeService: DeviceTypeService, private translateService: TranslateService, private authenticationService: AuthenticationService, private route: ActivatedRoute, private router: Router, protected storageHelper: StorageHelper) {
        const that = this;

        this.showActualHours = localStorage.getItem('ShowActualHours' + this.constructorName) === 'true';

        this.token = this.authenticationService.getAuthToken();
        this.timezoneIana = this.authenticationService.getTimeZoneIana()

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(2, 'days').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);
    }

    flipShowActualHours() {
        this.showActualHours = !this.showActualHours;

        this.getData();
    }

    resellerChanged(event) {
        this.trips = [];
        this.selectedAssetGroup = null;
        this.selectedAccountId = null;
    }

    accountChanged(event) {
        this.trips = [];
        this.selectedAssetGroup = null;

        this.getData();
    }

    ngOnInit() {
        this.vehicleTypes = Object.keys(VehicleType)
            .filter(k => typeof VehicleType[k] === 'string')
            .map(n => ({ id: n.toString(), name: VehicleType[n] }));

        // Get all the date for dropdown boxes
        forkJoin([
            this.translateService.get('general.date'),
            this.deviceTypeService.getDeviceTypes()]
        ).subscribe(
            data => {
                this.deviceTypes = data[1].filter(x => x.deviceCount > 0);
                this.deviceTypes = this.deviceTypes.sort((a, b) => (a.modelName > b.modelName ? 1 : -1));

                this.languageLoaded = true;
                this.cd.markForCheck();
            },
            err => {
                this.error = err;
                this.languageLoaded = true;
                this.loading = false;
                this.cd.markForCheck();
            });

        this.deferSearch$ = new Observable(observer => {
            of(this.stringFilter).pipe(debounceTime(500), distinctUntilChanged()).subscribe((_) => {
                this.renderData();
                observer.next();
            });
        });
    }

    getIcon(iconId) {
        return getIconPath(iconId)[2];
    }

    dateChanged(event) {
        const that = this;
        console.log('Changed date');
        if (event !== null) {
            this.getData();
        }
    }

    cancel() {
        this.mySubscription.unsubscribe();

        this.loading = false;

        this.warning = {};
        this.warning.warning = 'Call was cancelled.'
        this.warning.statusText = 'Warning';
    }

    getData() {
        this.loadingData = true;

        this.trips = [];

        if (this.selectedAccountId == null) {
            this.loadingData = false;
            return;
        }

        this.mySubscription = forkJoin([
            this.triggerScheduleService.getTriggerSchedulesByAccount(this.selectedAccountId),
            this.accountService.getAccountTrips(this.selectedAccountId, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), true, false, false, false, this.selectedVehicleType, this.selectedAssetType, this.selectedDeviceType, this.selectedProjectId, this.selectedAssetGroup),
            this.driverService.getDriverAssignmentsByAccountId(this.selectedAccountId, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')),
        ]).subscribe(([workingSchedules, trips, driverAssignments]) => {
            this.workingSchedulesForAccount = workingSchedules;

            trips.forEach(trip => {
                trip.beginTS = moment.utc(trip.beginTS).tz(this.timezoneIana);
                trip.endTS = moment.utc(trip.endTS).tz(this.timezoneIana);
                trip.duration = moment.duration(trip.endTS.diff(trip.beginTS)).asMinutes();

                trip.episodes?.forEach(episode => {
                    episode.episodeStart = moment.utc(episode.episodeStart).tz(this.timezoneIana);
                    episode.episodeEnd = moment.utc(episode.episodeEnd).tz(this.timezoneIana);
                });
            });

            if (this.deviceTypeOptions.length === 0) {
                trips.forEach(device => {
                    // Check if it ha it already
                    if (this.deviceTypeOptions.findIndex(x => x.id === device.deviceTypeId) === -1) {
                        const type = this.deviceTypes.find(x => x.id === device.deviceTypeId);
                        if (type) {
                            this.deviceTypeOptions.push({ id: device.deviceTypeId, value: type.modelName + ' - ' + type.description });
                        }
                    }
                });

                this.deviceTypeOptions.sort((a, b) => a.value.localeCompare(b.value));
            }

            this.trips = trips;
            this.driverAssignmentSource = driverAssignments;
            this.error = null;

            this.renderData();

            this.loadingData = false;
            this.cd.markForCheck();
        }, err => {
            this.error = err;
            this.languageLoaded = true;
            this.loadingData = false;
            this.cd.markForCheck();
        });
    }

    async renderData() {
        const that = this;

        const events: GanttEvent[] = [];
        const assetNames = [];
        const assetIconDictionary = {};
        const scheduleForDevice = {};
        const assetStatistic: { [key: string]: GanttStatistic } = {};

        const episodeTypes = {
            19: this.translateService.instant('general.equipmentIdling'),
            160: this.translateService.instant('general.crossover'),
            161: this.translateService.instant('general.work'),
        }

        const engineOnPrefix = this.translateService.instant('general.engineOn');
        const totalTimespan = (this.daterangepickerModel[1].getTime() - this.daterangepickerModel[0].getTime()) / 1000;

        for (const trip of this.trips) {
            if (!trip.assetId) {
                continue;
            }

            if (trip.assetName?.toLowerCase().indexOf(this.stringFilter?.toLowerCase()) === -1) {
                continue;
            }

            assetStatistic[trip.assetId] = assetStatistic[trip.assetId] ?? new GanttStatistic();

            assetNames[trip.assetId] = trip.assetName ?? trip.assetId;
            assetIconDictionary[trip.assetId] = trip.iconId;

            if (scheduleForDevice[trip.assetId] === undefined || scheduleForDevice[trip.assetId].length === 0) {
                scheduleForDevice[trip.assetId] = [];
            }

            scheduleForDevice[trip.assetId][trip.assignedScheduleId] = [trip.assignedScheduleStart, trip.assignedScheduleEnd];

            // Add the trip
            const tripEvent = {
                start: Moment.max(this.daterangepickerModel[0], trip.beginTS),
                end: trip.endTS ?? Moment(),
                location: trip.assetId,
                name: `${engineOnPrefix}\u200E\n${trip.beginTS.format('lll')}\u00A0-\u00A0${trip.endTS?.format('lll') ?? ''}`,
                stylePlain: 'margin: 10px 0 35px 0; background-color: rgb(0 122 128 / 30%); color: transparent; border: none; box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 30%)',
            };
            events.push(tripEvent);

            assetStatistic[trip.assetId].engineOn.push([tripEvent.start, tripEvent.end]);

            // Add the episodes below
            if (!trip.episodes) {
                continue;
            }

            // Skip idling episodes
            trip.episodes = trip.episodes.filter(x => x.fkDeviceEpisodeTypeId != 131);

            for (const episode of trip.episodes) {
                if (episode.durationInSeconds === 0) {
                    continue;
                }

                if (episode.episodeEnd > episode.episodeStart) {
                    let color = '#ccc';

                    switch (episode.fkDeviceEpisodeTypeId) {
                        case 19:
                            // Equipment idling
                            assetStatistic[trip.assetId].equipmentIdling.push([episode.episodeStart, episode.episodeEnd]);
                            color = '#EF7300e6';
                            break;
                        case 160:
                            // Crossover
                            assetStatistic[trip.assetId].crossover.push([episode.episodeStart, episode.episodeEnd]);
                            color = '#A8973B';
                            break;
                        case 161:
                            // Work
                            assetStatistic[trip.assetId].working.push([episode.episodeStart, episode.episodeEnd]);
                            color = '#01b8aa';
                            break;
                        default:
                            break;
                    }

                    // Insert the episode
                    events.push({
                        start: episode.episodeStart,
                        end: episode.episodeEnd,
                        location: trip.assetId,
                        name: `${episodeTypes[episode.fkDeviceEpisodeTypeId]}\u200E\n${episode.episodeStart.format('lll')}\u00A0-\u00A0${episode.episodeEnd.format('lll')}`,
                        stylePlain: `margin: 13px 0 38px 0; background-color: ${color}; color: transparent; border: 1px solid rgb(0 0 0 / 30%)`,
                    });
                }
            }
        }

        // Option to hide all assets without trips
        if (!this.hideEmptyRows) {
            for (const asset of this.selectedAssets) {
                assetNames[asset.id] = asset.name;
            }
        }

        const startRange = this.daterangepickerModel[0];
        const endRange = this.daterangepickerModel[1];
        const amountOfDays = Moment(endRange).diff(startRange, 'days');

        const workingSchedulePrefix = this.translateService.instant('general.schedule');
        const assets = [];
        const driverNames = [];
        const driverAssignments = [];

        // Display work schedules
        for (const asset in assetNames) {
            if (!asset) { continue };
            assets.push({ id: +asset, name: assetNames[asset] });

            function compareScheduleEntries(a, b) {
                const [_, [aStart, aEnd]] = a;
                const [__, [bStart, bEnd]] = b;

                if (aStart === undefined && bStart === undefined) {
                    return 0;
                } else if (aStart === undefined) {
                    return -1;
                } else if (bStart === undefined) {
                    return 1;
                } else if (aEnd === undefined && bEnd === undefined) {
                    return 0;
                } else if (aEnd === undefined) {
                    return -1;
                } else if (bEnd === undefined) {
                    return 1;
                } else {
                    return aEnd - bEnd;
                }
            }

            const schedulesForDevice = Array(amountOfDays);
            const sortedSchedule = <any>Object.entries(scheduleForDevice[asset]).sort(compareScheduleEntries);

            let filledFirst = false;

            for (const [scheduleId, [assignedScheduleStart, assignedScheduleEnd]] of sortedSchedule) {
                const deviceSchedule = this.workingSchedulesForAccount.find(x => x.id === +scheduleId);

                if (deviceSchedule === undefined) {
                    continue;
                }

                let start = startRange;
                if (assignedScheduleStart !== undefined) {
                    start = moment.utc(assignedScheduleStart).tz(this.timezoneIana).toDate();
                }

                if (filledFirst === false && assignedScheduleEnd === undefined) {
                    start = startRange;
                }

                const rangeDays = Moment(assignedScheduleEnd ?? endRange).diff(start ?? startRange, 'days');
                const startOffset = Moment(start ?? startRange).diff(startRange, 'days');

                for (let i = 0; i < rangeDays + 1; i++) {
                    const schedule = deviceSchedule.schedule[(start.getDay() + i) % 7];
                    const dateString = `${Moment(start).add(i, 'days').format('YYYY-MM-DD')} `;

                    schedulesForDevice[startOffset + i] = {
                        disabled: true,
                        location: +asset,
                        start: new Date(dateString + schedule.startTime),
                        end: new Date(dateString + schedule.endTime),
                        name: `${workingSchedulePrefix}\u200E\n${deviceSchedule.triggerScheduleName}`,
                        stylePlain: 'background-color: rgba(0, 0, 0, 0.15); border: none; border-left: rgba(255, 255, 255, 0.1) solid 1px; border-right: rgba(255, 255, 255, 0.1) solid 1px; color: transparent; z-index: 0;',
                    };
                }

                filledFirst = true;
            }

            for (const schedule of schedulesForDevice) {
                if (schedule === undefined) {
                    continue;
                }

                events.push(schedule);
            }
        }

        for (const driver of this.driverAssignmentSource) {
            if (driver.assetName?.toLowerCase().indexOf(this.stringFilter?.toLowerCase()) === -1) {
                continue;
            }

            driverNames[driver.assetId] = driver.assetName ?? driver.assetId;
            assetIconDictionary[driver.assetId] = driver.iconId;

            const start = driver.dateStart.toDate() > this.daterangepickerModel[0] ? driver.dateStart.toDate() : this.daterangepickerModel[0];
            const end = driver.dateEnd?.toDate() ?? new Date();

            driverAssignments.push({
                name: `${driver.driverName} (${this.translateService.instant('enums.assetDriverSource.' + driver.source)})\u200E\n${driver.dateStart?.format('lll')} - ${driver.dateEnd ? driver.dateEnd?.format('lll') : '-'}`,
                start,
                end,
                location: driver.assetId,
                stylePlain: 'margin: 48px 0 3px 0; opacity: 0.4; background-color: #555; color: #fff; border: none; box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 30%)',
                userData: { source: driver.source, driverId: driver.driverId },
            });
        }

        const assetStatistics = await Promise.all(Object.values(assetStatistic).map(x => new Promise<number[]>((resolve, _) => resolve(x.getAllTotals))));
        const [workingTotal, idlingTotal, equipmentIdlingTotal, engineOnTotal, crossoverTotal] = assetStatistics.reduce((
            [_workingTotal, _idlingTotal, _equipmentIdlingTotal, _engineOnTotal, _crossoverTotal], [_workingSum, _idlingSum, _equipmentIdlingSum, _engineOnSum, _crossoverSum]) =>
            [_workingTotal + _workingSum, _idlingTotal + _idlingSum, _equipmentIdlingTotal + _equipmentIdlingSum, _engineOnTotal + _engineOnSum, _crossoverTotal + _crossoverSum], [0, 0, 0, 0, 0]
        );

        const percentFormatter = new Intl.NumberFormat(undefined, {
            style: 'percent',
            minimumFractionDigits: 1,
        });

        this.engineOnHours = engineOnTotal ? roundMinutes(engineOnTotal / 60, true) + ' H' : '-';
        this.idlingHours = idlingTotal ? roundMinutes(idlingTotal / 60, true) + ' H' : '-';
        this.equipmentIdlingHours = equipmentIdlingTotal ? roundMinutes(equipmentIdlingTotal / 60, true) + ' H' : '-';
        this.workingHours = workingTotal ? roundMinutes(workingTotal / 60, true) + ' H' : '-';
        this.crossoverHours = crossoverTotal ? roundMinutes(crossoverTotal / 60, true) + ' H' : '-';

        this.engineOnPercentage = assets.length > 0 && engineOnTotal ? percentFormatter.format((engineOnTotal / assets.length) / totalTimespan) : '-';
        this.idlingPercentage = assets.length > 0 && idlingTotal ? percentFormatter.format((idlingTotal / assets.length) / totalTimespan) : '-';
        this.equipmentIdlingPercentage = assets.length > 0 && equipmentIdlingTotal ? percentFormatter.format((equipmentIdlingTotal / assets.length) / totalTimespan) : '-';
        this.workingPercentage = assets.length > 0 && workingTotal ? percentFormatter.format((workingTotal / assets.length) / totalTimespan) : '-';
        this.crossoverPercentage = assets.length > 0 && crossoverTotal ? percentFormatter.format((crossoverTotal / assets.length) / totalTimespan) : '-';

        this.totalItems = assets;

        const slidedAssets = assets.slice((this.currentPage - 1) * this.itemsPerPage, ((this.currentPage - 1) * this.itemsPerPage) + this.itemsPerPage);
        const slicedEvents = events.filter(x => contains(slidedAssets.map(y => y.id), x.location));
        const slicedDriverAssignments = driverAssignments.filter(x => contains(slidedAssets.map(y => y.id), x.location));

        $('#skedGantt')['skedTape']({
            caption: `Assets (${assets.length})`,
            start: this.daterangepickerModel[0],
            end: this.daterangepickerModel[1],
            // showEventTime: true,
            // showEventDuration: true,
            scrollWithYWheel: true,
            locations: slidedAssets,
            events: slicedEvents,
            footers: slicedDriverAssignments,
            // maxTimeGapHi: 60 * 1000, // 1 minute
            // minGapTimeBetween: 1 * 60 * 1000,
            snapToMins: 1,
            editMode: false,
            timeIndicatorSerifs: true,
            showIntermission: true,
            showPopovers: 'never',
            formatters: {
                date: function (date) {
                    return $.fn['skedTape'].format.date(date, 'l', '.');
                },
            },
            canAddIntoLocation: function (location, event) {
                return true;
            },
            postRenderLocation: function ($el, location, canAdd) {
                this.constructor.prototype.postRenderLocation($el, location, canAdd);
                const imageTag = '<img loading="lazy" style="position: relative; margin-top: -10px; margin-bottom: -3px; padding-right:10px" src="' + getIconPath(assetIconDictionary[location.id])[1] + '">';
                $el.prepend(imageTag);

                let engineOn = '';
                if (assetStatistic[location.id]?.getEngineOnTotal) {
                    engineOn = `<i class="fas fa-fw fa-square" style="color: #007a801a"></i> ${that.showActualHours ? roundMinutes(assetStatistic[location.id].getEngineOnTotal / 60, true) + ' H' : percentFormatter.format(assetStatistic[location.id].getEngineOnTotal / totalTimespan)}  `;
                }

                let equipmentIdling = '';
                if (assetStatistic[location.id]?.getEquipmentIdlingTotal) {
                    equipmentIdling = `<i class="fas fa-fw fa-square" style="color: #EF7300"></i> ${that.showActualHours ? roundMinutes(assetStatistic[location.id].getEquipmentIdlingTotal / 60, true) + ' H' : percentFormatter.format(assetStatistic[location.id].getEquipmentIdlingTotal / totalTimespan)}  `;
                }

                let working = '';
                if (assetStatistic[location.id]?.getWorkingTotal) {
                    working = `<i class="fas fa-fw fa-square" style="color: #01b8aa"></i> ${that.showActualHours ? roundMinutes(assetStatistic[location.id].getWorkingTotal / 60, true) + ' H' : percentFormatter.format(assetStatistic[location.id].getWorkingTotal / totalTimespan)}  `;
                }

                let crossover = '';
                if (assetStatistic[location.id]?.getCrossoverTotal) {
                    crossover = `<i class="fas fa-fw fa-square" style="color: #A8973B"></i> ${that.showActualHours ? roundMinutes(assetStatistic[location.id].getCrossoverTotal / 60, true) + ' H' : percentFormatter.format(assetStatistic[location.id].getCrossoverTotal / totalTimespan)}  `;
                }

                $el.after(`<span style="display: block; margin: -45px 0px 0px -2px;">${engineOn}${equipmentIdling}${working}${crossover}&nbsp;</div></span`);

                $el.addClass('secondary link_bolder hand').click(function () {
                    document.location.href = '#/AssetDetails/Index/' + location.id;
                });
            },
            postRenderEvent: function ($el, event) {
                $el.attr('data-container', 'body');
            },
        });

        this.loading = false;
        this.cd.markForCheck();
    }

    onChangeCheckbox() {
        if (this.selectedAccountId == null) {
            return;
        }

        this.loading = true;
        this.cd.markForCheck();

        setTimeout(() => {
            this.renderData();
        }, 0);
    }

    actualRound(value, decimals) {
        return roundAsNumber(value, decimals);
    }

    actualRoundMinutes(value) {
        return roundMinutes(value);
    }

    actualRoundSeconds(value) {
        return roundSeconds(value);
    }
}


