import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CarSharingGarage } from 'app/models/carsharingGarage.model';

import { Observable, catchError, map, throwError } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class CarSharingService {
    timestamp;
    url = '';

    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('carsharing');
    }

    getGaragesPagingUrl() {
        return this.url + 'Garages';
    }

    getGarages(): Observable<CarSharingGarage[]> {
        console.log('fetch garages');
        return this.http.get<CarSharingGarage[]>(this.url + 'Garage/', { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    getGarageById(id: number): Observable<CarSharingGarage> {
        console.log('fetch garage by id' + id);
        return this.http.get<CarSharingGarage>(this.url + 'Garage/' + id, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    updateGarage(garage: any): Observable<any> {
        console.log('update garage', garage);

        return this.http.put(this.url + 'Garage/' + garage.id, garage, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    saveGarage(garage: CarSharingGarage): Observable<any> {
        console.log('save garage', garage);

        return this.http.post(this.url + 'Garage/', garage, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    deleteGarage(garage: any): Observable<any> {
        console.log('delete garage', garage);

        return this.http.delete(this.url + 'Garage/' + garage.id, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    getDashboard(): Observable<any> {
        console.log('getting CarSharing Dashboard from service');
        return this.http.get(this.url + 'Dashboard', { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return data;
            }), catchError(this.handleError));
    }

    getDamages(): Observable<any> {
        console.log('getting CarSharing damages from service');
        return this.http.get(this.url + 'Damages', { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return data;
            }), catchError(this.handleError));
    }

    private handleError(error: Response) {
        return throwError(error);
    }
}
