import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';



import { Observable, catchError, map } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { Fuel, FuelUpload } from 'app/models/fuel.model';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { throwError } from 'rxjs/internal/observable/throwError';
window['moment'] = Moment;
mTZ()

@Injectable()
export class FuelService {
    url = '';
    base_url = '';
    inventoryUrl = '';
    Fuels: Fuel[] = [];
    timezoneIana: string;

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = this.authenticationService.getWebserviceURL('fuel');
        this.base_url = this.authenticationService.getWebserviceURL('');
        this.timezoneIana = this.authenticationService.getTimeZoneIana();
    }

    getPagingUrl(start, end, percentage, accountId) {
        return this.url + 'Paging?accountId=' + accountId + '&startRange=' + start.unix() + '&endRange=' + end.unix() + '&percentage=' + percentage;
    }

    getPagingThresholdsUrl() {
        return this.url + `PagingThresholds`;
    }

    getFuels(start, end): Observable<Fuel[]> {
        console.log('getting fuel from service');
        return this.http.get(this.url + '?start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return this.parseResponse(data);
            }), catchError(this.handleError));
    }

    getFuelEventById(id: string): Observable<Fuel> {
        console.log('Fetch fuel by id ' + id);
        return this.http.get(this.url + id, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return this.parseReponseDetails(data);
            }), catchError(this.handleError));
    }

    getFuelEventsByDevice(deviceId: string, start, end, certainty): Observable<any> {
        return this.http.get(this.base_url + 'device/' + deviceId + '/fuelEvents?start=' + start.unix() + '&end=' + end.unix() + '&certainty=' + certainty, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return this.parseResponse(data);
            }), catchError(this.handleError));
    }

    getFuelEventsByParentId(id: string): Observable<Fuel[]> {
        console.log('getting fuel from service');
        return this.http.get(this.url + id + '/children', { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return this.parseResponse(data);
            }), catchError(this.handleError));
    }

    saveFuelEvent(fuel: Fuel): Observable<any> {
        console.log('save fuel', fuel);

        return this.http.post(this.url, fuel, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    updateFuelEvent(fuel: Fuel): Observable<any> {
        console.log('update fuel', fuel);

        return this.http.put(this.url + fuel.id, fuel, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    uploadFuelEvent(fuel: FuelUpload[]): Observable<any> {
        return this.http.post(this.url + 'upload', fuel, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    deleteFuelEvent(id: string): Observable<any> {
        console.log('delete fuel', id);

        return this.http.delete(this.url + id, { headers: this.authenticationService.headers })
            .pipe(catchError(this.handleError));
    }

    private handleError(error: Response) {
        return throwError(error);
    }

    parseResponse(json: any): Fuel[] {
        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Fuels.');

        const ident = 1;
        const fuels: Fuel[] = [];

        json.forEach(item => {

            const fuel = this.parseReponseDetails(item);
            fuels.push(fuel);
        });

        return fuels;
    }

    parseReponseDetails(item) {
        const fuel = new Fuel();
        fuel.id = item.id;
        fuel.assetName = item.assetName;
        fuel.assetCode = item.assetCode;
        fuel.plateNumber = item.plateNumber;
        fuel.assetSearchName = item.assetName + ' - ' + item.unitId + ' - ' + item.assetCode + ' - ' + item.plateNumber;
        fuel.unitId = item.unitId;
        fuel.iconId = item.iconId;
        fuel.certaintyInPercentage = item.certaintyInPercentage;
        fuel.companyName = item.companyName;
        fuel.deviceId = item.deviceId;
        fuel.tags = item.tags;
        fuel.endFuelEventFuelLevelInLiters = item.endFuelEventFuelLevelInLiters;
        fuel.eventTimeStamp = Moment.utc(item.eventTimeStamp)['tz'](this.timezoneIana);
        fuel.fuelingActivityInLiters = item.fuelingActivityInLiters;
        fuel.latitude = item.latitude;
        fuel.longitude = item.longitude;
        fuel.refuelVsTheft = item.refuelVsTheft;
        fuel.resellerDescription = item.resellerDescription;
        fuel.resellerId = item.resellerId;
        fuel.source = item.source;
        fuel.auxiliaryAttachments = item.auxiliaryAttachments;
        fuel.timestamp = Moment.utc(item.timestamp)['tz'](this.timezoneIana);
        fuel.accountId = item.accountId;
        fuel.isValid = item.isValid;
        fuel.fuelTankCapacityInLiters = item.fuelTankCapacityInLiters;
        fuel.fuelTank1CapacityInLiters = item.fuelTank1CapacityInLiters ? item.fuelTank1CapacityInLiters : 0;
        fuel.fuelTank2CapacityInLiters = item.fuelTank2CapacityInLiters ? item.fuelTank2CapacityInLiters : 0;
        fuel.fillupThresholdPercentage = item.fillupThresholdPercentage ? item.fillupThresholdPercentage : 5;
        fuel.theftThresholdPercentage = item.theftThresholdPercentage ? item.theftThresholdPercentage : 5;
        fuel.geofenceId = item.geofenceId;
        fuel.geofenceLabel = item.geofenceLabel;
        fuel.geoJson = item.geoJson;
        fuel.geoColor = item.geoColor;

        return fuel;
    }
}
