<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/CustomCommands/Overview">{{'general.customCommands' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.add' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-navigation>
        <li class="active">
            <a href="/#/Devices/CustomCommands/Add/"><i class="fas fa-fw fa-tower-broadcast"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a>
        </li>
    </fh-device-navigation>
</div>

<div class="wrapper wrapper-content">
    <form #form="ngForm">
        <fh-custom-command-details [formMode]="formMode" [form]="form">
        </fh-custom-command-details>
    </form>
</div>