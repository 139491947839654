<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="" *ngIf="error || success">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>
    <div class="row" data-equalizer="">
        <div class="col-lg-9 col-md-8">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-fw fa-podcast"></i>{{ 'general.sharedWith' | translate }}
                    </h5>
                    <span class="float-right">
                        <div class="filter-form d-none d-md-block">
                            <input type="text" class="form-control table-inline top-filter d-none d-md-block"
                                [(ngModel)]="filter">
                        </div>
                    </span>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table class="table table-fixed nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <thead>
                            <tr>
                                <th style="width:auto" scope="col">
                                    {{ 'general.emailLoginName' | translate}}
                                </th>
                                <th style="width: 155px; " scope="col">
                                    {{ 'general.createdDate' | translate}}
                                </th>
                                <th style="width:45px;" scope="col">
                                    <span [tooltip]="'general.sharedAssetGroupsLabel' | translate">
                                        <i style="margin: 0 5px;" class="fas fa-fw fa-car-bus"></i></span>
                                </th>
                                <th style="width:45px;" scope="col">
                                    <span [tooltip]="'general.sharedDriverGroupsLabel' | translate">
                                        <i style="margin: 0 5px;" class="fa fa-fw fa-id-card-alt"></i></span>
                                </th>
                                <th style="width:50px;" scope="col">
                                    &nbsp;
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ngx-loading [show]="loading"></ngx-loading>
                            <ng-container
                                *ngFor="let child of sharedItems | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage, id: 'sharing'}">
                                <tr>
                                    <td style="width:auto" class="elipses_overflow">
                                        {{ child.emailLoginName }} </td>
                                    <td style="width: 155px; text-align: left;">
                                        {{ child.timestamp | amDateFormat: 'YYYY-MM-DD HH:mm'}}
                                    </td>
                                    <td style="width: 45px; text-align: center;">
                                        <a class='btn btn-primary btn-grid' (click)="assetGroupChooser.showModal(child)"
                                            title="{{'general.sharedAssetGroupsLabel' | translate }}">
                                            <i class="fas fa-fw fa-edit"></i>
                                        </a>
                                    </td>
                                    <td style="width: 45px; text-align: center;">
                                        <a class='btn btn-primary btn-grid'
                                            (click)="driverGroupChooser.showModal(child)"
                                            title="{{'general.sharedDriverGroupsLabel' | translate }}">
                                            <i class="fas fa-fw fa-edit"></i>
                                        </a>
                                    </td>
                                    <td style="width: 50px; text-align: right;">
                                        <a class='btn btn-danger btn-grid' (click)="deleteAccountViewer(child.id)"
                                            title="{{'general.delete' | translate }}">
                                            <i class="fas fa-fw fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="sharedItems.length === 0">
                                <td colspan="5">
                                    {{'general.nodatafound' | translate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer noselect dataTables_wrapper">
                    <pagination-template #ps="paginationApi" (pageChange)="currentPage = $event" id="sharing">
                        <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                            <ul class="pagination">
                                <li class="paginate_button page-item previous" [class.disabled]="ps.isFirstPage()">
                                    <a class="page-link" (click)="ps.previous()" *ngIf="!ps.isFirstPage()">{{
                                        'grid.previous' | translate }}</a>
                                    <a *ngIf="ps.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                </li>

                                <li class="paginate_button page-item " *ngFor="let page of ps.pages"
                                    [class.current]="ps.getCurrent() === page.value">
                                    <a class="page-link" (click)="ps.setCurrent(page.value)"
                                        *ngIf="ps.getCurrent() !== page.value">
                                        <span>{{ page.label }}</span>
                                    </a>
                                    <a class="page-link" (click)="ps.setCurrent(page.value)"
                                        *ngIf="ps.getCurrent() === page.value">
                                        <span style="font-weight: bold;">{{ page.label }}</span>
                                    </a>
                                </li>

                                <li class="paginate_button page-item next" [class.disabled]="ps.isLastPage()">
                                    <a class="page-link" (click)="ps.next()" *ngIf="!ps.isLastPage()">
                                        {{ 'grid.next' | translate }}
                                    </a>
                                    <a class="page-link" *ngIf="ps.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                </li>
                            </ul>
                        </div>
                    </pagination-template>
                    <div style="clear:both"></div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-bolt"></i> {{'general.actions' | translate }}
                    </h5>
                </div>
                <div class="ibox-footer" style="position: relative">
                    <button class="btn btn-primary" style="width:100%" (click)="sharingCreate.showModal()">
                        <i class="fa fa-fw fa-plus"></i> {{'general.add' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<fh-assetgroup-chooser #assetGroupChooser (onSelect)="assignGroups($event)">
</fh-assetgroup-chooser>

<fh-drivergroup-chooser #driverGroupChooser (onSelect)="assignDriverGroups($event)">
</fh-drivergroup-chooser>

<fh-sharing-create-modal #sharingCreate [accountId]="accountId" (onSelect)="onSelect($event)">
</fh-sharing-create-modal>