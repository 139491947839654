import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { InfoService } from 'app/services/info/info.service';

@Component({
  selector: 'fh-general-system',
  templateUrl: 'general.template.html'
})

export class SystemGeneralComponent implements OnInit {
  loading: boolean;

  health;

  constructor(private infoService: InfoService, private cd: ChangeDetectorRef) {
  }


  ngOnInit(): void {
    this.loading = true;

    this.infoService.getHealth().subscribe(result => {

      Object.keys(result.entries)?.forEach(element => {
        result.entries[element].isOpen = false;

        if (result.entries[element].status == 'Unhealthy') {
          result.entries[element].isOpen = true;
        }
      });

      this.health = result;

      this.loading = false;
      this.cd.markForCheck();
    }, error => {

      console.log(error);
      this.health = error.error;

      this.loading = false;
      this.cd.markForCheck();
    });
  }

  flipEntry(entry) {
    if (entry) {
      entry.isOpen = !entry.isOpen;
    }
  }

  checkEntry(entry) {
    if (entry) {
      return entry.isOpen;
    }
  }
}
