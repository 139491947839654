export class StateObject {
    public timestamp;
    public deviceStates: DeviceStatesItem[];
}

export class DeviceStatesItem {
    public id: number;
    public currentPosition: any;
    public cellPosition: any;
    public communicationState: any;
    public calculatedDeviceState: any;
    public insideGeofences: any;
    public engineHour: any;
    public assetName: any;
    public odometer: any;
    public markerColor: string;
    public tagScanStatus: any;
    public currentAddress: any;
}
