<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Accounts/Overview">{{'general.accounts' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="/#/Accounts/Overview">{{'menu.add' | translate }}</a>
                </li>
            </ol>
        </nav>

        <span class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix d-none d-md-inline-flex" title="Help"
                href="https://help.360locate.com/knowledge-base/fleet-management/add-a-new-account/" target="_blank"><i
                    class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span
                    class="d-none d-md-inline-flex">{{'general.needHelp' | translate
                    }}</span></a>
        </span>
    </div>
    <fh-account-navigation>
        <li class="active"><a href="/#/Accounts/Add/"><i class="fas fa-fw fa-building"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a></li>
    </fh-account-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <div class="row">
            <form #userForm="ngForm">
                <div class="col-md-6">

                    <div class="ibox" style="position:relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-building"></i> {{'general.account' | translate }}
                            </h5>
                        </div>
                        <fh-account-details [account]="account" [formMode]="formMode"></fh-account-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()" [formMode]="formMode"
                            [allowAdd]="permissions['Accounts_Add']">
                        </fh-save-footer>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="ibox" style="position:relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-users"></i> {{'menu.addUser' | translate }}
                            </h5>
                        </div>
                        <fh-account-user-details [user]="user" [formMode]="formMode"></fh-account-user-details>
                    </div>
                </div>
            </form>
        </div>
    </div>
</fh-permission-handler>