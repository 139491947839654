<fh-error [success]="success" [error]="error"></fh-error>

<div class="ibox">
    <div class="ibox-title">
        <h5 style="font-weight: bold;"><i class="fas fa-fw fa-traffic-light-stop"></i> {{ 'general.triggerOutput' |
            translate}} </h5>
        <span class="float-right">
            <h5><span style="margin-top: -10px; display: inline; " class="btn btn-info">4</span></h5>
        </span>
    </div>
    <div class="ibox-content slick" style="position:relative;">
        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
            <tbody *ngIf="(trigger && trigger.id) || formMode != 1">
                <tr>
                    <td class="row_opening"> {{ 'general.storeAsEpisode' | translate}}</td>
                    <td class="row_content">
                        <div class="fa_checkbox">
                            <input [checked]="true" [disabled]="formMode == 1" id="storeAsEpisode" type="checkbox"
                                name="storeAsEpisode" [(ngModel)]="trigger.storeAsEpisode" />
                            <label for="storeAsEpisode">
                            </label>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="trigger.storeAsEpisode">
                    <td class="row_opening"> {{ 'general.generatedEpisodeType' | translate}}
                        <span class="error" *ngIf="episodeTypeVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <ng-select name="episodeType" ngDefaultControl [searchable]="false" [clearable]="false"
                            placeholder="{{ 'general.episodeType' | translate}} ({{triggerNames.length}})"
                            [disabled]="formMode == 1" [(ngModel)]="trigger.episodeType" #episodeTypeVar="ngModel"
                            class="form-control table-inline" style="width:100%" required>
                            <ng-option *ngFor="let triggerName of triggerNames" [value]="triggerName.value">
                                {{triggerName.name}}
                            </ng-option>
                        </ng-select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="ibox">
    <div class="ibox-title">
        <h5 style="font-weight: bold;"><i class="fas fa-fw fa-envelope-open-text"></i> {{ 'general.message' |
            translate}} </h5>
    </div>
    <div class="ibox-content slick" style="position:relative;">
        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
            <tbody>
                <tr>
                    <td class="row_opening"> {{ 'general.outputMessage' | translate}}</td>
                    <td class="row_content">
                        <div class="fa_checkbox">
                            <input [checked]="true" [disabled]="formMode == 1" id="sendOutputMessage" type="checkbox"
                                name="sendOutputMessage" [(ngModel)]="trigger.sendOutputMessage"
                                [(ngModel)]="trigger.sendOutputMessage" />
                            <label for="sendOutputMessage">
                            </label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening"> {{ 'general.fmNotification' | translate}}</td>
                    <td class="row_content">
                        <div class="fa_checkbox">
                            <input [checked]="true" [disabled]="formMode == 1" id="sendFmNotification" type="checkbox"
                                name="sendFmNotification" [(ngModel)]="trigger.sendFmNotification" />
                            <label for="sendFmNotification">
                            </label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening"> {{ 'general.mobileNotification' | translate}}</td>
                    <td class="row_content">
                        <div class="fa_checkbox">
                            <input [checked]="true" [disabled]="formMode == 1" id="sendMobileNotification"
                                type="checkbox" name="sendMobileNotification"
                                [(ngModel)]="trigger.sendMobileNotification" />
                            <label for="sendMobileNotification">
                            </label>
                        </div>
                    </td>
                </tr>
                <tr
                    *ngIf="(trigger.sendOutputMessage || trigger.sendFmNotification || trigger.sendMobileNotification) && trigger.triggerType == 2">
                    <td class="row_opening">
                        {{ 'general.moment' | translate}}
                        <span class="error" *ngIf="notificationMoment.invalid && formMode != 1">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible">
                        <ng-select class="form-control table-inline" required #notificationMoment="ngModel"
                            name="webhookMoment" [clearable]="false" [searchable]="false" [disabled]="formMode == 1"
                            placeholder="{{ 'general.notificationMoment' | translate }}"
                            [(ngModel)]="trigger.outputMessageMoment" style="width:100%">
                            <ng-option [value]=1> {{ 'enums.notificationMoment.1' | translate}} </ng-option>
                            <ng-option [value]=2> {{ 'enums.notificationMoment.2' | translate}} </ng-option>
                            <ng-option [value]=3> {{ 'enums.notificationMoment.3' | translate}} </ng-option>
                        </ng-select>
                    </td>
                </tr>
                <tr *ngIf="trigger.sendOutputMessage || trigger.sendFmNotification || trigger.sendMobileNotification">
                    <td class="row_opening"> {{ 'general.userContactGroup' | translate}}
                        <span class="error" *ngIf="userContactGroupVar?.invalid">*</span>

                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <fh-user-contact-group-input name="userContactGroup" #userContactGroupVar="ngModel" required
                            [selectedAccountId]="trigger.accountId" [readonly]="formMode == 1"
                            placeholder="{{ (trigger.accountId? 'general.chooseUserContactGroup' : 'general.firstChooseAccount') | translate}}"
                            [(ngModel)]="trigger.userContactGroups">
                        </fh-user-contact-group-input>
                    </td>
                </tr>
                <tr *ngIf="(trigger.sendOutputMessage || trigger.sendFmNotification || trigger.sendMobileNotification)">
                    <td class="row_opening"> {{ 'general.subject' | translate}}
                        <span class="error" *ngIf="subjectTemplateVar?.invalid">*</span>
                    </td>
                    <td class="row_content">
                        <input type="text" [readonly]="formMode == 1" name="subject" class="form-control table-inline"
                            placeholder="{{ 'placeholder.subjectTemplate' | translate}}" required
                            [(ngModel)]="trigger.subjectTemplate" minlength="3" #subjectTemplateVar="ngModel">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="ibox-content" style="position: relative;"
        *ngIf="(trigger.sendOutputMessage || trigger.sendFmNotification || trigger.sendMobileNotification) ">
        <textarea id="textbox" name="text" #textVar="ngModel" class="noresize stickypad" [disabled]="formMode == 1"
            style="height:150px; width:100%; resize: none" [(ngModel)]="trigger.messageBodyTemplate" required></textarea>
    </div>
    <div class="ibox-footer"
        *ngIf="(trigger.sendOutputMessage || trigger.sendFmNotification || trigger.sendMobileNotification) ">
        <button class="btn btn-info" (click)="preview(trigger.messageBodyTemplate, trigger.subjectTemplate);"
            title="{{ 'general.preview' | translate }}"><i class="fa fa-fw fa-eye"></i>
            {{'general.preview' | translate }}</button>

        <button class="btn btn-info" (click)="showAvailableFields();"
            title="{{ 'general.showAvailableFields' | translate }}"><i class="fa fa-fw fa-eye"></i>
            {{'general.showAvailableFields' | translate }}</button>
    </div>
</div>

<fh-error [success]="jsonValid" [error]="jsonInvalid"></fh-error>
<div class="ibox">
    <div class="ibox-title">
        <h5 style="font-weight: bold;"><i class="fas fa-fw fa-network-wired"></i> {{ 'general.webhook' | translate}}
        </h5>
    </div>
    <div class="ibox-content slick" style="position:relative;">
        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
            <tbody>
                <tr>
                    <td class="row_opening"> {{ 'general.useWebhook' | translate}}</td>
                    <td class="row_content">
                        <div class="fa_checkbox">
                            <input [checked]="true" [disabled]="formMode == 1" id="useWebHook" type="checkbox"
                                name="useWebHook" [(ngModel)]="trigger.useWebHook" />
                            <label for="useWebHook">
                            </label>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="trigger.useWebHook">
                    <td class="row_opening"> {{ 'general.webhookEndpoint' | translate}}
                        <span class="error" *ngIf="webhookEndpointVar.invalid && formMode != 1">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible">
                        <fh-webhook-endpoint-input name="webhookEndpoints" #webhookEndpointVar="ngModel" required
                            [selectedAccountId]="trigger.accountId" [readonly]="formMode == 1"
                            (onChanged)="webhookEndpointChanged($event)"
                            placeholder="{{ (trigger.accountId? 'general.chooseWebhookEndpoint' : 'general.firstChooseAccount') | translate}}"
                            [(ngModel)]="trigger.webhookEndpointId"></fh-webhook-endpoint-input>
                    </td>
                </tr>
                <tr *ngIf="trigger.useWebHook && trigger.triggerType == 2">
                    <td class="row_opening">
                        {{ 'general.moment' | translate}}
                        <span class="error" *ngIf="webhookMoment.invalid && formMode != 1">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible">
                        <ng-select class="form-control table-inline" required #webhookMoment="ngModel"
                            name="webhookMoment" [readonly]="formMode === 1" [clearable]="false" [searchable]="false"
                            placeholder="{{ 'general.webhookMoment' | translate }}" [(ngModel)]="trigger.webhookMoment"
                            style="width:100%">
                            <ng-option [value]=1> {{ 'enums.notificationMoment.1' | translate}} </ng-option>
                            <ng-option [value]=2> {{ 'enums.notificationMoment.2' | translate}} </ng-option>
                            <ng-option [value]=3> {{ 'enums.notificationMoment.3' | translate}} </ng-option>
                        </ng-select>
                    </td>
                </tr>
                <tr *ngIf="trigger.useWebHook && webhookEndpoint">
                    <td class="row_opening"> {{ 'general.webHookUrl' | translate}}
                    </td>
                    <td class="row_content">
                        <input type="text" [readonly]="true" name="webhookEndpointUrl" class="form-control table-inline"
                            [(ngModel)]="webhookEndpoint.url">
                    </td>
                </tr>
                <tr *ngIf="trigger.useWebHook">
                    <td class="row_opening"> {{ 'general.webHookMethod' | translate}}
                        <span class="error" *ngIf="webHookMethodVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <ng-select class="form-control table-inline" name="webHookMethod" [clearable]="false"
                            #webHookMethodVar="ngModel" [(ngModel)]="webhookMethod"
                            changed="webHookMethodChanged($event)"
                            placeholder="{{ 'general.chooseWebHookMethod' | translate }}" required
                            pattern="(5000|([-1-4][-1-9][-1-9][-1-9])|([-1-9][-1-9][-1-9])|([-1-9][-1-9])|[-1-9])"
                            [disabled]="formMode == 1" style="width:100%">
                            <ng-container *ngIf="trigger.webHookMethod == 0">
                                <ng-option [value]=0 [disabled]="true"> {{ 'enums.webHookMethod.0' | translate}}
                                </ng-option>
                            </ng-container>
                            <ng-option [value]=-1>{{ 'general.useFromBase' | translate }}</ng-option>
                            <ng-option [value]=1> {{ 'enums.webHookMethod.1' | translate}} </ng-option>
                            <ng-option [value]=2> {{ 'enums.webHookMethod.2' | translate}} </ng-option>
                            <ng-option [value]=3> {{ 'enums.webHookMethod.3' | translate}} </ng-option>
                            <ng-option [value]=4> {{ 'enums.webHookMethod.4' | translate}} </ng-option>
                        </ng-select>
                    </td>
                </tr>
                <tr *ngIf="trigger.useWebHook">
                    <td class="row_opening"> {{ 'general.webHookContentType' | translate}}
                        <span class="error" *ngIf="webHookContentTypeVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <ng-select class="form-control table-inline" name="webHookContentType" [clearable]="false"
                            #webHookContentTypeVar="ngModel" [(ngModel)]="webhookContentType" [searchable]="false"
                            placeholder="{{ 'general.chooseWebHookContentType' | translate }}" required
                            [disabled]="formMode == 1" style="width:100%" (change)="webhookContentTypeChanged($event)">
                            <ng-container *ngIf="trigger.webHookContentType == 0">
                                <ng-option [value]=0 [disabled]="true"> {{ 'enums.webHookContentType.0' | translate}}
                                </ng-option>
                            </ng-container>
                            <ng-option [value]=-1>{{ 'general.useFromBase' | translate }}</ng-option>
                            <ng-option [value]=1> {{ 'enums.webHookContentType.1' | translate}} </ng-option>
                            <ng-option [value]=2> {{ 'enums.webHookContentType.2' | translate}} </ng-option>
                            <ng-option [value]=3> {{ 'enums.webHookContentType.3' | translate}} </ng-option>
                            <ng-option [value]=4> {{ 'enums.webHookContentType.4' | translate}} </ng-option>
                            <ng-option [value]=5> {{ 'enums.webHookContentType.5' | translate}} </ng-option>
                        </ng-select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="ibox-content slick" style="position: relative;" *ngIf="trigger.useWebHook">
        <form #headersForm="ngForm">
            <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                <thead>
                    <tr>
                        <th [colSpan]="2">{{ 'general.webHookHeadersInfo' | translate }}</th>
                        <th *ngIf="!(formMode == 1)" style="width: 40px; padding-top: 18px;">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="!(formMode == 1)">
                        <td style="width: auto">
                            <input [readonly]="formMode == 1" type="text" required name="headerName"
                                [(ngModel)]="webHookHeaderName" class="form-control table-inline"
                                #webHookHeaderNameProp="ngModel">
                        </td>
                        <td style="width: auto;">
                            <input [readonly]="formMode == 1" type="text" required name="headerProperty"
                                [(ngModel)]="webHookHeaderProperty" class="form-control table-inline"
                                #webHookHeaderPropertyProp="ngModel">
                        </td>
                        <td style="width: 40px; padding-top: 18px;">
                            <button class="btn btn-primary float-right btn-grid" [disabled]="headersForm.invalid"
                                (click)="addRow(webHookHeaderNameProp, webHookHeaderPropertyProp)">
                                <i class="fa fa-plus"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let header of trigger?.webHookHeaders">
                        <td style="width: auto">
                            <input type="text" [readonly]="true" class="form-control table-inline"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="header.webHookHeaderName">
                        </td>
                        <td style="width: auto;">
                            <input type="text" [readonly]="true" class="form-control table-inline"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="header.webHookHeaderProperty">
                        </td>
                        <td *ngIf="!(formMode == 1)" style="width: 40px; padding-top: 18px;">
                            <button class="btn btn-primary float-right btn-grid" (click)="removeRow(header)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
    <div class="ibox-content slick" style="position: relative;" *ngIf="trigger.useWebHook">
        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
            <thead>
                <tr>
                    <th>{{ 'general.webHookMessageBodyInfo' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="width: auto">
                        <textarea id="textbox" name="json" #jsonVar="ngModel" class="noresize stickypad"
                            [disabled]="formMode == 1" style="height:300px; width:100%;"
                            [(ngModel)]="trigger.json"></textarea>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="ibox-footer" *ngIf="trigger.useWebHook">
        <button class="btn btn-info" (click)="preview(trigger.json, 'JSON');"
            title="{{ 'general.preview' | translate }}"><i class="fa fa-fw fa-eye"></i>
            {{'general.preview' | translate }}</button>

        <button class="btn btn-info" (click)="showAvailableFields();"
            title="{{ 'general.showAvailableFields' | translate }}"><i class="fa fa-fw fa-eye"></i>
            {{'general.showAvailableFields' | translate }}</button>
        <button class="btn btn-info" (click)="validateJson();" title="{{ 'general.validate' | translate }}"><i
                class="fa fa-fw fa-eye"></i>
            {{'general.validate' | translate }}</button>
    </div>
</div>

<fh-confirmation-modal #popupModal [showButtons]=false>
    <h4 header class="modal-title pull-left"><i class="fa fa-fw fa-bolt"></i> {{'general.popup' | translate }}</h4>
    <div class="modal-body" body>
        <div *ngIf="displayPreview" style="max-height: 400px;overflow-y: auto;padding: 0px">
            <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                <tbody>
                    <tr>
                        <td style="width:100px">{{ 'general.subject' | translate}}</td>
                        <td style="width:calc(100% - 100px)"><span [innerHTML]="previewSubject"></span></td>
                    </tr>
                    <tr>
                        <td colspan="2"><span [innerHTML]="previewText"></span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="displayAvailableFields" style="max-height: 400px;overflow-y: auto;padding: 0px">
            <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                <tbody>
                    <tr *ngFor="let field of fieldList; let idx=index;">
                        <td class="row_opening">
                            <fh-copyinput [textMode]=true>
                                {{ '{' + '{' + field.key + '}' + '}'}}
                            </fh-copyinput>
                        </td>
                        <td class="row_content">{{ ('fieldName.' + field.key) | translate}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</fh-confirmation-modal>