<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()">
    <fh-wizard-step title="{{ 'enums.batchAction.26' | translate }}" icon='fa-user' (open)="initForm()"
        [validate]="groupForm">
        <div class="wizard-content">
            <form [formGroup]="groupForm">
                <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                    <tbody>
                        <tr *ngIf="showWarning">
                            <td colspan="2" class="alert alert-warning shadow">{{ 'general.warningOnlyOneAccount' |
                                translate }}
                            </td>
                        </tr>
                        <tr>
                            <td>{{ 'general.useCase' | translate }}</td>
                            <td>
                                <ng-select ngDefaultControl [searchable]="false" [clearable]="false" [multiple]="false"
                                    [(ngModel)]="useCase" [readonly]="false" (ngModelChange)="useCaseChanged()"
                                    placeholder="{{ 'general.chooseUsecase' | translate}}" #input
                                    formControlName="useCase" required class="form-control table-inline">
                                    <ng-option *ngFor="let useCase of useCaseOptions | orderBy : 'name':false"
                                        [value]="useCase.id">
                                        {{ useCase.name }}
                                    </ng-option>
                                </ng-select>
                            </td>
                        </tr>

                        <!-- ExternalPowerLost -->
                        <tr *ngIf="useCase == 1">
                            <td colspan="2">
                                <i>On <b>timer every 5m</b>, when <b>external power</b> is <b>off</b> for <b>5m</b></i>
                            </td>
                        </tr>

                        <!-- FixedSpeeding -->
                        <ng-container *ngIf="useCase == 2">
                            <tr>
                                <td colspan="2">
                                    <i>On <b>message received</b>, when <b>speed</b> is > <b>X</b> for <b>10s</b></i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>{{ 'placeholder.maxSpeed' | translate }}</span>
                                </td>
                                <td>
                                    <input class="form-control table-inline" required formControlName="value"
                                        [disabled]="useCase == -1" [(ngModel)]="value"
                                        placeholder="{{ 'placeholder.maxSpeed' | translate }}">
                                </td>
                            </tr>
                        </ng-container>

                        <!-- Geofence -->
                        <tr *ngIf="useCase == 3">
                            <td colspan="2"><i>On <b>geofence change</b>, when <b>Inside/Outside</b> any geofence(s)</i>
                            </td>
                        </tr>

                        <!-- UseOutsideSchedule -->
                        <tr *ngIf="useCase == 4">
                            <td colspan="2"><i>On <b>message received</b>, when <b>Tracker Status</b> is <b>Ignition
                                        On</b> for
                                    <b>60s</b></i></td>
                        </tr>

                        <!-- UseOutsideGeofences -->
                        <tr *ngIf="useCase == 5">
                            <td colspan="2"><i>On <b>geofence change</b>, when <b>Outside</b> ALL geofence(s) AND
                                    <b>Tracker
                                        Status</b> is <b>Ignition On</b></i></td>
                        </tr>

                        <tr>
                            <td class="row_opening"> {{ 'general.notifyOnCompletion' | translate}}</td>
                            <td class="row_content">
                                <div class="fa_checkbox">
                                    <input [checked]="false" id="sendOutputMessage" type="checkbox"
                                        name="sendOutputMessage" formControlName="sendOutputMessage" />
                                    <label for="sendOutputMessage">
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="groupForm.get('sendOutputMessage').value">
                            <td class="row_opening"> {{ 'general.userContactGroup' | translate}}
                                <span class="error" *ngIf="groupForm.get('sendOutputMessage').errors">*</span>

                            </td>
                            <td class="row_content" style="overflow: visible;">
                                <fh-user-contact-group-input name="userContactGroup" formControlName="userContactGroups"
                                    [selectedAccountId]="accountId"
                                    placeholder="{{ (accountId ? 'general.chooseUserContactGroup' : 'general.firstChooseAccount') | translate}}">
                                </fh-user-contact-group-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon='fa-file-pdf'>
        <div class="wizard-content" style="padding-bottom: 10px">
            {{ 'wizard.optionalComment' | translate }}
        </div>
        <div class="wizard-content">
            <textarea class=" form-control noresize stickypad" style="height:100px; width:100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}" [(ngModel)]="logItem"></textarea>
        </div>
    </fh-wizard-step>
</fh-wizard>