import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getDefaultDpConfig, getGridLanguages, getGridButtons, createdCellCheckbox } from 'app/common/gridhelper';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { GridBase360Directive } from 'app/common/360Grid.base';
import { Observable, Subject, forkJoin } from 'rxjs';

import { getIconPath, roundAsNumber, roundAsString, roundMinutes, roundSeconds } from 'app/common/globals';
import { AccountService } from 'app/services/account/account.service';
import { FhChartService } from 'app/services/charts/charts.service';

import * as Highcharts from 'highcharts';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { StorageHelper } from 'app/common/storagehelper';
import { TriggerScheduleService } from 'app/services/triggerSchedule/triggerSchedule.service';

// Moment timezone
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { ColorService } from 'app/services/common/color.service';

window['moment'] = Moment;
mTZ();


@Component({
    providers: [FhChartService],
    selector: 'fh-account-costs',
    templateUrl: 'costs.template.html'
})
export class AccountCostsViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
    Highcharts: typeof Highcharts = Highcharts;

    loading: boolean;
    asset: any;
    sub: any;
    messages: any;

    theMarker;

    excludingColumns = ['timestamp'];

    // Datepicker
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();

    to: any;
    from: any;
    daterangepickerModel: any[];
    permissions: {};
    languageLoaded: boolean;

    maxDate = new Date();
    sensors = [];
    constructorName = 'AccountUtilizationViewComponent';

    filterZeroValues = true;

    timezoneIana: string;
    token: string;

    error: any;
    warning: any;
    success: any;
    limit = 1000;

    loadingLocations = false;
    previousLookupTimestamp;
    locationSubscription: any;
    loadingLocation: boolean;

    updatesActive = true;
    isLoaded = false;
    loadingCount = false;
    randomKey: number;
    distanceChartData = [];
    devices = [];
    chartDistance;
    chartLocationCount;
    chartActiveDevices;
    chartDistanceCompare;
    chartCostPerType;
    chartUtilization;
    kpis;

    selectedResellerId;
    selectedAccountId;
    selectedAssetGroup;
    selectedDeviceType;
    utilizationCalculationFactor = 1;
    deviceTypeOptions = [];
    deviceTypes = [];

    yearlyDeprecation = "15"
    fuelCostPetrol = "1.99";
    fuelCostDiesel = "1.80";
    fuelCostElectricity = "0.35";
    costCall: any;

    resellerChanged(resellerId) {
        this.selectedAccountId = null;

        this.selectedResellerId = resellerId;
    }

    accountChanged(accountId) {
        this.selectedAccountId = accountId;
        this.selectedAssetGroup = null;

        this.error = null;
        this.warning = null;

        this.dateChanged(true);
    }

    constructor(private accountService: AccountService, private triggerScheduleService: TriggerScheduleService, private deviceTypeService: DeviceTypeService, private chartService: FhChartService, private translateService: TranslateService, private authenticationService: AuthenticationService, private route: ActivatedRoute, private router: Router, protected storageHelper: StorageHelper, private colorService: ColorService) {
        super(storageHelper);

        this.sensors = [];

        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(1, 'month').startOf('month').toDate(),
            Moment().tz(this.timezoneIana).subtract(1, 'month').endOf('month').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);
    }

    ngOnInit() {
        // Get all the date for dropdown boxes
        forkJoin(
            this.translateService.get('general.date'),
            this.deviceTypeService.getDeviceTypes()
        ).subscribe(
            data => {

                this.languageLoaded = true;
                this.loading = false;

                this.deviceTypes = data[1].filter(x => x.deviceCount > 0);
                this.deviceTypes = this.deviceTypes.sort((a, b) => (a.modelName > b.modelName ? 1 : -1));

                this.initGrid();
            },
            err => {
                this.error = err;
                this.languageLoaded = true;
                this.loading = false;
            });
    }


    actualRound(value, decimals) {
        return roundAsNumber(value, decimals);
    }

    actualRoundMinutes(value) {
        return roundMinutes(value);
    }

    actualRoundSeconds(value) {
        return roundSeconds(value);
    }

    cancel() {
        this.costCall.unsubscribe();

        this.loading = false;

        this.warning = {};
        this.warning.warning = 'Call was cancelled.'
        this.warning.statusText = 'Warning';
    }

    async processData(data) {

        // Chart cost per asset

        const categoriesDistance = [];
        const theDataDistance = [];
        const theContentDistance = [];

        data.sort((a, b) => (a.total < b.total ? 1 : -1));
        data.forEach(device => {
            categoriesDistance.push(device.assetName);
        });

        data.sort((a, b) => (a.total < b.total ? 1 : -1));
        data.forEach(device => {
            categoriesDistance.push(device.assetName);
            theContentDistance.push({ y: roundAsNumber(device.total, 0), deviceId: device.deviceId });
        });


        theDataDistance.push({
            data: theContentDistance,
            type: 'column',
            name: 'Cost',
            turboThreshold: 5000,
        })

        this.chartDistanceCompare = this.chartService.generateUtilizationScoreChart(theDataDistance, categoriesDistance, null, '');

        // Chart cost per type

        const dataViolationsPerType = [{
            name: this.translateService.instant('general.costPerType'),
            colorByPoint: true,
            minPointSize: 20,
            innerSize: '20%',
            zMin: 0,
            data: [{
                name: 'Depreciation',
                y: roundAsNumber(data.reduce((partial_sum, d) => partial_sum + d.depreciation, 0), 0),
            }, {
                name: 'Insurance',
                y: roundAsNumber(data.reduce((partial_sum, d) => partial_sum + d.insurance, 0), 0),
            }, {
                name: 'RoadTaxes',
                y: roundAsNumber(data.reduce((partial_sum, d) => partial_sum + d.roadTaxes, 0), 0),
            }, {
                name: 'Maintenance',
                y: roundAsNumber(data.reduce((partial_sum, d) => partial_sum + d.maintenance, 0), 0),
            }, {
                name: 'FuelCosts',
                y: roundAsNumber(data.reduce((partial_sum, d) => partial_sum + d.fuelCosts, 0), 0),
            }],
        }];

        this.chartCostPerType = this.chartService.generateVarPieChartDevice(dataViolationsPerType, {}, null, false);

        return data;
    }

    dateChanged(event) {
        const that = this;
        console.log('Changed date');
        if (event !== null && this.datatableElement != null && this.datatableElement.dtInstance != null) {
            this.loadingLocations = true;

            this.error = null;
            this.warning = null;

            this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.ajax.reload();
            });
        }
    }

    initGrid(): void {

        console.log('Init grid');

        const that = this;

        const commonExportOptions = {
            modifier: {
                page: 'all',
                search: 'none'
            },
            columns: ['id_export:name', ':visible[tabindex]']
        };

        this.columns = [
            {
                name: 'deviceId',
                data: 'deviceId',
                visible: false,
                defaultContent: '-',
                title: this.translateService.instant('general.deviceId')
            }, {
                name: 'assetId',
                data: 'assetId',
                visible: false,
                defaultContent: '-',
                title: this.translateService.instant('general.assetId')
            }, {
                name: 'accountId',
                data: 'accountId',
                visible: false,
                defaultContent: '-',
                title: this.translateService.instant('general.accountId')
            },
            {
                name: 'assetName',
                data: 'assetName',
                defaultContent: '-',
                title: this.translateService.instant('general.assetName'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        const icon = row.iconId ? '<img style="height: 32px; width: 32px; margin-top: -20px; margin-bottom: -10px;" src="' + (row.iconId > 0 ? getIconPath(row.iconId)[1] : '/assets/images/icons/vista/Trucks/32x32/TankerTruck_Black.png') + '">&nbsp;&nbsp;' : '';
                        return '<a class=\'secondary link_bolderd\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/DeviceDetails/Index/' + row.deviceId + '\'>' + icon + data + '</a>';
                    } else {
                        return data
                    };
                }
            }, {
                name: 'queryDateStart',
                data: 'queryDateStart',
                title: this.translateService.instant('general.queryDateStart'),
                render: function (data, type, row) {
                    const date = Moment.utc(data)['tz'](that.timezoneIana);
                    return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
                },
            }, {
                name: 'queryDateEnd',
                data: 'queryDateEnd',
                title: this.translateService.instant('general.queryDateEnd'),
                render: function (data, type, row) {
                    const date = Moment.utc(data)['tz'](that.timezoneIana);
                    return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
                },
            }, {
                name: 'segmentsDistance',
                data: 'segmentsDistance',
                visible: false,
                defaultContent: '-',
                title: this.translateService.instant('general.segmentsDistance'),
                render: function (data, type, row) {
                    return data ? roundAsNumber(data, 0).toLocaleString() : '-';
                },
            }, {
                name: 'purchasePrice',
                data: 'purchasePrice',
                visible: true,
                defaultContent: '-',
                title: this.translateService.instant('general.purchasePrice'),
                render: function (data, type, row) {
                    return data ? roundAsNumber(data, 2).toLocaleString() : '-';
                },
            }, {
                name: 'age',
                data: 'age',
                visible: true,
                defaultContent: '-',
                title: this.translateService.instant('general.age'),
                render: function (data, type, row) {
                    return data ? roundAsNumber(data, 0).toLocaleString() : '-';
                },
            }, {
                name: 'fuelType',
                data: 'fuelType',
                visible: true,
                defaultContent: '-',
                title: this.translateService.instant('general.fuelType'),
                render: function (data, type, row) {
                    return data ? data : '-';
                },
            }, {
                name: 'restValue',
                data: 'restValue',
                visible: true,
                defaultContent: '-',
                title: this.translateService.instant('general.restValue'),
                render: function (data, type, row) {
                    return data ? roundAsNumber(data, 0).toLocaleString() : '-';
                },
            },
            {
                name: 'depreciation',
                data: 'depreciation',
                visible: true,
                defaultContent: '-',
                title: this.translateService.instant('general.depreciation'),
                render: function (data, type, row) {
                    return data ? roundAsNumber(data, 2).toLocaleString() : '-';
                },
            },
            {
                name: 'insurance',
                data: 'insurance',
                visible: true,
                defaultContent: '-',
                title: this.translateService.instant('general.insurance'),
                render: function (data, type, row) {
                    return data ? roundAsNumber(data, 2).toLocaleString() : '-';
                },
            }, {
                name: 'roadTaxes',
                data: 'roadTaxes',
                visible: true,
                defaultContent: '-',
                title: this.translateService.instant('general.roadTaxes'),
                render: function (data, type, row) {
                    return data ? roundAsNumber(data, 2).toLocaleString() : '-';
                },
            }, {
                name: 'maintenance',
                data: 'maintenance',
                visible: true,
                defaultContent: '-',
                title: this.translateService.instant('general.maintenance'),
                render: function (data, type, row) {
                    return data ? roundAsNumber(data, 2).toLocaleString() : '-';
                },
            }, {
                name: 'totalConsumptionMixed',
                data: 'totalConsumptionMixed',
                visible: false,
                defaultContent: '-',
                title: this.translateService.instant('general.totalConsumptionMixed'),
                render: function (data, type, row) {
                    return data ? roundAsNumber(data, 2).toLocaleString() : '-';
                },
            }, {
                name: 'fuelCosts',
                data: 'fuelCosts',
                visible: true,
                defaultContent: '-',
                title: this.translateService.instant('general.fuelCosts'),
                render: function (data, type, row) {
                    return data ? roundAsNumber(data, 2).toLocaleString() : '-';
                },
            }, {
                name: 'total',
                data: 'total',
                visible: true,
                defaultContent: '-',
                title: this.translateService.instant('general.total'),
                render: function (data, type, row) {
                    return data ? roundAsNumber(data, 2).toLocaleString() : '-';
                },
            }];

        this.dtOptions = {
            buttons: getGridButtons(this.commonExportOptions, 'utilization_overview', this.translateService.instant('menu.messagesoverview'), this.colorService),
            pagingType: 'simple_numbers',
            serverSide: false,
            processing: false,
            scrollX: true,
            colReorder: { fixedColumnsLeft: 1 },
            order: [[0, 'desc']],
            stateSave: false,
            ajax: (dataTablesParameters: any, callback) => {
                $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));

                this.loading = true;
                this.costCall = this.accountService.getAccountCosts(this.selectedAccountId, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.yearlyDeprecation, this.fuelCostPetrol, this.fuelCostDiesel, this.fuelCostElectricity).subscribe(async resp => {
                    this.devices = await this.processData(resp);
                    this.loading = false;
                    callback({
                        recordsTotal: resp.length,
                        recordsFiltered: resp.length,
                        data: resp
                    });
                }, error => {
                    this.success = null;
                    this.error = error;
                    this.error.statusText = 'Error fetching data';
                    this.loading = false;
                });
            },
            initComplete: function (settings, json) {
                that.loading = false;
                that.loadingLocations = false;

                console.log('init complete');
                that.checkFilters();
                that.drawFilterRow();
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
            },
            columns: this.columns,
            pageLength: 25,
            lengthMenu: [[10, 17, 25, 50, 200, -1], [10, 17, 25, 50, 200, this.translateService.instant('general.all')]],
            language: getGridLanguages(this.translateService),
            rowCallback: (row, data) => { }
        };
    }
}


