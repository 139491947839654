import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { FhChartService } from '../../services/charts/charts.service';
import { DeviceService } from '../../services/device/device.service';

import { getDefaultDpConfig } from 'app/common/gridhelper';
import { TripService } from 'app/services/trip/trip.service';
import { AccountService } from 'app/services/account/account.service';
import { roundAsNumber } from 'app/common/globals';

import * as L from 'leaflet';
import { slideInOut } from 'app/common/animations';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { WarehouseService } from 'app/services/warehouse/warehouse.service';
import { Warehouse } from 'app/models/warehouse.model';
window['moment'] = Moment;
mTZ();

import * as Highcharts from 'highcharts';

@Component({
    selector: 'fh-warehouse-charts',
    templateUrl: 'charts.template.html',
    providers: [FhChartService],
    animations: [
        slideInOut
    ]
})

export class WarehouseChartsViewComponent implements OnInit, OnDestroy {
    Highcharts: typeof Highcharts = Highcharts;

    timeoutHandler;

    sensors;
    sub;
    loading = false;
    loadingSensors = false;

    timezoneIana: string;
    daterangepickerModel: Date[];
    dpConfig: {};

    error;
    success;

    charts = [];

    filterZeroValues = true;


    permissionName = 'Warehouses_View';

    warehouse: Warehouse;
    warehouseId: any;

    constructor(private chartService: FhChartService, private tripService: TripService, private warehouseService: WarehouseService, private accountService: AccountService, private translateService: TranslateService, private authenticationService: AuthenticationService, private http: HttpClient, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router) {
        this.warehouse = null;
        this.sensors = [];

        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(2, 'days').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);
    }

    ngOnInit() {
        this.warehouse = new Warehouse;
        this.loading = true;
        this.warehouse.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.warehouseId = id;
            this.warehouseService.getWarehouseById(id).subscribe(warehouse => {
                this.warehouse = warehouse;

                this.dateChanged(null);

                this.loading = false;
            }, error => {
                this.error = error;
                this.error.statusText = 'Error fetching warehouse';

                setTimeout(() => {
                    this.router.navigate(['/Warehouses/Overview']);
                }, 3000);
            });
        });
    }

    dateChanged(event) {
        this.generateData(this.warehouse.id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'));
    }

    generateData(id, start, end) {
        this.loadingSensors = true;
        this.error = undefined;
        this.warehouseService.getWarehouseZoneDataByReference(this.warehouse.referenceId, start, end).subscribe(sensors => {

            this.sensors = sensors;
            this.drawChart(sensors);

            this.loadingSensors = false;

        }, error => {
            this.loadingSensors = false;
            this.error = error;
        });
    }

    drawChart(sensors) {

        const that = this;

        sensors.forEach(sensor => {
            const theData = [];

            const dataTemperature = [];
            const dataHumidity = [];

            const sortedData = sensor.sensorData.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))

            sortedData.forEach(sensorData => {
                dataTemperature.push([Moment.utc(sensorData.timestamp)['tz'](that.timezoneIana).unix() * 1000, roundAsNumber(sensorData.temperature, 1)]);
                dataHumidity.push([Moment.utc(sensorData.timestamp)['tz'](that.timezoneIana).unix() * 1000, roundAsNumber(sensorData.humidity, 0)]);
            });

            theData.push({
                name: sensor.zoneName + ' °C',
                type: 'spline',
                visible: true,
                yAxis: 0,
                data: dataTemperature
            });

            theData.push({
                name: sensor.zoneName + '%',
                type: 'spline',
                visible: true,
                yAxis: 1,
                data: dataHumidity
            });

            this.charts[sensor.sensorId] = this.chartService.generateSensorChart(theData);
        });
    }

    ngOnDestroy(): void {
        clearTimeout(this.timeoutHandler);
    }
}
