export class Trip {
    beginLongitude: any;
    endLatitude: any;
    endLongitude: any;
    beginLatitude: any;
    locations: any;
    segmentDistanceInKilometers: any;
    boundingDistanceInMetres: any;
    endCity: any;
    endAddress: any;
    endAddressFull: any;
    endLabel: any;
    endDateTime: any;
    beginCity: any;
    beginAddress: any;
    beginAddressFull: any;
    beginLabel: any;
    beginDateTime: any;
    driverName: any;
    driverId: any;
    deviceId: any;
    assetId: any;
    id: any;
    duration;
    durationHumanized: string;
    maxSpeed: any;
    isPrivate: any;
    comment: any;
    isHidden: any;
    kmPrivate: any;
    tripMethod: any;
    ident: number;
    beginLocation: any;
    endLocation: any;
    tripType: any;
    source: any;
    calibratedOdoValueEnd: any;
    isStop: boolean;
    hasEpisodes: boolean;
    totalConsumptionMixed: any;
    totalEmissionCO2: any;
    totalEmissionParticlesHeavy: any;
    totalEmissionParticlesLight: any;
}
