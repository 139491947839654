<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="device?.accountId">
                    <a href="/#/AccountDetails/Index/{{device?.accountId}}">{{device?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}">{{'general.devices' | translate
                        }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}">{{ selectedAsset?.name }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.engineHour' | translate }}</li>
            </ol>
        </nav>

        <span class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix d-none d-md-inline-flex" title="Help"
                href="https://help.360locate.com/knowledge-base/asset-enginehour/" target="_blank"><i
                    class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span
                    class="d-none d-md-inline-flex">{{'general.needHelp' | translate }}</span></a>
        </span>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device"></fh-device-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [warning]="warning" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-5">

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-car-bus"></i> {{ 'general.asset' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position:relative">
                        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td>
                                        <ng-select [loading]="loadingAssets" name="selectedAssetId" ngDefaultControl
                                            [searchable]="true" [clearable]="false"
                                            placeholder="{{ 'general.chooseAsset' | translate}}"
                                            [(ngModel)]="selectedAssetId" #selectedAssetIdVar="ngModel"
                                            [disabled]="assets.length === 1" class="form-control table-inline"
                                            style="width:100%" required (ngModelChange)="assetChanged($event)">
                                            <ng-option *ngFor="let asset of assets | orderBy : 'name':false"
                                                [value]="asset.id">
                                                {{ asset.name }} {{ (asset.assignmentEnd == null ? '*' : '')}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                </tr>
                        </table>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-tachometer-alt"></i> {{ 'general.currentEngineHour' | translate}}
                        </h5>

                        <span *ngIf="isCalibrationOutdated" [tooltip]="'general.calibrationOutdated' | translate"
                            class="float-right">
                            <h5><i style="color: #CB711D;" class="fas fa-fw fa-exclamation-triangle"></i></h5>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position:relative" *ngIf="device.asset">
                        <ngx-loading [show]="loadingCacher || loadingHistory || loading || saving"></ngx-loading>
                        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.calibrated' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ round(assetCalibratedEngineHour)?.toLocaleString() }} h
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.lastCalibrationDate' | translate}}
                                    </td>
                                    <td class="row_content" title=" {{ lastCalibration?.toLocaleString() }}">
                                        {{ lastCalibration | amDateFormat: 'll'}}
                                    </td>
                                </tr>
                                <tr *ngIf="permissions['Platform_IsReseller']">
                                    <td class="row_opening">
                                        {{ 'general.tripEngineHour' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ round(tripEngineHour)?.toLocaleString() }} h
                                    </td>
                                </tr>
                                <tr *ngIf="permissions['Platform_IsReseller']">
                                    <td class="row_opening">
                                        {{ 'general.offset' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ round(engineHourOffset)?.toLocaleString() }} h
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer">
                        <button class="btn btn-info" (click)="refresh()"><i class="fas fa-fw fa-sync"></i>
                            {{'general.refresh' | translate }}</button>
                    </div>
                </div>

                <div class="ibox" *ngIf="permissions['FleetManagement_EngineHourEdit']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-tachometer-alt"></i> {{ 'general.addCalibration' | translate}}
                        </h5>
                    </div>
                    <form #engineHourForm="ngForm">
                        <div class="ibox-content"
                            style="position:relative; border-bottom: 1px solid rgba(255, 255, 255, 0.05)"
                            *ngIf="device.asset">
                            {{ 'general.suggestedEngineHour' | translate}}
                        </div>
                        <div class="ibox-content slick" style="position:relative;" *ngIf="device.asset">
                            <ngx-loading [show]="loadingHistory || loading || saving"></ngx-loading>
                            <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                                <tbody>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.date' | translate}}
                                            <span class="error"
                                                *ngIf="calibrationDateVar.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content" style="width:auto">
                                            <input type="text" [disabled]="formMode == 1" name="calibrationDate"
                                                #calibrationDateVar="ngModel" class="form-control table-inline"
                                                [(ngModel)]="calibrationDate" autocomplete="off" [bsConfig]="dpConfig"
                                                bsDatepicker>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.engineHour' | translate}}
                                            <span class="error"
                                                *ngIf="calibrationEngineHourVar.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input type="number" [readonly]="formMode == 1" name="calibratedEngineHour"
                                                class="form-control table-inline" [(ngModel)]="calibratedEngineHour"
                                                required minlength="3" #calibrationEngineHourVar="ngModel">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                    <fh-save-footer
                        [valid]="!engineHourForm.invalid  && selectedAssetId != null && tripEngineHour != null && tripEngineHour != 0"
                        (onSave)="onSave()" (onInsert)="onSave()"
                        [allowAdd]="permissions['FleetManagement_EngineHourEdit']" (setFormMode)="setFormMode($event)"
                        [formMode]="formMode">
                    </fh-save-footer>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="ibox" style="overflow: hidden;">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-list-ul"></i> {{'general.calibrations' | translate }}
                        </h5>

                        <span class="float-right">
                            <div class="filter-form d-none d-md-block">
                                <input type="text" class="form-control table-inline top-filter d-none d-md-block"
                                    (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: hidden">
                        <ngx-loading [show]="loadingHistory || loading || saving"></ngx-loading>
                        <table class="table table-fixed nowrap dtr-inline no-footer"
                            style="width:100%; margin-bottom:0px ">
                            <thead>
                                <tr>
                                    <th style="width:135px" class="elipses_overflow">
                                        {{'general.dateCalibration' | translate }}
                                    </th>
                                    <th *ngIf="permissions['Platform_IsReseller']" class="elipses_overflow">
                                        {{'general.tripEngineHour' | translate }}
                                    </th>
                                    <th class="elipses_overflow">
                                        {{ 'general.realEngineHour' | translate }}
                                    </th>
                                    <th style="width:45px"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let calibration of calibrations | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage, id: 'second' } ; let idx=index ">
                                    <td class="elipses_overflow"
                                        title="{{ calibration.dateCalibration?.toLocaleString() }}">
                                        {{ calibration.dateCalibration | amDateFormat: 'DD-MM-YY HH:mm:ss' }}</td>
                                    <td *ngIf="permissions['Platform_IsReseller']" class="elipses_overflow">
                                        {{ round(calibration.tripEngineHourInSeconds / 3600)?.toLocaleString() }}
                                    </td>
                                    <td class="elipses_overflow">
                                        {{ round(calibration.realEngineHourInSeconds / 3600)?.toLocaleString() }}
                                    </td>
                                    <td><a *ngIf="idx == 0" class="btn btn-danger btn-grid"
                                            (click)="showDelete(calibration.id)">
                                            <i class="fa fa-fw fa-trash-alt"></i>
                                        </a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event" id="second">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                            'grid.previous' | translate }}</a>
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value">
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-tachometer-alt"></i> {{'general.engineHourHistory' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content" style="min-height: 400px; position: relative">
                        <ngx-loading [show]="loadingHistory"></ngx-loading>

                        <highcharts-chart *ngIf="chartEngineHour != undefined" [runOutsideAngular]="false"
                            style="display: block; width: 100%; height:400px" [Highcharts]="Highcharts"
                            [options]="chartEngineHour"></highcharts-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-confirmation-modal #deleteModal (onFinish)="deleteCalibration($event)"></fh-confirmation-modal>