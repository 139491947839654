import { Component, Input, OnChanges, Inject } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ConfirmationModalComponent } from './confirmationModal.component';

@Component({
    selector: 'fh-archiving-modal',
    templateUrl: 'archivingModal.template.html',
})
export class ArchivingModalComponent implements OnChanges {

    @Input() device;
    @Input() driver;
    @Input() project;
    @Input() trailer;

    public archiveDate;

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    constructor(@Inject(ConfirmationModalComponent) private parent: ConfirmationModalComponent) {
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.isAnimated = true;
                this.dpConfig.withTimepicker = true;
        this.dpConfig.keepDatepickerOpened = true;

        this.archiveDate = new Date();

        if (this.archiveDate === null || isNaN(this.archiveDate)) {
            this.parent.invalid = true;
        }
    }

    ngOnChanges(): void {
    }

    dateChanged(event) {
        if (event === null || isNaN(event)) {
            this.parent.invalid = true;
            return
        }

        this.archiveDate = event;
        this.parent.invalid = false;
    }
}
