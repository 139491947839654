<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="device?.asset && device?.asset.accountId">
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}">{{'general.devices' | translate
                        }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.asset?.name ? device?.asset?.name :
                        device?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.assets' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId"></fh-device-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="">

            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success" [warning]="warning"></fh-error>
            </div>

            <div class="col-lg-6">
                <form #assetForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-car-bus"></i> {{'general.asset' | translate }}
                            </h5>
                        </div>
                        <fh-asset-details-extended [asset]="selectedAsset" [showProp]="showProp"
                            [formMode]="formModeAsset" [loading]="loading">
                        </fh-asset-details-extended>
                        <fh-save-footer [valid]="!assetForm.invalid" (onInsert)="onInsertAsset()"
                            (onSave)="onSaveAsset()" (onDelete)="onArchiveAsset($event)"
                            [allowAdd]="((!device?.asset?.id || device?.asset?.assignmentEnd) && device?.accountId) && permissions['Assets_Add']"
                            [allowEdit]="(device && selectedAsset?.id) && permissions['Assets_Edit']"
                            [allowDelete]=false [allowCancelOnInsert]=true (setFormMode)="setFormModeAsset($event)"
                            [formMode]="formModeAsset">
                            <span class="float-right">

                                <span *ngIf="device && selectedAsset?.id">
                                    <ng-container *ngIf="!selectedAsset?.assignmentEnd; else reactivate">
                                        <button class="btn btn-danger" (click)="showArchive(selectedAsset?.id);"
                                            *ngIf="permissions['Actions_Archive']"
                                            title="{{ 'general.archive' | translate }}"><i
                                                class="fa fa-fw fa-file-archive"></i>
                                            {{'general.archive' | translate }}</button>
                                    </ng-container>
                                    <ng-template #reactivate>
                                        <button class="btn btn-primary" (click)="reactivateAsset(selectedAsset?.id)"
                                            *ngIf="permissions['Actions_Archive']"
                                            title="{{ 'general.reactivate' | translate }}"><i
                                                class="fa fa-fw fa-recycle"></i>
                                            {{'general.reactivate' | translate }}</button>

                                        <button class="btn btn-danger" (click)="showDelete(selectedAsset?.id)"
                                            *ngIf="permissions['Assets_Delete']"
                                            title="{{ 'general.delete' | translate }}"><i
                                                class="fa fa-fw fa-trash-alt"></i>
                                            {{'general.delete' | translate }}</button>
                                    </ng-template>

                                    <button class="btn btn-info" *ngIf="showProp" (click)="showProperties()"
                                        title="{{'general.hideProperties' | translate }}">
                                        <i class="fa fa-fw fa-minus"></i>
                                        <span class="d-none d-sm-inline-flex" style="padding-left: 8px">
                                            {{'general.hideProperties' | translate }}</span>
                                    </button>
                                    <button class="btn btn-info" *ngIf="!showProp" (click)="showProperties()"
                                        title="{{'general.showProperties' | translate }}">
                                        <i class="fa fa-fw fa-plus"></i>
                                        <span class="d-none d-sm-inline-flex" style="padding-left: 2px">
                                            {{'general.showProperties' | translate }}</span>
                                    </button>
                                </span>
                            </span>
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">
                <div class="ibox" style="position:relative">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-list-ul"></i> {{'general.historicalAssets' | translate }}
                        </h5>
                        <span class="float-right">
                            <div class="filter-form d-none d-md-block">
                                <input type="text" class="form-control table-inline top-filter d-none d-md-block"
                                    (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
                        <ngx-loading [show]="loading || loadingAssets"></ngx-loading>
                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th style="width:20px"></th>
                                    <th>{{'general.asset' | translate }}</th>
                                    <th style="text-align: right;">{{'general.start' | translate }}</th>
                                    <th style="text-align: right;">{{'general.end' | translate }}</th>
                                    <th style="width:20px"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="assets.length == 0">
                                    <td colspan="4">{{'general.noArchivedAssetsFound' | translate }}</td>
                                </tr>
                                <tr class="hand"
                                    *ngFor="let asset of assets | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage }"
                                    (click)="selectedAsset = asset"
                                    [ngClass]="asset.id == selectedAsset.id ? 'rowSelected' : ''">
                                    <td>
                                        <i class='far fa-fw fa-lg'
                                            [ngClass]="asset.id == selectedAsset.id ? 'fa-check-square' : 'fa-square'"></i>
                                    </td>
                                    <td>
                                        {{asset.name}}
                                    </td>
                                    <td style="text-align: right;" title="{{ asset.assignmentStart }}">
                                        {{ asset.assignmentStart | amDateFormat: 'lll' }}
                                    </td>
                                    <td style="text-align: right;" title="{{ asset.assignmentEnd }}">
                                        {{ asset.assignmentEnd | amDateFormat: 'lll' }}
                                    </td>
                                    <td style="text-align: right;" *ngIf="permissions['Assets_Edit']">
                                        <span *ngIf="asset.id !== device?.asset?.id || device?.asset?.assignmentEnd">
                                            <ng-container
                                                *ngIf="device?.asset && !device.asset.assignmentEnd; else reactivate">
                                                <span [tooltip]="'Deactivate current asset first'">
                                                    <button class="btn btn-primary btn-grid" disabled>
                                                        <i class="fas fa-fw fa-recycle"></i>
                                                    </button>
                                                </span>
                                            </ng-container>
                                            <ng-template #reactivate>
                                                <a class='btn btn-primary btn-grid' *ngIf="permissions['Assets_Edit']"
                                                    title="{{'general.reactivate' | translate }}"
                                                    (click)="reactivateAsset(asset.id)">
                                                    <i class="fas fa-fw fa-recycle"></i>
                                                </a>
                                            </ng-template>
                                        </span>
                                        <span [tooltip]="'Change assignment dates'" *ngIf="permissions['Assets_Edit']">
                                            <button class="btn btn-primary btn-grid"
                                                (click)="showAssignmentAsset(asset.id)">
                                                <i class="fas fa-fw fa-calendar-alt"></i>
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                            'grid.previous' | translate }}</a>
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value">
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>

                <fh-custom-fields *ngIf="selectedAsset?.properties?.custom" [formMode]="formModeAsset"
                    [(customFields)]="selectedAsset.properties.custom">
                </fh-custom-fields>

                <fh-dashcams-admin *ngIf="selectedAsset?.properties?.dashcams" [formMode]="formModeAsset"
                    [(dashcams)]="selectedAsset.properties.dashcams">
                </fh-dashcams-admin>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-confirmation-modal #archiveModal (onFinish)="onArchiveAsset($event)">
    <fh-archiving-modal #archiveBody [device]="device" body></fh-archiving-modal>
</fh-confirmation-modal>

<fh-confirmation-modal #deleteModal (onFinish)="deleteAssetAssignment($event)">
</fh-confirmation-modal>

<fh-confirmation-modal #assignmentModal (onFinish)="onChangeAssetAssignment($event)">
    <fh-assignment-modal #assignmentBody [device]="device" [asset]="selectedAsset" body></fh-assignment-modal>
</fh-confirmation-modal>