import { Component, Input, OnInit, OnChanges, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { DeviceFuelThreshold } from 'app/models/device.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CalibrationComponent } from './calibrationDetails.component';

@Component({
    selector: 'fh-fuel-edit-thresholds',
    templateUrl: 'fuelThreshold.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FuelThresholdEditComponent implements OnInit, OnChanges {

    @Input() fuelThresholds: DeviceFuelThreshold;
    @Input() formMode: any = FormMode.read;

    @Input() hasVepamonEnabled: boolean = false;

    @Input() sensorCalibrationPoints = [];

    sensorError;
    sensorSuccess;

    tankNumber;

    device;

    @ViewChild('addModal') addModal: ModalDirective;
    @ViewChild('calibrationComponent') calibrationComponent: CalibrationComponent;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(): void {
    }

    // Adding
    showModal(tankNumber) {
        this.tankNumber = tankNumber;

        this.addModal.show();
    }

    hideModal() {
        this.calibrationComponent.form.reset();
        this.addModal.hide();
    }
}

