import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs/internal/Subject';
import { AssetService } from '../asset/asset.service';
import { DeviceService } from '../device/device.service';
import { of } from 'rxjs/internal/observable/of';
import { timer } from 'rxjs/internal/observable/timer';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { LocationService } from '../locations/locations.service';
import { AssetGroupsService } from '../asset/assetGroups.service';
import { DeviceStatesItem } from 'app/models/StateObject';
import { tap } from 'rxjs/internal/operators/tap';
import { type Subscription } from 'rxjs';
import { GeofenceService } from '../geofence/geofence.service';
import { GeofenceGroupsService } from '../geofence/geofenceGroups.service';
import { GeofenceGroupModel } from './models/geofenceGroup';
import { ListItemModel } from './models/listItemModel';
import { AuthenticationService } from '../authentication/authentication.service';

// Recursively reduce sub-arrays to the specified depth
function flatten<T>(arr: T[], depth = 1): T[] {
    // If depth is 0, return the array as-is
    if (depth < 1) {
        return arr.slice();
    }

    // Otherwise, concatenate into the parent array
    return arr.reduce(function (_acc, _val) {
        return _acc.concat(Array.isArray(_val) ? flatten(_val, depth - 1) : _val);
    }, []);
};

class Asset {
}

const FleetOverviewState = [
    'Initialize',
    'LoadingMap',
    'LoadedMap',
    'FetchingState',
    'Loaded',
] as const;

const FleetOverviewMode = [
    'Overview',
    'Live',
    'History',
] as const;

export type FleetOverviewStateCase = (typeof FleetOverviewState)[number];
export type FleetOverviewModeCase = (typeof FleetOverviewMode)[number];

@Injectable({ providedIn: 'root' })
export class FleetOverviewStoreService implements OnDestroy {
    private locationSubscription: Subscription;
    private previousLookupTimestamp: Date;

    private assetGroups = new Map();
    private geofences = new Map();
    private geofenceGroups = new Map();

    private insideGeofences = new Map<string, string[]>();
    private insideGeofencesByDevice = new Map<string, string[]>();

    private readonly _assets = new BehaviorSubject<Asset[]>([]);
    private readonly _deviceState = new BehaviorSubject<Map<number, number>>(new Map());
    private readonly _lastCommunication = new BehaviorSubject<Map<number, string>>(new Map());
    private readonly _lastStateUpdated = new BehaviorSubject<Map<number, string>>(new Map());
    private readonly _lastDeviceStates = new BehaviorSubject<DeviceStatesItem[]>([]);
    private readonly _allDeviceStates = new BehaviorSubject<DeviceStatesItem[]>([]);

    private readonly _fleetOverviewState = new BehaviorSubject<FleetOverviewStateCase>('Initialize');
    private readonly _fleetOverviewMode = new BehaviorSubject<FleetOverviewModeCase>('Overview');

    readonly assets$ = this._assets.asObservable();
    readonly deviceState$ = this._deviceState.asObservable();
    readonly lastCommunication$ = this._lastCommunication.asObservable();
    readonly lastStateUpdated$ = this._lastStateUpdated.asObservable();
    readonly lastDeviceStates$ = this._lastDeviceStates.asObservable();
    readonly allDeviceStates$ = this._allDeviceStates.asObservable();
    readonly fleetOverviewState$ = this._fleetOverviewState.asObservable();
    readonly fleetOverviewMode$ = this._fleetOverviewMode.asObservable();

    readonly geofencesMap = this.geofences;
    readonly geofencesGroupsMap = this.geofenceGroups;
    readonly insideGeofencesMap = this.insideGeofences;
    readonly insideGeofencesByDeviceMap = this.insideGeofencesByDevice;

    selectedTrip = new Subject<L.FeatureGroup<any>[]>();
    readonly selectedTrip$ = this.selectedTrip.asObservable();

    isTripPlaying = new Subject<boolean>();
    readonly isTripPlaying$ = this.isTripPlaying.asObservable();

    playTrip = new Subject<[string, any[]]>();
    readonly playTrip$ = this.playTrip.asObservable();

    removeTrip = new Subject<L.FeatureGroup<any>[]>();
    readonly removeTrip$ = this.removeTrip.asObservable();

    clearTrips = new Subject<void>();
    readonly clearTrips$ = this.clearTrips.asObservable();

    selectGeofence = new Subject<void>();
    readonly selectGeofence$ = this.selectGeofence.asObservable();

    fetchingStates = new Subject<boolean>();
    readonly fetchingStates$ = this.fetchingStates.asObservable();

    hiddenAssets = new BehaviorSubject<Map<number, boolean>>(new Map());
    readonly hiddenAssets$ = this.hiddenAssets.asObservable();

    hiddenGeofences = new BehaviorSubject<Map<number, boolean>>(new Map());
    readonly hiddenGeofences$ = this.hiddenGeofences.asObservable();

    stateFilter = new BehaviorSubject<Map<number, boolean>>(new Map());
    readonly stateFilter$ = this.stateFilter.asObservable();

    searchFilter = new BehaviorSubject<string>('');
    readonly searchFilter$ = this.searchFilter.asObservable();

    accountId;

    constructor(private authenticationService: AuthenticationService, private deviceService: DeviceService, private assetGroupService: AssetGroupsService, private geofenceService: GeofenceService, private geofenceGroupsService: GeofenceGroupsService, private locationService: LocationService) {
        this.accountId = authenticationService.getAccountId();
    }

    public async startup() {
        console.log('FO: Startup locationdata.');

        // Send all devicestates back
        this.lastDeviceStates = this.allDeviceStates;

        this.getDevices().finally(() => {
            this.getGeofences(this.accountId);

            // Reset previous timestamp
            this.previousLookupTimestamp = null;
            this.deviceStateSubscription();

            return true;
        });
    }

    public stop() {
        if (this.locationSubscription != null) {
            console.log('FO: Unsubscribe locationdata.');
            this.locationSubscription.unsubscribe();
        }
    }

    private async deviceStateSubscription() {
        const deviceIds = flatten(Array.from(this.assetGroups.values()).map(x => x.items)).map(x => x.id);

        this.stop();

        if (deviceIds.length === 0) {
            this.fleetOverviewState = 'Loaded';
            return;
        }

        this.locationSubscription = timer(0, 30000).pipe(
            tap(() => this.fetchingStates.next(true)),
            mergeMap(_ => this.locationService.getDeviceStates(deviceIds, null, this.previousLookupTimestamp, 0))
        ).subscribe((result) => {
            console.log('FO: Fetched locationdata.');

            this.fetchingStates.next(false)

            const newStates = this.deviceState;
            const newLastCommunication = this.lastCommunication;
            const newLastStateUpdated = this.lastStateUpdated;
            const newInsideGeofences = this.insideGeofences;
            const newInsideGeofencesByDevice = this.insideGeofencesByDevice;

            for (const state of result.deviceStates) {
                let deviceState = state.calculatedDeviceState?.deviceState ?? 0;

                if (!('currentPosition' in state || 'cellPosition' in state)) {
                    deviceState = 0;
                }

                const previousGeofences = newInsideGeofencesByDevice.get(state.id.toString()) ?? [] as string[];

                const updatedInsideGeofences = [] as string[];

                // tslint:disable-next-line:forin
                for (const geofenceId in state.insideGeofences) {
                    updatedInsideGeofences.push(geofenceId);
                }

                newInsideGeofencesByDevice.set(state.id.toString(), updatedInsideGeofences);

                const enteredGeofencesNew = updatedInsideGeofences.filter(x => !previousGeofences.includes(x));

                for (const geofenceId of enteredGeofencesNew) {
                    const enteredList = newInsideGeofences.get(geofenceId) ?? [] as string[];
                    enteredList.push(state.id.toString());

                    newInsideGeofences.set(geofenceId, enteredList);
                }

                const leftGeofencesNew = previousGeofences.filter(x => !updatedInsideGeofences.includes(x));

                for (const geofenceId of leftGeofencesNew) {
                    const leftList = newInsideGeofences.get(geofenceId) ?? [] as string[];
                    newInsideGeofences.set(geofenceId, leftList.filter(x => x !== geofenceId));
                }

                newStates.set(+state.id, deviceState);

                if (state.communicationState?.updateTimestamp !== undefined) {
                    newLastCommunication.set(+state.id, state.communicationState!.updateTimestamp);
                }

                if (state.calculatedDeviceState?.stateChangedTimestamp !== undefined) {
                    newLastStateUpdated.set(+state.id, state.calculatedDeviceState!.stateChangedTimestamp);
                }
            }

            this.deviceState = newStates;
            this.lastCommunication = newLastCommunication;
            this.lastStateUpdated = newLastStateUpdated;
            this.insideGeofences = newInsideGeofences;
            this.insideGeofencesByDevice = newInsideGeofencesByDevice;

            // Update states
            const allStates = this.allDeviceStates;

            result.deviceStates.forEach(state => {
                let theState = allStates.find(x => x.id === state.id);
                if (theState) {
                    // Copy old address
                    state.currentAddress = theState.currentAddress;
                    theState = state;
                } else {
                    allStates.push(state);
                }
            });

            // Set device states
            console.log('FO: Set devicestates: ' + result.deviceStates.length);
            this.lastDeviceStates = result.deviceStates;

            this.previousLookupTimestamp = result.timestamp;

            // Fetch cities for updated locations
            this.locationService.getCities(result.deviceStates).subscribe(locationResult => {
                const updatedDevices = locationResult.filter(x => x.country != null || x.city != null || x.address != null);

                const states2 = this.lastDeviceStates;
                const updateState = [];

                updatedDevices.forEach(address => {
                    const theState = states2.find(x => x.id === address.assetId);
                    if (theState && (theState.currentAddress == null ||
                        (theState.currentAddress.country != address.country
                            && theState.currentAddress.city != address.city
                            && theState.currentAddress.address != address.address))) {

                        theState.currentAddress = address;
                        updateState.push(theState);
                    }
                });

                console.log('FO: Updated addresses: ' + updateState.length);
                this.lastDeviceStates = updateState;
            });
        });
    }

    private async getGeofences(accountId) {
        const getGeofenceGroups = await this.geofenceGroupsService.getGeofenceGroups(accountId, true).toPromise();

        for (const group of getGeofenceGroups) {
            const items = [];
            for (const item of group.geofenceGroupItems) {
                items.push(new ListItemModel(item.id, item.geofenceName));
                this.geofences.set(item.id, item.geofenceName);
            }

            let assetGroupName = group.displayName;

            if (group.accountId !== +this.authenticationService.getAccountId()) {
                assetGroupName = group.displayName + ' - ' + group.companyName;
            }

            const geofenceGroup = new GeofenceGroupModel(group.id, assetGroupName, items);
            this.geofenceGroups.set(group.id, geofenceGroup);
        }
    }

    private async getDevices() {
        if (this.assetGroups.size > 0) {
            console.log('FO: Already loaded assetgroups -> returning');
            return;
        }

        console.log('FO: Fetching assetgroups.');
        const assetGroups = await this.assetGroupService
            .getAssetGroups(null, false)
            .toPromise();

        for (const assetGroup of assetGroups) {
            const name = assetGroup.displayName + ' - ' + assetGroup.companyName;
            this.assetGroups.set(assetGroup.id, { _order: name, name, items: [] });
        }

        const devices = await this.deviceService
            .getDevicesLimited(this.accountId, null, true, false, true)
            .toPromise();

        for (const device of devices) {
            if ((device?.asset?.name?.length ?? 0) === 0) {
                continue;
            }

            for (const id of device.asset.assetGroupIds) {
                const assetGroup = this.assetGroups.get(id)
                if (assetGroup !== undefined) {
                    assetGroup.items.push({ id: device.id, name: device.asset.name });
                }
            }
        }
    }

    get assets(): Asset[] {
        return this._assets.getValue();
    }

    private set assets(val: Asset[]) {
        this._assets.next(val);
    }

    get deviceState(): Map<number, number> {
        return this._deviceState.getValue();
    }

    private set deviceState(val: Map<number, number>) {
        this._deviceState.next(val);
    }

    get lastCommunication(): Map<number, string> {
        return this._lastCommunication.getValue();
    }

    private set lastCommunication(val: Map<number, string>) {
        this._lastCommunication.next(val);
    }

    get lastStateUpdated(): Map<number, string> {
        return this._lastStateUpdated.getValue();
    }

    private set lastStateUpdated(val: Map<number, string>) {
        this._lastStateUpdated.next(val);
    }

    get lastDeviceStates(): DeviceStatesItem[] {
        return this._lastDeviceStates.getValue();
    }

    private set lastDeviceStates(val: DeviceStatesItem[]) {
        this._lastDeviceStates.next(val);
    }

    get allDeviceStates(): DeviceStatesItem[] {
        return this._allDeviceStates.getValue();
    }

    private set allDeviceStates(val: DeviceStatesItem[]) {
        this._allDeviceStates.next(val);
    }

    get fleetOverviewState(): FleetOverviewStateCase {
        return this._fleetOverviewState.getValue();
    }

    set fleetOverviewState(val: FleetOverviewStateCase) {
        this._fleetOverviewState.next(val);
    }

    get fleetOverviewMode(): FleetOverviewModeCase {
        return this._fleetOverviewMode.getValue();
    }

    set fleetOverviewMode(val: FleetOverviewModeCase) {
        this._fleetOverviewMode.next(val);
    }

    ngOnDestroy(): void {
        this.assetGroups = null;
        this.stop();
    }
}
