import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { TriggerRules, FormMode, LocationEventType, InputPorts, EventTypesInput, LocationTypes, Conditions, OutputPorts, InsideOutsideSettings, RuleIOPorts, virtualSensorOutputType as VirtualSensorOutputType, TriggerDeviceSensors } from 'app/common/enums';
import { Trigger, TriggerRule } from 'app/models/trigger.model';
import { AccountService } from 'app/services/account/account.service';
import { TriggerScheduleService } from 'app/services/triggerSchedule/triggerSchedule.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'fh-trigger-rule-details',
    templateUrl: 'triggerRuleDetails.component.html',
})
export class TriggerRuleDetailsComponent implements OnInit, OnChanges {

    @Input() formMode: any = FormMode.read;
    @Input() trigger: Trigger;

    @Output() ruleAdded: EventEmitter<TriggerRule> = new EventEmitter<TriggerRule>();

    rule = new TriggerRule();
    useValue;

    triggerRules: { value: number; name: any; }[] = [];
    eventTypes: { value: number; name: any; }[] = [];
    virtualSensorOutputTypes: { value: number; name: any; }[] = [];
    eventTypesLogical: { value; name: any; }[] = [];
    inputPorts: { value: number; name: any; }[] = [];
    outputPorts: { value: number; name: any; }[] = [];
    ports: { value: number; name: any; }[] = [];
    conditions: { value: string; name: any; }[] = [];
    insideOutsideSettings: { value: number; name: any; }[] = [];
    triggerSchedules: { value: number; name: any; }[] = [];

    accountGeofences: { value: number; name: any; }[] = [];

    loadingGeofences = false;
    loadingTriggerSchedules = false;

    allTriggerRules;

    showValueCompare = false;
    selectedAccountId: any;
    triggerDeviceSensors: { value: number; name: string; }[];

    constructor(private cd: ChangeDetectorRef, private translateService: TranslateService, private accountService: AccountService, private triggerScheduleService: TriggerScheduleService) {
        this.allTriggerRules = TriggerRules;
    }

    ngOnInit(): void {
        // Get all the date for dropdown boxes
        forkJoin(
            this.translateService.get('general.date')
        ).subscribe(
            data => {
                this.triggerRules = Object.keys(TriggerRules)
                    .filter(k => typeof TriggerRules[k] === 'string')
                    .map(n => ({ value: +n, name: this.translateService.instant('enums.triggerRuleType.' + n) }));

                this.triggerDeviceSensors = Object.keys(TriggerDeviceSensors)
                    .filter(k => typeof TriggerDeviceSensors[k] === 'string')
                    .map(n => ({ value: +n, name: this.translateService.instant('enums.triggerDeviceSensor.' + n) }));

                this.eventTypes = Object.keys(LocationEventType)
                    .filter(k => typeof LocationEventType[k] === 'string')
                    .map(n => ({ value: +n, name: LocationEventType[n] }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                this.virtualSensorOutputTypes = Object.keys(VirtualSensorOutputType)
                    .filter(k => typeof VirtualSensorOutputType[k] === 'string')
                    .map(n => ({ value: +n, name: VirtualSensorOutputType[n] }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                this.eventTypesLogical = (Object.values(EventTypesInput) as LocationEventType[][])
                    .filter(k => typeof LocationEventType[k[0]] === 'string')
                    .map(n => ({ value: (BigInt(n[0])).toString(), name: LocationEventType[n[0]] }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                this.ports = Object.keys(RuleIOPorts)
                    .filter(k => typeof RuleIOPorts[k] === 'string')
                    .map(n => ({ value: +n, name: RuleIOPorts[n] }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                this.conditions = Object.keys(Conditions)
                    .filter(k => typeof Conditions[k] === 'string')
                    .map(n => ({ value: Conditions[n], name: n }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                this.insideOutsideSettings = Object.keys(InsideOutsideSettings)
                    .filter(k => typeof InsideOutsideSettings[k] === 'string')
                    .map(n => ({ value: +n, name: ('enums.insideOutsideSettings.' + n) }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                this.triggerSchedules = Object.keys(TriggerRules.Schedule)
                    .filter(k => typeof TriggerRules.Schedule[k] === 'string')
                    .map(n => ({ value: +n, name: TriggerRules.Schedule[n] }))
                    .sort((a, b) => a.name.localCompare(b.name));
            });

        this.updateInputSource();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateInputSource();
    }

    updateInputSource(): void {
        // When messages
        if (this.trigger.triggerInputSource === 0) {
            this.triggerRules = Object.keys(TriggerRules)
                .filter(k => typeof TriggerRules[k] === 'string')
                .map(n => ({ value: +n, name: this.translateService.instant('enums.triggerRuleType.' + n) })).filter(x => x.value !== 13);
        }

        // When avg weight deviation
        if (this.trigger.triggerInputSource === 3) {
            this.triggerRules = Object.keys(TriggerRules)
                .filter(k => typeof TriggerRules[k] === 'string')
                .map(n => ({ value: +n, name: this.translateService.instant('enums.triggerRuleType.' + n) }));
        }

        // When geofence event
        if (this.trigger.triggerInputSource === 4) {
            this.triggerRules = Object.keys(TriggerRules)
                .filter(k => typeof TriggerRules[k] === 'string')
                .map(n => ({ value: +n, name: this.translateService.instant('enums.triggerRuleType.' + n) }));
        }
    }

    addRule() {
        if ((this.rule.ruleType === 3 || this.rule.ruleType === 4) && this.rule.allGeofences) {
            this.rule.geofences = [];
        }

        this.ruleAdded.emit(this.rule);
        this.rule = new TriggerRule();
    }

    cancel() {
        this.ruleAdded.emit();
    }

    changeAccount(accountId): void {
        console.log('accountId', accountId);

        this.selectedAccountId = accountId;

        this.accountGeofences = [];
        this.triggerSchedules = [];
        this.cd.markForCheck();
    }

    // Set default selection options depending on rule type. Make null if rule types are selected for which options are not applicable
    onRuleTypeChanged(event): void {
        if (event === TriggerRules.Geofences || event === TriggerRules.Schedule) {
            this.rule.insideOutsideSettings = InsideOutsideSettings.Inside;
        } else {
            this.rule.insideOutsideSettings = null;
        }

        if (event === TriggerRules.DeviceSensor || event === TriggerRules.RoadSpeed) {
            this.rule.thresholdCompare = 1

            if (event === TriggerRules.RoadSpeed) {
                this.rule.threshold = 0
            }
        } else {
            this.rule.thresholdCompare = null;
        }
    }
}
