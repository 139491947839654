<div class="ibox-content slick" style="position:relative">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!group">
            <tr>
                <td colspan="4">
                    {{ 'general.noGroup' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="group">
            <ng-container *ngIf="formMode != 2">
                <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder"
                            href="/#/ResellerDetails/Index/{{group?.resellerId}}">{{group?.resellerDescription }}</a>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ 'general.account' | translate}}
                    </td>
                    <td class="row_content padding-readonly-correct">
                        <a class="secondary link_bolder"
                            href="/#/AccountDetails/Index/{{group?.accountId}}">{{group?.companyName }}</a>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode == 2">
                <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position:relative; overflow: visible">
                        <fh-reseller-input name="reseller" #resellerVar="ngModel" (onChanged)="resellerChanged($event)"
                            required placeholder="{{ 'general.chooseReseller' | translate}}" [tableInline]="true"
                            [(ngModel)]="selectedResellerId">
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View'] && group.accountId">
                    <td class="row_opening">
                        {{ 'general.account' | translate}}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position:relative; overflow: visible">
                        <fh-account-input name="account" #accountVar="ngModel" (onChanged)="accountChanged($event)"
                            required [selectedResellerId]="selectedResellerId" [tableInline]="true"
                            placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                            [(ngModel)]="group.accountId">
                        </fh-account-input>
                    </td>
                </tr>
            </ng-container>
            <tr *ngIf="!checkDefaultNames(group.name)">
                <td class="row_opening">
                    {{ 'general.name' | translate}}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="name" class="form-control table-inline"
                        [(ngModel)]="group.name" required minlength="1" #name="ngModel">
                </td>
            </tr>
            <tr *ngIf="checkDefaultNames(group.name)">
                <td class="row_opening">
                    {{ 'general.name' | translate}}
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="true" name="name2" class="form-control table-inline"
                        [(ngModel)]="group.displayName">
                </td>
            </tr>
            <tr *ngIf="formMode == 2">
                <td class="row_opening">
                    {{ 'general.owner' | translate}}
                    <span class="error" *ngIf="ownerVar.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="position:relative; overflow: visible;">
                    <fh-user-input name="ownerId" #ownerVar="ngModel" required [selectedAccountId]="group.accountId"
                        placeholder="{{ (group.accountId ? 'general.chooseUser' : 'general.firstChooseAccount') | translate}}"
                        [(ngModel)]="group.ownerId">
                    </fh-user-input>
                </td>
            </tr>
            <tr *ngIf="formMode != 2">
                <td class="row_opening">
                    {{ 'general.owner' | translate}}
                </td>
                <td class="row_content padding-readonly-correct ">
                    <a class="secondary link_bolder" href="/#/UserDetails/Index/{{group?.userId}}">{{ group?.firstName
                        }} {{ group?.lastName }}</a>
                </td>
            </tr>
            <tr *ngIf="isAssetGroup(group)">
                <td style="width:50%; vertical-align: middle;">
                    {{ 'general.color' | translate}}
                </td>
                <td class="row_content">
                    <span *ngIf="formMode != 1">
                        <input [style.background]="group.color" [cpPosition]="'bottom-left'"
                            class="form-control table-inline" [colorPicker]="group.color" [cpAlphaChannel]="'disabled'"
                            [cpOutputFormat]="'hex'"
                            [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                            placeholder="{{ 'placeholder.color' | translate}}"
                            (colorPickerChange)="group.color=$event" />

                        <span *ngIf="group.color" class="float-right hand btn btn-info"
                            style="position: absolute; right: 5px; margin-top: -26px;" (click)="group.color = null;">
                            <i class="fa fa-fw fa-times"></i>
                            <span class="d-none d-md-inline-flex"> {{'general.clear' | translate }}</span>
                        </span>
                    </span>
                    <span *ngIf="formMode == 1" [style.background]="group.color" class="form-control table-inline">
                    </span>
                </td>
            </tr>
            <ng-container *ngIf="permissions['Modules_HasPrivateFleet'] && isDriverGroup(group)">
                <tr *ngIf="formMode == 1">
                    <td class="row_opening">
                        {{ 'general.groupType' | translate }}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        {{ 'enums.groupType.' + group['groupType'] | translate }}
                    </td>
                </tr>
                <tr *ngIf="formMode != 1">
                    <td class="row_opening">
                        {{ 'general.groupType' | translate }}
                    </td>
                    <td style="overflow: unset;" class="padding-readonly-correct row_content">
                        <ng-select class="form-control" name="displayGroupType" (change)="changeGroupType($event)"
                            [clearable]="false" placeholder="{{ 'general.chooseGroupType' | translate }}"
                            [(ngModel)]="group['groupType']" style="width: 100%;">
                            <ng-option [value]=1>
                                {{ 'enums.groupType.1' | translate}}
                            </ng-option>
                            <ng-option [value]=17>
                                {{ 'enums.groupType.17' | translate}}
                            </ng-option>
                            <ng-option [value]=18>
                                {{ 'enums.groupType.18' | translate}}
                            </ng-option>
                            <ng-option [value]=19>
                                {{ 'enums.groupType.19' | translate}}
                            </ng-option>
                        </ng-select>
                    </td>
                </tr>
                <tr *ngIf="formMode == 1">
                    <td class="row_opening">
                        {{ 'general.parent' | translate }}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a *ngIf="group['parentGroupName']; else noParent" class="secondary link_bolder"
                            href="/#/DriverGroupDetails/Index/{{group['parentGroupId']}}">{{ group['parentGroupName']
                            }}</a>
                        <ng-template #noParent>
                            <span>-</span>
                        </ng-template>
                    </td>
                </tr>
                <tr *ngIf="formMode != 1">
                    <td class="row_opening">
                        {{ 'general.parent' | translate }}
                        <span class="error" *ngIf="driverGroupParentVar?.invalid">*</span>
                    </td>
                    <td style="overflow: unset;" class="padding-readonly-correct row_content">
                        <fh-drivergroupparent-selector name="driverGroupParent" #driverGroupParentVar="ngModel"
                            [(ngModel)]="group['parentGroupId']" [driverGroupId]="group.id"
                            [selectedGroupType]="group['groupType']" [placeholder]="'general.chooseParent' | translate">
                        </fh-drivergroupparent-selector>
                    </td>
                </tr>
            </ng-container>
            <tr *ngIf="formMode != 2">
                <td class="row_opening">
                    {{ 'general.timestamp' | translate}}
                </td>
                <td class="padding-readonly-correct row_content" title="{{ group.timestamp?.toLocaleString() }}">
                    {{group.timestamp | amDateFormat: 'lll'}}
                </td>
            </tr>
        </tbody>
    </table>
</div>