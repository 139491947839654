<div class="ibox" style="position:relative">
    <div class="ibox-title">
        <h5>
            <i class="fas fa-fw fa-video"></i> {{'general.dashcams' | translate }}
        </h5>
    </div>
    <div class="ibox-content slick" style="position: relative;">
        <form #customForm="ngForm">
            <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                <thead>
                    <tr>
                        <th>{{ 'general.name' | translate }}</th>
                        <th>{{ 'general.url' | translate }}</th>
                        <th *ngIf="!(formMode == 1)" style="width: 40px; padding-top: 18px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="!(formMode == 1)">
                        <td style="width: auto">
                            <input [readonly]="formMode == 1" type="text" required name="customName"
                                [(ngModel)]="customName" class="form-control table-inline">
                        </td>
                        <td style="width: auto;">
                            <input [readonly]="formMode == 1" type="text" required name="customUrl"
                                [(ngModel)]="customUrl" class="form-control table-inline">
                        </td>
                        <td style="width: 40px; padding-top: 18px;">
                            <button class="btn btn-primary float-right btn-grid" [disabled]="customForm.invalid"
                                (click)="addRow()">
                                <i class="fa fa-plus"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let dashcam of dashcams">
                        <td style="width: auto">
                            <input type="text" [readonly]="true" class="form-control table-inline"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="dashcam.name">
                        </td>
                        <td style="width: auto;">
                            <input type="text" [readonly]="true" class="form-control table-inline"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="dashcam.url">
                        </td>
                        <td *ngIf="!(formMode == 1)" style="width: 40px; padding-top: 18px;">
                            <button class="btn btn-primary float-right btn-grid" (click)="removeRow(dashcam)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="dashcams.length === 0">
                        <td colspan="2">
                            {{'general.nodatafound' | translate }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>