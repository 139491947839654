import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { AuditLog } from '../../models/auditlog.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { DeviceService } from '../../services/device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { getDefaultDpConfig, getGridButtons, createSearch, createdCellBackgroundColor, getGridLanguages, createdCellCheckbox } from 'app/common/gridhelper';


import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DataTableDirective } from 'angular-datatables';
import { TripService } from 'app/services/trip/trip.service';

import { DeviceEpisode } from 'app/common/enums';
import { GeofenceService } from 'app/services/geofence/geofence.service';
import { Geofence } from 'app/models/geofence.model';
import { GridBase360Directive } from 'app/common/360Grid.base';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { StorageHelper } from 'app/common/storagehelper';
import { ColorService } from 'app/services/common/color.service';

window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-geofence-states',
    templateUrl: 'geofenceStates.template.html'
})
export class GeofenceStatesViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
    sub;
    geofence: Geofence;
    loading = false;

    excludingColumns = ['stateChangeDateTime', 'episodeStart', 'episodeEnd'];

    error: any;
    success: any;

    timezoneIana: string;

    limit = 1000;

    token: string;



    languageLoaded = false;
    geofenceStatesLoaded = false;

    // Datepicker
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    to: any;
    from: any;
    daterangepickerModel: any[];
    permissions: {};

    maxDate = new Date();

    randomKey: number;

    columns = [];

    constructorName = 'GeofenceStatesViewComponent';
    permissionName = 'FleetManagement_GeofenceStates';
    geofenceId: any;

    constructor(private http: HttpClient,
        private auditLogService: AuditLogService,
        private geofenceService: GeofenceService,
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private tripService: TripService,
        private authenticationService: AuthenticationService,
        protected storageHelper: StorageHelper,
        private colorService: ColorService) {
        super(storageHelper);

        this.geofence = null;

        this.timezoneIana = authenticationService.getTimeZoneIana();
        this.token = authenticationService.getAuthToken();

        this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(1, 'weeks').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(moment, authenticationService);
    }

    ngOnInit() {
        this.loading = true;
        this.geofence = new Geofence;
        this.geofence.id = 0;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.geofenceId = id;
            this.geofenceService.getGeofenceById(id).subscribe(geofence => {
                this.geofence = geofence;

                if (this.geofence == null) {
                    this.router.navigate(['/Geofences/Overview'])
                }

                this.loading = false;

                this.translateService.get('general.date').subscribe(value => {
                    this.initGrid();
                    this.languageLoaded = true;
                });
            });
        });
    }

    dateChanged(event) {
        console.log('Changed date');
        const that = this;
        if (event !== null) {
            this.geofenceStatesLoaded = true;

            this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.ajax.url(that.tripService.getGeofenceStatesUrl(null, this.geofence.id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.randomKey))
                    .load(() => this.geofenceStatesLoaded = false);
            });
        }
    }

    initGrid(): void {
        const that = this;

        const commonExportOptions = {
            modifier: {
                page: 'all',
                search: 'none'
            },
            columns: ['id_export:name', ':visible[tabindex]']
        };

        const deviceEpisodeTypes = [];
        Object.values(DeviceEpisode).filter(key => isNaN(Number(DeviceEpisode[key]))).forEach(function (item, index) {
            deviceEpisodeTypes.push({ id: item.toString(), value: DeviceEpisode[item].toString() });
        });

        this.columns = [
            {
                name: 'stateChangeDateTime',
                data: 'stateChangeDateTime',
                title: this.translateService.instant('general.timestamp'),
                type: 'date',
                width: 130,
                render: function (data, type, row) {
                    const date = Moment.utc(data)['tz'](that.timezoneIana);
                    return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('YYYY-MM-DD HH:mm:ss') + '</span>' : '';
                },
            },
            {
                name: 'hasLeft',
                data: 'hasLeft',
                width: 70,
                type: 'checkBox',
                createdCell: createdCellCheckbox,
                title: this.translateService.instant('general.hasLeft'),
                render: function (data, type, row) {
                    return data ?? '0';
                },
            },
            {
                name: 'hasEntered',
                data: 'hasEntered',
                width: 70,
                type: 'checkBox',
                createdCell: createdCellCheckbox,
                title: this.translateService.instant('general.hasEntered'),
                render: function (data, type, row) {
                    return data ?? '0';
                },
            }, {
                name: 'hasPassedThrough',
                data: 'hasPassedThrough',
                width: 70,
                type: 'checkBox',
                createdCell: createdCellCheckbox,
                title: this.translateService.instant('general.hasPassedThrough'),
                render: function (data, type, row) {
                    return data ?? '0';
                },
            }, {
                name: 'geoFenceLabel',
                data: 'geoFenceLabel',
                visible: false,
                title: this.translateService.instant('general.geofence'),
                render: function (data, type, row) {
                    return '<a class="link_bolder" href="/#/GeofenceDetails/Index/' + row.geoFenceId + '">' + data + '</a>';
                },
            }, {
                name: 'assetName',
                data: 'assetName',
                title: this.translateService.instant('general.assetName'),
                render: function (data, type, row) {
                    return '<a class="link_bolder" href="/#/DeviceDetails/Index/' + row.deviceId + '">' + data + '</a>';
                },
            },
        ];


        this.dtOptions = {
            buttons: getGridButtons(this.commonExportOptions, 'geofenceStates_overview', this.translateService.instant('menu.deviceoverview'), this.colorService),
            pagingType: 'simple_numbers',
            serverSide: true,
            processing: true,
            // scrollY: 500,
            // scroller: {
            //     loadingIndicator: true
            // },
            searchDelay: 2000,
            deferRender: true,
            scrollX: true,
            colReorder: {},
            deferLoading: 0,
            stateSave: true,
            stateSaveCallback: function (settings, data) {
                that.saveState(that.constructorName, data);
            },
            stateLoadCallback: function (_, callback) {
                (async () => { try {
                    const columnSettings = await that.loadState(that.constructorName);
                    that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
                    return columnSettings;
                } catch (e) {
                    that.error = {};
                    that.error.error = e;
                    that.error.statusText = 'Error fetching column settings';

                    return null;
                }})().then(result => {
                    callback(result);
                });
            },
            order: [[0, 'desc']],
            ajax: {
                beforeSend: () => {
                    that.drawFilterRow();

                    $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
                },
                url: that.tripService.getGeofenceStatesUrl(null, this.geofence.id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.randomKey),
                data: (d) => {
                    return d;
                },
                dataSrc: function (json) {
                    return json.data;
                },
                type: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + that.token
                }
            },
            initComplete: function (settings, json) {
                that.loading = false;
                that.geofenceStatesLoaded = false;

                console.log('init complete');
                that.checkFilters();
                that.drawFilterRow();
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
            },
            columns: this.columns,
            pageLength: 17,
            lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
            language: getGridLanguages(this.translateService),
        };
    }
}
