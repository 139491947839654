import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getGridButtons, getGridLanguages, createSearch, setTableStars, setSelection, setSelectionRows, getDefaultDpConfig } from 'app/common/gridhelper';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { Observable, catchError, forkJoin } from 'rxjs';
import { AssetGroupsService } from 'app/services/asset/assetGroups.service';
import { BatchService } from 'app/services/batch/batch.service';
import { GridBase360Directive } from 'app/common/360Grid.base';
import { SignalrService } from 'app/services/signalr/signalr.service';
import { ActivatedRoute } from '@angular/router';
import { BatchItemStatus } from 'app/common/enums';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { StorageHelper } from 'app/common/storagehelper';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { ColorService } from 'app/services/common/color.service';

window['moment'] = Moment;
mTZ();

@Component({
  selector: 'fh-batch',
  templateUrl: 'batch.template.html'
})
export class BatchViewComponent extends GridBase360Directive implements OnDestroy {
  token: string;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  excludingColumns = ['jobRequested', 'batchItemsLength', 'batchItemsFailed'];

  success;
  error;

  randomKey: number;

  constructorName = 'BatchViewComponent';
  permissionName = 'FleetManagement_Jobs';
  timezoneIana: string;


  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  constructor(private route: ActivatedRoute, public signalrService: SignalrService, private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private batchService: BatchService, protected storageHelper: StorageHelper, private colorService: ColorService) {
    super(storageHelper);

    const that = this;
    this.loading = true;

    this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, 'months').startOf('day').toDate(),
      Moment().tz(this.timezoneIana).endOf('day').toDate()
    ];

    this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

    // Get all the date for dropdown boxes
    forkJoin(
      this.translateService.get('general.date')
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();

        this.initGrid();
      },
      err => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  guidGenerator() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable:no-bitwise
      const r = Math.random() * 16 | 0,
        v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  dateChanged(event) {
    const that = this;
    if (event !== null) {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.url(that.batchService.getPagingUrl(this.randomKey, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'))).load();
      });
    }
  }

  public processData(): void {

    const random = this.guidGenerator();

    console.log('Batch ' + random + ' created');
    this.success = 'The batch request ' + random + ' has been send to SignalR hub.We well be informed on updates.';
    const userId = +this.authenticationService.getUserId();

    this.signalrService.connection
      .invoke('StartDataProcessing', userId, random)
      .then(() => {
        this.success = 'Successfully finished batch SignalR'!;
        this.cd.markForCheck();
      })
      , catchError(error => {
        this.error = error;
        this.cd.markForCheck();
        return null;
      });
  }

  refresh() {
    super.seachChanged('');
  }

  initGrid(): void {
    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: 'id',
        data: 'id',
        className: 'noVis',
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: '20',
        render: function (data, type, row) {
          return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/System/BatchDetails/Index/' + data + '\'><span class="d-none d-md-inline-flex" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
        }
      },
      {
        name: 'jobRequested',
        data: 'jobRequested',
        type: 'date',
        title: this.translateService.instant('general.jobRequested'),
        render: function (data, type, row) {
          const date = Moment.utc(data)['tz'](that.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
        },
      },
      {
        name: 'account',
        data: 'account',
        title: this.translateService.instant('general.companyName'),
      },
      {
        name: 'user',
        data: 'user',
        title: this.translateService.instant('general.userName'),
      },
      {
        name: 'entityType',
        data: 'entityTypes',
        title: this.translateService.instant('general.batchEntity'),
        render: function (data, type, row) {
          return that.translateService.instant(('enums.entityTypes.' + data));
        }
      },
      {
        name: 'batchActionType',
        data: 'batchActionType',
        title: this.translateService.instant('general.batchActionType'),
        render: function (data, type, row) {
          return that.translateService.instant(('enums.batchAction.' + data));
        }
      },
      {
        name: 'status',
        data: 'status',
        title: this.translateService.instant('general.status'),
        render: function (data, type, row) {
          return that.translateService.instant(('enums.batchStatus.' + data));
        }
      },
      {
        name: 'batchItemsLength',
        data: 'batchItems',
        searchable: false,
        orderable: false,
        title: this.translateService.instant('general.itemCount'),
        render: function (data, type, row) {
          if (type === 'display') {
            return '<b>' + data.length + '</b>';
          }

          return data.length;
        }
      },
      {
        name: 'batchItemsFailed',
        data: 'batchItems',
        searchable: false,
        orderable: false,
        title: this.translateService.instant('general.failedItemCount'),
        render: function (data, type, row) {
          return data.filter(item => item.status === BatchItemStatus.Error).length;
        }
      }];

    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'jobs_overview', this.translateService.instant('general.job'), this.colorService),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => {
          try {
            const columnSettings = await that.loadState(that.constructorName);
            that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            that.error = {};
            that.error.error = e;
            that.error.statusText = 'Error fetching column settings';

            return null;
          }
        })().then(result => {
          callback(result);
        });
      },
      order: [[1, 'desc']],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: this.batchService.getPagingUrl(this.randomKey, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')),
        data: (d) => {
          return d;
        },
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        that.checkFilters();
        that.setEvents();
        that.drawFilterRow();
        that.loading = false;
        that.cd.markForCheck();
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id', 'id_export']
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
    };
  }
}
