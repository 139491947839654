<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Fuel/Overview">{{'general.fuel' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.dashboard' | translate }}</li>
            </ol>
        </nav>
    </div>
    <div *ngIf="!showMapOnSide" class="col-lg-12" style="padding: 1px 0 0 0;">
        <ngx-loading [show]="loading"></ngx-loading>
        <fh-leaflet-map (mapReady)="onMapReady($event)" [geofences]="geofences" [device]="device" [height]="mapHeight"
            [locations]="locationData">
        </fh-leaflet-map>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6" [hidden]="showFullScreen">

                <form #userForm="ngForm">
                    <div class="ibox" style="position: relative;">
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-fw fa-gas-pump"></i> {{'general.fuelDevice' | translate }}
                            </h5>
                        </div>
                        <fh-fuel-details [fuel]="fuelEvent" [formMode]="formMode" (error)="updateError($event)"
                            [loading]="loading || saving" (success)="updateSuccess($event)">
                        </fh-fuel-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()" (onDelete)="onDelete()"
                            [allowEdit]="permissions['FuelEvents_Edit']"
                            [allowDelete]="permissions['FuelEvents_Delete']" (setFormMode)="setFormMode($event)"
                            [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>

                <fh-tagging [objectId]="fuelEvent?.id" [objectType]="tagType">
                </fh-tagging>
            </div>
            <div [ngClass]="showFullScreen ? 'col-lg-12' : 'col-lg-6'">

                <div class="ibox">
                    <div class="ibox-title">
                        <span class="pull-left">
                            <h5>
                                <i class="fas fa-fw fa-gas-pump"></i> {{'general.fuelEvent' | translate }}
                            </h5>
                        </span>
                        <span class="float-right" [tooltip]="'Event timestamp'">
                            <span class="d-none d-md-inline-flex"><strong>
                                    {{ fuelEvent.eventTimeStamp | amDateFormat: 'LLL' }}</strong></span>
                        </span>
                    </div>
                    <div class="ibox-content" style="position: relative;" *ngIf=!fuelEvent>
                        {{ ('general.noDeviceState') | translate }}
                    </div>
                    <div class=" ibox-content slick" style="position: relative;" *ngIf=fuelEvent>
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div class="flex-container">
                            <div class="col-6 col-lg-3" style="padding:0" *ngIf="fuelEvent?.refuelVsTheft == 'Theft' ">
                                <div class="kpi-item border-active ">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-user-secret eye-active" style="color:#971c24"></i>
                                        {{'general.theft' | translate}}
                                    </h3>
                                    <div>{{'general.refuelVsTheft' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-3" style="padding:0" *ngIf="fuelEvent?.refuelVsTheft == 'Refuel' ">
                                <div class="kpi-item border-active ">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-gas-pump eye-active" style="color:#008e43"></i>
                                        {{ 'general.refuel' | translate}}
                                    </h3>
                                    <div>{{'general.refuelVsTheft' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-3" style="padding:0" *ngIf="fuelEvent?.fuelingActivityInLiters">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ fuelEvent?.fuelingActivityInLiters ? fuelEvent?.fuelingActivityInLiters.toFixed(1) :
                                        '-' }}
                                        L
                                    </h3>
                                    <div>{{'general.fuelingActivityInLiters' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-3" style="padding:0" *ngIf="fuelEvent?.certaintyInPercentage">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-crosshairs" style="opacity: 0.3;"></i> {{
                                        fuelEvent?.certaintyInPercentage ?
                                        fuelEvent?.certaintyInPercentage : '-' }} %
                                    </h3>
                                    <div>{{'general.certaintyInPercentage' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-3" style="padding:0"
                                *ngIf="fuelEvent?.isValid === null || fuelEvent?.isValid === undefined">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-check" style="opacity: 0.3;"></i> {{
                                        'general.unknownValidation' | translate }}
                                    </h3>
                                    <div>{{'general.isValid' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-3" style="padding:0"
                                *ngIf="fuelEvent?.isValid !== null && fuelEvent?.isValid !== undefined">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold" *ngIf="fuelEvent?.isValid">
                                        <i class="fa fa-fw fa-check" style="color: rgb(0, 142, 67)"></i>
                                        {{'general.valid' | translate }}
                                    </h3>
                                    <h3 class="margin-bottom font-bold" *ngIf="!fuelEvent?.isValid">
                                        <i class="fa fa-fw fa-times" style="color: rgb(151, 28, 36)"></i>
                                        {{'general.notValid' | translate }}
                                    </h3>
                                    <div>{{'general.isValid' | translate }}</div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                    <div class="ibox-footer">
                        <span class="float-right">
                            <button *ngIf="fuelEvent?.isValid !== undefined && fuelEvent?.isValid !== null" class="btn btn-info"
                                (click)="fuelValidation(null);" title="{{ 'general.clear' | translate }}">
                                <i class="fa fa-fw fa-check" style="opacity: 0.3;"></i>
                                {{'general.clear' | translate }}
                            </button>
                            <button
                                *ngIf="fuelEvent?.isValid === true || fuelEvent?.isValid === undefined || fuelEvent?.isValid === null"
                                class="btn btn-info" (click)="fuelValidation(false);"
                                title="{{ 'general.notValid' | translate }}">
                                <i class="fa fa-fw fa-times" style="color: rgb(151, 28, 36)"></i>
                                {{'general.notValid' | translate }}
                            </button>
                            <button
                                *ngIf="fuelEvent?.isValid === false || fuelEvent?.isValid === undefined || fuelEvent?.isValid === null"
                                class="btn btn-info" (click)="fuelValidation(true);"
                                title="{{ 'general.valid' | translate }}">
                                <i class="fa fa-fw fa-check" style="color: rgb(0, 142, 67)"></i>
                                {{'general.valid' | translate }}
                            </button>
                        </span>
                        <div style="clear: both;"></div>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title" style="overflow: visible; height: 46px">
                        <h5>
                            <i class="fas fa-fw fa-gas-pump"></i> {{'general.fuelEventGraph' | translate }}
                        </h5>
                        <span class="float-right">
                            <ibox-chart-export [chartObject]="chartFuel">
                                <i [tooltip]="'Switch wide'" class="fas fa-fw fa-2x fa-arrows-alt-h"
                                    style="font-size: 1.3em;" [ngStyle]="{'color': showFullScreen ? '#555': '#ccc' }"
                                    (click)="flipFullscreen()"></i>
                            </ibox-chart-export>
                        </span>
                    </div>
                    <div class="ibox-content" style="position: relative;">
                        <ngx-loading [show]="loadingFuel"></ngx-loading>
                        <highcharts-chart [runOutsideAngular]="true" style="display: block; width: 100%; height:350px"
                            [Highcharts]="Highcharts" [options]="chartFuel" *ngIf="fuelEvents.length > 0">
                        </highcharts-chart>
                        <span *ngIf="fuelEvents.length == 0">No fuel events found</span>
                    </div>
                </div>

                <div class="ibox" style="position: relative;" *ngIf="showMapOnSide">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <fh-leaflet-map (mapReady)="onMapReady($event)" [height]="mapHeight" [geofences]="geofences"
                        [geofenceEnabled]="false">
                    </fh-leaflet-map>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>