import { Component, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormMode, BasicTriggerCase } from 'app/common/enums';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DeviceService } from 'app/services/device/device.service';

@Component({
    selector: 'fh-batch-basictrigger',
    templateUrl: 'createBasicTrigger.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchBasicTriggerComponent {
    step: number;
    @Output() onFinish = new EventEmitter();
    @Input() gridSelection = [];
    @Input() accountId;

    showWarning = false;

    loadingDeviceDetails = false;
    logItem;
    deviceInfo = [];

    assetForm = new FormGroup({
        checkbox: new FormControl('', Validators.required),
    });

    groupForm = new FormGroup({
        useCase: new FormControl(null, Validators.required),
        value: new FormControl(null, Validators.required),
        sendOutputMessage: new FormControl(null),
        userContactGroups: new FormControl(null),
    });

    value;
    useCase;
    useCaseOptions: { id: string; name: any; }[];
    formMode = FormMode.read;
    userContactGroups;
    selectedResellerId;
    permissions: {};

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    get formControls() {
        return this.groupForm.controls as unknown as FormGroup[];
    }

    constructor(
        private cd: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private deviceService: DeviceService,
        private translateService: TranslateService) {
        this.permissions = this.authenticationService.permissions;
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.isAnimated = true;
        this.step = 1;
        this.formMode = FormMode.read;

        this.useCaseOptions = Object.keys(BasicTriggerCase)
            .filter(k => typeof BasicTriggerCase[k] === 'string')
            .map(n => ({
                id: n.toString(),
                name: this.translateService.instant('enums.basicTriggerCase.' + n)
            }));
    }

    initFinish() {
        // Fetch all data and make sure it can be saved
        console.log(1);
    }

    onCheckOutFinish() {
        this.onFinish.emit({ status: 'success', object: this.groupForm.value, log: this.logItem })
        this.step = 1;
    }

    initForm() {
        this.step = 1;
        console.log('init tab');

        if (this.accountId == 0) {
            console.log('calculate account');
        }

        this.useCaseChanged();

        if (this.accountId == 0) {
            // Get account

            this.deviceService.getDeviceData(this.gridSelection).subscribe(devices => {

                var valueArr = devices.map(function (item) { return item.accountId });

                const distinctThings = valueArr.filter(
                    (deviceId, i, arr) => arr.findIndex(t => t === deviceId) === i
                );

                if (distinctThings.length == 1) {
                    this.accountId = distinctThings[0];
                } else {
                    this.showWarning = true;
                    this.groupForm.disable();
                }
            });
        }
    }

    useCaseChanged() {
        if (this.groupForm.value.useCase != 2) {
            this.groupForm.patchValue({ value: 1 });
        } else {
            this.groupForm.patchValue({ value: null });
        }
    }
}

