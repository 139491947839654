<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()">
    <fh-wizard-step title='general.tripReport' icon='fa-file-pdf' (open)="initForm()" [validate]="groupForm">
        <div class="wizard-content">
            <form [formGroup]="groupForm">
                <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                    <tbody>
                        <tr>
                            <td class="row_opening">{{ 'general.report' | translate }}
                                <span class="error" *ngIf="groupForm.get('instantReportType')?.errors">*</span>
                            </td>
                            <td class="row_content" style="overflow: visible;">
                                <ng-select ngDefaultControl [searchable]="false" [clearable]="false" [multiple]="false"
                                    [readonly]="false" placeholder="{{ 'general.chooseReport' | translate}}" #input
                                    (change)="prepareFilters()" formControlName="instantReportType" required
                                    class="form-control table-inline">
                                    <ng-option *ngFor="let report of reports | orderBy : 'name':false"
                                        [value]="report.id">
                                        <i class="fas fa-fw fa-file-pdf"></i> {{ report.name }}
                                    </ng-option>
                                </ng-select>
                            </td>
                        </tr>
                        <tr *ngIf="groupForm.get('instantReportType')?.value != 2">
                            <td class="row_opening">
                                {{ 'general.reportTemplate' | translate }}
                                <span class="error" *ngIf="groupForm.get('templateId')?.errors">*</span>
                            </td>
                            <td class="row_content" style="position: relative; overflow: visible;">
                                <ng-select name="templateName" ngDefaultControl [searchable]="true" [clearable]="false"
                                    placeholder="{{ 'general.chooseReportTemplate' | translate}} ({{reportTemplates.length > 0 ? reportTemplates.length : 0}})"
                                    [disabled]="groupForm.get('instantReportType')?.value == null || reportTemplates.length == 0"
                                    formControlName="templateId" class="form-control table-inline" style="width: 100%;"
                                    required>
                                    <ng-option *ngIf="reportTemplates.length == 0" [value]="'default'">
                                        {{ 'general.default' | translate}}
                                    </ng-option>
                                    <ng-option *ngFor="let reportTemplate of reportTemplates | orderBy : 'name':false"
                                        [value]="reportTemplate.id">
                                        {{ reportTemplate.name }} <div *ngIf="reportTemplate.userid > 0"> ({{
                                            'general.personal' | translate}})</div>
                                    </ng-option>
                                </ng-select>
                            </td>
                        </tr>
                        <tr>
                            <td class="row_opening"> {{ 'general.date' | translate}}
                                <span class="error" *ngIf="groupForm.get('dateSelection')?.errors">*</span>
                            </td>
                            <td class="row_content">
                                <input name="Daterangepicker" required placeholder="Daterangepicker" style="width: 100%"
                                    placeholder="Datepicker" type="text" [bsConfig]="dpConfig"
                                    class="form-control table-inline" bsDaterangepicker
                                    formControlName="dateSelection" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </fh-wizard-step>
</fh-wizard>