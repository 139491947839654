<ng-content select="[header]"></ng-content>
<div class="ibox-content slick" style="position: relative; padding: 2px" [style.height.px]="height">
    <ngx-loading [show]="loadingLocation"></ngx-loading>
    <div *ngIf="isSpinning" style="position: absolute; z-index: 1400; bottom: 15px; left: 15px;"> <i
            tooltip="{{'general.updated' | translate }}" style="margin-left: 3px;"
            class="fa fa-fw fa-spinner fa-spin eye-active green"></i>
    </div>
    <div id="map-component" [style.border-radius.px]="borderRadius"
        style="height: 100%; width:100%; --svgcolor: {{editableGeofence?.color}};" leaflet [leafletOptions]="options"
        (leafletMapReady)="onMapReady($event)">
    </div>
</div>