<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Reporting/Executions">{{'general.reporting' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a
                        href="/#/ReportSubscriptionDetails/Index/{{reportSubscription?.id}}">{{reportSubscription?.id}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.dashboard' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-reporting-navigation></fh-reporting-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">

            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-clock"></i> {{'general.subscription' | translate }}
                        </h5>
                    </div>
                    <div *ngIf="formMode == 3" class="ibox-content slick" style="position:relative; overflow: auto">


                    </div>
                    <div *ngIf="formMode == 1" class="ibox-content slick" style="position:relative; overflow: auto">
                        <ngx-loading [show]="loading"></ngx-loading>                        
                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening_even">
                                        {{ 'general.account' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        {{ reportSubscription?.companyName }}
                                    </td>
                                    <td class="row_opening_even">
                                        {{ 'general.selection' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible"
                                        [title]="reportSubscription?.timestamp">
                                        {{ reportSubscription?.start | amDateFormat: 'LLL' }} - {{
                                        reportSubscription?.end | amDateFormat: 'LLL' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_even">
                                        {{ 'general.reportType' | translate}}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        {{ reportSubscription ? (('enums.reportType.' +
                                        reportSubscription?.selectedReportType) | translate) : '-' }}
                                    </td>
                                    <td class="row_opening_even">
                                        {{ 'general.reportName' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        {{ reportSubscription?.name != "" ? reportSubscription?.name : '-' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_even">
                                        {{ 'general.generatedBy' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        {{ reportSubscription?.userName }}
                                    </td>
                                    <td class="row_opening_even">
                                        {{ 'general.created' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible"
                                        [title]="reportSubscription?.createdDate">
                                        {{ reportSubscription?.createdDate | amDateFormat: 'LLL' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_even">
                                        {{ 'general.executionCount' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        {{ reportSubscription?.executionCount }}
                                    </td>
                                    <td class="row_opening_even">
                                        {{ 'general.maxExecutedTimestamp' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible"
                                        [title]="reportSubscription?.maxExecutedTimestamp">
                                        {{ reportSubscription?.maxExecutedTimestamp ?
                                        (reportSubscription?.maxExecutedTimestamp | amDateFormat: 'LLL') : '-' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_even">
                                        {{ 'general.reportSubscriptionType' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        {{ reportSubscription ? (('enums.reportSubscriptionType.' +
                                        reportSubscription?.reportSubscriptionType) | translate) : '-' }}
                                    </td>
                                    <td class="row_opening_even">
                                        {{ 'general.nextOccurrence' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible"
                                        *ngIf="!reportSubscription?.isArchived"
                                        [title]="reportSubscription?.nextOccurrence">
                                        <span *ngIf="!reportSubscription?.isArchived">{{
                                            reportSubscription?.nextOccurrence ? (reportSubscription?.nextOccurrence
                                            |amDateFormat: 'LLL') : '-' }}</span>
                                        <span *ngIf="reportSubscription?.isArchived"><i class="fa fa-history"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_even">
                                        {{ 'general.recipient' | translate }}
                                    </td>
                                    <td class="row_content"
                                        style="position: relative; max-width: 100px; white-space: normal; word-wrap: break-word;"
                                        colspan="3">
                                        {{ reportSubscription?.recipients ? reportSubscription?.recipients : '-' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <fh-save-footer [valid]="true" (onSave)="onSave()"
                        [allowEdit]="false && permissions['FleetManagementReporting_Edit']"
                        (onDelete)="onDelete($event)" [allowDelete]="permissions['FleetManagementReporting_Delete']"
                        (setFormMode)="setFormMode($event)" [formMode]="formMode">
                        <span *ngIf="permissions['FleetManagementReporting_Edit']" class="float-left">
                            <button *ngIf="reportSubscription?.reportSubscriptionType == 2" class="btn btn-primary" 
                            [routerLink]="['/Reporting/Edit/', reportSubscription?.id]"><i 
                                class="fa fa-fw fa-edit"></i>
                                <span class="d-none d-md-inline-flex">{{'general.edit' | translate}}</span>
                            </button>
                        </span>
                        <span class="float-right" *ngIf="reportSubscription && reportSubscription?.id">
                            <div class="ibox-content slick" style="position: relative; border: 0;">
                                <button #archiveButton class="btn btn-primary" (click)="showArchive()" 
                                    *ngIf="permissions['FleetManagementReporting_Edit'] && reportSubscription?.reportSubscriptionType == 2"
                                    [hidden]="reportSubscription?.isArchived"
                                    title="{{ 'general.archiveSubscription' | translate }}"><i
                                        class="fa fa-fw fa-box-archive"></i>
                                    <span class="d-none d-md-inline-flex">{{'general.archiveSubscription' | translate
                                        }}</span></button>
                                <button #unarchiveButton class="btn btn-primary" (click)="showUnarchive()"
                                    *ngIf="permissions['FleetManagementReporting_Edit'] && reportSubscription?.reportSubscriptionType == 2"
                                    [hidden]="!reportSubscription?.isArchived"
                                    title="{{ 'general.unarchiveSubscription' | translate }}"><i
                                        class="fa fa-fw fa-box-open"></i>
                                    <span class="d-none d-md-inline-flex">{{'general.unarchiveSubscription' | translate
                                        }}</span></button>
                                <button class="btn btn-primary" (click)="showRerun();"
                                    [disabled]="reportSubscription?.isArchived"
                                    title="{{ 'general.rerun' | translate }}"><i
                                        class="fa fa-fw fa-arrow-rotate-right"></i>
                                    <span class="d-none d-md-inline-flex">{{'general.rerun' | translate
                                        }}</span></button>
                            </div>
                        </span>

                    </fh-save-footer>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-file-chart-pie"></i> {{ 'general.executions' | translate }}
                        </h5>

                        <span class="float-right">
                            <div class="filter-form d-none d-md-block">
                                <input type="text" class="form-control table-inline top-filter d-none d-md-block"
                                    (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position:relative; overflow: x">
                        <ngx-loading [show]="loadingExecutions"></ngx-loading>

                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th style="width:20px"></th>
                                    <th>{{'general.timestamp' | translate }}</th>
                                    <th>{{'general.successful' | translate }}</th>
                                    <th>{{'general.executionDurationInSeconds' | translate }}</th>
                                    <th>{{'general.executionResultRecordCount' | translate }}</th>
                                    <th>{{'general.start' | translate }}</th>
                                    <th>{{'general.end' | translate }}</th>
                                    <th>{{'general.message' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="executions.length == 0">
                                    <td colspan="5">{{'general.noDataFound' | translate }}</td>
                                </tr>
                                <tr
                                    *ngFor="let execution of executions | orderBy : 'executedTimestamp':true | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage }">
                                    <td>
                                        <button class="btn btn-primary btn-grid"
                                            [routerLink]="['/ReportDetails/Index/', execution?.id]"
                                            title="{{ 'general.report' | translate }}">
                                            {{'general.details' | translate }}
                                            <i class="fas fa-fw fa-angle-right"></i>
                                        </button>
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible"
                                        [title]="execution?.executedTimestamp">
                                        {{ execution?.executedTimestamp | amDateFormat: 'lll' }}
                                    </td>
                                    <td>
                                        <i class="fa fa-fw"
                                            [ngClass]="execution?.isSuccessful ? 'fa-check' : 'fa-times'"
                                            [style.color]="execution?.isSuccessful ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                                    </td>
                                    <td>
                                        {{ execution ? actualRoundSeconds(execution?.executionDurationInMilliseconds /
                                        1000) : '-' }}
                                    </td>
                                    <td>
                                        {{ execution ? execution?.executionResultRecordCount : '-' }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible"
                                        [title]="execution?.periodStart">
                                        {{ execution?.periodStart | amDateFormat: 'lll' }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible"
                                        [title]="execution?.periodEnd">
                                        {{ execution?.periodEnd | amDateFormat: 'lll' }}
                                    </td>
                                    <td>
                                        {{ execution?.resultMessage != '' ? execution?.resultMessage : '-' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                            'grid.previous' | translate }}</a>
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value">
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-confirmation-modal #rerunModal (onFinish)="rerunReport($event)">
    <h4 header class="modal-title pull-left"><i class="fa fa-fw fa-bolt"></i> {{'general.action' | translate }}:
        {{ 'general.rerun' | translate }}</h4>
    <fh-rerun-report-modal #rerunBody [reportSubscription]="reportSubscription" body></fh-rerun-report-modal>
</fh-confirmation-modal>

<fh-confirmation-modal #archiveModal [message]="'general.warningArchive'"
    (onFinish)="archiveSubcription($event)"></fh-confirmation-modal>
    
<fh-confirmation-modal #unarchiveModal [message]="'general.warningUnarchive'"
    (onFinish)="unarchiveSubscription($event)"></fh-confirmation-modal>