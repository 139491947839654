<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System/">{{'general.system' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="/#/Notifications/Log/Chart">{{'general.notificationLog' | translate }}</a>
                </li>
            </ol>
        </nav>
    </div>
    <fh-notifications-navigation></fh-notifications-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="">
            <div class="col-lg-12">

                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative">
                        <div class="col-lg-6">
                            <div class="form-group input-group" style="margin-bottom: 5px;">
                                <span class="input-group-prepend">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker"
                                    type="text" [bsConfig]="dpConfig" class="form-control" bsDaterangepicker
                                    (onHidden)="dateChanged('hidden')" [(ngModel)]="daterangepickerModel">
                                <span class="input-group-calendar-datatables">
                                    <i class="fa fa-calendar-alt"></i>
                                </span>
                                <div style="clear:both"></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group input-group" style="margin-bottom: 5px;">
                                <span class="input-group-prepend">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <fh-reseller-input name="reseller" #resellerVar="ngModel"
                                    (onChanged)="resellerChanged($event)" required [tableInline]=false
                                    placeholder="{{ 'general.chooseReseller' | translate}}"
                                    [(ngModel)]="selectedResellerId">
                                </fh-reseller-input>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group input-group" style="margin-bottom: 5px;">
                                <span class="input-group-prepend">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <fh-account-input name="account" #accountVar="ngModel"
                                    (onChanged)="accountChanged($event)" required [tableInline]=false
                                    [selectedResellerId]="selectedResellerId"
                                    placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                    [(ngModel)]="selectedAccountId">
                                </fh-account-input>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <!-- <div class="form-group input-group" style="margin-bottom: 5px;" title="Filter on 0 values">
                                <span id="checkBox11" class="input-group-prepend hand" title="Filter on 0 values">
                                    <i id="checkBox_11" class="auto_checkbox fa fa-fw"
                                        (click)="filterZeroValues = !filterZeroValues"
                                        [ngClass]="filterZeroValues ? 'fa-check' : 'fa-times'"
                                        title="Filter on 0 values"
                                        [style.color]="filterZeroValues ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                                </span>
                                <input name="checkboxName_11" id="checkboxName_11" style="width:100%; cursor: pointer!important;"
                                    placeholder="{{'general.filterZeroValues' | translate }}" disabled="disabled"
                                    class="form-control auto_search">
                            </div> -->
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title" style="overflow: visible; height: 46px">
                        <h5>
                            <i class="fas fa-fw fa-chart-line"></i> {{'general.notificationsPerHour' | translate }}
                        </h5>
                        <span class="float-right">
                            <ibox-chart-export [chartObject]="chart">
                            </ibox-chart-export>
                        </span>
                    </div>
                    <div class="ibox-content" style="position: relative;">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <highcharts-chart [runOutsideAngular]="true" style="display: block; width: 100%; height:350px"
                            [Highcharts]="Highcharts" [options]="chart">
                        </highcharts-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>