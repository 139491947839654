import { Component, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { BatchAction } from 'app/common/enums';
import { DeviceService } from 'app/services/device/device.service';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'fh-batch-replace-device',
    templateUrl: 'replaceDevice.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchReplaceDeviceComponent {
    step: number;

    @Output() onFinish = new EventEmitter();
    @Input() gridSelection = [];
    @Input() resellerId;
    @Input() accountId;

    disabled = true;
    loadingDeviceDetails = false;
    loadingDevices = true;
    logItem;
    devicesToChoose = {};
    deviceDictionary = {};
    hardwareDictionary = {};

    deviceForm: FormArray;

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    get formControls() {
        return this.deviceForm.controls as unknown as FormGroup[];
    }

    constructor(private cd: ChangeDetectorRef, private deviceService: DeviceService, private deviceTypeService: DeviceTypeService) {
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.isAnimated = true;
        this.step = 1;
    }

    initFinish() {
        // Fetch all data and make sure it can be saved
        console.log(1);
    }

    onCheckOutFinish() {
        this.onFinish.emit({ status: 'success', object: this.deviceForm.value, log: this.logItem })
        this.step = 1;
    }

    loadDevices() {
        if (this.deviceForm) {
            return;
        }

        const formGroups: FormGroup[] = [];
        this.loadingDeviceDetails = true;
        this.loadingDevices = true;

        this.deviceService.getDeviceData(this.gridSelection).subscribe(devices => {
            this.loadingDeviceDetails = false;
            this.cd.markForCheck();

            devices = devices.filter(x => x.assetId !== null);

            for (const device of devices) {
                formGroups.push(new FormGroup({
                    imei: new FormControl(device.unitId, Validators.required),
                    deviceId: new FormControl(device.id, Validators.required),
                    accountId: new FormControl(device.accountId),
                    resellerId: new FormControl(device.resellerId),
                    companyName: new FormControl(device.companyName),
                    deviceTypeId: new FormControl(device.deviceTypeId),
                    name: new FormControl(device.assetName),
                    code: new FormControl(device.assetCode),
                    newDeviceId: new FormControl(null, Validators.required),
                    swapSim: new FormControl(null),
                }));
            }

            this.deviceForm = new FormArray(formGroups);
        });

        this.deviceTypeService.getDeviceTypes().subscribe(deviceTypes => {
            for (let i = 0, n = deviceTypes.length; i < n; ++i) {
                this.hardwareDictionary[deviceTypes[i].id] = { modelName: deviceTypes[i].modelName }
            }

            this.deviceService.getDevicesLimited(this.accountId, this.resellerId, false, false, null, true).subscribe(devices => {

                for (let i = 0, n = devices.length; i < n; ++i) {
                    if (devices[i].asset && devices[i].isActive && !devices[i].isArchived) {
                        continue;
                    }

                    if (this.gridSelection.find(x => +x === <any>devices[i].id)) {
                        continue;
                    }

                    const deviceList = this.devicesToChoose[devices[i].resellerId] || [];
                    deviceList.push({ unitId: devices[i].unitId, id: devices[i].id });
                    this.deviceDictionary[devices[i].id] = { unitId: devices[i].unitId, modelName: this.hardwareDictionary[devices[i].deviceTypeId]?.modelName, companyName: devices[i].companyName ?? 'Not activated' };
                    this.devicesToChoose[devices[i].resellerId] = deviceList;
                }

                this.loadingDevices = false;
                this.cd.markForCheck();
            });
        });
    }

    initForm() {
        this.step = 1;
        console.log('init tab');
    }
}
