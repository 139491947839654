<section class="main-preview-player">
    <video #target id="preview-player" class="main-preview-player video-js" controls preload="auto"
        crossorigin="anonymous">
        <p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that
            <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
        </p>
    </video>

    <div class="playlist-container" [hidden]="playlist.length < 2">
        <ul class="vjs-playlist" id="plylst_upcming"></ul>
        <span style="clear:both"></span>
    </div>
</section>