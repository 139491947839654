<div class="ibox-content slick" style="position:relative;">
    <ngx-loading [show]="loading || loadingDmv"></ngx-loading>
    <p class="alert alert-warning" *ngIf="formMode != 1 && dmvMessage" style="margin-bottom: 0;">
        {{ dmvMessage }}
    </p>

    <table style="width:100%" class="table nowrap dtr-inline no-footer table-fixed">
        <tbody *ngIf="!(asset && asset.id) && formMode == 1">
            <tr>
                <td>
                    {{ 'general.noAsset' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="(asset && asset.id) || formMode != 1">
            <tr [hidden]="!permissions['Resellers_View']">
                <td class="row_opening">
                    {{ 'general.resellerDescription' | translate}}
                </td>
                <td class="padding-readonly-correct row_content">
                    <a class="secondary link_bolder"
                        href="/#/ResellerDetails/Index/{{asset?.resellerId}}">{{asset?.resellerDescription }}</a>
                </td>
            </tr>
            <tr *ngIf="formMode != 2" [hidden]="!permissions['Accounts_View']">
                <td class="row_opening">
                    {{ 'general.companyName' | translate}}
                </td>
                <td class="padding-readonly-correct row_content">
                    <a class="secondary link_bolder"
                        href="/#/AccountDetails/Index/{{asset?.accountId}}">{{asset?.companyName }}</a>
                </td>
            </tr>
            <tr *ngIf="formMode == 2" [hidden]="!permissions['Accounts_View'] && asset.accountId">
                <td class="row_opening">
                    {{ 'general.companyName' | translate}}

                    <span class="error" *ngIf=" accountVar?.invalid">*</span>
                </td>
                <td class="row_content" style="position:relative; overflow: visible">
                    <fh-account-input name="account" #accountVar="ngModel" (onChanged)="accountChanged($event)" required
                        [autoSelect]="false" [selectedResellerId]="asset.resellerId" [clearable]="false"
                        [tableInline]="true"
                        placeholder="{{ (asset.resellerId? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                        [(ngModel)]="asset.accountId">
                    </fh-account-input>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.name' | translate}}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <fh-copyinput [hide]="formMode != 1">
                        <input body type="text" [readonly]="formMode == 1" name="name" class="form-control table-inline"
                            placeholder="{{ 'placeholder.name' | translate}}" [(ngModel)]="asset.name" required
                            minlength="3" #name="ngModel">
                    </fh-copyinput>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.assetCode' | translate}} / {{ 'general.plate' | translate}}
                </td>
                <td class="row_content">
                    <span style="width:50%; float: left">
                        <fh-copyinput [hide]="formMode != 1">
                            <input body type="text" [readonly]="formMode == 1" name="code"
                                placeholder="{{ 'placeholder.assetCode' | translate}}" class="form-control table-inline"
                                [(ngModel)]="asset.code" #code="ngModel">
                        </fh-copyinput>
                    </span>
                    <span style="width:50%; float: left; padding-left: 4px">
                        <fh-copyinput [hide]="formMode != 1">
                            <input body type="text" [readonly]="formMode == 1" name="plate"
                                class="form-control table-inline" placeholder="{{ 'placeholder.plate' | translate}}"
                                [(ngModel)]="asset.plateNumber" #plateNumber="ngModel">
                        </fh-copyinput>
                    </span>
                    <i *ngIf="formMode != 1 && asset.plateNumber && asset.plateNumber != ''"
                        tooltip="Get asset information from DMV" class="fa fa-fw fa-satellite-dish"
                        style="right: 22px; position: relative; cursor: pointer;"
                        (click)="getInformationDMV(asset.plateNumber)"></i>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.assetDateStart' | translate}}
                </td>
                <td class="row_content" *ngIf="formMode == 2">
                    <input type="text" name="assignmentStart" [bsConfig]="dpConfig"
                        placeholder="{{ 'placeholder.assignmentStart' | translate}}" bsDatepicker
                        [(ngModel)]="asset.assignmentStart" class="form-control table-inline">
                    <span *ngIf="formMode == 2" class="input-group-calendar">
                        <i class="fa fa-calendar-alt"></i></span>
                </td>
                <td class="row_content" *ngIf="formMode != 2">
                    <input type="text" readonly name="endDate" class="form-control table-inline"
                        [title]="asset?.assignmentStart" [value]="asset.assignmentStart | amDateFormat: 'lll'">
                </td>
            </tr>
            <tr *ngIf="asset?.assignmentEnd" class="elipses_overflow">
                <td [class.text-danger]="asset?.assignmentEnd" class="elipses_overflow">
                    {{ 'general.endDate' | translate}}
                </td>
                <td [class.text-danger]="asset?.assignmentEnd">
                    <input type="text" readonly name="endDate" class="form-control table-inline"
                        [title]="asset?.assignmentEnd" [value]="asset.assignmentEnd | amDateFormat: 'lll'">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.color' | translate}}
                    <span class="error" *ngIf="color.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="color5" class="form-control table-inline"
                        placeholder="{{ 'placeholder.color' | translate}}" [(ngModel)]="asset.color" #color="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.year' | translate}}
                    <span class="error" *ngIf="year.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="year" class="form-control table-inline"
                        placeholder="{{ 'placeholder.year' | translate}}" [(ngModel)]="asset.year" #year="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.vin' | translate}}
                    <span class="error" *ngIf="vin.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="vin" class="form-control table-inline"
                        placeholder="{{ 'placeholder.vin' | translate}}" [(ngModel)]="asset.vin" #vin="ngModel">
                    <i *ngIf="formMode != 1 && asset.vin && asset.vin != ''"
                        tooltip="Get asset information from DMV (Only available in the U.S.)"
                        class="fa fa-fw fa-satellite-dish"
                        style="right: 6px; position: relative; cursor: pointer; float: right; bottom: 18px;"
                        (click)="getInformationDMV(asset.vin)"></i>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.assetBrand' | translate}} / {{ 'general.assetModel' | translate}}
                    <span class="error" *ngIf="brand.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; float: left"> <input type="text" [readonly]="formMode == 1" name="brand"
                            placeholder="{{ 'placeholder.assetBrand' | translate}}" class="form-control table-inline"
                            [(ngModel)]="asset.brand" #brand="ngModel"></span>
                    <span style="width:50%; float: left; padding-left: 4px"> <input type="text"
                            [readonly]="formMode == 1" name="model" class="form-control table-inline"
                            placeholder="{{ 'placeholder.assetModel' | translate}}" [(ngModel)]="asset.model"
                            #model="ngModel"></span>
                </td>
            </tr>
            <ng-container *ngIf="permissions['Modules_HasPrivateFleet']">
                <tr *ngIf="formMode == 1">
                    <td class="row_opening">
                        {{ 'general.garage' | translate}}
                    </td>
                    <td class="row_content">
                        <input type="text" name="garage" disabled="disabled" class="form-control table-inline"
                            placeholder="{{ 'placeholder.garage' | translate}}" value="{{ asset.garageName }}">
                    </td>
                </tr>
                <tr *ngIf="formMode != 1">
                    <td class="row_opening">
                        {{ 'general.garage' | translate}}
                        <span class="error" *ngIf="assetGarageVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="position: relative; overflow: visible">
                        <fh-garage-selector name="garages" #assetGarageVar="ngModel"
                            [placeholder]="'general.chooseGarage' | translate" [(ngModel)]="asset.garageId">
                        </fh-garage-selector>
                    </td>
                </tr>
            </ng-container>
            <tr *ngIf="formMode == 1">
                <td class="row_opening">
                    {{ 'general.assetType' | translate}}
                </td>
                <td class="row_content">
                    <input type="text" name="vehicleType" disabled="disabled" class="form-control table-inline"
                        value="{{ asset.assetTypeName }}">
                </td>
            </tr>
            <tr *ngIf="formMode != 1">
                <td class="row_opening">
                    {{ 'general.assetType' | translate}}

                    <span class="error" *ngIf="assetTypeVar.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="position:relative; overflow: visible">
                    <fh-asset-type-input name="assetType" #assetTypeVar="ngModel" required
                        [selectedAccountId]="asset.accountId"
                        placeholder="{{ (asset.accountId? 'general.chooseAssetType' : 'general.firstChooseAccount') | translate}}"
                        [(ngModel)]="asset.assetTypeId">
                    </fh-asset-type-input>
                </td>
            </tr>
            <tr *ngIf="formMode == 1">
                <td class="row_opening">
                    {{ 'general.vehicleType' | translate}}
                </td>
                <td class="row_content">
                    <input type="text" name="vehicleType" disabled="disabled" class="form-control table-inline"
                        value="{{ ('enums.vehicleType.' + asset.vehicleType) | translate }}">
                </td>
            </tr>
            <tr *ngIf="formMode != 1">
                <td class="row_opening">
                    {{ 'general.vehicleType' | translate}}
                    <span class="error" *ngIf="vehicleType.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select [disabled]="formMode == 1" placeholder="{{ 'general.chooseVehicleType' | translate}}"
                        [clearable]="false" class="form-control table-inline" name="vehicleType" required
                        #vehicleType="ngModel" [(ngModel)]="asset.vehicleType" style="width:100%">
                        <ng-option *ngFor="let vehicleType of vehicleTypes" [value]="vehicleType.id">
                            {{ ('enums.vehicleType.' + vehicleType.id) | translate }}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.icon' | translate}}
                </td>
                <td class="row_content" style="overflow:visible">
                    <fh-asset-icon-chooser [iconId]="asset.icon" [formMode]="formMode"
                        (onIconChanged)="asset.icon = $event">
                    </fh-asset-icon-chooser>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="background-color: unset; padding: 2px">
                    <textarea name="note" [readonly]="formMode == 1" name="note" class="noresize stickypad"
                        style="height:100px; width:100%; resize: none" id="encCss"
                        placeholder="{{ 'placeholder.comments' | translate}}" [(ngModel)]="asset.comment"></textarea>

                </td>
            </tr>
        </tbody>
        <tbody *ngIf="((asset && asset.id) || formMode != 1) && showProp" style="border-top: none;">
            <tr>
                <td class="row_opening">
                    {{ 'general.owner' | translate}}
                    <span class="error" *ngIf="owner.invalid && formMode != 1">*</span> /
                    {{ 'general.ownership' | translate}}
                </td>
                <td class="row_content" style="overflow: unset;">
                    <span style="width:50%; display: inline-block">
                        <input type="text" [readonly]="formMode == 1" name="owner" class="form-control table-inline"
                            placeholder="{{ 'placeholder.owner' | translate}}" [(ngModel)]="asset.owner"
                            #owner="ngModel">
                    </span>
                    <span style="width:50%; display: inline-block; padding-left: 10px;">
                        <ng-container *ngIf="formMode == 1; else ownerShipComponent">
                            <input type="text" readonly name="ownership" class="form-control table-inline"
                                [value]="asset.ownership ?? ''">
                        </ng-container>
                        <ng-template #ownerShipComponent>
                            <ng-select #ownership [addTag]="true" name="ownership"
                                class="form-control table-inline ng-select-unset" style="width:100%" ngDefaultControl
                                [(ngModel)]="asset.ownership">
                                <ng-option [value]="'Rental'">Rental</ng-option>
                                <ng-option [value]="'Owned'">Owned</ng-option>
                                <ng-option [value]="'Leased'">Leased</ng-option>
                            </ng-select>
                        </ng-template>
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.clientAssetCategory' | translate}}
                    <span class="error" *ngIf="clientAssetCategory.invalid && formMode != 1">*</span> /
                    {{ 'general.clientAssetSubCategory' | translate}}
                    <span class="error" *ngIf="clientAssetSubCategory.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; display: inline-block">
                        <input type="text" [readonly]="formMode == 1" name="clientAssetCategory"
                            class="form-control table-inline"
                            placeholder="{{ 'placeholder.clientAssetCategory' | translate}}"
                            [(ngModel)]="asset.clientAssetCategory" #clientAssetCategory="ngModel">
                    </span>
                    <span style="width:50%; display: inline-block; padding-left: 10px">
                        <input type="text" [readonly]="formMode == 1" name="clientAssetSubCategory"
                            class="form-control table-inline"
                            placeholder="{{ 'placeholder.clientAssetSubCategory' | translate}}"
                            [(ngModel)]="asset.clientAssetSubCategory" #clientAssetSubCategory="ngModel">
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.fuelType' | translate}}
                </td>
                <td class="row_content" style="overflow: unset;">
                    <ng-container *ngIf="formMode == 1; else fuelTypeComponent">
                        <input type="text" readonly name="fuelType" class="form-control table-inline"
                            [value]="asset.fuelType ?? ''">
                    </ng-container>
                    <ng-template #fuelTypeComponent>
                        <ng-select #fuelType [addTag]="true" name="fuelType"
                            class="form-control table-inline ng-select-unset" style="width:100%" ngDefaultControl
                            [(ngModel)]="asset.fuelType">
                            <ng-option [value]="'Diesel'">Diesel</ng-option>
                            <ng-option [value]="'Petrol'">Petrol</ng-option>
                            <ng-option [value]="'Electricity'">Electricity</ng-option>
                            <ng-option [value]="'Hydrogen'">Hydrogen</ng-option>
                            <ng-option [value]="'Hybrid'">Hybrid</ng-option>
                            <ng-option [value]="'Biofuel'">Biofuel</ng-option>
                            <ng-option [value]="'Gas'">Gas</ng-option>
                        </ng-select>
                    </ng-template>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.capacity' | translate}}
                    <span class="error" *ngIf="capacity.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="capacity" class="form-control table-inline"
                        placeholder="{{ 'placeholder.capacity' | translate}}" [(ngModel)]="asset.capacity"
                        #capacity="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.bodyCode' | translate}}
                    <span class="error" *ngIf="bodyCode.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="bodyCode" class="form-control table-inline"
                        placeholder="{{ 'placeholder.bodyCode' | translate}}" [(ngModel)]="asset.bodyCode"
                        #bodyCode="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.engineSerialNumber' | translate}}
                    <span class="error" *ngIf="engineSerialNumber.invalid && formMode != 1">*</span> /
                    {{ 'general.engineType' | translate}}
                    <span class="error" *ngIf="engineType.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; display: inline-block">
                        <input type="text" [readonly]="formMode == 1" name="engineSerialNumber"
                            class="form-control table-inline"
                            placeholder="{{ 'placeholder.engineSerialNumber' | translate}}"
                            [(ngModel)]="asset.engineSerialNumber" #engineSerialNumber="ngModel">
                    </span>
                    <span style="width:50%; display: inline-block; padding-left: 10px">
                        <input type="text" [readonly]="formMode == 1" name="engineType"
                            class="form-control table-inline" placeholder="{{ 'placeholder.engineType' | translate}}"
                            [(ngModel)]="asset.engineType" #engineType="ngModel">
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.costCenter' | translate}}
                    <span class="error" *ngIf="costCenter.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="costCenter" class="form-control table-inline"
                        placeholder="{{ 'placeholder.costCenter' | translate}}" [(ngModel)]="asset.costCenter"
                        #costCenter="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.madeIn' | translate}}
                    <span class="error" *ngIf="madeIn.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="madeIn" class="form-control table-inline"
                        placeholder="{{ 'placeholder.madeIn' | translate}}" [(ngModel)]="asset.madeIn"
                        #madeIn="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.plateMunicipality' | translate}}
                    <span class="error" *ngIf="plateMunicipality.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="plateMunicipality"
                        class="form-control table-inline" placeholder="{{ 'placeholder.plateMunicipality' | translate}}"
                        [(ngModel)]="asset.plateMunicipality" #plateMunicipality="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.currentValue' | translate}}
                    <span class="error" *ngIf="currentValue.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="currentValue" class="form-control table-inline"
                        placeholder="{{ 'placeholder.currentValue' | translate}}" [(ngModel)]="asset.currentValue"
                        #currentValue="ngModel" pattern="\d+\.?\d*" title="Only numbers and 1 dot">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.dateAcquired' | translate}}
                    <span class="error" *ngIf="dateAcquired.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [disabled]="formMode == 1" name="dateAcquired" #dateAcquired="ngModel"
                        class="form-control table-inline" [(ngModel)]="asset.dateAcquired" autocomplete="off"
                        [bsConfig]="dpConfig" placeholder="{{ 'placeholder.dateAcquired' | translate}}" bsDatepicker>
                    <span *ngIf="formMode != 1" class="input-group-calendar"><i class="fa fa-calendar-alt"></i></span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.dealerName' | translate}}
                    <span class="error" *ngIf="dealerName.invalid && formMode != 1">*</span> /
                    {{ 'general.purchasePrice' | translate}}
                    <span class="error" *ngIf="purchasePrice.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; display: inline-block">
                        <input type="text" [readonly]="formMode == 1" name="dealerName"
                            class="form-control table-inline" placeholder="{{ 'placeholder.dealerName' | translate}}"
                            [(ngModel)]="asset.dealerName" #dealerName="ngModel">
                    </span>
                    <span style="width:50%; display: inline-block; padding-left: 10px">
                        <input type="text" [readonly]="formMode == 1" name="purchasePrice"
                            class="form-control table-inline" placeholder="{{ 'placeholder.purchasePrice' | translate}}"
                            [(ngModel)]="asset.purchasePrice" #purchasePrice="ngModel" pattern="\d+\.?\d*"
                            title="Only numbers and 1 dot">
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.monthlyInsurance' | translate}}
                    <span class="error" *ngIf="monthlyInsurance.invalid && formMode != 1">*</span> /
                    {{ 'general.monthlyRoadTax' | translate}}
                    <span class="error" *ngIf="monthlyRoadTax.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; display: inline-block">
                        <input type="text" [readonly]="formMode == 1" name="monthlyInsurance"
                            class="form-control table-inline"
                            placeholder="{{ 'placeholder.monthlyInsurance' | translate}}"
                            [(ngModel)]="asset.monthlyInsurance" #monthlyInsurance="ngModel" pattern="\d+\.?\d*"
                            title="Only numbers and 1 dot">
                    </span>
                    <span style="width:50%; display: inline-block; padding-left: 10px">
                        <input type="text" [readonly]="formMode == 1" name="monthlyRoadTax"
                            class="form-control table-inline"
                            placeholder="{{ 'placeholder.monthlyRoadTax' | translate}}"
                            [(ngModel)]="asset.monthlyRoadTax" #monthlyRoadTax="ngModel" pattern="\d+\.?\d*"
                            title="Only numbers and 1 dot">
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.totalDepreciation' | translate}}
                    <span class="error" *ngIf="totalDepreciation.invalid && formMode != 1">*</span> /
                    {{ 'general.dateDepreciation' | translate}}
                    <span class="error" *ngIf="dateDepreciation.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; display: inline-block">
                        <input type="text" [readonly]="formMode == 1" name="totalDepreciation"
                            class="form-control table-inline"
                            placeholder="{{ 'placeholder.totalDepreciation' | translate}}"
                            [(ngModel)]="asset.totalDepreciation" #totalDepreciation="ngModel" pattern="\d+\.?\d*"
                            title="Only numbers and 1 dot">
                    </span>
                    <span style="width:50%; display: inline-block; padding-left: 10px">
                        <input type="text" [disabled]="formMode == 1" name="dateDepreciation"
                            #dateDepreciation="ngModel" class="form-control table-inline"
                            [(ngModel)]="asset.dateDepreciation" autocomplete="off" [bsConfig]="dpConfig"
                            placeholder="{{ 'placeholder.dateDepreciation' | translate}}" bsDatepicker>
                        <span *ngIf="formMode != 1" class="input-group-calendar">
                            <i class="fa fa-calendar-alt"></i></span>
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.dateSold' | translate}}
                    <span class="error" *ngIf="dateSold.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [disabled]="formMode == 1" name="dateSold" #dateSold="ngModel"
                        class="form-control table-inline" [(ngModel)]="asset.dateSold" autocomplete="off"
                        [bsConfig]="dpConfig" placeholder="{{ 'placeholder.dateSold' | translate}}" bsDatepicker>
                    <span *ngIf="formMode != 1" class="input-group-calendar"><i class="fa fa-calendar-alt"></i></span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.soldTo' | translate}}
                    <span class="error" *ngIf="soldTo.invalid && formMode != 1">*</span> /
                    {{ 'general.soldPrice' | translate}}
                    <span class="error" *ngIf="soldPrice.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; display: inline-block">
                        <input type="text" [readonly]="formMode == 1" name="soldTo" class="form-control table-inline"
                            placeholder="{{ 'placeholder.soldTo' | translate}}" [(ngModel)]="asset.soldTo"
                            #soldTo="ngModel">
                    </span>
                    <span style="width:50%; display: inline-block; padding-left: 10px">
                        <input type="text" [readonly]="formMode == 1" name="soldPrice" class="form-control table-inline"
                            placeholder="{{ 'placeholder.soldPrice' | translate}}" [(ngModel)]="asset.soldPrice"
                            #soldPrice="ngModel" pattern="\d+\.?\d*" title="Only numbers and 1 dot">
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.insurancePolicy' | translate}}
                    <span class="error" *ngIf="insurancePolicy.invalid && formMode != 1">*</span> /
                    {{ 'general.dateExpireInsurance' | translate}}
                    <span class="error" *ngIf="dateExpireInsurance.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; display: inline-block">
                        <input type="text" [readonly]="formMode == 1" name="insurancePolicy"
                            class="form-control table-inline"
                            placeholder="{{ 'placeholder.insurancePolicy' | translate}}"
                            [(ngModel)]="asset.insurancePolicy" #insurancePolicy="ngModel">
                    </span>
                    <span style="width:50%; display: inline-block; padding-left: 10px">
                        <input type="text" [disabled]="formMode == 1" name="dateExpireInsurance"
                            #dateExpireInsurance="ngModel" class="form-control table-inline"
                            [(ngModel)]="asset.dateExpireInsurance" autocomplete="off" [bsConfig]="dpConfig"
                            placeholder="{{ 'placeholder.dateExpireInsurance' | translate}}" bsDatepicker>
                        <span *ngIf="formMode != 1" class="input-group-calendar">
                            <i class="fa fa-calendar-alt"></i></span>
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.registrationNumber' | translate}}
                    <span class="error" *ngIf="registrationNumber.invalid && formMode != 1">*</span> /
                    {{ 'general.dateExpireRegistration' | translate}}
                    <span class="error" *ngIf="dateExpireRegistration.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; display: inline-block">
                        <input type="text" [readonly]="formMode == 1" name="registrationNumber"
                            class="form-control table-inline"
                            placeholder="{{ 'placeholder.registrationNumber' | translate}}"
                            [(ngModel)]="asset.registrationNumber" #registrationNumber="ngModel">
                    </span>
                    <span style="width:50%; display: inline-block; padding-left: 10px">
                        <input type="text" [disabled]="formMode == 1" name="dateExpireRegistration"
                            #dateExpireRegistration="ngModel" class="form-control table-inline"
                            [(ngModel)]="asset.dateExpireRegistration" autocomplete="off" [bsConfig]="dpConfig"
                            placeholder="{{ 'placeholder.dateExpireRegistration' | translate}}" bsDatepicker>
                        <span *ngIf="formMode != 1" class="input-group-calendar">
                            <i class="fa fa-calendar-alt"></i></span>
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.permitOne' | translate}}
                    <span class="error" *ngIf="permitOne.invalid && formMode != 1">*</span> /
                    {{ 'general.permitOneExpirationDate' | translate}}
                    <span class="error" *ngIf="permitOneExpirationDate.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; display: inline-block">
                        <input type="text" [readonly]="formMode == 1" name="permitOne" class="form-control table-inline"
                            placeholder="{{ 'placeholder.permitOne' | translate}}" [(ngModel)]="asset.permitOne"
                            #permitOne="ngModel">
                    </span>
                    <span style="width:50%; display: inline-block; padding-left: 10px">
                        <input type="text" [disabled]="formMode == 1" name="permitOneExpirationDate"
                            #permitOneExpirationDate="ngModel" class="form-control table-inline"
                            [(ngModel)]="asset.permitOneExpirationDate" autocomplete="off" [bsConfig]="dpConfig"
                            placeholder="{{ 'placeholder.permitOneExpirationDate' | translate}}" bsDatepicker>
                        <span *ngIf="formMode != 1" class="input-group-calendar">
                            <i class="fa fa-calendar-alt"></i></span>
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.permitTwo' | translate}}
                    <span class="error" *ngIf="permitTwo.invalid && formMode != 1">*</span> /
                    {{ 'general.permitTwoExpirationDate' | translate}}
                    <span class="error" *ngIf="permitTwoExpirationDate.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; display: inline-block">
                        <input type="text" [readonly]="formMode == 1" name="permitTwo" class="form-control table-inline"
                            placeholder="{{ 'placeholder.permitTwo' | translate}}" [(ngModel)]="asset.permitTwo"
                            #permitTwo="ngModel">
                    </span>
                    <span style="width:50%; display: inline-block; padding-left: 10px">
                        <input type="text" [disabled]="formMode == 1" name="permitTwoExpirationDate"
                            #permitTwoExpirationDate="ngModel" class="form-control table-inline"
                            [(ngModel)]="asset.permitTwoExpirationDate" autocomplete="off" [bsConfig]="dpConfig"
                            placeholder="{{ 'placeholder.permitTwoExpirationDate' | translate}}" bsDatepicker>
                        <span *ngIf="formMode != 1" class="input-group-calendar">
                            <i class="fa fa-calendar-alt"></i></span>
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.safetyCertificate' | translate}}
                    <span class="error" *ngIf="safetyCertificate.invalid && formMode != 1">*</span> /
                    {{ 'general.safetyCertificateExpirationDate' | translate}}
                    <span class="error" *ngIf="safetyCertificateExpirationDate.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; display: inline-block">
                        <input type="text" [readonly]="formMode == 1" name="safetyCertificate"
                            class="form-control table-inline"
                            placeholder="{{ 'placeholder.safetyCertificate' | translate}}"
                            [(ngModel)]="asset.safetyCertificate" #safetyCertificate="ngModel">
                    </span>
                    <span style="width:50%; display: inline-block; padding-left: 10px">
                        <input type="text" [disabled]="formMode == 1" name="safetyCertificateExpirationDate"
                            #safetyCertificateExpirationDate="ngModel" class="form-control table-inline"
                            [(ngModel)]="asset.safetyCertificateExpirationDate" autocomplete="off" [bsConfig]="dpConfig"
                            placeholder="{{ 'placeholder.safetyCertificateExpirationDate' | translate}}" bsDatepicker>
                        <span *ngIf="formMode != 1" class="input-group-calendar">
                            <i class="fa fa-calendar-alt"></i></span>
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.safetyTestedBy' | translate}}
                    <span class="error" *ngIf="safetyTestedBy.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="safetyTestedBy"
                        class="form-control table-inline" placeholder="{{ 'placeholder.safetyTestedBy' | translate}}"
                        [(ngModel)]="asset.safetyTestedBy" #safetyTestedBy="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.erpCode' | translate}}
                    <span class="error" *ngIf="erpCode.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="erpCode" class="form-control table-inline"
                        placeholder="{{ 'placeholder.erpCode' | translate}}" [(ngModel)]="asset.erpCode"
                        #erpCode="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.branding' | translate}}
                    <span class="error" *ngIf="branding.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="branding" class="form-control table-inline"
                        placeholder="{{ 'placeholder.branding' | translate}}" [(ngModel)]="asset.branding"
                        #branding="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.equipment' | translate}}
                    <span class="error" *ngIf="equipment.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="equipment" class="form-control table-inline"
                        placeholder="{{ 'placeholder.equipment' | translate}}" [(ngModel)]="asset.equipment"
                        #equipment="ngModel">

                    <input type="hidden" name="mixedConsumption" [(ngModel)]="asset.mixedConsumption"
                        #equipment="ngModel">
                </td>
            </tr>

            <tr>
                <td class="row_opening">
                    {{ 'general.emissionCO2' | translate}}
                    <span class="error" *ngIf="emissionCO2.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="emissionCO2" class="form-control table-inline"
                        placeholder="{{ 'placeholder.emissionCO2' | translate}}" [(ngModel)]="asset.emissionCO2"
                        #emissionCO2="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.emissionParticlesLight' | translate}}
                    <span class="error" *ngIf="emissionParticlesLight.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="emissionParticlesLight"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.emissionParticlesLight' | translate}}"
                        [(ngModel)]="asset.emissionParticlesLight" #emissionParticlesLight="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.emissionParticlesHeavy' | translate}}
                    <span class="error" *ngIf="emissionParticlesHeavy.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="emissionParticlesHeavy"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.emissionParticlesHeavy' | translate}}"
                        [(ngModel)]="asset.emissionParticlesHeavy" #emissionParticlesHeavy="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.urbanConsumption' | translate}}
                    <span class="error" *ngIf="urbanConsumption.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="urbanConsumption"
                        class="form-control table-inline" placeholder="{{ 'placeholder.urbanConsumption' | translate}}"
                        [(ngModel)]="asset.urbanConsumption" #urbanConsumption="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.extraUrbanConsumption' | translate}}
                    <span class="error" *ngIf="extraUrbanConsumption.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="extraUrbanConsumption"
                        class="form-control table-inline"
                        placeholder="{{ 'placeholder.extraUrbanConsumption' | translate}}"
                        [(ngModel)]="asset.extraUrbanConsumption" #extraUrbanConsumption="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.mixedConsumption' | translate}}
                    <span class="error" *ngIf="mixedConsumption.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="mixedConsumption"
                        class="form-control table-inline" placeholder="{{ 'placeholder.mixedConsumption' | translate}}"
                        [(ngModel)]="asset.mixedConsumption" #mixedConsumption="ngModel">
                </td>
            </tr>
        </tbody>
    </table>
</div>