<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="warehouse?.accountId">
                    <a href="/#/AccountDetails/Index/{{warehouse?.accountId}}">{{warehouse?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Warehouses/Overview">{{'general.warehouses' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/WarehouseDetails/Index/{{warehouse?.id}}">{{warehouse?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.dashboard' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-warehouse-details-navigation [warehouseId]="warehouseId"></fh-warehouse-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">

                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-warehouse"></i> {{'general.warehouse' | translate }}
                            </h5>
                        </div>
                        <fh-warehouse-details [warehouse]="warehouse" [warehouseAzure]="warehouseAzure"
                            [formMode]="formMode" [loading]="loading || saving">
                        </fh-warehouse-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()" (onDelete)="onDelete()"
                            [allowEdit]="permissions['Warehouses_Edit']"
                            [allowDelete]="permissions['Warehouses_Delete']" (setFormMode)="setFormMode($event)"
                            [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>

                <fh-tagging [objectId]="warehouse?.id" [objectType]="tagType">
                </fh-tagging>

            </div>
            <div class="col-lg-6">
                <div class="ibox" style="position:relative" *ngIf="zoneData.length == 0">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-border-none"></i> {{'general.zones' | translate }}
                        </h5>

                        <span class="float-right"
                            tooltip="{{'general.updated' | translate }} {{ previousLookupDisplay | amDateFormat: 'LLL' }}">
                            <i style="margin-left: 7px; margin-top: 16px; margin-right: 4px; font-size: 20px;"
                                class="fa fa-fw"
                                [ngClass]="isSpinning ? 'fa-spinner fa-spin eye-active green' : 'fa-cloud-check'"></i>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative;">
                        <ngx-loading [show]="loadingZoneData"></ngx-loading>

                        <div style="padding:10px" *ngIf="loadingZoneData">Fetching zone data</div>
                        <div style="padding:10px" *ngIf="!loadingZoneData && zoneData.length == 0"> {{
                            'general.noSensorData' | translate}}</div>
                    </div>
                </div>

                <div class="ibox" style="position:relative"
                    *ngFor="let data of zoneData | orderBy: 'zoneName': false ; let i = index">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-border-none"></i> {{data.zoneName}}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative;">
                        <div class="flex-container">
                            <div class="col-lg-4 col-sm-12" style="padding: 0;">
                                <div class="kpi-item border-active " [ngClass]="data?.markerColor">
                                    <h3 class="margin-bottom font-bold"
                                        title="{{ data.maxTimestamp | amDateFormat: 'LLL'}}">
                                        <i class="fa fa-signal"
                                            [style.color]="data?.markerColor == 'green' ? '#0a8a15' : (data?.markerColor == 'orange' ? '#d48113' : '#b81313')"></i>
                                        {{ data.maxTimestamp | amTimeAgo}}
                                    </h3>
                                    <div title="{{ data.maxTimestamp | amDateFormat: 'LLL'}}">{{
                                        'general.lastCommunication' | translate}}</div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-12" style="padding: 0;">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">

                                        <i class="fas fa-fw fa-sensor"
                                            [style.color]="data.sensorViolationCount == 0 ? '#0a8a15' : '#b81313'"
                                            title="{{ 'general.sensorCount' | translate}} / {{ 'general.violations' | translate}}"></i>
                                        {{ data.sensorCount}} / {{ data.sensorViolationCount}}
                                    </h3>
                                    <div>{{ 'general.sensorCount' | translate}} / {{ 'general.violations' | translate}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-12" style="padding: 0;">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold"
                                        title="Accepted range: {{ data.lowerTemperatureThreshold }}°C - {{data.upperTemperatureThreshold}}°C">

                                        <i class="fas fa-fw fa-thermometer-three-quarters" title="{{ 'general.temperature' | translate}}"></i>
                                        {{ actualRound(data.avgTemperature,1) }} °C
                                    </h3>
                                    <div>{{ 'general.temperature' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-12" style="padding: 0;">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold"
                                        title="Accepted range: {{ data.lowerHumidityThreshold }}% - {{data.upperHumidityThreshold}}%">

                                        <i class="fas fa-fw fa-humidity" title="{{ 'general.humidity' | translate}}"></i>
                                        {{ actualRound(data.avgHumidity,0) }} %
                                    </h3>
                                    <div>{{ 'general.humidity' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-12" style="padding: 0;" *ngIf="data.avgRssi">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i style="color:#0a8a15" *ngIf="data.avgRssi < 30" class="fas fa-fw fa-signal" title="{{ 'general.signal' | translate}}"></i>
                                        <i style="color:#0a8a15" *ngIf="data.avgRssi > 29 && data.avgRssi < 67" class="fas fa-fw fa-signal-strong" title="{{ 'general.signal' | translate}}"></i>
                                        <i style="color:#d48113" *ngIf="data.avgRssi > 66 && data.avgRssi < 70" class="fas fa-fw fa-signal-good" title="{{ 'general.signal' | translate}}"></i>
                                        <i style="color:#b81313" *ngIf="data.avgRssi > 69 && data.avgRssi < 80" class="fas fa-fw fa-signal-fair" title="{{ 'general.signal' | translate}}"></i>
                                        <i style="color:#b81313" *ngIf="data.avgRssi > 79" class="fas fa-fw fa-weak" title="{{ 'general.signal' | translate}}"></i>
                                        {{ actualRound(data.avgRssi,0) }} dBm
                                    </h3>
                                    <div>{{ 'general.signal' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-12" style="padding: 0;" *ngIf="data.minPower">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i style="color: #0a8a15" *ngIf="data.minPower > 79" class="fas fa-fw fa-battery-full" title="{{ 'general.battery' | translate}}"></i>
                                        <i style="color:#d48113" *ngIf="data.minPower > 24 && data.minPower < 79" class="fas fa-fw fa-battery-half" title="{{ 'general.battery' | translate}}"></i>
                                        <i style="color:#b81313" *ngIf="data.minPower < 25" class="fas fa-fw fa-battery-low" title="{{ 'general.battery' | translate}}"></i>
                                        {{ actualRound(data.minPower,0) }} %
                                    </h3>
                                    <div>{{ 'general.battery' | translate }}</div>
                                </div>
                            </div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>

                <div class="ibox">
                    <fh-leaflet-map [editableGeofence]="geofence" [height]="450" [allowEdit]="allowEdit"
                        [isSpinning]="isSpinning" [showSearch]=true [allowBookmarks]=false
                        (onSave)="onSaveShape($event)">
                        <div header class="ibox-title">
                            <span class="pull-left">
                                <h5>
                                    <i class="fa fa-fw fa-map-marked-alt"></i> {{'general.map' | translate }}
                                </h5>
                            </span>
                        </div>
                    </fh-leaflet-map>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>