import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getGridButtons, getGridLanguages, createdCellCheckbox, getDefaultDpConfig, createdCellMaxWidth, createdCellCheckboxWithId } from 'app/common/gridhelper';

import { AuthenticationService } from '../../services/authentication/authentication.service';

import { Observable, forkJoin } from 'rxjs';
import { FuelService } from 'app/services/fuel/fuel.service';

import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { GridBase360Directive } from 'app/common/360Grid.base';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { StorageHelper } from 'app/common/storagehelper';
import { ColorService } from 'app/services/common/color.service';

window['moment'] = Moment;
mTZ();

@Component({
  selector: 'fh-fuel',
  templateUrl: 'fuel.template.html'
})
export class FuelViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  percentage = 20;
  showConfirm = false;
  error;
  success;

  tableIds = [];
  tableIdsSelection = [];

  token: string;

  showActiveOnly = true;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  excludingColumns = ['eventTimeStamp', 'endFuelEventFuelLevelInLiters', 'endFuelEventFuelLevelInLiters'];

  permissionName = 'FuelEvents_View';
  constructorName = 'FuelViewComponent';
  timezoneIana: string;

  selectedResellerId;
  selectedAccountId;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private fuelService: FuelService, protected storageHelper: StorageHelper, private colorService: ColorService) {
    super(storageHelper);

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, 'months').startOf('day').toDate(),
      Moment().tz(this.timezoneIana).endOf('day').toDate()
    ];

    this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

    // Get all the date for dropdown boxes
    forkJoin(
      this.translateService.get('general.date')
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      err => console.error(err)
    );
  }

  resellerChanged(resellerId) {
    this.selectedAccountId = null;

    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    this.selectedAccountId = accountId;
    this.error = null;

    this.dateChanged(true);
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log('Fire update');
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

        if (this.showActiveOnly === true) {
          dtInstance.column('isActive:name').search('true');
        } else {
          dtInstance.column('isActive:name').search('@ignore');
        }

        // Fire the request
        dtInstance.draw();
      });
    }
  }

  dateChanged(event) {
    const that = this;
    if (event !== null) {
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.url(that.fuelService.getPagingUrl(moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.percentage, this.selectedAccountId)).load();
      });
    }
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  // Check custom filters from grid save
  checkFilters() {
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      this.showActiveOnly = dtInstance.column('isActive:name').search() !== '@ignore';
    });

    this.fireFilter(1);
  }

  initGrid(): void {
    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: 'id',
        data: 'id',
        className: 'noVis',
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: '20',
        render: function (data, type, row) {
          if (!that.tableIds) {
            that.tableIds = [];
          }
          if (that.tableIds.indexOf(row.id.toString()) === -1) {
            that.tableIds.push(row.id.toString());
          }

          return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/FuelDetails/Index/' + data + '\'><span class="d-none d-md-inline-flex" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
        }
      }, {
        name: 'id_export',
        data: 'id',
        className: 'noVis',
        title: this.translateService.instant('general.id'),
        visible: false,
      }, {
        name: 'eventTimeStamp',
        data: 'eventTimeStamp',
        type: 'date',
        title: this.translateService.instant('general.eventTimeStamp'),
        render: function (data, type, row) {
          const date = Moment.utc(data)['tz'](that.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
        },
      }, {
        name: 'source',
        data: 'source',
        defaultContent: '-',
        title: this.translateService.instant('general.source')
      }, {
        name: 'assetName',
        data: 'assetName',
        defaultContent: '-',
        title: this.translateService.instant('general.assetName')
      }, {
        name: 'tags',
        data: 'tags',
        defaultContent: '-',
        title: this.translateService.instant('general.tags'),
        createdCell: createdCellMaxWidth(100),
        render: function (data, type, row) {
          return data ? data.split(',').map(x => x + ' ') : '-';
        },
      }, {
        name: 'fuelingActivityInLiters',
        data: 'fuelingActivityInLiters',
        type: 'num',
        title: this.translateService.instant('general.fuelingActivityInLiters'),
        render: function (data, type, row) {
          if (type && type === 'display') {
            return data ? Math.round(data) + ' L' : '-';
          } else {
            return data;
          };
        },
      }, {
        name: 'refuelVsTheft',
        data: 'refuelVsTheft',
        iconName: 'fa fa-fw fa-gas-pump',
        type: 'select',
        options: [
          { id: '1', value: 'Theft' },
          { id: '2', value: 'Refuel' },
        ],
        title: this.translateService.instant('general.eventType'),
        render: function (data, type, row) {
          return that.translateService.instant(('enums.fuelEvent.' + data));
      },
      }, {
        name: 'certaintyInPercentage',
        data: 'certaintyInPercentage',
        type: 'num',
        title: this.translateService.instant('general.certaintyInPercentage'),
        render: function (data, type, row) {
          if (type && type === 'display') {
            return data ? Math.round(data) + '%' : '';
          } else {
            return data;
          };
        },
      }, {
        name: 'isValid',
        data: 'isValid',
        type: 'checkBox',
        visible: true,
        defaultContent: '',
        createdCell: createdCellCheckboxWithId,
        title: this.translateService.instant('general.isValid'),

      }, {
        name: 'endFuelEventFuelLevelInLiters',
        data: 'endFuelEventFuelLevelInLiters',
        type: 'num',
        title: this.translateService.instant('general.endFuelEventFuelLevelInLiters'),
        render: function (data, type, row) {
          if (type && type === 'display') {
            return data ? Math.round(data) + ' L' : '-';
          } else {
            return data;
          };
        },
      },
      // , {
      //   name: 'geofenceLabel',
      //   data: 'geofenceLabel',
      //   defaultContent: '-',
      //   title: this.translateService.instant('general.geofenceLabel')
      // },
      {
        name: 'fuelTankCapacityInLiters',
        data: 'fuelTankCapacityInLiters',
        visible: false,
        type: 'num',
        title: this.translateService.instant('general.fuelCapacity'),
        render: function (data, type, row) {
          if (type && type === 'display') {
            return data ? Math.round(data) + ' L' : '-';
          } else {
            return data;
          };
        },
      }, {
        name: 'fillupThresholdPercentage',
        data: 'fillupThresholdPercentage',
        defaultContent: '-',
        visible: false,
        type: 'num',
        title: this.translateService.instant('general.fillupThresholds'),
        render: function (data, type, row) {
          return (data != null) ? data.toLocaleString() + '% ( ' + Math.round((data / 100) * row.fuelTankCapacityInLiters) + 'L )' : '-';
        },
      }, {
        name: 'theftThresholdPercentage',
        data: 'theftThresholdPercentage',
        defaultContent: '-',
        visible: false,
        type: 'num',
        title: this.translateService.instant('general.theftThresholds'),
        render: function (data, type, row) {
          return (data != null) ? data.toLocaleString() + '% ( ' + Math.round((data / 100) * row.fuelTankCapacityInLiters) + 'L )' : '-';
        },
      }, {
        name: 'companyName',
        data: 'companyName',
        visible: false,
        defaultContent: '-',
        title: this.translateService.instant('general.companyName')
      }, {
        name: 'accountId',
        data: 'accountId',
        defaultContent: '-',
        type: 'num',
        title: this.translateService.instant('general.accountId'),
        visible: false
      }, {
        name: 'resellerDescription',
        data: 'resellerDescription',
        visible: false,
        defaultContent: '-',
        title: this.translateService.instant('general.resellerDescription')
      }, {
        name: 'auxiliaryAttachments',
        data: 'auxiliaryAttachments',
        visible: false,
        defaultContent: '-',
        title: this.translateService.instant('general.sensorTypes')
      }
    ];

    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'fuel_overview', this.translateService.instant('menu.fueloverview'), this.colorService),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => {
          try {
            const columnSettings = await that.loadState(that.constructorName);
            that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            that.error = {};
            that.error.error = e;
            that.error.statusText = 'Error fetching column settings';

            return null;
          }
        })().then(result => {
          callback(result);
        });
      },
      order: [[2, 'asc']],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        data: (d) => {
          return d;
        },
        dataSrc: function (json) {
          return json.data;
        },
        url: that.fuelService.getPagingUrl(moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.percentage, this.selectedAccountId),
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        console.log('init complete');
        that.checkFilters();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id', 'id_export']
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
      drawCallback: (data) => {
        that.setFuelOptions(that.tableIds);
      },
    };
  }

  setFuelOptions(tableIds) {
    if (tableIds) {
      tableIds.forEach(id => {
        const star = $('#flag_' + id);
        star.addClass('hand');

        star.click(() => {
          if (star.hasClass('fas')) {
            star.removeClass('fas').addClass('far');
          } else {
            star.removeClass('far').addClass('fas');
          }
          // table.changeFlag(id, star.hasClass('fas'));
        });
      });
    };
  }

  // Change flag for device
  changeFlag(id: string, isFlagged: boolean): void {
    console.log('Change flagging');
    // this.deviceService.getDeviceById(id).subscribe(device => {
    //   device.isFlagged = isFlagged;
    //   this.deviceService.saveFlagged(device).subscribe(() => { });
    // });
  }
}
