<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="device?.asset && device?.asset.accountId">
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}">{{'general.devices' | translate
                        }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.asset?.name ? device?.asset?.name :
                        device?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.episodes' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device"></fh-device-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="">
            <fh-error [error]="error" [success]="success"></fh-error>

            <div class="col-lg-12">
                <fh-episode-details [accountId]="device.accountId" [device]="device">
                </fh-episode-details>
            </div>
        </div>
    </div>
</fh-permission-handler>