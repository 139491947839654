<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/">{{'general.notifications' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/Triggers/Overview">{{'general.triggers' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/TriggerDetails/Index/{{trigger?.id}}">{{trigger?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.notifications' | translate }}</li>
            </ol>
        </nav>

        <!-- <fh-filter-toggler style="margin-left: auto;" [constructorName]="constructorName"
        (filterChanged)="showFilter = $event" (presetsChanged)="showPresets = $event">
        <a style="margin-left:2px" class="btn btn-info margin-fix d-none d-md-inline-flex" title="Help"
            href="https://help.360locate.com/knowledge-base/triggers/" target="_blank"><i
                class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span class="d-none d-md-inline-flex">{{'general.needHelp' | translate
                }}</span></a>
        </fh-filter-toggler> -->
    </div>
    <fh-notifications-navigation></fh-notifications-navigation>
</div>


<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>

    <div class="row" data-equalizer="">
        <fh-notifications-list [notifications]="notifications" [loading]="loadingNotifications"
            (onDateChanged)="dateChanged($event)" [daterangepickerModel]="daterangepickerModel" [dpConfig]="dpConfig">
        </fh-notifications-list>
    </div>
</div>