import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, Inject, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode, StorageType, EntityType } from 'app/common/enums';
import { Device, DeviceSettings } from 'app/models/device.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DeviceService } from 'app/services/device/device.service';
import { AccountService } from 'app/services/account/account.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FhChartService } from '../../services/charts/charts.service';
import { ColorService } from '../../services/common/color.service';
import { LocationService } from '../../services/locations/locations.service';
import { timer } from 'rxjs/internal/observable/timer';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { AssetGroupsService } from 'app/services/asset/assetGroups.service';
import { ConfirmationModalComponent } from '../shared/usercontrols/confirmationModal.component';
import { AssetService } from 'app/services/asset/asset.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

// Moment timezone
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { ArchivingModalComponent } from '../shared/usercontrols/archivingModal.component';
import { Asset } from 'app/models/asset.model';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { getIconPath, roundAsNumber, roundAsString, roundMinutes, roundSeconds } from 'app/common/globals';
import { TriggerService } from 'app/services/triggers/triggers.service';
import { Trigger } from 'app/models/trigger.model';
import { getDefaultDpConfig } from 'app/common/gridhelper';
import { TranslateService } from '@ngx-translate/core';
import { StorageHelper } from 'app/common/storagehelper';
import * as Highcharts from 'highcharts';
import { colorMapper } from 'app/common/leafletGlobals';
import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';
import { AssetGroupInputComponent } from 'app/modules/customInputs/assetGroupSelector.component';
import { TriggerScheduleService } from 'app/services/triggerSchedule/triggerSchedule.service';
import { ScheduleAssignment } from 'app/models/triggerSchedule.model';
import { DriverService } from 'app/services/driver/driver.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DistanceUnitService } from 'app/common/distanceunit.service';
import { SensorTemplateService } from 'app/services/sensorTemplate/sensorTemplate.service';

window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-fh-device-details',
    templateUrl: 'deviceDetails.template.html',
    providers: [FhChartService],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        fadeInOnEnterAnimation({ anchor: 'enter', duration: 1000, delay: 100 }),
    ]
})
export class DeviceDetailsViewComponent implements OnInit, OnDestroy {
    @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;
    Highcharts: typeof Highcharts = Highcharts;

    @ViewChild('confirmModal', { static: false }) confirmModal: ConfirmationModalComponent;
    @ViewChild('archiveModal', { static: false }) archiveModal: ConfirmationModalComponent;
    @ViewChild('archiveBody', { static: false }) archiveBody: ArchivingModalComponent;

    tagType = EntityType.Device.valueOf();
    chartColumnRangeEnabled: boolean;
    chartLocationCount;
    chartDistance;

    progress = [];
    hasAdvice: any;
    hasSchedule: any;
    hasNotes: any;
    renderDateTime: number;
    insertAdviseSchedule: boolean;
    deviceType: any;

    note: string;
    isFlagged: boolean;

    sub: Subscription;
    showMapOnSide = false;
    loading = false;
    saving = false;
    loadingNote = false;
    loadingFlagged = false;
    loadingLocationCount = false;
    loadingLocation = true;
    showUtilization = true;
    unmappedPorts;

    storageType = StorageType.LocalStorage;

    device: Device;
    deviceId;

    marker;

    chartlocationType;
    permissions: {};

    locationSubscription: Subscription;

    formMode = FormMode.read;
    formModeAsset = FormMode.read;

    error: any;
    warning: any;

    success: { statusText: string; success: string; };

    locationCount = [];
    locationData = [];

    geofences = [];

    scheduleAssignment: ScheduleAssignment;

    // Device state
    previousLookupTimestamp: Date;
    lastCommunication;

    loadingGroups = false;
    assetGroups = [];
    assetGroupLink = 'AssetGroupDetails';
    loadingGeofences = false;
    distanceChartData = [];
    loadingCount: boolean;
    timezoneIana: string;
    previousLookupDisplay: any;

    isSpinning = false;
    showUnMapped = false;

    permissionName = 'Devices_View';
    constructorName = 'DeviceDetailsViewComponent';

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
    showPosition: boolean;
    violations: any = [];
    daterangepickerModel: Date[];
    loadingUtilization = false;
    loadingTriggers = false;
    deviceUtilization;
    triggers: Trigger[] = [];
    hideTriggers = false;
    hideUtilization = false;
    mapHeight = 250;
    showMapInHeader = false;
    showWarningMessage = false;
    compareDate = new Date(new Date().setDate(new Date().getDate() - 3));
    deviceState: any;
    scannedDriver: any;
    colorMapper = colorMapper;

    playerOptions = {};
    playerDashcamUrls = [];
    sources = [];

    translatedKmh: any = "km/h";
    translatedKm: any = "km";

    sensorTemplates: any[];
    loadingSensorTemplates: boolean;

    hasCanbus = false;

    constructor(private authenticationService: AuthenticationService,
        private cd: ChangeDetectorRef,
        private chartService: FhChartService,
        private toastr: ToastrService,
        private driverService: DriverService,
        private deviceService: DeviceService,
        private distance: DistanceUnitService,
        private locationService: LocationService,
        private assetService: AssetService,
        private route: ActivatedRoute,
        private router: Router,
        private triggerService: TriggerService,
        private authentication: AuthenticationService,
        private accountService: AccountService,
        private assetGroupsService: AssetGroupsService,
        private translateService: TranslateService,
        private storageHelper: StorageHelper,
        private sensorTemplateService: SensorTemplateService,) {

        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.device = null;

        this.scheduleAssignment = null;

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(1, 'months').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).subtract(1, 'days').startOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authentication);

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'showMapOnSide').subscribe((result) => {
            this.showMapOnSide = JSON.parse(result) === true;

            if (this.showMapOnSide) { this.mapHeight = 330; }
            this.cd.markForCheck();
        });

        this.translateService.get('general.date').subscribe(
            data => {
                this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
                this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
                this.cd.markForCheck();
            }
        );

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'showUnmapped').subscribe((result) => {
            this.showUnMapped = JSON.parse(result) === true;
        });

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'showPosition').subscribe((result) => {
            this.showPosition = JSON.parse(result) === true;
        });

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideTriggers').subscribe((result) => {
            this.hideTriggers = JSON.parse(result) === true;
        });

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideUtilization').subscribe((result) => {
            this.hideUtilization = JSON.parse(result) === true;
        });

    }

    clearViolations() {
        this.violations = [];
        this.cd.markForCheck();
    }


    loadSensorTemplates() {
        this.sensorTemplates = [];

        if (!this.device.accountId) {
            return;
        }

        this.loadingSensorTemplates = true;
        this.cd.markForCheck();

        this.sensorTemplateService.getSensorTemplatesByAccount(this.device.accountId).subscribe(result => {
            this.sensorTemplates = result;
            this.loadingSensorTemplates = false;
            this.cd.markForCheck();
        });
    }

    checkSensorOnTempate(value, templateId) {

        if (!this.sensorTemplates || this.sensorTemplates?.length == 0) {
            return;
        }

        const template = this.sensorTemplates?.find(x => x.id === templateId);

        if (value === undefined && templateId !== undefined) {
            return false;
        }

        if (template === undefined) {
            return true;
        }

        if (template.maxValue !== undefined && value > template.maxValue) {
            return false;
        }
        if (template.minValue !== undefined && value < template.minValue) {
            return false;
        }

        return true;
    }

    setDeviceDetails(device: Device): any {
        if (device == null) {
            this.router.navigate(['/Devices/Overview']);
        } else {
            this.device = device;
            this.showWarningMessage = this.device?.asset?.isCommentWarning;
            this.violations = [];
            this.sources = [];

            var customFields = device?.asset?.properties?.custom;
            if (customFields) {
                var streamKeys = Object.values(customFields).filter(x => x['key'] == 'video');
                streamKeys.forEach(streamKey => {
                    var streamUrl = streamKey && streamKey['value'];

                    if (streamUrl) {
                        // check video player
                        this.sources.push({
                            src: streamUrl,
                            type: 'application/x-mpegURL',
                        })
                    }
                });
            }

            var dashcams = device?.asset?.properties?.dashcams;
            if (dashcams) {
                var streamKeys = Object.values(dashcams);

                streamKeys.forEach(streamKey => {
                    var streamUrl = streamKey && streamKey['url'];

                    if (streamUrl) {
                        // check video player
                        this.sources.push({
                            src: streamUrl,
                            name: streamKey['name'],
                            type: 'application/x-mpegURL',
                        })
                    }
                });
            }

            if (this.sources.length > 0) {
                this.playerOptions = {
                    fluid: true,
                    aspectRatio: "16:9",
                    controls: true,
                    autoplay: false,
                    sources: this.sources,
                };
            }

            if (this.device?.asset?.calibrationOdoOffsetInKm) {
                this.device.asset.calibrationOdoOffsetInKm = this.distance.calculateDistanceUnitFromKmFixed(this.device.asset.calibrationOdoOffsetInKm);
            }

            // Check inventory
            if (this.device.asset && !this.device.isArchived && ((this.device.accountId && (this.device.accountId !== this.device.accountId)) || (this.device.resellerId && (this.device.resellerId !== this.device.resellerId)))) {
                this.scheduleAssignment.assetId = this.device.asset.id;
                this.warning = {
                    statusText: 'Warning',
                    warning: ''
                };

                if (this.device.companyName !== this.device.companyName) {
                    this.warning.warning += `The device inventory account ${this.device.companyName} is not the same as the asset account ${this.device.companyName}. `;
                }

                if (this.device.resellerDescription !== this.device.resellerDescription) {
                    this.warning.warning += `The device inventory reseller ${this.device.resellerDescription} is not the same as the asset reseller ${this.device.resellerDescription}. `;
                }
            }

            this.loadingNote = false;
            this.loadingTriggers = true;

            this.dateChanged(true);

            this.triggerService.getTriggerByDeviceId(+this.device.id).subscribe(triggers => {
                this.triggers = triggers;
                this.loadingTriggers = false;
                this.cd.markForCheck();
            });

            this.loading = false;
        }
    }

    dateChanged(event) {
        if (this.showUtilization) {
            this.getUtilization();
        }

        // Check for initial load
        if (event === true) {
            this.getLocationCount(!this.device.asset?.isAssetTracker);
        } else {
            this.getLocationCount(false);
        }
    }

    getLocationCount(checkForViolations = true) {
        this.loadingCount = true;
        this.violations = [];
        console.log(checkForViolations);

        this.deviceService.getLocationCount(this.device.id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(result => {

            result = result.sort((a, b) => (a.summaryDate < b.summaryDate ? -1 : 1));

            const d = new Date();
            const compareDate = Moment.utc()['tz'](this.timezoneIana).subtract(5, 'days');

            this.loadingCount = false;
            this.locationCount = result;
            this.distanceChartData = result;

            // Checking for violations
            if (checkForViolations) {
                let totalLocationCount = 0;
                let totalDelayCount = 0;

                // Only show these warnings for resellers
                if (this.permissions['Platform_IsReseller']) {
                    const issuePrefix = this.translateService.instant('general.warning');

                    if (this.locationCount.length === 0) {
                        this.violations.push(this.translateService.instant('general.warnNoMessagesFound'));
                    }

                    if (this.device.settings.ignition !== '32768'
                        && this.device.settings.externalPower !== '32768'
                        && !this.device.settings.inputPorts.some(x => x.byte === '32768')) {
                        this.violations.push(`- ${issuePrefix}: ${this.translateService.instant('general.noIgnitionMapped')}`);
                    }

                    if (this.device.settings.ignition !== '2199023255552'
                        && this.device.settings.externalPower !== '2199023255552'
                        && !this.device.settings.inputPorts.some(x => x.byte === '2199023255552')) {
                        this.violations.push(`- ${issuePrefix}: ${this.translateService.instant('general.noExternalPowerMapped')}`);
                    }

                    result.forEach(element => {
                        totalLocationCount += element.locationCount;
                        totalDelayCount += element.delayMoreThan10;

                        if (Moment.utc(element.summaryDate)['tz'](this.timezoneIana) > compareDate) {
                            if (element.locationCount > 5000) {
                                this.violations.push(`- ${issuePrefix}: ${element.locationCount} ${this.translateService.instant('general.messagesReported')}: ` + (element.summaryDate ? Moment.utc(element.summaryDate)['tz'](this.timezoneIana)?.format('LL') : ''));
                            }
                        }
                    });

                    if (totalDelayCount / totalLocationCount > 0.2) {
                        this.violations.push(`- ${issuePrefix}: ${this.translateService.instant('general.significantDelays')}`);
                    }

                    if (!this.device.asset && totalLocationCount > 0) {
                        this.violations.push(`- ${issuePrefix}: ${this.translateService.instant('general.messagesButNoAsset')}`);
                    }

                    if (this.device.asset && this.device.isArchived && totalLocationCount > 0) {
                        this.violations.push(`- ${issuePrefix}: ${this.translateService.instant('general.archivedButMessages')}`);
                    }
                }

                if (!(this.device?.settings?.canBusParameters?.length > 0)) {
                    // Check odo
                    if (this.device.asset?.calibrationPoints == 0 || this.device.asset?.lastCalibration < Moment().subtract(3, 'months').startOf('day').toDate()) {
                        this.violations.push(`- ${this.translateService.instant('general.calibrationOutdated')}`);
                    }
                } else {
                    this.hasCanbus = true;
                }
            }

            this.generateChartLocationCount(this.distanceChartData);
            this.loadingCount = false;
            this.cd.markForCheck();
        }, error => {
            this.error = error;
            this.error = 'Error getting location count from summary database';
            this.loadingCount = false;
            this.cd.markForCheck();
        });
    }

    getUtilization() {
        this.loadingUtilization = true;
        this.deviceService.getDeviceUtilization(this.device.id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(
            utilization => {
                if (utilization) {
                    this.deviceUtilization = utilization;
                }
                // this.selectedLocation = location;
                this.loadingUtilization = false;
                this.cd.markForCheck();
            }, error => {
                this.error = error;
                this.error = 'Error getting utilization';
                this.loadingUtilization = false;
                this.cd.markForCheck();
            });
    }

    showModal(selectedAction) {
        console.log(selectedAction, selectedAction.actionPath);
        this.confirmModal.showModal('');
    }

    hideModal() {
        this.confirmModal.hideModal();
    }

    showDeletedNotification(event: Device) {
        this.toastr.error('Device ' + event.name, 'Deleted', {
            progressBar: true
        });
    }

    updateSchedule(event) {
        // Update the schedule
        console.log('Update the schedule');
        this.renderDateTime = new Date().getTime();
    }

    ngOnDestroy(): void {
        if (this.locationSubscription !== undefined) {
            this.locationSubscription.unsubscribe();
        }
    }

    onMapReady(map) {
        setTimeout(() => {
            this.leafletMapComponent.invalidateSize();
        }, 10);
    }

    ngOnInit() {
        const that = this;

        this.permissions = this.authentication.permissions;

        this.loading = true;
        this.loadingNote = true;
        this.loadingLocationCount = true;
        this.loadingLocation = true;
        this.loadingGroups = true;
        this.loadingLocationCount = true;
        this.loadingCount = true;

        this.device = new Device();
        this.device.settings = new DeviceSettings();

        this.scheduleAssignment = new ScheduleAssignment();

        this.device.id = '';

        this.sub = this.route.params.subscribe(params => {
            // reset device data for reload
            this.locationData = [];
            this.previousLookupTimestamp = null;

            const id = params['id'];

            switch (this.route.snapshot.url[0].path) {
                case 'DeviceDetails':
                    this.deviceId = id;
                    this.getDeviceInfo(true);
                    return;
                case 'AssetDetails':
                    this.assetService.getAssetById(id).subscribe((asset) => {
                        this.deviceId = asset?.deviceId;
                        this.getDeviceInfo(true);
                    });
                    return;
            }
        });
    }

    getAssetGroupItems(device) {
        if (device.asset?.id !== undefined && device.asset?.id !== 0) {

            this.loadingGroups = true;
            this.assetGroupsService.getAssetGroupItemsById(device.asset?.id, 0).subscribe(res => {
                this.assetGroups = res;

                this.assetGroups.forEach(group => {
                    group.deviceId = null;
                });

                this.loadingGroups = false;
                this.cd.markForCheck();
            });
        } else {
            this.loadingGroups = false;
            this.cd.markForCheck();
        }
    }

    getLocations(deviceId) {
        if (this.locationSubscription !== undefined) {
            this.locationSubscription.unsubscribe();
        }

        this.locationSubscription = timer(0, 30000).pipe(
            mergeMap(() => {
                // Start the spinner
                this.isSpinning = true;
                this.cd.markForCheck();

                return this.locationService.getDeviceStates([+deviceId], null, this.previousLookupTimestamp, 0)
            })
        ).subscribe(result => {
            // Stop the spinner
            setTimeout(() => { this.isSpinning = false; this.cd.markForCheck(); }, 500);
            this.loadingLocation = false;

            if (result?.deviceStates?.length > 0) {
                this.previousLookupTimestamp = new Date(result.timestamp);

                this.deviceState = result.deviceStates[0];

                // Check if we need to check the sensor templates
                if (this.deviceState) {
                    if (this.deviceState.temperature1?.temperatureInCelcius ||
                        this.deviceState.temperature2?.temperatureInCelcius ||
                        this.deviceState.temperature3?.temperatureInCelcius ||
                        this.deviceState.temperature4?.temperatureInCelcius ||
                        this.deviceState.engineCoolantTemperature?.temperatureInCelcius ||
                        this.deviceState.fuel?.fuelLevelInPercentage ||
                        this.deviceState.fuel1?.fuelLevelInPercentage ||
                        this.deviceState.fuel2?.fuelLevelInPercentage ||
                        this.deviceState.humidity1?.humidityInPercent ||
                        this.deviceState.humidity2?.humidityInPercent ||
                        this.deviceState.axleWeight1?.totalWeightInKg ||
                        this.deviceState.axleWeight2?.totalWeightInKg ||
                        this.deviceState.totalAxleWeight?.totalWeightInKg) {
                        this.loadSensorTemplates();
                    }
                }

                if (this.deviceState?.odometer?.gpsOdometer) {
                    this.deviceState.odometer.gpsOdometer = this.distance.calculateDistanceUnitFromKmFixed(this.deviceState.odometer.gpsOdometer);
                }

                if (this.deviceState?.currentPosition?.speed) {
                    this.deviceState.currentPosition.speed = this.distance.calculateDistanceUnitFromKmFixed(this.deviceState.currentPosition.speed, 0)
                }

                this.previousLookupDisplay = this.deviceState.communicationState?.updateTimestamp ?? new Date();

                this.unmappedPorts = this.checkUnmappedPorts(this.device, this.deviceState);

                this.lastCommunication = this.deviceState.communicationState?.updateTimestamp ?? new Date();

                this.deviceState.markerColor = colorMapper(this.deviceState?.calculatedDeviceState?.deviceState);

                // tslint:disable:no-bitwise
                this.deviceState.hasGpsFix = (this.deviceState?.communicationState?.locationType & 2) > 0;
                this.deviceState.hasCellFix = (this.deviceState?.communicationState?.locationType & 1) > 0;
                // tslint:enable:no-bitwise

                let latitude = this.deviceState?.currentPosition?.latitude ?? null;
                let longitude = this.deviceState?.currentPosition?.longitude ?? null;
                let lastCommunication = this.deviceState?.currentPosition?.updateTimestamp;

                if (this.deviceState?.cellPosition && ((latitude === null && longitude === null) && !(this.deviceState.cellPosition.latitude === 0 && this.deviceState.cellPosition.longitude === 0) || !this.deviceState.hasGpsFix)) {
                    latitude = this.deviceState.cellPosition.latitude ?? null;
                    longitude = this.deviceState.cellPosition.longitude ?? null;
                    lastCommunication = this.deviceState.cellPosition.updateTimestamp;
                }

                if (this.deviceState?.tagScanStatus?.tag) {
                    this.driverService.getDriverByTag(this.deviceState?.tagScanStatus?.tag, this.device.accountId).subscribe(res => {
                        this.device.scannedDriver = res;
                        this.cd.markForCheck();
                    });
                }

                this.locationData = [{
                    assetName: this.device?.asset?.name,
                    assetCode: this.device?.asset?.code,
                    assetPlateNumber: this.device?.asset?.plateNumber,
                    companyName: this.device?.companyName,
                    icon: this.device?.asset?.icon,
                    deviceState: this.deviceState?.calculatedDeviceState?.deviceState,
                    data: this.deviceState,
                    headingInDegrees: this.deviceState?.currentPosition?.heading,
                    speed: this.deviceState?.currentPosition?.speed,
                    latitude: latitude,
                    longitude: longitude,
                    unitId: this.device?.unitId,
                    deviceId: this.device?.id,
                    deviceTypeId: this.device?.deviceTypeId,
                    lastCommunication: lastCommunication,
                    radiusInMeters: this.deviceState?.currentPosition?.radiusInMeters,
                    assetGroupIds: this.device?.asset?.assetGroups,
                    assetGroups: this.device?.asset?.assetGroups,
                    gpsPosition: !this.deviceState.hasGpsFix ? this.deviceState?.currentPosition : undefined,
                }];

                this.loadingLocation = false;
            } else {
                if (result.timestamp) {
                    this.previousLookupTimestamp = new Date(result.timestamp);
                }

                console.log(`No state found newer then ${this.previousLookupTimestamp}`);
            }

        }, error => {
            if (this.locationSubscription !== undefined) {
                this.locationSubscription.unsubscribe();
            }
            this.loadingLocation = false;
            this.error = error;
            this.cd.markForCheck();
        });
    }


    // Generate chart locationcount
    generateChartLocationCount(data) {
        const theLocationDataIgnitionOn = [];
        const theLocationDataIgnitionOff = [];
        const theLocationDataEvents = [];
        const theDistanceData = [];
        const theCategories = [];
        const theActiveAssetCountData = [];
        const theInActiveAssetCountData = [];
        const theDelayMoreThan10Data = [];

        // tslint:disable-next-line:forin
        data.forEach(value => {
            theLocationDataIgnitionOn.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.ignitionCount, clientData: value.customerId });
            theLocationDataIgnitionOff.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: (value.locationCount - value.ignitionCount), clientData: value.customerId });
            theLocationDataEvents.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.eventCount, clientData: value.customerId });
            theDistanceData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round((value.distanceInMeters + Number.EPSILON) / 1000), clientData: value.customerId });
            theActiveAssetCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round((value.activeDeviceCount + Number.EPSILON) * 100) / 100, clientData: value.customerId });
            theInActiveAssetCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.deviceCount - value.activeDeviceCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
            theDelayMoreThan10Data.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.delayMoreThan10, clientData: value.customerId });
        });

        const theChartDataLocationCount = [{
            name: this.translateService.instant('enums.locationEventType.32768'),
            data: theLocationDataIgnitionOn,
            type: 'column',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            color: undefined,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }, {
            name: this.translateService.instant('enums.locationEventType.65536'),
            data: theLocationDataIgnitionOff,
            type: 'column',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }, {
            name: this.translateService.instant('general.events'),
            data: theLocationDataEvents,
            type: 'column',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }];

        if (this.permissions['Platform_IsReseller']) {
            theChartDataLocationCount.push({
                name: this.translateService.instant('general.delayMoreThan10'),
                data: theDelayMoreThan10Data,
                type: 'line',
                dashStyle: 'dash',
                fillOpacity: 0.5,
                opacity: 1,
                color: '#971c24',
                marker: {
                    enabled: false,
                    lineWidth: 1,
                    symbol: 'square'
                },
            });
        }

        const theChartDataDistance = [{
            name: this.translateService.instant('general.distance'),
            data: theDistanceData,
            yAxis: 0,
            type: 'areaspline',
            fillOpacity: 0.5,
            opacity: 0.6,
        }];

        this.chartLocationCount = this.chartService.generateColumnChartDates(theChartDataLocationCount, {}, theCategories, undefined, 'normal', true);
        this.chartDistance = this.chartService.generateColumnChartDates(theChartDataDistance, {}, theCategories, undefined, 'normal', false, ' km');
    }

    actualRound(value, decimals) {
        return roundAsNumber(value, decimals);
    }

    actualRoundMinutes(value) {
        return roundMinutes(value);
    }

    actualRoundSeconds(value) {
        return roundSeconds(value);
    }

    getGeofences() {
        if (this.geofences.length === 0 && this.device.accountId != null && this.device.accountId > 0) {
            this.loadingGeofences = true;
            this.accountService.getGeofencesByAccount(this.device.accountId).subscribe(geofences => {
                this.geofences = geofences;
                this.loadingGeofences = false;
                this.cd.markForCheck();
            }, error => {
                this.loadingGeofences = false;
                this.success = null;
                this.error = error;
                this.cd.markForCheck();
            });
        }
    }

    finishTask(task) {
        if (task?.completed) {
            this.error = null;

            if (task.result?.message.startsWith('general.')){
                task.result.message = this.translateService.instant(task.result.message);
            }

            if (task.result?.isSuccess) {
                this.warning = null;
                this.success = {
                    statusText: 'Success',
                    success: task.result?.message,
                };
            } else {
                this.success = null;
                this.warning = {
                    statusText: 'Warning',
                    warning: task.result?.message,
                };
            }

            this.getDeviceInfo();
            this.cd.markForCheck();
        } else {
            this.error = task?.error;
        }
    }

    saveNote() {
        console.log('Adding note');

        this.loadingNote = true;

        this.deviceService.saveNote(this.device).subscribe(result => {
            this.loadingNote = false;
            this.cd.markForCheck();
        }, error => {
            this.loadingNote = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    getEngineColor(temperature) {
        if (temperature < 70) {
            return 'green';
        }

        if (temperature < 80) {
            return 'yellow';
        }

        if (temperature < 90) {
            return 'orange';
        }

        if (temperature < 100) {
            return 'darkorange';
        }

        if (temperature > 100) {
            return 'red';
        }

        return '#ccc';
    }

    getFuelColor(fuel) {
        if (fuel < 30) {
            return 'red';
        }

        if (fuel < 50) {
            return 'orange';
        }

        if (fuel < 70) {
            return 'yellow';
        }

        if (fuel < 100) {
            return 'green';
        }

        return '#ccc';
    }

    // Form
    onDelete() {
        this.error = 'Deleting is not implemented';
    }

    onSave() {
        this.saving = true;

        this.deviceService.saveDevice(this.device).subscribe(result => {
            this.saving = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Device is successfull updated'
            };

            this.deviceService.resetDeviceCache().subscribe(res => {
                console.log('Devices are cleared');
            });

            this.setFormMode(FormMode.read)
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    onInsertAsset() {
        this.loading = true;

        this.device.asset.deviceId = this.device.id;
        this.assetService.saveAsset(this.device.asset).subscribe(result => {
            this.loading = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Asset is successfull added',
            };

            this.setFormModeAsset(FormMode.read);
        }, error => {
            this.loading = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    onSaveAsset() {
        this.saving = true;

        this.assetService.updateAsset(this.device.asset).subscribe(result => {
            this.saving = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Asset is successfull updated'
            };
            this.setFormModeAsset(FormMode.read)
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    showArchive(id) {
        this.archiveModal.showModal(id);
    }

    onArchiveAsset(event) {
        this.archiveModal.hideModal();

        this.loading = true;
        this.assetService.archiveAsset(this.device.id, this.device.asset?.id, this.archiveBody.archiveDate).subscribe(result => {
            this.loading = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Asset is successfull archived',
            };

            this.getDeviceInfo();
        }, error => {
            this.loading = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    reactivateAsset(id) {
        this.loading = true;
        this.assetService.reactivateAsset(id, this.device.id).subscribe(task => {
            if (task?.isSuccess) {
                this.error = null;

                if (task.message?.startsWith('Please')) {
                    this.success = {
                        statusText: 'Warning',
                        success: task.message,
                    };
                } else {
                    this.success = {
                        statusText: 'Success',
                        success: task.message,
                    };
                }
            } else {
                this.error = task?.message ?? task?.error;
            }
            this.loading = false;
            this.getDeviceInfo();
        }, error => {
            this.loading = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getDeviceInfo();
        }
    }

    setFormModeAsset(mode) {
        this.formModeAsset = mode;

        if (this.formModeAsset === FormMode.read) {
            this.loading = true;
            this.getDeviceInfo();
        }

        // When adding a new assset
        if (this.formModeAsset === FormMode.add) {
            this.device.asset = new Asset();
            this.device.asset.deviceId = this.device.id;
            this.device.asset.companyName = this.device.companyName;
            this.device.asset.accountId = this.device.accountId;
            this.device.asset.resellerDescription = this.device.resellerDescription;
            this.device.asset.resellerId = this.device.resellerId;
            this.device.asset.assignmentStart = new Date()
        }
    }

    getDeviceInfo(firstRequest = false) {
        this.deviceService.getDeviceById(this.deviceId).subscribe(device => {
            if (firstRequest) {
                this.getLocations(device.id);
            }

            this.setDeviceDetails(device);
            this.getGeofences();
            this.getAssetGroupItems(device);
            this.cd.markForCheck();
        }, error => {
            this.loading = false;
            this.success = null;
            this.error = error;

            if (firstRequest) {
                this.error.statusText = 'Error fetching device';

                setTimeout(() => {
                    this.router.navigate(['/Devices/Overview']);
                }, 3000);
            }

            this.cd.markForCheck();
        });
    }

    // unmapped
    flipUnMapped() {
        this.showUnMapped = !this.showUnMapped;
        this.storageHelper.saveStoreState(this.storageType, 'settings_', 'showUnmapped', this.showUnMapped.toString());
    }

    flipPosition() {
        this.showPosition = !this.showPosition;
        this.storageHelper.saveStoreState(this.storageType, 'settings_', 'showPosition', this.showPosition.toString());
    }

    checkUnmappedPorts(device, deviceState) {
        if ((device === undefined || device.settings.inputPorts === undefined) || deviceState === undefined) {
            return false;
        }

        const IOPorts = device.settings.inputPorts.reduce((result, { id, byte }) => (result[id] = byte, result), ({}));

        return [
            { id: 1, value: deviceState.input1State },
            { id: 2, value: deviceState.input2State },
            { id: 3, value: deviceState.input3State },
            { id: 4, value: deviceState.input4State },
            { id: 5, value: deviceState.input5State },
            { id: 6, value: deviceState.input6State },
        ].some(io => io.value !== undefined && IOPorts[io.id] === '0');
    }

    formatIconId(iconId) {
        return '<img style="position: relative;" src="' + getIconPath(iconId)[2] + '">';
    }

    addToGroup(_: number, confirmationModal: ConfirmationModalComponent, assetComponent: AssetGroupInputComponent) {
        confirmationModal.hideModal();
        this.loadingGroups = true;

        const filteredAssets = assetComponent.value.filter(x => x > -1);
        assetComponent.selector?.clearModel();
        if (filteredAssets.length === 0) {
            this.getDeviceInfo();
            return;
        }

        this.assetService.addToGroup(this.device.asset?.id, filteredAssets).subscribe(result => {
            this.loading = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Adding asset to groups was successful',
            };

            this.getDeviceInfo();
        }, error => {
            this.loading = false;
            this.success = null;
            this.error = error;

            this.getDeviceInfo();
        });
    }
}
