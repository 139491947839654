import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit, OnDestroy, ChangeDetectorRef, HostListener, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { createMapOptions, getMapProviders, colorMapper, setBounds, getMapProvidersExtended, drawGeofences } from 'app/common/leafletGlobals';
import { Device } from 'app/models/device.model';
import { icon, Map, marker, TileLayer } from 'leaflet';
import 'leaflet-easybutton';
import { MapService } from '../../services/common/map.service';
import { DeviceService } from '../../services/device/device.service';
import { LocationService } from '../../services/locations/locations.service';
import { BOUND_CHECK, getIconPath, MAX_LATITUDE, MAX_LONGITUDE, roundAsNumber, roundAsString, roundSeconds, MovingMarkerHelper } from 'app/common/globals';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DeviceType } from 'app/models/devicetype.model';

import '../../../../vendor/leaflet-activearea/leaflet-activearea.js';
import '../../../../vendor/leaflet-extramarkers/js/leaflet.extra-markers.js';

import { TranslateService } from '@ngx-translate/core';
import { AssetDisplayName, StorageType } from 'app/common/enums';
import { StorageHelper } from 'app/common/storagehelper';
import { Router } from '@angular/router';

declare var L;
declare var HeatmapOverlay;
declare var PruneCluster;
declare var PruneClusterForLeaflet;
declare const window;
declare var $;

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { AccountService } from 'app/services/account/account.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { FleetOverviewStoreService } from 'app/services/fleetoverview/fleetoverview-store.service';
import { UserService } from 'app/services/users/user.service';
import { AppUser } from 'app/models/user.model';
import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';
import { DistanceUnitService } from 'app/common/distanceunit.service';

window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-overview',
    templateUrl: 'overview.template.html',
    styleUrls: ['overview.template.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MapService, LocationService],
    animations: [
        trigger('slideInOut', [
            state('in', style({
                transform: 'translate3d(0, 0, 0)',
                opacity: 1
            })),
            state('out', style({
                transform: 'translate3d(calc(100% - 10px), 0, 0)',
                opacity: 0
            })),
            transition('in => out', animate('400ms ease-in-out')),
            transition('out => in', animate('400ms ease-in-out'))
        ]),
    ]
})
export class FleetOverviewComponent implements OnInit, OnDestroy {
    @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

    pruneCluster: any;
    selectedDevice: Device;
    selectedDeviceExtended: Device;
    deviceId: string;
    isLoaded = false;
    loadingSidebar = false;

    loading = false;
    markers: any;
    radiusLayer: any;
    circleMarker;
    streets: any;
    osm: any;
    cities: any;
    map: Map;
    options;
    devices: Device[] = [];
    menuState = 'out';
    statusText = '';

    selectedAssetGroups;

    maps = [];
    locationSubscription: any;
    deviceSubscription: any;
    selectedLocation: any;

    search: string;
    deviceType;
    status;

    error;
    success;

    deviceTypeOptions = [];
    loadingDeviceTypes = false;
    pruneClusterLoaded = false;
    deviceTypes: DeviceType[] = [];
    permissions: {};
    daterangepickerModel: any[];

    loadingUtilization = false;
    selectedDeviceUtilization: any;

    permissionName = 'Devices_View';
    constructorName = 'DevicesMapViewComponent';
    timezoneIana: string;

    geofences = [];
    geofenceLayer: L.FeatureGroup<any>;
    geofenceMap;

    myMovingMarker: any;
    movingMarkerIcon: L.Icon<L.IconOptions>;

    tripLayer: L.FeatureGroup<any>;

    lastBounds;

    user: AppUser = new AppUser();

    storageType = StorageType.LocalStorage;
    skipClustering = false;
    hideLabels = false;
    skipIncludingGroupColors = false;

    focussedTrips = [];
    centerTripsTimeout: any;

    accountId;

    isSpinning = false;

    orginialMenuStateSmall = false;
    showScale: boolean = false;
    autoFlip = true;

    handleEnter() {

        this.fleetOverviewStoreService.searchFilter.next('');

        if (this.autoFlip) {
            // Check if mini navbar is set... When ont handle the move to mini navbar
            if (!jQuery('body').hasClass('mini-navbar')) {
                this.orginialMenuStateSmall = false;
                jQuery('body').addClass('mini-navbar');
            } else {
                this.orginialMenuStateSmall = true;
            }
        }
    }

    handleLeave() {
        if (this.autoFlip) {
            if (!this.orginialMenuStateSmall) {
                jQuery('body').removeClass('mini-navbar');
            }
        }
    }

    constructor(
        private ngZone: NgZone,
        private cd: ChangeDetectorRef,
        private accountService: AccountService,
        private router: Router,
        private locationService: LocationService,
        private deviceTypeService: DeviceTypeService,
        private theMapService: MapService,
        private zone: NgZone,
        private http: HttpClient,
        private userService: UserService,
        private deviceService: DeviceService,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private storageHelper: StorageHelper,
        private fleetOverviewStoreService: FleetOverviewStoreService,
        private distance: DistanceUnitService,
    ) {
        const that = this;

        this.handleEnter();

        // Get settings
        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'skipClustering').subscribe((result) => {
            this.skipClustering = JSON.parse(result) === true;
        });

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'showScale').subscribe((result) => {
            this.showScale = JSON.parse(result) === true;
        });

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideLabels').subscribe((result) => {
            this.hideLabels = JSON.parse(result) === true;
        });

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'skipIncludingGroupColors').subscribe((result) => {
            this.skipIncludingGroupColors = JSON.parse(result) === true;
        });

        this.initMap();

        this.permissions = this.authenticationService.permissions;

        this.accountId = this.authenticationService.getAccountId();

        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().subtract(1, 'week').startOf('day').toDate(),
            Moment().add(0, 'days').endOf('day').toDate()
        ];

        this.fleetOverviewStoreService.fetchingStates$.subscribe((fetchingState) => {
            this.isSpinning = fetchingState;
            this.cd.markForCheck();
        });

        this.accountService.getGeofencesByAccount(this.accountId).subscribe(geofences => {
            this.geofences = geofences;

            this.geofenceMap = drawGeofences(L, this.geofences, this.geofenceLayer, null);
            this.cd.markForCheck();
        });

        this.fleetOverviewStoreService.selectGeofence$.subscribe((id) => {
            const geofence = this.geofenceMap?.get(id);

            if (geofence === undefined) {
                return;
            }

            this.map.fitBounds(geofence.getBounds(), { maxZoom: 15, padding: [15, 15] });
        });

        this.fleetOverviewStoreService.hiddenGeofences$.subscribe((hiddenGeofences) => {
            for (const [geofenceId, hidden] of hiddenGeofences) {
                const geofence = this.geofenceMap?.get(geofenceId);

                if (geofence === undefined) {
                    continue;
                }

                this.geofenceLayer.addLayer(geofence);
                this.geofenceLayer.removeLayer(geofence);

                if (hidden !== true) {
                    this.geofenceLayer.addLayer(geofence);
                }
            }

            this.cd.markForCheck();
        });

        this.fleetOverviewStoreService.startup().finally(() => {
            console.log('FO: Startup successfull');
            this.cd.markForCheck();
        });

        this.fleetOverviewStoreService.fleetOverviewMode$.subscribe(mode => {
            this.cd.markForCheck();
        });

        this.fleetOverviewStoreService.hiddenAssets$.subscribe((_) => {
            this.filterMarkers(false);
        });

        this.fleetOverviewStoreService.stateFilter$.subscribe((_) => {
            this.filterMarkers(false);
        });

        this.fleetOverviewStoreService.searchFilter$.subscribe((_) => {
            this.filterMarkers(true);
        });

        this.fleetOverviewStoreService.clearTrips$.subscribe((_) => {
            this.tripLayer.clearLayers();
        })

        this.fleetOverviewStoreService.playTrip$.subscribe(([assetId, tripLocations]) => {
            const device = this.devices.find(x => x.asset?.id === assetId);

            let iconId = 0;
            if (device?.asset?.icon > -1) {
                iconId = device.asset.icon;
            }

            const iconPath = getIconPath(iconId)[1];

            this.movingMarkerIcon = L.icon({
                iconUrl: iconPath,
                iconAnchor: [16, 16],
            });

            this.playTrip(tripLocations);
        })

        this.fleetOverviewStoreService.removeTrip$.subscribe((trips: L.FeatureGroup[]) => {
            for (const trip of trips) {
                that.tripLayer.removeLayer(trip);
            }
        });

        this.fleetOverviewStoreService.selectedTrip$.subscribe((trips: L.FeatureGroup[]) => {
            if (trips === undefined || trips.length === 0 || trips[0] === undefined) {
                return;
            }

            clearTimeout(this.centerTripsTimeout);

            for (const trip of trips) {
                trip.addTo(this.tripLayer);
                this.focussedTrips.push(trip);
            }

            this.centerTripsTimeout = setTimeout(() => {
                this.centerTrips(this.focussedTrips);
            }, 350);
        });
    }

    centerTrips(trips) {
        this.focussedTrips = [];

        let bounds = this.tripLayer.getBounds();
        if (trips.length === 1) {
            bounds = trips[0].getBounds();
        }

        setTimeout(() => {
            if (bounds.isValid()) {
                this.map?.flyToBounds(bounds, { padding: [30, 30], animate: true, duration: 0.5 });
            }
        });
    }

    toggleMenu() {
        console.log('Toggle menu');

        if (this.menuState === 'in') {
            this.filterMarkers(false);
        }

        if (this.circleMarker) {
            this.map.removeLayer(this.circleMarker);
        }

        // 1-line if statement that toggles the value:
        this.menuState = this.menuState === 'out' ? 'in' : 'out';
        this.cd.markForCheck();
    }

    deselectDevice() {
        this.menuState = 'out';
        this.selectedDevice = null;

        this.resetViewPort();
        this.filterMarkers(false);

        if (this.circleMarker) {
            this.map.removeLayer(this.circleMarker);
        }
    }

    ngOnDestroy(): void {
        if (this.deviceSubscription !== undefined) {
            this.deviceSubscription.unsubscribe();
        }
        if (this.locationSubscription !== undefined) {
            this.locationSubscription.unsubscribe();
        }
        if (this.fleetOverviewStoreService) {
            this.fleetOverviewStoreService.stop();
        }

        this.devices = [];
        this.map = null;
        this.markers = [];
        this.radiusLayer = L.featureGroup();
        this.pruneCluster = L.featureGroup();

        this.handleLeave();
    }

    setFocus() {
        setTimeout(() => {
            // Set offset center
            this.map['setActiveArea']({
                position: 'absolute',
                top: '0px',
                left: '0px',
                right: '420px',
                height: '100%'
            });

            this.map.setView([this.selectedDevice.theMarker.data.deviceState?.currentPosition?.latitude, this.selectedDevice.theMarker.data.deviceState?.currentPosition?.longitude], 15, { animate: true, duration: 0.5 });

            // Set offset center
            this.map['setActiveArea']({
                position: 'absolute',
                top: '0px',
                left: '0px',
                right: '0px',
                height: '100%'
            });

        }, 300);
    }

    saveViewPort() {
        this.lastBounds = this.map.getBounds();
    }

    resetViewPort() {
        if (this.lastBounds) {
            this.map.fitBounds(this.lastBounds);
        }
    }

    selectDevice(accountId, deviceId: string, latitude, longitude, radiusInMeters): any {
        this.loadingSidebar = true;
        const that = this;

        this.selectedDevice = that.devices.find(x => x.id === deviceId);

        this.selectedDevice.deviceTypeNameFull = this.deviceTypes.find(x => x.id === this.selectedDevice.deviceTypeId)?.description;

        this.setFocus();

        this.filterAllMarkers(deviceId);

        this.deviceService.getDeviceById(deviceId).subscribe(
            device => {
                this.selectedDeviceExtended = device;
                // this.selectedLocation = location;
                that.loadingSidebar = false;
                this.cd.markForCheck();
            }
        );

        if (this.circleMarker) {
            this.map.removeLayer(this.circleMarker);
        }

        if (radiusInMeters) {
            this.circleMarker = L.circle([latitude, longitude], {
                color: '#e100ff',
                opacity: 0.4,
                fillOpacity: 0.1,
                dashArray: '10, 10',
                radius: radiusInMeters
            });
            this.radiusLayer.addLayer(this.circleMarker);
        }

        this.loadingUtilization = true;
        this.deviceService.getDeviceUtilization(deviceId, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(
            utilization => {
                this.selectedDeviceUtilization = utilization;
                // this.selectedLocation = location;
                that.loadingUtilization = false;
                this.cd.markForCheck();
            }
        );
    }

    async ngOnInit() {
        this.deviceTypeOptions = [];
        const that = this;
        this.loadingDeviceTypes = true;

        this.deviceTypeService.getDeviceTypes(false, false, true).subscribe(deviceTypes => {
            this.loadingDeviceTypes = false;

            deviceTypes.sort((a, b) => (a.modelName > b.modelName ? 1 : -1)).forEach(function (item, index) {
                if (item.modelName !== '') {
                    that.deviceTypeOptions.push({ id: item.id, value: item.modelName + ' (' + item.deviceCount + ')' });
                }
            });

            this.cd.markForCheck();
        });

        window.my = window.my || {};
        window.my.namespace = window.my.namespace || {};

        window.my.namespace.saveAsGeofence = this.saveAsGeofence.bind(this);
        window.my.namespace.panTo = this.panToPublic.bind(this);
        window.my.namespace.zoomIn = this.zoomInPublic.bind(this);
        window.my.namespace.zoomOut = this.zoomOutPublic.bind(this);

        this.user = await this.userService.getUserById(this.authenticationService.getUserId()).toPromise();
    }

    getDeviceCount(index) {
        if (index === 6) {
            // tslint:disable-next-line:no-bitwise
            return this.devices?.filter(x => x.theMarker?.filtered === false && x.theMarker?.position != null && !((x.theMarker?.data.deviceState?.communicationState?.locationType & 2) > 0)).length ?? '-';
        } else {
            return this.devices?.filter(x => x.theMarker?.filtered === false && x.theMarker?.category === index.toString()).length ?? '-';
        }
    }

    saveAsGeofence(latitude: number, longitude: number): void {
        const circle: L.Circle = L.circle([latitude, longitude], 50);

        this.zone.run(() => this.router.navigateByUrl('/Geofences/Add', {
            state: {
                newGeofence: circle.toGeoJSON(),
            }
        }));
    }

    zoomInPublic(e): void {
        this.ngZone.run(() => this.map.zoomIn());
    }

    zoomOutPublic(e): void {
        this.ngZone.run(() => this.map.zoomOut());
    }

    panToPublic(latitude, longitude): void {
        this.ngZone.run(() => this.map.panTo([latitude, longitude]));
    }

    filterAllMarkers(deviceId) {
        console.log('filtering markers');
        // filter the events on map

        this.devices.forEach(device => {
            const theMarker = device.theMarker;

            if (theMarker) {
                theMarker.filtered = true;
            }
        });

        const activeDevice = this.devices.find(x => x.id === deviceId);
        activeDevice.theMarker.filtered = false;

        this.pruneCluster.ProcessView();
    }

    filterMarkers(centerMap) {
        console.log('filtering markers');
        // filter the events on map

        this.search = this.fleetOverviewStoreService.searchFilter.getValue();

        const hiddenDevices = this.fleetOverviewStoreService.hiddenAssets.getValue();

        const stateFilter = this.fleetOverviewStoreService.stateFilter.getValue();

        if (this.fleetOverviewStoreService.fleetOverviewMode === 'Live') {
            centerMap = true;
        }

        this.devices.forEach(device => {
            const theMarker = device.theMarker;

            if (theMarker) {
                theMarker.filtered = false;

                if ((this.fleetOverviewStoreService.fleetOverviewMode === 'Overview' || this.fleetOverviewStoreService.fleetOverviewMode === 'Live') && this.search.length > 0) {
                    if (!(theMarker.data.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1
                        || device.assetSearchName?.toLowerCase().indexOf(this.search.toLowerCase()) > -1
                        || device.theMarker?.data?.deviceState?.currentAddress?.address?.toLowerCase().indexOf(this.search.toLowerCase()) > -1
                        || device.theMarker?.data?.deviceState?.currentAddress?.city?.toLowerCase().indexOf(this.search.toLowerCase()) > -1
                        || device.theMarker?.data?.deviceState?.currentAddress?.country?.toLowerCase().indexOf(this.search.toLowerCase()) > -1
                        || device.companyName?.toLowerCase().indexOf(this.search.toLowerCase()) > -1
                        || window['driverBindings'].get(device.id)?.()?.toLowerCase?.().indexOf(this.search.toLowerCase()) > -1)) {
                        theMarker.filtered = true;
                    }
                }

                if (stateFilter.get(device.theMarker.data.deviceState?.calculatedDeviceState?.deviceState) === true) {
                    theMarker.filtered = true;
                }

                if (stateFilter.get(6) === false) {
                    // tslint:disable-next-line:no-bitwise
                    if ((device.theMarker.data.deviceState?.communicationState?.locationType & 2) > 0) {
                        theMarker.filtered = true;
                    }
                }

                if (hiddenDevices.get(+device.id) === true) {
                    theMarker.filtered = true;
                }

                if (this.status) {
                    if (this.status === 6) {
                        if (device.theMarker.data.deviceState?.communicationState?.locationType !== 0) {
                            theMarker.filtered = true;
                        }
                    } else {
                        if (device.theMarker.data.deviceState?.calculatedDeviceState?.deviceState !== this.status) {
                            theMarker.filtered = true;
                        }
                    }
                }

                if (this.selectedAssetGroups && this.selectedAssetGroups.length > 0) {
                    const found = device.asset?.assetGroupIds.some(ag => this.selectedAssetGroups.includes(ag));
                    if (!found) {
                        theMarker.filtered = true;
                    }
                } else if (this.selectedAssetGroups && this.selectedAssetGroups > 0) {
                    const found = device.asset?.assetGroupIds.some(ag => this.selectedAssetGroups === ag);
                    if (!found) {
                        theMarker.filtered = true;
                    }
                }

                if (this.deviceType && this.deviceType > 0) {
                    if (device.deviceTypeId !== this.deviceType) {
                        theMarker.filtered = true;
                    }
                }
            }
        });

        window.deviceCounts = Array.from({ length: 7 }, (_, k) => this.getDeviceCount(k));

        if (centerMap) {
            this.centerMap();
        }
        console.log('Device count: ' + this.devices.filter(x => x.theMarker?.filtered === false).length)

        if (this.pruneCluster && this.pruneClusterLoaded) {
            if (this.pruneCluster.ProcessView && this.pruneCluster.ProcessView instanceof Function) {
                this.pruneCluster.ProcessView();
            }
        }
    }

    createIcon(data) {

        const location = data.deviceState;

        const iconPath = getIconPath(data.iconId)[1];

        const [markerIcon, heading] =
            (location.calculatedDeviceState?.deviceState === 6) ? ['fa-rss', 0] :
                (location.calculatedDeviceState?.deviceState === 1 && location.currentPosition.heading > 0) ? ['fa-arrow-circle-up', location.currentPosition.heading] :
                    (location.calculatedDeviceState?.deviceState === 2) ? ['fa-stop-circle', 0] :
                        (location.calculatedDeviceState?.deviceState === 3) ? ['fa-pause-circle', 0] :
                            (location.calculatedDeviceState?.deviceState === 4) ? ['fa-signal', 0] :
                                (location.calculatedDeviceState?.deviceState === 5) ? ['fa-power-off', 0] :
                                    (location.calculatedDeviceState?.deviceState === 0) ? ['fa-question-circle', 0] : ['fa-play-circle', 0];

        return L['StatusMarker'].icon({
            iconUrl: iconPath,
            icon: markerIcon,
            markerColor: colorMapper(location.calculatedDeviceState?.deviceState),
            rotate: heading,
            shape: 'circle',
            prefix: 'fas'
        });
    }

    // Leaflet
    initMap() {
        forkJoin([
            this.storageHelper.loadStoreState(StorageType.LocalStorage, 'settings_', 'mapSelectionOptions'),
            this.storageHelper.loadStoreState(StorageType.LocalStorage, 'Map_', 'GeofenceLayerEnabled'),
            this.storageHelper.loadStoreState(StorageType.LocalStorage, 'settings_', 'showCompass')
        ]).subscribe(([mapSelectionOptions, geofenceLayerEnabled, showCompass]) => {
            this.maps = getMapProvidersExtended(L, mapSelectionOptions);

            this.markers = L.featureGroup();
            this.radiusLayer = L.featureGroup();
            this.geofenceLayer = L.featureGroup();
            this.tripLayer = L.featureGroup();

            this.pruneCluster = new PruneClusterForLeaflet();
            this.pruneClusterLoaded = true;

            let mapType = this.theMapService.getLeafletMapType();

            if (!mapType) {
                mapType = this.maps[0].name;
                this.theMapService.setLeafletMapType(mapType);
            }

            const defaultLayers = [];

            let defaultMap = this.maps.find(x => x.name.toString() === mapType.toString())
            if (!defaultMap) {
                console.log('Falling back to default map');
                defaultMap = this.maps[0];
            }

            defaultLayers.push(defaultMap.layer);

            defaultLayers.push(this.markers);
            defaultLayers.push(this.pruneCluster);
            defaultLayers.push(this.radiusLayer);
            defaultLayers.push(this.tripLayer);

            if (geofenceLayerEnabled) {
                defaultLayers.push(this.geofenceLayer);
            }

            const mapOptions = createMapOptions(L, defaultLayers, this.translateService, false, showCompass ?? false);

            this.options = {
                ...mapOptions
            };

            const that = this;

            this.loading = true;

            // Select event

            that.theMapService.setPruneCluster(that.pruneCluster);

            if (this.skipClustering) {
                this.pruneCluster.Cluster.Size = .000001;
                this.pruneCluster.Cluster.Margin = .000001;
            }

            that.pruneCluster.PrepareLeafletMarker = function (theMarker, data, category) {
                // parse data to icon
                that.theMapService.addLabel(theMarker, data, !that.hideLabels, that.skipIncludingGroupColors);

                that.theMapService.createPopup(theMarker, data, category);

                theMarker.setIcon(that.createIcon(data));

                theMarker.off('click');

                theMarker.on('click', (evt: any) => {
                    that.zone.run(() => {
                        that.menuState = 'in';
                        console.log('select');
                        that.saveViewPort();
                        that.selectDevice(data.accountId, data.deviceState.id, data.deviceState?.currentPosition?.latitude, data.deviceState?.currentPosition?.longitude, data.deviceState?.currentPosition?.radiusInMeters);
                    });
                });

                // tslint:disable-next-line:no-bitwise
                data.deviceState.hasCellFix = (data.deviceState?.communicationState?.locationType & 1) > 0;

                // tslint:disable-next-line:no-bitwise
                data.deviceState.hasGpsFix = (data.deviceState?.communicationState?.locationType & 2) > 0;

                let markerEvents = {};

                // This code manages showing outdated position(s) on the map
                if (!data.deviceState.hasGpsFix && (data.deviceState.cellPosition?.latitude && data.deviceState.cellPosition?.longitude && data.deviceState.currentPosition)) {
                    const cellPosition = new L.LatLng(data.deviceState.cellPosition.latitude, data.deviceState.cellPosition.longitude);
                    const gpsPosition = new L.LatLng(data.deviceState.currentPosition.latitude, data.deviceState.currentPosition.longitude);
                    const distanceLargeEnough = cellPosition.distanceTo(gpsPosition) > 25;

                    if (distanceLargeEnough) {
                        const pointList = [cellPosition, gpsPosition];

                        const polyline = L.polyline(pointList, {
                            color: 'red',
                            weight: 2,
                            opacity: 0.8,
                            dashArray: '10,10',
                            smoothFactor: 0
                        });

                        const smallIcon = L.ExtraMarkers.icon({
                            icon: 'fa-thumbtack',
                            markerColor: 'blue-dark',
                            rotate: 0,
                            shape: 'circle',
                            prefix: 'fas'
                        });

                        const gpsMarker = L.marker(gpsPosition, { icon: smallIcon });

                        markerEvents = {
                            mouseover: function ({ target }): void {
                                target._map.addLayer(polyline);
                                target._map.addLayer(gpsMarker);
                            },
                            mouseout: function ({ target }): void {
                                target._map.removeLayer(polyline);
                                target._map.removeLayer(gpsMarker);
                            }
                        };
                    }
                }

                theMarker.on(markerEvents);
            };

            setTimeout(() => {
                this.fillDevices();
            }, 100);
        });
    }

    onMapReady(map: Map) {
        this.map = map;

        this.theMapService.leafletMapComponent = map;

        this.map.on('baselayerchange', (event) => {
            this.theMapService.setLeafletMapType(event?.['name']);
        });

        setBounds(L, map);

        const that = this;

        const overlayMaps = {
            'Markers': this.markers
        };

        this.map.on('click', (evt: any) => {

            this.zone.run(() => {
                if (this.selectedDevice || this.menuState === 'in') {
                    this.deselectDevice();
                }
                this.cd.markForCheck();
            });
        });

        // overlayMaps
        // L.control.layers(this.maps, overlayMaps, { position: 'topleft' }).addTo(map);
        if (this.showScale) {
            L.control.scale().addTo(map);
        }

        new L.basemapsSwitcher(this.maps, { position: 'topright' }).addTo(this.map);

        // Easybutton
        L.easyButton({
            id: 'fit map button',
            position: 'topleft',
            states: [{
                stateName: 'add-markers',
                icon: 'fa-arrows-to-eye',
                title: 'Fit map',
                onClick: function (control) {
                    that.centerMap();
                }
            }]
        }).addTo(this.map);

        // Clear trips
        L.easyButton({
            id: 'clear-trip-map',
            position: 'topleft',
            states: [{
                stateName: 'clear-trips',
                icon: 'fa-recycle',
                title: 'Clear trips from map',
                onClick: function (control) {
                    that.fleetOverviewStoreService.clearTrips.next();
                }
            }]
        }).addTo(this.map);

        // Add geofence button
        this.storageHelper.loadStoreState(StorageType.LocalStorage, 'Map_', 'GeofenceLayerEnabled').subscribe((geofenceLayerEnabled) => {
            const geofenceToggle = L.easyButton({
                id: 'animated-geofences-toggle',
                position: 'topright',
                states: [{
                    stateName: 'add-geofences',
                    icon: 'fa-draw-polygon',
                    title: 'Show geofences',
                    onClick: function (control) {
                        that.map.addLayer(that.geofenceLayer);
                        control.state('remove-geofences');
                        that.storageHelper.saveStoreState(StorageType.LocalStorage, 'Map_', 'GeofenceLayerEnabled', true);
                    }
                }, {
                    stateName: 'remove-geofences',
                    title: 'Remove geofences',
                    icon: 'fa-vector-polygon',
                    onClick: function (control) {
                        that.map.removeLayer(that.geofenceLayer);
                        control.state('add-geofences');
                        that.storageHelper.saveStoreState(StorageType.LocalStorage, 'Map_', 'GeofenceLayerEnabled', false);
                    }
                }]
            });

            if (geofenceLayerEnabled) {
                geofenceToggle.state('remove-geofences');
            }

            geofenceToggle.addTo(this.map);
        });

        this.map.invalidateSize();
    }

    fillDevices() {

        // Getting devices for account 
        this.statusText = 'Fetching devices';
        this.deviceSubscription = this.deviceService.getDevicesLimited(this.accountId, null, true, !this.skipIncludingGroupColors, true).subscribe(devices => {
            this.devices = devices.filter(x => x.isActive === true && x.isArchived === false);

            this.loading = true;
            this.cd.markForCheck();

            let markerCount = 0;
            this.devices.forEach(device => {
                let assetDisplayLabel: string;

                switch (this.user.assetDisplayName) {
                    case AssetDisplayName['Asset Code']:
                        assetDisplayLabel = `${device.asset?.code || device.asset?.name}`;
                        break;
                    case AssetDisplayName['Plate Number']:
                        assetDisplayLabel = `${device.asset?.plateNumber || device.asset?.name}`;
                        break;
                    case AssetDisplayName['Device Name']:
                        assetDisplayLabel = `${device.asset?.name ?? device.name}`;
                        break;
                    case AssetDisplayName['Client Handle: Asset Code']:
                        assetDisplayLabel = `${device.companyName}: ${device.asset?.code || device.asset?.name}`;
                        break;
                    case AssetDisplayName['Client Handle: Plate Number']:
                        assetDisplayLabel = `${device.companyName}: ${device.asset?.plateNumber || device.asset?.name}`;
                        break;
                    case AssetDisplayName['Client Handle: Device Name']:
                        assetDisplayLabel = `${device.companyName}: ${device.asset?.name}`;
                        break;
                    default:
                        assetDisplayLabel = `${device.asset?.name ?? device.name}`;
                        break;
                }

                device.assetDisplayLabel = assetDisplayLabel;
                device.assetSearchName += ' ' + Array.from(device.asset?.assetGroups.values() ?? []).map(x => x.name).join('');

                if (device.theMarker) {
                    device.theMarker.filtered = false;
                    this.pruneCluster.RegisterMarker(device.theMarker);
                    markerCount++;
                }
            });

            // when reload
            if (markerCount > 0) {
                this.cd.markForCheck();

                this.pruneCluster.ProcessView();
                this.centerMap();
            }

            this.statusText = 'Recovered locations from memory';


            console.log('FO: Get location updates');
            this.getLocationUpdates();
        });
    }

    getLocationUpdates() {
        this.statusText = 'Getting locations';

        this.fleetOverviewStoreService.fleetOverviewState = 'LoadingMap';

        this.loading = true;
        this.cd.markForCheck();

        this.locationSubscription = this.fleetOverviewStoreService.lastDeviceStates$.subscribe((deviceStates) => {
            if (deviceStates.length > 0) {
                this.processData(deviceStates);
            } else {
                this.loading = false;
                this.fleetOverviewStoreService.fleetOverviewState = 'Loaded';

                this.cd.markForCheck();
            }
        }, () => {
            this.loading = false;
            this.fleetOverviewStoreService.fleetOverviewState = 'Loaded';

            this.cd.markForCheck();
        });
    }

    processData(deviceStates) {
        const that = this;

        console.log('FO: Updating data for ' + deviceStates?.length + ' devices.');

        if (deviceStates.length > 0) {
            this.statusText = 'Last updated: ';

            deviceStates.forEach(deviceState => {
                const device = that.devices.find(x => +x.id === deviceState.id);
                if (device === undefined) {
                    return;
                }

                deviceState.markerColor = colorMapper(deviceState.calculatedDeviceState?.deviceState);

                // tslint:disable-next-line:no-bitwise
                const hasGpsFix = (deviceState.communicationState?.locationType & 2) > 0;

                let latitude = deviceState.currentPosition?.latitude ?? null;
                let longitude = deviceState.currentPosition?.longitude ?? null;

                if (deviceState.cellPosition && ((latitude === null && longitude === null) || !hasGpsFix)) {
                    latitude = deviceState.cellPosition.latitude ?? null;
                    longitude = deviceState.cellPosition.longitude ?? null;
                }

                if (latitude === null || longitude === null) {
                    return;
                }

                if (deviceState?.odometer?.gpsOdometer) {
                    deviceState.odometer.gpsOdometer = this.distance.calculateDistanceUnitFromKmFixed(deviceState.odometer.gpsOdometer);
                }

                if (deviceState?.currentPosition?.speed) {
                    deviceState.currentPosition.speed = this.distance.calculateDistanceUnitFromKmFixed(deviceState.currentPosition.speed, 0)
                }

                if (device.theMarker) {
                    device.theMarker.Move(latitude, longitude);
                    device.theMarker.data.forceIconRedraw = true;
                    device.theMarker.data.deviceState = deviceState;
                    device.theMarker.data.lastCommunication = deviceState.communicationState?.updateTimestamp;
                    device.theMarker.category = Math.ceil(deviceState.calculatedDeviceState?.deviceState ?? 0).toString();

                    // Try to see if we need to move
                    if (this.selectedDevice && this.selectedDevice.id === deviceState.id.toString()) {
                        this.setFocus();
                    }
                } else {
                    const theMarker = new PruneCluster.Marker(latitude, longitude, { title: device.assetDisplayLabel, iconId: device.asset?.icon });

                    theMarker.category = Math.ceil(deviceState.calculatedDeviceState?.deviceState ?? 0).toString();
                    theMarker.data.accountId = device.accountId;
                    theMarker.data.popup = device.name;
                    theMarker.data.deviceId = device.id;
                    theMarker.data.imei = device.unitId;
                    theMarker.data.lastCommunication = deviceState.communicationState?.updateTimestamp ?? new Date();
                    theMarker.data.deviceState = deviceState;
                    theMarker.data.deviceTypeId = device.deviceTypeId;
                    theMarker.data.assetGroups = device.asset?.assetGroups;

                    device.theMarker = theMarker;

                    that.pruneCluster.RegisterMarker(theMarker);
                }
            });

            setTimeout(() => {
                this.filterMarkers(false);
            }, 100);

            this.pruneCluster.ProcessView();

            if (!this.isLoaded) {
                this.fleetOverviewStoreService.fleetOverviewState = 'Loaded';

                this.loading = false;
                this.isLoaded = true;
                this.cd.markForCheck();

                setTimeout(() => {
                    this.centerMap();
                });
            } else {
                this.loading = false;
                this.cd.markForCheck();
            }
        } else if (that.devices.length === 0) {
            this.loading = false;
        } else {
            console.log('No data updated');
        }

        if (this.fleetOverviewStoreService.fleetOverviewState !== 'Loaded') {
            this.fleetOverviewStoreService.fleetOverviewState = 'FetchingState';
            this.cd.markForCheck();
        }
    }

    @HostListener('window:centerMap', ['$event.detail'])
    onClick(detail) {
        const assetsList = Object.values(this.devices);
        if (assetsList.length === 0) {
            return;
        }

        const asset = assetsList.find(x => x.asset?.deviceId === detail);
        if (asset === undefined) {
            return;
        }

        if (!(asset.theMarker && 'position' in asset.theMarker)) {
            return;
        }

        this.map.fitBounds(L.latLngBounds([asset.theMarker.position]), { padding: [50, 50], maxZoom: 15 });
    }

    centerMap() {
        if (this.pruneCluster === undefined) {
            return;
        }

        this.invalidateSize();

        if (this.router.url.indexOf('/FleetOverview') !== -1 && this.fleetOverviewStoreService.fleetOverviewMode === 'History') {
            const tripLayerBounds = L.latLngBounds(this.tripLayer.getBounds());

            if (tripLayerBounds) {
                if (tripLayerBounds.isValid()) {
                    if (this.map) {
                        this.map.fitBounds(tripLayerBounds, { padding: [50, 50], maxZoom: 15 });
                    }
                }
            }

            return;
        }

        const assetsList = Object.values(this.devices);
        if (assetsList.length === 0) {
            const clusterBound = this.pruneCluster.ComputeGlobalBounds?.();
            if (clusterBound) {
                this.map.fitBounds(new L.LatLngBounds(new L.LatLng(clusterBound.minLat, clusterBound.maxLng), new L.LatLng(clusterBound.maxLat, clusterBound.minLng)), { padding: [50, 50] });
            }
        }

        const arrBounds = [];
        assetsList.forEach(asset => {
            if (asset.theMarker && asset.theMarker.data && asset.theMarker.data.deviceState) {
                if (BOUND_CHECK(asset.theMarker.data.deviceState?.currentPosition?.latitude, MAX_LATITUDE)
                    && BOUND_CHECK(asset.theMarker.data.deviceState?.currentPosition?.longitude, MAX_LONGITUDE)) {

                    if (!asset.theMarker.filtered) {
                        arrBounds.push(new L.LatLng(asset.theMarker.data.deviceState?.currentPosition?.latitude, asset.theMarker.data.deviceState?.currentPosition?.longitude));
                    }
                }
            }
        });

        const bounds = L.latLngBounds(arrBounds);
        if (bounds) {
            if (bounds.isValid()) {
                if (this.map) {
                    this.map.fitBounds(bounds, { padding: [50, 50], maxZoom: 15 });
                }
            }
        }
    }

    clearTrips() {
        this.fleetOverviewStoreService.clearTrips.next();
    }

    invalidateSize() {
        this.map?.invalidateSize();
    }

    playTrip(locations: any[]) {
        if (this.myMovingMarker) {
            this.myMovingMarker.stop?.();
            return;
        }

        if (locations === undefined) {
            return;
        }

        console.log('playing trip now');

        const speedCalculator = 4;

        const latlngs = [];
        const locationsTimeDelta = [];

        // Remove locations
        // this.leafletMapComponent.locationLayer.clearLayers()

        locations.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

        let timestamp = locations.length && Moment.utc(locations[0].timestamp);

        locations.forEach(location => {
            const speed = Moment.utc(location.timestamp).diff(timestamp) / 1000;
            timestamp = Moment.utc(location.timestamp);

            if (location.latitude !== 0 && location.longitude !== 0) {
                latlngs.push(new L.LatLng(location.latitude, location.longitude));
                locationsTimeDelta.push(speed * speedCalculator);
            }
        });

        const context = (value: any) => {
            this.myMovingMarker = value;
        }

        const movingMarker = new MovingMarkerHelper(context, L, this.map, this.movingMarkerIcon, this.timezoneIana);
        movingMarker.setLocations(latlngs, locations, locationsTimeDelta);

        movingMarker.onStart = () => this.fleetOverviewStoreService.isTripPlaying.next(true);
        movingMarker.onComplete = () => this.fleetOverviewStoreService.isTripPlaying.next(false);
        movingMarker.onDestroy = () => this.cd.markForCheck();
        movingMarker.externalObserver = this.fleetOverviewStoreService.isTripPlaying$;

        movingMarker.run();
    }

    get fleetOverviewMode() {
        return this.fleetOverviewStoreService.fleetOverviewMode;
    }
}
