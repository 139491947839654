<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/">{{'general.notifications' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/TriggerSchedules/Overview">{{'general.triggerSchedules' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.details' | translate }}</li>
            </ol>
        </nav>

        <span style="margin-left: auto;" class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix d-none d-md-inline-flex" title="Help"
                href="https://help.360locate.com/knowledge-base/use-workschedule-to-mark-trips-as-private-or-business/"
                target="_blank"><i class="fa fa-fa fa-question"></i> <span
                    class="d-none d-md-inline-flex">{{'general.needHelp' |
                    translate }}</span></a>
        </span>
    </div>
    <fh-notifications-navigation>
        <li class="active"><a href="/#/Notifications/TriggerScheduleDetails/Add/"><i class="fas fa-fw fa-calendar"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a></li>
    </fh-notifications-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>

    <form #form="ngForm">
        <div class="row" data-equalizer="">
            <div class="col-lg-9">
                <div class="ibox">
                    <div class="ibox-title">
                        <span style="float: left; width: calc(100% - 30px); overflow:hidden">
                            <h5>
                                <i class="fas fa-fw fa-calendar"></i> {{'general.triggerSchedules' | translate }}
                            </h5>
                        </span>
                        <span class="float-right" style="width:30px">
                            <i class="fa fa-fw fa-calendar-clock"
                                title="{{'general.timezoneSelectorToolTip' | translate}}"
                                (click)="hideTimezoneSelector = !hideTimezoneSelector"></i>
                        </span>
                    </div>
                    <ngx-loading [show]="loading || saving"></ngx-loading>
                    <fh-trigger-schedule-details [triggerSchedule]="triggerSchedule" [loading]="loading"
                        [hideTimezoneSelector]="hideTimezoneSelector" [formMode]="formMode">
                    </fh-trigger-schedule-details>
                    <fh-save-footer [valid]="!form.invalid" [allowCancelOnInsert]=false (onInsert)="onInsert()"
                        [formMode]="formMode">
                    </fh-save-footer>
                </div>
                <div class="ibox">
                    <div class="ibox-content slick" style="position:relative">
                        <ngx-loading [show]="loading || saving"></ngx-loading>
                        <fh-working-hours-selector #workingHoursVar [schedule]="triggerSchedule.schedule"
                            [formMode]="formMode">
                        </fh-working-hours-selector>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="ibox">
                    <div class="ibox-title">
                        <span style="float: left; width: calc(100% - 30px); overflow:hidden">
                            <h5>
                                <i class="fas fa-fw fa-circle-info"></i> {{'general.info' | translate }}
                            </h5>
                        </span>
                    </div>
                    <div class="ibox-content" style="position:relative">
                        <span>
                            {{'general.scheduleInfo' | translate }}
                        </span>

                    </div>
                </div>
            </div>
        </div>
    </form>
</div>