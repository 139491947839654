import {
    Component, AfterViewInit, Input, ElementRef, ViewChild, OnInit, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef, Inject,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StorageType } from 'app/common/enums';
import { StorageHelper } from 'app/common/storagehelper';
import { AccountInventory } from 'app/models/account.model';
import { AccountService } from 'app/services/account/account.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

const noop = () => {
};

@Component({
    selector: 'fh-account-input',
    templateUrl: './accountSelector.component.html',
    styleUrls: ['./accountSelector.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: AccountInputComponent }
    ]
})
export class AccountInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
    @Input() placeholder: string;
    @Input() needReseller = true;
    @Input() clearable = true;
    @Input() selectedResellerId;
    @Input() autoSelect = true;
    @Input() readonly = false;
    @Input() activeOnly = true;
    @Input() waslOnly = false;
    @Input() tableInline;
    @Input() filterEmptyAccounts = true;
    @Input() showEmptyAccountsButton = true;
    @Input() key = 'id';
    @Input() disable = false;
    @Output() onChanged = new EventEmitter();
    storageType = StorageType.LocalStorage;

    accounts: AccountInventory[] = [];
    accountsData: AccountInventory[] = [];

    previousAccountId;

    loadingAccounts = true;

    @ViewChild('input') el: ElementRef;
    @ViewChild('toggler') toggler: ElementRef;

    private _value: string;
    private _onChange: (_: any) => void = noop;
    permissions: {};

    get value(): any {
        return this._value;
    };

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this._onChange(v);
            this.cd.markForCheck();
        }
    }

    constructor(private accountService: AccountService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private storageHelper: StorageHelper) {
        this.permissions = authenticationService.permissions;
    }

    accountChanged(event) {

        if (this.value !== this.previousAccountId) {
            this.previousAccountId = this.value;
            this.onChanged.emit(this.value);
            this.storageHelper.saveStoreState(this.storageType, 'settings_', 'selectedAccount', this.value);
        }

        this.loadingAccounts = false;
        this.cd.markForCheck();
    }

    ngOnChanges(changes) {
        this.loadingAccounts = true;

        if (changes['selectedResellerId'] !== null && this.accountsData.length > 0) {
            setTimeout(() => {
                // Get accounts again

                this.filterAccounts();
                this.cd.markForCheck();
            }, 0);
        }

        this.cd.markForCheck();
    }

    filterAccounts() {
        if (this.accountsData.length === 0) {
            return;
        }

        if (this.needReseller && this.selectedResellerId == null) {
            this.accounts = [];
            this.previousAccountId = null;
            this.loadingAccounts = false;
            this.cd.markForCheck();
            return;
        }

        let userAccount = null;
        this.accounts = this.accountsData;

        if (this.waslOnly) {
            this.accounts = this.accounts.filter(x => x.properties?.wasl?.referenceKey != null && x.properties?.wasl?.referenceKey.length > 4);
        }

        if (this.selectedResellerId) {
            this.accounts = this.accounts.filter(x => x.resellerId?.toString() === this.selectedResellerId?.toString());
        }

        if (this.filterEmptyAccounts) {
            this.accounts = this.accounts.filter(x => x.deviceCount > 0 || x.id === this.value);
        }

        if (this.activeOnly) {
            this.accounts = this.accounts.filter(x => x.isActive === true || x.id === this.value);
        }

        if (!this.value && this.autoSelect === true && !this.readonly) {
            if (this.accounts.length === 1) {
                console.log('Setting account: ' + this.accounts[0].name);
                setTimeout(() => {
                    this.value = this.accounts[0].id;
                    this.cd.markForCheck();
                    this.accountChanged(null);
                }, 0);
            } else {
                // Autoset when you dont have permissions

                if (!this.permissions['Accounts_View']) {
                    console.log('Forcing own account when no account permission is set');

                    userAccount = this.authenticationService.getAccountId();
                    setTimeout(() => {
                        this.value = +userAccount;
                        this.cd.markForCheck();
                        this.accountChanged(null);
                    }, 0);

                    return;
                }

                // Set to own account when it is in list
                this.storageHelper.loadStoreState(this.storageType, 'settings_', 'selectedAccount').subscribe((stateAccount) => {

                    if (stateAccount && this.accounts.indexOf(stateAccount)) {
                        userAccount = stateAccount;
                    } else {
                        userAccount = this.authenticationService.getAccountId();
                    }

                    if (userAccount != null) {
                        if (this.accounts.find(e => e.id.toString() === userAccount.toString())) {
                            setTimeout(() => {
                                this.value = +userAccount;
                                this.cd.markForCheck();
                                this.accountChanged(null);
                            }, 0);
                        }
                    }
                });
            }
        }

        this.loadingAccounts = false;
        this.cd.markForCheck();
    }

    ngOnInit() {
        this.loadingAccounts = true;

        this.accountService.getAccounts(true, this.waslOnly).subscribe(result => {
            this.accountsData = result;

            setTimeout(() => {
                this.filterAccounts();
                this.cd.markForCheck();
            }, 0);
        });

        this.cd.markForCheck();
    }

    ngAfterViewInit() {
        if (this.showEmptyAccountsButton) {
            const __this = this;
            const textbox = __this.el.nativeElement;
            const toggler = __this.toggler.nativeElement;
            const togglerIcon = toggler.childNodes[0];

            if (!this.filterEmptyAccounts) {
                togglerIcon.classList.toggle('fa-building');
                togglerIcon.classList.toggle('fa-car-building');
            }

            toggler.addEventListener('click', (e) => {
                this.filterEmptyAccounts = !this.filterEmptyAccounts;

                this.filterAccounts();

                togglerIcon.classList.toggle('fa-building');
                togglerIcon.classList.toggle('fa-car-building');

                this.cd.markForCheck();
            });
        }
    }

    writeValue(value: any) {
        this._value = value;
        this.cd.markForCheck();
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
        this.cd.markForCheck();
    }

    registerOnTouched(fn: any) {
        this.cd.markForCheck();
    }
}
