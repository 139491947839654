import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { Asset } from 'app/models/asset.model';
import { AssetService } from 'app/services/asset/asset.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { AssetGroupsService } from 'app/services/asset/assetGroups.service';
import { AssetGroup } from 'app/models/group.model';
import { DeviceService } from 'app/services/device/device.service';
import { idLocale } from 'ngx-bootstrap/locale';
import { AccountService } from 'app/services/account/account.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fh-assetgroup-add',
  templateUrl: 'groupAdd.template.html'
})
export class AssetGroupAddViewComponent implements OnInit {
  token: string;
  loading = false;
  loadingGroups = false;
  assetGroup: AssetGroup = new AssetGroup();

  formMode: FormMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string; };

  // Multiselect
  format = {
    add: 'Add', remove: 'Remove', all: 'All', none: 'None', draggable: true
  };

  display = 'name';

  source = [];
  confirmed = [];
  result = [];

  loadingAssets = false;

  permissionName = 'AssetGroups_Add'

  constructor(private translateServe: TranslateService, private assetService: AssetService, private assetGroupService: AssetGroupsService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private router: Router) {
    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
  }

  ngOnInit() {
    this.loadSelector();
  }

  accountChanged(accountId) {
    this.confirmed = [];
    if (accountId !== null) {
      this.loadSelector();
    } else {
      this.source = [];
    }
  }

  loadSelector() {
    // Load items from service
    if (+this.assetGroup.accountId > -1) {
      this.loadingAssets = true;

      this.assetService.getAssetsByAccount(this.assetGroup.accountId).subscribe(result => {

        this.source = result.filter(x => x.isArchived !== true);

        this.loadingAssets = false;
      });
    }
  }

  onInsert() {
    this.loading = true;

    this.assetGroup.items = this.confirmed.map((asset) => +asset.id);
    this.assetGroupService.saveAssetGroup(this.assetGroup).subscribe(result => {

      this.success = {
        statusText: 'Success',
        success: this.translateServe.instant('general.saveSuccessRedirect', { entity: 'assetGroup' })
      };

      setTimeout(() => {
        this.router.navigate(['/AssetGroupDetails/Index/' + result.id]);
      }, 2000);

      this.loading = false;
    }, error => {
      this.success = null;
      this.error = error;
      this.loading = false;
    });
  }
}
