<span class="float-right" style="margin-top: -6px; margin-bottom: -6px">
    <ng-content></ng-content>
</span>
<span class="float-right" style="margin-top: 4px; margin-bottom: -4px; display: inline;">
    <div class="input-group grid-search">
        <input type="text" class="form-control inline-search" title="{{ 'general.search' | translate }}"
            style="margin-top: -10px; border-radius: 5px 0px 0px 8px;" placeholder="{{'general.search' | translate }}"
            [(ngModel)]="searchVar" (ngModelChange)="search$.next($event)">

        <button class="btn btn-primary btn-info" title="{{ 'general.search' | translate }}" (click)="sumbitSearch()"
            style="margin-top: -10px; border-radius: 0px 6px 6px 0px;">
            <i class="fa fa-fw fa-search"></i>
        </button>

        <button id="toolboxButton" title="{{ 'general.gridSettings' | translate }}"
            class="btn btn-primary toolboxButton" [ngClass]="isEditMode ? 'btn-primary' : 'btn-info'"
            (click)="switchEditMode()" style="margin-top: -10px;">
            <i class="fa fa-fw fa-download"></i>
        </button>
        <button id="columnChooseButton" title="{{ 'general.chooseColumns' | translate }}"
            class="btn btn-primary columnChooseButton" *ngIf="showColumns"
            [ngClass]="showColumnsEnabled ? 'btn-primary' : 'btn-info'" (click)="switchColumnChooser()"
            style="margin-top: -10px;">
            <i class="fa fa-fw fa-list-check"></i>
        </button>
        <button id="columnPresetsButton" title="{{ 'general.showPresets' | translate }}"
            class="btn btn-primary columnPresetsButton" *ngIf="showPresets" (click)="showModal()"
            [ngClass]="showPresetsEnabled ? 'btn-primary' : 'btn-info'" style="margin-top: -10px;">
            <i class="fa fa-fw fa-square-star"></i>
        </button>
        <button id="showFilterButton" title="{{ 'general.showFilter' | translate }}"
            class="btn btn-primary columnChooseButton" [ngClass]="showFilter ? 'btn-info' : 'btn-info opacity-half'"
            (click)="changeShowFilter(!showFilter)" style="margin-top: -10px; cursor: pointer!important;">
            <i class="fa fa-fw fa-filter"></i>
        </button>
    </div>
</span>

<div (onHidden)="hideModal()" bsModal [config]="{ backdrop: true, ignoreBackdropClick: false }" #batchModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left">
                    <i class="fa fa-fw fa-square-star"></i> {{ 'general.presets' | translate }}
                </h5>
                <button type="button" class="close float-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <fh-error [error]="error" [success]="success"></fh-error>
                <div class="row">
                    <div style="padding: 0;" class="col-lg-7">
                        <div style="padding: 10px">
                            <ul class="list-group" style="margin:0">
                                <li *ngFor="let preset of presetList | orderBy: 'userId': false;"
                                    class="list-group-item" (click)="loadGridPreset(preset)">
                                    <i class="fas fa-fw fa-receipt"></i>
                                    <span
                                        *ngIf="((presetLoaded | async)?.name !== preset.name) else bold">{{preset.name}}</span>
                                    <ng-template #bold>
                                        <b>{{preset.name}}</b>
                                    </ng-template>
                                    <i *ngIf="!(preset?.fromDatabase) || preset?.userId === userId"
                                        class="fas fa-fw fa-times" style="float: right;"
                                        (click)="deletePreset(preset.name)"></i>
                                </li>
                                <li *ngIf="presetList.length === 0" class="list-group-item">
                                    <i class="fas fa-fw fa-info"></i> {{ 'general.noPresetsFound' | translate}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div style="padding: 0;" class="col-lg-5">
                        <div style="padding: 10px">
                            <input type="text" name="preset_name" [(ngModel)]="preset_name" class="form-control"
                                placeholder="{{ 'general.name' | translate}}" style="width: 100%;">

                            <button id="submit" name="submit" class="btn btn-primary"
                                style="margin-top: 10px; width: 100%; display: block;" [disabled]="preset_name == ''"
                                (click)="savePreset()" title="{{ 'general.saveState' | translate }}">
                                <i class="fas fa-fw fa-save"></i> {{ 'general.save' | translate }}
                            </button>

                            <button class="btn btn-info" style="margin-top: 10px; width: 100%; display: block;"
                                title="{{ 'general.clearSettings' | translate }}" (click)="clearGridPreset()"> <i
                                    class="fas fa-fw fa-times-circle"></i> {{
                                'general.clearSettings' |
                                translate }}
                            </button>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>