<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/">{{'general.notifications' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.live' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-notifications-navigation></fh-notifications-navigation>
</div>

<div class="wrapper wrapper-content">

    <div class="row">
        <div class="col-lg-12">

            <div class="ibox" style="position: relative;">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-bell"></i> {{'general.notifications' | translate }}
                    </h5>

                    <span class="float-right">
                        <div class="filter-form d-none d-md-block">
                            <input type="text" class="form-control table-inline top-filter d-none d-md-block"
                                [(ngModel)]="filter">
                        </div>
                    </span>
                </div>
                <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th style="width:100px">
                                    {{ 'general.date' | translate}}
                                </th>
                                <th style="width:100px">
                                    {{ 'general.priority' | translate}}
                                </th>
                                <th style="width:auto">
                                    {{ 'general.subject' | translate}}
                                </th>
                                <!-- <th style="width:auto">
                                    {{ 'general.message' | translate}}
                                </th> -->
                                <th style="width:50px">
                                    {{ 'general.isRead' | translate}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr (click)="setRead(notification)" [ngClass]="notification.isRead ? '' : 'font-bold'"
                                *ngFor="let notification of notifications | orderBy : 'date':true | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage, id: 'accounts'}">
                                <td> {{ notification.date | amDateFormat: 'YYYY-MM-DD HH:mm' }}</td>
                                <td>
                                    {{ ('enums.priority.' + notification.priority) | translate }}
                                </td>

                                <td><a href='{{notification.link}}'><i class="fas fa-fw fa-angle-right"></i>
                                        {{ notification.subject }}</a></td>
                                <!-- <td>
                                    {{ notification.message }}
                                </td> -->
                                <td>
                                    <i id="checkBox_11" class="auto_checkbox fa fa-fw"
                                        [ngClass]="notification.isRead ? 'fa-check' : 'fa-times'" title="Isread"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer noselect dataTables_wrapper">
                    <button style="float:left" class="btn btn-primary" (click)="clear()">
                        <i class="fa fa-fw fa-times"></i> {{'general.clear' | translate }}</button>

                    <button style="float:left" class="btn btn-primary" (click)="setIsRead()">
                        <i class="fa fa-fw fa-check"></i> {{'general.setIsRead' | translate }}</button>

                    <pagination-template #pAccounts="paginationApi" (pageChange)="currentPage = $event" id="accounts">
                        <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                            <ul class="pagination">
                                <li class="paginate_button page-item previous"
                                    [class.disabled]="pAccounts.isFirstPage()">
                                    <a class="page-link" (click)="pAccounts.previous()"
                                        *ngIf="!pAccounts.isFirstPage()">{{
                                        'grid.previous' | translate }}</a>
                                    <a *ngIf="pAccounts.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                </li>

                                <li class="paginate_button page-item " *ngFor="let page of pAccounts.pages"
                                    [class.current]="pAccounts.getCurrent() === page.value">
                                    <a class="page-link" (click)="pAccounts.setCurrent(page.value)"
                                        *ngIf="pAccounts.getCurrent() !== page.value">
                                        <span>{{ page.label }}</span>
                                    </a>
                                    <a class="page-link" (click)="pAccounts.setCurrent(page.value)"
                                        *ngIf="pAccounts.getCurrent() === page.value">
                                        <span style="font-weight: bold;">{{ page.label }}</span>
                                    </a>
                                </li>

                                <li class="paginate_button page-item next" [class.disabled]="pAccounts.isLastPage()">
                                    <a class="page-link" (click)="pAccounts.next()" *ngIf="!pAccounts.isLastPage()">
                                        {{ 'grid.next' | translate }}
                                    </a>
                                    <a class="page-link" *ngIf="pAccounts.isLastPage()"> {{ 'grid.next' | translate
                                        }} </a>
                                </li>
                            </ul>
                        </div>
                    </pagination-template>
                    <div style="clear:both"></div>
                </div>
            </div>
        </div>
    </div>
</div>