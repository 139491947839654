<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/System">{{'general.system' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.stateViewer' | translate}}</li>
            </ol>
        </nav>
    </div>
    <fh-system-navigation></fh-system-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="" *ngIf="error">
        <div class="col-lg-12">
            <fh-error [error]="error"></fh-error>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content slick_toppadding" style="position: relative">
                    <div class="col-lg-12">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-prepend">
                                <i class="fa fa-fw fa-bolt"></i>
                            </span>
                            <ng-select class="form-control" [(ngModel)]="component" (change)="getState()"
                                placeholder="{{ 'general.components' | translate }}">
                                <ng-option [value]=1>
                                    {{ 'general.triggerProcessor' | translate}}
                                </ng-option>
                                <ng-option [value]=2>
                                    {{ 'general.stateManager' | translate}}
                                </ng-option>
                                <ng-option [value]=3>
                                    {{ 'general.cacher_devices' | translate}}
                                </ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="component == 2" class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-prepend">
                                <i class="fa fa-fw fa-microchip"></i>
                            </span>
                            <input type="text" class="form-control" [(ngModel)]="deviceId" (change)="getState()"
                                placeholder="{{ 'general.devices' | translate }}" />
                        </div>
                    </div>
                    <div style="clear:both"></div>
                </div>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-code"></i> {{'general.state' | translate }}</h5>
                    <span class="float-right">
                        <div class="filter-form d-none d-md-block">
                            <input type="text" class="form-control table-inline top-filter d-none d-md-block"
                                (keyup)="search()" [(ngModel)]="filter">
                        </div>
                    </span>
                </div>
                <div class="ibox-content" style="position: relative; overflow-x:auto">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <ngx-json-viewer [json]="jsonObject" [expanded]="false"></ngx-json-viewer>
                </div>
            </div>
        </div>
    </div>
</div>