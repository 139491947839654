import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode, TriggerInputSource, TriggerResultName, TriggerRules } from 'app/common/enums';
import { Trigger } from 'app/models/trigger.model';
import { EntityType } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { ConfirmationModalComponent } from '../../shared/usercontrols/confirmationModal.component';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';

import { TriggerService } from 'app/services/triggers/triggers.service';
import { FhChartService } from 'app/services/charts/charts.service';
import { TriggerRuleDetailsComponent } from '../../shared/usercontrols/triggerRuleDetails.component';
import { TriggerScheduleService } from 'app/services/triggerSchedule/triggerSchedule.service';
import { TriggerRulesViewComponent } from '../../shared/usercontrols/triggerRules.component';
import { getDefaultDpConfig } from 'app/common/gridhelper';

// Moment timezone
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';

window['moment'] = Moment;
mTZ();


@Component({
    providers: [FhChartService],
    selector: 'fh-fh-trigger-details',
    templateUrl: 'triggerDetails.template.html'
})
export class TriggerDetailsViewComponent implements OnInit {
    @ViewChild('ruleConfiguration') ruleConfiguration: TriggerRuleDetailsComponent;
    @ViewChild('triggerRulesView') triggerRulesView: TriggerRulesViewComponent;
    @ViewChild('rerunModal') rerunModal: ConfirmationModalComponent;
    sub: any;
    trigger = new Trigger;
    device: any;
    tagType = EntityType.Trigger.valueOf();
    formMode = FormMode.read;

    triggerScheduleNames: any[];

    loading = false;
    saving = false;

    success: any;
    error: any;
    warning: any;

    permissions: {};

    loadingCount = false;

    adding = false;
    rerunDisabled = false;

    permissionName = 'Triggers_View';

    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    daterangepickerModel: Date[];
    timezoneIana: string;

    constructor(private router: Router, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private triggerService: TriggerService, private triggerScheduleService: TriggerScheduleService, private route: ActivatedRoute) {
        this.permissions = this.authenticationService.permissions;
        this.timezoneIana = this.authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(8, 'days').startOf('day').toDate(),
            Moment().subtract(0, 'days').startOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);
    }

    add() {
        this.adding = true;
        this.cd.markForCheck();
    }

    ngOnInit() {
        this.loading = true;
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.getTriggerInfo(id);
        });
    }

    // Form
    onDelete() {
        this.loading = true;

        this.triggerService.deleteTrigger(this.trigger).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Trigger is successfully deleted.'
            };

            setTimeout(() => {
                this.router.navigate(['/Notifications/Triggers/Overview']);
            }, 3000);
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    isTriggerRerunDisabled(trigger) {
        var result = false;

        // Set disabled when not set to incoming message
        if (trigger.triggerInputSource != 0) {
            result = true;
        }

        // Set disabled when geofence
        trigger.rules.forEach(rule => {
            if (rule.ruleType == 3) {
                result = true;
            }
        });

        return result;
    }

    onSave() {
        this.saving = true;

        // Update to default 'Other' if episode is not to be stored
        if (!this.trigger.storeAsEpisode) {
            this.trigger.episodeType = TriggerResultName.Other;
        }

        this.triggerService.updateTrigger(this.trigger).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Trigger is successfully updated.'
            };

            this.saving = false;
            this.cd.markForCheck();

            this.setFormMode(FormMode.read)
        }, error => {

            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getTriggerInfo();
            this.cd.markForCheck();
        }
    }

    sourceChanged(event) {
        // Set Geofence Input source fields to default values.
        if (event === TriggerInputSource.GeofenceEvent) {
            this.trigger.allGeofences = false;
            this.trigger.geofenceEventType = 0;
        }

        this.ruleConfiguration.updateInputSource();
    }

    finishTask(task) {
        if (task?.completed) {
            this.error = null;

            if (task.result?.isSuccess) {
                this.warning = null;
                this.success = {
                    statusText: 'Success',
                    success: task.result?.message,
                };
            } else {
                this.success = null;
                this.warning = {
                    statusText: 'Warning',
                    warning: task.result?.message,
                };
            }

            this.getTriggerInfo();

            this.cd.markForCheck();
        } else {
            this.error = task?.error;
        }
    }

    getTriggerInfo(id = null) {
        if (this.loading !== true) {
            this.loading = true;
        }

        // Get from current triggert
        if (id == null) {
            id = this.trigger.id;
        }

        this.triggerService.getTriggerById(id).subscribe(trigger => {
            this.trigger = trigger;
            if (this.trigger) {
                this.setAccountData(this.trigger?.accountId);
            }

            console.log('Check if rerun should be disabled');
            this.rerunDisabled = this.isTriggerRerunDisabled(trigger);

            this.loading = false;
            this.cd.markForCheck();
        }, error => {
            this.error = error;
            this.loading = false;
            this.error.statusText = 'Error fetching trigger';
            this.cd.markForCheck();

            setTimeout(() => {
                this.router.navigate(['/Notifications/Triggers/Overview']);
            }, 3000);
        });
    }

    setAccountData(accountId) {
        if (this.ruleConfiguration) {
            this.ruleConfiguration.changeAccount(accountId);
        }
    }

    assetGroupsChanged(event) {
    }

    onRuleAdded(rule) {
        if (rule) {
            this.trigger.rules.push(rule);
        }

        this.adding = false;
        this.triggerRulesView.refresh();

        this.cd.markForCheck();
    }

    removeRule(rule) {
        const index: number = this.trigger.rules.indexOf(rule);
        if (index !== -1) {
            this.trigger.rules.splice(index, 1);
        }
    }

    showRerunModal() {
        this.rerunModal.showModal(0);
    }

    rerun() {
        this.saving = true;

        this.triggerService.rerunTrigger(this.trigger.id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(result => {
            this.saving = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Trigger has been scheduled for rerun.'
            };

            this.rerunModal.hideModal();

            this.setFormMode(FormMode.read);
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    dateChanged(event) {
    }
}
