import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Observable ,  Subject, forkJoin } from 'rxjs';
import { getGridButtons, getGridLanguages, createSearch } from 'app/common/gridhelper';
import { GridBase360Directive } from 'app/common/360Grid.base';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { StorageHelper } from 'app/common/storagehelper';
import { ColorService } from 'app/services/common/color.service';

@Component({
  selector: 'fh-device-types',
  templateUrl: 'deviceTypes.template.html',
  styleUrls: ['deviceTypes.template.css']
})
export class DevicesTypeViewComponent extends GridBase360Directive implements OnInit, AfterViewInit {
  token: string;

  assets: Subject<any> = new Subject();
  loading = false;

  data = 0;
  sub: any;
  languageLoaded: boolean;

  permissionName = 'Entities_DeviceTypes';
  constructorName = 'DevicesTypeViewComponent';

  error;

  constructor(private translateService: TranslateService, private authenticationService: AuthenticationService, private http: HttpClient, private deviceTypeService: DeviceTypeService, private route: ActivatedRoute, private router: Router, protected storageHelper: StorageHelper, private colorService: ColorService) {
    super(storageHelper);

    this.token = this.authenticationService.getAuthToken();
    // Get all the date for dropdown boxes
    forkJoin(
      this.translateService.get('general.date')
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      err => console.error(err)
    );
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true; this.initGrid();
    });
  }

  fireFilter(): void {
    console.log('fire update');
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

      // Fire the request
      dtInstance.draw();
    });
  }

  checkFilters() {
    this.fireFilter();
  }

  initGrid(): void {
    const that = this;
    this.loading = true;

    this.columns = [{
      name: 'id',
      data: 'id',
      className: 'noVis',
      orderable: false,
      title: '<div class="hideDropdown"></div>',
      width: '20',
      render: function (data, type, row) {
        return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/Devices/DeviceTypeDetails/Index/' + data + '\'><span class="d-none d-md-inline-flex" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
      }
    }, {
      name: 'id_export',
      data: 'id',
      orderable: false,
      title: this.translateService.instant('general.id'),
      visible: false,
    },
    {
      name: 'supplier.name',
      data: 'supplier.name',
      title: this.translateService.instant('general.supplierName'),
      render: function (data, type, row) {
        return data === 'Unknown' ? that.translateService.instant('general.unknown') : data
      }
    },
    {
      name: 'name',
      data: 'name',
      title: this.translateService.instant('general.modelName')
    },
    {
      name: 'number',
      data: 'number',
      title: this.translateService.instant('general.modelNumber')
    },
    {
      name: 'description',
      data: 'description',
      title: this.translateService.instant('general.description')
    },
    {
      name: 'deviceCount',
      data: 'deviceCount',
      type: 'num',
      visible: false,
      title: this.translateService.instant('general.deviceCount')
    },
    {
      name: 'deviceCountActive',
      data: 'deviceCountActive',
      type: 'num',
      title: this.translateService.instant('general.deviceCountActive')
    },
    {
      name: 'port',
      data: 'port',
      type: 'num',
      title: this.translateService.instant('general.port')
    }];

    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'deviceTypes_overview', this.translateService.instant('menu.devicetypesoverview'), this.colorService),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => { try {
          const columnSettings = await that.loadState(that.constructorName);
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          that.error = {};
          that.error.error = e;
          that.error.statusText = 'Error fetching column settings';

          return null;
        }})().then(result => {
          callback(result);
        });
      },
      order: [[1, 'asc']],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.deviceTypeService.getPagingUrl(),
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        console.log('init complete');
        that.checkFilters();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id', 'id_export']
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
    };
  }
}
