import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { Geofence } from 'app/models/geofence.model';
import { GeofenceService } from 'app/services/geofence/geofence.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { GeofenceGroupsService } from 'app/services/geofence/geofenceGroups.service';
import { GeofenceGroup } from 'app/models/group.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fh-geofencegroup-add',
  templateUrl: 'groupAdd.template.html'
})
export class GeofenceGroupAddViewComponent implements OnInit {
  token: string;
  loading = false;
  loadingGroups = false;
  geofenceGroup: GeofenceGroup = new GeofenceGroup();

  formMode: FormMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string; };

  // Multiselect
  format = {
    add: 'Add', remove: 'Remove', all: 'All', none: 'None', draggable: true
  };

  display = 'name';

  source = [];
  confirmed = [];
  result = [];

  loadingGeofences = false;

  permissionName = 'GeofenceGroups_Add';

  constructor(private translateServe: TranslateService, private geofenceService: GeofenceService, private geofenceGroupService: GeofenceGroupsService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private router: Router) {
    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
  }

  ngOnInit() {
    this.loadSelector();
  }

  accountChanged(accountId) {
    this.confirmed = [];
    if (accountId !== null) {
      this.loadSelector();
    } else {
      this.source = [];
    }
  }

  loadSelector() {
    if (this.geofenceGroup.accountId !== undefined) {
      this.loadingGeofences = true;

      this.geofenceService.getGeofencesByAccount(this.geofenceGroup.accountId).subscribe(result => {
        this.source = result;
        this.loadingGeofences = false;
      });
    }
  }

  onInsert() {
    this.loading = true;

    this.geofenceGroup.items = this.confirmed.map((geofence) => +geofence.id);
    this.geofenceGroupService.saveGeofenceGroup(this.geofenceGroup).subscribe(result => {

      this.success = {
        statusText: 'Success',
        success: this.translateServe.instant('general.saveSuccessRedirect', { entity: 'geofencegroup' })
      };

      setTimeout(() => {
        this.router.navigate(['/GeofenceGroupDetails/Index/' + result.id]);
      }, 2000);

      this.loading = false;
    }, error => {
      this.error = error
      this.loading = false;
    });
  }


}
