<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/EcoDriving">{{'general.ecoDriving' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.settings' | translate }}</li>
            </ol>
        </nav>

        <span style="margin-left: auto;" class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix d-none d-md-inline-flex" title="Help"
                href="https://help.360locate.com/knowledge-base/eco-driving/" target="_blank"><i
                    class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span
                    class="d-none d-md-inline-flex">{{'general.needHelp' |
                    translate }}</span></a>
        </span>
    </div>
    <fh-ecodriving-navigation>
    </fh-ecodriving-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class='col-lg-12'>
            <fh-error [error]="error" [success]="success" [warning]="warning"></fh-error>

            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-cog"></i> {{'general.settings' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.resellerDescription' | translate}}
                                </td>
                                <td class="row_content" style="position:relative; overflow: visible">
                                    <fh-reseller-input name="reseller" #resellerVar="ngModel"
                                        (onChanged)="resellerChanged($event)" required
                                        placeholder="{{ 'general.chooseReseller' | translate}}" [tableInline]="true"
                                        [(ngModel)]="selectedResellerId">
                                    </fh-reseller-input>
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.account' | translate}}
                                </td>
                                <td class="row_content" style="position:relative; overflow: visible">
                                    <fh-account-input name="account" #accountVar="ngModel"
                                        (onChanged)="accountChanged($event)" required
                                        [selectedResellerId]="selectedResellerId" [tableInline]="true"
                                        placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                        [(ngModel)]="accountId">
                                    </fh-account-input>
                                </td>
                            </tr>

                            <tr>
                                <td class="row_opening">
                                    {{ 'general.calculationBasedOn' | translate}}
                                </td>
                                <td class="row_content" style="position:relative; overflow: visible">
                                    <ng-select [(ngModel)]="calculationBasedOn" class="form-control table-inline"
                                        name="basedOn" required [clearable]="false" style="width: 50%; float:left;"
                                        style="width:100%">
                                        <ng-option value='1'>{{'general.xyzValues' | translate }}
                                        </ng-option>
                                        <ng-option value='2'>{{'general.deviceEvents' | translate }}
                                        </ng-option>
                                    </ng-select>
                                    <div style="clear:both"></div>
                                </td>
                            </tr>

                            <tr>
                                <td class="row_opening">
                                    {{ 'general.vehicleType' | translate}}
                                </td>
                                <td class="row_content" style="overflow: visible">
                                    <ng-select placeholder="{{ 'general.chooseVehicleType' | translate}}"
                                        [clearable]="false" class="form-control table-inline" name="vehicleType"
                                        required #vehicleType="ngModel" [(ngModel)]="selectedVehicleType"
                                        style="width:100%">
                                        <ng-option *ngFor="let vehicleType of vehicleTypes" [value]="vehicleType.id">
                                            {{ ('enums.vehicleType.' + vehicleType.id) | translate }}
                                        </ng-option>
                                    </ng-select>
                                    <div style="clear:both"></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" (click)="save()" [disabled]="!accountId">
                        <i class="fas fa-fw fa-save"></i> {{'general.save' | translate }}
                    </button>
                </div>
            </div>

            <div *ngIf="accountId && options.length > 0">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fa fa-gauge-max"></i> {{ 'general.speeding' | translate}}
                        </h5>

                        <span class="float-right">
                            <div class="fa_checkbox fa_toggle noselect">
                                <input [checked]="true" id="speeding" type="checkbox" name="speeding"
                                    [(ngModel)]="options[selectedVehicleType].enableSpeeding" />
                                <label for="speeding"></label>
                            </div>
                        </span>
                    </div>
                    <!-- <div class="ibox-content slicker" style="position: relative"
                        *ngIf="calculationBasedOn === '1' && options[selectedVehicleType] && options[selectedVehicleType].enableSpeeding == true">
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <ng-container
                                    *ngFor="let speeding of options[selectedVehicleType].speedingValues; let idx=index;">
                                    <tr>
                                        <td class="row_opening" style="width: 200px; vertical-align: middle">
                                            {{ 'general.treshold' | translate}} {{ idx + 1}}

                                            <i *ngIf="idx !== 0" class="fas fa-fw fa-times"
                                                (click)="removeItem(idx, options[selectedVehicleType].speedingValues)"></i>
                                        </td>
                                        <td class="row_content" style="padding: 10px 15px 10px 15px">
                                            <ngx-slider
                                                [value]="getValue(options[selectedVehicleType].speedingValues, idx, 'value')"
                                                [highValue]="getNextValue(options[selectedVehicleType].speedingValues, idx, 'value', 'speeding')"
                                                (valueChange)="changeValue(idx,options[selectedVehicleType].speedingValues,  $event, 'value')"
                                                (highValueChange)="changeHighValue(idx, options[selectedVehicleType].speedingValues, $event, 'value')"
                                                [options]="sliderOptionsSpeed">
                                            </ngx-slider>
                                        </td>
                                        <td class="row_content" style="padding: 10px 15px 10px 15px">
                                            <ngx-slider [(value)]="speeding.score" [options]="sliderOptionsScore">
                                            </ngx-slider>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div> -->
                    <!-- <div class="ibox-footer"
                        *ngIf="options[selectedVehicleType] && options[selectedVehicleType].enableSpeeding == true">
                        <button class="btn btn-primary"
                            (click)="addItem('speeding', options[selectedVehicleType].speedingValues)">
                            <i class="fas fa-fw fa-plus"></i> {{'general.add' | translate }}
                        </button>
                    </div> -->
                    <div class="ibox-content slicker" style="position: relative"
                        *ngIf="options[selectedVehicleType] && options[selectedVehicleType].enableSpeeding == true">
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <tr>
                                    <td class="row_opening" style="width: 200px; vertical-align: middle">
                                        {{ 'general.score' | translate}}
                                    </td>
                                    <td colspan="2" class="row_content" style="padding: 10px 15px 10px 15px">
                                        <ngx-slider [(value)]="options[selectedVehicleType].speedingBaseScore"
                                            [options]="sliderOptionsScore">
                                        </ngx-slider>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fa fa-snooze"></i> {{ 'general.idling' | translate}}
                        </h5>

                        <span class="float-right">
                            <div class="fa_checkbox fa_toggle noselect">
                                <input [checked]="true" id="idling" type="checkbox" name="idling"
                                    [(ngModel)]="options[selectedVehicleType].enableIdling" />
                                <label for="idling"></label>
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slicker" style="position: relative"
                        *ngIf="options[selectedVehicleType] && options[selectedVehicleType].enableIdling == true">
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <tr>
                                    <td class="row_opening" style="width: 200px; vertical-align: middle">
                                        {{ 'general.score' | translate}}
                                    </td>
                                    <td colspan="2" class="row_content" style="padding: 10px 15px 10px 15px">
                                        <ngx-slider [(value)]="options[selectedVehicleType].idlingScore"
                                            [options]="sliderOptionsScore">
                                        </ngx-slider>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fa fa-shield-slash"></i> {{ 'general.seatBeltViolation' | translate}}
                        </h5>

                        <span class="float-right">
                            <div class="fa_checkbox fa_toggle noselect">
                                <input [checked]="true" id="seatbelt" type="checkbox" name="seatbelt"
                                    [(ngModel)]="options[selectedVehicleType].enableSeatbelt" />
                                <label for="seatbelt"></label>
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slicker" style="position: relative"
                        *ngIf="options[selectedVehicleType] && options[selectedVehicleType].enableSeatbelt == true">
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <tr
                                    *ngIf="options[selectedVehicleType] && options[selectedVehicleType].enableSeatbelt == true">
                                    <td class="row_opening" style="width: 200px; vertical-align: middle">
                                        {{ 'general.score' | translate}}
                                    </td>
                                    <td colspan="2" class="row_content" style="padding: 10px 15px 10px 15px">
                                        <ngx-slider [(value)]="options[selectedVehicleType].seatbeltScore"
                                            [options]="sliderOptionsScore">
                                        </ngx-slider>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fa fa-engine"></i> {{ 'general.rpm' | translate}}
                        </h5>

                        <span class="float-right">
                            <div class="fa_checkbox fa_toggle noselect">
                                <input [checked]="true" id="rpm" type="checkbox" name="rpm"
                                    [(ngModel)]="options[selectedVehicleType].enableRpm" />
                                <label for="rpm"></label>
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slicker" style="position: relative"
                        *ngIf="calculationBasedOn === '1' && options[selectedVehicleType] && options[selectedVehicleType].enableRpm == true">
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <ng-container
                                    *ngFor="let rpm of options[selectedVehicleType].rpmValues; let idx=index;">
                                    <tr>
                                        <td class="row_opening" style="width: 200px; vertical-align: middle">
                                            {{ 'general.treshold' | translate}} {{ idx + 1}}

                                            <i *ngIf="idx !== 0" class="fas fa-fw fa-times"
                                                (click)="removeItem(idx, options[selectedVehicleType].rpmValues)"></i>
                                        </td>
                                        <td class="row_content" style="padding: 10px 15px 10px 15px">
                                            <ngx-slider
                                                [value]="getValue(options[selectedVehicleType].rpmValues, idx, 'value')"
                                                [highValue]="getNextValue(options[selectedVehicleType].rpmValues, idx, 'value', 'rpm')"
                                                (valueChange)="changeValue(idx,options[selectedVehicleType].rpmValues, $event, 'value')"
                                                (highValueChange)="changeHighValue(idx, options[selectedVehicleType].rpmValues, $event, 'value')"
                                                [options]="sliderOptionsRpm">
                                            </ngx-slider>
                                        </td>
                                        <td class="row_content" style="padding: 10px 15px 10px 15px">
                                            <ngx-slider [(value)]="rpm.score" [options]="sliderOptionsScore">
                                            </ngx-slider>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-content slicker" style="position: relative"
                        *ngIf="calculationBasedOn === '2' && options[selectedVehicleType] && options[selectedVehicleType].enableRpm == true">
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <tr>
                                    <td class="row_opening" style="width: 200px; vertical-align: middle">
                                        {{ 'general.score' | translate}}
                                    </td>
                                    <td colspan="2" class="row_content" style="padding: 10px 15px 10px 15px">
                                        <ngx-slider [(value)]="options[selectedVehicleType].rpmBaseScore"
                                            [options]="sliderOptionsScore">
                                        </ngx-slider>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer"
                        *ngIf="calculationBasedOn === '1' && options[selectedVehicleType] && options[selectedVehicleType].enableRpm == true">
                        <button class="btn btn-primary"
                            (click)="addItem('speeding', options[selectedVehicleType].rpmValues)">
                            <i class="fas fa-fw fa-plus"></i> {{'general.add' | translate }}
                        </button>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fa fa-gauge-max"></i> {{ 'general.acceleration' | translate}}
                        </h5>

                        <span class="float-right">
                            <div class="fa_checkbox fa_toggle noselect">
                                <input [checked]="true" id="acceleration" type="checkbox" name="acceleration"
                                    [(ngModel)]="options[selectedVehicleType].enableAcceleration" />
                                <label for="acceleration"> </label>
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slicker" style="position: relative"
                        *ngIf="calculationBasedOn === '1' && options[selectedVehicleType] && options[selectedVehicleType].enableAcceleration == true">
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <ng-container
                                    *ngFor="let acceleration of options[selectedVehicleType].accelerationValues; let idx=index;">
                                    <tr>
                                        <td class="row_opening" style="width: 200px; vertical-align: middle">
                                            {{ 'general.treshold' | translate}} {{ idx + 1}}

                                            <i *ngIf="idx !== 0" class="fas fa-fw fa-times"
                                                (click)="removeItem(idx, options[selectedVehicleType].accelerationValues)"></i>
                                        </td>
                                        <td class="row_content" style="padding: 10px 15px 10px 15px">
                                            <ngx-slider
                                                [value]="getValue(options[selectedVehicleType].accelerationValues, idx, 'value')"
                                                [highValue]="getNextValue(options[selectedVehicleType].accelerationValues, idx, 'value', 'accelleration')"
                                                (valueChange)="changeValue(idx,options[selectedVehicleType].accelerationValues,  $event, 'value')"
                                                (highValueChange)="changeHighValue(idx, options[selectedVehicleType].accelerationValues, $event, 'value')"
                                                [options]="sliderOptionsMg">
                                            </ngx-slider>
                                        </td>
                                        <td class="row_content" style="padding: 10px 15px 10px 15px">
                                            <ngx-slider [(value)]="acceleration.score" [options]="sliderOptionsScore">
                                            </ngx-slider>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-content slicker" style="position: relative"
                        *ngIf="calculationBasedOn === '2' && options[selectedVehicleType] && options[selectedVehicleType].enableAcceleration == true">
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <tr>
                                    <td class="row_opening" style="width: 200px; vertical-align: middle">
                                        {{ 'general.score' | translate}}
                                    </td>
                                    <td colspan="2" class="row_content" style="padding: 10px 15px 10px 15px">
                                        <ngx-slider [(value)]="options[selectedVehicleType].accelerationBaseScore"
                                            [options]="sliderOptionsScore">
                                        </ngx-slider>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer"
                        *ngIf="calculationBasedOn === '1' && options[selectedVehicleType] && options[selectedVehicleType].enableAcceleration == true">
                        <button class="btn btn-primary"
                            (click)="addItem('speeding', options[selectedVehicleType].accelerationValues)">
                            <i class="fas fa-fw fa-plus"></i> {{'general.add' | translate }}
                        </button>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fa fa-brake-warning"></i> {{ 'general.braking' | translate}}
                        </h5>

                        <span class="float-right">
                            <div class="fa_checkbox fa_toggle noselect">
                                <input [checked]="true" id="braking" type="checkbox" name="braking"
                                    [(ngModel)]="options[selectedVehicleType].braking" />
                                <label for="braking"> </label>
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slicker" style="position: relative"
                        *ngIf="calculationBasedOn === '1' && options[selectedVehicleType] && options[selectedVehicleType].enableBraking == true">
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <ng-container
                                    *ngFor="let braking of options[selectedVehicleType].brakingValues; let idx=index;">
                                    <tr>
                                        <td class="row_opening" style="width: 200px; vertical-align: middle">
                                            {{ 'general.treshold' | translate}} {{ idx + 1}}

                                            <i *ngIf="idx !== 0" class="fas fa-fw fa-times"
                                                (click)="removeItem(idx, options[selectedVehicleType].brakingValues)"></i>
                                        </td>
                                        <td class="row_content" style="padding: 10px 15px 10px 15px">
                                            <ngx-slider
                                                [value]="getValue(options[selectedVehicleType].brakingValues, idx, 'value')"
                                                [highValue]="getNextValue(options[selectedVehicleType].brakingValues, idx, 'value', 'braking')"
                                                (valueChange)="changeValue(idx,options[selectedVehicleType].brakingValues,  $event, 'value')"
                                                (highValueChange)="changeHighValue(idx, options[selectedVehicleType].brakingValues, $event, 'value')"
                                                [options]="sliderOptionsMg">
                                            </ngx-slider>
                                        </td>
                                        <td class="row_content" style="padding: 10px 15px 10px 15px">
                                            <ngx-slider [(value)]="braking.score" [options]="sliderOptionsScore">
                                            </ngx-slider>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-content slicker" style="position: relative"
                        *ngIf="calculationBasedOn === '2' && options[selectedVehicleType] && options[selectedVehicleType].enableBraking == true">
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <tr>
                                    <td class="row_opening" style="width: 200px; vertical-align: middle">
                                        {{ 'general.score' | translate}}
                                    </td>
                                    <td colspan="2" class="row_content" style="padding: 10px 15px 10px 15px">
                                        <ngx-slider [(value)]="options[selectedVehicleType].brakingBaseScore"
                                            [options]="sliderOptionsScore">
                                        </ngx-slider>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer"
                        *ngIf="calculationBasedOn === '1' && options[selectedVehicleType] && options[selectedVehicleType].enableBraking == true">
                        <button class="btn btn-primary"
                            (click)="addItem('speeding', options[selectedVehicleType].brakingValues)">
                            <i class="fas fa-fw fa-plus"></i> {{'general.add' | translate }}
                        </button>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fa fa-car-tilt"></i> {{ 'general.cornering' | translate}}
                        </h5>

                        <span class="float-right">
                            <div class="fa_checkbox fa_toggle noselect">
                                <input [checked]="true" id="cornering" type="checkbox" name="cornering"
                                    [(ngModel)]="options[selectedVehicleType].enableCornering" />
                                <label for="cornering"> </label>
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slicker" style="position: relative"
                        *ngIf="calculationBasedOn === '1' && options[selectedVehicleType] && options[selectedVehicleType].enableCornering == true">
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <ng-container
                                    *ngFor="let cornering of options[selectedVehicleType].corneringValues; let idx=index;">
                                    <tr>
                                        <td class="row_opening" style="width: 200px; vertical-align: middle">
                                            {{ 'general.treshold' | translate}} {{ idx + 1}}

                                            <i *ngIf="idx !== 0" class="fas fa-fw fa-times"
                                                (click)="removeItem(idx, options[selectedVehicleType].corneringValues)"></i>
                                        </td>
                                        <td class="row_content" style="padding: 10px 15px 10px 15px">
                                            <ngx-slider
                                                [value]="getValue(options[selectedVehicleType].corneringValues, idx, 'value')"
                                                [highValue]="getNextValue(options[selectedVehicleType].corneringValues, idx, 'value', 'cornering')"
                                                (valueChange)="changeValue(idx,options[selectedVehicleType].corneringValues,  $event, 'value')"
                                                (highValueChange)="changeHighValue(idx, options[selectedVehicleType].corneringValues, $event, 'value')"
                                                [options]="sliderOptionsMg">
                                            </ngx-slider>
                                        </td>
                                        <td class="row_content" style="padding: 10px 15px 10px 15px">
                                            <ngx-slider [(value)]="cornering.score" [options]="sliderOptionsScore">
                                            </ngx-slider>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-content slicker" style="position: relative"
                        *ngIf="calculationBasedOn === '2' && options[selectedVehicleType] && options[selectedVehicleType].enableCornering == true">
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <tr>
                                    <td class="row_opening" style="width: 200px; vertical-align: middle">
                                        {{ 'general.score' | translate}}
                                    </td>
                                    <td colspan="2" class="row_content" style="padding: 10px 15px 10px 15px">
                                        <ngx-slider [(value)]="options[selectedVehicleType].corneringBaseScore"
                                            [options]="sliderOptionsScore">
                                        </ngx-slider>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer"
                        *ngIf="calculationBasedOn === '1' && options[selectedVehicleType] && options[selectedVehicleType].enableCornering == true">
                        <button class="btn btn-primary"
                            (click)="addItem('speeding', options[selectedVehicleType].corneringValues)">
                            <i class="fas fa-fw fa-plus"></i> {{'general.add' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>