import { race } from 'rxjs';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';

import * as Moment from 'moment';
import { roundSeconds } from './globals';

export class MovingMarkerHelper {
    #latlngs: any;
    #flattenedLocations: any;
    #locationsTimeDelta: any;

    #externalObserver: any;

    #onStart = function () { };
    #onComplete = function () { };
    #onDestroy = function () { };

    constructor(public context, public L, public map, public movingMarkerIcon, private timezoneIana) {
    }

    public setLocations(latlngs, flattenedLocations, locationsTimeDelta) {
        this.#latlngs = latlngs
        this.#flattenedLocations = flattenedLocations
        this.#locationsTimeDelta = locationsTimeDelta;
    }

    set onStart(value) {
        this.#onStart = value;
    }

    set onComplete(value) {
        this.#onComplete = value;
    }

    set onDestroy(value) {
        this.#onDestroy = value;
    }

    set externalObserver(value) {
        this.#externalObserver = value;
    }

    public run() {
        const myMovingMarker = this.L['movingMarker'](this.#latlngs, this.#locationsTimeDelta, { icon: this.movingMarkerIcon, animate: true, autostart: false }, this.map);
        this.context(myMovingMarker);

        myMovingMarker.addTo(this.map);

        this.#onStart();

        const startupTimeout = setTimeout(() => {
            myMovingMarker.start(true);
        }, 1000);

        const stopMarkerIcon = this.L.icon({
            iconUrl: 'assets/images/icons/end.png',
            className: 'markerEnd'
        });

        const stopMarker = this.L.marker(this.#latlngs[this.#latlngs.length - 1], { icon: stopMarkerIcon });
        stopMarker.addTo(this.map);

        const startDate = Moment.utc(this.#flattenedLocations[0].timestamp)['tz'](this.timezoneIana);
        const endDate = Moment.utc(this.#flattenedLocations[this.#flattenedLocations.length - 1].timestamp)['tz'](this.timezoneIana);
        const duration = roundSeconds(endDate.diff(startDate, 'seconds'));

        const customPopup = `<div style="width:300px; overflow: auto;" class="leaflet-mappopup">
                                <div class="header">` + 'End trip' + `</div>
                                <div class="content">` + 'Start' + `:</div><div class="content">` + startDate.format('lll') + `</div>
                                <div class="content">` + 'Duration' + `:</div><div class="content">` + duration + `</div>
                                <div class="content">` + 'End' + `:</div><div class="content">` + endDate.format('lll') + `</div>
                             </div>`;

        stopMarker.bindPopup(this.L.popup().setContent(customPopup), {
            closeButton: false
        });

        const observers = [
            fromEvent(myMovingMarker, 'end'),
        ];

        if (this.#externalObserver !== undefined) {
            observers.push(this.#externalObserver);
        }

        const listener = race(observers)
            .subscribe(() => {
                listener.unsubscribe();
                clearTimeout(startupTimeout);

                this.map.removeLayer(myMovingMarker);
                this.context(null);

                this.#onComplete();

                stopMarker.openPopup();

                setTimeout(() => {
                    this.map.closePopup();
                    this.map.removeLayer(stopMarker);

                    this.#onDestroy();
                }, 10000);
            });
    }
}
