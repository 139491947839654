import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BatchAction, EntityType } from 'app/common/enums';
import { batchActionList } from 'app/common/globals';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DeviceService } from 'app/services/device/device.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable, map, of } from 'rxjs';
import { ConfirmationModalComponent } from '../confirmationModal.component';
@Component({
    selector: 'fh-task-dropdown',
    templateUrl: 'singleTaskProcessing.template.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class SingleTaskProcessingDropdownComponent implements OnChanges {
    @ViewChild('confirmModal', { static: false }) confirmModal: ConfirmationModalComponent;

    @Input() batchModal: ModalDirective;

    @Input() entity;
    @Input() entityTypes;

    @Output() onFinishAction = new EventEmitter();

    taskObject;
    processing = false;

    selectedAction;
    logItem;
    batchActions = [];

    permissions: {};

    constructor(private cd: ChangeDetectorRef, private translate: TranslateService, private http: HttpClient, private deviceService: DeviceService, private authenticationService: AuthenticationService) {
        this.permissions = this.authenticationService.permissions;
    }

    ngOnChanges(): void {
        if (!this.entityTypes) { return }

        this.batchActions = batchActionList.filter(x => x.entityTypes === this.entityTypes && x.hideOnDetailPage !== true && this.permissions[x.permission] == true);

        this.translate.get('general.date').subscribe(value => {
            this.batchActions.forEach(batchAction => {
                this.checkBatchAction(batchAction);
            });
        });

        this.cd.markForCheck();
    }

    checkBatchAction(batchAction: any) {
        batchAction.name = this.translate.instant(('enums.batchAction.' + batchAction.batchActionType));

        // Filter items on object and actiontype
        if (batchAction.batchActionType === BatchAction.ActivateDevice) {
            batchAction.disabled = !(this.entity?.accountId === undefined || this.entity?.accountId === 0);
        }

        if (batchAction.batchActionType === BatchAction.MoveToAccount) {
            batchAction.disabled = (this.entity?.asset === undefined || this.entity?.accountId === 0);
        }

        if (batchAction.batchActionType === BatchAction.ReplaceDevice) {
            batchAction.disabled = (this.entity?.asset === undefined);
        }

        if (batchAction.batchActionType === BatchAction.RemoveFromAccount) {
            batchAction.disabled = (this.entity?.accountId === undefined || this.entity?.accountId === 0);
        }

        if (batchAction.batchActionType === BatchAction.CreateInstantReport) {
            batchAction.disabled = (this.entity?.asset === undefined || this.entity?.accountId === 0);
        }

        if (batchAction.batchActionType === BatchAction.RerunTrips) {
            batchAction.disabled = (this.entity?.asset === undefined || this.entity?.accountId === 0);
        }

        if (batchAction.batchActionType === BatchAction.CreateBasicTrigger) {
            batchAction.disabled = (this.entity?.asset === undefined || this.entity?.accountId === 0);
        }

        // Check permissions
        if (!this.permissions[batchAction.permission]) {
            batchAction.disabled = true;
        }
    }

    processAction(object): Observable<any> {
        console.log('sending completed', this.entity, this.selectedAction, EntityType[this.selectedAction.entityTypes]);

        if (!this.selectedAction.actionPath) {
            return of('Not implemented');
        }

        return this.http.post(this.authenticationService.getWebserviceURL(EntityType[this.selectedAction.entityTypes]) + this.selectedAction.actionPath.replace('{0}', this.entity.id), object, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = data;
                return parsedResponse;
            }));
    }

    handleError(error): any {
        console.log(error);
    }

    // Popup
    showModal(selectedAction) {
        this.selectedAction = selectedAction;
        this.confirmModal.showModal('');
    }

    hideModal(event = null) {
        this.selectedAction = 0;
        this.confirmModal.hideModal();
    }

    resetAction(event = null) {
        this.selectedAction = 0;
    }

    process(event) {

        this.processing = true;
        this.cd.markForCheck();

        let object;

        if (this.selectedAction?.batchActionType === BatchAction.AddLogitem) {
            object = { id: this.entity.id, accountId: this.entity?.accountId, comment: this.logItem };
        }

        if (this.selectedAction?.batchActionType === BatchAction.RefreshDeviceStateFromDatabase) {
            object = { deviceIds: [this.entity.id], accountId: this.entity?.accountId }
        }

        if (this.selectedAction?.batchActionType === BatchAction.RequestLatestLocation) {
            object = { deviceId: this.entity.id };
        }

        if (this.selectedAction?.batchActionType === BatchAction.ResetDevice) {
            object = { deviceId: this.entity.id };
        }

        if (this.selectedAction?.batchActionType === BatchAction.ActivateDevice) {
            object = { ...event.object.items[0], log: event?.log };
        }

        if (this.selectedAction?.batchActionType === BatchAction.ChangeReseller) {
            object = { selectedReseller: event?.object?.selectedReseller, log: event?.log };
        }

        if (this.selectedAction?.batchActionType === BatchAction.MoveToAccount) {
            object = { deviceId: this.entity.id, accountId: event?.object?.selectedAccount, log: event?.log };
        }

        if (this.selectedAction?.batchActionType === BatchAction.ReplaceDevice) {
            object = { ...event.object[0], log: event?.log };
        }

        if (this.selectedAction?.batchActionType === BatchAction.RemoveFromAccount) {
            object = { deviceId: event?.object[0]?.deviceId, log: event?.log };
        }

        if (this.selectedAction?.batchActionType === BatchAction.DeleteHistory && event?.object) {
            object = { ...event.object, accountId: this.entity?.accountId, deviceId: this.entity.id, log: event.log };
        }

        if (this.selectedAction?.batchActionType === BatchAction.CreateInstantReport) {
            object = { ...event.object, deviceId: this.entity.id, log: event?.log };
        }

        if (this.selectedAction?.batchActionType === BatchAction.CalibrateOdo) {
            object = { ...event.object, deviceId: this.entity.id, log: event?.log };
        }

        if (this.selectedAction?.batchActionType === BatchAction.RerunTrips) {
            object = { ...event.object, deviceId: this.entity.id, startDateBatch: event.object.startDate, log: event?.log };
        }

        if (this.selectedAction?.batchActionType === BatchAction.CreateBasicTrigger) {
            object = { ...event.object, deviceId: this.entity.id, accountId: this.entity?.accountId, value: event.object.value, useCase: event.object.useCase, log: event?.log };
        }
        
        if (this.selectedAction?.batchActionType === BatchAction.SendCustomCommand) {
            object = { ...event.object, deviceId: this.entity.id };
        }

        console.log(object);
        this.processAction(object).subscribe(result => {
            this.onFinishAction.emit({ completed: true, result });
            this.processing = false;
            this.cd.markForCheck();
        }, error => {
            this.onFinishAction.emit({ completed: false, error });
            this.processing = false;
            this.cd.markForCheck();
        });

        this.hideModal();
    }
}
