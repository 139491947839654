import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { FhChartService } from '../../services/charts/charts.service';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from 'app/services/dashboard/dashboard.service';

import { SearchService } from 'app/services/search/search.service';
import { Router } from '@angular/router';
import { slideInOut } from 'app/common/animations';
import { AccountService } from 'app/services/account/account.service';
import { DeviceService } from 'app/services/device/device.service';
import { Device } from 'app/models/device.model';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
declare var L;

import { AccountInventory } from 'app/models/account.model';
import { BOUND_CHECK, MAX_LATITUDE, MAX_LONGITUDE, roundAsNumber, roundAsString, roundMinutes, roundSeconds } from 'app/common/globals';
import { Observable, debounceTime, distinctUntilChanged, forkJoin, timer } from 'rxjs';
import { getDefaultDpConfig } from 'app/common/gridhelper';
import { AuditLogService } from 'app/services/auditlog/auditlog.service';
import { AuditLog } from 'app/models/auditlog.model';
import { NotificationLogService } from 'app/services/notificationlog/notificationlog.service';
import { UserService } from 'app/services/users/user.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { DeviceType } from 'app/models/devicetype.model';
import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';
import { LocationService } from 'app/services/locations/locations.service';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import * as moment from 'moment-timezone';
window['moment'] = Moment;
mTZ();

import * as Highcharts from 'highcharts';
import { StorageHelper } from 'app/common/storagehelper';
import { StorageType, VehicleType } from 'app/common/enums';

@Component({
  selector: 'fh-home-history',
  templateUrl: 'homeHistory.template.html',
  providers: [FhChartService, SearchService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    slideInOut
  ]
})
export class HomeHistoryComponent implements OnDestroy, OnInit {
  Highcharts: typeof Highcharts = Highcharts;

  permissionName = 'FleetManagement_Dashboard';
  constructorName = 'DashboardClientComponent';
  timezoneIana: string;
  permissions: {};
  loading = false;

  activeDevices = 0;
  activeGeofences = 0;
  activeDrivers = 0;
  firstAssetDate: any;
  error: any;

  devices = [];
  loadingDevices = false;
  loadingGeofences = false;
  includeAssetsOtherAccounts = false;
  skipIncludingGroupColors = false;
  geofences = [];

  locationData = [];
  loadingAccount: boolean;
  account: AccountInventory;

  mapHeight = 250;

  loadingUtilization = false;
  daterangepickerModel: Date[];
  utilization: any;
  accountUtilization;
  dpConfig: any;

  loadingAuditLog = false;
  auditLogs: AuditLog[] = [];
  currentPageAuditLog;
  filterAuditLog;

  loadingNotifications = false;
  notifications: AuditLog[] = [];
  currentPageNotifications;
  filterNotifications;

  loadingCount: boolean;
  distanceChartData: any;
  chartLocationCount;
  chartDistance;
  chartActiveDevices;
  chartActivity;
  chartEngineHours;
  culture;

  // Impersonation
  asyncSelected: any;
  typeaheadLoading: boolean;

  autoComplete$: Observable<string[]>;
  autoCompleteSearchTerm: string;
  deviceTypes: DeviceType[] = [];

  // mapping
  search;
  status;
  selectedAssetGroups;
  selectedAccountId;
  selectedVehicleType;
  selectedAssetType;
  loadingDeviceTypes = false;
  deviceType;
  deviceTypeOptions = [];

  // Location updates
  isSpinning = false;
  deviceSubscription;
  locationSubscription;
  statusText = 'Init';
  previousLookupTimestamp;
  previousLookupDisplay: Moment.Moment;

  storageType = StorageType.LocalStorage;

  // trends
  loadingTrends: boolean;
  groupBy = 1;
  trends: any;
  selectedResellerId: any;
  vehicleTypes: { id: string; name: any; }[];

  resellerChanged(resellerId) {
    this.selectedAccountId = null;

    this.selectedResellerId = resellerId;
  }

  accountChanged(accountId) {
    this.selectedAccountId = accountId;
    this.selectedAssetGroups = null;

    this.error = null;

    this.dateChanged();
  }

  public constructor(
    private router: Router,
    private deviceTypeService: DeviceTypeService,
    private userService: UserService,
    private searchService: SearchService,
    private locationService: LocationService,
    private chartService: FhChartService,
    private deviceService: DeviceService,
    private storageHelper: StorageHelper,
    private dashboardService: DashboardService, private accountService: AccountService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private translateService: TranslateService) {
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
    this.permissions = this.authenticationService.permissions;

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(14, 'days').startOf('day').toDate(),
      Moment().tz(this.timezoneIana).subtract(0, 'days').startOf('day').toDate()
    ];

    this.deviceTypeService.getDeviceTypes().subscribe(result => {
      this.deviceTypes = result;
    });

    this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

    this.storageHelper.loadStoreState(this.storageType, 'settings_', 'includeAssetsOtherAccounts').subscribe((result) => {
      this.includeAssetsOtherAccounts = JSON.parse(result) === true;
    });

    this.storageHelper.loadStoreState(this.storageType, 'settings_', 'skipIncludingGroupColors').subscribe((result) => {
      this.skipIncludingGroupColors = JSON.parse(result) === true;
    });

    const culture = this.authenticationService.getCultureLang();
    switch (culture) {
      case 'en':
        this.culture = 'en-US';
        break;
      case 'nl':
        this.culture = 'nl-NL';
        break;
      case 'ar':
        this.culture = 'ar-AE';
        break;
      default:
        this.culture = 'en-US';
        break;
    }
  }

  ngOnInit(): void {

    const that = this;

    this.loading = true;
    this.loadingAccount = true;
    this.loadingDevices = true;

    this.vehicleTypes = Object.keys(VehicleType)
      .filter(k => typeof VehicleType[k] === 'string')
      .map(n => ({ id: n.toString(), name: VehicleType[n] }));

    this.loadingDeviceTypes = true;

    this.deviceTypeService.getDeviceTypes(false, false, true).subscribe(deviceTypes => {
      this.loadingDeviceTypes = false;
      deviceTypes.sort((a, b) => (a.modelName > b.modelName ? 1 : -1)).forEach(function (item, index) {
        if (item.modelName !== '') {
          that.deviceTypeOptions.push({ id: item.id, value: item.modelName + ' (' + item.deviceCount + ')' });
        }
        that.cd.markForCheck();
      });
    });

    this.dashboardService.getKPISCount().subscribe(kpiResults => {
      this.activeDevices = kpiResults.deviceCount;
      this.activeGeofences = kpiResults.geofenceCount;
      this.activeDrivers = kpiResults.driverCount;
      this.firstAssetDate = moment(kpiResults.firstAssetDate).toDate();

      let startDate = Moment().subtract(1, 'months').startOf('day').toDate();
      if (this.firstAssetDate > startDate) {
        startDate = this.firstAssetDate;
      }

      this.daterangepickerModel = [
        startDate,
        Moment().tz(this.timezoneIana).subtract(1, 'days').startOf('day').toDate()
      ];

      this.dateChanged();

      this.loading = false;
      this.cd.markForCheck();
    });

    this.accountService.getGeofencesByAccount(this.selectedAccountId).subscribe(geofences => {
      this.loadingGeofences = false;
      this.geofences = geofences;
      this.cd.markForCheck();
    });

    this.dateChanged();

    this.autoComplete$ = Observable.create((observer: any) => {
      this.actualSearch(this.asyncSelected).subscribe((result: any) => {
        observer.next(result);
      })
    });
  }

  // includeAssetsOtherAccounts
  flipIncludeAssetsOtherAccounts() {
    this.includeAssetsOtherAccounts = !this.includeAssetsOtherAccounts;
    this.storageHelper.saveStoreState(this.storageType, 'settings_', 'includeAssetsOtherAccounts', this.includeAssetsOtherAccounts.toString());
  }

  actualSearch(search) {
    return this.searchService.searchUsers(search).pipe(debounceTime(300), distinctUntilChanged());
  }

  onSubmit() {
    console.log('Search on');
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    console.log('Selected value: ', e);

    const user = this.userService.getUserById(e.item.id).subscribe(appUser => {
      this.userService.getTokenForUser(appUser.id).subscribe(result => {
        this.authenticationService.setImpersonationToken(result);
      });
    });
  }

  dateChanged() {
    if (!this.selectedAccountId) {
      this.loadingDevices = false;
      this.cd.markForCheck();

      return;
    }

    this.getUtilization();
    this.getLocationCount();
    this.getTrends();
  }

  getUtilization() {
    this.loadingUtilization = true;
    this.accountService.getAccountUtilization(this.selectedAccountId, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.includeAssetsOtherAccounts, this.selectedVehicleType, this.selectedAssetType, null, null, this.selectedAssetGroups).subscribe(
      utilization => {

        this.utilization = utilization.filter(x => x.accountId?.toString() === this.selectedAccountId.toString() || this.includeAssetsOtherAccounts === true);

        let maxSpeedDeviceId;

        let maxSpeed = -1;

        for (const assetUtilization of this.utilization) {
          if (maxSpeed > assetUtilization.maxSpeed) {
            continue;
          }

          maxSpeed = assetUtilization.maxSpeed;
          maxSpeedDeviceId = assetUtilization.deviceId;
        }

        this.accountUtilization = {
          assetCount: this.utilization.length,
          assetCountWithTrips: this.utilization.filter(x => x.tripCount > 0).length,
          totalDurationInSeconds: this.utilization.reduce((partial_sum, d) => partial_sum + d.totalDurationInSeconds, 0),
          tripCount: this.utilization.reduce((partial_sum, d) => partial_sum + d.tripCount, 0),
          tripCountPrivate: this.utilization.reduce((partial_sum, d) => partial_sum + d.tripCountPrivate, 0),
          tripCountBusiness: this.utilization.reduce((partial_sum, d) => partial_sum + d.tripCountBusiness, 0),
          totalDistance: this.utilization.reduce((partial_sum, d) => partial_sum + d.segmentsDistance, 0),
          totalDistancePrivate: this.utilization.reduce((partial_sum, d) => partial_sum + d.segmentsDistancePrivate, 0),
          totalDistanceBusiness: this.utilization.reduce((partial_sum, d) => partial_sum + d.segmentsDistanceBusiness, 0),
          totalWorkingHoursInSeconds: this.utilization.reduce((partial_sum, d) => partial_sum + d.workingHoursInSeconds, 0),
          totalIdlingDurationInSeconds: this.utilization.reduce((partial_sum, d) => partial_sum + d.idlingDurationInSeconds, 0),
          totalPureDrivingDurationInSeconds: this.utilization.reduce((partial_sum, d) => partial_sum + d.pureDrivingDurationInSeconds, 0),
          totalConsumptionMixed: this.utilization.reduce((partial_sum, d) => partial_sum + d.totalConsumptionMixed, 0),
          totalEmissionCO2: this.utilization.reduce((partial_sum, d) => partial_sum + d.totalEmissionCO2, 0),
          totalEmissionParticlesHeavy: this.utilization.reduce((partial_sum, d) => partial_sum + d.totalEmissionParticlesHeavy, 0),
          totalEmissionParticlesLight: this.utilization.reduce((partial_sum, d) => partial_sum + d.totalEmissionParticlesLight, 0),
          maxSpeed: maxSpeed,
          maxSpeedDeviceId: maxSpeedDeviceId,
        }

        this.generateActivityReport(this.accountUtilization);


        this.loadingUtilization = false;
        this.cd.markForCheck();
      }, error => {
        this.error = error;
        // this.error = 'Error getting utilization';
        this.loadingUtilization = false;
        this.cd.markForCheck();
      });
  }

  getTrends() {
    this.loadingTrends = true;
    this.accountService.getAccountTrends(this.selectedAccountId, this.groupBy, 0, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'),
      {
        targetIdling: 0,
        targetDistance: 0,
        targetUtilization: 0,
      },
      this.selectedVehicleType,
      this.selectedAssetType,
      null,
      null,
      this.selectedAssetGroups,
      false).subscribe(resp => {
        this.trends = resp;

        this.generateChartEngineHours(resp);
        this.loadingTrends = false;
      }, error => {
        this.loadingTrends = false;
        this.error = error;
      });
  }

  stopImpersonation() {
    this.router.navigate(['/']).then(response => {
      this.authenticationService.stopImpersonation();
    });
  }

  ngOnDestroy(): any {
    if (this.deviceSubscription !== undefined) {
      console.log('Unsubscribe updates');

      this.deviceSubscription.unsubscribe();
    }
    if (this.locationSubscription !== undefined) {
      console.log('Unsubscribe updates');
      this.locationSubscription.unsubscribe();
    }
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  actualRoundSeconds(value) {
    return roundSeconds(value);
  }

  actualRoundMinutes(value) {
    return roundMinutes(value);
  }

  getLocationCount() {
    this.loadingCount = true;

    this.accountService.getLocationCount(this.includeAssetsOtherAccounts === true ? null : this.selectedAccountId, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.selectedVehicleType, this.selectedAssetType, null, null, this.selectedAssetGroups).subscribe(result => {
      this.loadingCount = false;
      result = result.sort((a, b) => (a.summaryDate < b.summaryDate ? -1 : 1));
      this.distanceChartData = result;
      this.generateChartLocationCountChart(this.distanceChartData);

      this.cd.markForCheck();
    }, error => {
      this.loadingCount = false;
      this.error = error;
      this.cd.markForCheck();
    });
  }

  // Generate chart locationcount
  generateChartLocationCountChart(data) {

    const theLocationDataIgnitionOn = [];
    const theLocationDataIgnitionOff = [];
    const theLocationDataEvents = [];
    const theDistanceData = [];
    const theCategories = [];
    const theNonCommunicatingCountData = [];
    const theComminicatingButNotMovingAssetCountData = [];
    const theMovingAssetCountData = [];
    const theDelayMoreThan10Data = [];

    // tslint:disable-next-line:forin
    data.forEach(value => {
      if (value.assetCount < value.deviceCommunicatingCount) {
        value.assetCount = value.deviceCommunicatingCount;
      }

      theLocationDataIgnitionOn.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.ignitionCount, clientData: value.customerId });
      theLocationDataIgnitionOff.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: (value.locationCount - value.ignitionCount), clientData: value.customerId });
      theLocationDataEvents.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.eventCount, clientData: value.customerId });
      theDistanceData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round((value.distanceInMeters + Number.EPSILON) / 1000), clientData: value.customerId });
      theNonCommunicatingCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.assetCount - value.deviceCommunicatingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
      theComminicatingButNotMovingAssetCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.deviceCommunicatingCount - value.deviceMovingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
      theMovingAssetCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.deviceMovingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
      theDelayMoreThan10Data.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.delayMoreThan10, clientData: value.customerId });
    });

    const theChartDataLocationCount = [{
      name: this.translateService.instant('enums.locationEventType.32768'),
      data: theLocationDataIgnitionOn,
      type: 'column',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      color: undefined,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('enums.locationEventType.65536'),
      data: theLocationDataIgnitionOff,
      type: 'column',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.events'),
      data: theLocationDataEvents,
      type: 'column',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }];

    if (this.permissions['Platform_IsReseller']) {
      theChartDataLocationCount.push({
        name: this.translateService.instant('general.delayMoreThan10'),
        data: theDelayMoreThan10Data,
        type: 'line',
        dashStyle: 'dash',
        fillOpacity: 0.5,
        opacity: 1,
        color: '#971c24',
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: 'square'
        },
      });
    }

    const theChartDataDistance = [{
      name: this.translateService.instant('general.distance'),
      data: theDistanceData,
      fillOpacity: 0.5,
      opacity: 0.6,
      type: 'areaspline'
    }];

    const theChartDataDeviceCount = [{
      name: this.translateService.instant('general.movement'),
      data: theMovingAssetCountData,
      yAxis: 0,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.nomovement'),
      data: theComminicatingButNotMovingAssetCountData,
      yAxis: 0,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.notcommunicating'),
      data: theNonCommunicatingCountData,
      yAxis: 0,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }];

    this.chartLocationCount = this.chartService.generateColumnChartDates(theChartDataLocationCount, {}, theCategories, undefined, 'normal', false);
    this.chartDistance = this.chartService.generateColumnChartDates(theChartDataDistance, {}, theCategories, undefined, 'normal', false, ' Km');
    this.chartActiveDevices = this.chartService.generateColumnChartDates(theChartDataDeviceCount, {}, theCategories, undefined, 'normal', false, '', null, true);

    this.cd.markForCheck();
  }

  // Generate chart engineHours
  generateChartEngineHours(data) {

    const theCategories = [];
    const theIdlingData = [];
    const theMovingData = [];

    // tslint:disable-next-line:forin
    data.forEach(value => {
      theIdlingData.push({ x: moment.utc(value.startDate).toDate().getTime(), y: Math.round(((value.idlingDurationInMinutes / 60) + Number.EPSILON) * 10) / 10, clientData: value.customerId });
      theMovingData.push({ x: moment.utc(value.startDate).toDate().getTime(), y: Math.round(((value.pureDrivingInMinutes / 60) + Number.EPSILON) * 10) / 10, clientData: value.customerId });
    });

    const theChartDataEngineHours = [{
      name: this.translateService.instant('general.idling'),
      data: theIdlingData,
      yAxis: 0,
      type: 'column',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      color: '#EE9234',
      opacity: 1,
      labels: {
        format: '{value} H'
      },
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.moving'),
      data: theMovingData,
      yAxis: 0,
      type: 'column',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      color: '#008E43',
      opacity: 1,
      labels: {
        format: '{value} H'
      },
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }];

    this.chartEngineHours = this.chartService.generateColumnChartDates(theChartDataEngineHours, {}, theCategories, null, true, false, ' H', null, true)

    this.cd.markForCheck();
  }


  // Generate chart engineHours
  generateActivityReport(data) {

    const drivingValue = (this.accountUtilization.totalPureDrivingDurationInSeconds / this.accountUtilization.totalDurationInSeconds) * 100;
    const idlingValue = (this.accountUtilization.totalIdlingDurationInSeconds / this.accountUtilization.totalDurationInSeconds) * 100;
    const ignitionOffValue = 100 - drivingValue - idlingValue;

    const moving = drivingValue;
    const idling = idlingValue;
    const ignitionOff = ignitionOffValue;

    const dataActivity = [{
      name: this.translateService.instant('general.activity'),
      colorByPoint: true,
      minPointSize: 20,
      innerSize: '20%',
      zMin: 0,
      data: [{
        name: this.translateService.instant('enums.locationEventType.65536'),
        y: ignitionOff,
        color: '#971C24',
      }, {
        name: this.translateService.instant('enums.locationEventType.32768'),
        y: moving,
        color: '#008E43',
      }, {
        name: this.translateService.instant('general.idling'),
        y: idling,
        color: '#EE9234',
      }],
    }];

    this.chartActivity = this.chartService.generateVarPieChartDevice(dataActivity, {}, null, false)

    this.cd.markForCheck();
  }
}

