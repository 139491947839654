import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';

import { Observable, forkJoin } from 'rxjs';
import { AuthenticationService } from '../../services/authentication/authentication.service';

// Moment timezone
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';

import { getIconPath, roundAsString } from 'app/common/globals';
import { DriverService } from 'app/services/driver/driver.service';
import { Driver } from 'app/models/driver.model';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { getDefaultDpConfig } from 'app/common/gridhelper';
import { AccountService } from 'app/services/account/account.service';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-device-assignments',
  templateUrl: 'assignments.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceAssignmentsViewComponent implements OnInit {
  token: string;
  countrySelect = false;

  excludingColumns = ['timeStamp', 'assetDateStart', 'assetDateEnd', 'lastCommunication', 'input1', 'input2', 'input3', 'input4', 'input5', 'input6', 'output1', 'output2'];
  loading = false;

  permissionName = 'Drivers_View';
  constructorName = 'DriverScoreViewComponent';

  selectedResellerId;
  selectedAccountId;
  selectedAssetGroups;

  error: any;
  success: any;
  warning: any;

  timezoneIana: string;
  languageLoaded: boolean;
  queryParameterAccountId: number;
  queryParametersLoaded = false;

  assignments = [];

  currentPage;
  filter;
  itemsPerPage = 10;
  orderBy = 'created';
  orderByReversed = false;
  status;

  autoSelect = false;

  // Datepicker
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  to: any;
  from: any;
  daterangepickerModel: any[];
  mySubscription: any;

  constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private accountService: AccountService, private deviceTypeService: DeviceTypeService, private route: ActivatedRoute) {

    const that = this;

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana()

    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(1, 'week').startOf('day').toDate(),
      Moment().tz(this.timezoneIana).endOf('day').toDate()
    ];

    this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

    // Get all the date for dropdown boxes
    forkJoin([
      this.translateService.get('general.date'),
      this.deviceTypeService.getDeviceTypes()]
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.cd.markForCheck();

      },
      err => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  resellerChanged(event) {
    this.assignments = [];
    this.selectedAssetGroups = [];

    if (this.queryParameterAccountId > -1) {
      this.selectedAccountId = this.queryParameterAccountId;
      this.queryParameterAccountId = null;
    } else {
      this.selectedAccountId = null;
    }

    this.accountChanged(this.selectedAccountId)
  }

  accountChanged(event) {
    this.assignments = [];
    this.selectedAssetGroups = [];
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['orderBy'] === undefined) {
        this.queryParametersLoaded = true;
        return;
      }

      if (params['start'] > -1 && params['end'] > -1) {
        this.daterangepickerModel = [
          moment.utc(new Date(+params['start'])).tz(this.timezoneIana).startOf('day').toDate(),
          moment.utc(new Date(+params['end'])).tz(this.timezoneIana).endOf('day').toDate(),
        ];
      }

      this.autoSelect = true;

      this.orderBy = params['orderBy'];
      this.orderByReversed = true;

      this.queryParameterAccountId = params['accountId'] > -1 ? +params['accountId'] : null;
      this.selectedAccountId = this.queryParameterAccountId;
      this.queryParametersLoaded = true;

      this.cd.markForCheck();
    },
      err => {
        this.queryParametersLoaded = true;
        this.cd.markForCheck();
      });
  }

  getIcon(iconId) {
    return getIconPath(iconId)[2];
  }

  filterAssets() {
    // Filter groups
    if (this.selectedAssetGroups && this.selectedAssetGroups.length > 0) {
      this.assignments = this.assignments.filter(x => x.assetGroups.some(ag => this.selectedAssetGroups.includes(+ag.id)));
    }

    this.cd.markForCheck();
  }

  dateChanged(event) {
    const that = this;
    console.log('Changed date');
    if (event !== null) {
      this.getData();
    }
  }

  cancel() {
    this.mySubscription.unsubscribe();

    this.loading = false;

    this.warning = {};
    this.warning.warning = 'Call was cancelled.'
    this.warning.statusText = 'Warning';
  }

  getData() {
    this.loading = true;

    this.assignments = [];

    if (this.selectedAccountId == null) {
      this.loading = false;
      return;
    }

    this.mySubscription = this.accountService.getAccountTrips(this.selectedAccountId, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(result => {
      const assignments = result

      assignments.forEach(assignment => {
        assignment.maxSpeed ??= -1;
        assignment.beginTS = moment.utc(assignment.beginTS).tz(this.timezoneIana);
        assignment.endTS = moment.utc(assignment.endTS).tz(this.timezoneIana);
        assignment.duration = moment.duration(assignment.endTS.diff(assignment.beginTS)).asMinutes();
      });

      this.assignments = assignments;
      this.error = null;

      this.filterAssets();
      this.loading = false;
      this.cd.markForCheck();
    },
      err => {
        this.error = err;
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  actualRound(value, decimals) {
    return roundAsString(value, decimals);
  }
}
