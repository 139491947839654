import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridBase360Directive } from 'app/common/360Grid.base';
import { getGridButtons, getGridLanguages } from 'app/common/gridhelper';
import { StorageHelper } from 'app/common/storagehelper';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { ColorService } from 'app/services/common/color.service';
import { DeviceRoutingConfigurationService } from 'app/services/deviceroutingconfiguration/deviceroutingconfiguration.service';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-device-routing-configuration',
  templateUrl: 'deviceRoutingConfiguration.template.html'
})
export class DeviceRoutingConfigurationComponent extends GridBase360Directive implements OnInit {

  accountId;
  component;
  deviceId;
  loading = false;
  objects = [];
  error: any;
  success: any;

  filter;
  languageLoaded = false;
  timezoneIana: string;

  constructor(protected authenticationService: AuthenticationService, private deviceRoutingConfigurationService: DeviceRoutingConfigurationService, private translateService: TranslateService, protected storageHelper: StorageHelper, private colorService: ColorService) {
    super(storageHelper);

    this.accountId = this.authenticationService.getAccountId();

    this.timezoneIana = this.authenticationService.getTimeZoneIana();
  }

  ngOnInit() {

    this.translateService.get('general.date').subscribe(value => {
      this.initGrid();
      this.languageLoaded = true;
    });

  }

  initGrid(): void {

    console.log('Init grid');

    const that = this;

    const commonExportOptions = {
      modifier: {
        page: 'all',
        search: 'none'
      },
      columns: ['id_export:name', ':visible[tabindex]']
    };

    this.columns = [

      {
        name: 'imei',
        data: 'imei',
        defaultContent: '-',
        title: this.translateService.instant('general.imei')
      }, {
        name: 'routings',
        data: 'routings.length',
        defaultContent: '-',
        title: this.translateService.instant('general.routings')
      }, {
        name: 'destinationCluster',
        data: 'routings',
        defaultContent: '-',
        title: this.translateService.instant('general.destinationClusters'),
        render: function (data, type, row) {
          let value = '';
          if (data !== null) {
            $.each(data, function (k, v) {
              if (value !== '') {
                value += ', ';
              }
              value += that.translateService.instant('enums.clusters.' + v.destinationCluster);
            });

            return value;
          } else {
            return '';
          }
        }
      }, {
        name: 'routingConfiguration',
        data: 'routings',
        defaultContent: '-',
        visible: false,
        title: this.translateService.instant('general.routingConfiguration'),
        render: function (data, type, row) {
          let value = '';
          if (data !== null) {
            $.each(data, function (k, v) {
              if (value !== '') {
                value += ', ';
              }
              value += v.routingConfiguration;
            });

            return value;
          } else {
            return '';
          }
        }
      }, {
        name: 'rowKey',
        data: 'routings',
        defaultContent: '-',
        visible: false,
        title: this.translateService.instant('general.rowKey'),
        render: function (data, type, row) {
          let value = '';
          if (data !== null) {
            $.each(data, function (k, v) {
              if (value !== '') {
                value += ', ';
              }
              value += v.rowKey;
            });

            return value;
          } else {
            return '';
          }
        }
      },
      {
        name: 'timestamp',
        data: 'routings',
        defaultContent: '-',
        visible: false,
        title: this.translateService.instant('general.timestamp'),
        render: function (data, type, row) {
          let value = '';
          if (data !== null) {
            $.each(data, function (k, v) {
              if (value !== '') {
                value += ', ';
              }
              value += v.timestamp;
            });

            return value;
          } else {
            return '';
          }
        }
      },
      // {
      //   name: 'destinations',
      //   data: 'routings',
      //   defaultContent: '-',
      //   visible: false,
      //   title: this.translateService.instant('general.destinations'),
      //   render: function (data, type, row) {
      //     if (data !== null) {
      //       let table = '<table style="width:100%">';
      //       $.each(data, function (k, v) {
      //         const date = Moment.utc(v.timestamp)['tz'](that.timezoneIana)
      //         table += '<tr><td>' + v.destinationCluster + '</td><td>' + v.routingConfiguration + '</td><td>' + v.rowKey + '</td><td>' + v.Number + '</td><td><span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span></td></tr>';
      //       });
      //       return table + '</table>';
      //     } else {
      //       return '';
      //     }
      //   }
      // }
    ];

    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'devicerouting_overview', this.translateService.instant('menu.messagesoverview'), this.colorService),
      pagingType: 'simple_numbers',
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      order: [[0, 'desc']],
      stateSave: false,
      // stateSaveCallback: function (settings, data) {
      //     localStorage.setItem('Columns_' + that.constructor.name, JSON.stringify(data));
      // },
      // stateLoadCallback: function () {
      //     try {
      //         const columnSettings = JSON.parse(localStorage.getItem('Columns_' + that.constructor.name));
      //         that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
      //         return columnSettings;
      //     } catch (e) {
      //         console.log(e);
      //     }
      // },
      ajax: (dataTablesParameters: any, callback) => {
        $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        this.loading = true;
        this.deviceRoutingConfigurationService.getMDeviceRoutingConfiguration('').subscribe(resp => {
          callback({
            recordsTotal: resp.length,
            recordsFiltered: resp.length,
            data: resp
          });
        }, error => {
          this.success = null;
          this.error = error;
          this.loading = false;
        });
      },
      initComplete: function (settings, json) {
        that.loading = false;

        console.log('init complete');
        that.checkFilters();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, 200, -1], [10, 17, 25, 50, 200, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
      rowCallback: (row, data) => { }
    };
  }
}

