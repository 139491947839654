<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="device?.asset && device?.asset.accountId">
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}">{{'general.devices' | translate
                        }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.asset?.name ? device?.asset?.name :
                        device?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.ioSettings' | translate }}</li>
            </ol>
        </nav>

        <span class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix d-none d-md-inline-flex" title="Help"
                href="https://help.360locate.com/knowledge-base/device-io-configuration/" target="_blank"><i
                    class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span
                    class="d-none d-md-inline-flex">{{'general.needHelp' |
                    translate }}</span></a>
        </span>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device"></fh-device-details-navigation>
</div>



<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <!-- <form #deviceForm="ngForm"> -->
            <!-- <fh-error [error]="error" [success]="success"></fh-error> -->
            <fh-devicesetting-details (form)="form = $event" [formMode]="formMode" [device]="device"
                [settings]="device.settings" [loading]="loading || saving">
                <div postAction>
                    <fh-save-footer [valid]="!form.invalid && !device.invalidConfiguration" (onSave)="onSave()"
                        [allowEdit]="permissions['FleetManagement_DeviceSettingsView']"
                        (setFormMode)="setFormMode($event)" (onDelete)="onDelete()"
                        [allowDelete]="permissions['Devices_Delete']" [formMode]="formMode">
                    </fh-save-footer>
                </div>
            </fh-devicesetting-details>
            <!-- </form> -->
        </div>
    </div>
</fh-permission-handler>