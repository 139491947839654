import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'app/../environments/environment';
import { detectBody } from '../../../app.helpers';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { ColorService } from 'app/services/common/color.service';

declare var jQuery: any;

@Component({
  selector: 'fh-share',
  templateUrl: 'shareLayout.template.html'
})
export class ShareLayoutComponent {
  cssVariables: any;

  isFleetOverview = false;

  ngOnInit(): void {
    detectBody();

    const whitelabel = this.authenticationService.getWhitelabel();

    if (this.authenticationService.config.Debug) {
      console.log('using whitelabel: ', whitelabel);
    }

    if (whitelabel == null) {
      return;
    }

    this.colors.getThemeColors(whitelabel).subscribe(result => {
      this.colors.addTheme(result);
    });
  }

  constructor(private colors: ColorService, private authenticationService: AuthenticationService, private titleService: Title) {
    this.titleService.setTitle(environment.ApplicationName);
  }

  public onResize() {
    detectBody();
  }
}
