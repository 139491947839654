import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { EntityType } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { AssetGroupsService } from 'app/services/asset/assetGroups.service';
import { AssetGroup } from 'app/models/group.model';
import { DeviceService } from 'app/services/device/device.service';
import { AssetService } from 'app/services/asset/asset.service';
import { ScheduleAssignment } from 'app/models/triggerSchedule.model';
import { TriggerScheduleService } from 'app/services/triggerSchedule/triggerSchedule.service';
import { ConfirmationModalComponent } from '../shared/usercontrols/confirmationModal.component';

@Component({
    selector: 'fh-asset-group-details',
    templateUrl: 'groupDetails.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetGroupDetailsViewComponent implements OnInit {
    @ViewChild('confirmModal', { static: false }) confirmModal: ConfirmationModalComponent;
    sub: any;
    assetGroup: AssetGroup = new AssetGroup();
    device: any;
    tagType = EntityType.AssetGroup.valueOf();
    formMode = FormMode.read;

    loading = false;
    saving = false;
    loadingGroups = false;
    loadingUsers = false;

    success: any;
    error: any;

    permissions: {};

    permissionName = 'AssetGroups_View';

    deviceGroupsItems = [];
    deviceLink = 'DeviceDetails';
    assetGroupsItems = [];
    userItems = [];

    // Selector
    source = [];
    confirmed = [];
    format = {
        add: 'Add', remove: 'Remove', all: 'All', none: 'None', draggable: true
    };
    loadingAssets = false;
    display = 'assetSearchName';

    // ScheduleAssignments
    scheduleAssignment: ScheduleAssignment;
    scheduleAssignments: ScheduleAssignment[];

    constructor(private cd: ChangeDetectorRef, private assetService: AssetService, private authentication: AuthenticationService, private assetGroupsService: AssetGroupsService, private triggerScheduleService: TriggerScheduleService, private route: ActivatedRoute, private router: Router) {
        this.permissions = this.authentication.permissions;
        this.scheduleAssignments = [];
        this.scheduleAssignment = null;
    }

    ngOnInit() {
        this.scheduleAssignment = new ScheduleAssignment();
        this.loading = true;
        this.loadingGroups = true;
        this.loadingUsers = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.assetGroupsService.getAssetGroupById(id).subscribe(assetGroup => {
                this.assetGroup = assetGroup;
                this.scheduleAssignment.assetGroupId = this.assetGroup.id;
                this.loading = false;
                this.cd.markForCheck();

                this.assetGroupsService.getAssetGroupItemsById(0, id).subscribe(res => {
                    if (res) {
                        this.assetGroupsItems = res.sort((a, b) => a.name?.localeCompare(b.name));
                    }
                    this.loadingGroups = false;
                    this.cd.markForCheck();
                });

                this.assetGroupsService.getUserItemsById(id).subscribe(res => {
                    this.userItems = res;
                    this.loadingUsers = false;
                    this.cd.markForCheck();
                });
            }, error => {
                this.error = error;
                this.error.statusText = 'Error fetching assetGroup';
                this.loading = false;
                this.cd.markForCheck();
            });
        });
    }


    // Form

    onDelete() {
        this.loading = true;

        this.assetGroupsService.deleteAssetGroup(this.assetGroup).subscribe(result => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Asset group is successfully deleted.'
            };

            setTimeout(() => {
                this.router.navigate(['/AssetGroups/Overview']);
            }, 3000);
        }, error => {
            this.success = null;
            this.error = error;
            this.getAssetGroupInfo();
        });
    }

    onSave() {
        this.saving = true;

        this.assetGroup.items = this.confirmed.map((asset) => +asset.id);
        this.assetGroupsService.updateAssetGroup(this.assetGroup).subscribe(result => {
            this.saving = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Asset group is successfully updated.'
            };


            this.setFormMode(FormMode.read)
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getAssetGroupInfo();
        } else {
            if (this.assetGroup.accountId) {
                this.loadSelector();
            } else {
                console.log('Timing error ');
            }
        }
    }

    loadSelector() {

        this.source = this.assetGroupsItems;
        this.confirmed = this.assetGroupsItems;

        // Load items from service
        if (+this.assetGroup.accountId !== 0) {
            this.loadingAssets = true;

            this.assetService.getAssetsByAccount(this.assetGroup.accountId).subscribe(result => {
                if (this.assetGroup.groupType.toString() === '14' || this.assetGroup.groupType.toString() === '15') {
                    console.log('Filter isarchived == true');
                    this.source = result.filter(x => x.isArchived === true);
                } else {
                    this.source = result.filter(x => x.isArchived !== true);
                }

                this.loadingAssets = false;
                this.cd.markForCheck();
            });
        }

        this.cd.markForCheck();
    }

    getAssetGroupInfo() {
        if (this.loading !== true) {
            this.loading = true;
        }

        this.assetGroupsService.getAssetGroupById(this.assetGroup.id).subscribe(assetGroup => {
            this.assetGroup = assetGroup;
            this.loading = false;
            this.cd.markForCheck();
        });
    }
}
