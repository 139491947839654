import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { FhChartService } from '../../services/charts/charts.service';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from 'app/services/dashboard/dashboard.service';
import * as moment from 'moment-timezone';
import { SearchService } from 'app/services/search/search.service';
import { Router } from '@angular/router';
import { slideInOut } from 'app/common/animations';

import * as Highcharts from 'highcharts';
@Component({
  selector: 'fh-dashboard',
  templateUrl: 'dashboard.template.html',
  styleUrls: ['dashboard.template.css'],
  providers: [FhChartService, SearchService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    slideInOut
  ]
})
export class DashboardComponent implements OnDestroy, OnInit {
  Highcharts: typeof Highcharts = Highcharts;

  permissionName = 'FleetManagement_Dashboard';
  constructorName = 'DashboardComponent';
  timezoneIana: string;
  permissions: {};
  loading = false;
  loadingCount = false;
  loadingTopUsage = false;
  loadingTopEvents = false;
  loadingDeviceTypes = false;
  chartClients: any;

  activeDevices = 0;
  activeCustomers = 0;
  activeGeofences = 0;
  activeDrivers = 0;
  activeUsers = 0;
  activeWarehouses = 0;
  activeTags = 0;
  chartLocationCount: any;
  chartDistance: any;
  chartActiveDevices: any;
  chartTopUsage: any;
  chartDeviceTypes: any;

  error: any;

  loadingDeviceCount = false;
  showEvents = false;
  chartDeviceCount: any;
  deviceCountData = [];
  distanceChartData = [];
  announcements = [];
  chartTopEvents: any;
  accountDeviceCount = [];

  isImpersonated = false;
  impersonationId: string;
  culture;

  chartTriggerCount;
  loadingTriggerCount = false;

  chartApiUsage;
  loadingApiUsage = false;

  chartReportUsage;
  loadingReportUsage = false;

  public constructor(
    private router: Router, private translate: TranslateService, private chartService: FhChartService, private dashboardService: DashboardService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private translateService: TranslateService) {
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
    this.permissions = this.authenticationService.permissions;
    this.impersonationId = this.authenticationService.getId();

    const culture = this.authenticationService.getCultureLang();
    switch (culture) {
      case 'en':
        this.culture = 'en-US';
        break;
      case 'nl':
        this.culture = 'nl-NL';
        break;
      case 'ar':
        this.culture = 'ar-AE';
        break;
      default:
        this.culture = 'en-US';
        break;
    }
  }

  ngOnInit(): void {

    this.loading = true;
    this.loadingCount = true;
    this.loadingTopUsage = true;
    this.loadingDeviceCount = true;
    this.loadingDeviceTypes = true;
    this.loadingTriggerCount = true;

    this.dashboardService.getKPISCount().subscribe(kpiResults => {
      this.activeDevices = kpiResults.deviceCount;
      this.activeCustomers = kpiResults.customerCount;
      this.activeGeofences = kpiResults.geofenceCount;
      this.activeDrivers = kpiResults.driverCount;
      this.activeUsers = kpiResults.userCount;
      this.activeWarehouses = kpiResults.warehouseCount;
      this.activeTags = kpiResults.tagCount;
      this.cd.markForCheck();

      this.dashboardService.getAccouncements().subscribe(result => {
        this.announcements = result;
        this.cd.markForCheck();
      }, error => {
        this.error = error;
      });

      this.dashboardService.getDeviceCount().subscribe(result => {
        this.loading = false;
        this.accountDeviceCount = result;
        this.generateChartClients(result);
      }, error => {
        this.error = error;
      });

      this.dashboardService.getLocationCount().subscribe(result => {
        this.loadingCount = false;
        result = result.sort((a, b) => (a.summaryDate < b.summaryDate ? -1 : 1));
        this.distanceChartData = result;
        this.generateChartLocationCount(this.distanceChartData);
        this.cd.markForCheck();
      }, error => {
        this.loadingCount = false;
        this.error = error;
        this.cd.markForCheck();
      });

      this.dashboardService.getTriggerCount().subscribe(result => {
        this.loadingTriggerCount = false;
        this.generateChartTriggerCount(result);
        this.cd.markForCheck();
      }, error => {
        this.loadingTriggerCount = false;
        this.error = error;
        this.cd.markForCheck();
      });

      this.dashboardService.getReportCount().subscribe(result => {
        this.loadingReportUsage = false;
        this.generateReportCount(result);
        this.cd.markForCheck();
      }, error => {
        this.loadingReportUsage = false;
        this.error = error;
        this.cd.markForCheck();
      });

      this.dashboardService.getApiCount().subscribe(result => {
        this.loadingApiUsage = false;
        this.generateApiCount(result);
        this.cd.markForCheck();
      }, error => {
        this.loadingApiUsage = false;
        this.error = error;
        this.cd.markForCheck();
      });

      this.dashboardService.getTopUsage().subscribe(result => {
        this.loadingTopUsage = false;
        result = result.sort((a, b) => (a.summaryDate < b.summaryDate ? -1 : 1));
        this.generateChartTopUsage(result);
      }, error => {
        this.loadingTopUsage = false;
        this.error = error;
        this.cd.markForCheck();
      });

      this.dashboardService.getTopEvents().subscribe(result => {
        this.loadingTopEvents = false;
        result = result.sort((a, b) => (a.summaryDate < b.summaryDate ? -1 : 1));
        this.generateChartEvents(result);
      }, error => {
        this.loadingTopEvents = false;
        this.error = error;
        this.cd.markForCheck();
      });

      this.dashboardService.getDeviceTypes().subscribe(result => {
        this.loadingDeviceTypes = false;
        this.generateChartDeviceTypes(result);
      }, error => {
        this.loadingDeviceTypes = false;
        this.error = error;
        this.cd.markForCheck();
      });


      this.dashboardService.getDevicesAdded().subscribe((devices) => {
        devices = devices.sort((a, b) => (a.date < b.date ? -1 : 1));
        this.loadingDeviceCount = false;
        this.deviceCountData = devices;
        this.cd.markForCheck();
      });

    });

    this.isImpersonated = this.authenticationService.getIsImpersonated();
  }

  stopImpersonation() {
    this.router.navigate(['/']).then(response => {
      this.authenticationService.stopImpersonation();
    });
  }

  public ngOnDestroy(): any {
  }

  // Generate chart clients
  generateChartClients(data) {

    const theActualData = [];
    const theCategories = [];

    this.loading = false;

    // tslint:disable-next-line:forin
    data.forEach(value => {
      theActualData.push({ y: value.deviceCount, clientData: value.customerId });
      theCategories.push(value.name);
    });

    const theData = [{
      name: this.translateService.instant('general.devices'),
      data: theActualData,
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }];

    this.chartClients = this.chartService.generateColumnChart(theData, {}, theCategories);
    this.cd.markForCheck();
  }

  // Generate chart locationcount
  generateChartTopUsage(data) {

    const theActualDataTop = [];
    const theCategories = [];

    this.loading = false;

    // tslint:disable-next-line:forin
    data.forEach(value => {
      theActualDataTop.push({ y: value.locationCount, deviceId: value.fkDeviceId });
      theCategories.push(value.name);
    });

    const theDataTopUsage = [{
      name: this.translateService.instant('general.messages'),
      data: theActualDataTop
    }];

    this.chartTopUsage = this.chartService.generateTopUsageChartDevice(theDataTopUsage, {}, theCategories, false, '');
    this.cd.markForCheck();
  }

  // Generate chart locationcount
  generateChartEvents(data) {

    const theActualDataEvents = [];
    const theCategories = [];

    this.loading = false;

    // tslint:disable-next-line:forin
    data.forEach(value => {
      theActualDataEvents.push({ y: value.eventCount, deviceId: value.fkDeviceId });
      theCategories.push(value.name);
    });

    const theDataEvents = [{
      name: 'Events',
      data: theActualDataEvents
    }];

    this.chartTopEvents = this.chartService.generateColumnChartDevice(theDataEvents, {}, theCategories, false, '');
    this.cd.markForCheck();
  }

  // Generate chart deviceTypes
  generateChartDeviceTypes(data) {

    const theActualDataEvents = [];
    const theCategories = [];

    this.loading = false;

    // tslint:disable-next-line:forin
    data.forEach(value => {
      if (value.deviceCount !== 0) {
        theActualDataEvents.push({ name: value.name, y: value.deviceCountActive, deviceTypeId: value.id });
        theCategories.push(value.name);
      }
    });

    const theDataEvents = [{
      name: this.translateService.instant('general.devicetype'),
      colorByPoint: true,
      data: theActualDataEvents,
      fillOpacity: 0.5,
      opacity: 1,
      minPointSize: 20,
      innerSize: '20%',
      zMin: 0,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }];

    this.chartDeviceTypes = this.chartService.generatePieChartDevice(theDataEvents, {}, theCategories, false, '');
    this.cd.markForCheck();
  }

  // Generate chart TriggerCount
  generateChartTriggerCount(source) {
    const theEpisodesData = [];
    const theEmailNoticationsData = [];
    const theSmsNoticationsData = [];
    const theWebhookNoticationsData = [];
    const data = [];

    source.forEach(item => {
      theEpisodesData.push({ x: moment.utc(item.summaryDate).toDate().getTime(), y: Math.round((item.episodeCount + Number.EPSILON) * 100) / 100 });
      theEmailNoticationsData.push({ x: moment.utc(item.summaryDate).toDate().getTime(), y: Math.round((item.emailNotificationCount + Number.EPSILON) * 100) / 100 });
      theSmsNoticationsData.push({ x: moment.utc(item.summaryDate).toDate().getTime(), y: Math.round((item.smsNotificationCount + Number.EPSILON) * 100) / 100 });
      theWebhookNoticationsData.push({ x: moment.utc(item.summaryDate).toDate().getTime(), y: Math.round((item.webhookEndpointNotificationCount + Number.EPSILON) * 100) / 100 });
    });

    const theCategories = [];

    const theChartData = [{
      name: this.translateService.instant('general.episodes'),
      data: theEpisodesData,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.emailNotifications'),
      data: theEmailNoticationsData,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.smsNotifications'),
      data: theSmsNoticationsData,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.webhookNotifications'),
      data: theWebhookNoticationsData,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }];

    this.chartTriggerCount = this.chartService.generateColumnChartDates(theChartData, {}, theCategories, undefined, 'normal', true);

    this.cd.markForCheck();
  }


  // Generate chart report
  generateReportCount(source) {
    const theReportCountData = [];
    const theSSRSReportCountData = [];

    source.forEach(item => {
      theReportCountData.push({ x: moment.utc(item.summaryDate).toDate().getTime(), y: Math.round((item.reportCount + Number.EPSILON) * 100) / 100 });
      theSSRSReportCountData.push({ x: moment.utc(item.summaryDate).toDate().getTime(), y: Math.round((item.ssrsReportCount + Number.EPSILON) * 100) / 100 });
    });

    const theCategories = [];

    const theChartData = [{
      name: this.translateService.instant('general.reports'),
      data: theReportCountData,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    },
    {
      name: this.translateService.instant('general.ssrsReports'),
      data: theSSRSReportCountData,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }];

    this.chartReportUsage = this.chartService.generateColumnChartDates(theChartData, {}, theCategories, undefined, 'normal', true);

    this.cd.markForCheck();
  }

  // generate api count
  generateApiCount(source) {
    const theApiFmData = [];
    const theApo360Data = [];

    source.forEach(item => {
      theApiFmData.push({ x: moment.utc(item.summaryDate).toDate().getTime(), y: Math.round((item.apiCount + Number.EPSILON) * 100) / 100 });
      theApo360Data.push({ x: moment.utc(item.summaryDate).toDate().getTime(), y: Math.round((item.api360Count + Number.EPSILON) * 100) / 100 });
    });

    const theCategories = [];

    const theChartData = [{
      name: this.translateService.instant('general.apiUsage'),
      data: theApiFmData,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.api360Usage'),
      data: theApo360Data,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }];

    this.chartApiUsage = this.chartService.generateColumnChartDates(theChartData, {}, theCategories, undefined, 'normal', true);

    this.cd.markForCheck();
  }

  // Generate chart locationcount
  generateChartLocationCount(data) {

    const theLocationDataIgnitionOn = [];
    const theLocationDataIgnitionOff = [];
    const theLocationDataEvents = [];
    const theDistanceData = [];
    const theCategories = [];
    const theNonCommunicatingCountData = [];
    const theCommunicatingButNotMovingAssetCountData = [];
    const theMovingAssetCountData = [];
    const theDelayMoreThan10Data = [];

    // tslint:disable-next-line:forin
    data.forEach(value => {

      if (value.deviceCount < value.deviceCommunicatingCount) {
        value.deviceCount = value.deviceCommunicatingCount;
      }

      theLocationDataIgnitionOn.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.ignitionCount, clientData: value.customerId });
      theLocationDataIgnitionOff.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: (value.locationCount - value.ignitionCount), clientData: value.customerId });
      theLocationDataEvents.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.eventCount, clientData: value.customerId });
      theDistanceData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round((value.distanceInMeters + Number.EPSILON) / 1000), clientData: value.customerId });
      theNonCommunicatingCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.assetCount - value.deviceCommunicatingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
      theCommunicatingButNotMovingAssetCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.deviceCommunicatingCount - value.deviceMovingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
      theMovingAssetCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.deviceMovingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
      theDelayMoreThan10Data.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.delayMoreThan10, clientData: value.customerId });
    });

    const theChartDataLocationCount = [{
      name: this.translateService.instant('enums.locationEventType.32768'),
      data: theLocationDataIgnitionOn,
      type: 'column',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      color: undefined,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('enums.locationEventType.65536'),
      data: theLocationDataIgnitionOff,
      type: 'column',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.events'),
      data: theLocationDataEvents,
      type: 'column',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }];

    if (this.permissions['Platform_IsReseller']) {
      theChartDataLocationCount.push({
        name: this.translateService.instant('general.delayMoreThan10'),
        data: theDelayMoreThan10Data,
        type: 'line',
        dashStyle: 'dash',
        fillOpacity: 0.5,
        opacity: 1,
        color: '#971c24',
        marker: {
          enabled: false,
          lineWidth: 1,
          symbol: 'square'
        },
      });
    }

    const theChartDataDistance = [{
      name: this.translateService.instant('general.distance'),
      data: theDistanceData,
      yAxis: 0,
      fillOpacity: 0.5,
      opacity: 0.6,
      type: 'areaspline'
    }];

    const theChartDataDeviceCount = [{
      name: this.translateService.instant('general.movement'),
      data: theMovingAssetCountData,
      yAxis: 0,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.nomovement'),
      data: theCommunicatingButNotMovingAssetCountData,
      yAxis: 0,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }, {
      name: this.translateService.instant('general.notcommunicating'),
      data: theNonCommunicatingCountData,
      yAxis: 0,
      type: 'area',
      dashStyle: 'dash',
      fillOpacity: 0.5,
      opacity: 1,
      marker: {
        enabled: false,
        lineWidth: 1,
        symbol: 'square'
      },
    }];
    this.chartLocationCount = this.chartService.generateColumnChartDates(theChartDataLocationCount, {}, theCategories, undefined, 'normal', true);
    this.chartDistance = this.chartService.generateColumnChartDates(theChartDataDistance, {}, theCategories, undefined, undefined, false, ' Km');
    this.chartActiveDevices = this.chartService.generateColumnChartDates(theChartDataDeviceCount, {}, theCategories, undefined, 'normal', true);

    this.cd.markForCheck();
  }
}
