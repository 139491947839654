<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Accounts/Overview">{{'general.accounts' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/AccountDetails/Index/{{account?.id}}">{{account?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.externalApi' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-account-details-navigation [accountId]="accountId"></fh-account-details-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [warning]="warning" [success]="success" [autoRemove]=false></fh-error>
        </div>
    </div>
    <div class="row" data-equalizer="">
        <div class="col-lg-6">

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-link"></i> {{'general.company' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.name' | translate}}
                                </td>
                                <td class="row_content">
                                    <input type="text" [readonly]=true name="ac_name" class="form-control table-inline"
                                        [(ngModel)]="account.name" required #name="ngModel">
                                </td>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-link"></i> {{'general.waslStatus' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">{{ 'general.connected' | translate}}</td>
                                <td class="row_content">{{ (account.properties.wasl.registerDateWasl ?
                                    'general.true' : 'general.false')|
                                    translate}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.date' | translate}}</td>
                                <td class="row_content">{{ account.properties.wasl.registerDateWasl |
                                    amDateFormat: 'LLL'}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.reference' | translate}}
                                </td>
                                <td class="row_content">
                                    {{ account.properties.wasl.referenceKey }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [disabled]=" waslForm.invalid" (click)="register('WASL')"><i
                            class="fa fa-fw fa-edit"></i>
                        {{'general.register' | translate }}</button>

                    <button class="btn btn-danger" *ngIf="account.properties.wasl.registerDateWasl"
                        (click)="unRegister('WASL')"><i class="fa fa-fw fa-times"></i>
                        {{'general.unRegister' | translate }}</button>

                    <button class="btn btn-primary" *ngIf="account.properties.wasl.registerDateWasl"
                        (click)="inquire('WASL')"><i class="fa fa-fw fa-broadcast-tower"></i>
                        {{'general.inquire' | translate }}</button>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-link"></i> {{'general.sfdaStatus' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">{{ 'general.connected' | translate}}</td>
                                <td class="row_content">{{ (account.properties.wasl.registerDateSfda ?
                                    'general.true' : 'general.false')|
                                    translate}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.date' | translate}}</td>
                                <td class="row_content">{{ account.properties.wasl.registerDateSfda |
                                    amDateFormat: 'LLL'}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.reference' | translate}}
                                </td>
                                <td class="row_content">
                                    {{ account.properties.wasl.referenceKey }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [disabled]="waslForm.invalid" (click)="register('SFDA')"><i
                            class="fa fa-fw fa-edit"></i>
                        {{'general.register' | translate }}</button>

                    <button class="btn btn-danger" *ngIf="account.properties.wasl.registerDateSfda"
                        (click)="unRegister('SFDA')"><i class="fa fa-fw fa-times"></i>
                        {{'general.unRegister' | translate }}</button>

                    <button class="btn btn-primary" *ngIf="account.properties.wasl.registerDateSfda"
                        (click)="inquire('SFDA')"><i class="fa fa-fw fa-broadcast-tower"></i>
                        {{'general.inquire' | translate }}</button>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-link"></i> {{'general.towStatus' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">{{ 'general.connected' | translate}}</td>
                                <td class="row_content">{{ (account.properties.wasl.registerDateTow ?
                                    'general.true' : 'general.false')|
                                    translate}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.date' | translate}}</td>
                                <td class="row_content">{{ account.properties.wasl.registerDateTow |
                                    amDateFormat: 'LLL'}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.reference' | translate}}
                                </td>
                                <td class="row_content">
                                    {{ account.properties.wasl.referenceKey }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [disabled]="waslForm.invalid" (click)="register('TOW_CAR')"><i
                            class="fa fa-fw fa-edit"></i>
                        {{'general.register' | translate }}</button>

                    <button class="btn btn-danger" *ngIf="account.properties.wasl.registerDateTow"
                        (click)="unRegister('TOW_CAR')"><i class="fa fa-fw fa-times"></i>
                        {{'general.unRegister' | translate }}</button>

                    <button class="btn btn-primary" *ngIf="account.properties.wasl.registerDateTow"
                        (click)="inquire('TOW_CAR')"><i class="fa fa-fw fa-broadcast-tower"></i>
                        {{'general.inquire' | translate }}</button>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-link"></i> {{'general.specialityTransportStatus' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">{{ 'general.connected' | translate}}</td>
                                <td class="row_content">{{ (account.properties.wasl.registerDateSpecialityTransport
                                    ?
                                    'general.true' : 'general.false')|
                                    translate}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.date' | translate}}</td>
                                <td class="row_content">{{ account.properties.wasl.registerDateSpecialityTransport
                                    | amDateFormat:
                                    'LLL'}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.reference' | translate}}
                                </td>
                                <td class="row_content">
                                    {{ account.properties.wasl.referenceKey }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [disabled]="waslForm.invalid" *ngIf="permissions['Wasl_Add']"
                        (click)="register('SPECIALITY_TRANSPORT')"><i class="fa fa-fw fa-edit"></i>
                        {{'general.register' | translate }}</button>

                    <button class="btn btn-danger"
                        *ngIf="account.properties.wasl.registerDateSpecialityTransport && permissions['Wasl_Delete']"
                        (click)="unRegister('SPECIALITY_TRANSPORT')"><i class="fa fa-fw fa-times"></i>
                        {{'general.unRegister' | translate }}</button>

                    <button class="btn btn-primary"
                        *ngIf="account.properties.wasl.registerDateSpecialityTransport && permissions['Wasl_Inquire']"
                        (click)="inquire('SPECIALITY_TRANSPORT')"><i class="fa fa-fw fa-broadcast-tower"></i>
                        {{'general.inquire' | translate }}</button>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-link"></i> {{'general.busRentalStatus' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">{{ 'general.connected' | translate}}</td>
                                <td class="row_content">{{ (account.properties.wasl.registerDateBusRental
                                    ?
                                    'general.true' : 'general.false')|
                                    translate}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.date' | translate}}</td>
                                <td class="row_content">{{ account.properties.wasl.registerDateBusRental
                                    | amDateFormat:
                                    'LLL'}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.reference' | translate}}
                                </td>
                                <td class="row_content">
                                    {{ account.properties.wasl.referenceKey }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [disabled]="waslForm.invalid" *ngIf="permissions['Wasl_Add']"
                        (click)="register('BUS_RENTAL')"><i class="fa fa-fw fa-edit"></i>
                        {{'general.register' | translate }}</button>

                    <button class="btn btn-danger"
                        *ngIf="account.properties.wasl.registerDateBusRental && permissions['Wasl_Delete']"
                        (click)="unRegister('BUS_RENTAL')"><i class="fa fa-fw fa-times"></i>
                        {{'general.unRegister' | translate }}</button>

                    <button class="btn btn-primary"
                        *ngIf="account.properties.wasl.registerDateBusRental && permissions['Wasl_Inquire']"
                        (click)="inquire('BUS_RENTAL')"><i class="fa fa-fw fa-broadcast-tower"></i>
                        {{'general.inquire' | translate }}</button>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-link"></i> {{'general.educationalTransportStatus' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">{{ 'general.connected' | translate}}</td>
                                <td class="row_content">{{
                                    (account.properties.wasl.registerDateEducationalTransport
                                    ?
                                    'general.true' : 'general.false')|
                                    translate}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.date' | translate}}</td>
                                <td class="row_content">{{ account.properties.wasl.registerDateEducationalTransport
                                    | amDateFormat:
                                    'LLL'}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.reference' | translate}}
                                </td>
                                <td class="row_content">
                                    {{ account.properties.wasl.referenceKey }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [disabled]="waslForm.invalid" *ngIf="permissions['Wasl_Add']"
                        (click)="register('EDUCATIONAL_TRANSPORT')"><i class="fa fa-fw fa-edit"></i>
                        {{'general.register' | translate }}</button>

                    <button class="btn btn-danger"
                        *ngIf="account.properties.wasl.registerDateEducationalTransport && permissions['Wasl_Delete']"
                        (click)="unRegister('EDUCATIONAL_TRANSPORT')"><i class="fa fa-fw fa-times"></i>
                        {{'general.unRegister' | translate }}</button>

                    <button class="btn btn-primary"
                        *ngIf="account.properties.wasl.registerDateEducationalTransport && permissions['Wasl_Inquire']"
                        (click)="inquire('EDUCATIONAL_TRANSPORT')"><i class="fa fa-fw fa-broadcast-tower"></i>
                        {{'general.inquire' | translate }}</button>
                </div>
            </div>
        </div>

        <div class="col-lg-6">

            <form #waslForm="ngForm">
                <div *ngIf="!loading && waslForm.invalid" class="alert alert-warning shadow">
                    <p *ngIf="waslForm.invalid">{{'general.waslFormInvalid' | translate }}</p>
                </div>

                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-link"></i> {{'general.properties' | translate }}
                        </h5>
                    </div>
                    <ngx-loading [show]="loading"></ngx-loading>
                    <fh-wasl-account-details [account]="account" [formMode]="formMode">
                    </fh-wasl-account-details>
                    <fh-save-footer [valid]="!waslForm.invalid" [allowSave]=false (setFormMode)="setFormMode($event)"
                        [formMode]="formMode"></fh-save-footer>
                </div>
            </form>
        </div>
    </div>
</div>