import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { Maintenance, MaintenanceOccurrence, MaintenanceType } from 'app/models/maintenance.model';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { MaintenanceTypesService } from 'app/services/maintenance/maintenanceTypes.service';
import { MaintenanceService } from 'app/services/maintenance/maintenance.service';
import { TranslateService } from '@ngx-translate/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { AccountService } from 'app/services/account/account.service';
window['moment'] = Moment;
mTZ()
@Component({
  selector: 'fh-maintenance-add',
  templateUrl: 'add.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceAddViewComponent implements OnInit {
  token: string;
  loading = false;
  maintenance = new Maintenance();

  maintenanceTypes: MaintenanceType[] = [];
  loadingMaintenanceTypes = false;
  loadingAccounts = false;

  formMode: FormMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string; };
  selectedResellerId;
  permissionName = 'Maintenances_Add';
  asset;

  // Datepicker
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  constructor(private translateServe: TranslateService, private route: ActivatedRoute, private accountService: AccountService, private maintenanceService: MaintenanceService, private maintenanceTypesService: MaintenanceTypesService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private router: Router) {
    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
  }

  ngOnInit(): void {
    // Datepicker
    this.dpConfig.containerClass = 'theme-default'; // or whatever color
    this.dpConfig.dateInputFormat = 'lll';
    this.dpConfig.isAnimated = true;
    this.dpConfig.withTimepicker = true;
    this.dpConfig.keepDatepickerOpened = true;

    this.maintenance = new Maintenance();
    this.maintenance.occurrenceDate = new Date();

    this.addOccurrence();
    this.cd.markForCheck();

    this.checkRoute();
  }

  handleAssetChanged(asset) {
    this.maintenance.assetId = asset ? asset.id : undefined
    this.asset = asset;
  }

  // Check route to apply filters
  checkRoute() {
    this.route.queryParams.subscribe(params => {
      const accountId = params['accountId'];
      const assetId = params['assetId'];
      const resellerId = params['resellerId'];

      if (resellerId) {
        this.selectedResellerId = +resellerId;
      }

      if (accountId) {
        this.maintenance.accountId = +accountId;
      }

      if (assetId) {
        this.maintenance.assetId = +assetId;
      }
    });
  }

  onInsert() {
    this.loading = true;
    let itemsSaved = 0;

    this.maintenance.occurrences.forEach(occurrence => {

      occurrence.assetId = this.maintenance.assetId;
      occurrence.maintenanceTypeId = this.maintenance.maintenanceTypeId;
      occurrence.occurrenceDate = this.maintenance.occurrenceDate;
      occurrence.supplierName = this.maintenance.supplierName;
      occurrence.localPurchaseOrderNumber = this.maintenance.localPurchaseOrderNumber;
      occurrence.materialReceiptInspectionVoucherNumber = this.maintenance.materialReceiptInspectionVoucherNumber;
      occurrence.controlNumber = this.maintenance.controlNumber;
      occurrence.accountId = this.maintenance.accountId;

      occurrence.reference = this.maintenance.reference;
      occurrence.comments = this.maintenance.comments;
      occurrence.dateCalibration = this.maintenance.dateCalibration;
      occurrence.realAssetOdo = this.maintenance.realAssetOdo;
      occurrence.description = this.maintenance.description;

      itemsSaved++;

      this.maintenanceService.saveMaintenance(occurrence).subscribe(result => {
      }, error => {
        this.error = error;
        this.cd.markForCheck();
      });
    });

    this.success = {
      statusText: 'Success for (' + itemsSaved + ') orders',
      success: this.translateServe.instant('general.saveSuccessRedirect', { entity: 'maintenance' })
    };

    this.cd.markForCheck();

    setTimeout(() => {
      this.router.navigate(['/Maintenances/Overview']);
    }, 2000);

    this.loading = false;
  }

  addOccurrence() {
    const newOccurrence = new MaintenanceOccurrence();
    newOccurrence.id = (Math.floor(Math.random() * (999999 - 100000)) + 100000).toString();

    this.maintenance.occurrences.push(newOccurrence);
  }

  deleteOccurrence(occurrence: MaintenanceOccurrence) {
    const index: number = this.maintenance.occurrences.indexOf(occurrence);
    if (index !== -1) {
      this.maintenance.occurrences.splice(index, 1);
    }

    this.cd.markForCheck();
  }

  resellerChanged(resellerId) {
    this.maintenance.accountId = null;
    this.maintenance.assetId = null;
    this.maintenance.maintenanceTypeId = null;

    this.selectedResellerId = resellerId;

    this.accountChanged(null);

    setTimeout(() => {
      this.cd.markForCheck();
    }, 100);
  }

  accountChanged(accountId) {
    this.maintenance.maintenanceTypeId = null;
    this.maintenance.assetId = null;
  }

  log(text) {
    console.log(text);
  }
}
