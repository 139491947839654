import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageType } from 'app/common/enums';
import { contains, getIconPath } from 'app/common/globals';
import { StorageHelper } from 'app/common/storagehelper';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

@Component({
    selector: 'fh-device-details-navigation',
    templateUrl: 'deviceDetailsNavigation.template.html'
})

export class DeviceDetailsNavigationComponent implements OnInit {
    @Input() deviceId;
    @Input() device;
    sub: any;
    activeId: any;

    permissions = {};
    permissionKeys: string[];

    storageType = StorageType.LocalStorage;

    hideTrailers: boolean;
    hideProjects: boolean;

    hasDataPermissions = false;
    dataParmissions = ['FleetManagement_Messages', 'FleetManagement_Notifications', 'FleetManagement_Episodes', 'FleetManagement_GeofenceStates', 'FleetManagement_Sensors', 'FleetManagement_DeviceUtilization', 'FleetManagement_RawData', 'FleetManagement_Log']

    hasSettingPermissions = false;
    settingPermissions = ['FleetManagement_DeviceSettingsView', 'FleetManagement_OdoView', 'FleetManagement_EngineHourView', 'FleetManagement_ImmobilizeVehicle', 'FleetManagement_Sensors', 'FuelEvents_View', 'Maintenances_View', 'FleetManagement_External'];

    constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService, private storageHelper: StorageHelper) {
        this.permissions = this.authenticationService.permissions;
        this.permissionKeys = Object.keys(this.permissions);

        this.hasDataPermissions = this.permissionKeys.some(e => contains(this.dataParmissions, e));

        this.hasSettingPermissions = this.permissionKeys.some(e => contains(this.settingPermissions, e));

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideTrailers').subscribe((result) => {
            this.hideTrailers = JSON.parse(result) === true;
        });

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideProjects').subscribe((result) => {
            this.hideProjects = JSON.parse(result) === true;
        });
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.activeId = id;
        });
    }

    activeRoute(routename: string): boolean {
        const active = this.router.url.indexOf(routename) !== -1;
        return active;
    }

    isData() {
        const routes = ['Log', 'Route', 'Messages', 'Notifications', 'Episodes', 'GeofenceStates', 'GeofenceEvents', 'GeofenceEpisodes', 'SensorsHistory', 'Utilization', 'RawData'];

        let routeActive = false;

        routes.forEach(route => {
            const active = this.router.url.indexOf(route);
            if (active > -1) {
                routeActive = true;
            }
        });

        return routeActive;
    }

    isSettings() {
        const routes = ['Settings', 'Changes', 'Odo', 'EngineHour', 'Immobilization', 'SensorSettings', 'Fuel', 'Maintenance', 'WorkingHours', 'External'];

        let routeActive = false;

        routes.forEach(route => {
            const active = this.router.url.indexOf(route);
            if (active > -1) {
                routeActive = true;
            }
        });

        return routeActive;
    }

    navigateData() {
        if (this.permissions['FleetManagement_Messages']) {
            this.router.navigate(['/DeviceDetails/Messages/' + this.deviceId]);
            return;
        }

        if (this.permissions['FleetManagement_Notifications']) {
            this.router.navigate(['/DeviceDetails/Notifications/' + this.deviceId]);
            return;
        }

        if (this.permissions['FleetManagement_Episodes']) {
            this.router.navigate(['/DeviceDetails/Episodes/' + this.deviceId]);
            return;
        }

        if (this.permissions['FleetManagement_GeofenceStates']) {
            this.router.navigate(['/DeviceDetails/GeofenceStates/' + this.deviceId]);
            return;
        }

        if (this.permissions['FleetManagement_Sensors']) {
            this.router.navigate(['/DeviceDetails/SensorsHistory/' + this.deviceId]);
            return;
        }

        if (this.permissions['FleetManagement_DeviceUtilization']) {
            this.router.navigate(['/DeviceDetails/Utilization/' + this.deviceId]);
            return;
        }

        if (this.permissions['FleetManagement_RawData']) {
            this.router.navigate(['/DeviceDetails/RawData/' + this.deviceId]);
            return;
        }

        if (this.permissions['FleetManagement_Log']) {
            this.router.navigate(['/DeviceDetails/Log/' + this.deviceId]);
            return;
        }
    }

    navigateSettings() {
        if (this.permissions['FleetManagement_DeviceSettingsView']) {
            this.router.navigate(['/DeviceDetails/Settings/' + this.deviceId]);
            return;
        }

        if (this.permissions['FleetManagement_DeviceSettingsView']) {
            this.router.navigate(['/DeviceDetails/Changes/' + this.deviceId]);
            return;
        }

        if (this.permissions['FleetManagement_OdoView']) {
            this.router.navigate(['/DeviceDetails/Odo/' + this.deviceId]);
            return;
        }

        if (this.permissions['FleetManagement_EngineHourView']) {
            this.router.navigate(['/DeviceDetails/EngineHour/' + this.deviceId]);
            return;
        }

        if (this.permissions['FleetManagement_ImmobilizeVehicle']) {
            this.router.navigate(['/DeviceDetails/Immobilization/' + this.deviceId]);
            return;
        }

        if (this.permissions['FleetManagement_Sensors']) {
            this.router.navigate(['/DeviceDetails/SensorSettings/' + this.deviceId]);
            return;
        }

        if (this.permissions['FuelEvents_View']) {
            this.router.navigate(['/DeviceDetails/Fuel/' + this.deviceId]);
            return;
        }

        if (this.permissions['Maintenances_View']) {
            this.router.navigate(['/DeviceDetails/Maintenance/' + this.deviceId]);
            return;
        }

        if (this.permissions['FleetManagement_External']) {
            this.router.navigate(['/DeviceDetails/External/' + this.deviceId]);
            return;
        }
    }

    formatIconId(iconId) {
        return '<img style="position: relative;" src="' + getIconPath(iconId)[2] + '">';
    }
}
