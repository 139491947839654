<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="device?.asset && device?.asset.accountId">
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}">{{'general.devices' | translate
                        }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.asset?.name ? device?.asset?.name :
                        device?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.settingsChanges' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device">
        <!-- <li *ngIf="device?.id">
            <a (click)="driverChooser.showModal()">
                <i class="fas fa-fw fa-cubes"></i>
                <div>{{'general.add' | translate }}</div>
            </a>
        </li> -->
    </fh-device-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">

            <div class="col-lg-9">
                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-cubes"></i> {{'general.settingsChanges' | translate }}
                        </h5>

                        <span class="float-right">
                            <div class="filter-form d-none d-md-block">
                                <input type="text" class="form-control table-inline top-filter d-none d-md-block"
                                    (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; min-height:72px; overflow-y: hidden">
                        <ngx-loading [show]="loadingSettingChanges"></ngx-loading>
                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th title="{{ 'general.date' | translate}}">
                                        {{ 'general.date' | translate}}
                                    </th>
                                    <th title="{{ 'general.sent' | translate}}">
                                        {{ 'general.sent' | translate}}
                                    </th>
                                    <th title="{{ 'general.applied' | translate}}">
                                        {{ 'general.applied' | translate}}
                                    </th>
                                    <th title="{{ 'general.setting' | translate}}">
                                        {{ 'general.setting' | translate}}
                                    </th>
                                    <th title="{{ 'general.value' | translate}}">
                                        {{ 'general.value' | translate}}
                                    </th>
                                    <th title="{{ 'general.response' | translate}}">
                                        {{ 'general.response' | translate}}
                                    </th>
                                    <th title="{{ 'general.status' | translate}}">
                                        {{ 'general.status' | translate}}
                                    </th>
                                    <th title="{{ 'general.retryCount' | translate}}">
                                        {{ 'general.retryCount' | translate}}
                                    </th>
                                    <th style="width:80px"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="settingChanges.length == 0 && !loadingSettingChanges">
                                    <tr>
                                        <td>{{ 'general.noDataFound' | translate}}</td>
                                    </tr>
                                </ng-container>
                                <ng-container
                                    *ngFor="let settingChange of settingChanges | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage }">
                                    <tr (click)="selectedChange = settingChange">
                                        <td title="{{ settingChange.timestamp?.toLocaleString() }}">
                                            {{ settingChange.timestamp
                                            ? (settingChange.timestamp | amDateFormat: 'LLL')
                                            : '-'
                                            }}
                                        </td>
                                        <td title="{{ settingChange.changeSentDate?.toLocaleString() }}"
                                            *ngIf="settingChange.changeSentDate">
                                            <i class="fa fa-check fa-fw " style="color: rgb(0, 142, 67);"
                                                title="{{(settingChange.changeSentDate | amDateFormat: 'LLL')}}"></i>
                                        </td>
                                        <td title="{{ settingChange.changeSentDate?.toLocaleString() }}"
                                            *ngIf="!settingChange.changeSentDate">
                                            <i class="fa fa-times fa-fw " style="color: rgb(151, 28, 36);"></i>
                                        </td>

                                        <td title="{{ settingChange.changeAppliedDate?.toLocaleString() }}"
                                            *ngIf="settingChange.changeAppliedDate">
                                            <i class="fa fa-check fa-fw " style="color: rgb(0, 142, 67);"
                                                title="{{(settingChange.changeAppliedDate | amDateFormat: 'LLL')}}"></i>
                                        </td>
                                        <td title="{{ settingChange.changeAppliedDate?.toLocaleString() }}"
                                            *ngIf="!settingChange.changeAppliedDate">
                                            <i class="fa fa-times fa-fw " style="color: rgb(151, 28, 36);"></i>
                                        </td>
                                        <td>
                                            {{ ('enums.deviceSettingChange.' + settingChange.settingId) | translate }}
                                        </td>
                                        <td title="{{ settingChange.value }}" style="width:150px;">
                                            <span class="elipses_overflow" style="position: absolute; width:150px;">{{
                                                settingChange.value }}</span>
                                        </td>
                                        <td title="{{ settingChange.response }}" style="width:150px;">
                                            <span class="elipses_overflow" style="position: absolute; width:150px;">{{
                                                settingChange.response }}</span>
                                        </td>
                                        <td>
                                            {{ ('enums.deviceSettingChangeStatus.' +settingChange.status) | translate }}
                                        </td>
                                        <td>
                                            {{ settingChange.retryCount }}
                                        </td>
                                        <td>
                                            <a class="btn btn-primary btn-grid"
                                                title="{{ 'general.resendSettingChange' | translate}}"
                                                (click)="resendChanges(settingChange.id)">
                                                <i class="fa fa-fw fa-plane-departure"></i>
                                            </a>
                                            <a *ngIf="!isStatusScheduled(settingChange)" class="btn btn-danger btn-grid"
                                                title="{{ 'general.deleteSettingChange' | translate}}"
                                                (click)="showDelete(settingChange.id)">
                                                <i class="fa fa-fw fa-trash-alt"></i>
                                            </a>
                                            <a *ngIf="isStatusScheduled(settingChange)" class="btn btn-danger btn-grid"
                                                title="{{ 'general.cancelSettingChange' | translate}}"
                                                (click)="showCancel(settingChange.id)">
                                                <i class="fa fa-fw fa-plane-arrival"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                            'grid.previous' | translate }}</a>
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value">
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fa fa-signal"></i> {{ 'general.commands' | translate }}</h5>
                    </div>
                    <div class="ibox-footer" style="position: relative">
                        <button class="btn btn-primary" (click)="refresh();"
                            title="{{ 'general.refresh' | translate }}"><i class="fa fa-fw fa-recycle"></i>
                            {{'general.refresh' | translate }}</button>
                    </div>
                </div>

                <form #userForm="ngForm">
                    <div class="ibox">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-cubes"></i> {{'general.applySetting' | translate }}
                            </h5>
                        </div>
                        <div class="ibox-content slick" style="position: relative;">
                            <ngx-loading [show]="loadingSettingChanges"></ngx-loading>
                            <fh-devicesettingchanges [deviceSettingChange]="deviceSettingChange" [device]="device">
                            </fh-devicesettingchanges>
                        </div>
                        <div class="ibox-footer noselect">
                            <button class="btn btn-primary" style="width:100%" (click)="addNewChange()"
                                [disabled]="userForm.invalid"> <i class="fas fa-fw fa-plus"></i>
                                {{'general.add' | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-confirmation-modal #deleteModal (onFinish)="deleteChanges($event)"></fh-confirmation-modal>
<fh-confirmation-modal #cancelModal [message]="'general.warningCancel'" (onFinish)="cancelChanges($event)">
</fh-confirmation-modal>