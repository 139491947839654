import { DeviceType } from './devicetype.model';
import { Location } from './location.model';
import { Asset } from './asset.model';
import { Moment } from 'moment';
import { Driver } from './driver.model';


export class DeviceSettings {
    public id: any;
    public inputPorts: any[] = [];
    public analogPorts: any[] = [];
    public outputPorts: any[] = [];
    public deviceAuxiliary: any[] = [];

    public canBusParameters: any[] = [];

    public externalPower: any;
    public updatedParams: any[] = [];
    public ignition: any;

    public isStickyDriver: boolean;

    public permittedIdlingInMinutes: number;
    public idlingCalculationMethod: number;
    public idlingRpmDuration: number;
    public idlingRpmIsActive: boolean;
    public idlingRpmValue: number;
    public idlingInputDuration: number;
    public idlingInputIsActive: boolean;
    public idlingInputValue: number;
    public idlingVoltageDuration: number;
    public idlingVoltageIsActive: boolean;
    public idlingVoltageValue: number;

    public homeIntervalInSeconds: number;
    public roamingIntervalInSeconds: number;
    public notCommunicatingThresholdInMinutes: number;
    public reportExternalPowerLostImmediately: boolean;

    public sensorCalibrationPoints: DeviceCalibrationPoints[];
}
export class Device {
    public id: string;
    public createdDate: Date;
    public customFields: any;
    public sensors: any;
    public modifiedDate: Date;
    public name: string;
    public assetSearchName: string;
    public assetDisplayLabel: string;
    public phone: string;
    public referenceId: string;
    public referenceLink: string;
    public unitId: string;
    public unitId2: string;
    public distance: number;
    public deviceType: DeviceType;

    public lastPosition: Location;
    public manufacturer: any;
    public manufacturerName: any;
    public deviceName: any;
    public modelName: any;
    public modelId: string;
    public issueCount: any;

    public note: any;
    public isNoteWarning: false;
    public isFlagged: boolean;

    public platformId: string;
    public platformName: string;
    public accountId: any;
    public companyName: any;
    public modelThumb: any;
    public endDate: Date | null;
    public isEnded: boolean;
    public accountPlatformId: any;
    public accountPlatformName: any;

    public simIdentifier: string;
    public msisdn: string;
    public imsi: string;

    public hasIssueCalculationEnabled: boolean;
    public firmware: string;
    public deviceTypeNameFull: string;
    public deviceTypeId: string;
    public theMarker: any;
    public speed: any;
    public hasGpsFix: any;

    public asset: Asset;
    public deviceState: any;
    public headingInDegrees: any;
    public isActive: any;
    public isArchived: any;

    public resellerDescription: any;
    public resellerId: any;

    public settings: DeviceSettings = new DeviceSettings();
    public activationDate: any;
    public expirationDate: any;

    public deviceInventoryStatus: any;
    public simActivationStatus: any;
    public deviceStatus: any;

    public ignition: any;
    public externalPower: any;
    public input1: any;
    public input2: any;
    public input3: any;
    public input4: any;
    public output1: any;
    public output2: any;

    public deviceAuxiliary: any;
    public canBusParameters: any;

    public driver: Driver;
    public score: any;

    public location: DeviceLocation;

    public invalidConfiguration: boolean;

    public properties = new Properties();
    scannedDriver: Driver;
}

export class Properties {
    public sensorTemplateSettings = new SensorsTemplateSettings();
}

export class SensorsTemplateSettings {
    public temp1;
    public temp2;
    public temp3;
    public temp4;

    public weight1;
    public weight2;

    public humidity1;
    public humidity2;

    public fuel1;
    public rpm1;
}


export class DeviceUtilization {
    deviceId: any;
    assetId: any;
    accountId: any;
    assetName: any;
    unitId: any;
    deviceTypeId: any;
    iconId: any;
    assetGroups: any;
    tripCount: any;
    tripDurationInSeconds: any;
    maxSpeed: any;
    segmentsDistance: any;
    segmentsDistancePrivate: any;
    totalDurationInSeconds: any;
    utilization: any;
    idlingDurationInSeconds: any;
    speedingCount: any;
    speedingDurationInSeconds: any;
    roadSpeedingCount: any;
    accelCount: any;
    decelCount: any;
    corneringCount: any;
    tripCountPrivate: any;
    geofenceEventCount: any;
    brakingEventScore: any;
    accellerationEventScore: any;
    corneringEventScore: any;
    pureDrivingDurationInSeconds: any;
    idlingPercentage: any;
    workingHoursInSeconds: any;
    tripCountBusiness: any;
    segmentsDistanceBusiness: any;
    avgKmh: any;
    active: boolean;
    vehicleType: any;
    equipmentIdlingDurationInSeconds: any;
    equipmentCrossOverDurationInSeconds: any;
    equipmentWorkDurationInSeconds: any;
    equipmentUtilization: number;
    equipmentIdlingPercentage: number;
    totalEmissionCO2: any;
    totalEmissionParticlesLight: any;
    totalEmissionParticlesHeavy: any;
    totalConsumptionMixed: any;
    fuelUsedWhileDriving: any;
    fuelLostWhileIdling: any;
    fuelLostPercentage: any;
    fuelUsedTotal: any;
    fuelEfficiency: any;
    assetTypeId: any;
    projectId: any;
    projectName: any;
    assetTypeName: any;
    brand: any;
    model: any;
    plateNumber: any;
    assignedScheduleId: any;
    assignedScheduleStart: any;
    assignedScheduleEnd: any;
}

export class DeviceSettingChange {
    public deviceId: number;
    public settingId: number;
    public settingValue: string;
    public wakeUpDevice: boolean = false;
}

export class DeviceLocation {
    public lastCommunication: any;
    public radiusInMeters: any;
    public lastPosition: Location;
    public headingInDegrees: any;
    public deviceState: any;
}

export class DeviceSettingChangeResult {
    public id: any;
    public changeAppliedDate: any;
    public changeSentDate: any;
    public commandToken: any;
    public deviceId: any;
    public retryCount: any;
    public status: any;
    public timestamp: any;
    public value: any;
    public settingId: any;
    public response: string;
}

export class IntermediateDevice {
    public id: string;
    public name: string;
    public phone: string;
    public unitId: string;
    public accountPlatformId: string;
    public assetId: any;
    public deviceType: any;
}

export class DeviceDashboard {
    public issuesLastMonthCount: number;
    public issuesLastMonthCountOld: number;
    public issuesLastWeekCount: number;
    public issuesLastWeekCountOld: number;
    public locationsLastMonthCount: number;
    public locationsLastMonthCountOld: number;
    public locationsLastWeekCount: number;
    public locationsLastWeekCountOld: number;
    public issuesThisWeekCount: number;
    public locationsYesterdayCount: number;
    public locationsYesterdayCountOld: number;
}

export class DeviceFuelThreshold {
    public deviceId: number;
    public fuelTankCapacity: number;
    public fuelTank1Capacity: number;
    public fuelTank2Capacity: number;
    public fillupThreshold: number;
    public theftThreshold: number;
    public fuelCalibrations: any[];
}


export class VepamonCalibrations {
    public vepamonCalibrationPointId: number;
    public deviceId: number;
    public tankNumber: number;
    public reportedValue: number;
    public calibratedValueInLiters: number;
}


export class DeviceCalibrationPoints {
    public sensorNumber: number;
    public reportedValue: number;
    public calibratedValue: number;
    public deviceId: number;
}

export class DeviceCalibration {
    public deviceId: number;
    public appliedTimestamp: any;
    public assetId: any;
    public calibrationState: any;
    public dateCalibration: any;
    public deviceOdoDistance: any;
    public deviceOdoDistanceOffset: any;
    public incrementalOdoDistanceOffset: any;
    public id: any;
    public realOdoDistance: any;
}

export class EngineHourCalibration {
    public id: any;
    public deviceId: number;
    public assetId: any;
    public dateCalibration: any;
    public realEngineHourInSeconds: any;
    public tripEngineHourInSeconds: any;
    public offsetInSeconds: any;
}
