import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DistanceUnitService } from "app/common/distanceunit.service";
import { DeviceAuxiliary, DeviceOutput, EntityType, StorageType } from "app/common/enums";
import { getIconPath, roundAsNumber, roundSeconds } from "app/common/globals";
import { StorageHelper } from "app/common/storagehelper";
import { DeviceSettingChange } from "app/models/device.model";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { DeviceSettingChangeService } from "app/services/device/deviceSettingChange.service";
import { DriverService } from "app/services/driver/driver.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, map } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FleetOverviewStoreService } from "app/services/fleetoverview/fleetoverview-store.service";

@Component({
    selector: 'fh-map-sidebar',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['sidebar.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetOverviewSidebarComponent implements OnInit, OnChanges {
    @Input() selectedDevice;
    @Input() selectedDeviceExtended;
    @Input() selectedDeviceUtilization;
    @Input() loadingSidebar;

    modalRef?: BsModalRef;

    deviceState;
    showUnMapped = false;

    showAll = false;

    showUtilization;
    constructorName = 'FleetOverviewSidebarComponent';
    permissions: {};

    tagType = EntityType.Device.valueOf();

    hasDriverTagsEnabled = true;
    immobilizationStatus = 1;
    thePort;
    immobilizationType;
    theSettingId;
    activeDsc: any;
    loading = false;

    translatedKmh: any = "km/h";
    translatedKm: any = "km";

    constructor(private authenticationService: AuthenticationService,
        private driverService: DriverService,
        private distance: DistanceUnitService,
        private cd: ChangeDetectorRef,
        private dsc: DeviceSettingChangeService,
        private translateService: TranslateService,
        private storageHelper: StorageHelper,
        private fleetOverviewStoreService: FleetOverviewStoreService,
        private bsModalService: BsModalService,
        private http: HttpClient,
        private toastr: ToastrService,
        private translate: TranslateService) {
        this.showUtilization = localStorage.getItem('ShowUtilization_' + this.constructorName) === 'true';
        this.permissions = authenticationService.permissions;
    }

    ngOnChanges() {
        this.deviceState = this.selectedDevice?.theMarker?.data?.deviceState;

        if (this.selectedDeviceExtended != null) {
            this.getCurrentStatus();
            this.getDeviceDriverSettings();

            if (this.selectedDevice) {
                this.selectedDevice.geofences = [];
                var geofences = this.deviceState?.insideGeofences;
                if (geofences) {
                    Object.keys(geofences).forEach(geofenceId => {
                        const geofenceName = this.fleetOverviewStoreService.geofencesMap.get(+geofenceId);
                        if (geofenceName) {
                            this.selectedDevice?.geofences.push({ id: +geofenceId, name: geofenceName, entered: geofences[geofenceId] })
                        }
                    });
                }
            }

            if (this.deviceState?.tagScanStatus?.tag) {
                this.driverService.getDriverByTag(this.deviceState?.tagScanStatus?.tag, this.selectedDevice.accountId).subscribe(res => {
                    this.selectedDeviceExtended.scannedDriver = res;
                    this.cd.markForCheck();
                });
            }
        }
    }

    ngOnInit(): void {
        this.translateService.get('general.date').subscribe(
            data => {
                this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
                this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
                this.cd.markForCheck();
            }
        );
    }

    customCommandModal(template: TemplateRef<void>) {
        this.modalRef = this.bsModalService.show(template);
    }

    flipUtilization() {
        this.showUtilization = !this.showUtilization;
        localStorage.setItem('ShowUtilization_' + this.constructorName, this.showUtilization.toString());
    }

    getIcon(iconId) {
        return getIconPath(iconId)[2];
    }

    actualRound(value, decimals) {
        return roundAsNumber(value, decimals);
    }

    actualRoundSeconds(value) {
        return roundSeconds(value, true);
    }

    // Immobilisation

    // Driver tags
    getDeviceDriverSettings() {
        const aux = this.selectedDeviceExtended.settings.deviceAuxiliary;

        // tslint:disable:no-bitwise
        if (aux.filter(x => (BigInt(x.id) & BigInt(DeviceAuxiliary.DriverFlags)) > 0).length > 0) {
            this.hasDriverTagsEnabled = true;
        }
        // tslint:enable:no-bitwise
    }

    getCurrentStatus() {
        this.thePort = null;
        this.immobilizationType = null;
        this.theSettingId = null;

        const outputPorts = this.selectedDeviceExtended?.settings?.outputPorts;

        if (outputPorts.filter(x => x.byte === DeviceOutput['OnCommand Starter Interrupt'].toString()).length > 0) {
            this.immobilizationType = 'OnCommandStarterInterrupt';
            this.theSettingId = 38;

            this.thePort = outputPorts.filter(x => x.byte === DeviceOutput['OnCommand Starter Interrupt'].toString())[0].id;
        }

        if (outputPorts.filter(x => x.byte === DeviceOutput['Automatic Starter Interrupt'].toString()).length > 0) {
            this.immobilizationType = 'AutomaticCommandStarterInterrupt';
            this.theSettingId = 39;

            this.thePort = outputPorts.filter(x => x.byte === DeviceOutput['Automatic Starter Interrupt'].toString())[0].id;
        }

        // Get the current status
        this.dsc.getDeviceSettingChangesById(this.selectedDeviceExtended.id).subscribe(result => {
            const currentStatus = result.filter(x => x.settingId === this.theSettingId && x.status === 2).sort((a, b) => (a.timestamp.unix() > b.timestamp.unix() ? -1 : 1));
            const activeDsc = result.filter(x => x.settingId === this.theSettingId && x.status !== 2 && x.status !== 3 && x.status !== 5 && x.status !== 6).sort((a, b) => (a.timestamp.unix() > b.timestamp.unix() ? -1 : 1));


            if (currentStatus.length > 0) {
                if (currentStatus[0].value.toString() === '1') {
                    this.immobilizationStatus = 2;
                } else {
                    this.immobilizationStatus = 1;
                }
            }

            if (activeDsc.length > 0) {
                const currentDsc = activeDsc[0];
                this.activeDsc = currentDsc;

                // scheduled
                if (currentDsc.value.toString() === '1') {
                    this.immobilizationStatus = 3;
                } else {
                    this.immobilizationStatus = 4;
                }
            }
            // ImmobilizationStatus
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    refresh() {
        this.loading = true;
        this.cd.markForCheck();

        this.getCurrentStatus();
    }

    abort() {
        this.loading = true;
        this.cd.markForCheck();

        this.dsc.deleteDeviceSettingChanges(this.activeDsc.id).subscribe(result => {

            this.immobilizationStatus = 1;

            this.loading = false;
            this.cd.markForCheck();

            setTimeout(() => {
                this.refresh();
            }, 3000);

        }, error => {
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    immobilise() {
        this.loading = true;
        this.cd.markForCheck();

        const change = new DeviceSettingChange();
        change.deviceId = +this.selectedDeviceExtended.id;
        change.settingId = this.theSettingId;
        change.settingValue = '1';

        this.dsc.sendDeviceSettingChanges(change).subscribe(result => {

            this.loading = false;
            this.cd.markForCheck();

            setTimeout(() => {
                this.refresh();
            }, 3000);

        }, error => {
            this.loading = false;
        });
    }

    unimmobilise() {
        this.loading = false;

        const change = new DeviceSettingChange();
        change.deviceId = +this.selectedDeviceExtended.id;
        change.settingId = this.theSettingId;
        change.settingValue = '0';

        this.dsc.sendDeviceSettingChanges(change).subscribe(result => {
            this.loading = false;

            setTimeout(() => {
                this.refresh();
            }, 3000);

        }, error => {
            this.loading = false;
        });
    }

    finishTask(event) {
        console.log(event);
    }

    processCustomCommand(event) {
        let object = { ...event.object, deviceId: this.selectedDeviceExtended.id };

        this.sendCustomCommand(object).subscribe(result => {
            if (result?.isSuccess) {
                this.toastr.success(this.translate.instant(result?.message), this.translate.instant('general.success'), {
                    progressBar: true
                });
            } else {
                if (result?.message.startsWith('general')) {
                    result.message = this.translate.instant(result.message)
                }

                this.toastr.warning(result?.message, this.translate.instant('general.warning'), {
                    progressBar: true
                });
            }
            this.cd.markForCheck();
        }, error => {
            this.toastr.error(this.translate.instant('general.somethingWentWrong'), this.translate.instant('general.error'), {
                progressBar: true
            });

            this.cd.markForCheck();
        });

        this.modalRef.hide();
    }

    sendCustomCommand(object): Observable<any> {
        return this.http.post(this.authenticationService.getWebserviceURL(EntityType[this.tagType]) + this.selectedDeviceExtended?.id + '/SendCustomCommand', object, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                const parsedResponse = data;
                return parsedResponse;
            }));
    }
}
