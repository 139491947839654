<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="warehouse?.accountId">
                    <a href="/#/AccountDetails/Index/{{warehouse?.accountId}}">{{warehouse?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Warehouses/Overview">{{'general.warehouses' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/WarehouseDetails/Index/{{warehouse?.id}}">{{warehouse?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.sensors' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-warehouse-details-navigation [warehouseId]="warehouseId"></fh-warehouse-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="">
            <div class="col-lg-12">

                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox" style="position:relative">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-sensor"></i> {{'general.sensors' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
                        <ngx-loading [show]="loading || loadingZones || loadingSensorData || saving"></ngx-loading>
                        <div style="padding:10px" *ngIf="loadingSensorData">Fetching sensor data</div>
                        <div style="padding:10px" *ngIf="!loadingSensorData && sensorData.length == 0"> {{
                            'general.noSensorData' | translate}}</div>
                        <div class="flex-container">
                            <ng-container *ngFor="let data of sensorData | orderBy: 'zoneName': false ; let i = index">
                                <div class="col-lg-2 col-sm-12" style="padding: 0;">
                                    <div class="kpi-item-small border-active " [ngClass]="data?.markerColor">
                                        <h3 class="margin-bottom font-bold" title="Sensor: {{ data.sensorId }}">
                                            <i class="fa fa-signal"
                                                [style.color]="data?.markerColor == 'green' ? '#0a8a15' : (data?.markerColor == 'orange' ? '#d48113' : '#b81313')"></i>
                                            {{
                                            data.sensorName}}
                                        </h3>
                                        <div title="{{ data.timestamp | amDateFormat: 'LLL'}}">{{
                                            data.zoneName}} - {{ data.timestamp | amTimeAgo}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-1 col-sm-6" style="padding: 0;">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold"
                                            title="Accepted range: {{ data.lowerTemperatureThreshold }}°C - {{data.upperTemperatureThreshold}}°C">

                                            <i class="fas fa-fw fa-thermometer-three-quarters" title="Temperature"></i>
                                            {{
                                            actualRound(data.temperature, 1) }} °C
                                        </h3>
                                        <div>{{ 'general.temperature' | translate}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-1 col-sm-6" style="padding: 0;">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold"
                                            title="Accepted range: {{ data.lowerHumidityThreshold }}% - {{data.upperHumidityThreshold}}%">

                                            <i class="fas fa-fw fa-humidity" title="humidity"></i>
                                            {{
                                            actualRound(data.humidity, 0)}} %
                                        </h3>
                                        <div>{{ 'general.humidity' | translate}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-1 col-sm-6" style="padding: 0;">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            <i style="color:#0a8a15" *ngIf="data.rssi < 30" class="fas fa-fw fa-signal" title="{{ 'general.signal' | translate}}"></i>
                                            <i style="color:#0a8a15" *ngIf="data.rssi > 29 && data.rssi < 67" class="fas fa-fw fa-signal-strong" title="{{ 'general.signal' | translate}}"></i>
                                            <i style="color:#d48113" *ngIf="data.rssi > 66 && data.rssi < 70" class="fas fa-fw fa-signal-good" title="{{ 'general.signal' | translate}}"></i>
                                            <i style="color:#b81313" *ngIf="data.rssi > 69 && data.rssi < 80" class="fas fa-fw fa-signal-fair" title="{{ 'general.signal' | translate}}"></i>
                                            <i style="color:#b81313" *ngIf="data.rssi > 79" class="fas fa-fw fa-weak" title="{{ 'general.signal' | translate}}"></i>
                                            {{ actualRound(data.rssi,0) }} dBm
                                        </h3>
                                        <div>{{ 'general.signal' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-1 col-sm-6" style="padding: 0;">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            <i style="color: #0a8a15" *ngIf="data.power > 79" class="fas fa-fw fa-battery-full" title="{{ 'general.battery' | translate}}"></i>
                                            <i style="color:#d48113" *ngIf="data.power > 24 && data.power < 79" class="fas fa-fw fa-battery-half" title="{{ 'general.battery' | translate}}"></i>
                                            <i style="color:#b81313" *ngIf="data.power > 0 && data.power < 25" class="fas fa-fw fa-battery-low" title="{{ 'general.battery' | translate}}"></i>
                                            <i style="color:#000000" *ngIf="data.power < 1" class="fas fa-fw fa-battery-slash" title="{{ 'general.battery' | translate}}"></i>
                                            {{ actualRound(data.power,0) == 0 ? '-' : actualRound(data.power,0) + '%'}}
                                        </h3>
                                        <div>{{ 'general.battery' | translate }}</div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>