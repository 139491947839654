import { AssetType } from './assetType.model';
import { Upload } from './upload.model';

export class MaintenanceOccurrence {
    public id: string;
    public occurrenceDate: any;
    public cost: any;
    public supplierName: any;
    public typeName: any;
    public localPurchaseOrderNumber: any;
    public materialReceiptInspectionVoucherNumber: any;
    public quantity = 1;
    public unitPrice = 0;
    public discount: any;
    public partNumber: any;
    public controlNumber: any;
    public assetId: any;
    public deviceId: any;
    public maintenanceTypeId: any;
    public assetName: any;
    public accountId: any;
    public companyName: any;
    public resellerId: any;
    public resellerDescription: any;

    public description: any;
    public reference: any;
    public remarks: any;
    public dateCalibration: any;
    public realAssetOdo: any;
    public comments: any;
}

export class Maintenance {
    public assetId: number;
    public maintenanceTypeId: any;
    public occurrenceDate: Date;
    public supplierName: any;
    public localPurchaseOrderNumber: any;
    public materialReceiptInspectionVoucherNumber: any;
    public controlNumber: any;
    public occurrences: MaintenanceOccurrence[] = [];
    public accountId: any;
    public companyName: any;
    public resellerId: any;
    public resellerDescription: any;

    public description: any;
    public reference: any;
    public remarks: any;
    public dateCalibration: any;
    public realAssetOdo: any;
    public comments: any;
}

export class MaintenanceType {
    public id: any;
    public accountId: any;
    public typeName: any;
    public hoursThreshold = 0;
    public kilometersThreshold = 0;
    public daysThreshold = 0;
    public timestamp: any;
    public companyName: any;
    public resellerId: any;
    public resellerDescription: any;
    public assetTypeIds: any;
    public assetTypeList = [];
}

export class MaintenanceUpload extends Upload {
    public assetName: any;
    public assetId: any;
    public maintenanceTypeId: any;
    public occurrenceDate: Date;
    public description: any;
    public cost: any;
    public reference: any;
    public supplierName: any;
    public typeName: any;
    public localPurchaseOrderNumber: any;
    public materialReceiptInspectionVoucherNumber: any;
    public quantity: any;
    public unitPrice: any;
    public discount: any;
    public remarks: any;
    public partNumber: any;
    public controlNumber: any;
    public accountId: any;
    public companyName: any;
}
