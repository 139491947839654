<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'about.header' | translate }}</li>
            </ol>
        </nav>
    </div>
</div>

<div class="wrapper wrapper-content">

    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-info"></i> {{'about.header' | translate }}
                    </h5>
                </div>
                <div class="ibox-content">
                    <!-- Introduction Row -->
                    <p>{{'about.intro' | translate }}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="row" data-equalizer>
        <div class="col-sm-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-microchip"></i> {{ appName }} API
                    </h5>
                </div>
                <div class="ibox-content slick">
                    <div>
                        <table style="width:100%" class="table nowrap dtr-inline no-footer table-fixed">
                            <tbody>
                                <tr>
                                    <td style="width:40%" class="row_opening">
                                        {{'about.build' | translate }}
                                    </td>
                                    <td style="width:60%">
                                        {{ appVersion }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:40%" class="row_opening">
                                        Endpoint
                                    </td>
                                    <td style="width:60%">
                                        {{ endPoint }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-microchip"></i> {{ appName }}
                    </h5>
                </div>
                <div class="ibox-content slick">
                    <div>
                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening">
                                        {{'about.build' | translate }}
                                    </td>
                                    <td class="row_content">
                                        {{ appVersion }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{'about.builddate' | translate }}
                                    </td>
                                    <td class="row_content">
                                        {{ appDate | amDateFormat: 'LLL' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>