import { Component, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, NgForm } from '@angular/forms';
import { FormMode, virtualSensorOutputType } from 'app/common/enums';
import { Observable } from 'rxjs/internal/Observable';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { SearchService } from 'app/services/search/search.service';
import { DeviceService } from 'app/services/device/device.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'fh-batch-virtualsensors-device',
    templateUrl: 'deviceVirtualSensors.template.html',
    providers: [SearchService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchDeviceVirtualSensorsComponent implements OnInit {
    @Input() gridSelection = [];
    @Output() onFinish = new EventEmitter();

    step: number;
    disabled = true;
    logItem: string

    formMode = FormMode.add;

    typeaheadLoading = false;
    autoComplete$: Observable<string[]>;
    autoCompleteSearchTerm: string;
    asyncSelected = '';

    virtualSensorsOverWrite = false;
    virtualSensors = [];
    virtualSensorOutputTypes = [];
    virtualSensorsLoading = false;

    newSensorName: string;
    newSensorFormula: string;
    newSensorOutputType = 0;

    validForm = false;

    assetForm = new FormGroup({
        checkbox: new FormControl('', Validators.required),
    });

    deviceForm: FormArray;

    constructor(private cd: ChangeDetectorRef, private searchService: SearchService, private deviceService: DeviceService) {
        this.step = 1;
    }

    ngOnInit() {
        this.virtualSensorOutputTypes = Object.keys(virtualSensorOutputType)
            .filter(k => typeof virtualSensorOutputType[k] === 'string')
            .map(n => ({ value: +n, name: ('enums.sensorOutputType.' + n) }));

        this.autoComplete$ = new Observable((observer: any) => {
            this.actualSearch(this.asyncSelected).subscribe((result: any) => {
                observer.next(result);
            });
        });
    }

    actualSearch(search: string) {
        return this.searchService.searchDevices(search).pipe(debounceTime(300), distinctUntilChanged());
    }

    onCheckOutFinish() {
        this.onFinish.emit({ status: 'success', object: { overWrite: this.virtualSensorsOverWrite, items: this.virtualSensors }, log: this.logItem })
        this.step = 1;
    }

    initForm() {
        this.step = 1;
        console.log('init tab');
    }

    checkForm(ngForm: NgForm) {
        setTimeout(() => {
            ngForm.form.markAllAsTouched();
            this.cd.markForCheck();
        }, 100);
    }

    add() {
        this.virtualSensors.push({ virtualSensorId: null, name: this.newSensorName, formula: this.newSensorFormula, sensorOutputType: this.newSensorOutputType, timestamp: Date() });

        this.newSensorName = '';
        this.newSensorFormula = '';
        this.newSensorOutputType = 0;
    }

    removeSensor(sensor) {
        const index: number = this.virtualSensors.indexOf(sensor);
        if (index !== -1) {
            this.virtualSensors.splice(index, 1);
        }
    }

    changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

    typeaheadOnSelect(e: TypeaheadMatch): void {
        console.log('Selected value: ', e);
        this.virtualSensorsLoading = true;

        this.deviceService.getVirtualSensors(e.item.id).subscribe(virtualSensors => {
            this.virtualSensors = [];

            virtualSensors.forEach(sensor => {
                this.virtualSensors.push({
                    // virtualSensorId: sensor.virtualSensorId,
                    name: sensor.name,
                    formula: sensor.formula,
                    sensorOutputType: sensor.sensorOutputType,
                    timestamp: new Date(),
                });
            });

            this.virtualSensorsLoading = false;
            this.cd.markForCheck();
        }, _ => {
            this.virtualSensorsLoading = false;
        });
    }
}
