import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { AuditLog } from '../../models/auditlog.model';
import { DeviceService } from '../../services/device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { MaintenanceService } from 'app/services/maintenance/maintenance.service';
import { getDefaultDpConfig } from 'app/common/gridhelper';

import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { MaintenanceOccurrence } from 'app/models/maintenance.model';
import { FormMode } from 'app/common/enums';
window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-device-maintenance',
    templateUrl: 'maintenance.template.html'
})
export class DeviceMaintenanceViewComponent implements OnInit {
    deviceLog: AuditLog[];
    sub;
    device: Device;
    deviceId;

    loading = false;

    error: any;
    success: any;

    maintenances = [];
    maintenanceDue = [];

    showProp = false;

    // FormAdd
    formModeAdd: FormMode = FormMode.add;
    maintenanceOccurrence: MaintenanceOccurrence;

    permissions = {};

    filter;
    currentPage;

    // Daterange
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    daterangepickerModel: Date[];
    timezoneIana: string;

    constructor(private maintenanceService: MaintenanceService, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router, private translateService: TranslateService, private authenticationService: AuthenticationService) {
        this.device = null;
        this.deviceLog = [];

        this.permissions = this.authenticationService.permissions;

        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(10, 'years').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, this.authenticationService);

        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.isAnimated = true;
        this.dpConfig.withTimepicker = false;
    }

    dateChanged(event) {
        const that = this;
        this.loading = true;
        if (event !== null) {
            this.maintenanceService.getMaintenancesByDeviceId(this.device.id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(maintenances => {
                this.maintenances = maintenances;
                this.loading = false;
            }, error => {
                this.error = error
                this.loading = false;
            });

            this.maintenanceService.getMaintenancesDueByDeviceId(this.device.id).subscribe(maintenanceDue => {
                this.maintenanceDue = maintenanceDue;
                this.loading = false;
            }, error => {
                this.error = error
                this.loading = false;
            });
        }
    }

    ngOnInit() {

        this.maintenanceOccurrence = new MaintenanceOccurrence();
        this.maintenanceOccurrence.occurrenceDate = new Date();

        this.loading = true;
        this.device = new Device;
        this.device.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.deviceId = id;
            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;

                this.maintenanceOccurrence.deviceId = this.device.id;
                this.maintenanceOccurrence.assetId = this.device.asset?.id;
                this.maintenanceOccurrence.accountId = this.device.accountId;

                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }

                this.dateChanged(true);
            });

        }, error => {
            this.error = error;
            this.error.statusText = 'Error fetching device';

            setTimeout(() => {
                this.router.navigate(['/Devices/Overview']);
            }, 3000);
        });
    }

    showProperties() {
        this.showProp = !this.showProp;
    }


    onInsertMaintenance() {
        this.loading = true;

        this.maintenanceOccurrence.deviceId = this.device.id;
        this.maintenanceOccurrence.assetId = this.device.asset?.id;
        this.maintenanceOccurrence.accountId = this.device.accountId;

        this.maintenanceService.saveMaintenance(this.maintenanceOccurrence).subscribe(result => {
        }, error => {
            this.error = error;
        });

        this.success = {
            statusText: 'Success',
            success: this.translateService.instant('general.saveSuccessRedirect', { entity: 'maintenance' })
        };

        this.maintenanceOccurrence = new MaintenanceOccurrence();
        this.maintenanceOccurrence.occurrenceDate = new Date();
        this.maintenanceOccurrence.deviceId = this.device.id;
        this.maintenanceOccurrence.assetId = this.device.asset?.id;
        this.maintenanceOccurrence.accountId = this.device.accountId;

        setTimeout(() => {
            this.dateChanged(true);
        }, 3000);

        this.loading = false;
    }
}
