import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

import { getDefaultDpConfig } from 'app/common/gridhelper';

import * as L from 'leaflet';
import { slideInOut } from 'app/common/animations';

import * as Highcharts from 'highcharts';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { NotificationLogService } from 'app/services/notificationlog/notificationlog.service';
import { FhChartService } from 'app/services/charts/charts.service';
window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-notification-chart',
    templateUrl: 'chart.template.html',
    providers: [FhChartService],
    animations: [
        slideInOut
    ]
})

export class NotificationChartViewComponent implements OnInit {
    Highcharts: typeof Highcharts = Highcharts;

    permissionName = 'FleetManagement_Notifications';
    timezoneIana: string;
    daterangepickerModel: Date[];
    dpConfig: {};
    chart: any;
    loading = false;
    filterZeroValues = false;

    error;
    success;

    selectedResellerId;
    selectedAccountId;

    constructor(private chartService: FhChartService, private notificationLogService: NotificationLogService, private authenticationService: AuthenticationService, private http: HttpClient, private route: ActivatedRoute, private router: Router) {

        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().subtract(3, 'days').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);
    }

    ngOnInit() {
        this.getNotificationData(moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'));
    }

    resellerChanged(event) {
        this.selectedAccountId = null;
        this.accountChanged(true);
    }

    accountChanged(event) {
        this.dateChanged(true);
    }

    dateChanged(event) {
        this.getNotificationData(moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'));
    }

    getNotificationData(start, end) {
        this.loading = true;
        this.error = undefined;

        this.notificationLogService.getNoticationLogChart(start, end, this.selectedAccountId).subscribe(dates => {

            this.drawChart(dates);

            this.loading = false;

        }, error => {
            this.loading = false;
            this.error = error;
        });
    }

    drawChart(dates) {

        const theChartCount = [];

        const that = this;

        $.each(dates.sort((a, b) => (a.date < b.date ? -1 : 1)), function (index, value) {
            theChartCount.push([Moment.utc(value.date)['tz'](that.timezoneIana).unix() * 1000, value.count]);
        });

        let theData = [];

        theData = [{
            name: 'Count',
            type: 'column',
            visible: true,
            yAxis: 0,
            data: theChartCount
        }];

        this.chart = this.chartService.generateColumnChartDates(theData, null, null, null, null, false);
    }

}
