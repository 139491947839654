import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { createMapOptions, getMapProviders, colorMapper, drawGeofences, setBounds, getMapProvidersExtended } from 'app/common/leafletGlobals';
import { Device } from 'app/models/device.model';
import { MapService } from '../../services/common/map.service';
import { LocationService } from '../../services/locations/locations.service';
import { getIconPath } from 'app/common/globals';
import { FuelService } from 'app/services/fuel/fuel.service';
import { getDefaultDpConfig } from 'app/common/gridhelper';
import { UserService } from 'app/services/users/user.service';
import { AppUser } from 'app/models/user.model';

// Leaflet
import { Map, TileLayer } from 'leaflet';
import 'leaflet-easybutton';

declare var L;
declare var HeatmapOverlay;
declare var PruneCluster;
declare var PruneClusterForLeaflet;
declare const window;

import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { AssetDisplayName, StorageType } from 'app/common/enums';
import { StorageHelper } from 'app/common/storagehelper';
import { Router } from '@angular/router';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';

window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-fuel-map',
    templateUrl: 'map.template.html',
    styleUrls: ['map.template.css'],
    providers: [MapService, LocationService],
    animations: [
        trigger('slideInOut', [
            state('in', style({
                transform: 'translate3d(0, 0, 0)'
            })),
            state('out', style({
                transform: 'translate3d(calc(100% - 10px), 0, 0)'
            })),
            transition('in => out', animate('400ms ease-in-out')),
            transition('out => in', animate('400ms ease-in-out'))
        ]),
    ]
})
export class FuelMapViewComponent implements OnInit, OnDestroy {
    pruneCluster: any;
    selectedEvent;
    deviceId: string;
    fuelingActivityInLiters: number;
    minimalCertainty: number;
    loadingSidebar = false;
    googleMaps: TileLayer;
    googleHybrid: TileLayer;
    loading = false;
    markers: any;
    streets: any;
    osm: any;
    cities: any;
    map: Map;
    options;
    devices: Device[];
    menuState = 'out';
    statusText = '';

    search;
    displayEventTypes = 0;

    maps = [];
    locationSubscription: any;
    deviceSubscription: any;
    previousLookupTimestamp: Date;
    selectedLocation: any;
    daterangepickerModel: Date[];
    dpConfig: {};
    geofences: any[] = [];
    geofenceLayer: any;
    theMarkers = [];
    error: any;
    success: any;
    permissions: {};
    timezoneIana: string;

    user: AppUser = new AppUser();

    constructor(private ngZone: NgZone, private router: Router, private authenticationService: AuthenticationService, private theMapService: MapService, private zone: NgZone, private http: HttpClient, private fuelService: FuelService, private translateService: TranslateService, private storageHelper: StorageHelper, private userService: UserService) {
        this.permissions = this.authenticationService.permissions;

        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(1, 'months').startOf('day').toDate(),
            Moment().add(0, 'days').endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

        this.initMap();
    }

    filterMarkers() {
        console.log('filtering markers');
        // filter the events on map
        this.geofences = [];
        this.theMarkers.forEach(marker => {
            marker.filtered = false;
            // Filter on asset and customer
            if (this.search) {
                marker.filtered = true;
                if (marker.data.event.assetSearchName?.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || marker.data.event.companyName?.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
                    marker.filtered = false;
                }
            }
            // filter on type
            switch (this.displayEventTypes) {
                case 1:
                    if (marker.data.category === '2') {
                        marker.filtered = true;
                    }
                    break;
                case 2:
                    if (marker.data.category === '1') {
                        marker.filtered = true;
                    }
                    break;
            }

            // filter on liters
            if (marker.filtered === false && this.fuelingActivityInLiters !== undefined) {
                marker.filtered = true;
                if (marker.data.event.fuelingActivityInLiters >= this.fuelingActivityInLiters || marker.data.event.fuelingActivityInLiters <= this.fuelingActivityInLiters * -1) {
                    marker.filtered = false;
                }
            }

            // fcertaintyInPercentage
            if (marker.filtered === false && this.minimalCertainty !== undefined) {
                marker.filtered = true;
                if (marker.data.event.certaintyInPercentage >= this.minimalCertainty) {
                    marker.filtered = false;
                }
            }

            // show geofences for non-filtered markers
            if (marker.filtered === false) {
                const geofenceToAdd = { id: marker.data.event.geofenceId, name: marker.data.event.geofenceLabel, geoJson: marker.data.event.geoJson, color: marker.data.event.geoColor };
                if (!(this.geofences.findIndex(geofence => geofence.id === geofenceToAdd.id) > -1)) {
                    this.geofences.push(geofenceToAdd);
                }
            }

        });

        this.pruneCluster.ProcessView();
        this.prepareGeofences(this.geofences)
    }

    toggleMenu() {
        // 1-line if statement that toggles the value:
        this.menuState = this.menuState === 'out' ? 'in' : 'out';
    }

    ngOnDestroy(): void {
        if (this.deviceSubscription !== undefined) {
            this.deviceSubscription.unsubscribe();
        }
        if (this.locationSubscription !== undefined) {
            this.locationSubscription.unsubscribe();
        }
    }

    selectEvent(eventId: string, event): any {
        this.selectedEvent = event;
    }

    async ngOnInit(): Promise<void> {
        window.my = window.my || {};
        window.my.namespace = window.my.namespace || {};

        window.my.namespace.saveAsGeofence = this.saveAsGeofence.bind(this);
        window.my.namespace.panTo = this.panToPublic.bind(this);
        window.my.namespace.zoomIn = this.zoomInPublic.bind(this);
        window.my.namespace.zoomOut = this.zoomOutPublic.bind(this);

        this.user = await this.userService.getUserById(this.authenticationService.getUserId()).toPromise();
    }

    saveAsGeofence(latitude: number, longitude: number): void {
        const circle: L.Circle = L.circle([latitude, longitude], 50);

        this.zone.run(() => this.router.navigateByUrl('/Geofences/Add', {
            state: {
                newGeofence: circle.toGeoJSON(),
            }
        }));
    }

    zoomInPublic(e): void {
        this.ngZone.run(() => this.map.zoomIn());
    }

    zoomOutPublic(e): void {
        this.ngZone.run(() => this.map.zoomOut());
    }

    panToPublic(latitude, longitude): void {
        this.ngZone.run(() => this.map.panTo([latitude, longitude]));
    }

    createIcon(data) {

        const iconPath = data.iconId ? getIconPath(data.iconId)[1] : '/assets/images/icons/vista/Trucks/32x32/TankerTruck_Black.png';
        const markerIcon = data.category === '2' ? 'fa-user-secret' : 'fa-gas-pump';

        return L['StatusMarker'].icon({
            iconUrl: iconPath,
            icon: markerIcon,
            markerColor: data.category === '2' ? 'red' : 'green',
            rotate: 0,
            shape: 'circle',
            prefix: 'fa'
        });
    }

    dateChanged(event = null) {
        if (this.theMarkers && this.theMarkers.length > 0) {
            this.pruneCluster.RemoveMarkers(this.theMarkers);
        }

        this.getFuelEvents();
    }

    // Leaflet
    initMap() {
        this.storageHelper.loadStoreState(StorageType.LocalStorage, 'settings_', 'mapSelectionOptions').subscribe((mapSelectionOptions) => {
            this.maps = getMapProvidersExtended(L, mapSelectionOptions);

            this.markers = L.featureGroup();
            this.geofenceLayer = L.featureGroup();
            this.pruneCluster = new PruneClusterForLeaflet();

            let mapType = this.theMapService.getLeafletMapType();

            if (!mapType) {
                mapType = this.maps[0].name;
                this.theMapService.setLeafletMapType(mapType);
            }

            const defaultLayers = [];

            let defaultMap = this.maps.find(x => x.name.toString() === mapType.toString())
            if (!defaultMap) {
                console.log('Falling back to default map');
                defaultMap = this.maps[0];
            }

            defaultLayers.push(defaultMap.layer);

            defaultLayers.push(this.markers);
            defaultLayers.push(this.pruneCluster);

            const mapOptions = createMapOptions(L, defaultLayers, this.translateService);

            this.options = {
                ...mapOptions
            };

            const that = this;

            this.loading = true;

            // Select event
            that.theMapService.setPruneCluster(that.pruneCluster);

            that.pruneCluster.PrepareLeafletMarker = function (theMarker, data, category) {

                that.theMapService.addLabel(theMarker, data);

                that.theMapService.createPopup(theMarker, data, category);

                theMarker.setIcon(that.createIcon(data));

                theMarker.on('click', () => {
                    that.zone.run(() => {
                        that.menuState = 'in';
                        that.selectEvent(data.event.id, data.event);
                    });
                });
            };

            setTimeout(() => {
                this.getFuelEvents();
                this.loading = false;
            }, 100);
        });
    }

    onMapReady(map: Map) {
        this.map = map;
        const that = this;

        this.map.on('baselayerchange', (event) => {
            this.theMapService.setLeafletMapType(event?.['name']);
        });

        setBounds(L, map);

        const overlayMaps = {
            'Markers': this.markers,
            'Geofences': this.geofenceLayer,
        };

        this.map.on('click', () => {
            this.zone.run(() => {
                this.menuState = 'out';
            });
        });

        // overlayMaps
        // L.control.layers(this.maps, overlayMaps, { position: 'topleft' }).addTo(map);
        new L.basemapsSwitcher(this.maps, { position: 'topleft' }).addTo(this.map);

        // Easybutton
        L.easyButton({
            id: 'fit map button',
            position: 'topleft',
            states: [{
                stateName: 'add-markers',
                icon: 'fa-arrows-to-eye',
                title: 'Fit map',
                onClick: function (control) {
                    that.centerMap();
                }
            }]
        }).addTo(this.map);

        this.map.invalidateSize();
    }

    getFuelEvents() {
        this.loading = true;
        // Getting devices for account
        this.statusText = 'Fetching events';

        this.fuelService.getFuels(moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(events => {
            this.statusText = '';

            this.loading = false;
            this.error = null;

            events.forEach(event => {
                this.geofences.push({ id: event.geofenceId, name: event.geofenceLabel, geoJson: event.geoJson, color: event.geoColor });

                if (event.latitude && event.latitude !== 0) {
                    let assetDisplayLabel: string;

                    switch (this.user.assetDisplayName) {
                        case AssetDisplayName['Asset Code']:
                            assetDisplayLabel = `${event.assetCode}`;
                            break;
                        case AssetDisplayName['Plate Number']:
                            assetDisplayLabel = `${event.plateNumber}`;
                            break;
                        case AssetDisplayName['Device Name']:
                            assetDisplayLabel = `${event.assetName}`;
                            break;
                        case AssetDisplayName['Client Handle: Asset Code']:
                            assetDisplayLabel = `${event.companyName}: ${event.assetCode}`;
                            break;
                        case AssetDisplayName['Client Handle: Plate Number']:
                            assetDisplayLabel = `${event.companyName}: ${event.plateNumber}`;
                            break;
                        case AssetDisplayName['Client Handle: Device Name']:
                            assetDisplayLabel = `${event.companyName}: ${event.assetName}`;
                            break;
                        default:
                            assetDisplayLabel = `${event.assetName}`;
                            break;
                    }

                    const marker = new PruneCluster.Marker(event.latitude.toString(), event.longitude.toString(), { title: `<b>${assetDisplayLabel}: </b>` + event.refuelVsTheft, iconId: event.iconId });

                    event.fuelingActivityInLiters = Math.ceil(event.fuelingActivityInLiters);

                    marker.category = Math.ceil(event.refuelVsTheft === 'Theft' ? 2 : 1).toString();
                    marker.data.category = Math.ceil(event.refuelVsTheft === 'Theft' ? 2 : 1).toString();
                    marker.data.popup = event.assetName + ': ' + event.refuelVsTheft;
                    marker.data.imei = event.unitId;
                    marker.data.event = event;

                    marker.data.assetDisplayLabel = `<b>${assetDisplayLabel}</b>`;
                    marker.data.summary = event.refuelVsTheft;

                    this.theMarkers.push(marker);

                    this.pruneCluster.RegisterMarker(marker);
                }
            });

            this.filterMarkers();
            this.pruneCluster.ProcessView();
            this.centerMap();
            this.prepareGeofences(this.geofences)
            this.loading = false;

        }, error => {
            this.error = error;
            console.log(error);
            this.loading = false;
        });
    }

    centerMap() {
        this.invalidateSize();

        const bounds = this.pruneCluster.Cluster.ComputeGlobalBounds();
        if (bounds) {
            this.map.flyToBounds(new L.LatLngBounds(new L.LatLng(bounds.minLat, bounds.maxLng), new L.LatLng(bounds.maxLat, bounds.minLng)), { padding: [50, 50], animate: true, duration: 0.5 });
        }
    }

    invalidateSize() {
        this.map.invalidateSize();
    }

    prepareGeofences(geofences) {
        if (geofences === undefined) {
            console.log('emptyevents')
            return;
        }

        if (this.geofenceLayer) {
            this.geofenceLayer.clearLayers();
        }

        drawGeofences(L, geofences, this.geofenceLayer, null);

        setTimeout(() => {
            this.centerMap();
        }, 100);
    }
}
