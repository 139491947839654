// Import the core angular services.
import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
// Import the application components and services.
import { ErrorLogService } from './error-log.service';


export class LoggingErrorHandlerOptions {
    rethrowError: boolean;
    unwrapError: boolean;
}

export const LoggingErrorHandlerOptionsTokens = new InjectionToken('error.handler.options');

@Injectable()
export class LoggingErrorHandler implements ErrorHandler {

    private errorLogService: ErrorLogService;
    private options: LoggingErrorHandlerOptions;

    // I initialize the service.
    // --
    // CAUTION: The core implementation of the ErrorHandler class accepts a boolean
    // parameter, `rethrowError`; however, this is not part of the interface for the
    // class. In our version, we are supporting that same concept; but, we are doing it
    // through an Options object (which is being defaulted in the providers).
    constructor(errorLogService: ErrorLogService,
        @Inject(LoggingErrorHandlerOptions) options: LoggingErrorHandlerOptions
    ) {

        this.errorLogService = errorLogService;
        this.options = options;
    }

    // I handle the given error.
    public handleError(error: any): void {

        // Send to the error-logging service.
        try {
            this.options.unwrapError
                ? this.errorLogService.logError(this.findOriginalError(error))
                : this.errorLogService.logError(error)
                ;
        } catch (loggingError) {
            console.group('ErrorHandler');
            console.warn('Error when trying to log error to', this.errorLogService);
            console.error(loggingError);
            console.groupEnd();
        }

        if (this.options.rethrowError) {
            throw (error);
        }
    }

    // I attempt to find the underlying error in the given Wrapped error.
    private findOriginalError(error: any): any {
        while (error && error.originalError) {
            error = error.originalError;
        }
        return (error);
    }
}

export let LoggingErrorHandlerProviders = [
    {
        provide: LoggingErrorHandlerOptions,
        useValue: LoggingErrorHandlerOptions
    },
    {
        provide: ErrorHandler,
        useClass: LoggingErrorHandler
    }
];
