<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Reporting">{{'general.reporting' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="/#/Reporting/Executions">{{'menu.overview' | translate }}</a>
                </li>
            </ol>
        </nav>
    </div>
    <fh-reporting-navigation>
        <li *ngIf="permissions['FleetManagementReporting_Add']"><a [routerLink]="['/Reporting/Create']">
                <i class="fa fa-fw fa-user-chart"></i>
                <div>{{'general.createReport' | translate }}</div>
            </a>
        </li>
    </fh-reporting-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-fa-file-chart-pie"></i> {{'general.reportUsage' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 210px; position: relative">
                        <ngx-loading [show]="loadingReportUsage"></ngx-loading>

                        <highcharts-chart *ngIf="chartReportUsage" [runOutsideAngular]="true"
                            style="display: block; width: 100%; height:200px" [Highcharts]="Highcharts"
                            [options]="chartReportUsage">
                        </highcharts-chart>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-clock"></i> {{'general.scheduledReports' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
                        <ngx-loading [show]="loadingScheduledReports"></ngx-loading>

                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th>{{'general.date' | translate }}</th>
                                    <th>{{'general.name' | translate }}</th>
                                    <th>{{'general.company' | translate }}</th>
                                    <th>{{'general.user' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="scheduledReports?.length == 0">
                                    <td colspan="4">{{'general.noDataFound' | translate }}</td>
                                </tr>
                                <tr *ngFor="let report of scheduledReports; let i = index">
                                    <td>
                                        {{ report.nextOccurrence | amDateFormat: 'lll'}}
                                    </td>
                                   <td>
                                        <a class="secondary link_bolder hand"
                                            routerLink="/ReportSubscriptionDetails/Index/{{report.id}}">
                                            {{ report.name || (('enums.reportType.' + report.selectedReportType) |
                                            translate) }}</a>
                                    </td>
                                    <td>
                                        {{ report.companyName}}
                                    </td>
                                    <td>
                                        {{ report.userName}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-file-chart-pie"></i> {{'general.latestReports' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
                        <ngx-loading [show]="loadingRecentReports"></ngx-loading>

                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th>{{'general.date' | translate }}</th>
                                    <th>{{'general.name' | translate }}</th>
                                    <th>{{'general.company' | translate }}</th>
                                    <th>{{'general.user' | translate }}</th>
                                    <th style="text-align: right;">{{'general.duration' | translate }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="recentReports?.length == 0">
                                    <td colspan="4">{{'general.noDataFound' | translate }}</td>
                                </tr>
                                <tr *ngFor="let report of recentReports; let i = index">
                                    <td>
                                        {{ report.executedTimestamp | amDateFormat: 'lll'}}
                                    </td>
                                    <td>
                                        <a class="secondary link_bolder hand"
                                            routerLink="/ReportSubscriptionDetails/Index/{{report.reportSubscriptionId}}">
                                            {{ report.name || (('enums.reportType.' + report.selectedReportType) |
                                            translate) }}</a>
                                    </td>
                                    <td>
                                        {{ report.companyName}}
                                    </td>
                                    <td>
                                        {{ report.emailLoginName}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{ report.sumExecutionDurationInSeconds | amDuration:'seconds' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-dollar"></i> {{'general.expensiveReports' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
                        <ngx-loading [show]="loadingExpensiveReports"></ngx-loading>

                        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th>{{'general.name' | translate }}</th>
                                    <th>{{'general.company' | translate }}</th>
                                    <th>{{'general.user' | translate }}</th>
                                    <th style="text-align: right;">{{'general.count' | translate }}</th>
                                    <th style="text-align: right;">{{'general.duration' | translate }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="expensiveReports?.length == 0">
                                    <td colspan="4">{{'general.noDataFound' | translate }}</td>
                                </tr>
                                <tr *ngFor="let report of expensiveReports; let i = index">
                                    <td>
                                        <a class="secondary link_bolder hand"
                                            routerLink="/ReportSubscriptionDetails/Index/{{report.reportSubscriptionId}}">
                                            {{ report.name || (('enums.reportType.' + report.selectedReportType) |
                                            translate) }}</a>
                                    </td>
                                    <td>
                                        {{ report.companyName }}
                                    </td>
                                    <td>
                                        {{ report.emailLoginName}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{ report.executionCount}}
                                    </td>
                                    <td style="text-align: right;">
                                        {{ report.sumExecutionDurationInSeconds | amDuration:'seconds' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>