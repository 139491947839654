<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Settings/">{{'general.settings' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Settings/ClientAnnouncements/Overview">{{'general.clientAnnouncements' | translate
                        }}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.add' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-settings-navigation>
        <li class="active">
            <a href="/#/Settings/ClientAnnouncementDetails/Add"><i class="fas fa-fw fa-calendar-exclamation"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a>
        </li>
    </fh-settings-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-calendar-exclamation"></i> {{'general.clientAnnouncements' |
                                translate
                                }}
                            </h5>
                        </div>
                        <fh-client-announcement-details [clientAnnouncement]="clientAnnouncement" [formMode]="formMode">
                        </fh-client-announcement-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()"
                            [allowAdd]="permissions['UserContacts_Add']" [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-md-6">
                <div class="ibox" style="position:relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-city"></i> {{'general.info' | translate }}</h5>
                    </div>
                    <div class="ibox-content">
                        Info
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>