<div style="float: left;" [ngStyle]="{'width': showEmptyAccountsButton === true ? 'calc(100% - 40px)' : '100%'}  ">
    <ng-select [loading]="loadingAccounts" ngDefaultControl [searchable]="true" [clearable]="clearable"
        [disabled]="accounts.length == 0 || disable" [readonly]="readonly" placeholder="{{placeholder}} ({{accounts.length}})"
        #input [(ngModel)]="value" class="form-control" [ngClass]="tableInline === true ? 'table-inline' : '' "
        (ngModelChange)="accountChanged($event)">
        <ng-option *ngFor="let account of accounts | orderBy : 'name':false" [value]="account[key]">
            <span title="Devices: {{account.deviceCount}} / Drivers: {{account.driverCount}}">
                <i class="fas fa-fw fa-building"></i> {{ account.name }}
            </span>
        </ng-option>
    </ng-select>
    <div style="clear:both"></div>
</div>
<div *ngIf="showEmptyAccountsButton" title="Show accounts without active devices" class="input-group-append"
    [ngClass]="tableInline === true ? 'table-inline' : '' " style="float: left; width: 40px; margin-top: -4px; margin-bottom: -6px;"
    #toggler><i class="fa fa-fw fa-car-building"></i>
</div>
<div style="clear:both"></div>