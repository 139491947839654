<div class="ibox-content slick" style="position:relative">
    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!asset">
            <tr>
                <td colspan="4">
                    {{ 'general.noWaslAsset' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="asset">
            <tr>
                <td class="row_opening">
                    {{ 'general.plateType' | translate}}
                    <span class="error" *ngIf="formMode != 1 && !asset.properties.wasl.plateType">*</span>
                </td>
                <td class="row_content" style="overflow: visible;" colspan="3">
                    <ng-container *ngIf="formMode == 1; else editPlate">
                        <input type="text" readonly class="form-control table-inline"
                            [value]="getPlateType(asset.properties.wasl.plateType) | translate">
                    </ng-container>
                    <ng-template #editPlate>
                        <ng-select required class="form-control table-inline" name="plateType" #plateType="ngModel"
                            [(ngModel)]="asset.properties.wasl.plateType" style="width:100%">
                            <ng-option [value]="undefined"> {{ 'enums.plateType.0' | translate}}</ng-option>
                            <ng-option [value]="1"> {{ 'enums.plateType.1' | translate}}</ng-option>
                            <ng-option [value]="2"> {{ 'enums.plateType.2' | translate}}</ng-option>
                            <ng-option [value]="3"> {{ 'enums.plateType.3' | translate}}</ng-option>
                            <ng-option [value]="4"> {{ 'enums.plateType.4' | translate}}</ng-option>
                            <ng-option [value]="5"> {{ 'enums.plateType.5' | translate}}</ng-option>
                            <ng-option [value]="6"> {{ 'enums.plateType.6' | translate}}</ng-option>
                            <ng-option [value]="7"> {{ 'enums.plateType.7' | translate}}</ng-option>
                            <ng-option [value]="8"> {{ 'enums.plateType.8' | translate}}</ng-option>
                            <ng-option [value]="9"> {{ 'enums.plateType.9' | translate}}</ng-option>
                            <ng-option [value]="10"> {{ 'enums.plateType.10' | translate}}</ng-option>
                            <ng-option [value]="11"> {{ 'enums.plateType.11' | translate}}</ng-option>
                        </ng-select>
                    </ng-template>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.sequenceNumber' | translate}}
                    <span class="error" *ngIf="sequenceNumber.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" colspan="3">
                    <input type="text" [readonly]="formMode == 1" name="sequenceNumber"
                        class="form-control table-inline" [(ngModel)]="asset.properties.wasl.sequenceNumber" required
                        #sequenceNumber="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.plateNo' | translate}}
                    <span class="error" *ngIf="plateNo.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" colspan="3">
                    <input type="text" [readonly]="formMode == 1" name="plateNo" class="form-control table-inline"
                        [(ngModel)]="asset.properties.wasl.vehiclePlateNumber" required #plateNo="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.plate' | translate}}
                    <span class="error"
                        *ngIf="formMode != 1 && (!asset.properties.wasl.vehiclePlateLeftLetter || !asset.properties.wasl.vehiclePlateMiddleLetter || !asset.properties.wasl.vehiclePlateRightLetter)">*</span>
                </td>
                <td style="width:16%">
                    <ng-container *ngIf="formMode == 1; else editLeftLetter">
                        <input type="text" readonly class="form-control table-inline"
                            [value]="asset.properties.wasl.vehiclePlateLeftLetter || ''">
                    </ng-container>
                    <ng-template #editLeftLetter>
                        <ng-select class="form-control table-inline" name="leftLetter" #leftLetter="ngModel" required
                            [(ngModel)]="asset.properties.wasl.vehiclePlateLeftLetter" style="width:100%">
                            <ng-option [value]="undefined">-</ng-option>
                            <ng-option value="ا">ا (A)</ng-option>
                            <ng-option value="ب">ب (B)</ng-option>
                            <ng-option value="ح">ح (J)</ng-option>
                            <ng-option value="د">د (D)</ng-option>
                            <ng-option value="ر">ر (R)</ng-option>
                            <ng-option value="س">س (S)</ng-option>
                            <ng-option value="ص">ص (X)</ng-option>
                            <ng-option value="ط">ط (T)</ng-option>
                            <ng-option value="ع">ع (E)</ng-option>
                            <ng-option value="ق">ق (G)</ng-option>
                            <ng-option value="ك">ك (K)</ng-option>
                            <ng-option value="ل">ل (L)</ng-option>
                            <ng-option value="م">م (Z)</ng-option>
                            <ng-option value="ن">ن (N)</ng-option>
                            <ng-option value="ه">ه (H)</ng-option>
                            <ng-option value="و">و (U)</ng-option>
                            <ng-option value="ى">ى (V)</ng-option>
                        </ng-select>
                    </ng-template>
                </td>
                <td style="width:16%">
                    <ng-container *ngIf="formMode == 1; else editMiddleLetter">
                        <input type="text" readonly class="form-control table-inline"
                            [value]="asset.properties.wasl.vehiclePlateMiddleLetter || ''">
                    </ng-container>
                    <ng-template #editMiddleLetter>
                        <ng-select class="form-control table-inline" name="middleLetter" #middleLetter="ngModel"
                            required [(ngModel)]="asset.properties.wasl.vehiclePlateMiddleLetter" style="width:100%">
                            <ng-option [value]="undefined">-</ng-option>
                            <ng-option value="ا">ا (A)</ng-option>
                            <ng-option value="ب">ب (B)</ng-option>
                            <ng-option value="ح">ح (J)</ng-option>
                            <ng-option value="د">د (D)</ng-option>
                            <ng-option value="ر">ر (R)</ng-option>
                            <ng-option value="س">س (S)</ng-option>
                            <ng-option value="ص">ص (X)</ng-option>
                            <ng-option value="ط">ط (T)</ng-option>
                            <ng-option value="ع">ع (E)</ng-option>
                            <ng-option value="ق">ق (G)</ng-option>
                            <ng-option value="ك">ك (K)</ng-option>
                            <ng-option value="ل">ل (L)</ng-option>
                            <ng-option value="م">م (Z)</ng-option>
                            <ng-option value="ن">ن (N)</ng-option>
                            <ng-option value="ه">ه (H)</ng-option>
                            <ng-option value="و">و (U)</ng-option>
                            <ng-option value="ى">ى (V)</ng-option>
                        </ng-select>
                    </ng-template>
                </td>
                <td style="width:16%">
                    <ng-container *ngIf="formMode == 1; else editRightLetter">
                        <input type="text" readonly class="form-control table-inline"
                            [value]="asset.properties.wasl.vehiclePlateRightLetter || ''">
                    </ng-container>
                    <ng-template #editRightLetter>
                        <ng-select class="form-control table-inline" name="rightLetter" #rightLetter="ngModel" required
                            [(ngModel)]="asset.properties.wasl.vehiclePlateRightLetter" style="width:100%">
                            <ng-option [value]="undefined">-</ng-option>
                            <ng-option value="ا">ا (A)</ng-option>
                            <ng-option value="ب">ب (B)</ng-option>
                            <ng-option value="ح">ح (J)</ng-option>
                            <ng-option value="د">د (D)</ng-option>
                            <ng-option value="ر">ر (R)</ng-option>
                            <ng-option value="س">س (S)</ng-option>
                            <ng-option value="ص">ص (X)</ng-option>
                            <ng-option value="ط">ط (T)</ng-option>
                            <ng-option value="ع">ع (E)</ng-option>
                            <ng-option value="ق">ق (G)</ng-option>
                            <ng-option value="ك">ك (K)</ng-option>
                            <ng-option value="ل">ل (L)</ng-option>
                            <ng-option value="م">م (Z)</ng-option>
                            <ng-option value="ن">ن (N)</ng-option>
                            <ng-option value="ه">ه (H)</ng-option>
                            <ng-option value="و">و (U)</ng-option>
                            <ng-option value="ى">ى (V)</ng-option>
                        </ng-select>
                    </ng-template>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.sfdaStoringCategory' | translate}}
                </td>
                <td class="row_content" colspan="3" style="overflow:visible">
                    <ng-select class="form-control table-inline" name="companyActivity" #sfdaCompanyActivity="ngModel"
                        [disabled]="formMode == 1" [(ngModel)]="asset.properties.wasl.storingCategory"
                        style="width:100%">
                        <ng-option value="SCD1"> {{ 'enums.sfdaStoringCategory.SCD1' | translate}}</ng-option>
                        <ng-option value="SCD2"> {{ 'enums.sfdaStoringCategory.SCD2' | translate}}</ng-option>
                        <ng-option value="SCD3"> {{ 'enums.sfdaStoringCategory.SCD3' | translate}} </ng-option>
                        <ng-option value="SCC1"> {{ 'enums.sfdaStoringCategory.SCC1' | translate}} </ng-option>
                        <ng-option value="SCM1"> {{ 'enums.sfdaStoringCategory.SCM1' | translate}} </ng-option>
                        <ng-option value="SCM2"> {{ 'enums.sfdaStoringCategory.SCM2' | translate}} </ng-option>
                        <ng-option value="SCM3"> {{ 'enums.sfdaStoringCategory.SCM3' | translate}} </ng-option>
                        <ng-option value="SCM4"> {{ 'enums.sfdaStoringCategory.SCM4' | translate}} </ng-option>
                        <ng-option value="SCM5"> {{ 'enums.sfdaStoringCategory.SCM5' | translate}} </ng-option>
                        <ng-option value="SCF1"> {{ 'enums.sfdaStoringCategory.SCF1' | translate}} </ng-option>
                        <ng-option value="SCF2"> {{ 'enums.sfdaStoringCategory.SCF2' | translate}} </ng-option>
                        <ng-option value="SCF3"> {{ 'enums.sfdaStoringCategory.SCF3' | translate}} </ng-option>
                        <ng-option value="SCF4"> {{ 'enums.sfdaStoringCategory.SCF4' | translate}} </ng-option>
                        <ng-option value="SCA1"> {{ 'enums.sfdaStoringCategory.SCA1' | translate}} </ng-option>
                        <ng-option value="SCP1"> {{ 'enums.sfdaStoringCategory.SCP1' | translate}} </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.account' | translate}}
                </td>
                <td class="row_content" colspan="3" style="overflow:visible">
                    <fh-account-input name="account" #accountVar="ngModel" [readonly]="formMode == 1"
                        [selectedResellerId]="asset.resellerId" [tableInline]="true" [waslOnly]="true"
                        [showEmptyAccountsButton]="false" [filterEmptyAccounts]="true" [autoSelect]="false"
                        [activeOnly]="true" placeholder="{{ ( 'general.chooseAccount') | translate}}"
                        [(ngModel)]="asset.properties.wasl.overrideAccountId">
                    </fh-account-input>
                </td>
            </tr>
        </tbody>
    </table>
</div>