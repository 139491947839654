import { Pipe, PipeTransform } from '@angular/core';

/**
 * A simple filter that checks if hidden is present in the object
 */
@Pipe({
    name: 'hiddenFilter'
})
export class HiddenFilterPipe implements PipeTransform {
    transform(items: any[]): any[] {
        if (!items) {
            return null;
        }

        return items.filter(function (item) {
            return !('hidden' in item && item.hidden !== false);
        });
    }
}
