<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li *ngIf="trailer?.accountId">
                <a href="/#/AccountDetails/Index/{{trailer?.accountId}}">{{trailer?.companyName}}</a>
            </li>
            <li>
                <a href="/#/Trailers/Overview">{{'general.trailer' | translate }}</a>
            </li>
            <li>
                <a href="/#/TrailerDetails/Index/{{trailer?.id}}">{{trailer?.name}}</a>
            </li>
            <li class="breadcrumb-item active">{{'general.dashboard' | translate }}</li>
        </ol>
</nav>
    </div>
    <fh-trailer-details-navigation [trailerId]="trailerId"></fh-trailer-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error || warning || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [warning]="warning" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">

                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-trailer"></i> {{'general.trailer' | translate }}
                            </h5>
                        </div>
                        <fh-trailer-details [trailer]="trailer" [formMode]="formMode" [loading]="loading || saving">
                        </fh-trailer-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()" (onDelete)="onDelete()"
                            [allowDelete]="allowDelete" (setFormMode)="setFormMode($event)" [formMode]="formMode">
                            <span class="float-right">
                                <fh-task-dropdown (onFinishAction)="finishTask($event)" #taskDropdown
                                    [entityTypes]="tagType" [entity]="trailer"></fh-task-dropdown>
                            </span>
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">

                <fh-trailer-devices [deviceAssignments]="trailerAssignments" [loading]="loadingAssignments">
                </fh-trailer-devices>

                <fh-tagging [objectId]="trailer?.id" [objectType]="tagType">
                </fh-tagging>

            </div>
        </div>
    </div>
</fh-permission-handler>