import { Schedule } from './commutingSchedule.model';

export class TriggerSchedule {
    id: string;
    schedule: Schedule[] = [];
    triggerScheduleName: string;
    timestamp: string;
    accountId: any;
    companyName: string;
    timezoneIana = 'Europe/Amsterdam';
    resellerId: any;
    resellerDescription: any;
    markAsPrivate: boolean;
    isAccountDefault: boolean;
}

export class ScheduleAssignment {
    id: string;
    triggerScheduleId: any;
    triggerScheduleName: string;
    assetId: any;
    assetGroupId: any;
    projectId: any;
    accountId: any;
    startDate: any;
    endDate: any;
    applyScheduleToTrip = false;
}
