<div class="slideMenuDetails white-bg fontColor" style="position:relative">
    <ngx-loading [show]="loadingSidebar"></ngx-loading>
    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody>
            <tr>
                <td class="row_opening">
                    {{ 'general.name' | translate }}
                </td>
                <td class="row_content" colspan="2">
                    <a class="secondary link_bolder" href="/#/DeviceDetails/Index/{{selectedDevice?.id}}">
                        {{ selectedDevice?.asset?.name }}</a>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.brand' | translate }}
                </td>
                <td class="row_content" colspan="2">
                    {{ selectedDevice?.asset?.brand }}
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.model' | translate }}
                </td>
                <td class="row_content" colspan="2">
                    {{ selectedDevice?.asset?.model }}
                </td>
            </tr>
            <tr *ngIf="selectedDevice?.asset?.year">
                <td class="row_opening">
                    {{ 'general.year' | translate }}
                </td>
                <td class="row_content" colspan="2">
                    {{ selectedDevice?.asset?.year }}
                </td>
            </tr>
            <tr *ngIf="selectedDevice?.asset?.color">
                <td class="row_opening">
                    {{ 'general.color' | translate }}
                </td>
                <td class="row_content" colspan="2">
                    {{ selectedDevice?.asset?.color }}
                </td>
            </tr>
            <tr *ngIf="selectedDevice?.asset?.vin">
                <td class="row_opening">
                    {{ 'general.vin' | translate }}
                </td>
                <td class="row_content" colspan="2">
                    {{ selectedDevice?.asset?.vin }}
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.lastCommunication' | translate}}
                </td>
                <td class="row_content" title="{{deviceState?.communicationState?.updateTimestamp}}">
                    {{ deviceState?.communicationState?.updateTimestamp | amTimeAgo }}
                </td>
                <td class="row_content"><img style="position: absolute; right: 20px; top: 0px;"
                        [src]="getIcon(selectedDevice?.asset?.icon)"></td>
            </tr>
            <tr *ngIf="permissions['Entities_DeviceTypes']">
                <td class="row_opening">
                    {{ 'general.modelId' | translate}}
                </td>
                <td class="row_content" colspan="2">
                    <a class="secondary link_bolder"
                        href="/#/Devices/DeviceTypeDetails/Index/{{selectedDeviceExtended?.deviceTypeId}}">
                        {{ selectedDeviceExtended?.deviceTypeNameFull }}
                    </a>
                </td>
            </tr>
            <tr *ngIf="permissions['Entities_DeviceTypes']">
                <td class="row_opening">
                    {{ 'general.unitId' | translate }}
                </td>
                <td class="row_content" colspan="2">
                    {{ selectedDevice?.unitId }}
                </td>
            </tr>
            <tr *ngIf="selectedDeviceExtended?.asset?.vin">
                <td class="row_opening">
                    {{ 'general.vin' | translate }}
                </td>
                <td class="row_content" colspan="2">
                    {{ selectedDeviceExtended?.asset?.vin }}
                </td>
            </tr>
            <tr *ngIf="deviceState?.currentAddress">
                <td class="row_opening">
                    {{ 'general.near' | translate}}
                </td>
                <td class="row_content" colspan="2"
                    title="{{ deviceState?.currentAddress?.address }}, {{ deviceState?.currentAddress?.city }}, {{ deviceState?.currentAddress?.country }}">
                    {{ deviceState?.currentAddress?.city }}
                    {{ deviceState?.currentAddress?.address ? ', ' +
                    deviceState.currentAddress?.address:'' }}
                    {{ deviceState?.currentAddress?.country ? ', ' +
                    deviceState?.currentAddress?.country :'' }}
                </td>
            </tr>
            <tr *ngIf="selectedDevice?.asset?.currentAddress">
                <td class="row_opening">
                    {{ 'general.near' | translate}}
                </td>
                <td class="row_content" colspan="2"
                    title="{{ selectedDevice?.asset?.currentAddress?.address }}, {{ selectedDevice?.asset?.currentAddress?.city }}, {{ selectedDevice?.asset?.currentAddress?.country }}">
                    {{ selectedDevice?.asset?.currentAddress?.city }}
                    {{ selectedDevice?.asset?.currentAddress?.address ? ', ' +
                    selectedDevice?.asset?.currentAddress?.address:'' }}
                    {{ selectedDevice?.asset?.currentAddress?.country ? ', ' +
                    selectedDevice?.asset?.currentAddress?.country :'' }}
                </td>
            </tr>
            <!-- <tr *ngIf="selectedDeviceExtended?.asset?.driver && permissions['Drivers_View']">
                <td class="row_opening">
                    {{ 'general.driver' | translate}}
                </td>
                <td class="row_content" colspan="2">
                    <a class="secondary link_bolder"
                        href="/#/DriverDetails/Index/{{selectedDeviceExtended?.asset?.driver?.id }}"> {{
                        selectedDeviceExtended?.asset?.driver?.name }}</a>
                </td>
            </tr> -->
            <tr *ngIf="permissions['Accounts_View']">
                <td class="row_opening">
                    {{ 'general.customer' | translate}}
                </td>
                <td class="row_content" colspan="2">
                    <a class="secondary link_bolder" href="/#/AccountDetails/Index/{{selectedDevice?.accountId}}">{{
                        selectedDevice?.companyName }}</a>
                </td>
            </tr>
            <tr *ngIf="permissions['AssetGroups_View']">
                <td class="row_opening">
                    {{ 'general.assetGroups' | translate}}
                </td>
                <td class="row_content" colspan="2" style="white-space: normal;">
                    <span *ngFor="let group of selectedDeviceExtended?.asset?.assetGroups">
                        <a class="btn btn-info btn-grid" href="/#/AssetGroupDetails/Index/{{group?.id}}"
                            style="text-wrap: nowrap; margin: 2px;">
                            <i class="fas fa-fw fa-layer-group" [style.color]="group.color"></i> {{group?.name
                            }}</a>
                    </span>
                </td>
            </tr>
            <tr *ngIf="!showAll">
                <td class="row_content" colspan="3">
                    <span class="float-right"
                        tooltip="{{ 'general.location' | translate}} / {{ 'general.utilizationLastWeek' | translate}}">

                        <div class="fa_checkbox fa_toggle">
                            <input [checked]="true" id="showUtilization" type="checkbox" name="showUtilization"
                                (click)="flipUtilization()" />
                            <label for="showUtilization"></label>
                        </div>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>

    <!-- Utilization -->
    <div class="flex-container" *ngIf="showUtilization || showAll">
        <div class="col-4" style="padding:0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ selectedDeviceUtilization?.segmentsDistance ?
                    selectedDeviceUtilization?.segmentsDistance.toLocaleString() : '-' }} {{ translatedKm }}
                </h3>
                <div>{{'general.distance' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ selectedDeviceUtilization?.tripDurationInSeconds ?
                    actualRoundSeconds(selectedDeviceUtilization?.tripDurationInSeconds) : '-'
                    }}
                </h3>
                <div>{{'general.duration' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ selectedDeviceUtilization?.idlingDurationInSeconds ?
                    actualRoundSeconds(selectedDeviceUtilization?.idlingDurationInSeconds) :
                    '-'
                    }}
                </h3>
                <div>{{'general.idling' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <a class='secondary link_bolder' (click)="$event.stopPropagation();"
                        href="/#/DeviceDetails/Trips/{{selectedDevice?.id}}"> {{
                        selectedDeviceUtilization?.tripCount ?
                        selectedDeviceUtilization?.tripCount : '-' }}</a>
                </h3>
                <div>{{'general.trips' | translate }}</div>
            </div>
        </div>

        <div class="col-4" style="padding:0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ selectedDeviceUtilization?.accelCount ?
                    selectedDeviceUtilization?.accelCount : '-' }}
                </h3>
                <div>{{'general.acceleration' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ selectedDeviceUtilization?.decelCount ?
                    selectedDeviceUtilization?.decelCount : '-' }}
                </h3>
                <div>{{'general.braking' | translate }}</div>
            </div>
        </div>
        <!-- <div class="col-4" style="padding:0">
                            <div class="kpi-item-small">
                                <h3 class="margin-bottom font-bold">
                                    {{ selectedDeviceUtilization?.corneringCount ?
                                    selectedDeviceUtilization?.corneringCount : '-' }}
                                </h3>
                                <div>{{'general.cornering' | translate }}</div>
                            </div>
                        </div> -->
        <div class="col-4" style="padding:0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ selectedDeviceUtilization?.roadSpeedingCount ?
                    selectedDeviceUtilization?.roadSpeedingCount : '-' }}
                </h3>
                <div>{{'general.speeding' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ selectedDeviceUtilization?.maxSpeed ?
                    selectedDeviceUtilization?.maxSpeed : '-' }}
                </h3>
                <div>{{'general.maxSpeed' | translate }}</div>
            </div>
        </div>
    </div>

    <!-- Device State -->
    <div class="flex-container" *ngIf="!showUtilization || showAll">
        <div class="col-4" style="padding:0">
            <div title="DeviceState: '{{ 'general.deviceState_' + deviceState?.calculatedDeviceState?.deviceState
            | translate }}' since {{ deviceState?.calculatedDeviceState?.stateChangedTimestamp }}"
                class="kpi-item-small border-active" [ngClass]="deviceState?.markerColor">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-map-marker-alt eye-active "
                        [ngClass]="deviceState?.markerColor"></i>
                    {{ ('general.deviceState_' +
                    deviceState?.calculatedDeviceState?.deviceState) |
                    translate }}
                </h3>
                <div title="{{deviceState?.calculatedDeviceState?.stateChangedTimestamp}}">
                    {{'general.stateSince' | translate }}
                    {{deviceState?.calculatedDeviceState?.stateChangedTimestamp |
                    amTimeAgo:true}}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0">
            <div title="Speed: '{{deviceState?.currentPosition?.speed}}' on {{ deviceState?.currentPosition?.updateTimestamp }}"
                class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ deviceState?.currentPosition?.speed ?
                    deviceState?.currentPosition?.speed : '-' }} {{ translatedKmh }}
                </h3>
                <div>{{'general.speed' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0">
            <div title="Heading: '{{deviceState?.currentPosition?.heading}}' on {{ deviceState?.currentPosition?.updateTimestamp }}"
                class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i *ngIf="deviceState" [ngClass]="deviceState?.markerColor"
                        [ngStyle]="{'transform': 'rotate('+deviceState?.currentPosition?.heading+'deg)', '-webkit-transform': 'rotate('+deviceState?.currentPosition?.heading+'deg)', '-ms-transform': 'rotate('+deviceState?.currentPosition?.heading+'deg)'}"
                        class="full-icon fas fa-arrow-up eye-active "></i>

                    {{ deviceState?.currentPosition?.heading ?
                    deviceState?.currentPosition?.heading :
                    '-' }} °
                </h3>
                <div>{{'general.heading' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0" *ngIf="deviceState?.externalBattery?.batteryLevelInVoltage">
            <div [title]="deviceState?.externalBattery?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-car-battery"></i> {{
                    deviceState?.externalBattery?.batteryLevelInVoltage ?
                    actualRound(deviceState?.externalBattery?.batteryLevelInVoltage,2).toLocaleString()
                    : '-' }} V
                </h3>
                <div>{{'general.battery' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0" *ngIf="deviceState?.engineCoolantTemperature?.temperatureInCelcius">
            <div [title]="deviceState?.engineCoolantTemperature?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-thermometer-three-quarters"></i> {{
                    deviceState?.engineCoolantTemperature?.temperatureInCelcius ?
                    actualRound(deviceState?.engineCoolantTemperature?.temperatureInCelcius, 2) : '-' }} °C
                </h3>
                <div>{{'general.engineCoolantTemperature' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0" *ngIf="deviceState?.temperature1?.temperatureInCelcius">
            <div [title]="deviceState?.temperature1?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-thermometer-three-quarters"></i> {{
                    deviceState?.temperature1?.temperatureInCelcius ?
                    actualRound(deviceState?.temperature1?.temperatureInCelcius, 2) : '-' }} °C
                </h3>
                <div>{{'general.temperature' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0" *ngIf="deviceState?.temperature2?.temperatureInCelcius">
            <div [title]="deviceState?.temperature2?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-thermometer-three-quarters"></i> {{
                    deviceState?.temperature2?.temperatureInCelcius ?
                    actualRound(deviceState?.temperature2?.temperatureInCelcius, 2): '-' }} °C
                </h3>
                <div>{{'general.temperature' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0" *ngIf="deviceState?.rpmStatus?.rpm">
            <div [title]="deviceState?.rpmStatus?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-engine"></i> {{
                    deviceState?.rpmStatus?.rpm ?
                    actualRound(deviceState?.rpmStatus?.rpm, 0) : '-' }}
                </h3>
                <div>{{'general.rpm' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0" *ngIf="deviceState?.fuel?.fuelLevelInPercentage">
            <div [title]="deviceState?.fuel?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-gas-pump"></i> {{
                    deviceState?.fuel?.fuelLevelInPercentage ?
                    actualRound(deviceState?.fuel?.fuelLevelInPercentage, 0) : '-' }}%
                </h3>
                <div>{{'general.fuel' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0" *ngIf="deviceState?.humidity1?.humidityInPercent">
            <div [title]="deviceState?.humidity1?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-humidity"></i> {{
                    deviceState?.humidity1?.humidityInPercent ?
                    actualRound(deviceState?.humidity1?.humidityInPercent, 0) : '-' }}%
                </h3>
                <div>{{'general.humidity' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0" *ngIf="deviceState?.totalAxleWeight">
            <div [title]="deviceState?.totalAxleWeight?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-weight"></i> {{
                    deviceState?.totalAxleWeight?.totalWeightInKg ?
                    actualRound(deviceState?.totalAxleWeight?.totalWeightInKg, 0).toLocaleString()
                    : '-' }} kg
                </h3>
                <div>{{'general.weight' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0">
            <div [title]="deviceState?.odometer?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ (deviceState?.odometer?.gpsOdometer !== null)
                    ? (actualRound(( selectedDeviceExtended?.asset?.calibrationOdoOffsetInKm ?? 0) +
                    (deviceState?.odometer?.gpsOdometer / 1000),
                    0)).toLocaleString() : '-' }} {{ translatedKm }}
                </h3>
                <div>{{'general.odo' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0" *ngIf="deviceState?.currentPosition?.radiusInMeters">
            <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    {{ deviceState?.currentPosition?.radiusInMeters ?
                    deviceState?.currentPosition?.radiusInMeters : '-' }} m
                </h3>
                <div>{{'general.radiusInMeters' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0"
            *ngIf="selectedDeviceExtended?.asset?.driver && permissions['Drivers_View']">
            <div class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <a class="secondary link_bolder"
                        href="/#/DriverDetails/Index/{{selectedDeviceExtended?.asset?.driver?.id }}">
                        {{
                        selectedDeviceExtended?.asset?.driver?.name }}</a>
                </h3>
                <div>{{'general.driver' | translate }}</div>
            </div>
        </div>
        <div class="col-4" style="padding:0" *ngIf="deviceState?.tagScanStatus?.tag != undefined">
            <div [title]="deviceState?.tagScanStatus?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">

                    <a *ngIf="permissions['Drivers_View'] && selectedDeviceExtended?.scannedDriver"
                        class="secondary link_bolder"
                        href="/#/DriverDetails/Index/{{selectedDeviceExtended?.scannedDriver?.id }}">
                        <i class="full-icon fas fa-fw fa-tag"></i>
                        {{ selectedDeviceExtended?.scannedDriver?.name }}</a>

                    <span *ngIf="!selectedDeviceExtended?.scannedDriver"> <i class="fas fa-fw fa-tag"></i>
                        {{ deviceState?.tagScanStatus?.tag.toLocaleString() }}</span>

                </h3>
                <div>{{'general.scannedDriver' | translate }} <i
                        *ngIf="deviceState?.tagScanStatus?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                        class="fa fa-fw history-icon hand fa-history"
                        title="{{ deviceState?.tagScanStatus?.updateTimestamp }}"></i></div>
            </div>
        </div>

        <!-- IO States -->
        <div class="col-4" style="padding:0"
            *ngIf="(selectedDeviceExtended?.settings?.inputPorts[0] && selectedDeviceExtended?.settings?.inputPorts[0].byte != null && selectedDeviceExtended?.settings?.inputPorts[0].byte != 0) || showUnMapped">
            <div [title]="deviceState?.input1?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                        [ngStyle]="{'color': (deviceState?.input1 == null) ? '#ccc' : (deviceState?.input1?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                    {{ 'general.inputFormatted' | translate:{ value: '1' } }}
                </h3>
                <div>
                    {{selectedDeviceExtended?.settings?.inputPorts && 'enums.locationEventType.' +
                    selectedDeviceExtended?.settings?.inputPorts[0]?.byte | translate }}
                </div>
            </div>
        </div>
        <div class="col-4" style="padding:0"
            *ngIf="(selectedDeviceExtended?.settings?.inputPorts[1] && selectedDeviceExtended?.settings?.inputPorts[1].byte != null && selectedDeviceExtended?.settings?.inputPorts[1].byte != 0) || showUnMapped">
            <div [title]="deviceState?.input2?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                        [ngStyle]="{'color': (deviceState?.input2 == null) ? '#ccc' : (deviceState?.input2?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                    {{ 'general.inputFormatted' | translate:{ value: '2' } }}
                </h3>
                <div>
                    {{selectedDeviceExtended?.settings?.inputPorts && 'enums.locationEventType.' +
                    selectedDeviceExtended?.settings?.inputPorts[1]?.byte | translate }}
                </div>
            </div>
        </div>
        <div class="col-4" style="padding:0"
            *ngIf="(selectedDeviceExtended?.settings?.inputPorts[2] && selectedDeviceExtended?.settings?.inputPorts[2].byte != null && selectedDeviceExtended?.settings?.inputPorts[2].byte != 0) || showUnMapped">
            <div [title]="deviceState?.input3?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                        [ngStyle]="{'color': (deviceState?.input3 == null) ? '#ccc' : (deviceState?.input3?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                    {{ 'general.inputFormatted' | translate:{ value: '3' } }}
                </h3>
                <div>
                    {{selectedDeviceExtended?.settings?.inputPorts && 'enums.locationEventType.' +
                    selectedDeviceExtended?.settings?.inputPorts[2]?.byte | translate }}
                </div>
            </div>
        </div>
        <div class="col-4" style="padding:0"
            *ngIf="(selectedDeviceExtended?.settings?.inputPorts[3] && selectedDeviceExtended?.settings?.inputPorts[3].byte != null && selectedDeviceExtended?.settings?.inputPorts[3].byte != 0) || showUnMapped">
            <div [title]="deviceState?.input4?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                        [ngStyle]="{'color': (deviceState?.input4 == null) ? '#ccc' : (deviceState?.input4?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                    {{ 'general.inputFormatted' | translate:{ value: '4' } }}
                </h3>
                <div>
                    {{selectedDeviceExtended?.settings?.inputPorts && 'enums.locationEventType.' +
                    selectedDeviceExtended?.settings?.inputPorts[3]?.byte | translate }}
                </div>
            </div>
        </div>
        <div class="col-4" style="padding:0"
            *ngIf="(selectedDeviceExtended?.settings?.inputPorts[4] && selectedDeviceExtended?.settings?.inputPorts[4].byte != null && selectedDeviceExtended?.settings?.inputPorts[4].byte != 0) || showUnMapped">
            <div [title]="deviceState?.input5?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                        [ngStyle]="{'color': (deviceState?.input5 == null) ? '#ccc' : (deviceState?.input5?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                    {{ 'general.inputFormatted' | translate:{ value: '5' } }}
                </h3>
                <div>
                    {{selectedDeviceExtended?.settings?.inputPorts && 'enums.locationEventType.' +
                    selectedDeviceExtended?.settings?.inputPorts[4]?.byte | translate }}
                </div>
            </div>
        </div>
        <div class="col-4" style="padding:0"
            *ngIf="(selectedDeviceExtended?.settings?.inputPorts[5] && selectedDeviceExtended?.settings?.inputPorts[5].byte != null && selectedDeviceExtended?.settings?.inputPorts[5].byte != 0) || showUnMapped">
            <div [title]="deviceState?.input6?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                        [ngStyle]="{'color': (deviceState?.input6 == null) ? '#ccc' : (deviceState?.input6?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                    {{ 'general.inputFormatted' | translate:{ value: '6' } }}
                </h3>
                <div>
                    {{selectedDeviceExtended?.settings?.inputPorts && 'enums.locationEventType.' +
                    selectedDeviceExtended?.settings?.inputPorts[5]?.byte | translate }}
                </div>
            </div>

        </div>
        <!-- <div class="col-4" style="padding:0"
            *ngIf="(selectedDeviceExtended?.settings?.ignition && selectedDeviceExtended?.settings?.ignition != null && selectedDeviceExtended?.settings?.ignition != 0) || showUnMapped">
            <div [title]="deviceState?.ignition?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                        [ngStyle]="{'color': (deviceState == null || deviceState?.ignition == null) ? '#ccc' : (deviceState?.ignition?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                    {{ 'general.ignition' | translate}} ({{ 'general.port' | translate}})
                </h3>
                <div> {{selectedDeviceExtended?.settings?.ignition && 'enums.locationEventType.' +
                    selectedDeviceExtended?.settings?.ignition | translate }}</div>
            </div>

        </div>
        <div class="col-4" style="padding:0"
            *ngIf="(selectedDeviceExtended?.settings?.externalPower && selectedDeviceExtended?.settings?.externalPower != null && selectedDeviceExtended?.settings?.externalPower != 0) || showUnMapped">
            <div [title]="deviceState?.externalPower?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                        [ngStyle]="{'color': (deviceState?.externalPower == null) ? '#ccc' : (deviceState?.externalPower?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                    {{ 'general.externalPower' | translate}} ({{ 'general.port' | translate}})
                </h3>
                <div> {{selectedDeviceExtended?.settings?.externalPower && 'enums.locationEventType.' +
                    selectedDeviceExtended?.settings?.externalPower | translate }}</div>
            </div>
        </div> -->
        <div class="col-4" style="padding:0"
            *ngIf="(selectedDeviceExtended?.settings?.outputPorts[0] && selectedDeviceExtended?.settings?.outputPorts[0].byte != null && selectedDeviceExtended?.settings?.outputPorts[0].byte != 0) || showUnMapped">
            <div [title]="deviceState?.output1?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                        [ngStyle]="{'color': (deviceState?.output1 == null) ? '#ccc' : (deviceState?.output1?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                    {{ 'general.outputFormatted' | translate:{ value: '1' } }}
                </h3>
                <div>
                    {{selectedDeviceExtended?.settings?.outputPorts && 'enums.deviceOutput.' +
                    selectedDeviceExtended?.settings?.outputPorts[0]?.byte | translate }}
                </div>
            </div>
        </div>
        <div class="col-4" style="padding:0"
            *ngIf="(selectedDeviceExtended?.settings?.outputPorts[1] && selectedDeviceExtended?.settings?.outputPorts[1].byte != null && selectedDeviceExtended?.settings?.outputPorts[1].byte != 0) || showUnMapped">
            <div [title]="deviceState?.output2?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                        [ngStyle]="{'color': (deviceState?.output2 == null) ? '#ccc' : (deviceState?.output2?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                    {{ 'general.outputFormatted' | translate:{ value: '2' } }}
                </h3>
                <div>
                    {{selectedDeviceExtended?.settings?.outputPorts && 'enums.deviceOutput.' +
                    selectedDeviceExtended?.settings?.outputPorts[1]?.byte | translate }}
                </div>
            </div>
        </div>
        <div class="col-4" style="padding:0"
            *ngIf="(selectedDeviceExtended?.settings?.outputPorts[2] && selectedDeviceExtended?.settings?.outputPorts[2].byte != null && selectedDeviceExtended?.settings?.outputPorts[2].byte != 0)  || showUnMapped">
            <div [title]="deviceState?.output3?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                        [ngStyle]="{'color': (deviceState?.output3 == null) ? '#ccc' : (deviceState?.output3?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                    {{ 'general.outputFormatted' | translate:{ value: '3' } }}
                </h3>
                <div>
                    {{selectedDeviceExtended?.settings?.outputPorts && 'enums.deviceOutput.' +
                    selectedDeviceExtended?.settings?.outputPorts[2]?.byte | translate }}
                </div>
            </div>
        </div>
        <div class="col-4" style="padding:0"
            *ngIf="(selectedDeviceExtended?.settings?.outputPorts[3] && selectedDeviceExtended?.settings?.outputPorts[3].byte != null && selectedDeviceExtended?.settings?.outputPorts[3].byte != 0)  || showUnMapped">
            <div [title]="deviceState?.output4?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                        [ngStyle]="{'color': (deviceState?.output4 == null) ? '#ccc' : (deviceState?.output4?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                    {{ 'general.outputFormatted' | translate:{ value: '4' } }}
                </h3>
                <div>
                    {{selectedDeviceExtended?.settings?.outputPorts && 'enums.deviceOutput.' +
                    selectedDeviceExtended?.settings?.outputPorts[3]?.byte | translate }}
                </div>
            </div>
        </div>
        <div class="col-4" style="padding:0"
            *ngIf="(selectedDeviceExtended?.settings?.analogPorts[0] && selectedDeviceExtended?.settings?.analogPorts[0].byte != null && selectedDeviceExtended?.settings?.analogPorts[0].byte != 0)  || showUnMapped">
            <div [title]="deviceState?.analog1?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-bolt"></i>
                    {{deviceState?.analog1 ?
                    deviceState?.analog1?.value?.toLocaleString() : '-' }} V
                </h3>
                <div>
                    {{selectedDeviceExtended?.settings?.analogPorts && 'enums.analogFunctions.' +
                    selectedDeviceExtended?.settings?.analogPorts[0]?.byte | translate }} (Analog 1)
                </div>
            </div>
        </div>
        <div class="col-4" style="padding:0"
            *ngIf="(selectedDeviceExtended?.settings?.analogPorts[1] && selectedDeviceExtended?.settings?.analogPorts[1].byte != null && selectedDeviceExtended?.settings?.analogPorts[1].byte != 0)  || showUnMapped">
            <div [title]="deviceState?.analog2?.updateTimestamp" class="kpi-item-small">
                <h3 class="margin-bottom font-bold">
                    <i class="full-icon fas fa-fw fa-bolt"></i>
                    {{deviceState?.analog2 ?
                    deviceState?.analog2?.value?.toLocaleString() : '-' }} V
                </h3>
                <div>
                    {{selectedDeviceExtended?.settings?.analogPorts && 'enums.analogFunctions.' +
                    selectedDeviceExtended?.settings?.analogPorts[1]?.byte | translate }} (Analog 2)
                </div>
            </div>
        </div>
        <div *ngIf="immobilizationType && permissions['FleetManagement_ImmobilizeVehicle']" class="col-4">
            <ngx-loading [show]="loading"></ngx-loading>
            <div class="kpi-item-small">
                <button class="btn btn-primary" style="width:100%" *ngIf="immobilizationStatus == 1"
                    (click)="immobilise()"><i class="fas fa-fw fa-check"></i>
                    {{'general.immobilise' | translate }}</button>

                <button class="btn btn-danger" style="width:100%" *ngIf="immobilizationStatus > 2" (click)="abort()"><i
                        class="fas fa-fw fa-times"></i>
                    {{'general.abort' | translate }}</button>

                <button class="btn btn-primary" style="width:100%" *ngIf="immobilizationStatus == 2"
                    (click)="unimmobilise()"><i class="fas fa-fw fa-check"></i>
                    {{'general.unimmobilise' | translate }}</button>
            </div>
        </div>
        <div *ngIf="permissions['Actions_SendCustomCommand']" class="col-4">
            <div  style="overflow: visible; padding: 3px;">
                <button class="btn btn-primary" style="width:100%" *ngIf="permissions['Actions_SendCustomCommand']"
                    (click)="customCommandModal(customCommandTemplate)"><i class="fas fa-fw fa-tower-broadcast"></i>
                    {{'general.commands' | translate }}</button>
            </div>
        </div>
        <div style="clear:both"></div>        
    </div>
</div>

<ng-template #customCommandTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        <i class="fas fa-fw fa-tower-broadcast" style="margin-right: 5px"></i>
        {{'general.customCommands' | translate }}
      </h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form #groupForm="ngForm" style="width: 100%; border-top: 0px">
            <fh-batch-custom-commands
            [gridSelection]="[selectedDeviceExtended?.id]" (onFinish)="processCustomCommand($event)">
            </fh-batch-custom-commands>
        </form>
    </div>
</ng-template>