<form #groupForm="ngForm" style="width: 100%; border-top: 0px">
    <table class="table nowrap dtr-inline no-footer" *ngIf="reportSubscription">
        <thead>
            <tr>
                <th class="row_opening">{{ 'general.name' | translate}}</th>
                <th class="row_content">{{ 'general.value' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="row_opening">
                    {{ 'general.name' | translate}}
                </td>
                <td class="row_content">
                    {{ reportSubscription?.name != "" ?
                    reportSubscription?.name : ('enums.reportType.' + reportSubscription?.selectedReportType) |
                    translate }}
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'report.period' | translate}}
                    <span class="error" *ngIf="dateVar.invalid">*</span>
                </td>
                <td class="row_content">
                    <input name="Daterangepicker" style="width:100%" placeholder="{{ 'report.period' | translate}}"
                        type="text" [bsConfig]="dpConfig" class="form-control table-inline" bsDaterangepicker
                        #dateVar="ngModel" required (ngModelChange)="dateChanged($event)"
                        (onHidden)="dateChanged('hidden')" [(ngModel)]="daterangepickerModel">
                    <span class="input-group-calendar">
                        <i class="fa fa-calendar-alt"></i>
                    </span>
                </td>
            </tr>
            <tr *ngIf="permissions['Platform_IsReseller']">
                <td class="row_opening">
                    {{ 'general.includeExecutedSql' | translate }}
                </td>
                <td class="row_content">
                    <div class="fa_checkbox fa_toggle">
                        <input id="include" type="checkbox" name="include" [(ngModel)]="includeExecutedSql" />
                        <label for="include"></label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</form>