import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { AuditLog } from '../../models/auditlog.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { DeviceService } from '../../services/device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/services/users/user.service';
import { AppUser } from 'app/models/user.model';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { getDefaultDpConfig } from 'app/common/gridhelper';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { AccountService } from 'app/services/account/account.service';
window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-account-notifications',
    templateUrl: 'notifications.template.html'
})
export class AccountNotificationsViewComponent implements OnInit {
    deviceLog: AuditLog[];
    sub;

    loading = false;
    loadingNotifications = false;

    error: any;
    success: any;
    notifications = [];
    selectedNotification;

    currentPage;
    filter;

    // Daterange
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    daterangepickerModel: Date[];
    userId: any;
    timezoneIana: string;
    account: any;

    constructor(private authenticationService: AuthenticationService, private cd: ChangeDetectorRef, private http: HttpClient, private auditLogService: AuditLogService, private accountService: AccountService, private route: ActivatedRoute, private router: Router, private translateService: TranslateService) {
        this.account = null;
        this.deviceLog = [];

        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(1, 'week').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).add(0, 'days').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, this.authenticationService);
    }

    dateChanged(event) {
        const that = this;
        this.loadingNotifications = true;

        if (event !== null) {
            this.daterangepickerModel = event;
        }

        this.accountService.getNotifications(this.account.id, moment(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(result => {
            this.notifications = result;
            this.loadingNotifications = false;
            this.cd.markForCheck();
        }, error => {
            this.error = error
            this.loadingNotifications = false;
            this.cd.markForCheck();
        });
    }

    ngOnInit() {
        this.loading = true;
        this.loadingNotifications = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.userId = id;
            this.accountService.getAccountById(id).subscribe(account => {
                this.account = account;

                if (this.account == null) {
                    this.router.navigate(['/Users/Overview'])
                }

                this.loading = false;

                this.dateChanged(null);
            });
        });
    }
}
