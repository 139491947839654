<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="device?.asset && device?.asset.accountId">
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}">{{'general.devices' | translate
                        }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.asset?.name ? device?.asset?.name :
                        device?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.externalApi' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device"></fh-device-details-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [warning]="warning" [success]="success" [autoRemove]=false></fh-error>
        </div>
    </div>

    <div class="row" *ngIf="device.asset?.id" data-equalizer="">
        <div class="col-lg-6">

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-link"></i> {{'general.asset' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.name' | translate}}
                                </td>
                                <td class="row_content">
                                    <input type="text" [readonly]=true name="ac_name" class="form-control table-inline"
                                        [(ngModel)]="device.asset.name" required #name="ngModel">
                                </td>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-link"></i> {{'general.waslStatus' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">{{ 'general.inquiryTime' | translate}}</td>
                                <td class="row_content"
                                    title="{{ device.asset.properties?.wasl?.inquiryStatus?.inquiryTime | amDateFormat: 'LLL'}}">
                                    {{ device.asset.properties?.wasl?.inquiryStatus?.inquiryTime ?
                                    (device.asset.properties?.wasl?.inquiryStatus?.inquiryTime |
                                    amTimeAgo:true) : '-'}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.isVehicleValid' | translate}}</td>
                                <td class="row_content">
                                    <i *ngIf="device.asset.properties?.wasl?.inquiryStatus?.isVehicleValid"
                                        class="far fa-fw fa-lg fa-check" style="color: rgba(0, 142, 67, 0.8);"></i>
                                    <i *ngIf="!device.asset.properties?.wasl?.inquiryStatus?.isVehicleValid"
                                        class="far fa-fw fa-lg fa-times" style="color: rgb(151, 28, 36);"></i>
                                </td>
                            </tr>
                            <tr *ngIf="!device.asset.properties?.wasl?.inquiryStatus?.isVehicleValid">
                                <td class="row_opening">{{ 'general.vehicleRejectionReason' | translate}}</td>
                                <td class="row_content">{{
                                    device.asset.properties?.wasl?.inquiryStatus?.vehicleRejectionReason }}</td>
                            </tr>

                            <tr>
                                <td class="row_opening">{{ 'general.receivedTime' | translate}}</td>
                                <td class="row_content"
                                    title="{{ device.asset.properties?.wasl?.inquiryStatus?.receivedTime | amDateFormat: 'LLL'}}">
                                    {{ device.asset.properties?.wasl?.inquiryStatus?.receivedTime ?
                                    (device.asset.properties?.wasl?.inquiryStatus?.receivedTime |
                                    amTimeAgo:true) : '-'}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.vehicleStatus' | translate}}</td>
                                <td class="row_content">{{ device.asset.properties?.wasl?.inquiryStatus?.vehicleStatus
                                    }}</td>
                            </tr>

                            <tr>
                                <td class="row_opening">{{ 'general.registrationTime' | translate}}</td>
                                <td class="row_content">{{
                                    device.asset.properties?.wasl?.registerDateWasl | amDateFormat:
                                    'LLL'}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.reference' | translate}}
                                </td>
                                <td class="row_content">
                                    {{ device.asset.properties.wasl.referenceKey }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [disabled]="waslForm.invalid" *ngIf="permissions['Wasl_Add']"
                        (click)="register('WASL')"><i class="fa fa-fw fa-edit"></i>
                        {{'general.register' | translate }}</button>

                    <button class="btn btn-danger"
                        *ngIf="device.asset.properties.wasl.registerDateWasl && permissions['Wasl_Delete']"
                        (click)="unRegister('WASL')"><i class="fa fa-fw fa-times"></i>
                        {{'general.unRegister' | translate }}</button>

                    <button class="btn btn-primary"
                        *ngIf="device.asset.properties.wasl.registerDateWasl && permissions['Wasl_Inquire']"
                        (click)="inquire('WASL')"><i class="fa fa-fw fa-broadcast-tower"></i>
                        {{'general.inquire' | translate }}</button>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-link"></i> {{'general.sfdaStatus' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">{{ 'general.connected' | translate}}</td>
                                <td class="row_content">{{ (device.asset.properties.wasl.registerDateSfda ?
                                    'general.true' : 'general.false')|
                                    translate}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.date' | translate}}</td>
                                <td class="row_content">{{ device.asset.properties.wasl.registerDateSfda | amDateFormat:
                                    'LLL'}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.reference' | translate}}
                                </td>
                                <td class="row_content">
                                    {{ device.asset.properties.wasl.referenceKey }}
                                </td>
                            </tr>
                            <tr *ngIf="device.asset.properties.wasl.registerDateSfda">
                                <td class="row_opening">
                                    {{ 'general.sfdaStoringCategory' | translate}}
                                </td>
                                <td class="row_content" colspan="3" style="overflow:visible">
                                    <ng-select class="form-control table-inline" name="companyActivity"
                                        #sfdaCompanyActivity="ngModel" [clearable]="false"
                                        [readonly]="!permissions['Wasl_UpdateStoringCategory']"
                                        [(ngModel)]="device.asset.properties.wasl.storingCategory" style="width:100%">
                                        <ng-option value="SCD1"> {{ 'enums.sfdaStoringCategory.SCD1' | translate}}
                                        </ng-option>
                                        <ng-option value="SCD2"> {{ 'enums.sfdaStoringCategory.SCD2' | translate}}
                                        </ng-option>
                                        <ng-option value="SCD3"> {{ 'enums.sfdaStoringCategory.SCD3' | translate}}
                                        </ng-option>
                                        <ng-option value="SCC1"> {{ 'enums.sfdaStoringCategory.SCC1' | translate}}
                                        </ng-option>
                                        <ng-option value="SCM1"> {{ 'enums.sfdaStoringCategory.SCM1' | translate}}
                                        </ng-option>
                                        <ng-option value="SCM2"> {{ 'enums.sfdaStoringCategory.SCM2' | translate}}
                                        </ng-option>
                                        <ng-option value="SCM3"> {{ 'enums.sfdaStoringCategory.SCM3' | translate}}
                                        </ng-option>
                                        <ng-option value="SCM4"> {{ 'enums.sfdaStoringCategory.SCM4' | translate}}
                                        </ng-option>
                                        <ng-option value="SCM5"> {{ 'enums.sfdaStoringCategory.SCM5' | translate}}
                                        </ng-option>
                                        <ng-option value="SCF1"> {{ 'enums.sfdaStoringCategory.SCF1' | translate}}
                                        </ng-option>
                                        <ng-option value="SCF2"> {{ 'enums.sfdaStoringCategory.SCF2' | translate}}
                                        </ng-option>
                                        <ng-option value="SCF3"> {{ 'enums.sfdaStoringCategory.SCF3' | translate}}
                                        </ng-option>
                                        <ng-option value="SCF4"> {{ 'enums.sfdaStoringCategory.SCF4' | translate}}
                                        </ng-option>
                                        <ng-option value="SCA1"> {{ 'enums.sfdaStoringCategory.SCA1' | translate}}
                                        </ng-option>
                                        <ng-option value="SCP1"> {{ 'enums.sfdaStoringCategory.SCP1' | translate}}
                                        </ng-option>
                                    </ng-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [disabled]="waslForm.invalid" *ngIf="permissions['Wasl_Add']"
                        (click)="register('SFDA')"><i class="fa fa-fw fa-edit"></i>
                        {{'general.register' | translate }}</button>

                    <button class="btn btn-danger"
                        *ngIf="device.asset.properties.wasl.registerDateSfda && permissions['Wasl_Delete']"
                        (click)="unRegister('SFDA')"><i class="fa fa-fw fa-times"></i>
                        {{'general.unRegister' | translate }}</button>

                    <button class="btn btn-primary"
                        *ngIf="device.asset.properties.wasl.registerDateSfda && permissions['Wasl_Inquire']"
                        (click)="inquire('SFDA')"><i class="fa fa-fw fa-broadcast-tower"></i>
                        {{'general.inquire' | translate }}</button>

                    <button class="btn btn-primary"
                        *ngIf="device.asset.properties.wasl.registerDateSfda && permissions['Wasl_UpdateStoringCategory']"
                        (click)="updateStoringCategory()"><i class="fa fa-fw fa-save"></i>
                        {{'general.updateStoringCategory' | translate }}</button>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-link"></i> {{'general.towStatus' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">{{ 'general.connected' | translate}}</td>
                                <td class="row_content">{{ (device.asset.properties.wasl.registerDateTow ?
                                    'general.true' : 'general.false')|
                                    translate}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.date' | translate}}</td>
                                <td class="row_content">{{ device.asset.properties.wasl.registerDateTow | amDateFormat:
                                    'LLL'}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.reference' | translate}}
                                </td>
                                <td class="row_content">
                                    {{ device.asset.properties.wasl.referenceKey }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [disabled]="waslForm.invalid" *ngIf="permissions['Wasl_Add']"
                        (click)="register('TOW_CAR')"><i class="fa fa-fw fa-edit"></i>
                        {{'general.register' | translate }}</button>

                    <button class="btn btn-danger"
                        *ngIf="device.asset.properties.wasl.registerDateTow && permissions['Wasl_Delete']"
                        (click)="unRegister('TOW_CAR')"><i class="fa fa-fw fa-times"></i>
                        {{'general.unRegister' | translate }}</button>

                    <button class="btn btn-primary"
                        *ngIf="device.asset.properties.wasl.registerDateTow && permissions['Wasl_Inquire']"
                        (click)="inquire('TOW_CAR')"><i class="fa fa-fw fa-broadcast-tower"></i>
                        {{'general.inquire' | translate }}</button>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-link"></i> {{'general.specialityTransportStatus' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">{{ 'general.connected' | translate}}</td>
                                <td class="row_content">{{ (device.asset.properties.wasl.registerDateSpecialityTransport
                                    ?
                                    'general.true' : 'general.false')|
                                    translate}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.date' | translate}}</td>
                                <td class="row_content">{{ device.asset.properties.wasl.registerDateSpecialityTransport
                                    | amDateFormat:
                                    'LLL'}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.reference' | translate}}
                                </td>
                                <td class="row_content">
                                    {{ device.asset.properties.wasl.referenceKey }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [disabled]="waslForm.invalid" *ngIf="permissions['Wasl_Add']"
                        (click)="register('SPECIALITY_TRANSPORT')"><i class="fa fa-fw fa-edit"></i>
                        {{'general.register' | translate }}</button>

                    <button class="btn btn-danger"
                        *ngIf="device.asset.properties.wasl.registerDateSpecialityTransport && permissions['Wasl_Delete']"
                        (click)="unRegister('SPECIALITY_TRANSPORT')"><i class="fa fa-fw fa-times"></i>
                        {{'general.unRegister' | translate }}</button>

                    <button class="btn btn-primary"
                        *ngIf="device.asset.properties.wasl.registerDateSpecialityTransport && permissions['Wasl_Inquire']"
                        (click)="inquire('SPECIALITY_TRANSPORT')"><i class="fa fa-fw fa-broadcast-tower"></i>
                        {{'general.inquire' | translate }}</button>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-link"></i> {{'general.busRentalStatus' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">{{ 'general.connected' | translate}}</td>
                                <td class="row_content">{{ (device.asset.properties.wasl.registerDateBusRental
                                    ?
                                    'general.true' : 'general.false')|
                                    translate}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.date' | translate}}</td>
                                <td class="row_content">{{ device.asset.properties.wasl.registerDateBusRental
                                    | amDateFormat:
                                    'LLL'}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.reference' | translate}}
                                </td>
                                <td class="row_content">
                                    {{ device.asset.properties.wasl.referenceKey }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [disabled]="waslForm.invalid" *ngIf="permissions['Wasl_Add']"
                        (click)="register('BUS_RENTAL')"><i class="fa fa-fw fa-edit"></i>
                        {{'general.register' | translate }}</button>

                    <button class="btn btn-danger"
                        *ngIf="device.asset.properties.wasl.registerDateBusRental && permissions['Wasl_Delete']"
                        (click)="unRegister('BUS_RENTAL')"><i class="fa fa-fw fa-times"></i>
                        {{'general.unRegister' | translate }}</button>

                    <button class="btn btn-primary"
                        *ngIf="device.asset.properties.wasl.registerDateBusRental && permissions['Wasl_Inquire']"
                        (click)="inquire('BUS_RENTAL')"><i class="fa fa-fw fa-broadcast-tower"></i>
                        {{'general.inquire' | translate }}</button>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-link"></i> {{'general.educationalTransportStatus' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">{{ 'general.connected' | translate}}</td>
                                <td class="row_content">{{
                                    (device.asset.properties.wasl.registerDateEducationalTransport
                                    ?
                                    'general.true' : 'general.false')|
                                    translate}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">{{ 'general.date' | translate}}</td>
                                <td class="row_content">{{ device.asset.properties.wasl.registerDateEducationalTransport
                                    | amDateFormat:
                                    'LLL'}}</td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.reference' | translate}}
                                </td>
                                <td class="row_content">
                                    {{ device.asset.properties.wasl.referenceKey }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [disabled]="waslForm.invalid" *ngIf="permissions['Wasl_Add']"
                        (click)="register('EDUCATIONAL_TRANSPORT')"><i class="fa fa-fw fa-edit"></i>
                        {{'general.register' | translate }}</button>

                    <button class="btn btn-danger"
                        *ngIf="device.asset.properties.wasl.registerDateEducationalTransport && permissions['Wasl_Delete']"
                        (click)="unRegister('EDUCATIONAL_TRANSPORT')"><i class="fa fa-fw fa-times"></i>
                        {{'general.unRegister' | translate }}</button>

                    <button class="btn btn-primary"
                        *ngIf="device.asset.properties.wasl.registerDateEducationalTransport && permissions['Wasl_Inquire']"
                        (click)="inquire('EDUCATIONAL_TRANSPORT')"><i class="fa fa-fw fa-broadcast-tower"></i>
                        {{'general.inquire' | translate }}</button>
                </div>
            </div>
        </div>

        <div class="col-lg-6">

            <form #waslForm="ngForm">
                <div *ngIf="!loading && waslForm.invalid" class="alert alert-warning shadow">
                    <p *ngIf="waslForm.invalid">{{'general.waslFormInvalid' | translate }}</p>
                </div>

                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-link"></i> {{'general.properties' | translate }}
                        </h5>
                    </div>
                    <ngx-loading [show]="loading"></ngx-loading>
                    <fh-wasl-asset-details [asset]="device.asset" [formMode]="formMode">
                    </fh-wasl-asset-details>
                    <fh-save-footer [valid]="!waslForm.invalid" (onSave)="onSave()" [allowSave]=false
                        [allowEdit]="permissions['Wasl_Edit']" (setFormMode)="setFormMode($event)"
                        [formMode]="formMode"></fh-save-footer>
                </div>
            </form>
        </div>
    </div>
</div>