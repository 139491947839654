import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { getDefaultDpConfig, getGridLanguages, createSearch, getGridButtons, createdCellBackgroundColor } from 'app/common/gridhelper';
import { Asset } from 'app/models/asset.model';
import { Device } from 'app/models/device.model';
import { AssetService } from 'app/services/asset/asset.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DeviceService } from 'app/services/device/device.service';
import { TripService } from 'app/services/trip/trip.service';

import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { numberFormat } from 'highcharts';
import { LocationService } from 'app/services/locations/locations.service';
import { timer } from 'rxjs/internal/observable/timer';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { LocationTypes, LocationEventType, sources } from 'app/common/enums';
import * as L from 'leaflet';

import { GridBase360Directive } from 'app/common/360Grid.base';
import { getIconPath, roundAsNumber, roundAsString } from 'app/common/globals';
import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';

import { colorMapper } from 'app/common/leafletGlobals';

// Moment timezone
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { AccountService } from 'app/services/account/account.service';
import { StorageHelper } from 'app/common/storagehelper';
import { forkJoin } from 'rxjs';
import { DistanceUnitService } from 'app/common/distanceunit.service';
import { ColorService } from 'app/services/common/color.service';

window['moment'] = Moment;
mTZ()

@Component({
    selector: 'fh-devices-messages',
    templateUrl: 'messages.template.html'
})
export class DeviceMessageViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
    @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

    loading: boolean;
    asset: any;
    sub: any;
    messages: any;

    theMarker;
    circleMarker;

    source = sources.Messages.toString();
    sources: { id: string; name: any; }[];

    excludingColumns = ['timestamp', 'latency', 'gpsBasedTimestamp', 'rtcBasedTimestamp', 'serverTimestamp', 'heading', 'location', 'messageType'];

    // Datepicker
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    to: any;
    from: any;
    daterangepickerModel: any[];
    permissions: {};
    languageLoaded: boolean;

    maxDate = new Date();

    constructorName = 'DeviceMessageViewComponent';
    permissionName = 'FleetManagement_Messages';

    device: Device;
    deviceId;
    timezoneIana: string;
    token: string;

    error: any;
    success: any;
    limit = 1000;

    loadingLocations = false;
    previousLookupTimestamp;
    locationSubscription: any;
    loadingLocation: boolean;

    updatesActive = true;
    isLoaded = false;
    randomKey: number;

    geofences = [];
    loadingGeofences = false;

    constructor(private accountService: AccountService, private distance: DistanceUnitService, private locationService: LocationService, private deviceService: DeviceService, private translateService: TranslateService, private authenticationService: AuthenticationService, private tripService: TripService, private assetService: AssetService, private route: ActivatedRoute, private router: Router, protected storageHelper: StorageHelper, private colorService: ColorService) {
        super(storageHelper);

        this.permissions = this.authenticationService.permissions;

        this.sources = Object.keys(sources)
            .filter(k => typeof sources[k] === 'string')
            .map(n => ({ id: n.toString(), name: sources[n] }));

        this.timezoneIana = authenticationService.getTimeZoneIana();
        this.token = authenticationService.getAuthToken();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(1, 'weeks').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).endOf('day').toDate()
        ];

        this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

        this.dpConfig = getDefaultDpConfig(moment, authenticationService);
    }

    ngOnInit() {
        this.device = new Device;
        this.device.id = ''

        this.loadingLocations = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.deviceId = id;
            // Get all the date for dropdown boxes
            forkJoin(
                this.translateService.get('general.date'),
                this.deviceService.getDeviceById(id)
            ).subscribe(([data, device]) => {
                this.languageLoaded = true;
                console.log('lang loaded');

                this.device = device;
                this.getGeofences();

                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }

                this.initGrid();
            }, error => {
                this.error = error;
                this.error.statusText = 'Error fetching device';

                setTimeout(() => {
                    this.router.navigate(['/Devices/Overview']);
                }, 3000);
            });

            // Check if new locations are added. then reload
            this.locationSubscription = timer(0, 30000).pipe(
                mergeMap(_ => this.locationService.getDeviceStates([+id], null, this.previousLookupTimestamp, 0)),
            ).subscribe(result => {
                this.loadingLocation = false;
                this.previousLookupTimestamp = new Date(result.timestamp);

                if (result?.deviceStates.length > 0 && this.updatesActive) {
                    this.addRows(result?.deviceStates);

                    // Frist time ignore reload
                    if (this.isLoaded === true) {

                        console.log('fetch new locations');
                        this.dateChanged('refresh');

                    } else {
                        this.isLoaded = true;
                    }
                }
            }, error => {
                this.error = error;
                if (this.locationSubscription !== undefined) {
                    this.locationSubscription.unsubscribe();
                }
                this.loadingLocation = false;
            });
        });
    }

    getGeofences() {
        if (this.geofences.length === 0 && this.device.accountId != null && this.device.accountId > 0) {
            this.loadingGeofences = true;
            this.accountService.getGeofencesByAccount(this.device.accountId).subscribe(geofences => {
                this.geofences = geofences;
                this.loadingGeofences = false;
            });
        }
    }

    addRows(result) {
        console.log('Adding row');
        this.datatableElement?.dtInstance?.then((dtInstance: DataTables.Api) => {
            // dtInstance.rows.add(result).draw();
        });
    }

    ngOnDestroy(): void {
        if (this.locationSubscription !== undefined) {
            this.locationSubscription.unsubscribe();
        }
        // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
        // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
        // handling this global variable
        $.fn['dataTable'].ext.search.pop();

        // Had to reset the array...
        $.fn['dataTable'].ext.search = [];
    }

    refresh() {
        this.source = sources.Messages.toString();
        this.dateChanged('refresh');
    }

    dateChanged(event) {

        // Default to end of day
        var date = new Date(this.daterangepickerModel[1].getTime());

        if (date.getHours() == 0 && date.getMinutes() == 0 && date.getSeconds() == 0) {
            date.setHours(23);
            date.setMinutes(59);
            date.setSeconds(59);

            this.daterangepickerModel = [
                this.daterangepickerModel[0]
                , date];
        }

        const that = this;
        console.log('Changed date');
        if (event !== null) {
            this.loadingLocations = true;

            this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

                if (event === 'refresh') {
                    if (dtInstance.table(0).page.info().page !== 0) {
                        console.log('Returning due to not being on the first page');
                        this.loadingLocations = false
                        return;
                    } else {
                        this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;
                    }
                }

                dtInstance.ajax.url(that.tripService.getMessageUrl(this.device.id, this.limit, this.source, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.randomKey))
                    .load(() => this.loadingLocations = false);
            });
        }
    }

    initGrid(): void {
        const that = this;

        const commonExportOptions = {
            modifier: {
                page: 'all',
                search: 'none'
            },
            columns: ['id_export:name', ':visible[tabindex]']
        };

        const inputTranslations = [
            this.translateService.instant('general.input1'),
            this.translateService.instant('general.input2'),
            this.translateService.instant('general.input3'),
            this.translateService.instant('general.input4'),
            this.translateService.instant('general.input5'),
            this.translateService.instant('general.input6'),
        ]

        if (this.device.settings?.ignition == 32768) {
            inputTranslations.push(this.translateService.instant('general.ignition'));
        } else {
            inputTranslations.push(this.translateService.instant('general.ignition') + ' (' + this.translateService.instant('general.port') + ');' + this.translateService.instant('enums.locationEventType.' + this.device.settings?.ignition));
        }

        if (this.device.settings?.externalPower == 2199023255552) {
            inputTranslations.push(this.translateService.instant('general.externalPower'));
        } else {
            inputTranslations.push(this.translateService.instant('general.externalPower') + ' (' + this.translateService.instant('general.port') + ');' + this.translateService.instant('enums.locationEventType.' + this.device.settings?.externalPower));
        }

        if (Object.prototype.toString.call(this.device.settings.inputPorts) === '[object Array]') {
            for (let index = 0; index < this.device.settings.inputPorts.length; index++) {
                if (this.device.settings.inputPorts[index].byte > 0) {
                    inputTranslations[index] += ('; ' + this.translateService.instant('enums.locationEventType.' + this.device.settings.inputPorts[index].byte));
                }
            }
        }
        const outputTranslations = [
            this.translateService.instant('general.output1'),
            this.translateService.instant('general.output2'),
            this.translateService.instant('general.output3'),
            this.translateService.instant('general.output4'),
        ]

        if (Object.prototype.toString.call(this.device.settings.outputPorts) === '[object Array]') {
            for (let index = 0; index < this.device.settings.outputPorts.length; index++) {
                if (this.device.settings.outputPorts[index].byte > 0) {
                    outputTranslations[index] += ('; ' + this.translateService.instant('enums.deviceOutput.' + this.device.settings.outputPorts[index].byte));
                }
            }
        }

        const eventTypes = [];
        Object.values(LocationEventType).filter(key => LocationEventType[key] > 0).forEach(function (item, index) {
            eventTypes.push({ id: LocationEventType[item].toString(), value: item.toString() });
        });

        const locationTypes = [];
        Object.values(LocationTypes).filter(key => LocationTypes[key] > 0).forEach(function (item, index) {
            locationTypes.push({ id: item.toString(), value: LocationTypes[item].toString() });
        });

        this.columns = [{
            name: 'id_export',
            data: 'id',
            className: 'noVis',
            title: this.translateService.instant('general.id'),
            visible: false,
        },
        {
            name: 'timestamp',
            data: 'timestamp',
            width: 130,
            defaultContent: '-',
            title: this.translateService.instant('general.timestamp'),
            type: 'date',
            render: function (data, type, row) {
                const date = Moment.utc(data)['tz'](that.timezoneIana);
                return data != null ? '<span title=" ' + date.toLocaleString() + '">' + date.format('YYYY-MM-DD HH:mm:ss') + '</span>' : '';
            },
        },
        {
            name: 'gpsBasedTimestamp',
            data: 'gpsBasedTimestamp',
            width: 130,
            title: this.translateService.instant('general.gpsBasedTimestamp'),
            visible: false,
            defaultContent: '-',
            render: function (data, type, row) {
                if (data && data !== '') {
                    const date = Moment.utc(data)['tz'](that.timezoneIana);
                    return data != null ? '<span title=" ' + date.toLocaleString() + '">' + date.format('YYYY-MM-DD HH:mm:ss') + '</span>' : '';
                } else {
                    return '-'
                }
            },
        },
        {
            name: 'rtcBasedTimestamp',
            data: 'rtcBasedTimestamp',
            width: 130,
            title: this.translateService.instant('general.rtcBasedTimestamp'),
            visible: false,
            render: function (data, type, row) {
                const date = Moment.utc(data);
                return data != null ? '<span title=" ' + date.toLocaleString() + '">' + date.format('YYYY-MM-DD HH:mm:ss') + '</span>' : '';
            },
        },
        {
            name: 'serverTimestamp',
            data: 'serverTimestamp',
            width: 130,
            defaultContent: '-',
            title: this.translateService.instant('general.serverTimestamp'),
            visible: false,
            render: function (data, type, row) {
                const date = Moment.utc(data)['tz'](that.timezoneIana);
                return data != null ? '<span title=" ' + date.toLocaleString() + '">' + date.format('YYYY-MM-DD HH:mm:ss') + '</span>' : '';
            },
        },
        {
            name: 'latency',
            data: 'serverTimestamp',
            width: 130,
            defaultContent: '-',
            title: this.translateService.instant('general.latencyInSeconds'),
            sortable: false,
            orderable: false,
            searchable: false,
            visible: false,
            render: function (data, type, row) {
                if (row.timestamp && row.serverTimestamp) {
                    const latency = new Date(row.serverTimestamp).getTime() - new Date(row.timestamp).getTime();

                    if (row.outOfSequence && row.outOfSequence !== 0) {
                        return latency ? '<i title="Out of sequence by ' + row.outOfSequence + ' messages" class="fa-regular fa-rotate-exclamation"></i> ' + roundAsString((latency / 1000), 0) : '-';
                    }

                    return latency ? roundAsString((latency / 1000), 0) : '-';
                }
            },
        },
        {
            name: 'eventType',
            data: 'eventType',
            type: 'select',
            defaultContent: '-',
            options: eventTypes.sort((a, b) => a.value.localeCompare(b.value)),
            title: this.translateService.instant('general.eventType'),
            render: function (data, type, row) {
                return that.translateService.instant(('enums.locationEventType.' + data));
            },
        },
        {
            name: 'ignition',
            data: 'ignition',
            defaultContent: '',
            type: 'checkBox',
            createdCell: createdCellBackgroundColor,
            width: 70,
            title: inputTranslations[6],
        },
        {
            name: 'externalPower',
            data: 'externalPower',
            defaultContent: '',
            type: 'checkBox',
            createdCell: createdCellBackgroundColor,
            width: 70,
            title: inputTranslations[7],
        },
        {
            name: 'satellites',
            data: 'satellites',
            type: 'num',
            defaultContent: '-',
            visible: false,
            title: this.translateService.instant('general.satellites'),
            render: function (data, type, row) {
                return data ?? '-';
            },
        },
        {
            name: 'hasGpsFix',
            data: 'hasGpsFix',
            type: 'checkBox',
            title: this.translateService.instant('general.hasGpsFix'),
            defaultContent: '',
            createdCell: createdCellBackgroundColor,
            width: 70,
        },
        {
            name: 'speed',
            data: 'speedInKph',
            defaultContent: '-',
            type: 'num',
            title: this.translateService.instant('general.speed'),
            render: function (data, type, row) {
                var translatedKm = that.translateService.instant(that.distance.getDistanceUnitPerHour());
                return data != null ? roundAsString(that.distance.calculateDistanceUnitFromKmFixed(data, 0), 0) + ' ' + translatedKm : '-';
            },
        },
        {
            name: 'messageType',
            data: 'locationType',
            defaultContent: '-',
            type: 'select',
            visible: false,
            options: locationTypes.sort((a, b) => a.value.localeCompare(b.value)),
            title: this.translateService.instant('general.messageType'),
            render: function (data, type, row) {

                let totalString = '';
                let locationString = '';
                let showUnderline = false;

                let p = 1;
                for (let i: any = 1; i <= 32; i++) {
                    // tslint:disable-next-line:no-bitwise
                    const isMatch = ((data & p) > 0);
                    if (isMatch) {
                        if (p > 2) {
                            showUnderline = true;
                        }
                        if (totalString !== '') { totalString += ', '; }
                        totalString += that.translateService.instant(('enums.locationType.' + p));
                    }

                    p = p * 2;
                }

                // tslint:disable-next-line:no-bitwise
                const cell = ((data & 1) > 0);

                // tslint:disable-next-line:no-bitwise
                const gps = ((data & 2) > 0);

                // tslint:disable-next-line:no-bitwise
                const lora = ((data & 1048576) > 0);

                if (cell) {
                    locationString = that.translateService.instant(('enums.locationType.' + 1));
                }

                if (gps) {
                    locationString = that.translateService.instant(('enums.locationType.' + 2));
                }

                if (lora) {
                    locationString = that.translateService.instant(('enums.locationType.' + 1048576));
                }

                return '<span title="' + totalString + '" style="' + (showUnderline ? 'text-decoration: underline' : '') + '">' + locationString + ' ' + (showUnderline ? '+' : '') + '</span>';
            },
        },
        {
            name: 'heading',
            data: 'heading',
            defaultContent: '-',
            title: this.translateService.instant('general.heading'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) + '°' : '-';
            },
        },
        {
            name: 'input1',
            data: 'input1',
            visible: false,
            defaultContent: '',
            type: 'checkBox',
            createdCell: createdCellBackgroundColor,
            width: 70,
            title: inputTranslations[0],
        },
        {
            name: 'input2',
            data: 'input2',
            visible: false,
            defaultContent: '',
            type: 'checkBox',
            createdCell: createdCellBackgroundColor,
            width: 70,
            title: inputTranslations[1],
        },
        {
            name: 'input3',
            data: 'input3',
            visible: false,
            defaultContent: '',
            type: 'checkBox',
            createdCell: createdCellBackgroundColor,
            width: 70,
            title: inputTranslations[2],
        },
        {
            name: 'input4',
            data: 'input4',
            visible: false,
            defaultContent: '',
            type: 'checkBox',
            createdCell: createdCellBackgroundColor,
            width: 70,
            title: inputTranslations[3],
        },
        {
            name: 'input5',
            data: 'input5',
            visible: false,
            defaultContent: '',
            type: 'checkBox',
            createdCell: createdCellBackgroundColor,
            width: 70,
            title: inputTranslations[4],
        },
        {
            name: 'input6',
            data: 'input6',
            visible: false,
            defaultContent: '',
            type: 'checkBox',
            createdCell: createdCellBackgroundColor,
            width: 70,
            title: inputTranslations[5],
        },
        {
            name: 'output1',
            data: 'output1',
            visible: false,
            defaultContent: '',
            type: 'checkBox',
            createdCell: createdCellBackgroundColor,
            width: 70,
            title: outputTranslations[0],
        },
        {
            name: 'output2',
            data: 'output2',
            visible: false,
            defaultContent: '',
            type: 'checkBox',
            createdCell: createdCellBackgroundColor,
            width: 70,
            title: outputTranslations[1],
        }, {
            name: 'output3',
            data: 'output3',
            visible: false,
            defaultContent: '',
            type: 'checkBox',
            createdCell: createdCellBackgroundColor,
            width: 70,
            title: outputTranslations[2],
        }, {
            name: 'output4',
            data: 'output4',
            visible: false,
            defaultContent: '',
            type: 'checkBox',
            createdCell: createdCellBackgroundColor,
            width: 70,
            title: outputTranslations[3],
        }, {
            name: 'analogInput1',
            data: 'analogInput1',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.analogInput1'),
            render: function (data, type, row) {
                return data != null ? (roundAsNumber(data, 2)).toLocaleString() + ' V' : '-';
            },
        }, {
            name: 'analogInput2',
            data: 'analogInput2',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.analogInput2'),
            render: function (data, type, row) {
                return data != null ? (roundAsNumber(data, 2)).toLocaleString() + ' V' : '-';
            },
        },
        {
            name: 'messageTag',
            data: 'messageTag',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.messageTag')
        },
        {
            name: 'latitude',
            data: 'latitude',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.latitude')
        },
        {
            name: 'longitude',
            data: 'longitude',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.longitude')
        },
        {
            name: 'odoValue',
            data: 'odoValue',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.odoValue'),
            render: function (data, type, row) {
                return (data != null) ? data.toLocaleString() : '-';
            },
        },
        {
            name: 'canBusOdoInMeters',
            data: 'canBusOdoInMeters',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.canBusOdoInMeters'),
            render: function (data, type, row) {
                return (data != null) ? data.toLocaleString() : '-';
            },
        },
        {
            name: 'altitude',
            data: 'altitude',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.altitude')
        },
        {
            name: 'hDoP',
            data: 'hDoP',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.hdop')
        },
        {
            name: 'vDoP',
            data: 'vDoP',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.vdop')
        },
        {
            name: 'pDoP',
            data: 'pDoP',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.pdop')
        },
        {
            name: 'cellId',
            data: 'cellId',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.cellId')
        },
        {
            name: 'lac',
            data: 'lac',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.lac')
        },
        {
            name: 'mnc',
            data: 'mnc',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.mnc')
        },
        {
            name: 'mcc',
            data: 'mcc',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.mcc')
        },
        {
            name: 'batteryLevel',
            data: 'batteryLevel',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.batteryLevel'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) + ' %' : '-';
            },
        },
        {
            name: 'externalBatteryLevelInVoltage',
            data: 'externalBatteryLevelInVoltage',
            visible: false,
            defaultContent: '-',
            type: 'num',
            title: this.translateService.instant('general.externalBatteryLevelInVoltage'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 2) + ' V' : '-';
            },
        },
        {
            name: 'rfid',
            data: 'rfid',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.rfid')
        }, {
            name: 'driverIdTag',
            data: 'driverIdTag',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.driverIdTag')
        }, {
            name: 'fuelLevel',
            data: 'fuelLevel',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.fuelLevel'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) + ' %' : '-';
            },
        }, {
            name: 'temperature',
            data: 'temperature',
            visible: false,
            type: 'num',
            defaultContent: '-',
            title: this.translateService.instant('general.temperature'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 1) + ' °C' : '-';
            },
        },
        {
            name: 'temperature2',
            data: 'temperature2',
            visible: false,
            type: 'num',
            defaultContent: '-',
            title: this.translateService.instant('general.temperature2'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 1) + ' °C' : '-';
            },
        },
        {
            name: 'temperature3',
            data: 'temperature3',
            visible: false,
            type: 'num',
            defaultContent: '-',
            title: this.translateService.instant('general.temperature3'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 1) + ' °C' : '-';
            },
        },
        {
            name: 'temperature4',
            data: 'temperature4',
            visible: false,
            type: 'num',
            defaultContent: '-',
            title: this.translateService.instant('general.temperature4'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 1) + ' °C' : '-';
            },
        },
        {
            name: 'fuelLevel1',
            data: 'fuelLevel1',
            visible: false,
            type: 'num',
            defaultContent: '-',
            title: this.translateService.instant('general.fuelLevel1'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) + ' %' : '-';
            },
        },
        {
            name: 'fuelLevel2',
            data: 'fuelLevel2',
            visible: false,
            type: 'num',
            defaultContent: '-',
            title: this.translateService.instant('general.fuelLevel2'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) + ' %' : '-';
            },
        },
        {
            name: 'fuelLevelRaw1',
            data: 'fuelLevelRaw1',
            visible: false,
            type: 'num',
            defaultContent: '-',
            title: this.translateService.instant('general.fuelLevelRaw1'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) : '-';
            },
        },
        {
            name: 'fuelLevelRaw2',
            data: 'fuelLevelRaw2',
            visible: false,
            type: 'num',
            defaultContent: '-',
            title: this.translateService.instant('general.fuelLevelRaw2'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) : '-';
            },
        },
        {
            name: 'fuelConsumed',
            data: 'fuelConsumed',
            visible: false,
            type: 'num',
            defaultContent: '-',
            title: this.translateService.instant('general.fuelConsumed'),
            render: function (data, type, row) {
                if (type && type === 'display') {
                    return data ? (roundAsNumber(data, 0)).toLocaleString() + ' L' : '-';
                } else {
                    return data;
                }
            },
        },
        {
            name: 'source',
            data: 'source',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.source')
        },
        {
            name: 'applicationId',
            data: 'applicationId',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.applicationId')
        },
        {
            name: 'ipAddress',
            data: 'ipAddress',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.ipAddress')
        },
        {
            name: 'rpm',
            data: 'rpm',
            visible: false,
            defaultContent: '-',
            type: 'num',
            title: this.translateService.instant('general.rpm')
        },
        {
            name: 'axleWeightInKg1',
            data: 'axleWeightInKg1',
            visible: false,
            type: 'num',
            defaultContent: '-',
            title: this.translateService.instant('general.axleWeightInKg') + ' ' + 1,
            render: function (data, type, row) {
                return data != null ? (roundAsNumber(data, 0)).toLocaleString() + ' kg' : '-';
            },
        },
        {
            name: 'axleWeightInKg2',
            data: 'axleWeightInKg2',
            visible: false,
            type: 'num',
            defaultContent: '-',
            title: this.translateService.instant('general.axleWeightInKg') + ' ' + 2,
            render: function (data, type, row) {
                return data != null ? (roundAsNumber(data, 0)).toLocaleString() + ' kg' : '-';
            },
        },
        {
            name: 'humidityInPercent1',
            data: 'humidityInPercent1',
            visible: false,
            type: 'num',
            defaultContent: '-',
            title: this.translateService.instant('general.humidityInPercent') + ' ' + 1,
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) + '%' : '-';
            },
        },
        {
            name: 'humidityInPercent2',
            data: 'humidityInPercent2',
            visible: false,
            type: 'num',
            defaultContent: '-',
            title: this.translateService.instant('general.humidityInPercent') + ' ' + 2,
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) + '%' : '-';
            },
        },
        {
            name: 'backupBatteryVoltage',
            data: 'backupBatteryVoltage',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.backupBatteryVoltage'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) + ' V' : '-';
            },
        },
        {
            name: 'bleBatteryVoltage1',
            data: 'bleBatteryVoltage1',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-bolt',
            visible: false,
            width: '40',
            title: this.translateService.instant('general.bleBatteryVoltage1'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) + ' %' : '-';
            },
        },
        {
            name: 'bleBatteryVoltage2',
            data: 'bleBatteryVoltage2',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-bolt',
            visible: false,
            width: '40',
            title: this.translateService.instant('general.bleBatteryVoltage2'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) + ' %' : '-';
            },
        },
        {
            name: 'bleBatteryVoltage3',
            data: 'bleBatteryVoltage3',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-bolt',
            visible: false,
            width: '40',
            title: this.translateService.instant('general.bleBatteryVoltage3'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) + ' %' : '-';
            },
        },
        {
            name: 'bleBatteryVoltage4',
            data: 'bleBatteryVoltage4',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-bolt',
            visible: false,
            width: '40',
            title: this.translateService.instant('general.bleBatteryVoltage4'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) + ' %' : '-';
            },
        },
        {
            name: 'radius',
            data: 'radiusInMeters',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-circle-dashed',
            visible: false,
            width: '40',
            title: this.translateService.instant('general.radiusInMeters'),
            render: function (data, type, row) {
                return data != null ? roundAsString(data, 0) + 'm' : '-';
            },
        }, {
            name: 'actualAccellerationForce',
            data: 'actualAccellerationForce',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-battery-half',
            title: this.translateService.instant('general.actualAccellerationForce'),
            visible: false,
            width: '40',
            render: function (data, type, row) {
                return data !== undefined ? (roundAsNumber(data, 0)).toLocaleString() + ' mg' : '-';
            },
        },
        {
            name: 'actualBrakingForce',
            data: 'actualBrakingForce',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-battery-half',
            title: this.translateService.instant('general.actualBrakingForce'),
            visible: false,
            width: '40',
            render: function (data, type, row) {
                return data !== undefined ? (roundAsNumber(data, 0)).toLocaleString() + ' mg' : '-';
            },
        },
        {
            name: 'actualCorneringForce',
            data: 'actualCorneringForce',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-battery-half',
            title: this.translateService.instant('general.actualCorneringForce'),
            visible: false,
            width: '40',
            render: function (data, type, row) {
                return data !== undefined ? (roundAsNumber(data, 0)).toLocaleString() + ' mg' : '-';
            },
        },
        {
            name: 'batteryChargeState',
            data: 'batteryChargeState',
            defaultContent: '-',
            type: 'bool',
            iconName: 'fas fa-fw fa-battery-half',
            title: this.translateService.instant('general.batteryChargeState'),
            visible: false,
            width: '40',
            render: function (data, type, row) {
                return data;
            },
        }, {
            name: 'batteryChargeLevelInPercentage',
            data: 'batteryChargeLevelInPercentage',
            defaultContent: '-',
            type: 'bool',
            iconName: 'fas fa-fw fa-battery-half',
            title: this.translateService.instant('general.batteryChargeLevelInPercentage'),
            visible: false,
            width: '40',
            render: function (data, type, row) {
                return data;
            },
        },
        {
            name: 'batteryPowerConsumptionInKWhPer100Km',
            data: 'batteryPowerConsumptionInKWhPer100Km',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-battery-half',
            title: this.translateService.instant('general.batteryPowerConsumptionInKWhPer100Km'),
            visible: false,
            width: '40',
            render: function (data, type, row) {
                return data !== undefined ? (roundAsNumber(data, 0)).toLocaleString() : '-';
            },
        }, {
            name: 'remainingDistanceInKm',
            data: 'remainingDistanceInKm',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-tachograph-digital',
            title: this.translateService.instant('general.remainingDistanceInKm'),
            visible: false,
            width: '40',
            render: function (data, type, row) {
                return data !== undefined ? (roundAsNumber(data, 0)).toLocaleString() + ' ' + that.translateService.instant(that.distance.getDistanceUnit()) : '-';
            },
        }, {
            name: 'angle1InDegrees',
            data: 'angle1InDegrees',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-angle',
            title: this.translateService.instant('general.angle1InDegrees'),
            visible: false,
            width: '40',
            render: function (data, type, row) {
                return data !== undefined ? (roundAsNumber(data, 0)).toLocaleString() + ' °' : '-';
            },
        }, {
            name: 'angle2InDegrees',
            data: 'angle2InDegrees',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-angle',
            title: this.translateService.instant('general.angle2InDegrees'),
            visible: false,
            width: '40',
            render: function (data, type, row) {
                return data !== undefined ? (roundAsNumber(data, 0)).toLocaleString() + ' °' : '-';
            },
        }, {
            name: 'angle3InDegrees',
            data: 'angle3InDegrees',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-angle',
            title: this.translateService.instant('general.angle3InDegrees'),
            visible: false,
            width: '40',
            render: function (data, type, row) {
                return data !== undefined ? (roundAsNumber(data, 0)).toLocaleString() + ' °' : '-';
            },
        }, {
            name: 'angle4InDegrees',
            data: 'angle4InDegrees',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-angle',
            title: this.translateService.instant('general.angle4InDegrees'),
            visible: false,
            width: '40',
            render: function (data, type, row) {
                return data !== undefined ? (roundAsNumber(data, 0)).toLocaleString() + ' °' : '-';
            },
        }, {
            name: 'engineCoolantTemperature',
            data: 'engineCoolantTemperature',
            defaultContent: '-',
            type: 'num',
            iconName: 'fas fa-fw fa-temperature-three-quarters',
            title: this.translateService.instant('general.engineCoolantTemperature'),
            visible: false,
            width: '40',
            render: function (data, type, row) {
                return data !== undefined ? (roundAsNumber(data, 0)).toLocaleString() + ' °C' : '-';
            },
        }, {
            name: 'location',
            data: 'latitude',
            defaultContent: '',
            title: this.translateService.instant('general.location'),
            render: function (data, type, row) {
                if (row.latitude != null && row.longitude != null) {
                    return '<a class="secondary link_bolder" target="_blank" href="http://maps.google.com/?q=' + row.latitude + ',' + row.longitude + '"><i class="fa fa-globe"></i> ' + row.latitude.toLocaleString('en-US', {
                        minimumFractionDigits: 4
                    }) + ',' + row.longitude.toLocaleString('en-US', {
                        minimumFractionDigits: 4
                    }) + '</a>';
                }
                return 'Unknown';
            },
        }, {
            name: 'analogInput3',
            data: 'analogInput3',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.analogInput3'),
            render: function (data, type, row) {
                return data != null ? (roundAsNumber(data, 2)).toLocaleString() + ' V' : '-';
            },
        }, {
            name: 'analogInput4',
            data: 'analogInput4',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.analogInput4'),
            render: function (data, type, row) {
                return data != null ? (roundAsNumber(data, 2)).toLocaleString() + ' V' : '-';
            },
        },
        {
            name: 'bleRpm1',
            data: 'bleRpm1',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.bleRpm1'),
            render: function (data, type, row) {
                return data != null ? data.toLocaleString() : '-';
            },
        }, {
            name: 'bleRpm2',
            data: 'bleRpm2',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.bleRpm2'),
            render: function (data, type, row) {
                return data != null ? data.toLocaleString() : '-';
            },
        }, {
            name: 'bleRpm3',
            data: 'bleRpm3',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.bleRpm3'),
            render: function (data, type, row) {
                return data != null ? data.toLocaleString() : '-';
            },
        }, {
            name: 'bleRpm4',
            data: 'bleRpm4',
            visible: false,
            defaultContent: '-',
            title: this.translateService.instant('general.bleRpm4'),
            render: function (data, type, row) {
                return data != null ? data.toLocaleString() : '-';
            },
        }
        ];

        this.dtOptions = {
            buttons: getGridButtons(this.commonExportOptions, 'messages_overview', this.translateService.instant('menu.messagesoverview'), this.colorService),
            pagingType: 'simple_numbers',
            serverSide: true,
            processing: true,
            // scrollY: 500,
            // scroller: {
            //     loadingIndicator: true
            // },
            searchDelay: 500,
            deferRender: true,
            scrollX: true,
            colReorder: { fixedColumnsLeft: 2 },
            deferLoading: 0,
            stateSave: true,
            stateSaveCallback: function (settings, data) {
                that.saveState(that.constructorName, data);
            },
            stateLoadCallback: function (_, callback) {
                (async () => {
                    try {
                        const columnSettings = await that.loadState(that.constructorName);
                        that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
                        return columnSettings;
                    } catch (e) {
                        that.error = {};
                        that.error.error = e;
                        that.error.statusText = 'Error fetching column settings';

                        return null;
                    }
                })().then(result => {
                    callback(result);
                });
            },
            order: [[1, 'desc']],
            ajax: {
                beforeSend: () => {
                    that.drawFilterRow();

                    $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
                },
                url: that.tripService.getMessageUrl(this.device.id, this.limit, this.source, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.randomKey),
                data: (d) => {
                    return d;
                },
                dataSrc: function (json) {
                    return json.data;
                },
                type: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + that.token
                }
            },
            initComplete: function (settings, json) {
                that.loading = false;
                that.loadingLocations = false;

                console.log('init complete');
                that.checkFilters();
                that.drawFilterRow();
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
            },
            columns: this.columns,
            pageLength: 200,
            lengthMenu: [[10, 17, 25, 50, 200, -1], [10, 17, 25, 50, 200, this.translateService.instant('general.all')]],
            language: getGridLanguages(this.translateService),
            rowCallback: (row, data) => {
                this.setMapInteraction(that, row, data);

                if (data.latitude != null && data.longitude != null) {
                    $(row).addClass('hand');
                }

                if (data.timestamp && data.serverTimestamp) {
                    const latency = new Date(data.serverTimestamp).getTime() - new Date(data.timestamp).getTime();

                    if ((latency / 1000) > 600) {
                        // More then 10 minutes old
                        $(row).addClass('messages_orange');
                    }
                    if ((latency / 1000) > 3600) {
                        // More then 60 minutes old
                        $(row).addClass('messages_red');
                    }
                }
            }
        };
    }

    setMapInteraction(table, row, data) {
        const theLatLon = data;
        const that = this;

        $(row).click(function () {
            that.showOnMap(data);
        });
    }

    showOnMap(data) {

        if (!data.latitude) {
            return;
        }

        const iconPath = getIconPath(this.device.asset?.icon)[1];

        // Find logical ignition
        let ignition = false;

        if (this.device.settings.ignition == '32768') {
            ignition = data.ignition;
        } else if (this.device.settings.externalPower == '32768') {
            ignition = data.externalPower;
        } else if (this.device.settings?.inputPorts[0]?.byte === '32768') {
            ignition = data.input1;
        } else if (this.device.settings?.inputPorts[1]?.byte === '32768') {
            ignition = data.input2;
        } else if (this.device.settings?.inputPorts[2]?.byte === '32768') {
            ignition = data.input3;
        } else if (this.device.settings?.inputPorts[3]?.byte === '32768') {
            ignition = data.input4;
        } else if (this.device.settings?.inputPorts[4]?.byte === '32768') {
            ignition = data.input5;
        } else if (this.device.settings?.inputPorts[5]?.byte === '32768') {
            ignition = data.input6;
        }

        if (!ignition) {
            data.deviceState = 2;
        } else {
            data.deviceState = 1;
        }

        const [markerIcon, heading] =
            (data.deviceState === 6 || data.hasGpsFix === false) ? ['fa-rss', 0] :
                (data.deviceState === 1 && data.heading > 0) ? ['fa-arrow-circle-up', data.heading] :
                    (data.deviceState === 2) ? ['fa-stop-circle', 0] :
                        (data.deviceState === 3) ? ['fa-pause-circle', 0] :
                            (data.deviceState === 4) ? ['fa-signal', 0] :
                                (data.deviceState === 5) ? ['fa-power-off', 0] :
                                    (data.deviceState === 0) ? ['fa-question-circle', 0] : ['fa-play-circle', 0];

        const theIcon = L['StatusMarker'].icon({
            iconUrl: iconPath,
            icon: markerIcon,
            markerColor: colorMapper(data.deviceState),
            rotate: heading,
            shape: 'circle',
            prefix: 'fas'
        });

        if (this.theMarker) {
            this.leafletMapComponent.map.removeLayer(this.theMarker);
        }

        if (this.circleMarker) {
            this.leafletMapComponent.map.removeLayer(this.circleMarker);
        }

        this.theMarker = L.marker([data.latitude, data.longitude], { icon: theIcon });
        this.theMarker.addTo(this.leafletMapComponent.radiusLayer);

        if (data.radiusInMeters) {
            this.circleMarker = L.circle([data.latitude, data.longitude],
                {
                    color: '#e100ff',
                    opacity: 0.4,
                    fillOpacity: 0.1,
                    dashArray: '10, 10',
                    radius: data.radiusInMeters
                }).addTo(this.leafletMapComponent.map);

            const markerBounds = this.circleMarker.getBounds();
            this.leafletMapComponent.map.fitBounds(markerBounds, { padding: [15, 15], maxZoom: 16, animate: true, duration: 0.5 });
        } else {

            this.leafletMapComponent.map.setView([data.latitude, data.longitude], 15, { animate: true, duration: 0.5 });
        }
    }
}


