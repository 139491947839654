import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './services/authentication/authentication.service';

/**
 * AuthGuard uses AuthenticationService to determine if a page can or cannot be shown, base uppon succesfull aquiring a WebserviceToken using the SSOToken cookie.
 */
@Injectable()
export class AuthGuardSharing implements CanActivate, CanActivateChild  {

  constructor(private authService: AuthenticationService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const auth = this.authService.IsAuthenticatedShareToken(state.url);
    return auth;
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const auth = this.authService.IsAuthenticatedShareToken(state.url);
    return auth;
  }
}
