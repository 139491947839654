<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Maintenances/Overview">{{'general.maintenance' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Maintenances/MaintenanceTypes/Overview">{{'general.maintenanceTypes' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.maintenanceTypeDetails' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-maintenance-navigation> </fh-maintenance-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">

                <form #userForm="ngForm">
                    <div class="ibox" style="position: relative;">
                        <ngx-loading [show]="loading || saving"></ngx-loading>
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-screwdriver-wrench"></i> {{'general.maintenanceType' | translate }}
                            </h5>
                        </div>
                        <fh-maintenance-type-details [maintenanceType]="maintenanceType" [formMode]="formMode">
                        </fh-maintenance-type-details>
                        <fh-save-footer [valid]="!userForm.invalid && hasSelectedTypes() === true" (onSave)="onSave()"
                            (onDelete)="onDelete()" [allowEdit]="permissions['MaintenanceTypes_Edit']"
                            [allowDelete]="permissions['MaintenanceTypes_Delete']" (setFormMode)="setFormMode($event)"
                            [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">
                <form #userForm="ngForm">
                    <fh-asset-type-selector [assetTypes]="assetTypes" [formMode]="formMode" [loading]="loading">
                    </fh-asset-type-selector>
                </form>

                <fh-tagging [objectId]="maintenanceType?.id" [objectType]="tagType">
                </fh-tagging>

            </div>
        </div>
    </div>
</fh-permission-handler>