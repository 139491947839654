<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/">{{'general.notifications' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/Triggers/Overview">{{'general.triggers' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/TriggerDetails/Index/{{trigger?.id}}">{{trigger?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.episodes' | translate }}</li>
            </ol>
        </nav>

        <fh-filter-toggler style="margin-left: auto;" [constructorName]="constructorName"
            (filterChanged)="showFilter = $event" (presetsChanged)="showPresets = $event">
            <a style="margin-left:2px" class="btn btn-info margin-fix d-none d-md-inline-flex" title="Help"
                href="https://help.360locate.com/knowledge-base/triggers/" target="_blank"><i class="fa fa-fa fa-book"
                    style="margin-right: 6px"></i> <span class="d-none d-md-inline-flex">{{'general.needHelp' | translate
                    }}</span></a>
        </fh-filter-toggler>
    </div>
    <fh-notifications-navigation></fh-notifications-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="">

            <div
                [ngClass]="{'col-lg-12': !showPresets, 'col-lg-9 col-md-8': showPresets && !showWiderMap, 'col-lg-6': showPresets && showWiderMap}">

                <fh-error [error]="error" [success]="success"></fh-error>

                <!-- Custom filters -->
                <div class="ibox" [hidden]="!showFilter">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                        <span class="float-right hand btn btn-info filter-margin-fix" (click)="clearAllFilters();">
                            <i class="fa fa-fw fa-times"></i>
                            <span class="d-none d-md-inline-flex"> {{'general.clearFilters' | translate }}</span>
                        </span>
                    </div>
                    <div class="ibox-content" style="position: relative;">
                        <ngx-loading [show]="episodesLoaded"></ngx-loading>
                        <div class="col-lg-12">
                            <div class="form-group input-group" style="margin-bottom: 5px;">
                                <span class="input-group-prepend">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <input [(ngModel)]="daterangepickerModel" (onHidden)="dateChanged('hidden')" name="from"
                                    type="text" autocomplete="off" class="form-control" [maxDate]="maxDate"
                                    [bsConfig]="dpConfig" bsDaterangepicker>
                                <span class="input-group-calendar-datatables">
                                    <i class="fa fa-calendar-alt"></i>
                                </span>
                            </div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                    <div class="ibox-content" style="position: relative;">
                        <ngx-loading [show]="episodesLoaded"></ngx-loading>
                        <div id="dynamicFilters" class="flex-container"></div>
                        <div style="clear:both"></div>
                    </div>
                </div>
                <!-- End default dynamic filters -->

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fa fa-traffic-light-stop"></i> {{ 'general.episodes' | translate }}</h5>
                        <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)"
                            [constructorName]="constructorName" (filterChanged)="showFilter = $event"
                            (presetLoaded)="onPresetLoaded($event)" (columnsChanged)="drawFilterRow()"></iboxseach>
                    </div>
                    <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                        <table style="width:100%; min-height:50px;" fh-datatable [dtOptions]="dtOptions"
                            [dtTrigger]="dtTrigger" class="table  nowrap margin-bottom-0 dataTable dtr-inline">
                        </table>
                    </div>
                </div>
            </div>
            <div class="sticky" [ngClass]="{'col-lg-6': showWiderMap, 'col-lg-3 col-md-4': !showWiderMap}"
                [hidden]="!showPresets">
                <fh-episode-details (onWideChanged)="onWideChanged()"
                    (onEpisodeDeleted)="episodeDeleted()"></fh-episode-details>
            </div>
        </div>
    </div>
</fh-permission-handler>