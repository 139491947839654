<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.profile' | translate}}</li>
            </ol>
        </nav>
    </div>
</div>

<div class="wrapper wrapper-content">

    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success" [copyText]="copyText" [autoRemove]="autoRemove"></fh-error>
        </div>
        <div class="col-lg-6">
            <form #userForm="ngForm" style="position: relative">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-user"></i> {{'general.profile' | translate}}
                        </h5>
                    </div>

                    <ngx-loading [show]="loading || saving"></ngx-loading>
                    <fh-user-details [user]="user" [formMode]="formMode" [profileMode]=true></fh-user-details>
                    <fh-save-footer [valid]="!userForm.invalid" [allowDelete]="false" (onSave)="onSave()"
                        (setFormMode)="setFormMode($event)" [formMode]="formMode" [showButtons]="true">
                        <span class="float-right">
                            <button class="btn btn-info" (click)="temporaryPassword()">
                                <i class="fa fa-fw fa-key"></i> {{'general.temporaryPassword' | translate }}</button>
                        </span>
                    </fh-save-footer>
                </div>
            </form>

            <div class="ibox" *ngIf="permissions['FleetManagement_Overview']">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-fw fa-cog"></i> {{ 'general.fosettings' | translate}}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table class="table table-fixed nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <tbody>
                            <tr *ngFor="let foSetting of foSettings | orderBy : 'name':false">
                                <td style="width: 50%">
                                    {{ ('general.' + foSetting.name) | translate}}
                                </td>
                                <td style="width: 50%">
                                    <div class="fa_checkbox fa_toggle">
                                        <input [checked]="true" [id]="'setting_' + foSetting.name" type="checkbox"
                                            [name]="'setting_' + foSetting.name" [disabled]="formMode == 1"
                                            [(ngModel)]="foSetting.value" />
                                        <label [for]="'setting_' + foSetting.name"></label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: middle;" class="row_opening">
                                    {{ ('general.sortingOrderFleetOverview') | translate}}
                                </td>
                                <td class="row_content" style="overflow: initial;">
                                    <ng-select [(ngModel)]="sortingOrderFleetOverview" class="form-control"
                                        [readonly]="formMode == 1" name="sortingOrderFleetOverview" required
                                        [clearable]="false" style="width:100%">
                                        @for (option of sortingOrderFleetOverviewOptions; track option.value;) {
                                        <ng-option [value]="option.value">{{'enums.sortingOrderFleetOverview.' +
                                            option.value | translate }}
                                        </ng-option>
                                        }
                                    </ng-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="ibox" *ngIf="permissions['Platform_IsReseller'] || isImpersonated">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-fw fa-cog"></i> {{ 'general.featureFlagSettings' | translate}}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table class="table table-fixed nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <tbody>
                            <tr *ngFor="let featureFlagSetting of featureFlagSettings | orderBy : 'name':false">
                                <td style="width: 50%">
                                    {{ ('general.' + featureFlagSetting.name) | translate}}
                                </td>
                                <td style="width: 50%">
                                    <div class="fa_checkbox fa_toggle">
                                        <input [checked]="true" [id]="'setting_' + featureFlagSetting.name"
                                            type="checkbox" [name]="'setting_' + featureFlagSetting.name"
                                            [disabled]="formMode == 1" [(ngModel)]="featureFlagSetting.value" />
                                        <label [for]="'setting_' + featureFlagSetting.name"></label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-fw fa-cog"></i> {{ 'general.settings' | translate}}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table class="table table-fixed nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <tbody>
                            <tr *ngFor="let userSetting of userSettings | orderBy : 'name':false">
                                <td class="row_opening">
                                    {{ ('general.' + userSetting.name) | translate}}
                                </td>
                                <td class="row_content">
                                    <div class="fa_checkbox fa_toggle">
                                        <input [checked]="true" [id]="'setting_' + userSetting.name" type="checkbox"
                                            [name]="'setting_' + userSetting.name" [disabled]="formMode == 1"
                                            [(ngModel)]="userSetting.value" />
                                        <label [for]="'setting_' + userSetting.name"></label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ ('general.mapSelectionOptions') | translate}}
                                </td>
                                <td class="row_content" style="overflow: initial;">
                                    <ng-select [(ngModel)]="mapSelectionOptions" class="form-control"
                                        [readonly]="formMode == 1" name="mapSelectionOptions" required
                                        [clearable]="false" style="width:100%">
                                        <ng-option [value]="0">{{'enums.mapSelectionOptions.0' | translate }}
                                        </ng-option>
                                        <ng-option [value]="1">{{'enums.mapSelectionOptions.1' | translate }}
                                        </ng-option>
                                        <ng-option [value]="2">{{'enums.mapSelectionOptions.2' | translate }}
                                        </ng-option>
                                        <ng-option [value]="3">{{'enums.mapSelectionOptions.3' | translate }}
                                        </ng-option>
                                    </ng-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-fw fa-router"></i> {{ 'general.changePassword' | translate}}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loadingPassword"></ngx-loading>
                    <form #passwordForm="ngForm" style="position: relative">
                        <table class="table table-fixed nowrap dtr-inline no-footer"
                            style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.oldPassword' | translate}} <span class="error"
                                            *ngIf="oldPasswordVar.invalid && formMode != 1">*</span>
                                    </td>
                                    <td class="row_content">
                                        <input name="oldPassword" type="text" type="password" [(ngModel)]="oldPassword"
                                            placeholder="{{ 'placeholder.oldPassword' | translate}}" required
                                            minlength="3" class="form-control table-inline" #oldPasswordVar="ngModel">
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.newPassword' | translate}} <span class="error"
                                            *ngIf="newPasswordVar.invalid && formMode != 1">*</span>
                                    </td>
                                    <td class="row_content">
                                        <fh-password-input name="password" autocomplete="off" [(ngModel)]="newPassword"
                                            required minlength="4"
                                            placeholder="{{ 'placeholder.newPassword' | translate}}"
                                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@!%?#.&])[\S]{8,}$"
                                            #newPasswordVar="ngModel" behaviour="click">
                                        </fh-password-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.confirmPassword' | translate}} <span class="error"
                                            *ngIf="confirmPasswordVar.invalid && formMode != 1">*</span>
                                    </td>
                                    <td class="row_content">
                                        <input name="confirmPassword" type="text" type="password"
                                            [(ngModel)]="confirmPassword" required minlength="3"
                                            placeholder="{{ 'placeholder.repeatPassword' | translate}}"
                                            class="form-control table-inline" #confirmPasswordVar="ngModel">
                                    </td>
                                </tr>
                                <tr class="alert alert-danger"
                                    *ngIf="(newPasswordVar.value?.length > 0 && confirmPasswordVar.value?.length > 0 && newPasswordVar.value !== confirmPasswordVar.value || (newPasswordVar?.invalid && (newPasswordVar?.dirty || newPasswordVar?.touched)))">
                                    <td colspan="2">
                                        <div *ngIf="newPasswordVar.value !== confirmPasswordVar.value">
                                            * Passwords do not match
                                        </div>
                                        <div *ngIf="newPasswordVar?.errors?.minlength">
                                            * Password must be at least 4 characters long.
                                        </div>
                                        <div *ngIf="newPasswordVar.errors?.pattern">
                                            * Requirements are not ok<br />
                                            (Eight characters, Uppercase letter, Lowercase letter, Special
                                            char, Number)
                                        </div>
                                        <div *ngIf="newPasswordVar?.errors?.required">
                                            * Password is required
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
                <div class="ibox-footer">
                    <!-- Button -->
                    <button id="submit" name="submit" class="btn btn-primary" (click)="savePassword()"
                        [disabled]="confirmPasswordVar.value !== newPasswordVar.value || passwordForm.invalid">
                        <i class="fas fa-fw fa-save"></i> {{ 'general.saveChanges' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>