import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { Subscription, filter } from 'rxjs';
import { Notification } from '../../models';
import { NotificationsService } from '../../services/notifications.service';



@Component({
    selector: 'fh-notify-notifications-container',
    templateUrl: './container.component.html',
    styleUrls: [
        './../../notifications.styles.css'
    ]
})
export class ContainerComponent implements OnInit, OnDestroy {
    protected sub: Subscription;
    protected routerSub: Subscription;

    public notifications: Notification[] = [];

    protected render(notification) {
        this.notifications.push(notification);
    }

    protected findSimilar(notification: Notification) {
        return this.notifications.find(existingNotification => {
            return existingNotification.message === notification.message
                && existingNotification.type === notification.type
                && existingNotification.header === notification.header
        });
    }

    constructor(protected notificationsService: NotificationsService,
        protected router: Router) {
    }

    ngOnInit() {
        this.sub = this.notificationsService.notifications
            .subscribe((n: Notification) => {
                if (this.findSimilar(n)) {
                    return;
                }

                this.render(n);
            });

        this.routerSub = this.router.events.pipe(
            filter(event => event instanceof NavigationStart))
            .subscribe((e) => {
                this.notifications = [];
            });
    }

    ngOnDestroy() {
        if (this.sub !== undefined) { this.sub.unsubscribe(); }
        if (this.routerSub !== undefined) { this.routerSub.unsubscribe(); }
    }

    onDismissNotify(n: Notification) {
        return this.notifications.splice(
            this.notifications.indexOf(n),
            1
        );
    }
}
