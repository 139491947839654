import {
    Component, AfterViewInit, Input, ElementRef, ViewChild, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, Inject
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StorageType } from 'app/common/enums';
import { StorageHelper } from 'app/common/storagehelper';
import { Reseller } from 'app/models/reseller.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { ResellerService } from 'app/services/resellers/resellers.service';

const noop = () => {
};

@Component({
    selector: 'fh-reseller-input',
    templateUrl: './resellerSelector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ResellerInputComponent }
    ]
})
export class ResellerInputComponent implements OnInit, AfterViewInit, ControlValueAccessor {

    @Input() placeholder: string;
    @Input() behaviour = 'press';
    @Input() autoSelect = true;
    @Input() clearable = true;
    @Input() readonly = false;
    @Input() tableInline;
    @Input() disable = false;
    @Output() onChanged = new EventEmitter();
    storageType = StorageType.LocalStorage;

    loadingResellers = false;
    resellers: Reseller[] = [];

    @ViewChild('input') el: ElementRef;

    private _value: string;
    private _onChange: (_: any) => void = noop;
    permissions: {};

    get value(): any {
        return this._value;
    };

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this._onChange(v);
            this.cd.markForCheck();
        }
    }

    constructor(private resellerService: ResellerService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private storageHelper: StorageHelper) {
        this.permissions = authenticationService.permissions;
    }

    resellerChanged() {
        this.onChanged.emit(this.value);

        this.storageHelper.saveStoreState(this.storageType, 'settings_', 'selectedReseller', this.value);

        this.cd.markForCheck();
    }

    ngOnInit() {
        this.loadingResellers = true;
        this.cd.markForCheck();

        this.resellerService.getResellers().subscribe(result => {
            this.resellers = result;
            let userReseller = null;

            if (!this.value && this.autoSelect === true && !this.readonly) {
                // Autoset when you dont have permissions

                if (!this.permissions['Resellers_View']) {
                    console.log('Forcing own reseller when no account permission is set');

                    userReseller = this.authenticationService.getResellerId();
                    setTimeout(() => {
                        this.value = +userReseller;
                        this.cd.markForCheck();
                        this.resellerChanged();
                    }, 0);

                    this.loadingResellers = false;
                    this.cd.markForCheck();
                    return;
                }

                if (this.resellers.length === 1) {
                    console.log('Setting reseller: ' + this.resellers[0].resellerDescription);
                    setTimeout(() => {
                        this.value = this.resellers[0].id;
                        this.cd.markForCheck();
                        this.resellerChanged();
                    }, 0);
                } else {
                    // Set to own reseller
                    this.storageHelper.loadStoreState(this.storageType, 'settings_', 'selectedReseller').subscribe((stateReseller) => {

                        if (stateReseller && this.resellers.indexOf(stateReseller)) {
                            userReseller = stateReseller;
                        } else {
                            userReseller = this.authenticationService.getResellerId();
                        }

                        if (userReseller != null) {
                            setTimeout(() => {
                                this.value = +userReseller;
                                this.cd.markForCheck();
                                this.resellerChanged();
                            }, 0);
                        }
                    });
                }
            }

            this.loadingResellers = false;
            this.cd.markForCheck();
        });
    }

    ngAfterViewInit() {
        this.cd.markForCheck();
    }

    writeValue(value: any) {
        this._value = value;
        this.cd.markForCheck();
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
        this.cd.markForCheck();
    }

    registerOnTouched(fn: any) {
        this.cd.markForCheck();
    }

}
