import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { roundAsString, roundSeconds } from 'app/common/globals';
import { simplifyDouglasPeucker } from 'app/common/simplify';
import { icon, marker } from 'leaflet';
// Moment timezone
declare var L;
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { AuthenticationService } from '../authentication/authentication.service';
import { ColorService } from '../common/color.service';

window['moment'] = Moment;
mTZ();

// Highcharts
import * as Highcharts from 'highcharts';

import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import ExportData from 'highcharts/modules/export-data';
import HighchartsMore from 'highcharts/highcharts-more';

import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsBoostCanvas from 'highcharts/modules/boost-canvas';
import HighchartsStock from 'highcharts/modules/stock';
import { DistanceUnitService } from 'app/common/distanceunit.service';

Exporting(Highcharts);
OfflineExporting(Highcharts);
ExportData(Highcharts);
HighchartsMore(Highcharts);
HighchartsBoost(Highcharts);
HighchartsBoostCanvas(Highcharts);
HighchartsStock(Highcharts);

@Injectable()
export class FhChartService {
    timezoneIana: string;

    translatedKm: any = "km";
    translatedKmh: any = "km/h";

    constructor(private colors: ColorService, private distance: DistanceUnitService, private authenticationService: AuthenticationService, private translateService: TranslateService) {
        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.translateService.get('general.date').subscribe(
            data => {
                this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
                this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
            }
        );

        if (this.timezoneIana !== 'undefined') {
            Highcharts.setOptions({
                time: {
                    timezone: this.timezoneIana
                    // timezoneOffset: -4 * 60
                },
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                chart: {
                    style: {
                        fontFamily: 'IBMPlexSansArabic-Regular, Inter, Helvetica, Arial, sans-serif',
                        fontSize: '12px',
                        fontWeight: '400'
                    },
                },
                title: {
                    style: {
                        fontSize: '12px',
                    }
                },
                tooltip: {
                    style: {
                        fontSize: '11px',
                    },
                },
                yAxis: {
                    labels: {
                        style: {
                            fontSize: '11px',
                        }
                    }
                },
                legend: {
                    itemStyle: {
                        fontSize: '11px',
                        color: 'hsla(0, 0%, 100%, .5)',
                    }
                },
                xAxis: {
                    labels: {
                        style: {
                            fontSize: '11px',
                            color: 'hsla(0, 0%, 100%, .5)',
                        }
                    }
                },
                exporting: {
                    sourceWidth: 900,
                    sourceHeight: 450,
                    scale: 2,
                    fallbackToExportServer: false
                },
                accessibility: {
                    enabled: false,
                },
            });
        }
    }

    countKeys(t) {
        switch (t?.constructor) {
            case Object:                                     // 1
                return Object
                    .values(t)
                    .reduce((r: any, v) => r + 1 + this.countKeys(v), 0)
            case Array:                                      // 2
                return t
                    .reduce((r, v) => r + this.countKeys(v), 0)
            default:                                         // 3
                return 0
        }
    }

    simplifyPathDouglasPecker(data, epsilon) {
        // CALL RDP Function
        const result = [];

        const beforeCount = this.countKeys(data);

        data.forEach(series => {
            const seriesData = simplifyDouglasPeucker(series.data, epsilon);
            series.data = seriesData;
            result.push(series);
        });

        const afterCount = this.countKeys(data);

        console.log(`Using simplifyPathDouglasPecker with epsilon ${epsilon} to format from '${beforeCount}' to '${afterCount}' (${roundAsString((afterCount / beforeCount) * 100, 1)}%)`);

        return result;
    }

    hideUndefinedForLegend(data: any[]) {
        return data.length > 1 || (data.length === 1 && data[0]?.name !== 'Undefined');
    }

    generateOdoChart(theData) {
        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: 'area'
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: ''
                },
                labels: {
                    rotation: -45
                }
            },
            yAxis: { // left y axis
                title: {
                    text: this.translateService.instant('chart.Odometer')
                },
                showFirstLabel: false,
                gridLineDashStyle: 'longdash'
            },
            legend: {
                align: 'center',
                verticalAlign: 'top'
            },

            tooltip: {
                shared: true,
                crosshairs: true
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    marker: {
                        lineWidth: 1
                    }
                }
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };

        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateColumnChart(theData, theDrilldownData = {}, xAxis = [], stacking = false, linkUrl = '/#/AccountDetails/Index/', linkName = 'clientData') {
        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: xAxis,
                title: {
                    text: null
                },
                labels: {
                    rotation: -45
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                },
                labels: {
                    overflow: 'justify'
                },
                gridLineDashStyle: 'longdash'
            },
            plotOptions: {
                column: {
                    borderRadius: 5,
                    stacking: stacking,
                    pointPadding: 0.2,
                    borderWidth: 0,
                    cursor: 'pointer',
                    events: {
                        click: function (event) {
                            const point = event.point as any;
                            location.href = linkUrl + point[linkName];
                        }
                    }
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: false
            },
            series: theData,
            drilldown: theDrilldownData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };

        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateUtilizationScoreChart(theData, xAxis = [], chartType = 'column', valueSuffix = null, useCustomFormatter = false) {
        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: chartType,
                panning: true,
                panKey: 'shift'
            },
            boost: {
                useGPUTranslations: true
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: xAxis,
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                endOnTick: false,
                title: {
                    text: ''
                },
                labels: {
                    overflow: 'justify'
                },
                gridLineDashStyle: 'longdash'
            },
            tooltip: {
                shared: false,
                valueSuffix: valueSuffix,
                formatter: function (tooltip) {
                    if (!useCustomFormatter) {
                        return tooltip.defaultFormatter.call(this, tooltip);
                    } else {
                        return `${this.x}<br/>` + this.translateService.instant('general.utilization') + `: <b>${this.y}%</b><br/>` + this.translateService.instant('general.hours') + `: <b>${this.point.hours}</b>`;
                    }
                },
            },
            plotOptions: {
                column: {
                    borderRadius: 5,
                    pointPadding: 0.2,
                    borderWidth: 0,
                    cursor: 'pointer',
                    events: {
                        click: function (event) {
                            const point = event.point as any;
                            if (point.deviceId) {
                                location.href = '/#/DeviceDetails/Index/' + point.deviceId;
                            }
                            if (point.driverId) {
                                location.href = '/#/DriverDetails/Index/' + point.driverId;
                            }
                        }
                    }
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: false
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateReportChart(theData, xAxis = [], chartType = 'column', enableLegend = false) {
        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: chartType,
                panning: true,
                panKey: 'shift'
            },
            boost: {
                useGPUTranslations: true
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: xAxis,
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                },
                labels: {
                    overflow: 'justify'
                },
                gridLineDashStyle: 'longdash'
            },
            plotOptions: {
                column: {
                    borderRadius: 5,
                    pointPadding: 0.2,
                    borderWidth: 0,
                    cursor: 'pointer',
                },
                pie: {
                    borderRadius: 5,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: '110%',
                    dataLabels: {
                        enabled: !enableLegend,
                        style: {
                            fontWeight: 'normal'
                        },
                        formatter: function () {
                            if (this.point.percentage > 2) {
                                return '<b>' + this.point.name + '</b>:' + Highcharts.numberFormat(this.point.percentage, 1) + '%';
                            }
                        }
                    },
                    events: {
                        click: function (event) {
                            const point = event.point as any;

                            if (point.deviceTypeId) {
                                location.href = '/#/Devices/DeviceTypeDetails/Index/' + point.deviceTypeId;
                            }

                            if (point.deviceId) {
                                location.href = '/#/DeviceDetails/Index/' + point.deviceId;
                            }
                        }
                    },
                    showInLegend: enableLegend,
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: enableLegend,
                align: 'center',
                verticalAlign: chartType === 'pie' ? 'bottom' : 'top'
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateReportChartDateTime(theData, chartType = 'column', enableLegend = true) {
        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: chartType,
                panning: true,
                panKey: 'shift'
            },
            boost: {
                useGPUTranslations: true
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: ''
                },
                labels: {
                    rotation: -45
                }
            },
            yAxis: [{
                title: {
                    text: ''
                },
                showEmpty: false,
                gridLineDashStyle: 'longdash'
            },
            {
                showEmpty: false,
                opposite: true,
                title: {
                    text: 'Temperature (C°)' // Temperature
                },
                labels: {
                    format: '{value}°C'
                },
                gridLineDashStyle: 'longdash'
            },
            {
                showEmpty: false,
                opposite: false,
                title: {
                    text: 'Percentage (%)' // Percent
                },
                labels: {
                    format: '{value}%'
                },
                min: 0,
                max: 100,
                gridLineDashStyle: 'longdash'
            }, {
                showEmpty: false,
                opposite: true,
                title: {
                    text: 'Weight (kg)' // Weight
                },
                labels: {
                    format: '{value} kg', // ton formatter
                },
                gridLineDashStyle: 'longdash'
            }, {
                showEmpty: false,
                min: 0,
                max: 20,
                categories: ['OFF', 'ON', ''],
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                title: {
                    text: 'Ignition'
                },
                visible: false,
                opposite: true,
                gridLineDashStyle: 'longdash'
            }, {
                showEmpty: false,
                min: -2,
                max: 18,
                categories: ['OFF', 'ON', ''],
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                title: {
                    text: 'Ignition'
                },
                visible: false,
                opposite: true,
                gridLineDashStyle: 'longdash'
            }, {
                showEmpty: false,
                opposite: false,
                title: {
                    text: 'Speed ' + this.translatedKmh // Speed
                },
                labels: {
                    format: '{value} ' + this.translatedKmh
                },
                min: 0,
            }, {
                showEmpty: false,
                opposite: false,
                title: {
                    text: 'Voltage (V)' // voltage
                },
                labels: {
                    format: '{value}V'
                },
                gridLineDashStyle: 'longdash',
                min: 0,
                max: 30,
            }],
            tooltip: {
                shared: true,
                valueSuffix: ''
            },
            plotOptions: {
                column: {
                    borderRadius: 5,
                    pointPadding: 0.2,
                    borderWidth: 0,
                    cursor: 'pointer',
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: enableLegend,
                align: 'center',
                verticalAlign: 'top'
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };

        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateColumnChartDates(theData, theDrilldownData = {}, xAxis = [], plotLines = null, stacking = undefined, enableLegend = null, valueSuffix = null, maxvalue = null, addTotal = false) {
        if (enableLegend === null) {
            enableLegend = this.hideUndefinedForLegend(theData);
        }

        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: 'area'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: ''
                },
                labels: {
                    rotation: -45
                },
                plotLines: plotLines
            },
            yAxis: [{
                min: 0,
                max: maxvalue,
                endOnTick: maxvalue === null ? false : true,
                title: {
                    text: ''
                },
                gridLineDashStyle: 'longdash'
            },
            {
                opposite: true,
                min: 0,
                max: maxvalue,
                title: {
                    text: ''
                },
                gridLineDashStyle: 'longdash'
            }],
            tooltip: {
                shared: true,
                valueSuffix: valueSuffix,
                xDateFormat: '%A, %d %B',
                footerFormat: addTotal ? ('● ' + this.translateService.instant('general.total') + ': <b>{point.total} ' + (valueSuffix ?? '') + '</b>') : '',
            },
            plotOptions: {
                area: {
                    stacking: stacking,
                },
                column: {
                    borderRadius: 5,
                    pointPadding: 0,
                    borderWidth: 0,
                    stacking: stacking,
                }
            },
            credits: { enabled: false },
            legend: {
                enabled: enableLegend,
                align: 'center',
                verticalAlign: 'top'
            },
            series: theData,
            drilldown: theDrilldownData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };

        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateMapChart(theData, plotLines = null, plotBands = null, map = null, theIcon, plotLinesXAxis = null, hideSidebar = false, showLegend = true) {
        let theMarker;

        const chart: Highcharts.Options = (<any>{
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x'
            },
            boost: {
                useGPUTranslations: true,
                debug: {
                    showSkipSummary: true,
                    timeBufferCopy: true,
                    timeKDTree: true,
                    timeRendering: true,
                    timeSeriesProcessing: true,
                    timeSetup: true
                }
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: ''
                },
                plotLines: plotLines,
                plotBands: plotBands
            },
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value} ' + this.translatedKmh
                },
                title: {
                    text: ''
                },
                opposite: true,
                visible: !hideSidebar,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 0) ?? null,
                gridLineDashStyle: 'longdash',
                tooltip: {
                    valueSuffix: ' ' + this.translatedKm
                }
            }, {
                labels: {
                    format: '{value} ' + this.translatedKm
                },
                title: {
                    text: ''
                },
                visible: !hideSidebar,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 1) ?? null,
                gridLineDashStyle: 'longdash',
            }, { // Secondary yAxis
                max: 100,
                title: {
                    text: ''
                },
                labels: {
                    format: '{value}%'
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 2) ?? null,
                gridLineDashStyle: 'longdash',
                tooltip: {
                    valueSuffix: '%'
                }
            }, {
                min: 0,
                max: 20,
                categories: ['OFF', 'ON', ''],
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                title: {
                    text: 'Ignition'
                },
                visible: false,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 3) ?? null,
                gridLineDashStyle: 'longdash'
            }, {
                min: -2,
                max: 18,
                categories: ['OFF', 'ON', ''],
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                title: {
                    text: 'External power'
                },
                visible: false,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 4) ?? null,
                gridLineDashStyle: 'longdash'
            }, {
                min: -4,
                max: 16,
                categories: ['OFF', 'ON', ''],
                labels: {
                    formatter: function () {
                        return this.value === 1 ? 'On' : 'Off';
                    }
                },
                title: {
                    text: 'Gps fix'
                },
                visible: false,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 5) ?? null,
                gridLineDashStyle: 'longdash'
            }, {
                max: 30,
                labels: {
                    format: '{value} V'
                },
                title: {
                    text: ''
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 6) ?? null,
                gridLineDashStyle: 'longdash',
                tooltip: {
                    valueSuffix: ' V'
                }
            }, {
                labels: {
                    format: '{value} °C'
                },
                title: {
                    text: ''
                },
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 7) ?? null,
                gridLineDashStyle: 'longdash',
                tooltip: {
                    valueSuffix: '°C'
                }
            }, {
                max: 100,
                labels: {
                    format: '{value} %'
                },
                title: {
                    text: ''
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 8) ?? null,
                gridLineDashStyle: 'longdash',
                tooltip: {
                    valueSuffix: '%'
                }
            }, {
                labels: {
                    format: '{value} kg',
                },
                title: {
                    text: ''
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 9) ?? null,
                gridLineDashStyle: 'longdash',
                tooltip: {
                    valueSuffix: 'kg'
                }
            },
            {
                labels: {
                    format: '{value} mg'
                },
                title: {
                    text: ''
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 10) ?? null,
                gridLineDashStyle: 'longdash',
                tooltip: {
                    valueSuffix: 'mg'
                }
            }, {
                title: {
                    text: ''
                },
                labels: {
                    format: '{value} %'
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 11) ?? null,
                gridLineDashStyle: 'longdash'
            }, {
                title: {
                    text: ''
                },
                labels: {
                    format: '{value} L'
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 12) ?? null,
                gridLineDashStyle: 'longdash',
                tooltip: {
                    valueSuffix: ' L'
                }
            }, {
                title: {
                    text: ''
                },
                labels: {
                    format: '{value} RPM'
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 13) ?? null,
                gridLineDashStyle: 'longdash',
                tooltip: {
                    valueSuffix: ' rpm'
                }
            }],
            tooltip: {
                borderRadius: 5,
                borderWidth: 1,
                borderColor: '#ccc',
                shadow: false,
                shared: true,
                useHTML: true,
                valueDecimals: 0,
                headerFormat:
                    '<table class="tip"><caption>{point.key}</caption>'
                    + '<tbody>',
                pointFormat:
                    '<tr><th style="color: {series.color}">{series.name} {point.name}: </th>'
                    + '<td style="text-align: right">{point.y} {point.suffix}</td></tr>',
                footerFormat:
                    '</tbody></table>'
            },
            legend: {
                enabled: showLegend,
                align: 'center',
                verticalAlign: 'top',
                floating: false,
                x: 0,
                y: 0,
                // backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
                // borderWidth: 1
            },

            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    turboThreshold: 0,
                    cursor: 'pointer',
                    point: {
                        events: {
                            mouseOver: function (evt) {
                                if (this.latlon) {
                                    if (theMarker) {
                                        map.removeLayer(theMarker);
                                    }
                                    theMarker = marker(this.latlon, { icon: theIcon }).addTo(map);

                                    const duration = (evt.target.category - evt.target.series.data[0].category);
                                    var zoom = map.getZoom();
                                    if (zoom < 15) {
                                        zoom = 15;
                                    }

                                    theMarker.bindTooltip(roundSeconds(duration / (1000), false) + ' / ' + evt.target.series.name + ' : ' + (Math.round(evt.target.options.y * 10) / 10) + (evt.target.options.suffix ? ' ' + evt.target.options.suffix : ''),
                                        {
                                            permanent: true,
                                            offset: L.point(0, 15),
                                            direction: 'bottom'
                                        });

                                    map.setView(this.latlon, zoom);
                                }
                            },
                            click: function () {
                                const seriesName = this.series.name;
                                return true;
                            },
                            mouseOut: function () {
                                if (theMarker) {
                                    map.removeLayer(theMarker);
                                }
                            }
                        }
                    }
                }
            },
            series: theData,
            colors: [
                this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D',
                this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282',
                this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE',
                this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE',
                this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE'
            ]
        });

        return chart;
    }


    generateSensorChart(theData) {

        const chart: Highcharts.Options = (<any>{
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: 'spline'
            },
            boost: {
                useGPUTranslations: true,
                debug: {
                    showSkipSummary: true,
                    timeBufferCopy: true,
                    timeKDTree: true,
                    timeRendering: true,
                    timeSeriesProcessing: true,
                    timeSetup: true
                }
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: ''
                },
            },
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value} °'
                },
                title: {
                    text: 'Temperature °C'
                },
                opposite: false
            }, { // Secondary yAxis
                min: 0,
                max: 100,
                title: {
                    text: 'Humidity %'
                },
                labels: {
                    format: '{value} %'
                },
                opposite: true
            }],
            tooltip: {
                borderRadius: 5,
                borderWidth: 1,
                borderColor: '#ccc',
                shadow: false,
                shared: true,
            },
            legend: {
                itemWidth: 150,
                symbolWidth: 40,
                align: 'center',
                verticalAlign: 'bottom',
                floating: false,
                x: 0,
                y: 10
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                }
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        });

        return chart;
    }


    generateFuelChart(theData, plotLines = null, plotBands = null, map = null, theIcon) {
        let theMarker;

        const chart: Highcharts.Options = (<any>{
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: ''
                },
                plotLines: plotLines,
                plotBands: plotBands
            },
            yAxis: [{
                min: 0,
                labels: {
                    format: '{value} ' + this.translatedKm
                },
                title: {
                    text: ''
                },
                tooltip: {
                    valueSuffix: ' km'
                },
                opposite: true
            }, {
                min: 0,
                labels: {
                    format: '{value} L'
                },
                title: {
                    text: ''
                },
                visible: false,
            }, {
                min: 0,
                max: 100,
                title: {
                    text: 'Fuel tank'
                },
                labels: {
                    format: '{value} %'
                },
                tooltip: {
                    valueSuffix: ' %'
                },
                visible: true,
                opposite: false
            }, {
                min: -2,
                max: 10,
                categories: ['OFF', 'ON', ''],
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                title: {
                    text: 'Speed'
                },
                visible: false,
                opposite: true
            }, {
                min: 0,
                max: 11,
                categories: ['OFF', 'ON', ''],
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                title: {
                    text: 'Ignition'
                },
                visible: false,
                opposite: true
            }, {
                min: 0,
                max: 10,
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                title: {
                    text: 'Events'
                },
                visible: false,
                opposite: true
            }, {
                title: {
                    text: 'RPM'
                },
                gridLineDashStyle: 'longdash',
                opposite: true
            },],
            tooltip: {
                borderRadius: 5,
                borderWidth: 1,
                borderColor: '#ccc',
                shadow: false,
                shared: true,
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                floating: false,
                x: 0,
                y: 10
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            mouseOver: function () {
                                if (this.latlon) {
                                    if (theMarker) {
                                        map.removeLayer(theMarker);
                                    }
                                    theMarker = marker(this.latlon, { icon: theIcon }).addTo(map);
                                    map.panTo(this.latlon);
                                }
                            },
                            click: function () {
                                const seriesName = this.series.name;
                                return true;
                            },
                            mouseOut: function () {
                                if (theMarker) {
                                    map.removeLayer(theMarker);
                                }
                            }
                        }
                    }
                }
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        });
        return chart;
    }

    generateColumnChartDevice(theData, theDrilldownData = {}, xAxis = [], enableLegend = false, yAxisName = '') {

        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: xAxis,
                title: {
                    text: null
                },
                labels: {
                    rotation: -45
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: yAxisName
                },
                labels: {
                    overflow: 'justify'
                }
            },
            plotOptions: {
                column: {
                    borderRadius: 5,
                    pointPadding: 0.2,
                    borderWidth: 0,
                    cursor: 'pointer',
                    events: {
                        click: function (event) {
                            const point = event.point as any;
                            location.href = '/#/DeviceDetails/Index/' + point.deviceId;
                        }
                    }
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: enableLegend,
                align: 'top',
                verticalAlign: 'top',
                floating: true,
                x: 0,
                y: 0
            },
            series: theData,
            drilldown: theDrilldownData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateTopUsageChartDevice(theData, theDrilldownData = {}, xAxis = [], enableLegend = false, yAxisName = '') {

        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: xAxis,
                title: {
                    text: null
                },
                labels: {
                    rotation: -45
                }
            },
            yAxis: {
                title: {
                    text: yAxisName
                },
                type: 'logarithmic',
                labels: {
                    overflow: 'justify'
                },
                plotLines: [{
                    value: 1000,
                    dashStyle: 'dash',
                    width: 1,
                    color: 'rgba(243, 156, 18, 1)',
                    label: {
                        align: 'right',
                        style: {
                            fontStyle: 'italic',
                            color: 'rgba(243, 156, 18, 1)'
                        },
                        text: 'Normal usage 1000/day',
                        x: -10
                    }
                },
                {
                    value: 1500,
                    dashStyle: 'dash',
                    width: 1,
                    color: 'rgba(217, 30, 24, 1)',
                    label: {
                        align: 'right',
                        style: {
                            fontStyle: 'italic',
                            color: 'rgba(217, 30, 24, 1)'
                        },
                        text: 'Excessive usage 1500/day',
                        x: -10
                    }
                }],
            },
            plotOptions: {
                column: {
                    borderRadius: 5,
                    pointPadding: 0.2,
                    borderWidth: 0,
                    cursor: 'pointer',
                    events: {
                        click: function (event) {
                            const point = event.point as any;
                            if (point.deviceId) {
                                location.href = '/#/DeviceDetails/Index/' + point.deviceId;
                            }
                        }
                    }
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: enableLegend,
                align: 'center',
                verticalAlign: 'top',
                floating: true,
                x: 0,
                y: 0
            },
            series: theData,
            drilldown: theDrilldownData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generatePieChartDevice(theData, theDrilldownData = {}, xAxis = [], enableLegend = false, yAxisName = '') {
        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: xAxis,
                title: {
                    text: null
                },
                labels: {
                    rotation: -45
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: yAxisName
                },
                labels: {
                    overflow: 'justify'
                }
            },
            plotOptions: {
                pie: {
                    borderRadius: 5,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: '110%',
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'normal'
                        },
                        formatter: function () {
                            if (this.point.percentage > 2) {
                                return '<b>' + this.point.name + '</b>:' + Highcharts.numberFormat(this.point.percentage, 1) + '%';
                            }
                        }
                    },
                    events: {
                        click: function (event) {
                            const point = event.point as any;
                            if (point.deviceTypeId) {
                                location.href = '/#/Devices/DeviceTypeDetails/Index/' + point.deviceTypeId;
                            }
                        }
                    },
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: enableLegend,
                align: 'center',
                verticalAlign: 'top',
                floating: true,
                x: 0,
                y: 0
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateVarPieChartDevice(theData, theDrilldownData = {}, xAxis = [], enableLegend = false, yAxisName = '') {
        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            credits: { enabled: false },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y:.,0f} ({point.percentage:.1f}%)</b>'
            },
            plotOptions: {
                pie: {
                    borderRadius: 5,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            legend: {
                enabled: enableLegend,
                useHTML: true,
                floating: true,
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                backgroundColor: 'rgba(255, 255, 255, 0.20)',
                itemMarginTop: 10,
                borderRadius: 5,
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }
}
