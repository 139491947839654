import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { Driver } from 'app/models/driver.model';
import { EntityType } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { WaslService } from 'app/services/wasl/wasl.service';

// Moment timezone
import { DriverService } from '../../services/driver/driver.service';
import { DriverGroupsService } from 'app/services/driver/driverGroups.service';
import { LocationService } from 'app/services/locations/locations.service';
import { ConfirmationModalComponent } from '../shared/usercontrols/confirmationModal.component';
import { DeviceStatesItem } from 'app/models/StateObject';

@Component({
    selector: 'fh-fh-driver-details',
    templateUrl: 'driverDetails.template.html'
})
export class DriverDetailsViewComponent implements OnInit, OnDestroy {
    @ViewChild('confirmModal', { static: false }) confirmModal: ConfirmationModalComponent;
    sub: any;
    driver: Driver = new Driver();
    device: any;
    tagType = EntityType.Driver.valueOf();
    formMode = FormMode.read;

    loading = false;
    saving = false;

    error: any;
    success: any;
    warning: any;

    selectedAction;

    permissions: {};
    permissionName = 'Drivers_View';
    geofences = [];

    loadingGroups = false;
    driverGroups = [];
    driverGroupLink = 'DriverGroupDetails';

    loadingLocation = false;
    loadingAssignments = false;
    allowDelete = true;

    previousLookupTimestamp: Date;
    driverAssignments = [];
    driverId: any;
    locationData: DeviceStatesItem;

    constructor(private router: Router, private cd: ChangeDetectorRef, private locationService: LocationService, private waslService: WaslService, private driverGroupService: DriverGroupsService, private authentication: AuthenticationService, private driverService: DriverService, private route: ActivatedRoute) {
        this.permissions = this.authentication.permissions;
    }

    ngOnDestroy(): void {
    }

    ngOnInit() {
        this.loading = true;
        this.loadingAssignments = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.driverId = id;

            this.driverService.getDriverById(id).subscribe(driver => {
                this.driver = driver;
                this.loading = false;
            }, error => {
                this.error = error;
                this.error.statusText = 'Error fetching driver';

                this.cd.markForCheck();

                setTimeout(() => {
                    this.router.navigate(['/Drivers/Overview']);
                }, 3000);
            });

            this.loadingGroups = true;
            this.driverGroupService.getDriverGroupItemsById(id, 0).subscribe(res => {
                this.driverGroups = res;
                this.loadingGroups = false;
            });

            this.driverService.getDriverAssignmentsById(id).subscribe(res => {
                this.driverAssignments = res.sort((a, b) => (a.dateStart.unix() > b.dateStart.unix() ? -1 : 1));
                this.loadingAssignments = false;
            });
        });
    }

    showModal(selectedAction) {
        this.selectedAction = selectedAction;
        this.confirmModal.showModal('');
    }

    hideModal() {
        this.confirmModal.hideModal();
    }

    finishTask(task) {
        if (task?.completed) {
            this.error = null;

            if (task.result?.isSuccess) {
                this.warning = null;
                this.success = {
                    statusText: 'Success',
                    success: task.result?.message,
                };
            } else {
                this.success = null;
                this.warning = {
                    statusText: 'Warning',
                    warning: task.result?.message,
                };
            }

            this.getDriverInfo();
            this.cd.markForCheck();
        } else {
            this.error = task?.error;
        }
    }

    // Form
    onDelete() {
        this.loading = true;

        this.driverService.deleteDriver(this.driver).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Driver is successfully deleted.'
            };

            setTimeout(() => {
                this.router.navigate(['/Drivers/Overview']);
            }, 3000);
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    onSave() {
        this.saving = true;

        this.driverService.updateDriver(this.driver).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Driver is successfully updated.'
            };

            this.saving = false;
            this.cd.markForCheck();

            this.driverService.resetCache().subscribe(res => {
                console.log('Drivers are cleared');
            });

            this.setFormMode(FormMode.read);
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getDriverInfo();
        }
    }

    getDriverInfo() {
        this.loading = true;
        this.loadingGroups = true;

        this.driverService.getDriverById(this.driver.id).subscribe(driver => {
            this.driver = driver;
            this.loading = false;
            this.cd.markForCheck();
        });

        this.driverGroupService.getDriverGroupItemsById(this.driver.id, 0).subscribe(res => {
            this.driverGroups = res;
            this.loadingGroups = false;
            this.cd.markForCheck();
        });
    }

    addToGroup(_, confirmationModal: ConfirmationModalComponent, driverComponent) {
        confirmationModal.hideModal();
        this.loadingGroups = true;

        const filteredDrivers = driverComponent.value.filter(x => x > -1);
        driverComponent.selector?.clearModel();
        if (filteredDrivers.length === 0) {
            this.getDriverInfo();
            return;
        }

        this.driverService.addToGroup(this.driver.id, filteredDrivers).subscribe(result => {
            this.loading = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Adding driver to groups was successful',
            };

            this.getDriverInfo();
        }, error => {
            this.loading = false;
            this.success = null;
            this.error = error;

            this.getDriverInfo();
        });
    }
}
