import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsBitmask, FormMode, EntityType, UserModalAction } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { AppUser, UpdatePasswordRequest } from '../../models/user.model';
import { UserService } from '../../services/users/user.service';
import { ConfirmationModalComponent } from '../shared/usercontrols/confirmationModal.component';
import { TranslateService } from '@ngx-translate/core';
import { sha256 } from 'js-sha256';
import { contains, generatePassword } from 'app/common/globals';
import { AccountService } from 'app/services/account/account.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'fh-user-details-view',
    templateUrl: 'userDetails.template.html'
})

export class UserDetailsViewComponent implements OnInit {
    @ViewChild('confirmModal', { static: false }) confirmModal: ConfirmationModalComponent;
    tagType = EntityType.User.valueOf();

    options;
    layersControl;
    selectedRow: any;
    selected: any;
    theTrips = [];
    timeoutHandler;
    chartFuel: any;
    sensors;
    sub;
    user: AppUser = new AppUser();
    loading = false;
    saving = false;
    loadingSensors = false;
    loadingKpi = false;

    theSaveBitmask;

    newUserPassword: string;
    userModalAction: UserModalAction;

    permissionBitmask: any;
    filteredPermissions: any;
    loadingRoles: boolean;
    isImpersonated = false;

    formMode: FormMode = FormMode.read;
    languages: { name: string; path: any; }[];

    activeUserId: string;
    error: any;
    success: any;
    autoRemove: any;
    copyText: any;

    activeDevices = 0;
    activeDeviceViewerCount = 0;
    activeDrivers = 0;
    activeGeofences = 0;

    currentPage;

    loadingAssetGroups = false;
    assetGroups = [];

    loadingViewerGroups = false;
    groupList = [];

    resetUserPassword = false;

    permissionName = 'Users_View';
    permissions: {};

    filter;
    selectedItems = [];

    assignMode = false;
    userId: any;

    get UserModalAction() {
        return UserModalAction;
    }

    constructor(private cd: ChangeDetectorRef, private accountService: AccountService, private route: ActivatedRoute, private router: Router, private userService: UserService, private authenticationService: AuthenticationService, private translateService: TranslateService) {
        this.user = null;
        this.sensors = [];
        this.theTrips = [];
    }

    ngOnInit() {
        this.permissions = this.authenticationService.permissions;
        this.isImpersonated = this.authenticationService.getIsImpersonated();

        this.user = new AppUser;
        this.loading = true;
        this.loadingKpi = true;
        this.loadingAssetGroups = true;
        this.cd.markForCheck();

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.userId = id;
            this.userService.getUserById(id).subscribe(user => {
                this.user = user;
                this.loading = false;
                this.loadingRoles = true;

                if (this.user == null) {
                    this.router.navigate(['/Users/Overview'])
                };

                this.fetchAssetGroupSharing();

                this.userService.getKPISCount(id).subscribe(kpiResults => {
                    this.loadingKpi = false;
                    this.activeDevices = kpiResults.deviceCount;
                    this.activeDeviceViewerCount = kpiResults.deviceViewerCount;
                    this.activeGeofences = kpiResults.geofenceCount;
                    this.activeDrivers = kpiResults.driverCount;
                    this.cd.markForCheck();
                });
            });
        });
    }

    checkedAllGroups() {
        return !!this.assetGroups?.every(x => x.isChecked === true);
    }

    selectAllGroups({ currentTarget }) {
        if (!this.assetGroups?.length) {
            return;
        }

        this.assetGroups.forEach(assetGroup => {
            if (assetGroup.userId === this.user.id) {
                return;
            }

            assetGroup.isChecked = currentTarget.checked;
        });
    }

    fetchAssetGroupSharing() {
        this.loadingViewerGroups = true;
        this.groupList = [];
        this.currentPage = 0;

        forkJoin([
            this.userService.getAssetGroupsbyViewerId(this.user.viewerId, true),
            this.accountService.getAccountSharedAssetGroups(this.user.accountId)
        ]).subscribe(([viewerGroups, assetGroups]) => {
            this.assetGroups = assetGroups;

            if (this.user.viewerId) {
                this.assetGroups.forEach(assetGroup => {

                    // Does not exist yet
                    if (assetGroup.viewers && assetGroup.viewers.length > 0 && contains(assetGroup.viewers, this.user.viewerId)) {
                        assetGroup.isChecked = true;
                        this.groupList.push(assetGroup);

                    } else if (assetGroup.userId === this.user.id) {

                        assetGroup.isChecked = true;
                        this.groupList.push(assetGroup);
                    }
                });
            }

            // Check if assetgroup already in set
            viewerGroups.filter(x => x.viewerPermission === true).forEach(group => {
                var linkedGroup = this.groupList.find(x => x.id == group.id);

                if (!linkedGroup) {
                    this.groupList.push(group);
                }
            });

            this.loadingAssetGroups = false;
            this.loadingViewerGroups = false;
            this.cd.markForCheck();
        });
    }

    saveAccountViewers() {
        this.loadingAssetGroups = true;

        const keys = this.assetGroups.filter(x => x.isChecked).map(x => x.id);

        this.userService.postAssetGroupsbyUserId({ id: this.user.viewerId, groups: keys }).subscribe(result => {
            this.success = 'Groups sharing has been updated';
            this.assignMode = !this.assignMode;
            this.loadingViewerGroups = true;
            this.cd.markForCheck();

                this.fetchAssetGroupSharing();
        }, error => {
            this.loadingAssetGroups = false;
            this.cd.markForCheck();
            this.error = error;
            this.getUserInfo();
        });
    }

    getUserInfo() {
        this.userService.getUserById(this.user.id).subscribe(user => {
            this.user = user;
            this.loading = false;
            this.loadingRoles = true;

            if (this.user == null) {
                this.router.navigate(['/Users/Overview'])
            }
        }, error => {
            this.success = null;
            this.error = error;
            this.error.statusText = 'Error fetching user';

            this.cd.markForCheck();

            setTimeout(() => {
                this.router.navigate(['/Users/Overview']);
            }, 3000);
        });
    }

    impersonate() {
        const user = this.userService.getUserById(this.user.id).subscribe(appUser => {
            this.userService.getTokenForUser(appUser.id).subscribe(result => {
                this.authenticationService.setImpersonationToken(result);
            });
        });
    }

    // form
    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.getUserInfo();
        }
    }

    resetPassword() {
        this.saving = true;
        this.autoRemove = true;
        this.success = undefined;
        this.error = undefined;
        this.copyText = undefined;

        this.userService.resetPassword(this.user.id).subscribe(result => {
            this.saving = false;
            this.cd.markForCheck();

            this.setFormMode(FormMode.read);

            if (result === true) {
                this.success = this.translateService.instant('login.passwordResetSucces');
            } else {
                this.error = this.translateService.instant('login.passwordResetFailed');
            }
        }, error => {
            this.saving = false;
            this.cd.markForCheck();
            this.error = error
            this.getUserInfo();
        });
    }

    temporaryPassword() {
        this.saving = true;
        this.autoRemove = false;
        this.success = undefined;
        this.error = undefined;
        this.copyText = undefined;

        this.userService.createTemporaryPassword(this.user.id).subscribe(result => {
            if (result.isSuccess) {
                this.success = `Your temporary password is; ` + result.message;
                this.copyText = result.message;
            } else {
                this.success = result.message;
            }
            this.saving = false;
            this.cd.markForCheck();
        }, error => {
            this.saving = false;
            this.cd.markForCheck();
            this.error = error;
            this.getUserInfo();
        });
    }

    newUserWelcome() {
        this.saving = true;
        this.autoRemove = false;
        this.success = undefined;
        this.error = undefined;
        this.copyText = undefined;

        this.userService.createNewUserWelcome(this.user.id).subscribe(result => {
            if (result.isSuccess) {
                this.success = `User has received an email`;
                this.copyText = result.message;
            } else {
                this.success = result.message;
            }
            this.saving = false;
            this.cd.markForCheck();
        }, error => {
            this.saving = false;
            this.cd.markForCheck();
            this.error = error;
            this.getUserInfo();
        });
    }

    changePassword() {
        this.newUserPassword = generatePassword(8);
        this.userModalAction = UserModalAction.UpdatePassword;
        this.confirmModal.showModal('');
    }

    processPasswordChanges(event) {
        if (this.resetUserPassword) {
            this.resetPassword();
            this.confirmModal.decline();
            return;
        }

        const request = new UpdatePasswordRequest();
        request.id = this.user.id;
        request.userName = this.user.name;

        const newPasswordValue = this.newUserPassword;
        request.newPassword = sha256(newPasswordValue);

        this.confirmModal.decline();
        this.saving = true;
        this.success = undefined;
        this.error = undefined;
        this.copyText = undefined;

        this.userService.updateUserPassword(this.user.id, request).subscribe(result => {
            if (result.isSuccess) {
                this.success = `Your new password is; ` + newPasswordValue;
                this.copyText = newPasswordValue;
            } else {
                this.success = result.message;
            }
            this.saving = false;
            this.cd.markForCheck();
        }, error => {
            this.saving = false;
            this.cd.markForCheck();
            this.error = error;
            this.getUserInfo();
        });
    }

    resetPasswordChangeModal(event) {
        this.newUserPassword = '';
    }

    onSave() {
        this.saving = true;
        this.autoRemove = true;
        this.success = undefined;
        this.error = undefined;
        this.copyText = undefined;
        this.cd.markForCheck();

        this.userService.updateUserProfile(this.user).subscribe(result => {
            this.saving = false;
            this.cd.markForCheck();

            if (result === true) {
                this.error = this.translateService.instant('general.errorUpdate');
            } else {
                this.success = this.translateService.instant('general.updatedUser');
            }

            this.setFormMode(FormMode.read);
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    onDelete(): void {
        this.loading = true;
        this.autoRemove = true;
        this.success = undefined;
        this.error = undefined;
        this.copyText = undefined;

        this.userService.deleteUser(this.user.id).subscribe(result => {
            this.loading = false;
            this.success = this.translateService.instant('general.deletedUser');
            this.cd.markForCheck();

            setTimeout(() => {
                this.router.navigate(['/Users/Overview']);
            }, 3000);
        }, error => {
            this.loading = false;
            this.cd.markForCheck();
            this.error = error
            this.getUserInfo();
        });
    }
}
