import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getGridButtons, getGridLanguages, setSelection, setTableStars, setSelectionRows, createdCellCheckbox } from 'app/common/gridhelper';
import { DeviceType } from 'app/models/devicetype.model';

import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Observable, Subject, debounceTime, distinctUntilChanged, forkJoin } from 'rxjs';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslatorService } from '../../services/common/translator.service';
import { DeviceService } from '../../services/device/device.service';
import { AnalogFunctions, BatchStatus, DeviceOutput, EntityType, LocationEventType } from 'app/common/enums';
import { getIconPath, roundAsNumber, roundAsString } from 'app/common/globals';
import { GridBase360Directive } from 'app/common/360Grid.base';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { StorageHelper } from 'app/common/storagehelper';
import { DistanceUnitService } from 'app/common/distanceunit.service';
import { ColorService } from 'app/services/common/color.service';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-devices-external',
  templateUrl: 'external.template.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DevicesExternalViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  token: string;
  countrySelect = false;

  excludingColumns = ['timeStamp', 'assetDateStart'];
  loading = false;
  hideArchived = true;

  deviceTypes: DeviceType[];

  dropdown_CustomerName = '';
  languageLoaded: boolean;
  timezoneIana: string;
  dropdown_CustomerNameSettings: any;
  selectedCustomers = [];

  dropdownSettingsDeviceTypes: any;

  permissionName = 'Devices_View';
  constructorName = 'DevicesExternalViewComponent';

  error: any;
  success: any;

  enableSelecting = true;

  gridSelection = [];
  showSelection = false;

  entityTypes = EntityType.DeviceExternal;
  isAllSelected = false;
  permissions: {};

  selectedResellerId: any;
  selectedAccountId: any;
  selectedAssetGroups: any[] = [];

  imeiText;
  imeiChanged: Subject<string> = new Subject<string>();

  showExtendedDeviceFilter = false;

  constructor(private localeService: BsLocaleService, private distance: DistanceUnitService, private route: ActivatedRoute, private translatorService: TranslatorService, private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private deviceService: DeviceService, private deviceTypeService: DeviceTypeService, protected storageHelper: StorageHelper, private colorService: ColorService) {
    super(storageHelper);

    const that = this;
    this.loading = true;
    this.permissions = authenticationService.permissions;

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.showExtendedDeviceFilter = localStorage.getItem('ShowExtendedDeviceFilter_' + this.constructorName) === 'true';

    this.imeiChanged
      .pipe(
        debounceTime(2000)
        , distinctUntilChanged()
      ) // wait 2 sec after the last event before emitting last event
      // only emit if value is different from previous value
      .subscribe(model => {
        this.imeiText = model;

        // Call your function which calls API or do anything you would like do after a lag of 2 sec
        this.fireFilter(1);
      });


    // Get all the date for dropdown boxes
    forkJoin([
      this.translateService.get('general.date'),
      this.deviceTypeService.getDeviceTypes()]
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();

        this.deviceTypes = data[1].filter(x => x.deviceCount > 0);
        this.deviceTypes = this.deviceTypes.sort((a, b) => (a.modelName > b.modelName ? 1 : -1));

        this.initGrid();
      },
      err => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  ngOnInit(): void {
  }

  onImeiChanged(text) {
    this.imeiChanged.next(text);
  }

  resellerChanged(event) {
    this.selectedAccountId = null;
    this.selectedAssetGroups = [];
    this.fireFilter(1);
    this.cd.markForCheck();
  }

  accountChanged(event) {
    this.selectedAssetGroups = [];
    this.fireFilter(1);
    this.cd.markForCheck();
  }

  flipExtendedDeviceFilter() {
    this.showExtendedDeviceFilter = !this.showExtendedDeviceFilter;
    localStorage.setItem('ShowExtendedDeviceFilter_' + this.constructorName, this.showExtendedDeviceFilter.toString());

    if (this.showExtendedDeviceFilter === false) {
      this.imeiText = '';
      this.selectedResellerId = null;
      this.selectedAccountId = null;
      this.selectedAssetGroups = [];

      this.fireFilter(1);
    }
  }

  // Check custom filters from grid save
  checkFilters() {

    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      this.hideArchived = dtInstance.column('isArchived:name').search() !== '@ignore';
    });

    this.fireFilter(1);
  }

  // Check route to apply filters
  checkRoute() {
  }

  assetGroupsChanged() {
    this.fireFilter(1);
    this.cd.markForCheck();
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log('Fire update');
      this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

        if (this.hideArchived === true) {
          dtInstance.column('isArchived:name').search('false');
        } else {
          dtInstance.column('isArchived:name').search('@ignore');
        }

        // Fire the request
        dtInstance.draw();
      });
    }
  }

  initGrid(): void {

    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    const deviceTypeOptions = [];
    this.deviceTypes.forEach(function (item, index) {
      if (item.modelName !== '') {
        deviceTypeOptions.push({ id: item.modelName, value: item.modelName + ' (' + item.deviceCount + ')' });
      }
    });

    const inputMapping = Object.keys(LocationEventType)
      .filter(k => typeof LocationEventType[k] === 'string')
      .map(n => ({ id: n, value: this.translateService.instant('enums.locationEventType.' + n) }))
      .sort((a, b) => a.value.localeCompare(b.value));

    const outputMapping = Object.keys(DeviceOutput)
      .filter(k => typeof DeviceOutput[k] === 'string')
      .map(n => ({ id: n, value: this.translateService.instant('enums.deviceOutput.' + n) }))
      .sort((a, b) => a.value.localeCompare(b.value));

    const analogMapping = Object.keys(AnalogFunctions)
      .filter(k => typeof AnalogFunctions[k] === 'string')
      .map(n => ({ id: n, value: this.translateService.instant('enums.analogFunctions.' + n) }))
      .sort((a, b) => a.value.localeCompare(b.value));

    const excludedColumns = ['id', 'id_export', 'isSelected', 'form'];
    let selectorVisible = true;

    if (!this.permissions['FleetManagement_Actions']) {
      selectorVisible = false;
    }

    this.columns = [{
      name: 'isSelected',
      data: 'id',
      className: 'noVis',
      title: '<div class="hideDropdown" id="selectorHeader"></div>',
      orderable: false,
      visible: selectorVisible,
      width: '20',
      render: function (data, type, row) {
        if (!that.processing.tableIdsSelection) {
          that.processing.tableIdsSelection = [];
        }
        if (that.processing.tableIdsSelection.indexOf(row.id.toString()) === -1) {
          that.processing.tableIdsSelection.push(row.id.toString());
        }

        if (!that.processing.tableIds) {
          that.processing.tableIds = [];
        }
        if (that.processing.tableIds.indexOf(row.id.toString()) === -1) {
          that.processing.tableIds.push(row.id.toString());
        }

        const isSelected = that.processing.gridSelection.indexOf(data.toString()) !== -1;
        return '<i id="selection_' + row.id.toString() + '" class="far fa-fw fa-lg ' + (isSelected ? 'fa-check-square ' : 'fa-square ') + '" style="cursor: pointer;"></i>';
      }
    },
    {
      name: 'id',
      data: 'id',
      className: 'noVis',
      orderable: false,
      title: '<div class="hideDropdown"></div>',
      width: '20',
      render: function (data, type, row) {
        return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/DeviceDetails/External/' + data + '\'><span class="d-none d-md-inline-flex" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
      }
    },
    {
      name: 'id_export',
      data: 'id',
      className: 'noVis',
      title: this.translateService.instant('general.id'),
      visible: false,
    },
    {
      name: 'assetName',
      data: 'asset.name',
      defaultContent: '-',
      title: this.translateService.instant('general.name'),
    },
    {
      name: 'isVehicleValid',
      data: 'asset.properties.wasl.inquiryStatus.isVehicleValid',
      defaultContent: '-',
      title: this.translateService.instant('general.isVehicleValid'),
      visible: true,
      searchable: true,
      orderable: true,
      type: 'checkBox',
      createdCell: createdCellCheckbox,
      render: function (data, type, row) {
        return data ? true : false;
      }
    },
    {
      name: 'vehicleRejectionReason',
      data: 'asset.properties.wasl.inquiryStatus.vehicleRejectionReason',
      defaultContent: '-',
      title: this.translateService.instant('general.vehicleRejectionReason'),
      visible: true
    },
    {
      name: 'inquiryTime',
      data: 'asset.properties.wasl.inquiryStatus.inquiryTime',
      defaultContent: '-',
      title: this.translateService.instant('general.inquiryTime'),
      render: function (data, type, row) {
        const date = Moment.utc(data)['tz'](that.timezoneIana);
        return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '-';
      },
      visible: true
    },
    {
      name: 'vehiclePlateNumber',
      data: 'asset.properties.wasl.vehiclePlateNumber',
      defaultContent: '-',
      visible: false,
      title: this.translateService.instant('general.plateNo'),
    },
    {
      name: 'vehiclePlateRightLetter',
      data: 'asset.properties.wasl.vehiclePlateRightLetter',
      defaultContent: '-',
      visible: false,
      title: this.translateService.instant('general.plateNoRight'),
    },
    {
      name: 'vehiclePlateMiddleLetter',
      data: 'asset.properties.wasl.vehiclePlateMiddleLetter',
      defaultContent: '-',
      visible: false,
      title: this.translateService.instant('general.plateNoMiddle'),
    },
    {
      name: 'vehiclePlateLeftLetter',
      data: 'asset.properties.wasl.vehiclePlateLeftLetter',
      defaultContent: '-',
      visible: false,
      title: this.translateService.instant('general.plateNoLeft'),
    },

    {
      name: 'erpCode',
      data: 'erpCode',
      defaultContent: '-',
      visible: true,
      title: this.translateService.instant('general.erpCode'),
    },
    {
      name: 'accountErpCode',
      data: 'accountErpCode',
      defaultContent: '-',
      visible: false,
      title: this.translateService.instant('general.accountErpCode'),
    },
    {
      name: 'unitId',
      data: 'unitId',
      title: this.translateService.instant('general.unitId'),
      visible: true
    },
    {
      name: 'deviceTypeName',
      data: 'deviceTypeName',
      defaultContent: '-',
      iconName: 'fas fa-fw fa-router',
      type: 'select',
      options: deviceTypeOptions.sort((a, b) => a.value.localeCompare(b.value)),
      title: this.translateService.instant('general.devicetype')
    },
    {
      name: 'manufacturer',
      data: 'manufacturer',
      defaultContent: '-',
      title: this.translateService.instant('general.manufacturer'),
      visible: false,
      render: function (data, type, row) {
        return data ? data : '-';
      }
    },
    {
      name: 'companyName',
      data: 'companyName',
      defaultContent: '-',
      title: this.translateService.instant('general.companyName')
    },
    {
      name: 'referenceKey',
      data: 'asset.properties.wasl.referenceKey',
      defaultContent: '-',
      title: this.translateService.instant('general.waslKey'),
      visible: false,
    },
    {
      name: 'registrationTime',
      data: 'asset.properties.wasl.inquiryStatus.registrationTime',
      defaultContent: '-',
      title: this.translateService.instant('general.registrationTime'),
      render: function (data, type, row) {
        const date = Moment.utc(data)['tz'](that.timezoneIana);
        return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '-';
      },
      visible: false
    },
    {
      name: 'actualTime',
      data: 'asset.properties.wasl.inquiryStatus.actualTime',
      defaultContent: '-',
      title: this.translateService.instant('general.actualTime'),
      render: function (data, type, row) {
        const date = Moment.utc(data)['tz'](that.timezoneIana);
        return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '-';
      },
      visible: false
    },
    {
      name: 'receivedTime',
      data: 'asset.properties.wasl.inquiryStatus.receivedTime',
      defaultContent: '-',
      title: this.translateService.instant('general.receivedTime'),
      render: function (data, type, row) {
        const date = Moment.utc(data)['tz'](that.timezoneIana);
        return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '-';
      },
      visible: true
    },
    {
      name: 'vehicleStatus',
      data: 'asset.properties.wasl.inquiryStatus.vehicleStatus',
      defaultContent: '-',
      title: this.translateService.instant('general.vehicleStatus'),
      visible: true
    },
    {
      name: 'registerDateWasl',
      data: 'asset.properties.wasl.registerDateWasl',
      defaultContent: '-',
      title: this.translateService.instant('general.wasl'),
      visible: true,
      searchable: true,
      orderable: true,
      type: 'checkBox',
      createdCell: createdCellCheckbox,
      render: function (data, type, row) {
        return data ? true : false;
      }
    },
    {
      name: 'registerDateSfda',
      data: 'asset.properties.wasl.registerDateSfda',
      defaultContent: '-',
      title: this.translateService.instant('general.sfda'),
      visible: true,
      searchable: true,
      orderable: true,
      type: 'checkBox',
      createdCell: createdCellCheckbox,
      render: function (data, type, row) {
        return data ? true : false;
      }
    },
    {
      name: 'storingCategory',
      data: 'asset.properties.wasl.storingCategory',
      defaultContent: '-',
      title: this.translateService.instant('general.sfdaStoringCategory'),
      visible: false,
      searchable: false,
      orderable: true,
      render: function (data, type, row) {
        return data !== undefined ? '<span title="' + data + '">' + that.translateService.instant('enums.sfdaStoringCategory.' + data) + '</span>' : '-';
      },
    },
    {
      name: 'registerDateTow',
      data: 'asset.properties.wasl.registerDateTow',
      defaultContent: '-',
      title: this.translateService.instant('general.tow'),
      visible: true,
      searchable: true,
      orderable: true,
      type: 'checkBox',
      createdCell: createdCellCheckbox,
      render: function (data, type, row) {
        return data ? true : false;
      }
    },
    {
      name: 'assetSequenceNumber',
      data: 'asset.properties.wasl.sequenceNumber',
      defaultContent: '-',
      title: this.translateService.instant('general.assetSequenceNumber'),
      visible: true,
    },
    {
      name: 'accountSequenceNumber',
      data: 'asset.accountProperties.wasl.identityNumber',
      defaultContent: '-',
      title: this.translateService.instant('general.accountIdentityNumber'),
      visible: true,
    },
    {
      name: 'timeStamp',
      data: 'timestamp',
      title: this.translateService.instant('general.timeStamp'),
      render: function (data, type, row) {
        const date = Moment.utc(data)['tz'](that.timezoneIana);
        return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
      },
      visible: false
    },
    {
      name: 'resellerDescription',
      data: 'resellerDescription',
      defaultContent: '-',
      title: this.translateService.instant('general.resellerDescription'),
      visible: false
    },
    {
      name: 'assetCount',
      data: 'assetCount',
      defaultContent: '-',
      title: this.translateService.instant('general.assetCount'),
      visible: false
    },
    {
      name: 'assetTypeName',
      data: 'assetTypeName',
      defaultContent: '-',
      title: this.translateService.instant('general.assetTypeName'),
      visible: false
    },
    {
      name: 'deviceState',
      data: 'deviceState.calculatedDeviceState?.deviceState',
      title: this.translateService.instant('general.deviceState'),
      visible: false,
      iconName: 'fas fa-fw fa-map-marker-alt',
      type: 'select',
      options: [
        { id: '0', value: that.translateService.instant(('general.deviceState_0')) },
        { id: '1', value: that.translateService.instant(('general.deviceState_1')) },
        { id: '2', value: that.translateService.instant(('general.deviceState_2')) },
        { id: '3', value: that.translateService.instant(('general.deviceState_3')) },
        { id: '4', value: that.translateService.instant(('general.deviceState_4')) },
        { id: '5', value: that.translateService.instant(('general.deviceState_5')) },
        { id: '6', value: that.translateService.instant(('general.deviceState_6')) },
      ],
      render: function (data, type, row) {
        if (data == null) {
          return '-';
        } else {
          let color = 'black';
          if (data === 1) {
            color = 'green';
          }
          if (data === 2) {
            color = 'red';
          }
          if (data === 3) {
            color = 'orange';
          }
          if (data === 4) {
            color = 'blue';
          }
          return '<i class="fa fa-fw fa-map-marker-alt eye-active ' + color + '"></i> ' + that.translateService.instant('general.deviceState_' + data);
        }
      }
    },
    {
      name: 'lastCommunication',
      data: 'deviceState.communicationState.updateTimestamp',
      defaultContent: '',
      title: this.translateService.instant('general.lastCommunication'),
      render: function (data, type, row) {
        if (data == null) {
          return 'N/A';
        } else {
          const date = Moment.utc(data)['tz'](that.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
        }
      }
    },
    {
      name: 'latency',
      data: 'deviceState.communicationState.updateTimestamp',
      defaultContent: '',
      title: this.translateService.instant('general.latencyInSeconds'),
      visible: false,
      render: function (data, type, row) {
        const serverTime = Moment.utc(row.deviceState?.communicationState?.serverTimestamp);
        const deviceTime = Moment.utc(row.deviceState?.communicationState?.updateTimestamp);

        const duration = Moment.duration(serverTime.diff(deviceTime));

        return data ? roundAsString(duration.asSeconds(), 0) : '-';
      }
    },
    {
      name: 'speedInKph',
      data: 'deviceState.currentPosition.speed',
      defaultContent: '-',
      type: 'num',
      iconName: 'fa fa-fa fa-gauge-max',
      title: this.translateService.instant('general.speedInKph'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '  ' + that.translateService.instant(that.distance.getDistanceUnitPerHour()) : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'rpm',
      data: 'deviceState.rpmStatus.rpm',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-engine',
      title: this.translateService.instant('general.rpm'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + ' RPM' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'externalBatteryLevelInVoltage',
      data: 'deviceState.externalBattery.batteryLevelInVoltage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-car-battery',
      title: this.translateService.instant('general.externalBatteryLevelInVoltage'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 2) + ' V' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'temperatureInCelcius',
      data: 'deviceState.temperature1.temperatureInCelcius',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-thermometer-three-quarters',
      title: this.translateService.instant('general.temperature'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 1) + ' °C' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'temperature2InCelcius',
      data: 'deviceState.temperature2.temperatureInCelcius',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-thermometer-three-quarters',
      title: this.translateService.instant('general.temperature2'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 1) + ' °C' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'temperature3',
      data: 'deviceState.temperature3.temperatureInCelcius',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-thermometer-three-quarters',
      title: this.translateService.instant('general.temperature3'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 1) + ' °C' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'temperature4',
      data: 'deviceState.temperature4.temperatureInCelcius',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-thermometer-three-quarters',
      title: this.translateService.instant('general.temperature4'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 1) + ' °C' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'totalWeight',
      data: 'deviceState.totalAxleWeight.totalWeightInKg',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-weight',
      title: this.translateService.instant('general.weight'),
      visible: true,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? (roundAsNumber(data, 0)).toLocaleString() + ' kg' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'humidityInPercent1',
      data: 'deviceState.humidity1.humidityInPercent',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-humidity',
      title: this.translateService.instant('general.humidityInPercent1'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'fuelLevelInPercentage',
      data: 'deviceState.fuel.fuelLevelInPercentage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-gas-pump',
      title: this.translateService.instant('general.fuelLevelInPercentage'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'fuelConsumed',
      data: 'deviceState.fuelConsumptionState.fuelConsumed',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-weight',
      title: this.translateService.instant('general.fuelConsumed'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? (roundAsNumber(data, 0)).toLocaleString() + ' L' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'externalPower_mapping',
      data: 'mappingProfile.externalPowerMapping',
      defaultContent: '',
      title: this.translateService.instant('general.externalPowerMapping'),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.externalPower + '">' + that.translateService.instant('enums.locationEventType.' + data) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'externalPower',
      data: 'deviceState.externalPower.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.externalPowerState'),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'ignition_mapping',
      data: 'mappingProfile.ignitionMapping',
      defaultContent: '',
      title: this.translateService.instant('general.ignitionMapping'),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.ignition + '">' + that.translateService.instant('enums.locationEventType.' + data) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'ignition',
      data: 'deviceState.ignition.state',
      type: 'checkBox',
      defaultContent: '',
      iconName: 'fas fa-fw fa-key',
      title: this.translateService.instant('general.ignitionState'),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'input1_mapping',
      data: 'mappingProfile.inputMapping1',
      defaultContent: '-',
      title: this.translateService.instant('general.inputMappingFormatted', { value: '1' }),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.input1 + '">' + that.translateService.instant('enums.locationEventType.' + data) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'input1_value',
      data: 'deviceState.input1.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.inputStateFormatted', { value: '1' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'input2_mapping',
      data: 'mappingProfile.inputMapping2',
      defaultContent: '-',
      title: this.translateService.instant('general.inputMappingFormatted', { value: '2' }),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.input2 + '">' + that.translateService.instant('enums.locationEventType.' + data) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'input2_value',
      data: 'deviceState.input2.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.inputStateFormatted', { value: '2' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'input3_mapping',
      data: 'mappingProfile.inputMapping3',
      defaultContent: '',
      title: this.translateService.instant('general.inputMappingFormatted', { value: '3' }),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.input3 + '">' + that.translateService.instant('enums.locationEventType.' + data) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'input3_value',
      data: 'deviceState.input3.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.inputStateFormatted', { value: '3' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'input4_mapping',
      data: 'mappingProfile.inputMapping4',
      defaultContent: '',
      title: this.translateService.instant('general.inputMappingFormatted', { value: '4' }),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.input4 + '">' + that.translateService.instant('enums.locationEventType.' + data) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'input4_value',
      data: 'deviceState.input4.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.inputStateFormatted', { value: '4' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'input5_mapping',
      data: 'mappingProfile.inputMapping5',
      defaultContent: '',
      title: this.translateService.instant('general.inputMappingFormatted', { value: '5' }),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.input5 + '">' + that.translateService.instant('enums.locationEventType.' + data) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'input5_value',
      data: 'deviceState.input5.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.inputStateFormatted', { value: '5' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'input6_mapping',
      data: 'mappingProfile.inputMapping6',
      defaultContent: '',
      title: this.translateService.instant('general.inputMappingFormatted', { value: '6' }),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.input6 + '">' + that.translateService.instant('enums.locationEventType.' + data) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'input6_value',
      data: 'deviceState.input6.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.inputStateFormatted', { value: '6' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'output1_mapping',
      data: 'mappingProfile.outputMapping1',
      defaultContent: '',
      title: this.translateService.instant('general.outputMappingFormatted', { value: '1' }),
      visible: false,
      type: 'select',
      options: outputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.output1 + '">' + that.translateService.instant('enums.deviceOutput.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'output1_value',
      data: 'deviceState.output1.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.outputStateFormatted', { value: '1' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'output2_mapping',
      data: 'mappingProfile.outputMapping2',
      defaultContent: '',
      title: this.translateService.instant('general.outputMappingFormatted', { value: '2' }),
      visible: false,
      type: 'select',
      options: outputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.output2 + '">' + that.translateService.instant('enums.deviceOutput.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'output2_value',
      data: 'deviceState.output2.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.outputStateFormatted', { value: '2' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'output3_mapping',
      data: 'mappingProfile.outputMapping3',
      defaultContent: '',
      title: this.translateService.instant('general.outputMappingFormatted', { value: '3' }),
      visible: false,
      type: 'select',
      options: outputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.output3 + '">' + that.translateService.instant('enums.deviceOutput.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'output3_value',
      data: 'deviceState.output3.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.outputStateFormatted', { value: '3' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'output4_mapping',
      data: 'mappingProfile.outputMapping4',
      defaultContent: '',
      title: this.translateService.instant('general.outputMappingFormatted', { value: '4' }),
      visible: false,
      type: 'select',
      options: outputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.output4 + '">' + that.translateService.instant('enums.deviceOutput.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'output4_value',
      data: 'deviceState.output4.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.outputStateFormatted', { value: '4' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'analog1_mapping',
      data: 'mappingProfile.analogMapping1',
      defaultContent: '',
      title: this.translateService.instant('general.analogMappingFormatted', { value: '1' }),
      visible: false,
      type: 'select',
      options: analogMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.analog1 + '">' + that.translateService.instant('enums.analogFunctions.' + data) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'analog1_value',
      data: 'deviceState.analog1.Value',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-bolt',
      title: this.translateService.instant('general.analogStateFormatted', { value: '1' }),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 2) + ' V' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'analog2_mapping',
      data: 'mappingProfile.analogMapping2',
      defaultContent: '',
      title: this.translateService.instant('general.analogMappingFormatted', { value: '2' }),
      visible: false,
      type: 'select',
      options: analogMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.analog2 + '">' + that.translateService.instant('enums.analogFunctions.' + data) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'analog2_value',
      data: 'deviceState.analog2.Value',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-bolt',
      title: this.translateService.instant('general.analogStateFormatted', { value: '2' }),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 2) + ' V' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'bleBatteryPercentage1',
      data: 'deviceState.bleBatteryState1.batteryLevelPercentage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-battery-half',
      title: this.translateService.instant('general.bleBatteryVoltage1'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'bleBatteryPercentage2',
      data: 'deviceState.bleBatteryState1.batteryLevelPercentage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-battery-half',
      title: this.translateService.instant('general.bleBatteryVoltage2'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'bleBatteryPercentage3',
      data: 'deviceState.bleBatteryState1.batteryLevelPercentage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-battery-half',
      title: this.translateService.instant('general.bleBatteryVoltage3'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'bleBatteryPercentage4',
      data: 'deviceState.bleBatteryState4.batteryLevelPercentage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-battery-half',
      title: this.translateService.instant('general.bleBatteryVoltage4'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'iconId',
      data: 'iconId',
      title: this.translateService.instant('general.icon'),
      render: function (data, type, row) {
        if (data == null) {
          return '-';
        } else {
          return data ? '<img style=" margin-top: -20px; margin-bottom: -10px;" src="' + (data > 0 ? getIconPath(data)[1] : '/assets/images/icons/vista/Trucks/32x32/TankerTruck_Black.png') + '">' : '';
        }
      },
      visible: false
    },
    {
      name: 'isArchived',
      data: 'isArchived',
      type: 'checkBox',
      title: this.translateService.instant('general.isArchived'),
      visible: true,
      createdCell: createdCellCheckbox,
      width: '40',
    }];

    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'device_external_overview', this.translateService.instant('menu.deviceoverview'), this.colorService),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => {
          try {
            const columnSettings = await that.loadState(that.constructorName);
            that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            that.error = {};
            that.error.error = e;
            that.error.statusText = 'Error fetching column settings';

            return null;
          }
        })().then(result => {
          callback(result);
        });
      },
      order: [[3, 'asc']],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.deviceService.getExternalPagingUrl(),
        data: (d) => {
          const imeiList = [];

          if (this.imeiText && this.imeiText.length > 10) {
            if (this.imeiText?.indexOf(';') > -1) {
              const tempList = this.imeiText?.split(';');
              tempList.forEach(item => {
                item = item.trim();
                if (item.length > 5) {
                  imeiList.push(item);
                }
              });
            } else {
              const tempList = this.imeiText?.split(/\r?\n/);
              tempList.forEach(item => {
                item = item.trim();
                if (item.length > 5) {
                  imeiList.push(item);
                }
              });
            }
          }

          return {
            ...d, filterOptions: { imeiList: imeiList, accountId: this.selectedAccountId, resellerId: this.selectedResellerId, assetGroups: this.selectedAssetGroups }
          };
        },
        dataSrc: function (json) {
          // if selection and returning keys
          if (that.processing.tableIdsSelection && that.processing.tableIdsSelection.length === 0) {
            that.processing.tableIdsSelection = json.keys;
          }
          return json.data;
        },
        error: (jqXHR, ajaxOptions, thrownError) => {
          that.loading = false;
          that.error = thrownError;
          that.cd.markForCheck();
        },
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        that.checkRoute();
        that.checkFilters();
        that.setEvents();
        that.drawFilterRow();
        $('#grid_select_or').appendTo($('th > #selectorHeader'));
        that.loading = false;
        that.cd.markForCheck();
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: excludedColumns
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
      drawCallback: (data) => {
        setTableStars(that.processing.tableIds);
        setSelection(that.processing.tableIds, that.processing.gridSelection);
      },
      rowCallback: (row, data) => {
        setSelectionRows(that, row, data, that.processing.gridSelection);
      }
    };
  }
  // batch processing
  onClose(event) {
    console.log('Closing batch popup');

    this.success = null;
    this.error = null;

    if (event && event.status === BatchStatus.Success) {
      console.log('success');

      this.success = {};
      this.success.statusText = 'Successfully posted batch';
      this.success.success = 'Your batch was accepted and scheduled to be proccesed';
      this.success.unique = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      this.cd.markForCheck();
    }
    if (event && event.status === BatchStatus.Error) {
      this.error = {};
      this.error.statusText = 'Error';
      this.error.error = 'Error in processing the batch';
      this.error.unique = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      this.cd.markForCheck();
    }
  }
}
