import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { getGridButtons, getGridLanguages, createSearch, createdCellCheckbox, getDefaultDpConfig } from 'app/common/gridhelper';

import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { Observable, forkJoin } from 'rxjs';

import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { GridBase360Directive } from 'app/common/360Grid.base';
import { UserService } from 'app/services/users/user.service';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { StorageHelper } from 'app/common/storagehelper';

window['moment'] = Moment;
mTZ()

@Component({
    selector: 'fh-active-users',
    templateUrl: 'activeUsers.component.html'
})
export class ActiveUsersComponent extends GridBase360Directive implements OnInit, OnDestroy {
    token: string;

    showActiveOnly = true;

    excludingColumns = [];

    loading = false;
    languageLoaded: boolean;
    permissions: {};

    permissionName = 'Platform_IsAdministrator';
    constructorName = 'ErrorLogComponent';
    timezoneIana: string;

    error;

    // Daterange
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    daterangepickerModel: Date[];

    constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private userService: UserService, protected storageHelper: StorageHelper) {
        super(storageHelper);

        this.token = this.authenticationService.getAuthToken();
        this.timezoneIana = this.authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(1, 'months').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

        // Get all the date for dropdown boxes
        forkJoin(
            this.translateService.get('general.date')
        ).subscribe(
            data => {

                this.languageLoaded = true;
                this.loading = false;

                this.initGrid();
            },
            err => console.error(err)
        );
    }

    ngOnInit() {
        this.permissions = this.authenticationService.permissions;
    }

    initGrid(): void {
        const that = this;

        $.fn['dataTable'].ext.search.pop();

        this.loading = true;

        this.columns = [
            {
                name: 'key',
                data: 'key',
                title: this.translateService.instant('general.connectionId'),
            },
            {
                name: 'userName',
                data: 'value.userName',
                title: this.translateService.instant('general.userName')
            },
            {
                name: 'userId',
                data: 'value.userId',
                title: this.translateService.instant('general.userId')
            },
            {
                name: 'accountId',
                data: 'value.accountId',
                defaultContent: '-',
                title: this.translateService.instant('general.accountId'),
            }, {
                name: 'sessionStart',
                data: 'value.sessionStart',
                defaultContent: '-',
                title: this.translateService.instant('general.sessionStart'),
                render: function (data, type, row) {
                    const date = Moment.utc(data)['tz'](that.timezoneIana);
                    return data ? date.format('llll') : '';
                },
            }, {
                name: 'lastConnectedTime',
                data: 'value.lastConnectedTime',
                defaultContent: '-',
                title: this.translateService.instant('general.lastConnectedTime'),
                render: function (data, type, row) {
                    const date = Moment.utc(data)['tz'](that.timezoneIana);
                    return data ? date.format('llll') : '';
                },
            },
        ];

        this.dtOptions = {
            pagingType: 'simple_numbers',
            serverSide: false,
            processing: false,
            scrollX: true,
            colReorder: { fixedColumnsLeft: 1 },
            stateSave: true,
            stateSaveCallback: function (settings, data) {
                that.saveState(that.constructorName, data);
            },
            stateLoadCallback: function (_, callback) {
                (async () => { try {
                    const columnSettings = await that.loadState(that.constructorName);
                    that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
                    return columnSettings;
                } catch (e) {
                    that.error = {};
                    that.error.error = e;
                    that.error.statusText = 'Error fetching column settings';

                    return null;
                }})().then(result => {
                    callback(result);
                });
            },
            order: [[0, 'desc']],
            ajax: (dataTablesParameters: any, callback) => {
                $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
                this.userService.getActiveUsers().subscribe(resp => {
                    callback({
                        recordsTotal: resp.length,
                        recordsFiltered: resp.length,
                        data: resp
                    });
                });
            },
            initComplete: function (settings, json) {
                console.log('init complete');
                that.checkFilters();
                that.drawFilterRow();
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
                exclude: ['id', 'id_export']
            },
            columns: this.columns,
            pageLength: 17,
            lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
            language: getGridLanguages(this.translateService),
        };
    }
}

