<ngx-loading [show]="loading"></ngx-loading>

<div>
    <table style="width:100%;" class="table nowrap dtr-inline no-footer">
        <tbody>
            <tr>
                <td style="width:66%">
                    <ng-select name="reportTemplateId" ngDefaultControl [searchable]="true" required bindLabel="source"
                        bindValue="source" (ngModelChange)="templateChanged($event)" [clearable]="false"
                        placeholder="{{'general.loadTemplate' | translate}} ({{reportTemplates.length + 1}})"
                        style="width: 100%px; height: unset;" groupBy="entity" [disabled]="false" #input
                        [multiple]="false" [(ngModel)]="selectedReportTemplateId" class="form-control table-inline">
                        <ng-option [value]="'default'">
                            {{ 'general.default' | translate}}
                        </ng-option>
                        <ng-option *ngFor="let reportTemplate of reportTemplates | orderBy : 'name':false"
                            [value]="reportTemplate.id">
                            {{ reportTemplate.name }}
                        </ng-option>
                    </ng-select>
                </td>
                <td style="width: 33%;">
                    <button class="btn btn-primary btn-inline" (click)="saveReportTemplate()" style="width: 50%"
                        [disabled]="!selectedReportTemplateId || selectedReportTemplateId =='default' || !checkForEdit(selectedReportTemplateId)">
                        <i class="fa fa-fw fa-save"></i> <span class="d-none d-md-inline-flex"
                            style="padding-left: 7px;">{{
                            'general.reportTemplateSave' | translate }}</span>
                    </button>
                    <button class="btn btn-primary btn-inline" (click)="isSavingModal = true" style="width: 50%">
                        <i class="fa fa-fw fa-plus"></i> <span class="d-none d-md-inline-flex"
                            style="padding-left: 7px;">{{
                            'general.reportTemplateSaveAs' | translate }}</span>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<br /><br />
<div style="width:100%; overflow-y: auto;">
    <table style="width:100%;" class="table nowrap dtr-inline no-footer">
        <thead>
            <tr>
                <th style="width: 0px"></th>
                <th>{{ 'general.entity' | translate}} / {{ 'general.source' | translate}}</th>
                <th>{{ 'general.name' | translate}}</th>
                <th>{{ 'general.dataType' | translate}}</th>
                <th>{{ 'general.uom' | translate}}</th>
                <th>{{ 'general.formatting' | translate}}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let column of template.columnConfiguration; let i=index" [class.active]="draggedOverIndex == i"
                class="draggable noselect" (drop)="onDrop($event, i)">
                <td style="width: 0px" (dragstart)="onDragStart($event, i)" draggable="true" class="dragHandle"
                    (dragover)="allowDrop($event, i)">
                    <i class="fa fa-fw fa-arrows-up-down"></i>
                </td>
                <td style="overflow: unset;">
                    {{column.entity}} / {{column.source}}
                </td>
                <td>
                    {{column.name}}
                </td>
                <td>
                    {{ column.dataType }}
                </td>
                <td>
                    {{ column.uom }}
                </td>
                <td>
                    {{ column.formatting }}
                </td>
                <td style="text-align: right; width: 60px;">
                    <button class="btn btn-danger btn-inline" (click)="removeColumn(i)">
                        <i class="fa fa-fw fa-trash-alt"></i>
                    </button>
                    <button class="btn btn-primary btn-inline" (click)="showModal(i)">
                        <i class="fa fa-fw fa-wrench"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div>
    <table style="width:100%;" class="table nowrap dtr-inline no-footer">
        <tbody>
            <tr>
                <td colspan="2" style="width:100%">
                    <ng-select name="source_new" ngDefaultControl [searchable]="true" [items]="sourceColumns"
                        bindLabel="name" bindValue="source" [clearable]="false" style="height: unset" groupBy="entity"
                        placeholder="{{'general.chooseColumn' | translate}} ({{sourceColumns.length}})" #input
                        [multiple]="false" [(ngModel)]="source_new" class="form-control table-inline" [ngClass]="">
                        <ng-template ng-optgroup-tmp let-item="item">
                            {{item.entity || 'Unknown entity'}}
                        </ng-template>
                    </ng-select>
                </td>
                <td style="width: auto;">
                    <button class="btn btn-primary btn-inline" [disabled]="!source_new" (click)="add()">
                        <i class="fa fa-fw fa-plus"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<br />
<div>
    <table style="width:100%;" class="table nowrap dtr-inline no-footer">
        <thead>
            <tr>
                <th colspan="3">{{ 'general.sortingAndGrouping' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="row_opening">
                    {{ 'general.groupOn' | translate}}
                </td>
                <td class="row_opening" style="overflow: visible">
                    <ng-select (ngModelChange)="groupOnChanges($event)" name="groupOn" ngDefaultControl
                        [searchable]="true" [clearable]="false" style="height: unset; width: 100%"
                        [(ngModel)]="template.groupByIndex" [multiple]="false" class="form-control table-inline">
                        <ng-option [value]="-1">
                            {{ 'general.noGrouping' | translate}}
                        </ng-option>
                        <ng-option *ngFor="let column of template.columnConfiguration; let i=index" [value]="i">
                            {{ column.name }}
                        </ng-option>
                    </ng-select>
                </td>

                <td class="row_opening" style="overflow: visible">
                    <ng-select (ngModelChange)="emitChanges()" name="groupByType" ngDefaultControl [searchable]="false"
                        [clearable]="false" style="height: unset; width: 100%" [(ngModel)]="template.groupByType"
                        [multiple]="false"
                        [disabled]="template.groupByIndex == undefined || template.groupByIndex == -1"
                        class="form-control table-inline">
                        <ng-option [value]="-1" [disabled]="true">
                            {{ 'general.disabled' | translate}}
                        </ng-option>
                        <ng-option [value]="1"
                            [disabled]="template.groupByIndex > -1 && template.columnConfiguration[template.groupByIndex]?.dataType === 'DateTime'">
                            {{ 'enums.groupByType.1' | translate}}
                        </ng-option>
                        <ng-option [value]="2"
                            [disabled]="template.groupByIndex > -1 && template.columnConfiguration[template.groupByIndex]?.dataType !== 'DateTime'">
                            {{ 'enums.groupByType.2' | translate}}
                        </ng-option>
                        <ng-option [value]="3"
                            [disabled]="template.groupByIndex > -1 && template.columnConfiguration[template.groupByIndex]?.dataType !== 'DateTime'">
                            {{ 'enums.groupByType.3' | translate}}
                        </ng-option>
                        <ng-option [value]="4"
                            [disabled]="template.groupByIndex > -1 && template.columnConfiguration[template.groupByIndex]?.dataType !== 'DateTime'">
                            {{ 'enums.groupByType.4' | translate}}
                        </ng-option>
                        <ng-option [value]="5"
                            [disabled]="template.groupByIndex > -1 && template.columnConfiguration[template.groupByIndex]?.dataType !== 'DateTime'">
                            {{ 'enums.groupByType.5' | translate}}
                        </ng-option>
                        <ng-option [value]="6"
                            [disabled]="template.groupByIndex > -1 && template.columnConfiguration[template.groupByIndex]?.dataType !== 'DateTime'">
                            {{ 'enums.groupByType.6' | translate}}
                        </ng-option>
                        <ng-option [value]="7"
                            [disabled]="template.groupByIndex > -1 && template.columnConfiguration[template.groupByIndex]?.dataType !== 'DateTime'">
                            {{ 'enums.groupByType.7' | translate}}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr *ngIf="!(template.groupByIndex == undefined || template.groupByIndex == -1)">
                <td class="row_opening">
                    {{ 'general.hideGroupedOn' | translate}}
                </td>
                <td colspan="2">
                    <div class="fa_checkbox fa_toggle">
                        <input [checked]="false" id="hideGroupByColumn" type="checkbox" name="hideGroupByColumn"
                            (ngModelChange)="emitChanges()" [(ngModel)]="template.hideGroupByColumn" />
                        <label for="hideGroupByColumn"></label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.sortOn' | translate}}
                </td>
                <td class="row_opening" style="overflow: visible">
                    <ng-select (ngModelChange)="sortOnChanges()" name="orderByIndex" ngDefaultControl
                        [searchable]="true" [clearable]="false" style="height: unset; width: 100%"
                        [(ngModel)]="template.orderByIndex" [multiple]="false" class="form-control table-inline">
                        <ng-option [value]="-1">
                            {{ 'general.noSorting' | translate}}
                        </ng-option>
                        <ng-option *ngFor="let column of template.columnConfiguration; let i=index" [value]="i">
                            {{ column.name }}
                        </ng-option>
                    </ng-select>
                </td>
                <td class="row_opening" style="overflow: visible">
                    <ng-select (ngModelChange)="emitChanges()" name="orderByAscending" ngDefaultControl
                        [searchable]="false" [clearable]="false"
                        [disabled]="template.orderByIndex == undefined || template.orderByIndex == -1"
                        style="height: unset; width: 100%" [(ngModel)]="template.orderByAscending" [multiple]="false"
                        class="form-control table-inline">
                        <ng-option [value]="true">
                            {{'general.ascending' | translate}}
                        </ng-option>
                        <ng-option [value]="false">
                            {{'general.descending' | translate}}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.orientation' | translate}}
                </td>
                <td class="row_opening" style="overflow: visible">
                    <ng-select (ngModelChange)="sortOnChanges()" name="orientation" ngDefaultControl
                        [searchable]="false" [clearable]="false" style="height: unset; width: 100%"
                        [(ngModel)]="template.orientation" [multiple]="false" class="form-control table-inline">
                        <ng-option [value]="0">
                            {{ 'general.landscape' | translate}}
                        </ng-option>
                        <ng-option [value]="1">
                            {{ 'general.portrait' | translate}}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div style="clear: both;"></div>

<div *ngIf="isSavingModal" [config]="{ show: true, backdrop: true, ignoreBackdropClick: true }" (onHidden)="onHidden()"
    bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left">
                    {{ 'general.reportTemplateHeader' | translate }}
                </h5>
                <button type="button" class="close float-right" aria-label="Close" (click)="isSavingModal = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #saveTemplateForm="ngForm">
                    <table style="width: 100%; margin-bottom: 0;" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.name' | translate }}
                                    <span class="error" *ngIf="nameField.invalid">*</span>
                                </td>
                                <td class="row_content">
                                    <input #nameField="ngModel" ngModel name="name" type="text" style="width:100%"
                                        class="form-control table-inline" required />
                                </td>
                            </tr>

                            <tr [hidden]="!permissions['Resellers_View']">
                                <td class="row_opening">
                                    {{ 'general.reseller' | translate}}
                                </td>
                                <td class="row_content" style="position: relative; overflow: visible;">
                                    <fh-reseller-input name="targetResellerId" #resellerVar="ngModel"
                                        [autoSelect]="false" [tableInline]="true"
                                        placeholder="{{ 'general.chooseReseller' | translate }}" ngModel>
                                    </fh-reseller-input>
                                </td>
                            </tr>
                            <tr [hidden]="!permissions['Accounts_View']">
                                <td class="row_opening">
                                    {{ 'general.account' | translate}}
                                </td>
                                <td class="row_content" style="position: relative; overflow: visible;">
                                    <fh-account-input name="targetAccountId" #accountVar="ngModel" [autoSelect]="false"
                                        [selectedResellerId]="resellerVar.value" [needReseller]="false"
                                        [tableInline]="true" placeholder="{{ 'general.chooseAccount' | translate }}"
                                        ngModel>
                                    </fh-account-input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-info" (click)="showColumns()" style="min-width: 120px;"><i
                        class="fas fa-fw fa-table-columns"></i>
                    {{ 'general.showColumns' | translate }}</button> -->
                <button type="button" class="btn btn-primary" (click)="saveAsReportTemplate(saveTemplateForm.form)"
                    [disabled]="saveTemplateForm.invalid" style="min-width: 120px;"><i class="fa fa-fw fa-save"></i>
                    {{ 'general.save' | translate }}</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isModalShown" [config]="{ show: true, backdrop: true, ignoreBackdropClick: true }" (onHidden)="onHidden()"
    bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left">
                    <i class="fas fa-table-columns"></i> {{'general.column' | translate }}
                </h5>
                <button type="button" class="close float-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #columnForm="ngForm">
                    <table style="width:100%; margin-bottom: 0;" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td>{{'general.source' | translate }}</td>
                                <td>{{selectedColumn.source}}</td>
                            </tr>
                            <tr>
                                <td>{{'general.dataType' | translate }}</td>
                                <td>{{selectedColumn.dataType}}</td>
                            </tr>
                            <tr>
                                <td>{{'general.displayName' | translate }}</td>
                                <td><input name="name" type="text" style="width:100%" class="form-control table-inline"
                                        [(ngModel)]="selectedColumn.name" required></td>
                            </tr>
                            <tr>
                                <td>{{'general.widthPx' | translate }}</td>
                                <td><input name="width" type="number" style="width: 100%;"
                                        class="form-control table-inline" [(ngModel)]="selectedColumn.width"></td>
                            </tr>
                            <tr>
                                <td>{{'general.alignment' | translate }}</td>
                                <td>
                                    <ng-select [(ngModel)]="selectedColumn.alignment" class="form-control table-inline"
                                        name="alignment" required [clearable]="false" style="width:100%">
                                        <ng-option [value]="0">{{'enums.alignment.0' | translate }}
                                        </ng-option>
                                        <ng-option [value]="1">{{'enums.alignment.1' | translate }}
                                        </ng-option>
                                        <ng-option [value]="2">{{'enums.alignment.2' | translate }}
                                        </ng-option>
                                        <ng-option [value]="3">{{'enums.alignment.3' | translate }}
                                        </ng-option>
                                    </ng-select>
                                </td>
                            </tr>
                            <tr
                                *ngIf="selectedColumn.dataType == 'Int32' || selectedColumn.dataType == 'Int64' || selectedColumn.dataType == 'Float' || selectedColumn.dataType == 'Number' || selectedColumn.dataType ==  'Formula'">
                                <td>{{'general.summaryRow' | translate }}</td>
                                <td>
                                    <ng-select [(ngModel)]="selectedColumn.grouping" class="form-control table-inline"
                                        name="grouping" required [clearable]="false" style="width:100%">
                                        <ng-option [value]="0">{{'enums.reportGrouping.0' | translate }}
                                        </ng-option>
                                        <ng-option [value]="1">{{'enums.reportGrouping.1' | translate }}
                                        </ng-option>
                                        <ng-option [value]="2">{{'enums.reportGrouping.2' | translate }}
                                        </ng-option>
                                        <ng-option [value]="3">{{'enums.reportGrouping.3' | translate }}
                                        </ng-option>
                                        <ng-option [value]="4">{{'enums.reportGrouping.4' | translate }}
                                        </ng-option>
                                    </ng-select>
                                </td>
                            </tr>
                            <tr *ngIf="enableFormula && (selectedColumn.dataType ==  'Formula')">
                                <td>{{'general.formula' | translate }}
                                    <i tooltip="Uses mathjs.org for parsing" class="fas fa-fw fa-question"></i>
                                </td>
                                <td><input name="formula" type="text" style="width:100%"
                                        (keyup)="evaluate(selectedColumn)" class="form-control table-inline"
                                        [(ngModel)]="selectedColumn.formula"></td>
                            </tr>
                            <tr *ngIf="selectedColumn.formula">
                                <td>
                                </td>
                                <td><span>{{selectedColumn.evaluationResult}}</span></td>
                            </tr>
                            <tr
                                *ngIf="selectedColumn.dataType == 'Int32' || selectedColumn.dataType == 'Int64' || selectedColumn.dataType == 'Float' || selectedColumn.dataType == 'Number' || selectedColumn.dataType ==  'Formula' || selectedColumn.isCustom">
                                <td>{{'general.uom' | translate }}</td>
                                <td>
                                    <ng-select [(ngModel)]="selectedColumn.uom" class="form-control table-inline"
                                        name="uom" [clearable]="false" style="width: 100%;">
                                        <ng-option value="">{{ 'enums.uom.unknown' | translate }}</ng-option>
                                        <ng-option value="km">{{ 'enums.uom.km' | translate }}</ng-option>
                                        <ng-option value="kmh">{{ 'enums.uom.kmh' | translate }}</ng-option>
                                        <ng-option value="celsius">{{ 'enums.uom.celsius' | translate }}</ng-option>
                                        <ng-option value="kg">{{ 'enums.uom.kg' | translate }}</ng-option>
                                        <ng-option value="rpm">{{ 'enums.uom.rpm' | translate }}</ng-option>
                                        <ng-option value="percent">{{ 'enums.uom.percent' | translate }}</ng-option>
                                        <ng-option value="liter">{{ 'enums.uom.liter' | translate }}</ng-option>
                                        <ng-option value="volt">{{ 'enums.uom.volt' | translate }}</ng-option>

                                        <ng-option value="euro">{{ 'enums.uom.euro' | translate }}</ng-option>
                                        <ng-option value="dollar">{{ 'enums.uom.dollar' | translate }}</ng-option>
                                        <ng-option value="kmperliter">{{ 'enums.uom.kmperliter' | translate
                                            }}</ng-option>
                                        <ng-option value="literper100km">{{ 'enums.uom.literper100km' | translate
                                            }}</ng-option>
                                    </ng-select>
                                </td>
                            </tr>
                            <tr *ngIf="selectedColumn.dataType == 'DateTime'">
                                <td>{{'general.formatting' | translate }}
                                    <span class="error" *ngIf="formattingVar.invalid">*</span>
                                </td>
                                <td>
                                    <ng-select #formattingVar="ngModel" [(ngModel)]="selectedColumn.formatting"
                                        class="form-control" name="formatting" required [clearable]="false"
                                        style="width:100%">
                                        <ng-option value="DATE_lll">{{ exampleDate | amDateFormat: 'lll'}} </ng-option>
                                        <ng-option value="DATE_LLL">{{ exampleDate | amDateFormat: 'LLL'}} </ng-option>
                                        <ng-option value="DATE_LLLL">{{ exampleDate | amDateFormat: 'LLLL'}}
                                        </ng-option>
                                        <ng-option value="DATE_L">{{ exampleDate | amDateFormat: 'L'}} </ng-option>
                                        <!-- <ng-option value="DATE_l">{{ exampleDate | amDateFormat: 'l'}} </ng-option> -->
                                        <ng-option value="DATE_LL">{{ exampleDate | amDateFormat: 'LL'}} </ng-option>
                                        <ng-option value="DATE_ll">{{ exampleDate | amDateFormat: 'll'}} </ng-option>
                                        <ng-option value="DATE_LT">{{ exampleDate | amDateFormat: 'LT'}}</ng-option>
                                        <ng-option value="DATE_LTS">{{ exampleDate | amDateFormat: 'LTS'}}</ng-option>
                                        <ng-option value="DATE_HH:mm">{{ exampleDate | amDateFormat: 'HH:mm'}}
                                        </ng-option>
                                        <ng-option value="DATE_YYYY-MM-DD HH:mm">{{ exampleDate | amDateFormat:
                                            'YYYY-MM-DD HH:mm'}} </ng-option>
                                        <ng-option value="DATE_DD">{{ exampleDate | amDateFormat: 'DD'}} </ng-option>
                                        <ng-option value="DATE_MM">{{ exampleDate | amDateFormat: 'MM'}} </ng-option>
                                        <ng-option value="DATE_YYYY">{{ exampleDate | amDateFormat: 'YYYY'}}
                                        </ng-option>
                                    </ng-select>
                                </td>
                            </tr>
                            <tr
                                *ngIf="selectedColumn.dataType.startsWith('Int') || selectedColumn.dataType == 'Float' || selectedColumn.dataType == 'Formula'">
                                <td>{{'general.formatting' | translate }}
                                    <span class="error" *ngIf="formattingVar.invalid">*</span>
                                </td>
                                <td>
                                    <ng-select #formattingVar="ngModel" [(ngModel)]="selectedColumn.formatting"
                                        bindLabel="value" bindValue="value" class="form-control" name="formatting"
                                        groupBy="type" [clearable]="false" style="width: 100%;">
                                        <ng-template ng-optgroup-tmp let-item="item">
                                            {{ item.type }}
                                        </ng-template>
                                        <ng-option [value]="{type: 'Numeric', value: ''}">{{ 'general.rawValueFormatted'
                                            | translate:{ value: '3.141' } }}</ng-option>
                                        <ng-option [value]="{type: 'Numeric', value: 'NUMERIC_num0'}">3.141</ng-option>
                                        <ng-option [value]="{type: 'Numeric', value: 'NUMERIC_num1'}">3.141,<b>5</b>
                                        </ng-option>
                                        <ng-option [value]="{type: 'Numeric', value: 'NUMERIC_num2'}">3.141,<b>59</b>
                                        </ng-option>
                                        <ng-option [value]="{type: 'Numeric', value: 'NUMERIC_num3'}">3.141,<b>592</b>
                                        </ng-option>
                                        <ng-option [value]="{type: 'Time (from seconds)', value: 'NUMERIC_HH:mm:ss'}">
                                            <b>{{ exampleDate | amDateFormat: 'HH:mm:ss'}}</b>
                                        </ng-option>
                                        <ng-option [value]="{type: 'Time (from seconds)', value: 'NUMERIC_HH:mm'}"><b>{{
                                                exampleDate | amDateFormat: 'HH:mm'}}</b>:{{ exampleDate | amDateFormat:
                                            'ss'}} (Hours + Minutes)</ng-option>
                                        <ng-option [value]="{type: 'Time (from seconds)', value: 'NUMERIC_mm:ss'}">{{
                                            exampleDate | amDateFormat: 'HH'}}:<b>{{ exampleDate | amDateFormat:
                                                'mm:ss'}}</b> (Minutes + Seconds)</ng-option>
                                        <ng-option [value]="{type: 'Time (from seconds)', value: 'NUMERIC_H'}"><b>{{
                                                exampleDate | amDateFormat: 'HH'}}</b>:{{ exampleDate | amDateFormat:
                                            'mm:ss'}} (Hours)</ng-option>
                                        <ng-option [value]="{type: 'Time (from seconds)', value: 'NUMERIC_m'}">{{
                                            exampleDate | amDateFormat: 'HH'}}:<b>{{ exampleDate | amDateFormat:
                                                'mm'}}</b>:{{ exampleDate | amDateFormat: 'ss'}} (Minutes)</ng-option>
                                        <ng-option [value]="{type: 'Time (from seconds)', value: 'NUMERIC_X'}">{{
                                            exampleDate | amDateFormat: 'HH:mm'}}:<b>{{ exampleDate | amDateFormat:
                                                'ss'}}</b> (Seconds)</ng-option>

                                        <!-- Add date formatting for Formula specific -->
                                        <ng-container *ngIf="selectedColumn.dataType == 'Formula'">
                                            <ng-option [value]="{type: 'Date', value: 'DATE_lll'}">{{ exampleDate |
                                                amDateFormat: 'lll'}}</ng-option>
                                            <ng-option [value]="{type: 'Date', value: 'DATE_LLL'}">{{ exampleDate |
                                                amDateFormat: 'LLL'}}</ng-option>
                                            <ng-option [value]="{type: 'Date', value: 'DATE_LLLL'}">{{ exampleDate |
                                                amDateFormat: 'LLLL'}}</ng-option>
                                            <ng-option [value]="{type: 'Date', value: 'DATE_L'}">{{ exampleDate |
                                                amDateFormat: 'L'}}</ng-option>
                                            <ng-option [value]="{type: 'Date', value: 'DATE_LL'}">{{ exampleDate |
                                                amDateFormat: 'LL'}}</ng-option>
                                            <ng-option [value]="{type: 'Date', value: 'DATE_ll'}">{{ exampleDate |
                                                amDateFormat: 'll'}}</ng-option>
                                            <ng-option [value]="{type: 'Date', value: 'DATE_LT'}">{{ exampleDate |
                                                amDateFormat: 'LT'}}</ng-option>
                                            <ng-option [value]="{type: 'Date', value: 'DATE_LTS'}">{{ exampleDate |
                                                amDateFormat: 'LTS'}}</ng-option>
                                            <ng-option [value]="{type: 'Date', value: 'DATE_HH:mm'}">{{ exampleDate |
                                                amDateFormat: 'HH:mm'}}</ng-option>
                                            <ng-option [value]="{type: 'Date', value: 'DATE_YYYY-MM-DD HH:mm'}">{{
                                                exampleDate | amDateFormat:
                                                'YYYY-MM-DD HH:mm'}}</ng-option>
                                            <ng-option [value]="{type: 'Date', value: 'DATE_DD'}">{{ exampleDate |
                                                amDateFormat: 'DD'}}</ng-option>
                                            <ng-option [value]="{type: 'Date', value: 'DATE_MM'}">{{ exampleDate |
                                                amDateFormat: 'MM'}}</ng-option>
                                            <ng-option [value]="{type: 'Date', value: 'DATE_YYYY'}">{{ exampleDate |
                                                amDateFormat: 'YYYY'}}</ng-option>
                                        </ng-container>
                                    </ng-select>
                                </td>
                            </tr>
                            <tr *ngIf="selectedColumn.dataType == 'Boolean'">
                                <td>{{'general.formatting' | translate }}
                                    <span class="error" *ngIf="formattingVar.invalid">*</span>
                                </td>
                                <td>
                                    <div style="display: flex;">
                                        <ng-select #formattingVar="ngModel" [(ngModel)]="selectedColumn.formatting"
                                            bindLabel="name" bindValue="name" class="form-control" name="formatting"
                                            groupBy="type" [clearable]="false" style="width: 100%;">
                                            <ng-option value="">True/False</ng-option>
                                            <ng-option value="BOOL_onoff">On/Off</ng-option>
                                            <ng-option value="BOOL_highlow">High/Low</ng-option>
                                            <ng-option value="BOOL_openclosed">Open/Closed</ng-option>
                                            <ng-option value="BOOL_enableddisabled">Enabled/Disabled</ng-option>
                                            <ng-option value="BOOL_binary">1/0</ng-option>
                                        </ng-select>
                                        <div [tooltip]="'Invert output'" class="fa_checkbox"
                                            style="margin-top: 3px; margin-left: 1rem;">
                                            <input [checked]="false" id="inverted" type="checkbox" name="inverted"
                                                [(ngModel)]="selectedColumn.inverted" />
                                            <label for="inverted">
                                            </label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
            <div class="modal-footer">
                <button
                    *ngIf="selectedColumn.dataType.startsWith('Int') || selectedColumn.dataType == 'Float' || selectedColumn.dataType ==  'Formula'"
                    type="button" class="btn btn-info" (click)="availableColumns = true" style="min-width: 120px;"><i
                        class="fas fa-fw fa-table-columns"></i>
                    {{ 'general.showColumns' | translate }}</button>
                <button type="button" class="btn btn-primary" (click)="saveColumn(selectedColumn)"
                    [disabled]="columnForm.invalid" style="min-width: 120px;"><i class="fa  fa-fw fa-save"></i>
                    {{ 'general.save' | translate }}</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="availableColumns" [config]="{ show: true, backdrop: true, ignoreBackdropClick: true }"
    (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left">
                    {{ 'general.columns' | translate }}
                </h5>
                <button type="button" class="close float-right" aria-label="Close" (click)="availableColumns = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #saveTemplateForm="ngForm">
                    <table style="width: 100%; margin-bottom: 0;" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_content">
                                    <b>{{ 'general.source' | translate }}</b>
                                </td>
                                <td class="row_opening">
                                    <b>{{ 'general.name' | translate }}</b>
                                </td>
                                <td class="row_content">
                                    <b>{{ 'general.type' | translate }}</b>
                                </td>
                            </tr>
                            <tr *ngFor="let column of formulaColumns">
                                <td class="row_content">
                                    [{{ column.entity }}.{{ column.source }}]
                                </td>
                                <td class="row_opening">
                                    {{ column.name }}
                                </td>
                                <td class="row_content">
                                    {{column.dataType}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    </div>
</div>