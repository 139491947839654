import { Component, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BasicTriggerCase, BatchAction, InstantReportSelection } from 'app/common/enums';
import { ReportService } from 'app/services/reports/report.service';
import { BsDatepickerConfig, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import * as moment from 'moment-timezone';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { getDefaultDpConfig } from 'app/common/gridhelper';

window['moment'] = Moment;
mTZ()

@Component({
    selector: 'fh-batch-instant-report',
    templateUrl: 'instantReport.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchInstantReportComponent {
    step: number;
    @Output() onFinish = new EventEmitter();
    @Input() gridSelection = [];
    disabled = true;
    loadingDeviceDetails = false;
    logItem;


    groupForm = new FormGroup({
        instantReportType: new FormControl(null, Validators.required),
        templateId: new FormControl(null, Validators.required),
        dateSelection: new FormControl([
            Moment()
                .subtract(7, "day")
                .startOf("day")
                .toDate(),
            Moment().add(0, "days").endOf("day").toDate(),
        ], Validators.required),
    });

    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();

    reports: { id: string; name: any; }[];
    reportTemplatesAll = [];
    reportTemplates = [];
    template;
    timezoneIana: string;

    get formControls() {
        return this.groupForm.controls as unknown as FormGroup[];
    }

    constructor(private authenticationService: AuthenticationService, private cd: ChangeDetectorRef, private reportService: ReportService, private translateService: TranslateService) {
        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

        this.step = 1;
        this.timezoneIana = this.authenticationService.getTimeZoneIana();

        this.reports = Object.keys(InstantReportSelection)
            .filter(k => typeof InstantReportSelection[k] === 'string')
            .map(n => ({
                id: n.toString(),
                name: this.translateService.instant('enums.instantReportSelection.' + n)
            }));
    }

    initFinish() {
        // Fetch all data and make sure it can be saved
        console.log(1);
    }

    onCheckOutFinish() {
        var template = this.applyColumnConfiguration();

        var result = {
            template: template,
            instantReportType: this.groupForm.value.instantReportType,
            reportStartDate: moment.utc(this.groupForm.value.dateSelection[0]).tz(this.timezoneIana).startOf('day').unix(),
            reportEndDate: moment.utc(this.groupForm.value.dateSelection[1]).tz(this.timezoneIana).endOf('day').unix(),
        }

        console.log(result);

        this.onFinish.emit({
            status: 'success', object: result, log: this.logItem
        })
        this.step = 1;
    }

    initForm() {
        this.step = 1;
        console.log('init tab');

        this.reportService.getReportTemplates().subscribe(result => {
            this.reportTemplatesAll = result;
        })
    }


    applyColumnConfiguration() {
        if (this.groupForm.value.instantReportType == 2) {
            this.template = 'default';
        }

        // Find template
        const reportTemplate = this.reportTemplatesAll.find(x => x.id === this.groupForm.value.templateId);

        // Set properties
        return reportTemplate;
    }

    prepareFilters() {
        this.groupForm.patchValue({ templateId: null });

        this.reportTemplates = this.reportTemplatesAll.filter(x => x.reportType.toString() === this.groupForm.value.instantReportType?.toString());

        // Set required templateId when TWD
        if (this.groupForm.value.instantReportType == 2) {
            this.groupForm.patchValue({ templateId: -1 });
        }

        // Check for default template
        const defaultTemplate = this.reportTemplates.find(x => x.isDefault === true);
        if (defaultTemplate) {
            this.groupForm.patchValue({ templateId: defaultTemplate.id });
        }

        // [
        //     Moment()
        //       .subtract(this.selectedReport.defaultDayCount, "day")
        //       .startOf("day")
        //       .toDate(),
        //     Moment().add(0, "days").endOf("day").toDate(),
        //   ]
    }
}
