<div class="ibox-content slick" style="position:relative;">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!maintenanceOccurrence">
            <tr>
                <td colspan="2">
                    {{ 'general.noMaintenance' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="maintenanceOccurrence">
            <tr>
                <td class="row_opening">
                    {{ 'general.remarks' | translate}}
                    <span class="error" *ngIf="remarks.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" [name]="'remarks_' + identifier" required
                        class="form-control table-inline" [(ngModel)]="maintenanceOccurrence.remarks"
                        placeholder="{{ 'placeholder.remarks' | translate}}" #remarks="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.partNumber' | translate}}
                    <span class="error" *ngIf="partNumber.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" [name]="'partNumber_' + identifier"
                        placeholder="{{ 'placeholder.partNumber' | translate}}" class="form-control table-inline"
                        [(ngModel)]="maintenanceOccurrence.partNumber" #partNumber="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.quantity' | translate}}
                    <span class="error" *ngIf="quantity.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="number" [readonly]="formMode == 1" [name]="'quantity_' + identifier"
                        placeholder="{{ 'placeholder.quantity' | translate}}" class="form-control table-inline"
                        [(ngModel)]="maintenanceOccurrence.quantity" #quantity="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.unitPrice' | translate}}
                    <span class="error" *ngIf="unitPrice.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" [name]="'unitPrice_' + identifier"
                        placeholder="{{ 'placeholder.unitPrice' | translate}}" class="form-control table-inline"
                        [(ngModel)]="maintenanceOccurrence.unitPrice" required pattern="\d+\.?\d*"
                        title="Only numbers and 1 dot" #unitPrice="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.discount' | translate}}
                    <span class="error" *ngIf="discount.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" [name]="'discount_' + identifier"
                        class="form-control table-inline" [(ngModel)]="maintenanceOccurrence.discount"
                        placeholder="{{ 'placeholder.discount' | translate}}" pattern="\d+\.?\d*"
                        title="Only numbers and 1 dot" #discount="ngModel">
                </td>
            </tr>
        </tbody>
    </table>
</div>