import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { DistanceUnits } from './enums';
import { kmToMilesMultiplier } from './globals';

@Injectable()
export class DistanceUnitService {

    multiplier = 1;
    distanceUnit = DistanceUnits.Kilometers.valueOf();

    constructor(private translate: TranslateService) {
    }

    setUnit(unit) {
        this.distanceUnit = +unit

        if (this.distanceUnit !== DistanceUnits.Kilometers) {
            this.multiplier = kmToMilesMultiplier;
            console.log('setting multiplier ' + this.multiplier);
        } else {
            console.log('setting multiplier ' + this.multiplier);
        }
    }

    getDistanceUnit(translate: boolean = false): string {
        if (this.distanceUnit === DistanceUnits.Kilometers) {
            return translate ? this.translate.instant('general.km') : 'general.km';
        } else {
            return translate ? this.translate.instant('general.miles') : 'general.miles';
        }
    }

    getDistanceUnitPerHour(translate: boolean = false): string {
        if (this.distanceUnit === DistanceUnits.Kilometers) {
            return translate ? this.translate.instant('general.kmh') : 'general.kmh';
        } else {
            return translate ? this.translate.instant('general.mph') : 'general.mph';
        }
    }

    getDistanceUnitSpeedUoM(): string {
        if (this.distanceUnit === DistanceUnits.Kilometers) {
            return 'kph';
        } else {
            return 'mph';
        }
    }

    getDistanceUnitOdo(translate: boolean = false): string {
        if (this.distanceUnit === DistanceUnits.Kilometers) {
            return translate ? this.translate.instant('general.odo') : 'general.odo';
        } else {
            return translate ? this.translate.instant('general.odoInMiles') : 'general.odoInMiles';
        }
    }

    calculateDistanceUnitFromKmFixed(distanceInKm: number, precision = 2): number {
        if (this.distanceUnit === DistanceUnits.Kilometers) {
            return distanceInKm;
        } else {
            return +(distanceInKm * this.multiplier).toFixed(precision);
        }
    }

    calculateDistanceUnitFromKm(distanceInKm: number): number {
        if (this.distanceUnit === DistanceUnits.Kilometers) {
            return distanceInKm;
        } else {
            return +(distanceInKm * this.multiplier);
        }
    }

    calculateDistanceUnitFromKmString(distanceInKm: number, precision = 0): string {
        if (this.distanceUnit === DistanceUnits.Kilometers) {
            return distanceInKm.toString();
        } else {
            return (distanceInKm * this.multiplier).toFixed(precision);
        }
    }
}

