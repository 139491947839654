export abstract class Group {
    public id: string;
    public displayName: string;
    public name: string;
    public showAll = false;
    public firstName: any;
    public lastName: any;
    public userId: any;
    public ownerId: any;
    public itemCount: number;
    public timestamp: any;
    public accountId: any;
    public companyName: any;
    public resellerId: any;
    public resellerDescription: any;
    public items: any = [];
    public reference: any;
    public deviceId: any;
}

export class DriverGroup extends Group {
    public driverGroupItems: any[];
    public groupType: number;
    public parentGroupId: number;
    public parentGroupName: string;
}

export class GeofenceGroup extends Group {
    public leaveUserContactGroupId: number;
    public enterUserContactGroupId: number;
    public violateInsideThresholdUserContactGroupId: number;
    public insideThresholdInSeconds: any;
    public violateOutsideThresholdUserContactGroupId: number;
    public outsideThresholdInSeconds: any;
    public violateOutsideAllGeofencesThresholdUserContactGroupId: number;
    public outsideAllThresholdsInSeconds: any;
    public notifyOnEventTypeOccurenceUserContactGroupId: number;
    public notifyOnEventTypeOccurrence: number;
    public selectedDevices: any;
    public devicesCount: number;
    public geofenceGroupItems: any[];
}

export class AssetGroup extends Group {
    public assetGroupItems = [];
    public emailLoginName: string;
    public groupType: string;
    public color: string;
}

export abstract class GroupItem {
    public id: string;
    public name: string;
    public companyName: any;
    public accountId: any;
    public timestamp: any;
    public startDate: any;
    public endDate: any;
    public displayName: string;
    public groupType;
}

export class DriverGroupItem extends GroupItem {
}

export class GeofenceGroupItem extends GroupItem {
    public color: any;
    public geoJson: any;
    public radius: any;
}

export class AssetGroupItem extends GroupItem {
    public deviceId: string;
    public color: string;
    public unitId: string;
    public assetSearchName: any;
}
