import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageType } from 'app/common/enums';
import { StorageHelper } from 'app/common/storagehelper';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

@Component({
    selector: 'fh-notifications-navigation',
    templateUrl: 'notificationsNavigation.template.html'
})

export class NotificationNavigationComponent implements OnInit {
    sub: any;
    permissions: {};
    activeId;
    storageType = StorageType.LocalStorage;
    hideTags = false;
    hideTriggers = false;

    constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService, private storageHelper: StorageHelper) {
        this.permissions = this.authenticationService.permissions;

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideTags').subscribe((result) => {
            this.hideTags = JSON.parse(result) === true;
        });

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideTriggers').subscribe((result) => {
            this.hideTriggers = JSON.parse(result) === true;
        });
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.activeId = id;
        });
    }

    activeRoute(routename: string): boolean {
        const active = this.router.url.indexOf(routename) !== -1;
        return active;
    }

    isUserContacts() {
        const routes = ['UserContact'];

        let routeActive = false;

        routes.forEach(route => {
            const active = this.router.url.indexOf(route);
            if (active > -1) {
                routeActive = true;
            }
        });

        return routeActive;
    }

    isTriggerDetails() {
        const routes = ['TriggerDetails'];

        let routeActive = false;

        routes.forEach(route => {
            const active = this.router.url.indexOf(route);
            if (active > -1) {
                routeActive = true;
            }
        });

        return routeActive;
    }
}
