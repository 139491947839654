<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/Overview">{{'general.notifications' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/UserContactGroups/Overview">{{'general.userContactGroups' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.details' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-notifications-navigation></fh-notifications-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">

                <form #userForm="ngForm">
                    <div class="ibox" style="position: relative;">
                        <ngx-loading [show]="loading || saving"></ngx-loading>
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-address-book" style="margin-right: 0px;"></i>
                                {{'general.userContactGroup' | translate }}
                            </h5>
                        </div>
                        <fh-user-contact-group-details [userContactGroup]="userContactGroup" [formMode]="formMode">
                        </fh-user-contact-group-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()"
                            [allowEdit]="permissions['UserContactGroups_Edit']" (onDelete)="onDelete()"
                            [allowDelete]="permissions['UserContactGroups_Delete']" (setFormMode)="setFormMode($event)"
                            [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">

                <form #userForm="ngForm">
                    <fh-user-contact-selector [userContacts]="userContacts" [formMode]="formMode"
                        [loading]="loadingUserContacts">
                    </fh-user-contact-selector>
                </form>

                <fh-tagging [objectId]="userContactGroup?.id" [objectType]="tagType">
                </fh-tagging>

            </div>
        </div>
    </div>
</fh-permission-handler>