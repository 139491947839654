<div class="ibox" style="position:relative">
    <ngx-loading [show]="loading"></ngx-loading>
    <div class="ibox-title">
        <h5>
            <i class="fas fa-fw fa-car-mechanic"></i> {{'general.assetTypes' | translate }} ({{activeCount}})
        </h5>
        <span class="float-right">
            <div class="filter-form d-none d-md-block">
                <input name="filter" type="text" class="form-control table-inline top-filter d-none d-md-block"
                    (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
            </div>
        </span>
        <!-- <span class="float-right">
            <i class="fa fa-fw fa-cog pointer" [routerLink]="['/Settings/AssetTypes/Overview']"></i>
        </span> -->
    </div>
    <div class="ibox-content slick" style="position:relative;">
        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
            <ng-container *ngIf="assetTypes?.length > 0; else noTableData">
                <thead>
                    <tr>
                        <th style="width:75px" *ngIf="!isDisplayOnly">
                        </th>
                        <th style="width:auto">
                            {{ 'general.name' | translate}}
                        </th>
                        <th style="width:100px">
                            {{ 'general.assetCount' | translate}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let assetType of assetTypes | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage }">
                        <td style="width:25px" *ngIf="!isDisplayOnly">
                            <div class="fa_checkbox">
                                <input [name]="'id_' + assetType.id" [id]="'id_' + assetType.id" type="checkbox"
                                    [disabled]="formMode == 1" [(ngModel)]="assetType.maintenanceTypeChecked" />
                                <label [for]="'id_' + assetType.id"></label>
                            </div>
                        </td>
                        <td style="width:auto">
                            {{ assetType.name }}
                        </td>
                        <td style="width:100px">
                            {{assetType.assetCount}}
                        </td>
                    </tr>
                </tbody>
            </ng-container>
            <ng-template #noTableData>
                <tbody>
                    <tr>
                        <td colspan="2">
                            {{ 'general.noAssetTypes' | translate}}
                        </td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
    </div>
    <div class="ibox-footer noselect dataTables_wrapper">
        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                <ul class="pagination">
                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{ 'grid.previous' |
                            translate }}</a>
                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                    </li>

                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                        [class.current]="p.getCurrent() === page.value">
                        <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                            <span>{{ page.label }}</span>
                        </a>
                        <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() === page.value">
                            <span style="font-weight: bold;">{{ page.label }}</span>
                        </a>
                    </li>

                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                            {{ 'grid.next' | translate }}
                        </a>
                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                    </li>
                </ul>
            </div>
        </pagination-template>
        <div style="clear:both"></div>
    </div>
</div>