import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import * as L from 'leaflet';
import { icon, Map, marker, Marker } from 'leaflet';
import { DeviceService } from '../../services/device/device.service';
import { TripService } from '../../services/trip/trip.service';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { getDefaultDpConfig } from 'app/common/gridhelper';

import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';
import { FhChartService } from 'app/services/charts/charts.service';

import { colorArray2, roundAsString, getIconPath, MovingMarkerHelper } from 'app/common/globals';

import 'leaflet-polylinedecorator';

import '../../../../vendor/leaflet-movingmarker/leaflet-movingmarker.js';
import '../../../../vendor/leaflet-numbermarker/leaflet-numbermarker.js';

import { AccountService } from 'app/services/account/account.service';
import { DownloadService } from 'app/services/common/download.service';
import { TranslateService } from '@ngx-translate/core';

import * as XLSX from 'xlsx-js-style';
type AOA = any[][];

import * as Highcharts from 'highcharts';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { drawGeofence } from 'app/common/leafletGlobals';
import { StorageType } from 'app/common/enums';
import { StorageHelper } from 'app/common/storagehelper';
import { LocationService } from 'app/services/locations/locations.service';
import { DriverService } from 'app/services/driver/driver.service';
import { DistanceUnitService } from 'app/common/distanceunit.service';

window['moment'] = Moment;
mTZ()

@Component({
    selector: 'fh-device-shared-trip-detials',
    templateUrl: 'sharedTripDetails.template.html',
    providers: [FhChartService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger(
            'enterAnimation', [
            transition(':enter', [
                // css styles at start of transition
                style({ opacity: 0 }),

                // animation and styles at end of transition
                animate('0.5s', style({ opacity: 1 }))
            ]),
        ]
        )
    ],
})
export class DeviceSharedTripDetailsViewComponent implements OnInit, OnDestroy {
    Highcharts: typeof Highcharts = Highcharts;

    @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

    loading: boolean;
    tripMapLocations;
    selectedTrip: any;
    zone: any;
    menuState: string;
    map: any;
    marker: Marker;
    options;
    timeoutHandler;
    trips: any[];
    sub;
    device: Device;
    layersControl;
    success;

    tripPolyLineDict = {};

    colorArray = colorArray2;
    itemsPerPage = 10;
    colorIdx = 0;

    showMapOnSide = true;

    filter;
    filter2;

    hasAsset = false;
    loadingDetails = false;
    mapBusy = false;
    loadingTrips = false;
    deviceId;
    // Daterange
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    daterangepickerModel: Date[];
    maxDate = new Date();
    mapHeight = 300;

    // Pagination
    totalItems = 0;
    currentPage = 1;
    currentPageDebug = 1;
    smallnumPages = 0;
    error: any;
    tripLocations = [];
    showLocations = false;
    speedChart: any;

    currentDate;

    timezoneIana;

    allTripLocations = [];
    geofences = [];
    theGeofence;

    fileName = 'TripLocations.xlsx';

    storageType = StorageType.LocalStorage;

    loadingGeofences = false;
    theMarker: any;
    movingMarkerIcon: L.Icon<L.IconOptions>;

    permissions: {};
    startMarker: L.Marker<any>;
    endMarker: L.Marker<any>;
    myMovingMarker: any;
    stopMarker: L.Marker<any>;
    scannedDriver: any;

    showAbuseIcons = false;

    constructorName = 'DeviceTripsViewComponent';

    speedCalculator = 4;
    rangeValue: number;
    translatedKm: any = "km";
    translatedKmh: any = "km/h";

    sliderOptions = {};

    constructor(private downloadService: DownloadService, private distance: DistanceUnitService, private driverService: DriverService, private locationService: LocationService, private accountService: AccountService, private chartService: FhChartService, private tripService: TripService, private cd: ChangeDetectorRef, private http: HttpClient, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService, private storageHelper: StorageHelper, private translateService: TranslateService) {
        this.permissions = this.authenticationService.permissions;

        this.device = null;
        this.trips = [];
        this.tripLocations = [];

        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(1, 'weeks').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

        this.translateService.get('general.date').subscribe(
            data => {
                this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
                this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
                this.cd.markForCheck();
            }
        );
    }

    ngOnInit() {
        this.device = new Device;
        this.device.id = ''
        this.loading = true;
        this.cd.markForCheck();

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            // Get trip by id
            this.tripService.getTripDetails(id, true).subscribe(result => {

                this.error = null;

                this.selectedTrip = result;

                this.tripLocations = result.messages.sort((a, b) => b.timestamp - a.timestamp);

                this.allTripLocations[0] = this.tripLocations;

                // If active trip
                this.scannedDriver = null;
                if (result.tripType == 3) {
                    this.locationService.getDeviceStates([this.deviceId], null, null, 0, false, true).subscribe(deviceState => {
                        if (deviceState.deviceStates[0]?.tagScanStatus?.tag) {
                            this.driverService.getDriverByTag(deviceState.deviceStates[0]?.tagScanStatus?.tag, this.device.accountId).subscribe(res => {
                                this.scannedDriver = res;
                                this.cd.markForCheck();
                            });
                        }
                    });
                }

                this.drawChart(this.allTripLocations);

                this.loadingDetails = false;
                this.cd.markForCheck();

                this.displayTrip(result, result, result.tripType === 3)

                this.selectedTrip.concatenatedEvents = result.concatenatedEvents;

                this.deviceId = result.deviceId;
                this.deviceService.getDeviceById(result.deviceId, true).subscribe(device => {
                    this.device = device;

                    const iconPath = getIconPath(this.device.asset?.icon)[1];

                    this.movingMarkerIcon = L.icon({
                        iconUrl: iconPath,
                        // className: 'markerPlayTrip',
                        iconAnchor: [16, 16],
                    });

                    if (this.device && this.device.id) {
                        this.getGeofences();
                    }

                    this.loading = false;
                    this.cd.markForCheck();
                });

            });

        }, error => {
            this.loadingDetails = false;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    actualRound(value, decimals) {
        return roundAsString(value, decimals);
    }

    resetDate() {
        this.currentDate = null;
        return false;
    }

    getGeofences() {
        if (this.geofences.length === 0 && this.device.accountId != null && this.device.accountId > 0) {
            this.loadingGeofences = true;
            this.accountService.getGeofencesByAccount(this.device.accountId, true).subscribe(geofences => {
                this.geofences = geofences;
                this.loadingGeofences = false;
                this.cd.markForCheck();
            });
        }
    }

    ngOnDestroy() {
        if (this.timeoutHandler) {
            console.log('Distroy timeouthandler');
            clearInterval(this.timeoutHandler);
        }
    }


    // Drawing multiple trip
    playTrips() {
        let flattenedLocations = [];

        Object.values(this.allTripLocations).forEach(trip => {
            flattenedLocations = flattenedLocations.concat(Object.values(trip));
        });

        flattenedLocations.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

        this.prepareMovingMarker(flattenedLocations);
    }

    pauseTrip() {
        this.myMovingMarker?.pause();
    }

    resumeTrip() {
        this.myMovingMarker?.resume();
    }

    stopTrip() {
        this.myMovingMarker?.stop();
    }

    outputUpdate() {
        this.myMovingMarker?.setcurpos(this.rangeValue / 100);
    }

    speedChanged() {
        this.stopTrip();
    }

    prepareMovingMarker(flattenedLocations) {

        this.clearLocation();

        const latlngs = [];
        const locationsTimeDelta = [];

        // Remove locations
        this.leafletMapComponent.locationLayer.clearLayers()

        let timestamp = flattenedLocations.length && Moment.utc(flattenedLocations[0].timestamp);

        flattenedLocations.forEach(location => {

            const speed = Moment.utc(location.timestamp).diff(timestamp) / 1000;
            timestamp = Moment.utc(location.timestamp);

            if (location.latitude !== 0 && location.longitude !== 0) {
                latlngs.push(new L.LatLng(location.latitude, location.longitude));
                locationsTimeDelta.push(speed * this.speedCalculator);
            }
        });

        const context = (value: any) => {
            this.myMovingMarker = value;
        }

        const movingMarker = new MovingMarkerHelper(context, L, this.leafletMapComponent.map, this.movingMarkerIcon, this.timezoneIana);
        movingMarker.setLocations(latlngs, flattenedLocations, locationsTimeDelta);
        movingMarker.onDestroy = () => this.cd.markForCheck();

        movingMarker.run();

        const bounds = this.leafletMapComponent.tripLayer.getBounds();

        if (bounds.isValid()) {
            this.leafletMapComponent?.map?.flyToBounds(bounds, { padding: [15, 15], animate: true, duration: 0.5 });
        }
    }

    showLocationDetails() {
        this.mapBusy = true;

        const tripLocations = [];
        Object.values(this.allTripLocations).forEach(trip => {
            Object.values(trip).forEach(location => {
                tripLocations.push(location);
            });
        });

        this.leafletMapComponent?.locationLayer?.clearLayers();

        if (tripLocations && tripLocations.filter(x => x.hasGpsFix === true).length > 1) {

            const startLocation = tripLocations[0];

            for (let index = 1; index < tripLocations.length; index++) {
                const location = tripLocations[index];

                if (location.latitude !== 0 && location.latitude !== 0) {

                    let markerIcon;

                    if (location.hasGpsFix) {
                        markerIcon = L['StatusMarker'].icon({
                            iconSize: [20, 20],
                            iconAnchor: [10, 10],
                            shadowSize: [0, 0],
                            shadowAnchor: [0, 0],
                            icon: 'fa-arrow-circle-up',
                            prefix: 'fa',
                            rotate: location.heading ?? 0,
                            iconColor: 'green',
                            className: 'm360_12',
                        });
                    } else {
                        markerIcon = L['StatusMarker'].icon({
                            iconSize: [20, 20],
                            iconAnchor: [10, 10],
                            shadowSize: [0, 0],
                            shadowAnchor: [0, 0],
                            icon: 'fa-rss',
                            prefix: 'fa',
                            iconColor: 'green',
                            className: 'm360_12',
                        });
                    }

                    const customPopup = `<div style="width:300px; overflow: auto;" class="leaflet-mappopup">
                            <div class="header">` + 'Location' + `</div>
                            <div class="content">` + 'Time' + `:</div><div class="content">` + Moment.utc(location.timestamp)['tz'](this.timezoneIana).format('YYYY-MM-DD HH:mm:ss') + `</div>
                            <div class="content">` + 'Heading' + `:</div><div class="content">` + location.heading + ` °</div>
                            <div class="content">` + 'Speed' + `:</div><div class="content">` + location.speed + ` km/h</div>
                            ${(location.fuelLevel ? `<div class="content">` + 'Fuel' + `:</div><div class="content">` + location.fuelLevel + `%</div>` : '')}
                            ${(location.temperature1 ? `<div class="content">` + 'Temperature' + `:</div><div class="content">` + location.temperature1 + ` °C</div>` : '')}
                            ${(location.temperature2 ? `<div class="content">` + 'Temperature' + `:</div><div class="content">` + location.temperature2 + ` °C</div>` : '')}
                            ${(location.temperature3 ? `<div class="content">` + 'Temperature' + `:</div><div class="content">` + location.temperature3 + ` °C</div>` : '')}
                            ${(location.temperature4 ? `<div class="content">` + 'Temperature' + `:</div><div class="content">` + location.temperature4 + ` °C</div>` : '')}
                            <div class="content">` + 'Location' + `:</div><div class="content">` + location.latitude.toFixed(5) + ` / ` + location.longitude.toFixed(5) + `</div>
                        </div>`;

                    const locationMarker = L.marker(new L.LatLng(location.latitude, location.longitude), { icon: markerIcon });
                    locationMarker.bindPopup(L.popup().setContent(customPopup), {
                        closeButton: false
                    });
                    locationMarker.addTo(this.leafletMapComponent.locationLayer);
                    this.leafletMapComponent.oms.addMarker(locationMarker);
                }
            }
        }

        this.mapBusy = false;
        this.cd.markForCheck();
    }

    displayLocation(location, geofence = null, episode = null) {

        // beginLongitude
        this.clearLocation();

        if (location != null && geofence == null) {

            let theEpisodeIcon = null;

            if (episode) {
                theEpisodeIcon = L['StatusMarker'].icon({
                    // iconUrl: iconPath,
                    icon: episode.icon,
                    markerColor: episode.markerColor,
                    rotate: 0,
                    shape: 'circle',
                    prefix: 'fas'
                });
            }

            this.theMarker = marker(location, { icon: episode ? theEpisodeIcon : this.movingMarkerIcon }).addTo(this.leafletMapComponent.map);
            this.leafletMapComponent.map.setView(location, 15);
        }

        if (location != null && geofence != null) {

            // Draw geofence
            if (this.theGeofence) {
                this.leafletMapComponent.map.removeLayer(this.theGeofence);
            }

            drawGeofence(L, geofence, this.leafletMapComponent.map, geofence.color);
            this.theGeofence = geofence.layerRef;

            setTimeout(() => {
                // Get accounts again
                this.leafletMapComponent.map.removeLayer(this.theGeofence);
            }, 4000);

            const bounds = geofence.layerRef && geofence.layerRef.getBounds();

            if (bounds) {
                this.leafletMapComponent?.map?.flyToBounds(bounds, { padding: [15, 15] });

                this.theMarker = marker(location, { icon: this.movingMarkerIcon }).addTo(this.leafletMapComponent.map);
            }
        }
    }

    clearLocation() {
        if (this.theMarker) {
            this.leafletMapComponent.map.removeLayer(this.theMarker);
        }

        if (this.myMovingMarker) {
            this.leafletMapComponent.map.removeLayer(this.myMovingMarker);
        }

        if (this.stopMarker) {
            this.leafletMapComponent.map.removeLayer(this.stopMarker);
        }
    }

    displayTrip(trip, selection, isOngoing = false) {

        const tripLocations = trip.messages

        const pointList = [];

        const gpsFixLocations = tripLocations.filter(x => x.hasGpsFix === true);

        if (gpsFixLocations.length > 0) {

            gpsFixLocations.forEach(location => {
                if (location.latitude !== 0) {
                    pointList.push(new L.LatLng(location.latitude, location.longitude));
                }
            });

            const icon = this.device.asset?.icon

            const tripFeatureGroup = this.tripService.drawTrip(trip, selection.ident, this.colorArray, isOngoing, icon);

            tripFeatureGroup.addTo(this.leafletMapComponent.tripLayer);

            const bounds = tripFeatureGroup.getBounds();

            this.tripPolyLineDict[selection.id] = tripFeatureGroup;

            this.leafletMapComponent.eventsLayer.clearLayers();
            if (this.showAbuseIcons) {
                console.log('display episodes');
                this.addEpisodesToMap(trip, this.leafletMapComponent.eventsLayer);
            }

            if (bounds.isValid()) {
                this.leafletMapComponent?.map?.flyToBounds(bounds, { padding: [30, 30], animate: true, duration: 0.5 });
            }
        } else {
            this.error = 'No data to display';
        }
    }

    public addEpisodesToMap(trip, layer) {
        trip.episodes.forEach(episode => {
            this.addEpisodeToMap(episode, layer);
        });
    }

    public addEpisodeToMap(episode, layer) {
        const displayAsItem = false;
        let episodeMarker = undefined;

        if (episode.displayAsLabel) {
            const theEpisodeIcon = L['StatusMarker'].icon({
                // iconUrl: iconPath,
                icon: episode.icon,
                markerColor: episode.markerColor,
                rotate: 0,
                shape: 'square',
                prefix: 'fas'
            });

            episodeMarker = L.marker([episode.beginLatitude, episode.beginLongitude], { icon: theEpisodeIcon }).addTo(layer);
            this.leafletMapComponent.oms.addMarker(episodeMarker);

        } else {
            const circle = new L.Circle(new L.LatLng(episode.beginLatitude, episode.beginLongitude), {
                radius: 40,
                fillColor: '#ff0000',
                color: 'red',
                fillOpacity: 0.2,
                weight: 4,
                opacity: 0.8
            });

            circle.addTo(layer);
            episodeMarker = circle;
        }

        const direction = 'bottom';
        const offset = L.point(0, 0);

        const label = episode.description;

        episodeMarker.bindTooltip(label, { permanent: false, direction: direction, opacity: 0.9, offset: offset });
    }

    onMapReady(map) {
        setTimeout(() => {
            this.leafletMapComponent.invalidateSize();
        }, 10);
    }

    onMapResized() {
        setTimeout(() => {
            this.leafletMapComponent.centerMap();
        }, 200);
    }

    drawLocation(location) {
        setTimeout(() => {
            if (location) {

                if (this.marker) {
                    this.map.removeLayer(this.marker);
                }

                this.marker = marker([location.latitude, location.longitude], {
                    icon: icon({
                        iconSize: [25, 41],
                        iconAnchor: [13, 41],
                        iconUrl: 'assets/marker-icon.png',
                        shadowUrl: 'assets/marker-shadow.png'
                    })
                });

                this.map.addLayer(this.marker);
                this.map.setView([location.latitude, location.longitude], 6, { animate: true, duration: 0.5 });
            }
        }, 100);
    }

    drawChart(trips) {

        this.speedChart = this.tripService.generateChart(trips, this.translateService, this.leafletMapComponent?.map, this.chartService, this.device?.asset?.icon)

        this.cd.markForCheck();
    }

    showReport() {

    }
}
