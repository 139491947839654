import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { ConfirmationModalComponent } from './confirmationModal.component';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { getDefaultDpConfig } from 'app/common/gridhelper';

@Component({
    selector: 'fh-rerun-report-modal',
    templateUrl: 'rerunReportModal.template.html',
})

export class RerunReportModalComponent implements OnChanges, OnInit {
    @Input() reportSubscription;
    @Input() timezoneIana: string;
    @Input() daterangepickerModel: Date[];

    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    permissions: {};
    public includeExecutedSql;

    constructor(@Inject(ConfirmationModalComponent) private parent: ConfirmationModalComponent, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
        this.timezoneIana = this.authenticationService.getTimeZoneIana();

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);
        this.dpConfig.withTimepicker = true;
        this.dpConfig.rangeInputFormat = 'lll';

        this.permissions = this.authenticationService.permissions;

        //Set daterange
        // this.daterangepickerModel = [
        //     Moment().subtract(7, 'day').startOf('day').toDate(),
        //     Moment().add(0, 'days').endOf('day').toDate()
        // ];
    }
    ngOnInit(): void {
        this.parent.invalid = true;
    }

    ngOnChanges(): void {
        // console.log(this.reportSubscription);

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(7, 'days').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).subtract(0, 'days').endOf('day').toDate()
        ];

        this.dateChanged(this.daterangepickerModel);
    }

    dateChanged(event) {

        var date = new Date(this.daterangepickerModel[1].getTime());

        if (date.getHours() == 0 && date.getMinutes() == 0 && date.getSeconds() == 0) {
            date.setHours(23);
            date.setMinutes(59);
            date.setSeconds(59);

            this.daterangepickerModel = [
                this.daterangepickerModel[0]
                , date];

            this.cd.markForCheck();
        }

        if (event === null || event === undefined) {
            this.parent.invalid = true;
            return;
        }
        if (event === 'hidden') {
            return;
        }

        this.daterangepickerModel = event;
        this.parent.invalid = false;
    }
}
