<ng-container *ngIf="showIbox">
    <div class="ibox" style="position:relative">
        <ngx-loading [show]="loading"></ngx-loading>
        <div class="ibox-title">
            <h5 *ngIf="name">
                <i class="fas fa-fw fa-list-ul"></i> {{ name }}
                <i [tooltip]="tooltip | translate" class="fas fa-fw fa-question"></i>
            </h5>
            <span class="float-right">
                <div class="filter-form d-none d-md-block">
                    <input type="text" class="form-control table-inline top-filter d-none d-md-block"
                        (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
                </div>
            </span>
        </div>
        <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
            <table style="width: 100%;" class="table nowrap dtr-inline no-footer">
                <thead>
                    <tr>
                        <th></th>
                        <th>{{'general.name' | translate }}</th>
                        <th>{{'general.emailLoginName' | translate }}</th>
                        <th>{{'general.count' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="groups.length == 0">
                        <td colspan="4">{{'general.noGroupsFound' | translate }}</td>
                    </tr>
                    <tr
                        *ngFor="let group of groups | stringFilter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                        <td class="elipses_overflow" style="width: 30px;">
                            <div class="fa_checkbox">
                                <ng-container *ngIf="formMode == 1; else editDeviceGroup">
                                    <input [readonly]=true [id]="'id_' + group.id" type="checkbox"
                                        [checked]="selectedItems[group.id]" disabled />
                                    <label [for]="'id_' + group.id"></label>
                                </ng-container>
                                <ng-template #editDeviceGroup>
                                    <input [(ngModel)]="selectedItems[group.id]" [id]="'id_' + group.id"
                                        type="checkbox" />
                                    <label [for]="'id_' + group.id"></label>
                                </ng-template>
                            </div>
                        </td>
                        <td>
                            <a href="/#/{{linkUrl}}/Index/{{ group.deviceId ? group.deviceId : group.id }}">
                                <span class='secondary link_bolder'><i class="fas fa-fw fa-angle-right"></i></span>
                                {{ group.name }}
                            </a>
                        </td>
                        <td class="elipses_overflow">
                            <a href="/#/UserDetails/Index/{{ group.userId }}">
                                <span class='secondary link_bolder'><i class="fas fa-fw fa-angle-right"></i></span>
                                {{group.reference}}
                            </a>
                        </td>
                        <td>{{group.itemCount}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="ibox-footer noselect dataTables_wrapper">
            <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
                <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                    <ul class="pagination">
                        <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                            <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{ 'grid.previous' |
                                translate }}</a>
                            <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                        </li>

                        <li class="paginate_button page-item " *ngFor="let page of p.pages"
                            [class.current]="p.getCurrent() === page.value">
                            <a class="page-link" (click)="p.setCurrent(page.value)"
                                *ngIf="p.getCurrent() !== page.value">
                                <span>{{ page.label }}</span>
                            </a>
                            <a class="page-link" (click)="p.setCurrent(page.value)"
                                *ngIf="p.getCurrent() === page.value">
                                <span style="font-weight: bold;">{{ page.label }}</span>
                            </a>
                        </li>

                        <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                            <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                {{ 'grid.next' | translate }}
                            </a>
                            <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                        </li>
                    </ul>
                </div>
            </pagination-template>
            <div style="clear:both"></div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!showIbox">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width: 100%;" class="table nowrap dtr-inline no-footer">
        <thead>
            <tr>
                <th style="width: 50px;">
                    <div class="fa_checkbox">
                        <input [checked]="checkedAllGroups()" (change)="selectAllGroups($event)" id="all_viewerGroups"
                            type="checkbox" name="all_viewerGroups"/>
                        <label for="all_viewerGroups">
                        </label>
                    </div>
                </th>
                <th>{{'general.name' | translate }}</th>
                <th>{{'general.emailLoginName' | translate }}</th>
                <th>{{'general.count' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="groups.length == 0">
                <td colspan="4">{{'general.noGroupsFound' | translate }}</td>
            </tr>
            <tr *ngFor="let group of groups">
                <td class="elipses_overflow" style="width: 30px;">
                    <div class="fa_checkbox">
                        <ng-container *ngIf="formMode == 1; else editDeviceGroup">
                            <input [readonly]=true [id]="'id_' + group.id" type="checkbox"
                                [checked]="selectedItems[group.id]" disabled />
                            <label [for]="'id_' + group.id"></label>
                        </ng-container>
                        <ng-template #editDeviceGroup>
                            <input [(ngModel)]="selectedItems[group.id]" [id]="'id_' + group.id" type="checkbox" />
                            <label [for]="'id_' + group.id"></label>
                        </ng-template>
                    </div>
                </td>
                <td>
                    <a href="/#/{{linkUrl}}/Index/{{ group.deviceId ? group.deviceId : group.id }}">
                        <span class='secondary link_bolder'><i class="fas fa-fw fa-angle-right"></i></span>
                        {{ group.name }}
                    </a>
                </td>
                <td class="elipses_overflow">
                    <a href="/#/UserDetails/Index/{{ group.userId }}">
                        <span class='secondary link_bolder'><i class="fas fa-fw fa-angle-right"></i></span>
                        {{group.reference}}
                    </a>
                </td>
                <td>{{group.itemCount}}</td>
            </tr>
        </tbody>
    </table>
</ng-container>