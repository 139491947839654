import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy, SimpleChanges, } from '@angular/core';
import { WizardComponent } from "app/modules/wizard/wizard";
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { getDefaultDpConfig } from "app/common/gridhelper";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { ReportService } from "app/services/reports/report.service";
import { ReportSubscription, ReportTemplate } from "app/models/reporting.model";
import { forkJoin } from "rxjs";
import { CronOptions } from "app/modules/cron-editor/CronOptions";
import { TranslateService } from "@ngx-translate/core";
import { ReportCategory } from "app/common/enums";
import { Router, ActivatedRoute } from '@angular/router';

// Moment
import * as Moment from "moment";
import * as moment from "moment-timezone";
import * as mTZ from "moment-timezone";

@Component({
  selector: 'fh-report-wizard',
  templateUrl: 'reportWizard.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ReportWizardComponent implements OnInit {
  @ViewChild(WizardComponent, { static: true }) wizard: WizardComponent;

  FormMode = FormMode;
  @Input() formMode: any = FormMode.read;

  //Input properties (add)
  @Input() reportSubscription;
  @Input() success;
  @Input() error;

  //Input functions (add)
  @Input() resellerChanged: (resellerId) => void;
  @Input() accountChanged: (accountId) => void;

  @Input() sendOutputMessage = false;

  //Input functions (edit)
  @Input() prefillWizard: (reportSubscription) => void;

  //Output properties 
  @Output() subscription = new EventEmitter();
  @Output() reportWizard = new EventEmitter();
  @Output() finish = new EventEmitter();

  subscriptionId;
  sub: any;
  reportType;
  template;
  selectedReportEdit;

  defaultValueScheduleEdit = 2;

  reportCategory = ReportCategory;

  availableReportCategories = {};

  step = 1;
  isReportReady = false;
  processing = false;

  // Resellers
  selectedReseller;
  loadingResellers = false;
  selectedResellerId;

  // Accounts
  selectedAccount;

  loadingAccounts = false;

  // Reports
  reports = [];
  reportsAll = [];
  reportTemplates = [];
  reportTemplatesAll = [];

  customFields = [];

  name = "";
  selectedReportTemplateId = null;
  selectedReport;

  // Filters
  daterangepickerModel: Date[];
  assetGroups = [];
  driverGroups = [];

  selectedAssetGroups;
  selectedDriverGroups;
  selectedGeofenceGroups;
  selectedProjects;

  loadingAssetGroups = false;
  loadingDriverGroups = false;
  filterSmallTrips = false;
  filterGrouping = 0;
  filterDateGrouping = 0;

  loadingColumns = false;

  // Report
  reportShown = false;
  reportData = {
    request: null,
    kpiList: [],
    charts: [],
    data: [],
  };

  // Grid
  dtOptions;
  dtTrigger;

  // Datepicker
  timezoneIana: string;
  public dpConfig: Partial<BsDaterangepickerConfig> =
    new BsDaterangepickerConfig();

  // Notifications
  //sendOutputMessage = false;
  createShareLink = false;
  selectedUserContactGroups = [];
  useCustomSubject = false;

  // Cron selector
  // Hangfire 1.7+ compatible expression: '3 2 12 1/1 ?'
  // Quartz compatible expression: '4 3 2 12 1/1 ? *'
  public reportingInterval = "10 8 ? * MON";

  public isCronDisabled = false;
  public cronOptions: CronOptions = {
    formInputClass: "cron-editor-input table-inline",
    formSelectClass: "cron-editor-select table-inline",
    formRadioClass: "cron-editor-radio",
    formCheckboxClass: "cron-editor-checkbox",

    defaultTime: "10:00:00",
    use24HourTime: true,

    hideMinutesTab: true,
    hideHourlyTab: true,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: false,
    hideAdvancedTab: true,

    hideSeconds: true,
    removeSeconds: true,
    removeYears: true,
  };

  defaultSourceColumns = [];

  permissions: {};

  constructor(
    private authenticationService: AuthenticationService,
    private reportService: ReportService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
    this.dpConfig = getDefaultDpConfig(Moment, authenticationService);
    this.dpConfig.withTimepicker = true;
    this.dpConfig.rangeInputFormat = 'lll';

    this.permissions = this.authenticationService.permissions;

    this.timezoneIana = this.authenticationService.getTimeZoneIana();

  }

  ngOnInit(): void {
    if (this.formMode === FormMode.add) {
      this.reportSubscription = new ReportSubscription();
    }
    this.cd.markForCheck();
  }

  getReportTypes() {
    this.loadingColumns = true;

    if (this.formMode === FormMode.edit) {
      this.sub = this.route.params.subscribe(params => {
        this.subscriptionId = params['id'];

        this.reportService.getReportSubscriptionById(this.subscriptionId).subscribe(res => {
          this.reportSubscription = res;
        });
      });
    }

    forkJoin([
      this.reportService.getReportColumnsByReportType(
        this.reportSubscription.selectedReportType
      ),
      this.reportService.getReportTemplates(),
    ]).subscribe(
      ([columns, templates]) => {

        if (this.reportsAll.length == 0) {
          this.reportsAll = columns;
        }

        for (const item in columns) {
          if (item === null) {
            continue;
          }

          this.availableReportCategories[columns[item].reportCategory] = true;
        }

        this.reportTemplatesAll = templates;

        this.selectedReport = columns["0"];

        this.defaultSourceColumns = this.selectedReport?.template?.columnConfiguration;

        // Set daterange
        this.daterangepickerModel = [
          Moment().tz(this.timezoneIana).subtract(this.selectedReport?.defaultDayCount ?? 14, "day").startOf("day").toDate(),
          Moment().tz(this.timezoneIana).endOf("day").toDate(),
        ];

        if (this.formMode === FormMode.edit) {
          this.fillWizard();
        }

        this.listReports();

        this.loadingColumns = false;
        this.cd.markForCheck();
      },
      (error) => {
        this.success = null;
        this.error = error;
      }
    );
  }

  fillWizard() {
    this.sub = this.route.params.subscribe(params => {
      this.subscriptionId = params['id'];

      this.reportService.getReportSubscriptionById(this.subscriptionId).subscribe(res => {
        this.reportSubscription = res;

        this.prefillWizard(this.reportSubscription);

        this.applyColumnEdit();
      });
    });
  }

  listReports() {
    const reportsArray = [];
    this.selectedAssetGroups = [];
    this.selectedDriverGroups = [];
    this.selectedGeofenceGroups = [];
    this.selectedProjects = [];
    this.selectedUserContactGroups = [];
    this.reportTemplates = [];

    if (this.formMode === FormMode.add) {
      this.reportSubscription.selectedReportType = null;
      this.selectedReportTemplateId = null;
    }

    // tslint:disable-next-line:forin
    for (const key in this.reportsAll) {
      reportsArray.push({ ...this.reportsAll[key], id: key });
    }

    this.reports = reportsArray.filter((x) => x.reportCategory != 0);

    this.reports.forEach((report) => {
      report.name = this.translate.instant(
        "enums.reportType." + report.reportType
      );
      report.category = this.translate.instant(
        "enums.reportCategory." + report.reportCategory
      );
    });

    this.cd.markForCheck();
  }

  prepareFilters() {
    if (this.formMode === FormMode.add) {
      this.selectedAssetGroups = [];
      this.selectedDriverGroups = [];
      this.selectedGeofenceGroups = [];
      this.selectedProjects = [];
      this.selectedUserContactGroups = [];
    }

    this.reportTemplates = [];
    this.customFields = [];

    this.selectedReportTemplateId = null;

    this.reportTemplates = this.reportTemplatesAll.filter(
      (x) =>
        x.reportType.toString() ===
        this.reportSubscription?.selectedReportType?.toString()
    );


    this.reportTemplates.forEach(template => {
      if (template.name.startsWith('lt.reporting')) {
        template.name = this.translate.instant(template.name);
      }
    });

    if (this.formMode === FormMode.add) {
      if (this.reportTemplates.length === 0) {
        this.selectedReportTemplateId = "default";
      }
      if (this.reportTemplates.length === 1) {
        this.selectedReportTemplateId = this.reportTemplates[0].id;
      }
    }
    else if (this.formMode === FormMode.edit) {
      this.selectedReportTemplateId = this.reportSubscription?.template?.id;
    }

    // Check for default template
    if (this.formMode === FormMode.add) {
      const defaultTemplate = this.reportTemplates.find(x => x.isDefault === true);
      if (defaultTemplate) {
        this.selectedReportTemplateId = defaultTemplate.id;
      }
    }

    this.cd.markForCheck();
  }

  changeReseller(id) {
    if (this.formMode === FormMode.add) {
      this.resellerChanged(id);
    }
  }

  changeAccount(id) {
    if (this.formMode === FormMode.add) {
      this.accountChanged(id);
    }
  }

  checkFormMode() {
    if (this.formMode === FormMode.edit) {
      return true;
    }
    return false;
  }

  getDefaultColumns() {
    this.selectedReport = this.reportsAll.find(x => x.reportType === this.reportSubscription.selectedReportType);

    if (this.selectedReport == null) {
      this.error = "Error getting selected report";
      return;
    }

    if (this.formMode === FormMode.edit && this.selectedReport.filterDefinitions.options !== undefined) {
      this.selectedReportEdit = this.selectedReport;
    }

    // Set daterange
    this.daterangepickerModel = [
      Moment().tz(this.timezoneIana).subtract(this.selectedReport?.defaultDayCount ?? 14, "day").startOf("day").toDate(),
      Moment().tz(this.timezoneIana).endOf("day").toDate(),
    ];

    if (this.customFields.length === 0) {
      for (const name in this.selectedReport.filterDefinitions) {
        if (
          name.toLowerCase().startsWith("select") ||
          name === "filterDateGrouping"
        ) {
          continue;
        }
        if (name === "filterGrouping") {
          this.selectedReport.filterDefinitions[name].options = JSON.parse(
            this.selectedReport.filterDefinitions[name].defaultValue
          );

          // select first
          this.reportSubscription.parameterConfiguration.filterGrouping = this.selectedReport.filterDefinitions[name].options[0];
          continue;
        }

        if (this.formMode === FormMode.add) {
          if (this.selectedReport.filterDefinitions[name].type === "bool") {
            this.reportSubscription.parameterConfiguration[name] =
              this.selectedReport.filterDefinitions[name].defaultValue === "true";
          } else if (
            this.selectedReport.filterDefinitions[name].type === "enum"
          ) {
            // Set json options
            this.selectedReport.filterDefinitions[name].options = JSON.parse(
              this.selectedReport.filterDefinitions[name].defaultValue
            );
            // Set defaults
            this.reportSubscription.parameterConfiguration[name] = [];
            this.selectedReport.filterDefinitions[name].options.forEach(
              (option) => {
                if (option.Value == true) {
                  this.reportSubscription.parameterConfiguration[name].push(
                    option.Key
                  );
                }
              }
            );
          } else {
            this.reportSubscription.parameterConfiguration[name] =
              this.selectedReport.filterDefinitions[name].defaultValue;
          }
        }

        this.customFields.push({
          ...this.selectedReport.filterDefinitions[name],
          name,
        });
      }
    }

    this.defaultSourceColumns =
      this.selectedReport?.template?.columnConfiguration;
    this.applyColumnConfiguration();
  }

  applyColumnEdit() {
    this.selectedReport = this.reportsAll[this.reportSubscription.selectedReportType];

    this.reportTemplates = this.reportTemplatesAll.filter(
      (x) =>
        x.reportType.toString() ===
        this.reportSubscription?.selectedReportType?.toString()

    );

    if (this.reportSubscription.template.name != 'default') {
      this.reportTemplates.push(this.reportSubscription.template);
    }

    this.selectedReportTemplateId = this.reportSubscription?.template?.id;
  }

  applyColumnConfiguration() {

    // Skip going to the column page when trip with details
    if ((this.reportSubscription.selectedReportType == 2 || this.reportSubscription.selectedReportType == 32)) {
      var previousStep = this.wizard.step;
      if (previousStep == 2) {
        setTimeout(() => {
          if (this.wizard.step == 3) {
            this.wizard.handleNext();
          }
        }, 1);
      }

      if (previousStep == 4) {
        setTimeout(() => {
          if (this.wizard.step == 3) {
            this.wizard.handlePrevious();
          }
        }, 1);
      }
    }

    if (this.formMode === FormMode.add) {
      if (this.reportSubscription.selectedReportType == 2) {
        this.selectedReportTemplateId = "default";
      }

      if (this.selectedReportTemplateId === "default") {
        // Get default report
        const template = this.selectedReport?.template;
        this.reportSubscription.template = new ReportTemplate();
        this.reportSubscription.template = { ...template };
        this.reportSubscription.template.name = "default";

        this.reportSubscription.template.columnConfiguration =
          template?.columnConfiguration?.filter((x) => x.enabled === true);
      } else {

        // Find template
        const reportTemplate = this.reportTemplatesAll.find(
          (x) => x.id === this.selectedReportTemplateId
        );

        // Set properties              
        this.reportSubscription.template = { ...reportTemplate };
        this.reportSubscription.template.columnConfiguration =
          reportTemplate.columnConfiguration.filter((x) => x.enabled === true);
      }
    }
  }

  //Override search function to be able to search on groups
  searchFn(searchTerm: string, item: any) {
    const that = this;
    let isSearchMatch = false;

    if (item.name?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
      isSearchMatch = true;
    }
    if (item.category?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
      isSearchMatch = true;
    }

    return isSearchMatch;
  }

  dateChanged(event) {
    // Default to end of day
    var date = new Date(this.daterangepickerModel[1].getTime());

    if (date.getHours() == 0 && date.getMinutes() == 0 && date.getSeconds() == 0) {
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);

      this.daterangepickerModel = [
        this.daterangepickerModel[0]
        , date];

      this.cd.markForCheck();
    }
  }

  checkOutputNotification() {
    setTimeout(() => {
      this.cd.markForCheck();
    }, 0);
  }

  compareGroups = (item, selected) => {
    if (selected.reportCategory && item.reportCategory) {
      return item.reportCategory === selected.reportCategory;
    }
    if (item.name && selected.name) {
      return item.name === selected.name;
    }
    return false;
  };

  fillReportSubscription(reportSubscription: ReportSubscription) {
    if (this.formMode === FormMode.add) {
      if (this.reportSubscription.subscriptionType === 2) {

        // 2 = scheduled
        reportSubscription.reportingInterval = this.reportingInterval;
      }

      reportSubscription.start = moment
        .utc(this.daterangepickerModel[0])
        .tz(this.timezoneIana)
        //.startOf("day")
        .unix();
      reportSubscription.end = moment
        .utc(this.daterangepickerModel[1])
        .tz(this.timezoneIana)
        //.endOf("day")
        .unix();

      this.selectedAssetGroups.forEach((element) => {
        if (typeof element === "number") {
          reportSubscription.selectedAssets.push(element);
        } else if (element.length > 0) {
          if (
            reportSubscription.selectedAssetGroups.indexOf(
              element[0].assetGroupId
            ) === -1
          ) {
            reportSubscription.selectedAssetGroups.push(element[0].assetGroupId);
          }
        }
      });

      this.selectedProjects.forEach((element) => {
        if (typeof element === "number") {
          reportSubscription.selectedProjects.push(element);
        } else if (element.length > 0) {
          if (
            reportSubscription.selectedProjects.indexOf(element[0].projectId) ===
            -1
          ) {
            reportSubscription.selectedProjects.push(element[0].projectId);
          }
        }
      });

      this.selectedDriverGroups.forEach((element) => {
        if (typeof element === "number") {
          reportSubscription.selectedDrivers.push(element);
        } else if (element.length > 0) {
          if (
            reportSubscription.selectedDriverGroups.indexOf(
              element[0].driverGroupId
            ) === -1
          ) {
            reportSubscription.selectedDriverGroups.push(
              element[0].driverGroupId
            );
          }
        }
      });

      this.selectedGeofenceGroups.forEach((element) => {
        if (typeof element === "number") {
          reportSubscription.selectedGeofences.push(element);
        } else if (element.length > 0) {
          if (
            reportSubscription.selectedGeofenceGroups.indexOf(
              element[0].geofenceGroupId
            ) === -1
          ) {
            reportSubscription.selectedGeofenceGroups.push(
              element[0].geofenceGroupId
            );
          }
        }
      });

      this.selectedUserContactGroups.forEach((element) => {
        if (typeof element === "number") {
          reportSubscription.notificationConfiguration.userContactRecipients.push(
            element
          );
        } else if (typeof element === "string") {
          reportSubscription.notificationConfiguration.emailRecipients.push(
            element
          );
        } else if (element.length > 0) {
          if (
            reportSubscription.notificationConfiguration.userContactGroupRecipients.indexOf(
              element[0].userContactGroupId
            ) === -1
          ) {
            reportSubscription.notificationConfiguration.userContactGroupRecipients.push(
              element[0].userContactGroupId
            );
          }
        }
      });
    }

    if (this.formMode === FormMode.edit) {
      this.reportSubscription.assetSelection.selectedAssets = [];
      this.reportSubscription.assetSelection.selectedAssetGroups = [];

      this.reportSubscription.projectSelection.selectedProjects = [];

      this.reportSubscription.driverSelection.selectedDrivers = [];
      this.reportSubscription.driverSelection.selectedDriverGroups = [];

      this.reportSubscription.geofenceSelection.selectedGeofences = [];
      this.reportSubscription.geofenceSelection.selectedGeofenceGroups = [];

      this.reportSubscription.notificationConfiguration.userContactGroupRecipients = [];
      this.reportSubscription.notificationConfiguration.userContactRecipients = [];

      this.selectedAssetGroups.forEach((element) => {
        if (typeof element === "number") {
          this.reportSubscription.assetSelection.selectedAssets.push(element);
        } else if (element.length > 0) {
          if (
            this.reportSubscription.assetSelection.selectedAssetGroups.indexOf(
              element[0].assetGroupId
            ) === -1
          ) {
            this.reportSubscription.assetSelection.selectedAssetGroups.push(element[0].assetGroupId);
          }
        } else if (typeof element === 'object') {
          if (element.id) {
            this.reportSubscription.assetSelection.selectedAssetGroups.push(element.id);
          }
        }
      });

      this.selectedProjects.forEach((element) => {
        if (typeof element === "number") {
          this.reportSubscription.projectSelection.selectedProjects.push(element);
        } else if (element.length > 0) {
          if (
            this.reportSubscription.projectSelection.selectedProjects.indexOf(element[0].projectId) ===
            -1
          ) {
            this.reportSubscription.projectSelection.selectedProjects.push(element[0].projectId);
          }
        }
      });

      this.selectedDriverGroups.forEach((element) => {
        if (typeof element === "number") {
          this.reportSubscription.driverSelection.selectedDrivers.push(element);
        } else if (element.length > 0) {
          if (
            this.reportSubscription.driverSelection.selectedDriverGroups.indexOf(
              element[0].driverGroupId
            ) === -1
          ) {
            this.reportSubscription.driverSelection.selectedDriverGroups.push(
              element[0].driverGroupId
            );
          }
        } else if (typeof element === 'object') {
          if (element.id) {
            this.reportSubscription.driverSelection.selectedDriverGroups.push(
              element.id
            );
          }
        }
      });

      this.selectedGeofenceGroups.forEach((element) => {
        if (typeof element === "number") {
          this.reportSubscription.geofenceSelection.selectedGeofences.push(element);
        } else if (element.length > 0) {
          if (
            this.reportSubscription.geofenceSelection.selectedGeofenceGroups.indexOf(
              element[0].geofenceGroupId
            ) === -1
          ) {
            this.reportSubscription.geofenceSelection.selectedGeofenceGroups.push(
              element[0].geofenceGroupId
            );
          }
        } else if (typeof element === 'object') {
          if (element.id) {
            this.reportSubscription.geofenceSelection.selectedGeofenceGroups.push(
              element.id
            );
          }
        }
      });

      this.selectedUserContactGroups.forEach((element) => {
        if (typeof element === "number") {
          reportSubscription.notificationConfiguration.userContactRecipients.push(
            element
          );
        } else if (typeof element === "string") {
          reportSubscription.notificationConfiguration.emailRecipients.push(
            element
          );
        } else if (element.length > 0) {
          if (
            reportSubscription.notificationConfiguration.userContactGroupRecipients.indexOf(
              element[0].userContactGroupId
            ) === -1
          ) {
            reportSubscription.notificationConfiguration.userContactGroupRecipients.push(
              element[0].userContactGroupId
            );
          }
        } else if (typeof element === 'object') {
          if (element.id) {
            reportSubscription.notificationConfiguration.userContactGroupRecipients.push(
              element.id
            );
          }
        }
      });
    }
  }

  onCheckOutFinish() {
    this.fillReportSubscription(this.reportSubscription);
    this.subscription.emit(this.reportSubscription);
    this.reportWizard.emit(this.wizard);
    this.finish.emit();
  }

  resetWizard() {
    const oldReport = { ...this.reportSubscription };

    this.reportShown = false;
    this.reportSubscription = new ReportSubscription();

    this.reportSubscription.selectedReportCategory =
      oldReport.selectedReportCategory;
    this.reportSubscription.selectedReportType = oldReport.selectedReportType;
    this.reportSubscription.accountId = oldReport.accountId;
    this.reportSubscription.name = oldReport.name;

    this.reportSubscription.parameterConfiguration =
      oldReport.parameterConfiguration;
    this.reportSubscription.template = oldReport.template;

    this.reportSubscription.selectedAssetGroups = oldReport.selectedAssetGroups;
    this.reportSubscription.selectedAssets = oldReport.selectedAssets;
    this.reportSubscription.selectedDriverGroups =
      oldReport.selectedDriverGroups;
    this.reportSubscription.selectedDrivers = oldReport.selectedDrivers;
    this.reportSubscription.selectedGeofenceGroups =
      oldReport.selectedGeofenceGroups;
    this.reportSubscription.selectedGeofences = oldReport.selectedGeofences;
    this.reportSubscription.selectedProjects = oldReport.selectedProjects;

    this.wizard.reset();
  }

  saveReportTemplate(reportType, event) {
    const reportTemplate = {
      ReportType: reportType,
      Name: event.form.name,
      ColumnConfiguration: event.template.columnConfiguration.filter(
        (x) => x.enabled === true
      ),
      TargetResellerId: event.form.targetResellerId,
      TargetAccountId: event.form.targetAccountId,
      TargetUserId: this.authenticationService.getUserId(),
      GroupByIndex: event.template.groupByIndex,
      GroupByType: event.template.groupByType,
      OrderByIndex: event.template.orderByIndex,
      OrderByAscending: event.template.orderByAscending,
      HideGroupByColumn: event.template.hideGroupByColumn,
      Orientation: event.template.orientation,
    };

    // If no admin force own account
    if (!this.permissions['Platform_IsReseller']) {
      reportTemplate.TargetAccountId = this.authenticationService.getAccountId();
    }

    this.reportService.saveReportTemplate(reportTemplate).subscribe(
      (_) => {
        this.error = null;
        this.success = null;
        this.success = {
          statusText: "Success",
          success: this.translate.instant("general.reportTemplateSaved"),
        };
        this.cd.markForCheck();
      },
      (error) => {
        this.success = null;
        this.error = error;
      }
    );
  }

  updateReportTemplate(event) {
    this.reportService
      .updateReportTemplate(event.template.id, event.template)
      .subscribe(
        (entity) => {
          this.error = null;
          this.success = null;

          if (entity.isSuccess) {
            this.success = {
              statusText: "Success",
              success: this.translate.instant("general.reportTemplateSaved"),
            };
          } else {
            this.error = entity.message;
          }
          this.cd.markForCheck();
        },
        (error) => {
          this.success = null;
          this.error = error;
          this.cd.markForCheck();
        }
      );
  }
}
