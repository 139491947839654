import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DeviceSettingChange, DeviceSettingChangeResult } from 'app/models/device.model';
import { User } from 'oidc-client';


import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable, catchError, map, of } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
window['moment'] = Moment;
mTZ()
@Injectable()
export class DeviceSettingChangeService {
    url = '';
    timezoneIana: string;

    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        this.url = this.authenticationService.getWebserviceURL('deviceSettingChange');
        this.timezoneIana = this.authenticationService.getTimeZoneIana();
    }

    getDeviceSettingChanges(accountId, start, end): Observable<DeviceSettingChangeResult[]> {
        if (accountId == null) {
            return of([]);
        }

        return this.http.get(this.url + '?accountId=' + accountId + '&start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .pipe(map((data: any) => {
                return data;
            }), catchError(this.handleError));
    }

    getDeviceSettingChangesById(id: string): Observable<DeviceSettingChangeResult[]> {
        return this.http.get(this.url + id, { headers: this.authenticationService.headers })
            .pipe(map((data: any) => {
                const changes: DeviceSettingChangeResult[] = [];

                data.forEach(item => {

                    const change = new DeviceSettingChangeResult();
                    change.id = item.id;
                    change.changeAppliedDate = item.changeAppliedDate !== undefined ? Moment.utc(item.changeAppliedDate)['tz'](this.timezoneIana) : undefined;
                    change.changeSentDate = item.changeSentDate !== undefined ? Moment.utc(item.changeSentDate)['tz'](this.timezoneIana) : undefined;
                    change.commandToken = item.commandToken;
                    change.deviceId = item.deviceId;
                    change.retryCount = item.retryCount;
                    change.settingId = item.settingId;
                    change.status = item.status;
                    change.response = item.response;
                    change.timestamp = item.timestamp !== undefined ? Moment.utc(item.timestamp)['tz'](this.timezoneIana) : undefined;
                    change.value = item.value;
                    changes.push(change);
                });

                return changes;
            }), catchError(this.handleError));
    }

    sendDeviceSettingChanges(deviceSettingChange: DeviceSettingChange): Observable<any> {
        return this.http.post(this.url, deviceSettingChange, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return data;
            }), catchError(this.handleError));
    }

    resendDeviceSettingChanges(id: string): Observable<any> {
        return this.http.get(this.url + id + '/Resend', { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return data;
            }), catchError(this.handleError));
    }

    cancelDeviceSettingChanges(id: string): Observable<any> {
        return this.http.get(this.url + id + '/Cancel', { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return data;
            }), catchError(this.handleError));
    }

    deleteDeviceSettingChanges(id: string): Observable<any> {
        return this.http.delete(this.url + id, { headers: this.authenticationService.headers })
            .pipe(map(data => {
                return data;
            }), catchError(this.handleError));
    }

    private handleError(error: Response) {
        return throwError(error);
    }
}
