import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageType } from 'app/common/enums';
import { StorageHelper } from 'app/common/storagehelper';
import 'jquery-slimscroll';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
@Component({
  selector: 'fh-navigation',
  templateUrl: 'navigation.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isFleetOverview = false;

  hasFuel: any;
  // Permissions
  hasReporting: any;
  hasLocations: any;
  hasTrips: any;
  hasAdvice: any;
  hasSchedule: any;
  hasSettings: any;
  hasAssets: any;
  hasDevices: any;
  hasCustomers: any;

  showId: any;
  id: number;
  private sub: Subscription;
  children = false;
  permissions: {};
  isImpersonated: boolean;
  storageType = StorageType.LocalStorage;

  hideWarehouses = false;
  hideTrailers = false;
  hideProjects = false;
  hideFuel = false;
  hideMaintenance = false;

  constructor(private cd: ChangeDetectorRef, private router: Router, private route: ActivatedRoute, private authentication: AuthenticationService, private storageHelper: StorageHelper) {
    this.id = 0;
    this.showId = '';

    this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideWarehouses').subscribe((result) => {
      this.hideWarehouses = JSON.parse(result) === true;
    });

    this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideTrailers').subscribe((result) => {
      this.hideTrailers = JSON.parse(result) === true;
    });

    this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideProjects').subscribe((result) => {
      this.hideProjects = JSON.parse(result) === true;
    });

    this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideFuel').subscribe((result) => {
      this.hideFuel = JSON.parse(result) === true;
    });

    this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideMaintenance').subscribe((result) => {
      this.hideMaintenance = JSON.parse(result) === true;
    });
  }

  ngAfterViewInit() {

    setTimeout(() => {
      $('#side-menu')['metisMenu']();
    }, 1);

    if ($('body').hasClass('fixed-sidebar')) {
      $('.sidebar-collapse')['slimscroll']({
        height: '100%'
      })
    }
  }

  ngOnInit() {
    this.isImpersonated = this.authentication.getIsImpersonated();

    this.permissions = this.authentication.permissions;

    this.router.events.subscribe(params => {
      // console.log(params);
      this.cd.markForCheck();
    });

    if (this.route.children.length > 0) {
      this.children = true;

      this.sub = this.route.children[0].params.subscribe(params => {
        this.id = params['id'];
        this.showId = this.id ? this.id.toString().substring(0, 4) : '';
        this.cd.markForCheck();
      });
    }
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) === 1;
  }

  ngOnDestroy() {
    if (this.children) {
      this.sub.unsubscribe();
    }
  }
}
