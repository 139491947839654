import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import exportFromJSON from 'export-from-json'
import { roundAsNumber, roundSeconds } from 'app/common/globals';
import { ReportService } from 'app/services/reports/report.service';
import { FhChartService } from 'app/services/charts/charts.service';
import { ReportDisplayDetailsComponent } from './reportDisplay.component';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from '../shared/usercontrols/confirmationModal.component';
import { forkJoin, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/internal/operators/first';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { ReportTemplate } from 'app/models/reporting.model';

window['moment'] = Moment;
mTZ()

@Component({
    selector: 'fh-reporting-details',
    templateUrl: 'reportingDetails.template.html',
    providers: [FhChartService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingDetailsViewComponent implements OnInit {
    @ViewChild('deleteModal') deleteModal: ConfirmationModalComponent;
    @ViewChild(ReportDisplayDetailsComponent, { static: false }) reportDisplayComponent: ReportDisplayDetailsComponent;

    @ViewChild('inputWrapper') inputWrapper;

    copyCompleted = false;
    copyTimeout;

    reportTemplates = [];

    loading: boolean;
    loadingDetails: boolean;
    sub: any;

    activeReport;
    showJson = false;
    showColumnSelector = false;

    columnSuccess;

    success;
    error;

    filter;

    selectedReportTemplateId;

    // Pagination
    currentPage = 1;

    permissionName = 'FleetManagement_Reporting';

    timezoneIana: string;
    reportData: any;

    template: ReportTemplate = new ReportTemplate();

    defaultSourceColumns = [];
    permissions: {};

    reportDataCollected = new ReplaySubject<boolean>();

    userId: number;
    activeReportJson: any;

    constructor(private router: Router, private cd: ChangeDetectorRef, private translate: TranslateService, private authenticationService: AuthenticationService, private route: ActivatedRoute, private reportService: ReportService) {
        this.timezoneIana = this.authenticationService.getTimeZoneIana();
        this.permissions = this.authenticationService.permissions;
        this.userId = +this.authenticationService.getUserId();
    }

    actualRound(value, decimals) {
        return roundAsNumber(value, decimals);
    }

    actualRoundSeconds(value) {
        return roundSeconds(value);
    }

    displayColumnSelector() {
        this.showColumnSelector = !this.showColumnSelector;

        if (this.defaultSourceColumns.length === 0) {
            console.log('getting columns');
            this.getDefaultColumns();
        }
    }

    ngOnInit() {
        this.loading = true;
        this.loadingDetails = true;
        this.template = new ReportTemplate();

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.reportService.getReportById(id).subscribe(res => {

                if (res == null) {
                    this.loadingDetails = false;
                    this.loading = false;

                    this.success = null;
                    this.error = {
                        statusText: 'Error',
                        error: 'Unable to fetch report'
                    };

                    this.loadingDetails = false;
                    this.cd.markForCheck();
                    return;
                }

                this.activeReport = res;

                this.activeReport.periodStart = Moment.utc(this.activeReport.periodStart)['tz'](this.timezoneIana);
                this.activeReport.periodEnd = Moment.utc(this.activeReport.periodEnd)['tz'](this.timezoneIana);

                this.activeReport.executedTimestamp = Moment.utc(this.activeReport.executedTimestamp)['tz'](this.timezoneIana);

                this.activeReport.ReportType = this.activeReport?.selectedReportType;
                this.activeReport.ReportName = this.activeReport.name;

                this.activeReport.selectedReportType = this.translate.instant('enums.reportType.' + this.activeReport?.selectedReportType);

                this.activeReport.timestamp = Moment.utc(this.activeReport.timestamp)['tz'](this.timezoneIana);

                this.template = this.activeReport.template;
                if (!this.template.orientation) {
                    this.template.orientation = 0;
                }

                this.activeReport.reportData = [];
                this.activeReportJson = { ...this.activeReport };

                if (this.activeReport && this.activeReport.isSuccessful) {
                    this.loading = false;
                    this.loadingDetails = true;
                    this.cd.markForCheck();

                    this.reportService.getReportDetailsById(id, this.activeReport.accountIdentifier).subscribe(details => {

                        this.activeReport.reportData = details.reportData;
                        this.reportData = this.activeReport.reportData;
                        this.loadingDetails = false;

                        // When no columns in report take the default columns
                        if (this.template.columnConfiguration == null) {
                            console.log('Getting default columns');
                            this.getDefaultColumns(true);
                        }

                        this.cd.markForCheck();

                        // Check if we need to download
                        this.sub = this.route.queryParams.subscribe(queryParams => {
                            const { downloadPdf, downloadExcel } = queryParams;

                            switch ('true') {
                                case downloadPdf:
                                    setTimeout(() => {
                                        console.log('downloading pdf');
                                        this.reportDisplayComponent.downloadPdf(true, true);
                                    }, 100);
                                    break;
                                case downloadExcel:
                                    setTimeout(() => {
                                        console.log('downloading excel');
                                        this.reportDisplayComponent.downloadXls(false);
                                    }, 100);
                                    break;
                                default:
                                    break;
                            }

                        });
                    }, error => {
                        this.success = null;
                        this.error = error;
                        this.loadingDetails = false;
                        this.cd.markForCheck();
                    });
                } else {
                    this.loadingDetails = false;
                    this.loading = false;

                    this.success = null;
                    this.error = {
                        statusText: 'Error',
                        error: 'Error loading report'
                    };

                    this.cd.markForCheck();
                }
            });
        }, error => {
            this.success = null;
            this.error = error;
            this.loadingDetails = false;
            this.loading = false;

            this.cd.markForCheck();
        });
    }

    showDelete() {
        this.deleteModal.showModal(this.activeReport.id);
    }

    onDelete(event) {
        this.loading = true;
        this.deleteModal.hideModal();

        this.reportService.deleteReportById(this.activeReport).subscribe(result => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Report is successfully deleted.'
            };

            setTimeout(() => {
                this.router.navigate(['/Reporting/Executions']);
            }, 3000);
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    format(template) {
        console.log('Formatting data');
        if (this.activeReport.template) {
            this.activeReport.template.name = template.name;
        }
        this.reportDisplayComponent.formatData(template ?? this.template);
    }

    getDefaultColumns(reset = false) {
        forkJoin([this.reportService.getReportColumnsByReportType(this.activeReport.ReportType), this.reportService.getReportTemplates()]).subscribe(([columns, templates]) => {
            this.defaultSourceColumns = columns[this.activeReport.ReportType]?.template?.columnConfiguration;
            this.reportTemplates = templates.filter(x => x.reportType === this.activeReport.ReportType);

            this.reportTemplates.forEach(template => {
                if (template.name?.startsWith('lt.reporting')) {
                    template.name = this.translate.instant(template.name);
                }
            });

            // this.selectedReportTemplateId = 'default';
            if (reset) {
                this.template.columnConfiguration = this.defaultSourceColumns.filter(x => x.enabled === true);
                this.format(this.template);
            }

            this.cd.markForCheck();
        }, error => {
            this.success = null;
            this.error = error;

            this.cd.markForCheck();
        });
    }

    resetColumnConfiguration() {
        this.template.columnConfiguration = this.defaultSourceColumns;
        this.format(this.template);
    }

    async downloadPdf(includeCharts = true, includeData = true, includeMaps = false) {
        this.loading = true;
        const that = this;
        setTimeout(async () => {
            try {
                await this.reportDisplayComponent.downloadPdf(includeCharts, includeData, includeMaps).then(function () {
                    that.loading = false;
                    that.cd.markForCheck();
                });
            } catch (error) {
                this.error = error;
                this.loading = false;
                this.cd.markForCheck();
            }
        }, 10);
    }

    async downloadXls(formatXlsExport) {
        this.loading = true;
        this.cd.markForCheck();

        setTimeout(() => {
            this.reportDisplayComponent.downloadXls(false, formatXlsExport);

            this.loading = false;
            this.cd.markForCheck();
        }, 10);
    }

    downloadCsv(formatXlsExport) {
        this.loading = true;
        this.cd.markForCheck();

        const that = this;

        setTimeout(() => {
            try {
                const result = this.reportDisplayComponent.downloadXls(true, formatXlsExport);
                this.loading = false;
                this.cd.markForCheck();
            } catch (error) {
                this.error = error;
                this.loading = false;
                this.cd.markForCheck();
            }
        }, 10);
    }

    downloadJson() {
        exportFromJSON({
            data: this.reportData,
            fileName: 'reportdata',
            exportType: exportFromJSON.types.json,
            beforeTableEncode: rows => rows.sort((p, c) => p['Data']?.Name.localeCompare(c['Data']?.Name)),
        })
    }

    saveReportTemplate(reportType, event) {
        const reportTemplate = {
            ReportType: reportType,
            Name: event.form.name,
            ColumnConfiguration: event.template.columnConfiguration.filter(x => x.enabled === true),
            TargetResellerId: event.form.targetResellerId,
            TargetAccountId: event.form.targetAccountId,
            TargetUserId: this.authenticationService.getUserId(),
            GroupByIndex: event.template.groupByIndex,
            GroupByType: event.template.groupByType,
            OrderByIndex: event.template.orderByIndex,
            OrderByAscending: event.template.orderByAscending,
            HideGroupByColumn: event.template.hideGroupByColumn,
            Orientation: event.template.orientation
        };

        // If no admin force own account
        if (!this.permissions['Platform_IsReseller']) {
            reportTemplate.TargetAccountId = this.authenticationService.getAccountId();
        }

        this.reportService.saveReportTemplate(reportTemplate).subscribe(_ => {
            this.error = null;
            this.success = null;
            this.success = {
                statusText: 'Success',
                success: this.translate.instant('general.reportTemplateSaved'),
            };
            this.cd.markForCheck();
        }, error => {
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    updateReportTemplate(event) {
        this.reportService.updateReportTemplate(event.template.id, event.template).subscribe(entity => {
            this.error = null;
            this.success = null;

            if (entity.isSuccess) {
                this.success = {
                    statusText: 'Success',
                    success: this.translate.instant('general.reportTemplateSaved'),
                };
            } else {
                this.error = entity.message;
            }
            this.cd.markForCheck();
        }, error => {
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }


    textToClipboard(text) {
        const value = text ? text : $(this.inputWrapper.nativeElement.getElementsByTagName('input'))?.val();

        if (!value) {
            return;
        }

        clearTimeout(this.copyTimeout);

        const input = document.createElement('textarea');
        input.innerHTML = value;
        input.value = String(value)?.trim();
        input.setAttribute('readonly', '');
        input.style.position = 'absolute';
        input.style.left = '-9999px';
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, 9999999);
        document.execCommand('copy');
        document.body.removeChild(input);
        this.copyCompleted = true;
        this.copyTimeout = setTimeout(() => { this.copyCompleted = false }, 2000);
    }
}
