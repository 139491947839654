import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserContactGroupService } from 'app/services/users/userContactGroups.service';

const noop = () => {
};

@Component({
    selector: 'fh-user-contact-group-item-input',
    templateUrl: 'userContactGroupItemSelector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: UserContactGroupItemInputComponent }
    ]
})

export class UserContactGroupItemInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
    @Input() placeholder: string;
    @Input() clearable = true;
    @Input() selectedAccountId;
    @Input() readonly = false;
    @Input() multiple = true;
    @Input() tableInline;
    @Input() includeSystemGroups = true;
    @Input() setCurrentContacts;

    @Output() onChanged = new EventEmitter();

    userContactGroups = [];
    selectGroupBy = 'userContactGroupItems';
    selectBindLabel = 'userContactGroupName';

    loadingUserContactGroups = false;

    @ViewChild('input') el: ElementRef

    private _value: string;
    private _onChange: (_: any) => void = noop;

    get value(): any {
        return this._value;
    }

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this._onChange(v);
            this.cd.markForCheck();
        }
    }

    constructor(private userContactGroupService: UserContactGroupService, private cd: ChangeDetectorRef) {
    }

    groupValueFn = (object: { [key: string]: any }, children: any[]) => ({ name: object.value.name, id: [{ userContactGroupId: object.value.id }], length: children.length });

    // Override search function to be able to search on groups
    searchFn(searchTerm: string, item: any) {
        const columns = ['userContactGroupName', 'email'];
        let isSearchMatch = false;

        columns.forEach(column => {
            if (item[column]?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
                isSearchMatch = true;
            }
        })

        return isSearchMatch;
    }

    userContactGroupChanged() {
        this.onChanged.emit(this.value);
        this.cd.markForCheck();
    }

    ngOnChanges() {
        setTimeout(() => {
            // Get userContactGroups again
            this.loadUserContactGroups();
            this.cd.markForCheck();
        }, 0);
    }

    compareFunction = (item, selected) => {
        if (selected.email && item.email) {
            return item.gender === selected.gender;
        }
        if (item.id && selected.id) {
            return item.id === selected.id;
        }
        return false;
    };

    compareFn(item, selected) : boolean {
        if (item.name !== undefined) {
            if (item.id[0].userContactGroupId === selected) {
                return true;
            }           
            else if (selected.id !== undefined && item.id[0].userContactGroupId === selected.id){
                return true;
            }
            return false;
        }
        if (item?.id === selected) {
            return true;
        }
        return false;
    }

    showAll(event, userContactGroup) {
        userContactGroup.showAll = !userContactGroup.showAll;
        event.stopPropagation();
    }

    loadUserContactGroups() {
        this.userContactGroups = [];
        this.cd.markForCheck();

        if (this.selectedAccountId) {
            this.loadingUserContactGroups = true;
            this.cd.markForCheck();

            if (this.selectedAccountId && this.selectedAccountId !== 0) {
                this.userContactGroupService.getUserContactGroups(this.selectedAccountId, true).subscribe(result => {
                    this.userContactGroups = result;

                    this.userContactGroups.sort((a, b) => (a.name < b.name ? -1 : 1));

                    if (this.includeSystemGroups === false) {
                        this.userContactGroups = result.filter(x => x.isSystemGroup !== true);
                    }

                    this.loadingUserContactGroups = false;
                    this.cd.markForCheck();
                });
            } else {
                this.loadingUserContactGroups = false;
                this.cd.markForCheck();
            }
        }
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        const __this = this;
    }

    writeValue(value: any) {
        this._value = value;
        this.cd.markForCheck();
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
        this.cd.markForCheck();
    }

    registerOnTouched(fn: any) {
        this.cd.markForCheck();
    }
}
