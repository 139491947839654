<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Drivers/Overview">{{'general.drivers' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.assignmentGantt' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-driver-navigation></fh-driver-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row">
            <div class="col-lg-9">
                <fh-error [error]="error" [success]="success" [warning]="warning"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative;" *ngIf="languageLoaded">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div id="dynamicFilters" class="flex-container">
                            <div class="col-lg-4">
                                <div class="form-group" style="margin-bottom: 6px;">
                                    <fh-reseller-input name="reseller" #resellerVar="ngModel"
                                        (onChanged)="resellerChanged($event)" required [tableInline]="false"
                                        placeholder="{{ 'general.chooseReseller' | translate}}"
                                        [(ngModel)]="selectedResellerId">
                                    </fh-reseller-input>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group" style="margin-bottom: 6px;">
                                    <fh-account-input name="account" #accountVar="ngModel"
                                        (onChanged)="accountChanged($event)" required [activeOnly]=true
                                        [selectedResellerId]="selectedResellerId" [tableInline]="false"
                                        placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                        [(ngModel)]="selectedAccountId">
                                    </fh-account-input>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group input-group" style="margin-bottom: 6px;">
                                    <span class="input-group-prepend">
                                        <i class="fa fa-fw fa-calendar"></i>
                                    </span>
                                    <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker"
                                        type="text" [bsConfig]="dpConfig" class="form-control" bsDaterangepicker
                                        (onHidden)="dateChanged('hidden')" [(ngModel)]="daterangepickerModel">
                                    <span class="input-group-calendar-datatables">
                                        <i class="fa fa-calendar-alt"></i>
                                    </span>
                                </div>
                            </div>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                    <div class="ibox-footer">
                        <button *ngIf="!loading" type="button" name="search" class="btn btn-primary"
                            (click)="dateChanged('hidden')" [disabled]="selectedAccountId == null">{{
                            'general.fetchData' |
                            translate}}</button>

                        <button *ngIf="loading" type="button" name="cancel" class="btn btn-info" (click)="cancel()">
                            <i class="fas fa-fw fa-times"></i> {{ 'general.cancel' | translate}}</button>
                    </div>
                </div>
            </div>
            <div class='col-lg-3'>
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-square-info"></i> {{'general.legend' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative;min-height: 99px;"
                        *ngIf="languageLoaded">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div class="col-lg-12 sked-legend" style="line-height: 40px;">
                            <i class="fas fa-fw fa-square" style="color: #007a80"></i> {{'general.assignedDriver' |
                            translate }}
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>
                <!-- End default dynamic filters -->
            </div>

            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-solid fa-calendar-days"></i> {{'general.assignmentGantt' | translate
                            }}
                        </h5>
                        <span class="float-right">
                            <div class="filter-form d-none d-md-block">
                                <input type="text" class="form-control table-inline top-filter d-none d-md-block"
                                    [(ngModel)]="stringFilter" (input)="deferSearch$.subscribe()">
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative;">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div id="skedGantt"></div>
                        <div
                            *ngFor="let _ of totalItems | stringFilter: stringFilter | paginate: { itemsPerPage, currentPage }">
                        </div>
                        <span style="padding:10px; display: block"
                            *ngIf="!totalItems || totalItems?.length == 0">{{'general.noDataFound' |
                            translate}}</span>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event; renderData()">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                            'grid.previous' |
                                            translate }}</a>
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value">
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>