<div dropdown [dropup]="true" class="taskDropdown" *ngIf="permissions['FleetManagement_Actions']"
    [isDisabled]="!entity || batchActions.length == 0" style="width: 100%; position: relative; overflow: visible;">
    <ngx-loading [show]="processing"></ngx-loading>
    <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
        aria-controls="dropdown-basic" style="width: 100%; margin:0px">
        <i class="fa fa-fw fa-bolt"></i> <span class="d-none d-md-inline-flex"> {{'general.chooseAction' | translate
            }}</span> <span class="caret"></span>
    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-fix dropdown-menu-right" role="menu"
        aria-labelledby="button-basic">
        <li role="menuitem" *ngFor="let action of batchActions | orderBy : 'name':false"
            [ngClass]="action.disabled ? 'disabled' : ''">
            <a [ngClass]="action.disabled ? 'disabled' : ''" class="dropdown-item" href="javascript:void(0)"
                (click)="!action.disabled ? showModal(action) : null">
                <i class="fas fa-fw  " [ngClass]="action.icon ? action.icon : 'fa-angle-right'"
                    style="padding-right:5px"></i>
                {{("enums.batchAction." + action.batchActionType) |
                translate}}</a>
        </li>
    </ul>
</div>

<fh-confirmation-modal [message]="'general.areyousure'"
    [showButtons]="(selectedAction?.batchActionType == 3 || selectedAction?.batchActionType == 4 || selectedAction?.batchActionType == 25 || selectedAction?.batchActionType == 27)"
    [invalid]="groupForm.invalid" #confirmModal (onFinish)="process($event)" (onHide)="resetAction($event)">
    <h4 header class="modal-title pull-left"><i class="fa fa-fw fa-bolt"></i> {{'general.action' | translate }}:
        {{('enums.batchAction.' + selectedAction?.batchActionType) | translate }}</h4>

    <form body #groupForm="ngForm" style="width: 100%; border-top: 0px">

        <!-- Add log item -->
        <div *ngIf="selectedAction?.batchActionType == 3 || selectedAction?.batchActionType == 25" class="modal-body">
            <div class="wizard-content" style="padding-bottom: 10px">
                {{'general.addANote' | translate }}
            </div>
            <div class="wizard-content">
                <textarea class="form-control noresize stickypad" style="height:100px; width:100%; resize: none"
                    name="comment" id="comment" required placeholder="{{ 'placeholder.comments' | translate}}"
                    [(ngModel)]="logItem"></textarea>
            </div>
        </div>

        <!--Add to client -->
        <fh-batch-activate-device *ngIf="selectedAction && selectedAction.batchActionType == 6"
            [gridSelection]="[entity.id]" (onFinish)="process($event)">
        </fh-batch-activate-device>

        <!-- Change Reseller -->
        <fh-batch-change-inventory [gridSelection]="[entity.id]" *ngIf="selectedAction?.batchActionType == 11"
            (onFinish)="process($event)">
        </fh-batch-change-inventory>

        <!-- Move to client -->
        <fh-batch-move-to-client *ngIf="selectedAction && selectedAction.batchActionType == 12"
            [gridSelection]="[entity.id]" (onFinish)="process($event)">
        </fh-batch-move-to-client>

        <!-- Replace device -->
        <fh-batch-replace-device *ngIf="selectedAction && selectedAction.batchActionType == 13"
            [gridSelection]="[entity.id]" [resellerId]="entity.resellerId" (onFinish)="process($event)">
        </fh-batch-replace-device>

        <!-- Deactivate device -->
        <fh-batch-remove-from-account *ngIf="selectedAction && selectedAction.batchActionType == 14"
            [gridSelection]="[entity.id]" (onFinish)="process($event)">
        </fh-batch-remove-from-account>

        <!-- Delete device history -->
        <fh-batch-delete-history *ngIf="selectedAction && selectedAction.batchActionType == 17"
            [gridSelection]="[entity.id]" (onFinish)="process($event)">
        </fh-batch-delete-history>

        <!-- Reset device -->
        <fh-batch-reset-device *ngIf="selectedAction && selectedAction.batchActionType == 18"
            [gridSelection]="[entity.id]" (onFinish)="process($event)">
        </fh-batch-reset-device>

        <!-- Create trip report -->
        <fh-batch-instant-report *ngIf="selectedAction && selectedAction.batchActionType == 22"
            [gridSelection]="[entity.id]" (onFinish)="process($event)">
        </fh-batch-instant-report>

        <!-- Calibrate odo -->
        <fh-batch-odo *ngIf="selectedAction && selectedAction.batchActionType == 23" [gridSelection]="[entity.id]"
            (onFinish)="process($event)">
        </fh-batch-odo>

        <!-- Rerun trips -->
        <fh-batch-rerun-trips *ngIf="selectedAction && selectedAction.batchActionType == 24"
            [gridSelection]="[entity.id]" (onFinish)="process($event)">
        </fh-batch-rerun-trips>

        <!-- Basic trigger -->
        <fh-batch-basictrigger *ngIf="selectedAction && selectedAction.batchActionType == 26"
            [gridSelection]="[entity.id]" [accountId]="[entity.accountId]" (onFinish)="process($event)">
        </fh-batch-basictrigger>

        <!-- Set up custom commands -->
        <fh-batch-custom-commands *ngIf="selectedAction && selectedAction.batchActionType == 28"
            [gridSelection]="[entity.id]" (onFinish)="process($event)">
        </fh-batch-custom-commands>

        <!-- Request location item -->
        <div *ngIf="selectedAction?.batchActionType == 4" class="modal-body">
            <div class="wizard-content">
                {{'general.getLocationsWarning' | translate:{ value: 1 } }}
            </div>
        </div>
    </form>
</fh-confirmation-modal>