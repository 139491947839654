<div class="wrapper wrapper-content">

    <div class="row" data-equalizer="">
        <div class="col-lg-12">

            <fh-error [error]="error" [success]="success"></fh-error>

        </div>

        <div class="col-lg-12">
            <div class="ibox" style="position: relative;">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-location-arrow"></i> {{'general.trip' | translate }}: {{ this.device?.asset?.name ?? this.device?.name }}
                    </h5>
                </div>
                <div class=" ibox-content" style="position: relative;"
                    *ngIf="!selectedTrip || leafletMapComponent?.tripLayer.getLayers().length > 1">
                    <ngx-loading [show]="loadingDetails || loadingGeofences || mapBusy">
                    </ngx-loading>
                    <span style="width: 100%" *ngIf="leafletMapComponent?.tripLayer.getLayers().length > 1">
                        {{'general.selectOnlyOneTrip' | translate }}
                    </span>
                    <span *ngIf="!selectedTrip">
                        {{'general.noTripSelected' | translate }}
                    </span>
                </div>
                <div class=" ibox-content slick" style="position: relative;"
                    *ngIf="selectedTrip && !(leafletMapComponent?.tripLayer.getLayers().length > 1)">
                    <ngx-loading [show]="loadingDetails || loadingGeofences || mapBusy">
                    </ngx-loading>
                    <div class="flex-container">
                        <div class="col-lg-4" style="padding:0">
                            <div class="kpi-item-small">
                                <h3 class="margin-bottom font-bold"
                                    title="{{selectedTrip?.beginDateTime?.toLocaleString()}}">
                                    {{ selectedTrip?.beginDateTime | amDateFormat:'ll HH:mm' }}
                                </h3>
                                <div> {{'general.start' | translate }}</div>
                            </div>
                        </div>
                        <div class="col-lg-4" style="padding:0">
                            <div class="kpi-item-small">
                                <h3 class="margin-bottom font-bold"
                                    title="{{selectedTrip?.endDateTime?.toLocaleString()}}">
                                    {{ selectedTrip?.endDateTime | amDateFormat:'ll HH:mm' }}
                                </h3>
                                <div> {{'general.end' | translate }}</div>
                            </div>
                        </div>
                        <div class="col-lg-4" style="padding:0">
                            <div class="kpi-item-small">
                                <h3 class="margin-bottom font-bold">
                                    {{ selectedTrip?.duration }}
                                </h3>
                                <div> {{'general.duration' | translate }}</div>
                            </div>
                        </div>
                        <div class="col-lg-4" style="padding:0">
                            <div class="kpi-item-small">
                                <h3 class="margin-bottom font-bold">
                                    {{ actualRound(selectedTrip?.segmentDistanceInKilometers,2)?.toLocaleString()
                                    }}
                                    {{ translatedKm }}
                                </h3>
                                <div> {{'general.distanceGpsBased' | translate }}</div>
                            </div>
                        </div>
                        <div class="col-lg-4" style="padding:0">
                            <div class="kpi-item-small">
                                <h3 class="margin-bottom font-bold">
                                    {{ actualRound(selectedTrip?.calibratedOdoValueEnd, 0)?.toLocaleString() }}
                                    {{ translatedKm }}
                                </h3>
                                <div> {{'general.odo' | translate }}</div>
                            </div>
                        </div>
                        <div class="col-lg-4" style="padding:0">
                            <div class="kpi-item-small">
                                <h3 class="margin-bottom font-bold">
                                    {{ selectedTrip?.maxSpeed }} {{ translatedKmh }}
                                </h3>
                                <div> {{'general.maxSpeed' | translate }}</div>
                            </div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>
            </div>

            <div class="ibox" style="position: relative;">
                <ngx-loading [show]="loadingGeofences"></ngx-loading>
                <fh-leaflet-map (mapReady)="onMapReady($event)" [geofences]="geofences" [height]="mapHeight"
                    [borderRadius]="8" [geofenceEnabled]="false" [allowBookmarks]="false" [isSpinning]="loadingDetails"
                    (mapResized)="onMapResized()" [geofenceFromTrip]="true">
                </fh-leaflet-map>
            </div>

            <div class="ibox" style="position: relative;">
                <div class="ibox-content" style="padding: 5px; position: relative;">
                    <highcharts-chart [runOutsideAngular]="true" *ngIf="speedChart && selectedTrip"
                        style="display: block; width: 100%; height:220px" [Highcharts]="Highcharts"
                        [options]="speedChart">
                    </highcharts-chart>
                </div>
                <div class="ibox-footer" *ngIf="selectedTrip">
                    <ng-container>
                        <button *ngIf="!myMovingMarker" class="btn btn-info"
                            title="{{ 'general.playTrip' | translate }}" (click)="playTrips()">
                            <i class="fa fa-fw fa-play"></i>
                            <span class="d-none d-md-inline-flex"> {{'general.play' | translate
                                }}</span>
                        </button>

                        <button *ngIf="myMovingMarker" class="btn btn-info"
                            title="{{ 'general.resumeTrip' | translate }}" (click)="resumeTrip()">
                            <i class="fa fa-fw fa-play"></i>
                            <span class="d-none d-md-inline-flex"> {{'general.resume' | translate
                                }}</span>
                        </button>

                        <button *ngIf="myMovingMarker" class="btn btn-info"
                            title="{{ 'general.pauseTrip' | translate }}" (click)="pauseTrip()">
                            <i class="fa fa-fw fa-pause"></i>
                            <span class="d-none d-md-inline-flex"> {{'general.pause' | translate
                                }}</span>
                        </button>

                        <button *ngIf="myMovingMarker" class="btn btn-info" title="{{ 'general.stopTrip' | translate }}"
                            (click)="stopTrip()">
                            <i class="fa fa-fw fa-stop"></i>
                            <span class="d-none d-md-inline-flex"> {{'general.stop' | translate
                                }}</span>
                        </button>
                        <!-- <button class="btn btn-info" title="{{ 'general.showReport' | translate }}"
                                    (click)="showReport()">
                                    <i class="fa fa-fw fa-user-chart"></i>
                                    <span class="d-none d-md-inline-flex"> {{'general.showReport' | translate
                                        }}</span>
                                </button> -->
                    </ng-container>

                    <span class="float-right">
                        <ng-select class="form-control" name="speedCalculator" [(ngModel)]="speedCalculator"
                            (ngModelChange)="speedChanged()" [searchable]="false" [clearable]="false"
                            style="width:100px">
                            <ng-option [value]=1>Fast</ng-option>
                            <ng-option [value]=4>Normal</ng-option>
                            <ng-option [value]=10>Slow</ng-option>
                        </ng-select>
                    </span>
                    <div style="clear: both"></div>
                </div>
            </div>



            <div class="ibox" style="position: relative;">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-location-arrow"></i> {{'general.episodes' | translate }}
                    </h5>
                </div>
                <div class=" ibox-content" style="position: relative;"
                    *ngIf="!selectedTrip || leafletMapComponent?.tripLayer.getLayers().length > 1">
                    <ngx-loading [show]="loadingDetails || loadingGeofences || mapBusy">
                    </ngx-loading>
                    <span style="width: 100%" *ngIf="leafletMapComponent?.tripLayer.getLayers().length > 1">
                        {{'general.selectOnlyOneTrip' | translate }}
                    </span>
                    <span *ngIf="!selectedTrip">
                        {{'general.noTripSelected' | translate }}
                    </span>
                </div>
                <div class=" ibox-content slick" style="position: relative;"
                    *ngIf="selectedTrip && !(leafletMapComponent?.tripLayer.getLayers().length > 1)">

                    <div class="ibox-content slick" style="position: relative;"
                        *ngIf="selectedTrip && !(leafletMapComponent?.tripLayer.getLayers().length > 1)">
                        <table class="table nowrap dtr-inline no-footer low-opacity"
                            style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <tr class="hand" *ngIf="selectedTrip?.tripType != 3"
                                    (click)="displayLocation(selectedTrip?.endLocation)">
                                    <td style="width:50px; padding-left:18px">
                                        <i class="fa fa-fw fa-flag-checkered"></i>
                                    </td>
                                    <td style="width:60px; text-align: right;"
                                        title="{{selectedTrip?.endDateTime?.toLocaleString()}}"> {{
                                        selectedTrip?.endDateTime | amDateFormat:'HH:mm' }}</td>
                                    <td> {{'general.end' | translate }} in {{ selectedTrip?.endAddressFull }}
                                    </td>
                                </tr>
                                <tr class="hand" (click)="displayLocation(event.location, event.geofence, event)"
                                    *ngFor="let event of selectedTrip?.concatenatedEvents; let i = index;">
                                    <td style="width:50px; padding-left:18px"><i class="fa fa-fw "
                                            [ngClass]="event?.icon" [style.color]="event?.iconFontColor"></i></td>
                                    <td style="width:60px; text-align: right;"
                                        title="{{event.startUtc?.toLocaleString()}}">{{event.startUtc
                                        |
                                        amDateFormat: 'HH:mm' }}</td>
                                    <td><span [innerHTML]="event.description"></span></td>
                                </tr>
                                <tr class="hand" (click)="displayLocation(selectedTrip?.beginLocation)">
                                    <td style="width:50px; padding-left:18px"><i class="fa fa-fw fa-car-bus"></i>
                                    </td>
                                    <td style="width:60px; text-align: right;"
                                        title="{{selectedTrip?.beginDateTime?.toLocaleString()}}"> {{
                                        selectedTrip?.beginDateTime | amDateFormat:'HH:mm' }}</td>
                                    <td> {{'general.start' | translate }} in {{
                                        selectedTrip?.beginAddressFull }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>