export class CustomCommand {
    id: any;
    userId: any;
    accountId: any;
    resellerId: any;
    companyName: any;
    resellerDescription: any;
    name: any;
    isResellerOnly: boolean;
    timestamp: any;
    implementations: CustomCommandImplementation[];
}

export class CustomCommandImplementation {
    deviceTypeId: number;
    value: any;
}
