<div class="ibox-content slick" style="position:relative;">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width:100%" class="table nowrap dtr-inline no-footer">
        <tbody *ngIf="!(sensorTemplate && sensorTemplate.id) && formMode == 1">
            <tr>
                <td colspan="3">
                    {{ 'general.noDataFound' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="(sensorTemplate && sensorTemplate.id) || formMode != 1">
            <ng-container *ngIf="formMode != 2">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                    </td>
                    <td colspan="2" class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder"
                            href="/#/ResellerDetails/Index/{{sensorTemplate?.resellerId}}">{{sensorTemplate?.resellerDescription
                            }}</a>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ 'general.account' | translate}}
                    </td>
                    <td colspan="2" class="row_content padding-readonly-correct">
                        <a class="secondary link_bolder"
                            href="/#/AccountDetails/Index/{{sensorTemplate?.accountId}}">{{sensorTemplate?.companyName
                            }}</a>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode == 2">
                <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td colspan="2" class="row_content" style="position:relative; overflow: visible">
                        <fh-reseller-input name="reseller" #resellerVar="ngModel" (onChanged)="resellerChanged($event)"
                            [tableInline]="true" required placeholder="{{ 'general.chooseReseller' | translate}}"
                            [(ngModel)]="selectedResellerId">
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View'] && sensorTemplate.accountId">
                    <td class="row_opening">
                        {{ 'general.account' | translate}}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td colspan="2" class="row_content" style="position:relative; overflow: visible">
                        <fh-account-input name="account" #accountVar="ngModel" (onChanged)="accountChanged($event)"
                            required [selectedResellerId]="selectedResellerId" [tableInline]="true"
                            placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                            [(ngModel)]="sensorTemplate.accountId">
                        </fh-account-input>
                    </td>
                </tr>
            </ng-container>
            <!-- name -->
            <tr>
                <td class="row_opening">
                    {{ 'general.name' | translate}}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="name" class="form-control table-inline"
                        placeholder="{{ 'placeholder.triggerName' | translate}}" [(ngModel)]="sensorTemplate.name"
                        required minlength="3" #name="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.type' | translate}}
                    <span class="error" *ngIf="sensorType.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" class="row_content" style="overflow: visible">
                    <ng-select class="form-control table-inline" required #sensorType="ngModel" name="triggerType"
                        [clearable]="false" [disabled]="formMode == 1" [searchable]="false"
                        placeholder="{{ 'general.triggerType' | translate }}" [(ngModel)]="sensorTemplate.sensorType"
                        style="width:100%">
                        <ng-option [value]=1> {{ 'enums.sensorTemplateType.1' | translate}} </ng-option>
                        <ng-option [value]=2> {{ 'enums.sensorTemplateType.2' | translate}} </ng-option>
                        <ng-option [value]=3> {{ 'enums.sensorTemplateType.3' | translate}} </ng-option>
                        <ng-option [value]=4> {{ 'enums.sensorTemplateType.4' | translate}} </ng-option>
                        <ng-option [value]=5> {{ 'enums.sensorTemplateType.5' | translate}} </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.minValue' | translate}}
                    <span class="error" *ngIf="minValue.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="number" [readonly]="formMode == 1" name="minValue" class="form-control table-inline"
                        [(ngModel)]="sensorTemplate.minValue" #minValue="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.maxValue' | translate}}
                    <span class="error" *ngIf="maxValue.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="number" [readonly]="formMode == 1" name="maxValue" class="form-control table-inline"
                        [(ngModel)]="sensorTemplate.maxValue" #maxValue="ngModel">
                </td>
            </tr>
        </tbody>
    </table>
</div>