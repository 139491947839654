import { Component, Input, OnInit, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormMode } from 'app/common/enums';
import { AssetGroupItem, DriverGroupItem, GeofenceGroupItem, Group } from 'app/models/group.model';
import { AssetService } from 'app/services/asset/asset.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DriverService } from 'app/services/driver/driver.service';
import { GeofenceService } from 'app/services/geofence/geofence.service';

@Component({
    selector: 'fh-group-overview',
    templateUrl: 'groupOverview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupOverviewComponent implements OnInit, OnChanges {
    @Input() groups: Group[] = [];
    @Input() loading = false;
    @Input() name: string;
    @Input() itemsPerPage = 10;
    @Input() showCount = false;
    @Input() showAccount = true;
    @Input() showItem = false;
    @Input() linkUrl = 'GeofenceGroupDetails';

    @Input() deletePermission: string;
    @Input() childId: number;

    @Input() formMode: any = FormMode.read;

    @Output() onFinish = new EventEmitter();

    selectedItems = {};

    permissions: {};
    // Pagination
    totalItems = 0;
    currentPage = 1;
    smallnumPages = 0;

    filter;

    success;
    error;

    constructor(private translate: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private assetService: AssetService, private driverService: DriverService, private geofenceService: GeofenceService) {
        this.permissions = this.authenticationService.permissions;
    }

    ngOnChanges(): void {

        this.groups.forEach(group => {
            group['layerRef'] = null;
        });

        this.totalItems = this.groups.length;
        this.cd.markForCheck();
    }

    ngOnInit(): void {
    }

    loadItems(items) {
        console.log('loaded', items);
        this.groups = items;

        this.groups.forEach((item) => {
            this.selectedItems[item.id] = item['selected'];
        })

        this.cd.markForCheck();
    }

    changeSelectedItem(item) {
        this.selectedItems[item.id] = !this.selectedItems[item.id];
    }

    // Pagination
    setPage(pageNo: number): void {
        this.currentPage = pageNo;
    }

    pageChanged(event: any): void {
        console.log('Page changed to: ' + event.page);
        console.log('Number items per page: ' + event.itemsPerPage);
    }

    isSystemGroup(group) {
        switch (true) {
            case group instanceof AssetGroupItem:
                return group.name === 'MyManagedDevices' || group.groupType === 3;
            case group instanceof DriverGroupItem:
                return group.name === 'MyManagedDrivers' || group.groupType === 11;
            case group instanceof GeofenceGroupItem:
                return group.name === 'MyManagedGeofences';
            default:
                return true;
        }
    }

    deleteItem(group, childId) {
        console.log('deleting from', group instanceof AssetGroupItem, childId);
        this.loading = true;

        switch (true) {
            case group instanceof AssetGroupItem:
                this.assetService.removeFromGroups(childId, { assetId: childId, assetGroups: [group.id] }).subscribe(
                    this.onDeleteItemSuccess.bind(this),
                    this.onDeleteItemError.bind(this),
                );
                break;
            case group instanceof DriverGroupItem:
                this.driverService.removeFromGroups(childId, { driverId: childId, driverGroups: [group.id] }).subscribe(
                    this.onDeleteItemSuccess.bind(this),
                    this.onDeleteItemError.bind(this),
                );
                break;
            case group instanceof GeofenceGroupItem:
                this.geofenceService.removeFromGroups(childId, { geofenceId: childId, geofenceGroups: [group.id] }).subscribe(
                    this.onDeleteItemSuccess.bind(this),
                    this.onDeleteItemError.bind(this),
                );
                break;
            default:
                this.loading = false;
                break;
        }

        this.cd.markForCheck();
    }

    onDeleteItemSuccess(result: any) {
        this.error = null;
        this.success = null;

        if (result.isSuccess) {
            this.success = {
                statusText: 'Success',
                success: 'Delete has been successful',
            };
        }

        this.onFinish.emit();
    }

    onDeleteItemError(result: any) {
        this.error = null;
        this.success = null;

        this.error = {
            statusText: 'Error',
            error: 'Delete has not been successful',
        };

        this.onFinish.emit();
    }
}

