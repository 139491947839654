<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/">{{'general.notifications' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/Triggers/Overview">{{'general.triggers' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Notifications/TriggerDetails/Index/{{trigger?.id}}">{{trigger?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.dashboard' | translate }}</li>
            </ol>
        </nav>

        <span class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix d-none d-md-inline-flex" title="Help"
                href=" https://help.360locate.com/knowledge-base/triggers/" target="_blank"><i class="fa fa-fa fa-book"
                    style="margin-right: 6px"></i> <span class="d-none d-md-inline-flex">{{'general.needHelp' |
                    translate }}</span></a>
        </span>
    </div>
    <fh-notifications-navigation></fh-notifications-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [warning]="warning" [error]="error"></fh-error>
            </div>
        </div>
        <form #userForm="ngForm">
            <div class="row">
                <div class="col-lg-6">

                    <div class="ibox" style="position:relative">
                        <ngx-loading [show]="loading || saving"></ngx-loading>
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-fw fa-bell-on"></i> {{'general.trigger' | translate }}
                            </h5>
                        </div>
                        <fh-trigger-details [trigger]="trigger" [formMode]="formMode">
                        </fh-trigger-details>
                        <div class="ibox-footer">
                            <button class="btn btn-info" [routerLink]="['/Notifications/TriggerDetails/Details/', trigger.id]"
                                title="{{ 'general.details' | translate }}"><i class="fa fa-fw fa-edit"></i>
                                {{'general.details' | translate }}</button>

                            <span class="float-right">
                                <button class="btn btn-info"
                                    [disabled]="!(!userForm.invalid && trigger.assetGroups.length > 0 && trigger.rules.length > 0)"
                                    (click)="testMessage();" title="{{ 'general.test' | translate }}"><i
                                        class="fa fa-fw fa-fire"></i>
                                    {{'general.test' | translate }}</button>
                            </span>
                            <div style="clear:both"></div>
                        </div>
                    </div>

                    <div class="ibox" style="position:relative">
                        <ngx-loading [show]="loadingAssets"></ngx-loading>
                        <div class="ibox-title">
                            <h5>
                                <h5><i class="fas fa-fw fa-car"></i> {{'general.assets' | translate }} </h5>
                            </h5>
                            <span class="float-right">
                                <div class="filter-form d-none d-md-block">
                                    <input type="text" class="form-control table-inline top-filter d-none d-md-block"
                                        [(ngModel)]="filter" [ngModelOptions]="{standalone: true}">
                                </div>
                            </span>
                        </div>
                        <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
                            <table style="width:100%" class="table nowrap dtr-inline no-footer">
                                <thead>
                                    <tr>
                                        <th>{{'general.name' | translate }}</th>
                                        <th class="elipses_overflow" style="width: 100px"
                                            title="{{'general.episodeCount48Hours | translate }}">
                                            {{'general.episodeCount48Hours' | translate }}</th>
                                        <th class="elipses_overflow" style="width: 100px"
                                            title="{{'general.notificationCount48Hours' | translate }}">
                                            {{'general.notificationCount48Hours' | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="assets.length > 0">
                                    <tr
                                        *ngFor="let asset of assets | orderBy : 'assetName':false | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage}">
                                        <td>
                                            <a href="/#/DeviceDetails/Index/{{asset?.deviceId}}">
                                                <span class="secondary link_bolder"><i
                                                        class="fas fa-fw fa-angle-right"></i></span>
                                                {{asset?.assetName}}</a>
                                        </td>
                                        <td style="width: 100px">{{asset?.episodeCount}}</td>
                                        <td style="width: 100px">{{asset?.notificationCount}}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="assets.length == 0">
                                    <tr>
                                        <td colspan="3">
                                            {{ ('general.noDataFound') | translate}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="ibox-footer noselect dataTables_wrapper">
                            <pagination-template #ut="paginationApi" (pageChange)="currentPage = $event">
                                <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                    <ul class="pagination">
                                        <li class="paginate_button page-item previous"
                                            [class.disabled]="ut.isFirstPage()">
                                            <a class="page-link" (click)="ut.previous()" *ngIf="!ut.isFirstPage()">{{
                                                'grid.previous' |
                                                translate }}</a>
                                            <a *ngIf="ut.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                        </li>

                                        <li class="paginate_button page-item " *ngFor="let page of ut.pages"
                                            [class.current]="ut.getCurrent() === page.value">
                                            <a class="page-link" (click)="ut.setCurrent(page.value)"
                                                *ngIf="ut.getCurrent() !== page.value">
                                                <span>{{ page.label }}</span>
                                            </a>
                                            <a class="page-link" (click)="ut.setCurrent(page.value)"
                                                *ngIf="ut.getCurrent() === page.value">
                                                <span style="font-weight: bold;">{{ page.label }}</span>
                                            </a>
                                        </li>

                                        <li class="paginate_button page-item next" [class.disabled]="ut.isLastPage()">
                                            <a class="page-link" (click)="ut.next()" *ngIf="!ut.isLastPage()">
                                                {{ 'grid.next' | translate }}
                                            </a>
                                            <a class="page-link" *ngIf="ut.isLastPage()"> {{ 'grid.next' | translate }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </pagination-template>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="ibox">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-location-arrow"></i> {{'general.activity' | translate }}
                            </h5>
                        </div>
                        <div class="ibox-content" style="min-height:200px; position: relative">
                            <ngx-loading [show]="loadingEpisodeCount"></ngx-loading>

                            <highcharts-chart *ngIf="chartCount" [runOutsideAngular]="true"
                                style="display: block; width: 100%; height:200px" [Highcharts]="Highcharts"
                                [options]="chartCount">
                            </highcharts-chart>
                        </div>
                    </div>

                    <fh-tagging [objectId]="trigger?.id" [objectType]="tagType">
                    </fh-tagging>
                </div>
            </div>
        </form>
    </div>
</fh-permission-handler>