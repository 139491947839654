import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewChild,
} from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";

import { CustomCommandDetailsComponent } from "app/views/shared/usercontrols/customCommandDetails.component";

// Moment
import * as Moment from "moment";
import * as mTZ from "moment-timezone";

import { FormMode } from "app/common/enums";

window["moment"] = Moment;
mTZ();

@Component({
    selector: "fh-custom-command-add",
    templateUrl: "addCommand.template.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCommandAddViewComponent implements OnInit {
    @ViewChild(CustomCommandDetailsComponent, { static: true })
    commandParent: CustomCommandDetailsComponent;

    formMode = FormMode.add;

    constructor() { }

    ngOnInit() { }
}
