<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Warehouses/Overview">{{'general.warehouses' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="/#/Warehouses/Add">{{'menu.add' | translate }}</a>
                </li>
            </ol>
        </nav>
    </div>
    <fh-warehouse-navigation>
        <li class="active">
            <a href="/#/Warehouses/Add/"><i class="fas fa-fw fa-warehouse"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a>
        </li>
    </fh-warehouse-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-warehouse"></i> {{'general.warehouses' | translate }}
                            </h5>
                        </div>
                        <fh-warehouse-details [warehouse]="warehouse" [formMode]="formMode" [loading]="loading"
                            (onAccountChanged)="accountChanged($event)"></fh-warehouse-details>
                        <fh-save-footer [valid]="!userForm.invalid && geofence.geoJson != null" (onInsert)="onInsert()"
                            [allowAdd]="permissions['Warehouses_Add']" [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">
                <div class="ibox">
                    <fh-leaflet-map [geofences]="[geofence]" [editableGeofence]="geofence" [height]="450"
                        [allowEdit]="allowEdit" [showSearch]=true (mapReady)="onMapReady()"
                        [allowBookmarks]="allowBookmarks" (onSave)="onSaveShape($event)">
                        <div header class="ibox-title">
                            <span class="pull-left">
                                <h5>
                                    <i class="fa fa-fw fa-map-marked-alt"></i> {{'general.map' | translate }}
                                </h5>
                            </span>
                        </div>
                    </fh-leaflet-map>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>