<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Projects/Overview">{{'general.projects' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="/#/Projects/Add">{{'menu.add' | translate }}</a>
                </li>
            </ol>
        </nav>
    </div>
    <fh-project-navigation>
        <li class="active"><a href="/#/Projects/Add/"><i class="fas fa-fw fa-gavel"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a></li>
    </fh-project-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-gavel"></i> {{'general.project' | translate }}
                            </h5>
                        </div>
                        <fh-project-details [project]="project" [formMode]="formMode" [loading]="loading"
                            (onAccountChanged)="accountChanged($event)"></fh-project-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()"
                            [allowAdd]="permissions['Projects_Add']" [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-md-6">
                <div class="ibox" style="position:relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-gavel"></i> {{'general.info' | translate }}</h5>
                    </div>
                    <div class="ibox-content">
                        Project assignments can be done after the project has been created.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="padding: 20px 0px; clear: both;"></div>
</fh-permission-handler>