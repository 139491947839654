import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getGridButtons, getGridLanguages, createSearch } from 'app/common/gridhelper';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Observable, forkJoin } from 'rxjs';
import { DriverGroupsService } from 'app/services/driver/driverGroups.service';
import { GridBase360Directive } from 'app/common/360Grid.base';
import { localizeSystemGroupNames } from 'app/common/globals';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { StorageHelper } from 'app/common/storagehelper';
import { ColorService } from 'app/services/common/color.service';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-driver-groups',
  templateUrl: 'groups.template.html'
})
export class DriverGroupViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  token: string;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  permissionName = 'DriverGroups_View';
  constructorName = 'DriverGroupViewComponent';
  timezoneIana: string;

  error;

  constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private driverGroupService: DriverGroupsService, protected storageHelper: StorageHelper, private colorService: ColorService) {
    super(storageHelper);

    this.timezoneIana = this.authenticationService.getTimeZoneIana()
    this.token = this.authenticationService.getAuthToken();

    // Get all the date for dropdown boxes
    forkJoin(
      this.translateService.get('general.date')
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      err => console.error(err)
    );
  }

  fireFilter(): void {
    console.log('fire update');
    this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {

      // Fire the request
      dtInstance.draw();
    });
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  checkFilters() {
    this.fireFilter();
  }

  initGrid(): void {
    const that = this;

    this.permissions = this.authenticationService.permissions;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: 'id',
        data: 'id',
        className: 'noVis',
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: '20',
        render: function (data, type, row) {
          return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/DriverGroupDetails/Index/' + data + '\'><span class="d-none d-md-inline-flex" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
        }
      }, {
        name: 'id_export',
        data: 'id',
        className: 'noVis',
        title: this.translateService.instant('general.id'),
        visible: false,
      },
      {
        name: 'name',
        data: 'name',
        title: this.translateService.instant('general.name'),
        render: function (data, type, row) {
          return localizeSystemGroupNames(data, that.translateService);
        }
      },
      {
        name: 'itemCount',
        data: 'itemCount',
        type: 'num',
        title: this.translateService.instant('general.itemCount')
      },
      {
        name: 'groupType',
        data: 'groupType',
        title: this.translateService.instant('general.groupType'),
        visible: !!this.permissions['Modules_HasPrivateFleet'],
        type: 'select',
        options: [
          { id: '1', value: 'Default' },
          { id: '17', value: 'Sector' },
          { id: '18', value: 'Management' },
          { id: '19', value: 'Department' },
        ],
        render: (data, type, row) => {
          return this.translateService.instant('enums.groupType.' + data);
        },
      },
      {
        name: 'parentGroupName',
        data: 'parentGroupName',
        defaultContent: '-',
        visible: !!this.permissions['Modules_HasPrivateFleet'],
        title: this.translateService.instant('general.parent'),
      },
      {
        name: 'companyName',
        data: 'companyName',
        defaultContent: '-',
        title: this.translateService.instant('general.companyName')
      },
      {
        name: 'timestamp',
        data: 'timestamp',
        type: 'date',
        title: this.translateService.instant('general.createdDate'),
        render: function (data, type, row) {
          const date = Moment.utc(data)['tz'](that.timezoneIana);
          return date.format('lll')
        }
      },
    ];

    const excludedColumns = ['id', 'id_export'];

    if (!this.permissions['Modules_HasPrivateFleet']) {
      excludedColumns.push('groupType', 'parentGroupName');
    }

    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'groups_overview', this.translateService.instant('menu.groupsoverview'), this.colorService),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => { try {
          const columnSettings = await that.loadState(that.constructorName);
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          that.error = {};
          that.error.error = e;
          that.error.statusText = 'Error fetching column settings';

          return null;
        }})().then(result => {
          callback(result);
        });
      },
      order: [[2, 'asc']],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.driverGroupService.getPagingUrl(),
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        console.log('init complete');
        that.checkFilters();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: excludedColumns,
      },
      columnDefs: [{ targets: [4, 5], className: this.permissions['Modules_HasPrivateFleet'] ? '' : 'hidden' }],
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
    };
  }
}
