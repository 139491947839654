import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, Inject, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode, StorageType, EntityType } from 'app/common/enums';
import { Device, DeviceSettings } from 'app/models/device.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DeviceService } from 'app/services/device/device.service';
import { AccountService } from 'app/services/account/account.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FhChartService } from '../../services/charts/charts.service';
import { ColorService } from '../../services/common/color.service';
import { LocationService } from '../../services/locations/locations.service';
import { timer } from 'rxjs/internal/observable/timer';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { AssetGroupsService } from 'app/services/asset/assetGroups.service';
import { ConfirmationModalComponent } from '../shared/usercontrols/confirmationModal.component';
import { AssetService } from 'app/services/asset/asset.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

// Moment timezone
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { ArchivingModalComponent } from '../shared/usercontrols/archivingModal.component';
import { Asset } from 'app/models/asset.model';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { getIconPath, roundAsNumber, roundAsString, roundMinutes, roundSeconds } from 'app/common/globals';
import { TriggerService } from 'app/services/triggers/triggers.service';
import { Trigger } from 'app/models/trigger.model';
import { getDefaultDpConfig } from 'app/common/gridhelper';
import { TranslateService } from '@ngx-translate/core';
import { StorageHelper } from 'app/common/storagehelper';
import * as Highcharts from 'highcharts';
import { colorMapper } from 'app/common/leafletGlobals';
import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';
import { ScheduleAssignment } from 'app/models/triggerSchedule.model';
import { DriverService } from 'app/services/driver/driver.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DistanceUnitService } from 'app/common/distanceunit.service';
import { SensorTemplateService } from 'app/services/sensorTemplate/sensorTemplate.service';

window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-fh-device-share-details',
    templateUrl: 'deviceShareDetails.template.html',
    providers: [FhChartService],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        fadeInOnEnterAnimation({ anchor: 'enter', duration: 1000, delay: 100 }),
    ]
})
export class DeviceShareDetailsViewComponent implements OnInit, OnDestroy {
    @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;
    Highcharts: typeof Highcharts = Highcharts;

    @ViewChild('confirmModal', { static: false }) confirmModal: ConfirmationModalComponent;
    @ViewChild('archiveModal', { static: false }) archiveModal: ConfirmationModalComponent;
    @ViewChild('archiveBody', { static: false }) archiveBody: ArchivingModalComponent;

    tagType = EntityType.Device.valueOf();
    chartColumnRangeEnabled: boolean;
    chartLocationCount;
    chartDistance;

    progress = [];
    hasAdvice: any;
    hasSchedule: any;
    hasNotes: any;
    renderDateTime: number;
    insertAdviseSchedule: boolean;
    deviceType: any;

    note: string;
    isFlagged: boolean;

    sub: Subscription;
    showMapOnSide = false;
    loading = false;
    saving = false;
    loadingNote = false;
    loadingFlagged = false;
    loadingLocationCount = false;
    loadingLocation = true;
    showUtilization = false;
    unmappedPorts;

    storageType = StorageType.LocalStorage;

    device: Device;
    deviceId;

    marker;

    chartlocationType;
    permissions: {};

    locationSubscription: Subscription;

    formMode = FormMode.read;
    formModeAsset = FormMode.read;

    error: any;
    warning: any;

    success: { statusText: string; success: string; };

    locationCount = [];
    locationData = [];

    geofences = [];

    scheduleAssignment: ScheduleAssignment;

    // Device state
    previousLookupTimestamp: Date;
    lastCommunication;

    loadingGroups = false;
    assetGroups = [];
    assetGroupLink = 'AssetGroupDetails';
    loadingGeofences = false;
    distanceChartData = [];
    loadingCount: boolean;
    timezoneIana: string;
    previousLookupDisplay: any;

    isSpinning = false;
    showUnMapped: boolean = false;

    constructorName = 'DeviceShareDetailsViewComponent';

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
    showPosition: boolean = false;
    violations: any = [];
    daterangepickerModel: Date[];
    loadingUtilization = false;
    loadingTriggers = false;
    deviceUtilization;
    triggers: Trigger[] = [];
    hideTriggers = false;
    hideUtilization = false;
    mapHeight = 300;
    showMapInHeader = false;
    showWarningMessage = false;
    compareDate = new Date(new Date().setDate(new Date().getDate() - 3));
    deviceState: any;
    scannedDriver: any;
    colorMapper = colorMapper;

    playerOptions = {};
    playerDashcamUrls = [];
    sources = [];

    translatedKmh: any = "km/h";
    translatedKm: any = "km";

    sensorTemplates: any[];
    loadingSensorTemplates: boolean;

    hasCanbus = false;

    constructor(private authenticationService: AuthenticationService,
        private cd: ChangeDetectorRef,
        private driverService: DriverService,
        private deviceService: DeviceService,
        private distance: DistanceUnitService,
        private locationService: LocationService,
        private assetService: AssetService,
        private route: ActivatedRoute,
        private router: Router,
        private triggerService: TriggerService,
        private authentication: AuthenticationService,
        private accountService: AccountService,
        private assetGroupsService: AssetGroupsService,
        private translateService: TranslateService,) {

        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.device = null;

        this.scheduleAssignment = null;

        this.translateService.get('general.date').subscribe(
            data => {
                this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
                this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
                this.cd.markForCheck();
            }
        );
    }

    clearViolations() {
        this.violations = [];
        this.cd.markForCheck();
    }

    setDeviceDetails(device: Device): any {
        if (device == null) {
            this.router.navigate(['/Devices/Overview']);
        } else {
            this.device = device;
            this.showWarningMessage = this.device?.asset?.isCommentWarning;
            this.violations = [];
            this.sources = [];

            var customFields = device?.asset?.properties?.custom;
            if (customFields) {
                var streamKeys = Object.values(customFields).filter(x => x['key'] == 'video');
                streamKeys.forEach(streamKey => {
                    var streamUrl = streamKey && streamKey['value'];

                    if (streamUrl) {
                        // check video player
                        this.sources.push({
                            src: streamUrl,
                            type: 'application/x-mpegURL',
                        })
                    }
                });
            }

            var dashcams = device?.asset?.properties?.dashcams;
            if (dashcams) {
                var streamKeys = Object.values(dashcams);

                streamKeys.forEach(streamKey => {
                    var streamUrl = streamKey && streamKey['url'];

                    if (streamUrl) {
                        // check video player
                        this.sources.push({
                            src: streamUrl,
                            name: streamKey['name'],
                            type: 'application/x-mpegURL',
                        })
                    }
                });
            }

            if (this.sources.length > 0) {
                this.playerOptions = {
                    fluid: true,
                    aspectRatio: "16:9",
                    controls: true,
                    autoplay: false,
                    sources: this.sources,
                };
            }

            if (this.device?.asset?.calibrationOdoOffsetInKm) {
                this.device.asset.calibrationOdoOffsetInKm = this.distance.calculateDistanceUnitFromKmFixed(this.device.asset.calibrationOdoOffsetInKm);
            }

            // Check inventory
            if (this.device.asset && !this.device.isArchived && ((this.device.accountId && (this.device.accountId !== this.device.accountId)) || (this.device.resellerId && (this.device.resellerId !== this.device.resellerId)))) {
                this.scheduleAssignment.assetId = this.device.asset.id;
                this.warning = {
                    statusText: 'Warning',
                    warning: ''
                };

                if (this.device.companyName !== this.device.companyName) {
                    this.warning.warning += `The device inventory account ${this.device.companyName} is not the same as the asset account ${this.device.companyName}. `;
                }

                if (this.device.resellerDescription !== this.device.resellerDescription) {
                    this.warning.warning += `The device inventory reseller ${this.device.resellerDescription} is not the same as the asset reseller ${this.device.resellerDescription}. `;
                }
            }

            this.loadingNote = false;
            this.loadingTriggers = true;
        }
    }

    ngOnDestroy(): void {
        if (this.locationSubscription !== undefined) {
            this.locationSubscription.unsubscribe();
        }
    }

    onMapReady(map) {
        setTimeout(() => {
            this.leafletMapComponent.invalidateSize();
        }, 10);
    }

    ngOnInit() {
        const that = this;

        this.permissions = this.authentication.permissions;

        this.loading = true;
        this.loadingNote = true;
        this.loadingLocationCount = true;
        this.loadingLocation = true;
        this.loadingGroups = true;
        this.loadingLocationCount = true;
        this.loadingCount = true;

        this.device = new Device();
        this.device.settings = new DeviceSettings();

        this.scheduleAssignment = new ScheduleAssignment();

        this.device.id = '';

        this.sub = this.route.params.subscribe(params => {
            // reset device data for reload
            this.locationData = [];
            this.previousLookupTimestamp = null;

            const id = params['id'];

            this.deviceId = id;
            this.getDeviceInfo(true);
        });
    }

    getLocations(deviceId) {
        if (this.locationSubscription !== undefined) {
            this.locationSubscription.unsubscribe();
        }

        this.locationSubscription = timer(0, 30000).pipe(
            mergeMap(() => {
                // Start the spinner
                this.isSpinning = true;
                this.cd.markForCheck();

                return this.locationService.getDeviceStates([+deviceId], null, this.previousLookupTimestamp, 0, true, true)
            })
        ).subscribe(result => {
            // Stop the spinner
            setTimeout(() => { this.isSpinning = false; this.cd.markForCheck(); }, 500);
            this.loadingLocation = false;

            if (result?.deviceStates?.length > 0) {
                this.previousLookupTimestamp = new Date(result.timestamp);

                this.deviceState = result.deviceStates[0];

                if (this.deviceState?.odometer?.gpsOdometer) {
                    this.deviceState.odometer.gpsOdometer = this.distance.calculateDistanceUnitFromKmFixed(this.deviceState.odometer.gpsOdometer);
                }

                if (this.deviceState?.currentPosition?.speed) {
                    this.deviceState.currentPosition.speed = this.distance.calculateDistanceUnitFromKmFixed(this.deviceState.currentPosition.speed, 0)
                }

                this.previousLookupDisplay = this.deviceState.communicationState?.updateTimestamp ?? new Date();

                this.unmappedPorts = this.checkUnmappedPorts(this.device, this.deviceState);

                this.lastCommunication = this.deviceState.communicationState?.updateTimestamp ?? new Date();

                this.deviceState.markerColor = colorMapper(this.deviceState?.calculatedDeviceState?.deviceState);

                // tslint:disable:no-bitwise
                this.deviceState.hasGpsFix = (this.deviceState?.communicationState?.locationType & 2) > 0;
                this.deviceState.hasCellFix = (this.deviceState?.communicationState?.locationType & 1) > 0;
                // tslint:enable:no-bitwise

                let latitude = this.deviceState?.currentPosition?.latitude ?? null;
                let longitude = this.deviceState?.currentPosition?.longitude ?? null;
                let lastCommunication = this.deviceState?.currentPosition?.updateTimestamp;

                if (this.deviceState?.cellPosition && ((latitude === null && longitude === null) && !(this.deviceState.cellPosition.latitude === 0 && this.deviceState.cellPosition.longitude === 0) || !this.deviceState.hasGpsFix)) {
                    latitude = this.deviceState.cellPosition.latitude ?? null;
                    longitude = this.deviceState.cellPosition.longitude ?? null;
                    lastCommunication = this.deviceState.cellPosition.updateTimestamp;
                }

                if (this.deviceState?.tagScanStatus?.tag) {
                    this.driverService.getDriverByTag(this.deviceState?.tagScanStatus?.tag, this.device.accountId).subscribe(res => {
                        this.device.scannedDriver = res;
                        this.cd.markForCheck();
                    });
                }

                this.locationData = [{
                    assetName: this.device?.asset?.name,
                    assetCode: this.device?.asset?.code,
                    assetPlateNumber: this.device?.asset?.plateNumber,
                    companyName: this.device?.companyName,
                    icon: this.device?.asset?.icon,
                    deviceState: this.deviceState?.calculatedDeviceState?.deviceState,
                    data: this.deviceState,
                    headingInDegrees: this.deviceState?.currentPosition?.heading,
                    speed: this.deviceState?.currentPosition?.speed,
                    latitude: latitude,
                    longitude: longitude,
                    unitId: this.device?.unitId,
                    deviceId: this.device?.id,
                    deviceTypeId: this.device?.deviceTypeId,
                    lastCommunication: lastCommunication,
                    radiusInMeters: this.deviceState?.currentPosition?.radiusInMeters,
                    assetGroupIds: this.device?.asset?.assetGroups,
                    assetGroups: this.device?.asset?.assetGroups,
                    gpsPosition: !this.deviceState.hasGpsFix ? this.deviceState?.currentPosition : undefined,
                }];

                this.loadingLocation = false;
            } else {
                if (result.timestamp) {
                    this.previousLookupTimestamp = new Date(result.timestamp);
                }

                console.log(`No state found newer then ${this.previousLookupTimestamp}`);
            }

        }, error => {
            if (this.locationSubscription !== undefined) {
                this.locationSubscription.unsubscribe();
            }
            this.loadingLocation = false;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    actualRound(value, decimals) {
        return roundAsNumber(value, decimals);
    }

    actualRoundMinutes(value) {
        return roundMinutes(value);
    }

    actualRoundSeconds(value) {
        return roundSeconds(value);
    }

    getGeofences() {
        if (this.geofences.length === 0 && this.device.accountId != null && this.device.accountId > 0) {
            this.loadingGeofences = true;
            this.accountService.getGeofencesByAccount(this.device.accountId, true).subscribe(geofences => {
                this.geofences = geofences;
                this.loadingGeofences = false;
                this.cd.markForCheck();
            }, error => {
                this.loadingGeofences = false;
                this.success = null;
                this.error = error;
                this.cd.markForCheck();
            });
        }
    }

    getEngineColor(temperature) {
        if (temperature < 70) {
            return 'green';
        }

        if (temperature < 80) {
            return 'yellow';
        }

        if (temperature < 90) {
            return 'orange';
        }

        if (temperature < 100) {
            return 'darkorange';
        }

        if (temperature > 100) {
            return 'red';
        }

        return '#ccc';
    }

    getFuelColor(fuel) {
        if (fuel < 30) {
            return 'red';
        }

        if (fuel < 50) {
            return 'orange';
        }

        if (fuel < 70) {
            return 'yellow';
        }

        if (fuel < 100) {
            return 'green';
        }

        return '#ccc';
    }

    checkSensorOnTempate(value, templateId) {

        if (!this.sensorTemplates || this.sensorTemplates?.length == 0) {
            return;
        }

        const template = this.sensorTemplates?.find(x => x.id === templateId);

        if (value === undefined && templateId !== undefined) {
            return false;
        }

        if (template === undefined) {
            return true;
        }

        if (template.maxValue !== undefined && value > template.maxValue) {
            return false;
        }
        if (template.minValue !== undefined && value < template.minValue) {
            return false;
        }

        return true;
    }

    getDeviceInfo(firstRequest = false) {
        this.deviceService.getDeviceById(this.deviceId, true).subscribe(device => {
            if (firstRequest) {
                this.getLocations(device.id);
            }

            this.setDeviceDetails(device);
            this.getGeofences();
            this.cd.markForCheck();
        }, error => {
            this.loading = false;
            this.success = null;
            this.error = error;
            console.log(error);

            this.cd.markForCheck();
        });
    }

    checkUnmappedPorts(device, deviceState) {
        if ((device === undefined || device.settings.inputPorts === undefined) || deviceState === undefined) {
            return false;
        }

        const IOPorts = device.settings.inputPorts.reduce((result, { id, byte }) => (result[id] = byte, result), ({}));

        return [
            { id: 1, value: deviceState.input1State },
            { id: 2, value: deviceState.input2State },
            { id: 3, value: deviceState.input3State },
            { id: 4, value: deviceState.input4State },
            { id: 5, value: deviceState.input5State },
            { id: 6, value: deviceState.input6State },
        ].some(io => io.value !== undefined && IOPorts[io.id] === '0');
    }

    formatIconId(iconId) {
        return '<img style="position: relative;" src="' + getIconPath(iconId)[2] + '">';
    }
}
