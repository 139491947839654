<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()">
    <fh-wizard-step title="{{ 'general.chooseAccount' | translate }}" icon='fa-file-pdf' [validate]="groupForm" [padding]=false
        (open)="initForm()">
        <div class="wizard-content border-bottom" style="padding:12px">
            {{ 'wizard.selectYourClient' | translate}}
        </div>
        <div class="wizard-content" style="position: relative;">
            <form [formGroup]="groupForm" style="width: 100%; border-top: 0px">
                <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                    <tbody>
                        <tr>
                            <td style="width: 25%; border-bottom: 0px!important">
                                {{'general.reseller' | translate }}

                                <span class="error" *ngIf="groupForm.get('selectedReseller')?.errors">*</span>
                            </td>
                            <td style="width: 75%; border-bottom: 0px!important">
                                <fh-reseller-input name="reseller" (onChanged)="resellerChanged($event)" required
                                    placeholder="{{ 'general.chooseReseller' | translate}}" [tableInline]="true"
                                    formControlName="selectedReseller">
                                </fh-reseller-input>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 25%; border-bottom: 0px!important">
                                {{'general.account' | translate }}

                                <span class="error" *ngIf="groupForm.get('selectedAccount').errors">*</span>
                            </td>
                            <td style="width: 75%; border-bottom: 0px!important">
                                <fh-account-input name="account" (onChanged)="accountChanged($event)" required
                                    [selectedResellerId]="selectedResellerId" [tableInline]="true"
                                    placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                    formControlName="selectedAccount">
                                </fh-account-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon='fa-file-pdf'>
        <div class="wizard-content" style="padding-bottom: 10px">
            {{ 'wizard.optionalComment' | translate }}
        </div>
        <div class="wizard-content">
            <textarea class="form-control noresize stickypad" style="height:100px; width:100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}" [(ngModel)]="logItem"></textarea>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Summary" icon='fa-summary-pdf'>
        <div class="wizard-content" style="padding-bottom: 10px"
            [innerHTML]="'wizard.summary' | translate:{ count: gridSelection.length }">
        </div>
        <div class="ibox-content slick popupOverflow" style="position: relative; min-height:72px; overflow: auto">
            <table class="table nowrap dtr-inline no-footer table-summary">
                <thead>
                    <tr>
                        <th class="small_padding">{{ 'general.imei' | translate}}</th>
                        <th class="small_padding">{{ 'general.assetName' | translate}}</th>
                        <th class="small_padding">{{ 'general.old' | translate }} {{ 'general.companyName' | translate}}</th>
                        <th class="small_padding">{{ 'general.new' | translate }} {{ 'general.companyName' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let id of gridSelection; let i = index;">
                        <tr>
                            <td class="small_padding">
                                <input [value]="deviceInfo[id]?.name" readonly class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="deviceInfo[id]?.asset?.name ?? ('general.undefined' | translate)" readonly class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="deviceInfo[id]?.companyName" readonly class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="selectedAccountName" readonly class="form-control table-inline">
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </fh-wizard-step>
</fh-wizard>