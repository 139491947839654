import { Component, OnInit, ChangeDetectorRef, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode, StorageType } from 'app/common/enums';
import { AccountInventory } from 'app/models/account.model';
import { EntityType } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { WaslService } from 'app/services/wasl/wasl.service';

import { AccountService } from 'app/services/account/account.service';
import { UserService } from 'app/services/users/user.service';
import { AppUser } from 'app/models/user.model';
import { FhChartService } from 'app/services/charts/charts.service';

import { DeviceService } from 'app/services/device/device.service';
import { Device } from 'app/models/device.model';
import { roundAsNumber, roundAsString, roundMinutes, roundSeconds } from 'app/common/globals';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import * as moment from 'moment-timezone';
import { getDefaultDpConfig } from 'app/common/gridhelper';
import { AssetGroupsService } from 'app/services/asset/assetGroups.service';
import { StorageHelper } from 'app/common/storagehelper';
import { TranslateService } from '@ngx-translate/core';

window['moment'] = Moment;
mTZ();

import * as Highcharts from 'highcharts';
import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';
import { TriggerScheduleService } from 'app/services/triggerSchedule/triggerSchedule.service';
import { ScheduleAssignment } from 'app/models/triggerSchedule.model';
import { DistanceUnitService } from 'app/common/distanceunit.service';

@Component({
    providers: [FhChartService],
    selector: 'fh-fh-account-details',
    templateUrl: 'accountDetails.template.html'
})
export class AccountDetailsViewComponent implements OnInit {
    @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

    Highcharts: typeof Highcharts = Highcharts;

    sub: any;
    account = new AccountInventory;
    accountId;

    tagType = EntityType.Account.valueOf();
    formMode = FormMode.read;
    formModeWasl = FormMode.read;

    loading = false;
    saving = false;
    loadingAccountWasl = false;

    filter;
    filterAccounts;

    error: any;
    success: any;

    permissions: {};
    permissionName = 'Accounts_View';

    waslSuccess: { statusText: string; success: any; };
    waslError: any;

    activeDevices = 0;
    activeDrivers = 0;
    activeGeofences = 0;

    geofences = [];
    loadingGeofences = false;
    loadingUsers = false;
    loadingKpi = false;
    loadingDevices = false;
    loadingSubAccounts = false;
    loadingUtilization = false;
    users: AppUser[];

    showUtilization = true;

    loadingGroups = false;
    assetGroups = [];
    assetGroupLink = 'AssetGroupDetails';

    loadingDeviceCount = false;
    chartDeviceCount: any;

    // Pagination
    totalItems = 0;
    currentPage = 1;
    smallnumPages = 0;
    deviceCountData = [];
    distanceChartData = [];
    loadingCount = false;
    chartLocationCount: any;
    chartDistance: any;
    chartActiveDevices: any;
    devices = [];
    locationData: Device[] = [];
    subAccounts: AccountInventory[] = [];
    currentPageAccounts;
    timezoneIana;
    accountUtilization: any;
    utilization: any;
    daterangepickerModel: Date[] = [];
    dpConfig;

    mapHeight = 250;
    showMapOnSide = false;
    hideUtilization = false;
    storageType = StorageType.LocalStorage;

    scheduleAssignment: ScheduleAssignment;

    translatedKm: any = "km";
    translatedKmh: any = "km/h";

    constructor(private chartService: FhChartService, private router: Router, private distance: DistanceUnitService, private assetGroupsService: AssetGroupsService, private deviceService: DeviceService, private cd: ChangeDetectorRef, private authentication: AuthenticationService, private accountService: AccountService, private route: ActivatedRoute, private userService: UserService, private translateService: TranslateService, private storageHelper: StorageHelper, private triggerScheduleService: TriggerScheduleService) {
        this.permissions = this.authentication.permissions;
        this.timezoneIana = this.authentication.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(1, 'months').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).subtract(1, 'days').startOf('day').toDate()
        ];

        this.translateService.get('general.date').subscribe(
            data => {
                this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
                this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
                this.cd.markForCheck();
            }
        );

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'showMapOnSide').subscribe((result) => {
            this.showMapOnSide = JSON.parse(result) === true;

            if (this.showMapOnSide) { this.mapHeight = 330; }
            this.cd.markForCheck();
        });

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideUtilization').subscribe((result) => {
            this.hideUtilization = JSON.parse(result) === true;

            this.cd.markForCheck();
        });

        this.dpConfig = getDefaultDpConfig(Moment, authentication);

        this.scheduleAssignment = new ScheduleAssignment();
    }

    ngOnInit() {

        this.loading = true;
        this.loadingGeofences = true;
        this.loadingUsers = true;
        this.loadingKpi = true;
        this.loadingDeviceCount = true;
        this.loadingCount = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.accountId = id;
            this.accountService.getAccountById(id).subscribe(account => {
                this.account = account;
                this.scheduleAssignment.accountId = this.account.id;
                this.loading = false;
                this.cd.markForCheck();

                this.accountService.getKPISCount(id).subscribe(kpiResults => {
                    this.loadingKpi = false;
                    this.activeDevices = kpiResults.deviceCount;
                    this.activeGeofences = kpiResults.geofenceCount;
                    this.activeDrivers = kpiResults.driverCount;
                    this.cd.markForCheck();
                });

                this.accountService.getGeofencesByAccount(id).subscribe(geofences => {
                    this.loadingGeofences = false;
                    this.geofences = geofences;
                    this.cd.markForCheck();
                }, error => {
                    this.loadingGeofences = false;
                    this.success = null;
                    this.error = error;
                    this.cd.markForCheck();
                });

                this.userService.getUsersByAccountId(id).subscribe(result => {
                    this.users = result.filter(x => x.isActive === true && x.userLevel !== 12);
                    this.totalItems = this.users.length;

                    this.loadingUsers = false;
                    this.cd.markForCheck();
                }, error => {
                    this.loadingUsers = false;
                    this.success = null;
                    this.error = error;
                    this.cd.markForCheck();
                });

                this.accountService.getDevicesAdded(id).subscribe((devices) => {
                    this.loadingDeviceCount = false;
                    devices = devices.sort((a, b) => (a.date < b.date ? -1 : 1));
                    this.deviceCountData = devices;
                    this.cd.markForCheck();
                }, error => {
                    this.loadingDeviceCount = false;
                    this.success = null;
                    this.error = error;
                    this.cd.markForCheck();
                });

                this.loadingDevices = true;
                this.deviceService.getDevicesWithLocation(id).subscribe(devices => {
                    this.devices = devices.filter(x => x.isActive === true && x.isArchived === false);
                    this.loadingDevices = false;
                    this.drawLocations(this.devices);
                }, error => {
                    this.loadingDevices = false;
                    this.success = null;
                    this.error = error;
                    this.cd.markForCheck();
                });

                this.loadingSubAccounts = true;
                this.accountService.getSubAccounts(id).subscribe(subAccounts => {
                    this.subAccounts = subAccounts;
                    this.loadingSubAccounts = false;
                }, error => {
                    this.loadingSubAccounts = false;
                    this.success = null;
                    this.error = error;
                    this.cd.markForCheck();
                });

                this.getAssetGroupItems(id);

                this.dateChanged(event);

            }, error => {
                this.success = null;
                this.error = error;
                this.error.statusText = 'Error fetching account';

                this.cd.markForCheck();

                setTimeout(() => {
                    this.router.navigate(['/Accounts/Overview']);
                }, 3000);
            });
        });
    }

    getAssetGroupItems(accountId) {

        this.loadingGroups = true;
        this.assetGroupsService.getAssetGroupItemsByAccountId(accountId).subscribe(res => {
            this.assetGroups = res;

            this.assetGroups.forEach(group => {
                group.deviceId = null;
            });

            this.loadingGroups = false;
            this.cd.markForCheck();
        });
    }

    dateChanged(event) {
        if (this.showUtilization) {
            this.getUtilization();
        }
        this.getLocationCount();
    }

    getLocationCount() {
        this.accountService.getLocationCount(this.account.id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), null, null, null, null, null).subscribe(result => {
            this.loadingCount = false;
            result = result.sort((a, b) => (a.summaryDate < b.summaryDate ? -1 : 1));
            this.distanceChartData = result;
            this.generateChartLocationCount(this.distanceChartData);
            this.cd.markForCheck();
        }, error => {
            this.loadingCount = false;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    getUtilization() {
        this.loadingUtilization = true;
        this.accountService.getAccountUtilization(this.account.id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(
            utilization => {
                if (utilization.length > 0) {
                    this.utilization = utilization;

                    this.accountUtilization = {
                        assetCount: this.utilization.length,
                        assetCountWithTrips: this.utilization.filter(x => x.tripCount > 0).length,
                        tripCount: this.utilization.reduce((partial_sum, d) => partial_sum + d.tripCount, 0),
                        tripCountPrivate: this.utilization.reduce((partial_sum, d) => partial_sum + d.tripCountPrivate, 0),
                        totalDistance: this.utilization.reduce((partial_sum, d) => partial_sum + d.segmentsDistance, 0),
                        totalDuration: this.utilization.reduce((partial_sum, d) => partial_sum + d.tripDurationInSeconds, 0),
                        totalIdling: this.utilization.reduce((partial_sum, d) => partial_sum + d.idlingDurationInSeconds, 0),
                        totalGeofenceEventCount: this.utilization.reduce((partial_sum, d) => partial_sum + d.geofenceEventCount, 0),
                        totalDrivingEventCount: this.utilization.reduce((partial_sum, d) => partial_sum + (d.accelCount + d.corneringCount + d.decelCount), 0),
                        totalSpeedingEventCount: this.utilization.reduce((partial_sum, d) => partial_sum + (d.speedingCount + d.roadSpeedingCount), 0),
                        utilization: this.utilization.reduce((partial_sum, d) => partial_sum + d.utilization, 0),
                        maxSpeed: this.utilization.reduce((partial_sum, d) => (partial_sum > d.maxSpeed) ? partial_sum : d.maxSpeed, 0),
                    }
                }

                // this.selectedLocation = location;
                this.loadingUtilization = false;
                this.cd.markForCheck();
            }, error => {
                this.error = error;
                // this.error = 'Error getting utilization';
                this.loadingUtilization = false;
                this.cd.markForCheck();
            });
    }


    actualRound(value, decimals) {
        return roundAsNumber(value, decimals);
    }


    actualRoundSeconds(value) {
        return roundSeconds(value);
    }

    onMapReady(map) {
        setTimeout(() => {
            this.leafletMapComponent.invalidateSize();
        }, 10);
    }

    drawLocations(devices: Device[]) {
        const localLocationData = [];

        let resellerDefaultAssetIcon;
        if (this.account?.resellerDefaultAssetIcon > 999) {
            resellerDefaultAssetIcon = this.account.resellerDefaultAssetIcon;
        }

        devices.forEach(device => {
            if (device.deviceState === null) {
                return;
            }

            let latitude = device.deviceState.currentPosition?.latitude ?? null;
            let longitude = device.deviceState.currentPosition?.longitude ?? null;
            let lastCommunication = Moment.utc(device.deviceState.currentPosition?.updateTimestamp)['tz'](this.timezoneIana);

            // tslint:disable-next-line:no-bitwise
            const hasGpsFix = (device.deviceState.communicationState?.locationType & 2) > 0;

            if (device.deviceState.cellPosition && ((latitude === null && longitude === null) || !hasGpsFix)) {
                latitude = device.deviceState.cellPosition.latitude ?? null;
                longitude = device.deviceState.cellPosition.longitude ?? null;
                lastCommunication = Moment.utc(device.deviceState.cellPosition.updateTimestamp)['tz'](this.timezoneIana);
            }

            if (latitude === null || longitude === null) {
                return;
            }

            const location = {
                assetName: device.asset?.name ?? device.name,
                assetBrand: device?.asset?.brand,
                assetModel: device?.asset?.model,
                assetCode: device?.asset?.code,
                assetPlateNumber: device?.asset?.plateNumber,
                companyName: device?.companyName,
                icon: device?.asset?.icon,
                deviceState: device.deviceState?.calculatedDeviceState?.deviceState,
                stateChangedTimestamp: Moment.utc(device.deviceState?.calculatedDeviceState?.stateChangedTimestamp)['tz'](this.timezoneIana),
                headingInDegrees: device.deviceState?.currentPosition?.heading,
                speed: device.deviceState.currentPosition?.speed,
                latitude: latitude,
                longitude: longitude,
                unitId: device?.unitId,
                deviceId: device?.id,
                deviceTypeId: device?.deviceTypeId,
                lastCommunication: lastCommunication,
                radiusInMeters: device.deviceState?.currentPosition?.radiusInMeters,
                assetGroupIds: device?.asset?.assetGroupIds,
                assetGroups: device?.asset?.assetGroups,
            };

            localLocationData.push(location);
        });

        this.locationData = localLocationData;
        this.cd.markForCheck();
    }

    // Generate chart locationcount
    generateChartLocationCount(data) {
        const theLocationDataIgnitionOn = [];
        const theLocationDataIgnitionOff = [];
        const theLocationDataEvents = [];
        const theDistanceData = [];
        const theCategories = [];
        const theNonCommunicatingCountData = [];
        const theComminicatingButNotMovingAssetCountData = [];
        const theMovingAssetCountData = [];
        const theDelayMoreThan10Data = [];

        // tslint:disable-next-line:forin
        data.forEach(value => {
            if (value.assetCount < value.deviceCommunicatingCount) {
                value.assetCount = value.deviceCommunicatingCount;
            }

            theLocationDataIgnitionOn.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.ignitionCount, clientData: value.customerId });
            theLocationDataIgnitionOff.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: (value.locationCount - value.ignitionCount), clientData: value.customerId });
            theLocationDataEvents.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.eventCount, clientData: value.customerId });
            theDistanceData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round((value.distanceInMeters + Number.EPSILON) / 1000), clientData: value.customerId });
            theNonCommunicatingCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.assetCount - value.deviceCommunicatingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
            theComminicatingButNotMovingAssetCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.deviceCommunicatingCount - value.deviceMovingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
            theMovingAssetCountData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: Math.round(((value.deviceMovingCount) + Number.EPSILON) * 100) / 100, clientData: value.customerId });
            theDelayMoreThan10Data.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.delayMoreThan10, clientData: value.customerId });
        });

        const theChartDataLocationCount = [{
            name: this.translateService.instant('enums.locationEventType.32768'),
            data: theLocationDataIgnitionOn,
            type: 'column',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            color: undefined,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }, {
            name: this.translateService.instant('enums.locationEventType.65536'),
            data: theLocationDataIgnitionOff,
            type: 'column',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }, {
            name: this.translateService.instant('general.events'),
            data: theLocationDataEvents,
            type: 'column',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }];

        if (this.permissions['Platform_IsReseller']) {
            theChartDataLocationCount.push({
                name: this.translateService.instant('general.delayMoreThan10'),
                data: theDelayMoreThan10Data,
                type: 'line',
                dashStyle: 'dash',
                fillOpacity: 0.5,
                opacity: 1,
                color: '#971c24',
                marker: {
                    enabled: false,
                    lineWidth: 1,
                    symbol: 'square'
                },
            });
        }

        const theChartDataDistance = [{
            name: 'Km',
            data: theDistanceData,
            yAxis: 0,
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            type: 'areaspline'
        }];

        const theChartDataDeviceCount = [{
            name: this.translateService.instant('general.movement'),
            data: theMovingAssetCountData,
            yAxis: 0,
            type: 'area',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }, {
            name: this.translateService.instant('general.nomovement'),
            data: theComminicatingButNotMovingAssetCountData,
            yAxis: 0,
            type: 'area',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }, {
            name: this.translateService.instant('general.notcommunicating'),
            data: theNonCommunicatingCountData,
            yAxis: 0,
            type: 'area',
            dashStyle: 'dash',
            fillOpacity: 0.5,
            opacity: 1,
            marker: {
                enabled: false,
                lineWidth: 1,
                symbol: 'square'
            },
        }];

        this.chartLocationCount = this.chartService.generateColumnChartDates(theChartDataLocationCount, {}, theCategories, undefined, 'normal', true);
        this.chartDistance = this.chartService.generateColumnChartDates(theChartDataDistance, {}, theCategories, undefined, 'normal', false, ' Km');
        this.chartActiveDevices = this.chartService.generateColumnChartDates(theChartDataDeviceCount, {}, theCategories, undefined, 'normal', true);
    }

    // Form

    onDelete() {
        this.loading = true;

        this.accountService.deleteAccount(this.account).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Account is successfully deleted.'
            };

            this.loading = false;
            this.cd.markForCheck();

            setTimeout(() => {
                this.router.navigate(['/Accounts/Overview']);
            }, 3000);
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    onSave() {
        this.saving = true;

        this.accountService.updateAccount(this.account).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Account is successfully updated.'
            };

            this.saving = false;
            this.cd.markForCheck();

            this.setFormMode(FormMode.read);
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getAccountInfo();
        }
    }

    setFormModeWasl(mode) {
        this.formModeWasl = mode;

        if (this.formModeWasl === FormMode.read) {
            this.loadingAccountWasl = true;
            this.getAccountInfo();
        }
    }

    getAccountInfo() {
        if (this.loading !== true && this.loadingAccountWasl !== true) {
            this.loading = true;
        }

        this.accountService.getAccountById(this.account.id).subscribe(account => {
            this.account = account;
            this.loading = false;
            this.loadingAccountWasl = false;
        });
    }

    // Pagination
    setPage(pageNo: number): void {
        this.currentPage = pageNo;
    }

    pageChanged(event: any): void {
        console.log('Page changed to: ' + event.page);
        console.log('Number items per page: ' + event.itemsPerPage);
    }

    // Wasl

    register() {
    }

    unRegister() {
    }
}
