<div class="col-lg-12">
    <div class='horizontalMenuWrapper'>
        <ul>
            <li [ngClass]="{active:  activeRoute('System/Health')}">
                <a [routerLink]="['/System/Health']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-laptop-medical"></i>
                    <div>{{ 'general.health' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsFleetManager']"
                [ngClass]="{active: activeRoute('System/WebhookEndpoint')}">
                <a [routerLink]="['/System/WebhookEndpoints/Overview']">
                    <i class="fas fa-fw fa-network-wired"></i>
                    <div>{{ 'general.webhookEndpoints' | translate }}</div>
                </a>
            </li>
            <li *ngIf="!hideTags && permissions['FleetManagement_Tags']"
                [ngClass]="{active: activeRoute('System/Tags')}">
                <a [routerLink]="['/System/Tags/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-tags"></i>
                    <div>{{ 'general.tags' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Jobs']" [ngClass]="{active: activeRoute('System/Batch')}">
                <a [routerLink]="['/System/Batch/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-sync-alt"></i>
                    <div>{{ 'general.jobs' | translate }}</div>
                </a>
            </li>
            <li [ngClass]="{active: activeRoute('System/Import')}">
                <a [routerLink]="['/System/Import']">
                    <i class="fas fa-fw fa-file-import"></i>
                    <div>{{ 'general.import' | translate }}</div>
                </a>
            </li>
            <li [ngClass]="{active: activeRoute('System/Export')}">
                <a [routerLink]="['/System/Export']">
                    <i class="fas fa-fw fa-file-export"></i>
                    <div>{{ 'general.export' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsReseller']" [ngClass]="{active: isLogs()}">
                <a [routerLink]="['/System/AuditLog/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-archive"></i>
                    <div>{{ 'general.logs' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class='horizontalMenuWrapper' style="float:right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div class="col-lg-12" style="padding: 5px" *ngIf="isLogs()">
    <div class='horizontalMenuSubWrapper'>
        <ul>
            <li *ngIf="permissions['Platform_IsReseller']" [ngClass]="{active: activeRoute('AuditLog')}">
                <a [routerLink]="['/System/AuditLog/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-archive"></i>
                    {{ 'general.auditLog' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsAdministrator']" [ngClass]="{active: activeRoute('System/ErrorLog')}">
                <a [routerLink]="['/System/ErrorLog']">
                    <i class="fas fa-fw fa-bug"></i>
                    {{ 'menu.errorlog' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsAdministrator']" [ngClass]="{active: activeRoute('System/StateViewer')}">
                <a [routerLink]="['/System/StateViewer']">
                    <i class="fas fa-fw fa-code"></i>
                    {{ 'menu.stateViewer' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsAdministrator']" [ngClass]="{active: activeRoute('System/ActiveUsers')}">
                <a [routerLink]="['/System/ActiveUsers']">
                    <i class="fas fa-fw fa-users"></i>
                    {{ 'general.activeUsers' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>
<div style="clear: both"></div>