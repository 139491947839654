<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="device?.asset && device?.asset.accountId">
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}">{{'general.devices' | translate
                        }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.asset?.name ? device?.asset?.name :
                        device?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.geofenceStates' | translate }}</li>
            </ol>
        </nav>

        <fh-filter-toggler style="margin-left: auto;" [constructorName]="constructorName"
            (filterChanged)="showFilter = $event" (presetsChanged)="showPresets = $event"></fh-filter-toggler>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId"></fh-device-details-navigation>
    <div *ngIf="!showMapOnSide" class="col-lg-12" style="padding: 1px 0 0 0;">
        <ngx-loading [show]="loading"></ngx-loading>
        <fh-leaflet-map [allowBookmarks]=false [geofences]="geofences" [geofenceEnabled]="false" [height]="mapHeight"
            [showGeofenceSwitch]="false" [showFitMap]=false [fly]=false>
        </fh-leaflet-map>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>

            <div [ngClass]="{'col-lg-12': !showMapOnSide, 'col-lg-8': showMapOnSide}">

                <!-- Custom filters -->
                <div class="ibox" [hidden]="!showFilter">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                        <span class="float-right hand btn btn-info filter-margin-fix" (click)="clearAllFilters();">
                            <i class="fa fa-fw fa-times"></i>
                            <span class="d-none d-md-inline-flex"> {{'general.clearFilters' | translate }}</span>
                        </span>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative;">
                        <ngx-loading [show]="geofenceStatesLoaded"></ngx-loading>
                        <div class="col-lg-12">
                            <div class="form-group input-group" style="margin-bottom: 5px;">
                                <span class="input-group-prepend">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <input [(ngModel)]="daterangepickerModel" (onHidden)="dateChanged('hidden')" name="from"
                                    type="text" autocomplete="off" class="form-control" [maxDate]="maxDate"
                                    [bsConfig]="dpConfig" bsDaterangepicker>
                                <span class="input-group-calendar-datatables">
                                    <i class="fa fa-calendar-alt"></i>
                                </span>
                            </div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative;">
                        <ngx-loading [show]="geofenceStatesLoaded"></ngx-loading>
                        <div id="dynamicFilters" class="flex-container"></div>
                        <div style="clear:both"></div>
                    </div>
                </div>
                <!-- End default dynamic filters -->

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fa fa-fw fa-hexagon"></i> {{ 'general.geofenceEpisodes' | translate }}</h5>
                        <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)"
                            [constructorName]="constructorName" (filterChanged)="showFilter = $event"
                            (presetLoaded)="onPresetLoaded($event)" (columnsChanged)="drawFilterRow()"></iboxseach>
                    </div>
                    <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                        <table style="width:100%; min-height:50px;" fh-datatable [dtOptions]="dtOptions"
                            [dtTrigger]="dtTrigger" class="table  nowrap margin-bottom-0 dataTable dtr-inline">
                        </table>
                    </div>
                </div>
            </div>

            <div class="sticky col-lg-4" *ngIf="showMapOnSide">
                <div class="ibox">
                    <fh-leaflet-map (mapReady)="onMapReady($event)" [allowBookmarks]=false [geofences]="geofences"
                        [borderRadius]="8" [geofenceEnabled]="false" [height]="mapHeight" [showGeofenceSwitch]="false"
                        [showFitMap]=false [fly]=false>
                    </fh-leaflet-map>
                </div>
            </div>

        </div>
    </div>
</fh-permission-handler>