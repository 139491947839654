<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Maintenances/Overview">{{'general.maintenance' | translate }}</a>
                </li>
                <li class="breadcrumb-item active">{{'menu.add' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-maintenance-navigation>
        <li class="active">
            <a href="/#/Maintenances/Add/"><i class="fas fa-fw fa-car-mechanic"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a>
        </li>
    </fh-maintenance-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <div class="row">
            <form #userForm="ngForm">
                <div class="col-md-6">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-car-mechanic"></i> {{'general.maintenance' | translate }}
                            </h5>
                        </div>
                        <div class="ibox-content slick" style="position:relative;">
                            <ngx-loading [show]="loading"></ngx-loading>
                            <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                                <tbody *ngIf="!maintenance">
                                    <tr>
                                        <td colspan="2">
                                            {{ 'general.noMaintenance' | translate}}
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="maintenance">
                                    <ng-container *ngIf="formMode != 2">
                                        <tr>
                                            <td class="row_opening">
                                                {{ 'general.resellerDescription' | translate}}
                                            </td>
                                            <td class="padding-readonly-correct row_content">
                                                <a class="secondary link_bolder"
                                                    href="/#/AccountDetails/Index/{{maintenance?.resellerId}}">{{maintenance?.resellerDescription
                                                    }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="row_opening">
                                                {{ 'general.companyName' | translate}}
                                            </td>
                                            <td class="row_content padding-readonly-correct">
                                                <a class="secondary link_bolder"
                                                    href="/#/AccountDetails/Index/{{maintenance?.accountId}}">{{maintenance?.companyName
                                                    }}</a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="formMode == 2">
                                        <tr>
                                            <td class="row_opening">
                                                {{ 'general.reseller' | translate}}
                                                <span class="error" *ngIf="resellerVar.invalid">*</span>
                                            </td>
                                            <td class="row_content" style="position:relative; overflow: visible">
                                                <fh-reseller-input name="reseller" #resellerVar="ngModel"
                                                    (onChanged)="resellerChanged($event)" required
                                                    placeholder="{{ 'general.chooseReseller' | translate}}"
                                                    [(ngModel)]="selectedResellerId">
                                                </fh-reseller-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="row_opening">
                                                {{ 'general.account' | translate}}
                                                <span class="error" *ngIf="accountVar.invalid">*</span>
                                            </td>
                                            <td class="row_content" style="position:relative; overflow: visible">
                                                <fh-account-input name="account" #accountVar="ngModel"
                                                    (onChanged)="accountChanged($event)" required [tableInline]="true"
                                                    [selectedResellerId]="selectedResellerId"
                                                    placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                                    [(ngModel)]="maintenance.accountId">
                                                </fh-account-input>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.device' | translate}}
                                            <span class="error"
                                                *ngIf="formMode == 2 && !(maintenance.assetId > 0)">*</span>
                                        </td>
                                        <td class="row_content" style="position:relative; overflow: visible;">
                                            <fh-asset-chooser name="asset" *ngIf="formMode == 2"
                                                [assetId]="maintenance.assetId" [accountId]="maintenance.accountId"
                                                [formMode]="formMode" [activeOnly]="true"
                                                (onAssetChanged)="handleAssetChanged($event)">
                                            </fh-asset-chooser>

                                            <a class="secondary link_bolder" *ngIf="formMode != 2"
                                                href="/#/DeviceDetails/Index/{{maintenance?.assetId}}">{{
                                                maintenance?.assetId }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.typeName' | translate}}
                                            <span class="error"
                                                *ngIf="maintenanceType.invalid && formMode != 1">*</span>
                                            <span>
                                                <i class="fa fa-fw fa-list pointer"
                                                    title="Edit the maintenance types here"
                                                    [routerLink]="['/Maintenances/MaintenanceTypes/Add']"></i>
                                            </span>
                                        </td>
                                        <td class="row_content" style="position:relative; overflow: visible;">
                                            <fh-maintenance-type-input name="maintenanceType" #maintenanceType="ngModel"
                                                required [selectedAccountId]="maintenance.accountId"
                                                [assetTypeId]="asset?.assetTypeId"
                                                placeholder="{{ (maintenance.accountId? 'general.maintenanceTypeSelector' : 'general.firstChooseAccount') | translate}}"
                                                [(ngModel)]="maintenance.maintenanceTypeId">
                                            </fh-maintenance-type-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.occurrenceDate' | translate}}
                                            <span class="error" *ngIf="occurrenceDate.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input type="text" [disabled]="formMode == 1" name="occurrenceDate"
                                                #occurrenceDate="ngModel" class="form-control table-inline"
                                                [(ngModel)]="maintenance.occurrenceDate" autocomplete="off"
                                                placeholder="{{ 'placeholder.occurrenceDate' | translate}}"
                                                [bsConfig]="dpConfig" required bsDatepicker
                                                #occurrenceDateDp="bsDatepicker">
                                            <span class="input-group-calendar"><i class="fa fa-calendar-alt"
                                                    (click)="occurrenceDateDp.toggle()"></i></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.supplierName' | translate}}
                                            <span class="error" *ngIf="supplierName.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input type="text" [readonly]="formMode == 1" name="supplierName"
                                                class="form-control table-inline" [(ngModel)]="maintenance.supplierName"
                                                placeholder="{{ 'placeholder.supplierName' | translate}}"
                                                #supplierName="ngModel">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.localPurchaseOrderNumber' | translate}}
                                            <span class="error"
                                                *ngIf="localPurchaseOrderNumber.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input type="text" [readonly]="formMode == 1"
                                                name="localPurchaseOrderNumber" class="form-control table-inline"
                                                [(ngModel)]="maintenance.localPurchaseOrderNumber"
                                                placeholder="{{ 'placeholder.localPurchaseOrderNumber' | translate}}"
                                                #localPurchaseOrderNumber="ngModel">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.materialReceiptInspectionVoucherNumber' | translate}}
                                            <span class="error"
                                                *ngIf="materialReceiptInspectionVoucherNumber.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input type="text" [readonly]="formMode == 1"
                                                name="materialReceiptInspectionVoucherNumber"
                                                class="form-control table-inline"
                                                placeholder="{{ 'placeholder.materialReceiptInspectionVoucherNumber' | translate}}"
                                                [(ngModel)]="maintenance.materialReceiptInspectionVoucherNumber"
                                                #materialReceiptInspectionVoucherNumber="ngModel">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.controlNumber' | translate}}
                                            <span class="error" *ngIf="controlNumber.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input type="text" [readonly]="formMode == 1" name="controlNumber"
                                                placeholder="{{ 'placeholder.controlNumber' | translate}}"
                                                class="form-control table-inline"
                                                [(ngModel)]="maintenance.controlNumber" #controlNumber="ngModel">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.reference' | translate}}
                                            <span class="error" *ngIf="reference.invalid && formMode != 1">*</span>
                                        </td>
                                        <td class="row_content">
                                            <input type="text" [readonly]="formMode == 1" name="reference"
                                                class="form-control table-inline" [(ngModel)]="maintenance.reference"
                                                placeholder="{{ 'placeholder.reference' | translate}}"
                                                #reference="ngModel">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.description' | translate}}
                                            <span class="error" *ngIf="description.invalid && formMode != 1">*</span>
                                        </td>
                                        <td>
                                            <textarea [readonly]="formMode == 1" name="description"
                                                class="noresize stickypad"
                                                style="height:100px; width:100%; resize: none" id="encCss"
                                                [(ngModel)]="maintenance.description" minlength="4"
                                                placeholder="{{ 'placeholder.comments' | translate}}"
                                                #description="ngModel"></textarea>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.dateCalibration' | translate}}
                                        </td>
                                        <td class="row_content" style="width:auto">
                                            <input type="text" [disabled]="formMode == 1 || formMode == 3"
                                                name="calibrationDate" #calibrationDateVar="ngModel"
                                                class="form-control table-inline"
                                                [(ngModel)]="maintenance.dateCalibration" autocomplete="off"
                                                [bsConfig]="dpConfig" bsDatepicker>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.odo' | translate}}
                                        </td>
                                        <td class="row_content">
                                            <input type="number" [readonly]="formMode == 1 || formMode == 3"
                                                name="calibrationOdo" class="form-control table-inline"
                                                [(ngModel)]="maintenance.realAssetOdo" minlength="3"
                                                #calibrationOdoVar="ngModel">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()"
                            [allowAdd]="permissions['Maintenances_Add']" [formMode]="formMode">
                            <button type="button" class="btn btn-primary float-right" (click)="addOccurrence()"> <i
                                    class="fas fa-fw fa-plus"></i> {{ 'general.addOccurrence' | translate}}</button>
                        </fh-save-footer>
                    </div>

                </div>
                <div class="col-md-6">
                    <div class="ibox" style="position:relative"
                        *ngFor="let occurrence of maintenance.occurrences; let i=index;">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div class="ibox-title">
                            <h5><i class="fas fa-fw fa-car-mechanic"></i> {{'general.maintenanceOrder' | translate }}
                            </h5>
                        </div>

                        <fh-maintenance-details-item [maintenanceOccurrence]="occurrence" [identifier]="i"
                            [useCompact]=true [formMode]="formMode">
                        </fh-maintenance-details-item>

                        <div class="ibox-footer">
                            <button class="btn btn-danger" [disabled]="i == 0" (click)="deleteOccurrence(occurrence)">
                                <i class="fa fa-fw fa-trash-alt"></i> {{'general.delete' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</fh-permission-handler>