import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FhChartService } from 'app/services/charts/charts.service';
import { LeafletMapComponent } from './leafletMap.component';
import * as L from 'leaflet';
import { getIconPath } from 'app/common/globals';
import * as Highcharts from 'highcharts';

// Moment
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { AccountService } from 'app/services/account/account.service';
import { TripService } from 'app/services/trip/trip.service';
import { Episode } from 'app/models/episode.model';
import { DistanceUnitService } from 'app/common/distanceunit.service';
import { TranslateService } from '@ngx-translate/core';

window['moment'] = Moment;
mTZ();
@Component({
    providers: [FhChartService],
    selector: 'fh-episode-details',
    templateUrl: 'episodeDetails.template.html',
})
export class EpisodeDetailsComponent implements OnChanges, OnInit {
    Highcharts: typeof Highcharts = Highcharts;
    chartRef: Highcharts.Chart;

    @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

    @Input() loading = false;
    @Input() accountId;
    @Input() device;
    @Output() onWideChanged = new EventEmitter();
    @Output() onEpisodeDeleted = new EventEmitter();

    geoJsonData: any;
    tripFeatureGroup: any;
    trip: any;
    theMarker: any;
    loadingEpisodeDetails: boolean;
    loadingMapData: boolean;
    chartOptions: any;
    geoJson: any;
    timezoneIana;
    showWiderMap: boolean;
    constructorName = 'EpisodesViewComponent';
    geofences = [];
    loadingGeofences = false;
    updateFlag = false;
    permissions: {};
    episode: Episode;

    constructor(private translate: TranslateService, private chartService: FhChartService, private tripService: TripService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private accountService: AccountService, private distance: DistanceUnitService) {
        this.timezoneIana = authenticationService.getTimeZoneIana();
        this.showWiderMap = localStorage.getItem('ShowWiderMap_Eppisodes') === 'true';
        this.permissions = authenticationService.permissions;
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.accountId != null) {
            this.getGeofences(this.accountId);
            return;
        }

        if (this.device?.accountId) {
            this.getGeofences(this.device?.accountId);
            return;
        }
    }

    getGeofences(accountId) {
        if (this.geofences.length === 0 && accountId != null && accountId > 0) {
            this.loadingGeofences = true;
            this.accountService.getGeofencesByAccount(accountId).subscribe(geofences => {
                this.geofences = geofences;
                this.loadingGeofences = false;
            });
        }
    }

    delete() {
        this.tripService.deleteEpisodeById(this.episode.id).subscribe(result => {
            this.onEpisodeDeleted.emit(true);

            this.geoJsonData = null;

            if (this.tripFeatureGroup) {
                this.leafletMapComponent.map.removeLayer(this.tripFeatureGroup);
            }

            if (this.trip) {
                this.leafletMapComponent.tripLayer.removeLayer(this.trip);
            }

            if (this.theMarker) {
                this.leafletMapComponent.map.removeLayer(this.theMarker);
            }
        });
    }

    public showOnMap(data) {

        if (!this.accountId) {
            if (data.accountId) {
                this.getGeofences(data.accountId);
            } else {
                this.geofences = [];
            }
        }

        const that = this;
        this.geoJsonData = null;

        if (this.tripFeatureGroup) {
            this.leafletMapComponent.map.removeLayer(this.tripFeatureGroup);
        }

        if (this.trip) {
            this.leafletMapComponent.tripLayer.removeLayer(this.trip);
        }

        if (this.theMarker) {
            this.leafletMapComponent.map.removeLayer(this.theMarker);
        }

        if (data.id) {
            this.loadingEpisodeDetails = true;
            this.cd.markForCheck();

            this.tripService.getEpisodeDetailsById(data.id).subscribe(result => {
                this.episode = result;
                this.loadingEpisodeDetails = false;

                this.setIssueJsonDetails(this.episode);
            });
        } else if (data.episodeIdentifier && !data.id) {
            this.loadingEpisodeDetails = true;
            this.cd.markForCheck();

            this.tripService.getEpisodeDetailsByIdentifier(data.episodeIdentifier).subscribe(result => {
                this.episode = result;
                this.loadingEpisodeDetails = false;

                this.setIssueJsonDetails(this.episode);
            });
        } else {
            if (!data.beginLatitude) {
                return;
            }

            const iconPath = '/assets/images/icons/vista/Trucks/32x32/TankerTruck_Black.png';

            const theIcon = L['StatusMarker'].icon({
                iconUrl: iconPath,
                icon: 'fa-exclamation',
                markerColor: 'orange',
                rotate: 0,
                shape: 'circle',
                prefix: 'fas'
            });

            this.theMarker = L.marker([data.beginLatitude, data.beginLongitude], { icon: theIcon }).addTo(this.leafletMapComponent.map);
            this.leafletMapComponent.map.setView([data.beginLatitude, data.beginLongitude], 15, { animate: true, duration: 0.5 });
        }
    }

    logChartInstance(chart) {
        console.log('Enable chart');
        this.chartRef = chart;
    }

    // When flipping from full screen mode to normal mode
    reRenderMap() {
        this.loadingMapData = true;

        setTimeout(() => {
            this.leafletMapComponent.map.invalidateSize()

            console.log('reflow chart');

            this.chartRef.reflow();

            this.loadingMapData = false;
            this.cd.markForCheck();
        }, 100);
    }

    setIssueJsonDetails(episode): any {
        if (!episode.geoJson) {
            this.geoJsonData = null;

            // Fallback to location
            if (!episode.beginLatitude) {
                return;
            }

            const iconPath = '/assets/images/icons/vista/Trucks/32x32/TankerTruck_Black.png';

            const theIcon = L['StatusMarker'].icon({
                iconUrl: iconPath,
                icon: 'fa-exclamation',
                markerColor: 'orange',
                rotate: 0,
                shape: 'circle',
                prefix: 'fas'
            });

            this.theMarker = L.marker([episode.beginLatitude, episode.beginLongitude], { icon: theIcon }).addTo(this.leafletMapComponent.map);
            this.leafletMapComponent.map.setView([episode.beginLatitude, episode.beginLongitude], 15, { animate: true, duration: 0.5 });
            return;
        }
        // Set on map
        setTimeout(() => {
            if (episode.geoJson) {
                let roadspeedIndex = -1;
                let index = -1;

                episode.geoJson.Rules?.forEach(rule => {
                    index++;
                    if (rule.RuleType != null && rule.RuleType === 16) { // RoadSpeed
                        roadspeedIndex = index;

                        let deviationText = '';
                        if (rule.Threshold != null && rule.Threshold > 0) {
                            deviationText = ' (+' + rule.Threshold + '%)';
                        }

                        rule.Name = this.translate.instant('general.exceedlimit') + ' ' + this.distance.calculateDistanceUnitFromKmFixed(episode.geoJson.Locations[0].Thresholds[roadspeedIndex], 0) + this.distance.getDistanceUnitPerHour(true) + deviationText + ' ' + this.translate.instant('general.on') + ' \'' + rule.Name + '\'';
                        rule.Unit = this.distance.getDistanceUnitSpeedUoM();
                    }
                });

                if(roadspeedIndex > -1) {
                    episode.geoJson.Locations?.forEach(location => {
                        if (location.Values != null && location.Values.length > roadspeedIndex) {
                            location.Values[roadspeedIndex] = this.distance.calculateDistanceUnitFromKmFixed(location.Values[roadspeedIndex], 0);
                        }
                    });
                }

                // Remove the first icon

                const color = '#ff0000';

                this.geoJsonData = episode.geoJson;
                this.geoJsonData?.Locations?.sort((a, b) => a.Timestamp.localeCompare(b.Timestamp));

                this.drawChart(this.geoJsonData);

                if (this.geoJson) {
                    this.leafletMapComponent.map.removeLayer(this.geoJson);
                }
                const pointList = [];

                this.geoJsonData?.Locations?.forEach(location => {
                    if (location.Coordinates) {
                        pointList.push(new L.LatLng(location.Coordinates[0], location.Coordinates[1]));
                    }
                });

                this.loadingMapData = false;

                if (pointList.length === 0) {
                    this.geoJsonData = null;
                    return;
                }

                // Trip

                const startIcon = new L['NumberMarker'].Icon({
                    backgroundColor: color,
                    className: 'm360',
                    color: '#fff',
                    number: 1,
                });

                const startMarker = L.marker(new L.LatLng(pointList[0].lat, pointList[0].lng), { icon: startIcon });
                startMarker.addTo(this.leafletMapComponent.map);

                const endIcon = L.icon({
                    iconUrl: 'assets/images/icons/end.png',
                    className: 'markerEnd',
                });

                const endMarker = L.marker(new L.LatLng(pointList[pointList.length - 1].lat, pointList[pointList.length - 1].lng), { icon: endIcon });
                endMarker.addTo(this.leafletMapComponent.map);

                const polyline = new L.Polyline(pointList, {
                    fillColor: '#DC143C',
                    color: color,
                    fillOpacity: 0.2,
                    weight: 4,
                    opacity: 0.8,
                    smoothFactor: 1
                });

                const decoratorLine = L['polylineDecorator'](polyline, {
                    patterns: [
                        { offset: 24, repeat: 100, symbol: L['Symbol']['arrowHead']({ pixelSize: 15, pathOptions: { fillOpacity: 0.9, color, weight: 0, stroke: true } }) }
                    ]
                });

                this.tripFeatureGroup = L.featureGroup([startMarker, endMarker, polyline, decoratorLine]);
                this.tripFeatureGroup.addTo(this.leafletMapComponent.tripLayer);

                // Issue types that should have a red plot on the issue date
                const bounds = this.tripFeatureGroup.getBounds();

                if (bounds.isValid()) {
                    this.leafletMapComponent.map.fitBounds(bounds, { padding: [15, 15] });
                }

                this.cd.markForCheck();
            } else {
                this.loadingMapData = false;
                this.cd.markForCheck();
            }
        }, 100);
        // End set on map
    }

    flipShowWiderMap() {
        this.showWiderMap = !this.showWiderMap;
        localStorage.setItem('ShowWiderMap_Episodes', this.showWiderMap.toString());

        this.onWideChanged.emit(true);

        setTimeout(() => {
            this.reRenderMap();
        }, 1);
    }

    drawChart(data) {
        const iconPath = getIconPath(this.device?.asset?.icon)[1];

        const theIcon = L.icon({
            iconUrl: iconPath,
            // className: 'markerPlayTrip',
            iconAnchor: [16, 16],
        });

        const theResult = this.filterChartData(data);

        const plotBands = [];
        const plotLines = [];

        if (data.DebounceInSeconds) {
            plotLines.push({
                dashStyle: 'dash',
                width: 1,
                color: 'rgba(255,255,255,0.2)',
                value: Moment.utc(data.Locations[0].Timestamp)['tz'](this.timezoneIana).add(data.DebounceInSeconds, 'seconds').unix() * 1000
            });
        }

        console.log('Update chart');
        this.chartOptions = this.chartService.generateMapChart(theResult.data, plotLines, plotBands, this.leafletMapComponent.map, theIcon, theResult.plotLinesYAxis);
    }

    filterChartData(data) {
        const that = this;
        const values = [];
        const thresholds = [];
        const dateTimes = [];
        const coordinates = []
        const theData = [];
        const plotLinesYAxis = [];

        data?.Locations?.forEach((location, ruleIndex) => {
            const timestamp = Moment.utc(location.Timestamp)['tz'](that.timezoneIana).unix() * 1000;
            dateTimes.push(timestamp);

            let cooord = null;
            if (location.Coordinates) {
                cooord = new L.LatLng(location.Coordinates[0], location.Coordinates[1]);
                coordinates.push(cooord);
            } else {
                coordinates.push(null);
            }

            location.Values.forEach((value, locIndex) => {
                if (!values[locIndex]) {
                    values[locIndex] = [];
                }

                values[locIndex].push({ x: timestamp, y: value, latlon: cooord, suffix: this.formatSuffix(data?.Rules[locIndex].Unit) }); // todo unit
            });

            if (location.Thresholds != null) {
                location.Thresholds.forEach((value, index) => {
                    if (!thresholds[index]) {
                        thresholds[index] = [];
                    }
                    thresholds[index].push({ x: timestamp, y: value, latlon: cooord, name: location.Names[index], suffix: this.distance.getDistanceUnitPerHour(true)});
                });
            }
        });

        data?.Rules?.forEach((rule, index) => {
            const isBool = this.getYAxis(rule) === 5;

            theData.push({
                name: rule.RuleType === 16 ? this.translate.instant('general.actualSpeed') : (rule.Name ?? index),
                data: values[index],
                type: isBool ? 'line' : 'spline',
                step: isBool ? 'left' : null,
                yAxis: this.getYAxis(rule),
            });

            if (rule.RuleType === 16) {
                console.log(thresholds[index]);
                theData.push({
                    name: 'Limit' + thresholds[index][0].name ?? index,
                    data: thresholds[index],
                    type: isBool ? 'line' : 'spline',
                    step: isBool ? 'left' : null,
                    color: '#FF0000',
                    yAxis: this.getYAxis(rule),
                });
            }

            // Add tresholds
            if (rule.Threshold) {
                plotLinesYAxis.push({
                    color: '#ddd',
                    dashStyle: 'dash',
                    width: 2,
                    opacity: 0.5,
                    value: rule.Threshold,
                    yAxis: this.getYAxis(rule),
                });
            }
        });

        return { data: theData, plotLinesYAxis: plotLinesYAxis };
    }

    formatSuffix(unit){
        if (unit==='bool')
        {
            return '';
        }

        return unit;
    }

    getYAxis(rule) {
        if (rule.RuleType === 16 || rule.Name === 'Speed' || rule.Unit?.indexOf('km/h') > -1) {
            return 0;
        }

        if (rule.Name === 'Battery level' || rule.Unit === 'V') {
            return 6;
        }

        if (rule.Name === 'Temperature' || rule.Unit?.indexOf('°C') > -1) {
            return 7;
        }

        if (rule.Name === 'Fuel') {
            return 8;
        }

        if (rule.Name === 'Weight' || rule.Unit?.indexOf('kg') > -1) {
            return 9;
        }

        if (rule.Unit === 'bool') {
            return 5;
        }

        if (rule.Unit?.indexOf('%') > -1) {
            return 11;
        }

        if (rule.Unit?.indexOf('rpm') > -1) {
            return 13;
        }

        return 1;
    }
}

