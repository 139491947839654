<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="device?.asset && device?.asset.accountId">
                    <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Devices/Overview?companyName={{device?.companyName}}">{{'general.devices' | translate
                        }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.asset?.name ? device?.asset?.name :
                        device?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.dashboard' | translate }}</li>
            </ol>
        </nav>

        <span class="fa-filter-container noselect" style="margin-right:8px">
            <a style="margin-left:2px" class="btn btn-info margin-fix d-none d-md-inline-flex" title="Help"
                href="https://help.360locate.com/knowledge-base/device-dashboard/" target="_blank"><i
                    class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span
                    class="d-none d-md-inline-flex">{{'general.needHelp'
                    |
                    translate }}</span></a>
        </span>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device">
        <span class="top-car-icon" *ngIf="device?.asset?.icon" [innerHTML]="formatIconId(device?.asset?.icon)"></span>
    </fh-device-details-navigation>
    <div *ngIf="!showMapOnSide" class="col-lg-12" style="padding: 1px 0 0 0;">
        <ngx-loading [show]="loadingGeofences"></ngx-loading>
        <fh-leaflet-map (mapReady)="onMapReady($event)" [device]="device" [locations]="locationData"
            [isSpinning]="isSpinning" [geofences]="geofences" [geofenceEnabled]="false" [allowBookmarks]="false"
            [height]="mapHeight">
        </fh-leaflet-map>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error" [warning]="warning" [success]="success"></fh-error>

                <div class="alert alert-warning shadow" *ngIf="showWarningMessage && device?.asset?.comment">
                    <p style="font-size: 15px;"><strong>{{ 'general.warning' | translate }}</strong></p>
                    <p>{{device.asset.comment}}</p>
                </div>

                <div class="alert alert-warning shadow" *ngIf="violations.length > 0">
                    <i class="fas fa-fw fa-times float-right" style="cursor: pointer;" (click)="clearViolations()"></i>
                    <p style="font-size: 15px;"><strong>{{ 'general.violations' | translate }}
                            ({{violations.length}})</strong></p>
                    <p *ngFor="let violation of violations">{{violation}}</p>
                </div>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <form #deviceForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-microchip"></i> {{ 'general.device' | translate }}
                            </h5>

                            <span class="float-right" style="margin-top: -5px;">
                                <fh-share-link type="2" [entityId]="device?.id"></fh-share-link>
                            </span>
                        </div>
                        <fh-device-details [device]="device" [formMode]="formMode" [loading]="loading || saving">
                        </fh-device-details>
                        <fh-save-footer [valid]="!deviceForm.invalid" (onSave)="onSave()"
                            [allowEdit]="permissions['Devices_Edit']" [allowDelete]=false
                            (setFormMode)="setFormMode($event)" (onDelete)="onDelete()" [formMode]="formMode">
                            <span class="float-right">
                                <fh-task-dropdown (onFinishAction)="finishTask($event)" #taskDropdown
                                    [entityTypes]="tagType" [entity]="device"></fh-task-dropdown>
                            </span>
                        </fh-save-footer>
                    </div>
                </form>

                <form #assetForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-car-bus"></i> {{'general.asset' | translate }}
                            </h5>
                        </div>
                        <fh-asset-details [asset]="device.asset" [formMode]="formModeAsset"
                            [loading]="loading || saving"></fh-asset-details>
                        <fh-save-footer [valid]="!assetForm.invalid" (onInsert)="onInsertAsset()"
                            (onSave)="onSaveAsset()" (onDelete)="onArchiveAsset($event)"
                            [allowAdd]="((!device?.asset?.id || device?.asset?.assignmentEnd) && device?.accountId) && permissions['Assets_Add']"
                            [allowCancelOnInsert]=true [allowEdit]="(device?.asset?.id) && permissions['Assets_Edit']"
                            [allowDelete]=false (setFormMode)="setFormModeAsset($event)" [formMode]="formModeAsset">
                            <span class="float-right">
                                <span *ngIf="(device && device?.asset && device?.asset?.id)">
                                    <ng-container *ngIf="!device.asset.assignmentEnd; else reactivate">
                                        <button class="btn btn-danger" (click)="showArchive(device.asset?.id);"
                                            *ngIf="permissions['Actions_Archive']"
                                            title="{{ 'general.archive' | translate }}"><i
                                                class="fa fa-fw fa-file-archive"></i>
                                            {{'general.archive' | translate }}</button>
                                    </ng-container>
                                    <ng-template #reactivate>
                                        <button class="btn btn-primary" (click)="reactivateAsset(device.asset.id)"
                                            *ngIf="permissions['Actions_Archive']"
                                            title="{{ 'general.unArchiveDevice' | translate }}"><i
                                                class="fa fa-fw fa-recycle"></i>
                                            {{'general.reactivate' | translate }}</button>
                                    </ng-template>
                                </span>
                            </span>
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">

                <div class="ibox" style="position: relative;" *ngIf="showMapOnSide">
                    <ngx-loading [show]="loadingGeofences"></ngx-loading>
                    <fh-leaflet-map (mapReady)="onMapReady($event)" [device]="device" [locations]="locationData"
                        [isSpinning]="isSpinning" [borderRadius]="8" [geofences]="geofences" [geofenceEnabled]="false"
                        [allowBookmarks]="false" [height]="mapHeight">
                    </fh-leaflet-map>
                </div>

                <div *ngIf="permissions['Dashcams_View'] && sources.length > 0" class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-video"></i> {{'general.dashcam' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <app-vjs-player #videoJs [options]="playerOptions"></app-vjs-player>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <span class="pull-left">
                            <h5>
                                <i class="fas fa-fw fa-satellite"></i> {{'general.deviceState' | translate }}
                            </h5>
                        </span>
                        <span class="float-right" [tooltip]="'Show GPS items'">
                            <i (click)="flipPosition()" class="fa-duotone fa-lg fa-fw"
                                [ngClass]="showPosition ? 'fa-toggle-on' : 'fa-toggle-off'">
                            </i>
                        </span>
                        <span style="padding-right:7px" class="float-right" tooltip="Device communicated: {{ lastCommunication | amDateFormat: 'LL h:mm:ss A'
                        }} | {{ lastCommunication | amTimeAgo:true }} ago">
                            <span class="d-none d-md-inline-flex"><strong>
                                    {{ lastCommunication | amDateFormat: 'LLL'
                                    }}</strong></span>
                        </span>
                    </div>

                    <!-- DeviceState -->
                    <div class="ibox-content" style="position: relative;"
                        *ngIf="deviceState == null && !loadingLocation">
                        {{ ('general.noDeviceState') | translate }}
                    </div>
                    <div class="ibox-content" style="position: relative;"
                        *ngIf="deviceState == null && loadingLocation">
                        <ngx-loading [show]="loadingLocation"></ngx-loading>
                        {{ ('general.loadingDeviceState') | translate }}
                    </div>
                    <div class=" ibox-content slick" style="position: relative;" *ngIf="deviceState != null">
                        <ngx-loading [show]="loadingLocation"></ngx-loading>
                        <div class="flex-container" [@enter]>
                            <div class="col-6 col-lg-4" style="padding:0" *ngIf="!device.asset?.isAssetTracker">
                                <div title="DeviceState: '{{ 'general.deviceState_' + deviceState?.calculatedDeviceState?.deviceState
                                | translate }}' since {{ deviceState?.calculatedDeviceState?.stateChangedTimestamp }}"
                                    class="kpi-item-small border-active"
                                    [ngClass]="colorMapper(deviceState?.calculatedDeviceState?.deviceState)">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-map-marker-alt eye-active"
                                            [ngClass]="colorMapper(deviceState?.calculatedDeviceState?.deviceState)"></i>
                                        {{ 'general.deviceState_' + deviceState?.calculatedDeviceState?.deviceState
                                        | translate }}
                                    </h3>
                                    <div *ngIf="deviceState?.calculatedDeviceState?.updateTimestamp; else noTimeStamp">
                                        {{ 'general.stateSince' | translate }} {{
                                        deviceState?.calculatedDeviceState?.stateChangedTimestamp | amTimeAgo:true
                                        }}
                                    </div>
                                    <ng-template #noTimeStamp>
                                        {{ 'general.undefined' | translate }}
                                    </ng-template>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0" *ngIf="!device.asset?.isAssetTracker">
                                <div title="Speed: '{{deviceState?.currentPosition?.speed}}' on {{ deviceState?.currentPosition?.updateTimestamp }}"
                                    class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.currentPosition?.speed != undefined ?
                                        deviceState?.currentPosition?.speed :
                                        '-' }} {{ this.translatedKmh}}
                                    </h3>
                                    <div>{{'general.speed' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0" *ngIf="!device.asset?.isAssetTracker">
                                <div title="Heading: '{{deviceState?.currentPosition?.heading}}' on {{ deviceState?.currentPosition?.updateTimestamp }}"
                                    class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i *ngIf="deviceState" [ngClass]="deviceState?.markerColor"
                                            [ngStyle]="{'transform': 'rotate('+deviceState?.currentPosition?.heading+'deg)', '-webkit-transform': 'rotate('+deviceState?.currentPosition?.heading+'deg)', '-ms-transform': 'rotate('+deviceState?.currentPosition?.heading+'deg)'}"
                                            class="full-icon fas fa-arrow-up eye-active "></i>
                                        {{ deviceState?.currentPosition?.heading != undefined ?
                                        deviceState?.currentPosition?.heading
                                        : '-' }}°
                                    </h3>
                                    <div>{{'general.heading' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.externalBattery?.batteryLevelInVoltage != undefined">
                                <div title="ExternalBattery: '{{deviceState?.externalBattery?.batteryLevelInVoltage}}' on {{ deviceState?.externalBattery?.updateTimestamp }}"
                                    class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-car-battery"
                                            [ngStyle]="{'color': (!deviceState?.externalBattery?.batteryLevelInVoltage) ? '#ccc' : ((deviceState?.externalBattery?.batteryLevelInVoltage > 9) ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{
                                        deviceState?.externalBattery?.batteryLevelInVoltage != undefined ?
                                        actualRound(deviceState?.externalBattery?.batteryLevelInVoltage,1).toLocaleString()
                                        :
                                        '-' }} V
                                    </h3>
                                    <div>{{'general.battery' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.temperature1 && deviceState?.temperature1.updateTimestamp > compareDate">
                                <div [title]="deviceState?.temperature1?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-thermometer-three-quarters"
                                            [ngClass]="deviceState?.temperature1?.temperatureInCelcius == undefined && device.properties.sensorTemplateSettings.temp1 == undefined ? '' : (checkSensorOnTempate(deviceState?.temperature1?.temperatureInCelcius , device.properties.sensorTemplateSettings.temp1) == true ?  'eye-active green' : 'fa-exclamation-triangle eye-active red') "></i>
                                        {{ deviceState?.temperature1?.temperatureInCelcius.toLocaleString() }} °C
                                    </h3>
                                    <div>{{'general.temperature' | translate }} 1 <i
                                            *ngIf="deviceState?.temperature1?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"
                                            title="{{ deviceState?.temperature1?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.temperature2 && deviceState?.temperature2.updateTimestamp > compareDate">
                                <div class="kpi-item-small">
                                    <h3 [title]="deviceState?.temperature2?.updateTimestamp"
                                        class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-thermometer-three-quarters"
                                            [ngClass]="deviceState?.temperature2?.temperatureInCelcius == undefined && device.properties.sensorTemplateSettings.temp2 == undefined ? '' : (checkSensorOnTempate(deviceState?.temperature2?.temperatureInCelcius , device.properties.sensorTemplateSettings.temp2) == true ?  'eye-active green' : 'fa-exclamation-triangle eye-active red') "></i>
                                        {{ deviceState?.temperature2?.temperatureInCelcius.toLocaleString() }} °C
                                    </h3>
                                    <div>{{'general.temperature' | translate }} 2 <i
                                            *ngIf="deviceState?.temperature2?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"
                                            title="{{ deviceState?.temperature2?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.temperature3 && deviceState?.temperature3.updateTimestamp > compareDate">
                                <div [title]="deviceState?.temperature3?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-thermometer-three-quarters"
                                            [ngClass]="deviceState?.temperature3?.temperatureInCelcius == undefined && device.properties.sensorTemplateSettings.temp3 == undefined ? '' : (checkSensorOnTempate(deviceState?.temperature3?.temperatureInCelcius , device.properties.sensorTemplateSettings.temp3) == true ?  'eye-active green' : 'fa-exclamation-triangle eye-active red') "></i>
                                        {{ deviceState?.temperature3?.temperatureInCelcius.toLocaleString() }} °C
                                    </h3>
                                    <div>{{'general.temperature' | translate }} 3 <i
                                            *ngIf="deviceState?.temperature3?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"
                                            title="{{ deviceState?.temperature3?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.temperature4 && deviceState?.temperature4.updateTimestamp > compareDate">
                                <div [title]="deviceState?.temperature4?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-thermometer-three-quarters"
                                            [ngClass]="deviceState?.temperature4?.temperatureInCelcius == undefined && device.properties.sensorTemplateSettings.temp4 == undefined ? '' : (checkSensorOnTempate(deviceState?.temperature4?.temperatureInCelcius , device.properties.sensorTemplateSettings.temp4) == true ?  'eye-active green' : 'fa-exclamation-triangle eye-active red') "></i>
                                        {{ deviceState?.temperature4?.temperatureInCelcius.toLocaleString() }} °C
                                    </h3>
                                    <div>{{'general.temperature' | translate }} 4 <i
                                            *ngIf="deviceState?.temperature4?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"
                                            title="{{ deviceState?.temperature4?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.engineCoolantTemperature && deviceState?.engineCoolantTemperature.updateTimestamp > compareDate">
                                <div [title]="deviceState?.engineCoolantTemperature?.updateTimestamp"
                                    class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-thermometer-three-quarters"
                                            [ngStyle]="{'color': getEngineColor(deviceState?.engineCoolantTemperature?.temperatureInCelcius) }"></i>
                                        {{ deviceState?.engineCoolantTemperature?.temperatureInCelcius.toLocaleString()
                                        }} °C
                                    </h3>
                                    <div>{{'general.engineCoolantTemperature' | translate }} <i
                                            *ngIf="deviceState?.engineCoolantTemperature?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"
                                            title="{{ deviceState?.engineCoolantTemperature?.updateTimestamp }}"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.fuel && deviceState?.fuel.updateTimestamp > compareDate">
                                <div [title]="deviceState?.fuel?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-gas-pump"
                                            [ngStyle]="{'color': getFuelColor(deviceState?.fuel?.fuelLevelInPercentage) }"></i>
                                        {{ actualRound(deviceState?.fuel?.fuelLevelInPercentage,0).toLocaleString() }}%
                                    </h3>
                                    <div>{{'general.fuel' | translate }} <i
                                            *ngIf="deviceState?.fuel?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"
                                            title="{{ deviceState?.fuel?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.fuel1 && deviceState?.fuel1.updateTimestamp > compareDate">
                                <div [title]="deviceState?.fuel1?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-gas-pump"
                                            [ngClass]="deviceState?.fuel1?.fuelLevelInPercentage == undefined && device.properties.sensorTemplateSettings.fuel1 == undefined ? '' : (checkSensorOnTempate(deviceState?.fuel1?.fuelLevelInPercentage , device.properties.sensorTemplateSettings.fuel1) == true ?  'eye-active green' : 'fa-exclamation-triangle eye-active red') "></i>

                                        {{ actualRound(deviceState?.fuel1?.fuelLevelInPercentage,0).toLocaleString() }}%
                                    </h3>
                                    <div>{{'general.fuel' | translate }} 2 <i
                                            *ngIf="deviceState?.fuel1?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"
                                            title="{{ deviceState?.fuel1?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.fuel2 && deviceState?.fuel2.updateTimestamp > compareDate">
                                <div [title]="deviceState?.fuel2?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-gas-pump"></i>
                                        {{ actualRound(deviceState?.fuel2?.fuelLevelInPercentage,0).toLocaleString() }}%
                                    </h3>
                                    <div>{{'general.fuel' | translate }} 2 <i
                                            *ngIf="deviceState?.fuel2?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"
                                            title="{{ deviceState?.fuel2?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.humidity1 && deviceState?.humidity1.updateTimestamp > compareDate">
                                <div [title]="deviceState?.humidity1?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-humidity"
                                            [ngClass]="deviceState?.humidity1?.humidityInPercent == undefined && device.properties.sensorTemplateSettings.humidity1 == undefined ? '' : (checkSensorOnTempate(deviceState?.humidity1?.humidityInPercent , device.properties.sensorTemplateSettings.humidity1) == true ?  'eye-active green' : 'fa-exclamation-triangle eye-active red') "></i>

                                        {{ deviceState?.humidity1?.humidityInPercent.toLocaleString() }} %
                                    </h3>
                                    <div>{{'general.humidity' | translate }} 1 <i
                                            *ngIf="deviceState?.humidity1?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"
                                            title="{{ deviceState?.humidity1?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.humidity2 && deviceState?.humidity2.updateTimestamp > compareDate">
                                <div [title]="deviceState?.humidity2?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-humidity"
                                            [ngClass]="deviceState?.humidity2?.humidityInPercent == undefined && device.properties.sensorTemplateSettings.humidity2 == undefined ? '' : (checkSensorOnTempate(deviceState?.humidity2?.humidityInPercent , device.properties.sensorTemplateSettings.humidity2) == true ?  'eye-active green' : 'fa-exclamation-triangle eye-active red') "></i>

                                        {{ deviceState?.humidity2?.humidityInPercent.toLocaleString() }} %
                                    </h3>
                                    <div>{{'general.humidity' | translate }} 2 <i
                                            *ngIf="deviceState?.humidity2?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"
                                            title="{{ deviceState?.humidity2?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.axleWeight1 && deviceState?.axleWeight1.updateTimestamp > compareDate">
                                <div [title]="deviceState?.axleWeight1?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-weight"
                                            [ngClass]="deviceState?.axleWeight1?.axleWeightInKg == undefined && device.properties.sensorTemplateSettings.weight1 == undefined ? '' : (checkSensorOnTempate(deviceState?.axleWeight1?.axleWeightInKg , device.properties.sensorTemplateSettings.weight1) == true ?  'eye-active green' : 'fa-exclamation-triangle eye-active red') "></i>

                                        {{ deviceState?.axleWeight1?.axleWeightInKg.toLocaleString() }} kg
                                    </h3>
                                    <div title="{{ deviceState?.axleWeight1?.updateTimestamp }}">{{'general.weight'
                                        | translate }} 1 <i
                                            *ngIf="deviceState?.axleWeight1?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"></i></div>
                                </div>
                            </div>

                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.axleWeight2 && deviceState?.axleWeight2.updateTimestamp > compareDate">
                                <div [title]="deviceState?.axleWeight2?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-weight"
                                            [ngClass]="deviceState?.axleWeight2?.axleWeightInKg == undefined && device.properties.sensorTemplateSettings.weight2 == undefined ? '' : (checkSensorOnTempate(deviceState?.axleWeight2?.axleWeightInKg , device.properties.sensorTemplateSettings.weight2) == true ?  'eye-active green' : 'fa-exclamation-triangle eye-active red') "></i>

                                        {{ deviceState?.axleWeight2?.axleWeightInKg.toLocaleString() }} kg
                                    </h3>
                                    <div title="{{ deviceState?.axleWeight2?.updateTimestamp }}">{{'general.weight'
                                        | translate }} 2 <i
                                            *ngIf="deviceState?.axleWeight2?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"></i></div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.totalAxleWeight && deviceState?.totalAxleWeight.updateTimestamp > compareDate">
                                <div [title]="deviceState?.totalAxleWeight?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-weight"></i>
                                        {{ deviceState?.totalAxleWeight?.totalWeightInKg.toLocaleString() }} kg
                                    </h3>
                                    <div>{{'general.totalWeight' | translate }} <i
                                            *ngIf="deviceState?.totalAxleWeight?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"
                                            title="{{ deviceState?.totalAxleWeight?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.rpmStatus?.rpm != undefined && deviceState?.rpmStatus?.updateTimestamp > compareDate">
                                <div [title]="deviceState?.rpmStatus?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-engine"
                                            [ngClass]="deviceState?.rpmStatus?.rpm == undefined && device.properties.sensorTemplateSettings.rpm1 == undefined ? '' : (checkSensorOnTempate(deviceState?.rpmStatus?.rpm , device.properties.sensorTemplateSettings.rpm1) == true ?  'eye-active green' : 'fa-exclamation-triangle eye-active red') "></i>

                                        {{ deviceState?.rpmStatus?.rpm ?
                                        deviceState?.rpmStatus?.rpm.toLocaleString(): '-' }}
                                    </h3>
                                    <div>{{'general.rpm' | translate }} <i
                                            *ngIf="deviceState?.rpmStatus?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"
                                            title="{{ deviceState?.rpmStatus?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="deviceState?.tagScanStatus?.tag != undefined">
                                <div [title]="deviceState?.tagScanStatus?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">

                                        <a *ngIf="permissions['Drivers_View'] && device.scannedDriver"
                                            class="secondary link_bolder"
                                            href="/#/DriverDetails/Index/{{device?.scannedDriver?.id }}">
                                            <i class="full-icon fas fa-fw fa-tag"></i>
                                            {{ device?.scannedDriver?.name }}</a>

                                        <span *ngIf="!device.scannedDriver"> <i class="fas fa-fw fa-tag"></i>
                                            {{ deviceState?.tagScanStatus?.tag.toLocaleString() }}</span>

                                    </h3>
                                    <div>{{'general.scannedDriver' | translate }} <i
                                            *ngIf="deviceState?.tagScanStatus?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"
                                            title="{{ deviceState?.tagScanStatus?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="device?.asset && !device.asset?.isAssetTracker">
                                <div class="kpi-item-small" [title]="deviceState?.odometer?.updateTimestamp">
                                    <h3 class="margin-bottom font-bold">
                                        {{ (deviceState?.odometer?.gpsOdometer !== null)
                                        ? (actualRound((device?.asset?.calibrationOdoOffsetInKm ?? 0) +
                                        (deviceState?.odometer?.gpsOdometer / 1000), 0)).toLocaleString() : '-' }} {{
                                        translatedKm }}
                                    </h3>
                                    <div>{{'general.assetOdo' | translate }} <i
                                            *ngIf="device?.asset?.calibrationOdoOffsetInKm === null && !hasCanbus"
                                            class="fa fa-fw warning fa-exclamation-triangle"
                                            title="{{'general.addCalibration' | translate}}"></i></div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="device?.asset && permissions['FleetManagement_EngineHourView'] && !device.asset?.isAssetTracker">
                                <div [title]="deviceState?.engineHour?.updateTimestamp" class="kpi-item-small"
                                    [title]="deviceState?.engineHour?.updateTimestamp">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.engineHour?.engineHourInSeconds !== null ?
                                        ( actualRound(((device?.asset?.engineHourCalibrationOdoOffset ?? 0) +
                                        deviceState?.engineHour?.engineHourInSeconds) / 3600, 0)).toLocaleString() : '-'
                                        }} H
                                    </h3>
                                    <div>{{'general.engineHours' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="device?.asset && deviceState?.odometer?.canBusOdometer && !device.asset?.isAssetTracker">
                                <div [title]="deviceState?.odometer?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ actualRound((deviceState?.odometer?.canBusOdometer /1000),0).toLocaleString()
                                        }} {{ translatedKm }}
                                    </h3>
                                    <div>{{'general.canbusOdo' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="device?.asset?.driver && permissions['Drivers_View']">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <a class="secondary link_bolder"
                                            href="/#/DriverDetails/Index/{{device?.asset?.driver?.id }}">
                                            {{ device?.asset?.driver?.name }}</a>
                                    </h3>
                                    <div>{{'general.assignedDriver' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="device?.asset?.activeProject && permissions['Projects_View']">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <a class="secondary link_bolder"
                                            href="/#/ProjectDetails/Index/{{device?.asset?.activeProject?.id }}">
                                            {{ device?.asset?.activeProject?.name }}</a>
                                    </h3>
                                    <div>{{'general.assignedProject' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="showPosition" class="col-6 col-lg-4" style="padding:0">
                                <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
                                    <h3 [ngSwitch]="true" class="margin-bottom font-bold">
                                        <ng-container
                                            *ngSwitchCase="deviceState?.hasGpsFix == false && deviceState?.hasCellFix == true && deviceState?.cellPosition != null && !(deviceState?.cellPosition.latitude === 0 && deviceState?.cellPosition.longitude === 0)">
                                            <i title="Cell Position"
                                                class="full-icon fas fa-fw fa-location-crosshairs-slash"
                                                [ngStyle]="{'color': 'rgba(151,28,36,0.9)' }"></i>
                                            <a target="_blank" rel="nofollow"
                                                href="https://www.google.com/maps/place/{{deviceState?.cellPosition?.latitude}}+{{deviceState?.cellPosition?.longitude}}/@{{deviceState?.cellPosition?.latitude}},{{deviceState?.cellPosition?.longitude}},15z">
                                                {{ deviceState?.cellPosition?.latitude.toLocaleString('en-US', {
                                                minimumFractionDigits: 2 }) + ',' +
                                                deviceState?.cellPosition?.longitude.toLocaleString('en-US', {
                                                minimumFractionDigits: 2 }) }}
                                            </a>
                                        </ng-container>
                                        <ng-container
                                            *ngSwitchCase="deviceState?.hasGpsFix == true && deviceState?.currentPosition != null">
                                            <i title="GPS Position" class="full-icon fas fa-fw fa-location-crosshairs"
                                                [ngStyle]="{'color': 'rgba(0,142,67,0.9)' }"></i>
                                            <a target="_blank" rel="nofollow"
                                                href="https://www.google.com/maps/place/{{deviceState?.currentPosition?.latitude}}+{{deviceState?.currentPosition?.longitude}}/@{{deviceState?.currentPosition?.latitude}},{{deviceState?.currentPosition?.longitude}},15z">
                                                {{ deviceState?.currentPosition?.latitude.toLocaleString('en-US', {
                                                minimumFractionDigits: 2 }) + ', ' +
                                                deviceState?.currentPosition?.longitude.toLocaleString('en-US', {
                                                minimumFractionDigits: 2 }) }}
                                            </a>
                                        </ng-container>
                                        <ng-container
                                            *ngSwitchCase="deviceState?.hasGpsFix == false && deviceState?.currentPosition != null">
                                            <i title="Outdated position"
                                                class="full-icon fas fa-fw fa-location-crosshairs-slash"
                                                [ngStyle]="{'color': 'rgba(151,28,36,0.9)' }"></i>
                                            <a target="_blank" rel="nofollow"
                                                href="https://www.google.com/maps/place/{{deviceState?.currentPosition?.latitude}}+{{deviceState?.currentPosition?.longitude}}/@{{deviceState?.currentPosition?.latitude}},{{deviceState?.currentPosition?.longitude}},15z">
                                                {{ deviceState?.currentPosition?.latitude.toLocaleString('en-US', {
                                                minimumFractionDigits: 2 }) + ',' +
                                                deviceState?.currentPosition?.longitude.toLocaleString('en-US', {
                                                minimumFractionDigits: 2 }) }}
                                            </a>
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            &ndash;
                                        </ng-container>
                                    </h3>
                                    <div>{{'general.location' | translate }} <i
                                            *ngIf="deviceState?.currentPosition != null && deviceState?.currentPosition?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw history-icon hand fa-history"
                                            title="{{ deviceState?.currentPosition?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div *ngIf="showPosition && deviceState?.currentPosition?.satellites" class="col-6 col-lg-4"
                                style="padding:0">
                                <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.currentPosition?.satellites ?
                                        deviceState?.currentPosition?.satellites.toLocaleString():
                                        '-'
                                        }}
                                    </h3>
                                    <div>{{'general.satellites' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="showPosition && deviceState?.currentPosition?.hdop" class="col-6 col-lg-4"
                                style="padding:0">
                                <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.currentPosition?.hdop ?
                                        deviceState?.currentPosition?.hdop.toLocaleString(): '-' }}
                                    </h3>
                                    <div>{{'general.hdop' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="showPosition && deviceState?.currentPosition?.pdop" class="col-6 col-lg-4"
                                style="padding:0">
                                <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.currentPosition?.pdop ?
                                        deviceState?.currentPosition?.pdop.toLocaleString(): '-' }}
                                    </h3>
                                    <div>{{'general.pdop' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="showPosition && deviceState?.currentPosition?.radiusInMeters"
                                class="col-6 col-lg-4" style="padding:0">
                                <div [title]="deviceState?.currentPosition?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.currentPosition?.radiusInMeters ?
                                        deviceState?.currentPosition?.radiusInMeters.toLocaleString() + ' m': '-' }}
                                    </h3>
                                    <div>{{'general.radiusInMeters' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="showPosition && deviceState?.actualAccellerationForce != undefined "
                                class="col-6 col-lg-4" style="padding:0">
                                <div [title]="deviceState?.actualAccellerationForce?.updateTimestamp"
                                    class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.actualAccellerationForce != undefined ?
                                        deviceState?.actualAccellerationForce.toLocaleString() + ' mg': '-' }}
                                    </h3>
                                    <div>{{'general.actualAccellerationForce' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="showPosition && deviceState?.actualBrakingForce != undefined"
                                class="col-6 col-lg-4" style="padding:0">
                                <div [title]="deviceState?.actualAccellerationForce?.updateTimestamp"
                                    class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.actualBrakingForce != undefined ?
                                        deviceState?.actualBrakingForce.toLocaleString() + ' mg': '-' }}
                                    </h3>
                                    <div>{{'general.actualBrakingForce' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="showPosition && deviceState?.actualCorneringForce != undefined"
                                class="col-6 col-lg-4" style="padding:0">
                                <div [title]="deviceState?.actualAccellerationForce?.updateTimestamp"
                                    class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.actualCorneringForce != undefined ?
                                        deviceState?.actualCorneringForce.toLocaleString() + ' mg': '-' }}
                                    </h3>
                                    <div>{{'general.actualCorneringForce' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.fuelConsumptionState?.fuelConsumed != undefined && deviceState?.fuelConsumptionState?.fuelConsumed != 0"
                                class="col-6 col-lg-4" style="padding:0">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.fuelConsumptionState?.fuelConsumed != undefined ?
                                        deviceState?.fuelConsumptionState?.fuelConsumed.toLocaleString() + '
                                        L' : '-' }}
                                    </h3>
                                    <div>{{'general.fuelConsumed' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.batteryConsumptionState?.batteryChargeLevelInPercentage != undefined && deviceState?.batteryConsumptionState?.batteryChargeLevelInPercentage != 0"
                                class="col-6 col-lg-4" style="padding:0">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.batteryConsumptionState?.batteryChargeLevelInPercentage !=
                                        undefined ?
                                        deviceState?.batteryConsumptionState?.batteryChargeLevelInPercentage.toLocaleString()
                                        + '%' : '-' }}
                                    </h3>
                                    <div>{{'general.batteryChargeLevelInPercentage' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.batteryConsumptionState?.batteryChargeState != undefined"
                                class="col-6 col-lg-4" style="padding:0">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-car-battery"
                                            [ngStyle]="{'color': (deviceState?.batteryConsumptionState?.batteryChargeState == null) ? '#ccc' : (deviceState?.batteryConsumptionState?.batteryChargeState ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.' + deviceState?.batteryConsumptionState?.batteryChargeState
                                        | translate }}

                                    </h3>
                                    <div>{{'general.batteryChargeState' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.batteryConsumptionState?.batteryPowerConsumptionInKWhPer100Km != undefined && deviceState?.batteryConsumptionState?.batteryPowerConsumptionInKWhPer100Km != 0"
                                class="col-6 col-lg-4" style="padding:0">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.batteryConsumptionState?.batteryPowerConsumptionInKWhPer100Km !=
                                        undefined ?
                                        deviceState?.batteryConsumptionState?.batteryPowerConsumptionInKWhPer100Km.toLocaleString()
                                        + 'KWhPer100Km ' : '-' }}
                                    </h3>
                                    <div>{{'general.batteryPowerConsumptionInKWhPer100Km' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.batteryConsumptionState?.remainingDistanceInKm != undefined && deviceState?.batteryConsumptionState?.remainingDistanceInKm != 0"
                                class="col-6 col-lg-4" style="padding:0">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.batteryConsumptionState?.remainingDistanceInKm !=
                                        undefined ?
                                        deviceState?.batteryConsumptionState?.remainingDistanceInKm.toLocaleString()
                                        + ' ' + translatedKm : '-' }}
                                    </h3>
                                    <div>{{'general.remainingDistanceInKm' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.angle1InDegrees != undefined"
                                class="col-6 col-lg-4" style="padding:0">
                                <div [title]="deviceState?.angle1InDegrees?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i [ngStyle]="{'transform': 'rotate('+deviceState?.angle1InDegrees+'deg)', '-webkit-transform': 'rotate('+deviceState?.angle1InDegrees+'deg)', '-ms-transform': 'rotate('+deviceState?.angle1InDegrees+'deg)'}"
                                            class="full-icon fas fa-fw  fa-arrow-up"></i>

                                        {{ deviceState?.angle1InDegrees != undefined ?
                                        deviceState?.angle1InDegrees.toLocaleString() + ' °' : '-' }}
                                    </h3>
                                    <div>{{'general.angle1InDegrees' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.angle2InDegrees != undefined"
                                class="col-6 col-lg-4" style="padding:0">
                                <div [title]="deviceState?.angle2InDegrees?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i [ngStyle]="{'transform': 'rotate('+deviceState?.angle2InDegrees+'deg)', '-webkit-transform': 'rotate('+deviceState?.angle2InDegrees+'deg)', '-ms-transform': 'rotate('+deviceState?.angle2InDegrees+'deg)'}"
                                            class="full-icon fas fa-fw  fa-arrow-up"></i>

                                        {{ deviceState?.angle2InDegrees != undefined ?
                                        deviceState?.angle2InDegrees.toLocaleString() + ' °' : '-' }}
                                    </h3>
                                    <div>{{'general.angle2InDegrees' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.angle3InDegrees != undefined"
                                class="col-6 col-lg-4" style="padding:0">
                                <div [title]="deviceState?.angle3InDegrees?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i [ngStyle]="{'transform': 'rotate('+deviceState?.angle3InDegrees+'deg)', '-webkit-transform': 'rotate('+deviceState?.angle3InDegrees+'deg)', '-ms-transform': 'rotate('+deviceState?.angle3InDegrees+'deg)'}"
                                            class="full-icon fas fa-fw  fa-arrow-up"></i>

                                        {{ deviceState?.angle3InDegrees != undefined ?
                                        deviceState?.angle3InDegrees.toLocaleString() + ' °' : '-' }}
                                    </h3>
                                    <div>{{'general.angle3InDegrees' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.angle4InDegrees != undefined"
                                class="col-6 col-lg-4" style="padding:0">
                                <div [title]="deviceState?.angle4InDegrees?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i [ngStyle]="{'transform': 'rotate('+deviceState?.angle4InDegrees+'deg)', '-webkit-transform': 'rotate('+deviceState?.angle4InDegrees+'deg)', '-ms-transform': 'rotate('+deviceState?.angle4InDegrees+'deg)'}"
                                            class="full-icon fas fa-fw  fa-arrow-up"></i>

                                        {{ deviceState?.angle4InDegrees != undefined ?
                                        deviceState?.angle4InDegrees.toLocaleString() + ' °' : '-' }}
                                    </h3>
                                    <div>{{'general.angle4InDegrees' | translate }}</div>
                                </div>
                            </div>

                            <ng-container *ngIf="showPosition">
                                <div *ngIf="deviceState?.calculatedIgnitionState && !device.asset?.isAssetTracker"
                                    class="col-6 col-lg-4" style="padding:0">
                                    <div [title]="deviceState?.calculatedIgnitionState?.stateChangedTimestamp"
                                        class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            <i class="full-icon fas fa-fw  fa-key"
                                                [ngStyle]="{'color': (!deviceState?.calculatedIgnitionState) ? '#ccc' : (deviceState.calculatedIgnitionState.isIgnitionOn ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                            {{ 'general.stateSince' | translate }} {{
                                            deviceState?.calculatedIgnitionState?.stateChangedTimestamp | amTimeAgo:true
                                            }}
                                        </h3>
                                        <div> {{ 'general.stateIgnition' | translate }}
                                            <i *ngIf="deviceState?.calculatedIgnitionState?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                                class="fa fa-fw history-icon hand fa-history"
                                                title="{{ deviceState?.calculatedIgnitionState?.updateTimestamp }}"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding:0"
                                    *ngIf="deviceState.idlingState?.isCurrentlyIdling && !device.asset?.isAssetTracker">
                                    <div [title]="deviceState?.idlingState?.stateChangedTimestamp"
                                        class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            <i class="full-icon fas fa-fw fa-snooze"
                                                [ngStyle]="{'color': (!deviceState?.idlingState) ? '#ccc' : (deviceState.idlingState.isCurrentlyIdling ? 'rgba(238, 146, 52, 0.9)' : 'rgba(255, 255, 255, 0.3)') }"></i>
                                            <span *ngIf="deviceState.idlingState?.isCurrentlyIdling">{{
                                                'general.stateSince' | translate }} {{
                                                deviceState?.idlingState?.stateChangedTimestamp | amTimeAgo:true
                                                }}</span>
                                            <span *ngIf="!deviceState.idlingState?.isCurrentlyIdling">-</span>
                                        </h3>
                                        <div>
                                            {{ 'general.stateIdling' | translate }}
                                            <i *ngIf="deviceState?.idlingState?.calculatedTimestamp < deviceState.communicationState?.updateTimestamp"
                                                class="fa fa-fw history-icon hand fa-history"
                                                title="{{ deviceState?.idlingState?.calculatedTimestamp }}"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding:0">
                                    <div [title]="deviceState?.calculatedCommunicatingState?.updateTimestamp"
                                        class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            <i class="full-icon fas fa-fw fa-satellite-dish"
                                                [ngStyle]="{'color': (!deviceState?.calculatedCommunicatingState) ? '#ccc' : (deviceState.calculatedCommunicatingState.isCommunicating ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                            {{ deviceState.communicationState?.updateTimestamp | amTimeAgo:true }}
                                        </h3>
                                        <div> {{ 'general.stateCommunicating' | translate }}
                                            <i *ngIf="deviceState?.calculatedCommunicatingState?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                                class="fa fa-fw history-icon hand fa-history"
                                                title="{{ deviceState?.calculatedCommunicatingState?.updateTimestamp }}"></i>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="deviceState?.calculatedExternalPowerState && !device.asset?.isAssetTracker"
                                    class="col-6 col-lg-4" style="padding:0">
                                    <div [title]="deviceState?.calculatedExternalPowerState?.updateTimestamp"
                                        class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            <i class="full-icon fas fa-fw fa-car-battery"
                                                [ngStyle]="{'color': (!deviceState?.calculatedExternalPowerState) ? '#ccc' : (deviceState.calculatedExternalPowerState.isExternalPowerOn ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                            {{ 'general.' + deviceState?.calculatedExternalPowerState?.isExternalPowerOn
                                            | translate }}
                                        </h3>
                                        <div> {{ 'general.stateExternalPower' | translate }}
                                            <i *ngIf="deviceState?.calculatedExternalPowerState?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                                class="fa fa-fw history-icon hand fa-history"
                                                title="{{ deviceState?.calculatedExternalPowerState?.updateTimestamp }}"></i>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                </div>

                <!-- IO States -->
                <div class="ibox" *ngIf="!device.asset?.isAssetTracker">
                    <div class="ibox-title">
                        <span class="pull-left">
                            <h5>
                                <i class="fas fa-fw fa-random"></i> {{'general.ioStates' | translate }}
                                &nbsp;
                            </h5>
                            <span [tooltip]="'Some of your input ports are unmapped'" *ngIf="unmappedPorts">
                                <i style="color: #CB711D;" cclass="fas fa-fw fa-exclamation-triangle"></i>
                            </span>
                        </span>
                        <span class="float-right" [tooltip]="'Show unmapped items'">
                            <i (click)="flipUnMapped()" class="fa-duotone fa-lg fa-fw"
                                [ngClass]="showUnMapped ? 'fa-toggle-on' : 'fa-toggle-off'">
                            </i>
                        </span>
                    </div>
                    <div class="ibox-content" style="position: relative;"
                        *ngIf="deviceState == null && !loadingLocation">
                        {{ ('general.noDeviceState') | translate }}
                    </div>
                    <div class="ibox-content" style="position: relative;"
                        *ngIf="deviceState == null && loadingLocation">
                        <ngx-loading [show]="loadingLocation"></ngx-loading>
                        {{ ('general.loadingDeviceState') | translate }}
                    </div>
                    <div class="ibox-content slick" style="position: relative;" *ngIf="deviceState != null">
                        <ngx-loading [show]="loadingLocation"></ngx-loading>

                        <div class="flex-container" [@enter]>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.inputPorts[0] && device.settings.inputPorts[0].byte != null && device.settings.inputPorts[0].byte != 0) || showUnMapped">
                                <div [title]="deviceState?.input1?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState?.input1 == null) ? '#ccc' : (deviceState?.input1?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.inputFormatted' | translate:{ value: '1' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.inputPorts && 'enums.locationEventType.' +
                                        device.settings.inputPorts[0]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.inputPorts[1] && device.settings.inputPorts[1].byte != null && device.settings.inputPorts[1].byte != 0) || showUnMapped">
                                <div [title]="deviceState?.input2?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState?.input2 == null) ? '#ccc' : (deviceState?.input2?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.inputFormatted' | translate:{ value: '2' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.inputPorts && 'enums.locationEventType.' +
                                        device.settings.inputPorts[1]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.inputPorts[2] && device.settings.inputPorts[2].byte != null && device.settings.inputPorts[2].byte != 0) || showUnMapped">
                                <div [title]="deviceState?.input3?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState?.input3 == null) ? '#ccc' : (deviceState?.input3?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.inputFormatted' | translate:{ value: '3' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.inputPorts && 'enums.locationEventType.' +
                                        device.settings.inputPorts[2]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.inputPorts[3] && device.settings.inputPorts[3].byte != null && device.settings.inputPorts[3].byte != 0) || showUnMapped">
                                <div [title]="deviceState?.input4?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState?.input4 == null) ? '#ccc' : (deviceState?.input4?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.inputFormatted' | translate:{ value: '4' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.inputPorts && 'enums.locationEventType.' +
                                        device.settings.inputPorts[3]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.inputPorts[4] && device.settings.inputPorts[4].byte != null && device.settings.inputPorts[4].byte != 0) || showUnMapped">
                                <div [title]="deviceState?.input5?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState?.input5 == null) ? '#ccc' : (deviceState?.input5?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.inputFormatted' | translate:{ value: '5' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.inputPorts && 'enums.locationEventType.' +
                                        device.settings.inputPorts[4]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.inputPorts[5] && device.settings.inputPorts[5].byte != null && device.settings.inputPorts[5].byte != 0) || showUnMapped">
                                <div [title]="deviceState?.input6?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState?.input6 == null) ? '#ccc' : (deviceState?.input6?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.inputFormatted' | translate:{ value: '6' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.inputPorts && 'enums.locationEventType.' +
                                        device.settings.inputPorts[5]?.byte | translate }}
                                    </div>
                                </div>

                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.ignition && device.settings.ignition != null && device.settings.ignition != 0) && showUnMapped && !device.asset?.isAssetTracker">
                                <div [title]="deviceState?.ignition?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState == null || deviceState?.ignition == null) ? '#ccc' : (deviceState?.ignition?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.ignition' | translate}} ({{ 'general.port' | translate}})
                                    </h3>
                                    <div> {{device.settings.ignition && 'enums.locationEventType.' +
                                        device.settings.ignition | translate }}</div>
                                </div>

                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.externalPower && device.settings.externalPower != null && device.settings.externalPower != 0) && showUnMapped && !device.asset?.isAssetTracker">
                                <div [title]="deviceState?.externalPower?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState?.externalPower == null) ? '#ccc' : (deviceState?.externalPower?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.externalPower' | translate}} ({{ 'general.port' | translate}})
                                    </h3>
                                    <div> {{device.settings.externalPower && 'enums.locationEventType.' +
                                        device.settings.externalPower | translate }}</div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.outputPorts[0] && device.settings.outputPorts[0].byte != null && device.settings.outputPorts[0].byte != 0) || showUnMapped">
                                <div [title]="deviceState?.output1?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                                            [ngStyle]="{'color': (deviceState?.output1 == null) ? '#ccc' : (deviceState?.output1?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.outputFormatted' | translate:{ value: '1' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.outputPorts && 'enums.deviceOutput.' +
                                        device.settings.outputPorts[0]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.outputPorts[1] && device.settings.outputPorts[1].byte != null && device.settings.outputPorts[1].byte != 0) || showUnMapped">
                                <div [title]="deviceState?.output2?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                                            [ngStyle]="{'color': (deviceState?.output2 == null) ? '#ccc' : (deviceState?.output2?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.outputFormatted' | translate:{ value: '2' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.outputPorts && 'enums.deviceOutput.' +
                                        device.settings.outputPorts[1]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.outputPorts[2] && device.settings.outputPorts[2].byte != null && device.settings.outputPorts[2].byte != 0)  || showUnMapped">
                                <div [title]="deviceState?.output3?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                                            [ngStyle]="{'color': (deviceState?.output3 == null) ? '#ccc' : (deviceState?.output3?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.outputFormatted' | translate:{ value: '3' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.outputPorts && 'enums.deviceOutput.' +
                                        device.settings.outputPorts[2]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.outputPorts[3] && device.settings.outputPorts[3].byte != null && device.settings.outputPorts[3].byte != 0)  || showUnMapped">
                                <div [title]="deviceState?.output4?.updateTimestamp" class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-arrow-alt-circle-down"
                                            [ngStyle]="{'color': (deviceState?.output4 == null) ? '#ccc' : (deviceState?.output4?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.outputFormatted' | translate:{ value: '4' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.outputPorts && 'enums.deviceOutput.' +
                                        device.settings.outputPorts[3]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.analogPorts[0] && device.settings.analogPorts[0].byte != null && device.settings.analogPorts[0].byte != 0)  || showUnMapped">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-bolt"></i>
                                        {{deviceState?.analog1 ? deviceState?.analog1?.value?.toLocaleString() : '-' }}
                                        V
                                    </h3>
                                    <div>
                                        {{device.settings.analogPorts && 'enums.analogFunctions.' +
                                        device.settings.analogPorts[0]?.byte | translate }} (Analog 1)
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.analogPorts[1] && device.settings.analogPorts[1].byte != null && device.settings.analogPorts[1].byte != 0)  || showUnMapped">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-bolt"></i>
                                        {{deviceState?.analog2 ? deviceState?.analog2?.value?.toLocaleString() : '-' }}
                                        V
                                    </h3>
                                    <div>
                                        {{device.settings.analogPorts && 'enums.analogFunctions.' +
                                        device.settings.analogPorts[1]?.byte | translate }} (Analog 2)
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.analogPorts[2] && device.settings.analogPorts[2].byte != null && device.settings.analogPorts[2].byte != 0)  || showUnMapped">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-bolt"></i>
                                        {{ deviceState?.analog3 ? deviceState?.analog3?.value?.toLocaleString() : '-' }}
                                        V
                                    </h3>
                                    <div>
                                        {{device.settings.analogPorts && 'enums.analogFunctions.' +
                                        device.settings.analogPorts[2]?.byte | translate }} (Analog 3)
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngIf="(device.settings.analogPorts[3] && device.settings.analogPorts[3].byte != null && device.settings.analogPorts[3].byte != 0)  || showUnMapped">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="full-icon fas fa-fw fa-bolt"></i>
                                        {{deviceState?.analog4 ? deviceState?.analog4?.value?.toLocaleString() : '-' }}
                                        V
                                    </h3>
                                    <div>
                                        {{device.settings.analogPorts && 'enums.analogFunctions.' +
                                        device.settings.analogPorts[3]?.byte | translate }} (Analog 4)
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                </div>

                <div class="ibox" *ngIf="deviceState != null && deviceState.virtualSensors?.length > 0">
                    <div class="ibox-title">
                        <span class="pull-left">
                            <h5>
                                <i class="fas fa-fw fa-sensor"></i> {{'general.virtualSensors' | translate }}
                                &nbsp;
                            </h5>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative;" *ngIf="deviceState != null">
                        <div class="flex-container" [@enter]>
                            <div class="col-6 col-lg-4" style="padding:0"
                                *ngFor="let virtualSensor of deviceState.virtualSensors">
                                <div class="kpi-item-small">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-sensor"
                                            [ngStyle]="{'color': (virtualSensor.value == null || virtualSensor.value == '') ? '#ccc' : (virtualSensor.value == 'True' ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{('enums.virtualSensorOutputType.' + virtualSensor.sensorOutputType) |
                                        translate}}
                                    </h3>
                                    <div>
                                        {{ virtualSensor.name }}
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                </div>

                <div class="ibox"
                    *ngIf="permissions['FleetManagement_DeviceUtilization'] && showUtilization && !hideUtilization && !device.asset?.isAssetTracker">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-engine"></i> {{'general.utilization' | translate }}
                        </h5>

                        <span class="float-right form-group input-group hand"
                            style="margin-bottom: 6px; width: 220px; margin-top: -6px; margin-bottom: -6px; background: none;">
                            <input placeholder="Daterangepicker"
                                style=" margin-top: 0px; margin-bottom: -5px; background: none;"
                                placeholder="Datepicker" type="text" [bsConfig]="dpConfig" class="form-control hand"
                                bsDaterangepicker (onHidden)="dateChanged('hidden')"
                                [(ngModel)]="daterangepickerModel" />
                            <span class="input-group-calendar-datatables">
                                <i class="fa fa-calendar-alt"></i>
                            </span>
                        </span>
                    </div>
                    <div class="ibox-content" style="display: none">
                        <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker" type="text"
                            [bsConfig]="dpConfig" class="form-control" #dp="bsDaterangepicker" bsDaterangepicker
                            (onHidden)="dateChanged('hidden')" [(ngModel)]="daterangepickerModel">
                    </div>
                    <div class="ibox-content slick" style="position: relative;">
                        <ngx-loading [show]="loadingUtilization"></ngx-loading>
                        <div style="padding: 10px" *ngIf="!deviceUtilization && loadingUtilization"> {{
                            ('general.loadingData') | translate }}
                        </div>
                        <div style="padding: 10px" *ngIf="!deviceUtilization && !loadingUtilization"> {{
                            ('general.noDataFound') | translate }}
                        </div>
                        <div *ngIf="deviceUtilization">
                            <div class="flex-container">
                                <div class="col-6 col-lg-4" style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.segmentsDistance ?
                                            deviceUtilization?.segmentsDistance.toLocaleString() : 0 }} {{translatedKm}}
                                        </h3>
                                        <div>{{'general.distance' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.workingHoursInSeconds ?
                                            actualRoundSeconds(deviceUtilization?.workingHoursInSeconds) : '-'
                                            }}
                                        </h3>
                                        <div>{{'general.duration' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.idlingDurationInSeconds ?
                                            actualRoundSeconds(deviceUtilization?.idlingDurationInSeconds) : '-'
                                            }}
                                        </h3>
                                        <div>{{'general.idling' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            <a class='secondary link_bolder' (click)="$event.stopPropagation();"
                                                href="/#/DeviceDetails/Trips/{{device.id}}"> {{
                                                deviceUtilization?.tripCount ?
                                                deviceUtilization?.tripCount?.toLocaleString() : 0 }}</a>
                                        </h3>
                                        <div>{{'general.trips' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{actualRound(deviceUtilization?.utilization,2)?.toLocaleString() }} %
                                        </h3>
                                        <div>{{'general.utilization' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.maxSpeed ?
                                            deviceUtilization?.maxSpeed : '-' }} {{translatedKmh}}
                                        </h3>
                                        <div>{{'general.maxSpeed' | translate }}</div>
                                    </div>
                                </div>
                                <div *ngIf="permissions['FleetManagement_Equipment']" class="col-6 col-lg-4"
                                    style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.equipmentWorkDurationInSeconds ?
                                            actualRoundSeconds(deviceUtilization?.equipmentWorkDurationInSeconds) : '-'
                                            }}
                                        </h3>
                                        <div>{{'general.equipmentWorkDurationInSeconds' | translate }}</div>
                                    </div>
                                </div>
                                <div *ngIf="permissions['FleetManagement_Equipment']" class="col-6 col-lg-4"
                                    style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">{{
                                            deviceUtilization?.equipmentIdlingDurationInSeconds ?
                                            actualRoundSeconds(deviceUtilization?.equipmentIdlingDurationInSeconds) :
                                            '-'
                                            }}

                                        </h3>
                                        <div>{{'general.equipmentIdlingDurationInSeconds' | translate }}</div>
                                    </div>
                                </div>
                                <div *ngIf="permissions['FleetManagement_Equipment']" class="col-6 col-lg-4"
                                    style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.equipmentCrossOverDurationInSeconds
                                            ? actualRoundSeconds(deviceUtilization?.equipmentCrossOverDurationInSeconds)
                                            : '-'
                                            }}
                                        </h3>
                                        <div>{{'general.equipmentCrossOverDurationInSeconds' | translate }}</div>
                                    </div>
                                </div>

                                <div class="col-6 col-lg-4" style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.fuelLostWhileIdling
                                            ? actualRound(deviceUtilization?.fuelLostWhileIdling, 1).toLocaleString() :
                                            '-'
                                            }} L
                                        </h3>
                                        <div>{{'general.fuelLostWhileIdling' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.fuelLostPercentage
                                            ? actualRound(deviceUtilization?.fuelLostPercentage, 1).toLocaleString() :
                                            '-'
                                            }}%
                                        </h3>
                                        <div>{{'general.fuelLostPercentage' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.fuelUsedTotal
                                            ? actualRound(deviceUtilization?.fuelUsedTotal, 1).toLocaleString() : '-'
                                            }} L
                                        </h3>
                                        <div>{{'general.fuelUsedTotal' | translate }}</div>
                                    </div>
                                </div>

                                <div class="col-6 col-lg-4" style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.totalEmissionCO2 ?
                                            actualRound(deviceUtilization?.totalEmissionCO2, 1).toLocaleString() : '-'
                                            }} kg
                                        </h3>
                                        <div>{{'general.totalEmissionCO2' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">{{
                                            deviceUtilization?.totalEmissionParticlesLight ?
                                            actualRound(deviceUtilization?.totalEmissionParticlesLight,
                                            1).toLocaleString() : '-'
                                            }} / {{
                                            deviceUtilization?.totalEmissionParticlesHeavy ?
                                            actualRound(deviceUtilization?.totalEmissionParticlesHeavy,
                                            1).toLocaleString() : '-'
                                            }}

                                        </h3>
                                        <div>{{'general.totalEmissionParticlesLight' | translate }} /
                                            {{'general.totalEmissionParticlesHeavy' | translate }} </div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-4" style="padding:0">
                                    <div class="kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.totalConsumptionMixed
                                            ? actualRound(deviceUtilization?.totalConsumptionMixed, 1).toLocaleString()
                                            : '-'
                                            }} L
                                        </h3>
                                        <div>{{'general.totalConsumptionMixed' | translate }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-ruler"></i> {{'general.distanceperday' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart *ngIf="chartDistance" [runOutsideAngular]="true"
                            style="display: block; width: 100%; height:214px" [Highcharts]="Highcharts"
                            [options]="chartDistance">
                        </highcharts-chart>
                    </div>
                </div>

                <div class="ibox" *ngIf="permissions['Platform_IsReseller']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-comment-alt-dots"></i> {{'general.locationCount' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart *ngIf="chartLocationCount" [runOutsideAngular]="true"
                            style="display: block; width: 100%; height:214px" [Highcharts]="Highcharts"
                            [options]="chartLocationCount">
                        </highcharts-chart>
                    </div>
                </div>

                <div class="ibox" *ngIf="!hideTriggers && permissions['Triggers_View']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-bell-on"></i> {{'general.triggers' |
                            translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <ngx-loading [show]="loadingTriggers"></ngx-loading>

                        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th>{{'general.name' | translate }}</th>
                                    <th class="elipses_overflow" style="width: 100px"
                                        title="{{'general.episodeCountLastWeek' | translate }}">
                                        {{'general.episodeCountLastWeek' | translate }}</th>
                                    <th class="elipses_overflow" style="width: 100px"
                                        title="{{'general.notificationCountLastWeek' | translate }}">
                                        {{'general.notificationCountLastWeek' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="triggers.length == 0">
                                <tr>
                                    <td colspan="3">
                                        {{'general.noTriggersFound' | translate }}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr *ngFor="let trigger of triggers">
                                    <td class="elipses_overflow" title="{{trigger?.name}}">
                                        <a href="/#/Notifications/TriggerDetails/Index/{{trigger?.id}}">
                                            <span class="secondary link_bolder"><i
                                                    cclass="fas fa-fw fa-angle-right"></i></span>
                                            {{trigger?.name}}</a>
                                    </td>
                                    <td class="elipses_overflow" style="width: 100px">{{trigger?.episodeCount}}</td>
                                    <td class="elipses_overflow" style="width: 100px">{{trigger?.notificationCount}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <fh-group-overview name="{{'general.assetGroups' | translate }}" [loading]="loadingGroups"
                    [childId]="device.asset?.id" (onFinish)="getDeviceInfo()" [groups]="assetGroups"
                    [linkUrl]="assetGroupLink" [deletePermission]="'Assets_Edit'">
                    <span groupAddSlot (click)="confirmationModal.showModal(device.asset.id)"
                        [title]="'general.addToGroup' | translate" container="body">
                        <button class="btn btn-primary pull-left">
                            <i class="fa fa-fw fa-add"></i> {{ 'general.assign' | translate}}
                        </button>
                    </span>
                </fh-group-overview>

                <fh-schedule-assignments [assetId]="device?.asset?.id" [selectedAccountId]="device?.accountId"
                    [deletePermission]="'Assets_Edit'">
                </fh-schedule-assignments>

                <fh-tagging [objectId]="device.id" [objectType]="tagType">
                </fh-tagging>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-confirmation-modal #confirmationModal (onFinish)="addToGroup($event, confirmationModal, groupInputVar)"
    [invalid]="!(groupInputVar.value?.length > 0) || groupInputVar.loadingAssetGroups">
    <h4 header class="modal-title pull-left"><i class="fa fa-fw fa-bolt"></i> {{'general.action' | translate }}: {{
        'general.addToGroup' | translate }}</h4>
    <form body #groupForm="ngForm" style="width: 100%; border-top: 0px;">
        <div class="modal-body">
            <fh-asset-group-input #groupInputVar ngDefaultControl required [tableInline]="false"
                [selectedAccountId]="device.accountId" [hideSystemGroups]="true" [hideEmptyGroups]="false"
                [activeOnly]="true" [autoSelect]="false" [canAddNew]="true"
                placeholder="{{ 'general.chooseAssetGroups' | translate }}">
            </fh-asset-group-input>
        </div>
    </form>
</fh-confirmation-modal>

<fh-confirmation-modal #archiveModal (onFinish)="onArchiveAsset($event)">
    <h4 header class="modal-title pull-left"><i class="fa fa-fw fa-bolt"></i> {{'general.action' | translate }}:
        {{ 'general.archiveDevice' | translate }}</h4>
    <fh-archiving-modal #archiveBody [device]="device" body></fh-archiving-modal>
</fh-confirmation-modal>