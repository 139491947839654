import { Component, ViewChild, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { SharingCreateViewerComponent } from './sharingCreate.component';
import { sha256 } from 'js-sha256';
import { UserService } from 'app/services/users/user.service';
import { AssetGroupChooserComponent } from './assetGroupChooser.component';
import { DriverGroupChooserComponent } from './driverGroupChooser.component';

@Component({
    selector: 'fh-sharing',
    templateUrl: 'sharing.template.html',
})
export class SharingComponent implements OnInit {
    @ViewChild('assetGroupChooser') assetGroupChooser: AssetGroupChooserComponent;
    @ViewChild('driverGroupChooser') driverGroupChooser: DriverGroupChooserComponent;
    @ViewChild('sharingCreateViewerComponent') sharingCreate: SharingCreateViewerComponent;

    @Input() accountId: any;

    loading: boolean;
    sharedItems = [];
    totalItems = 0;
    currentPage = 0;
    account;

    error;
    success;
    filter = '';

    constructor(private userService: UserService, private authenticationService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.loading = true;

        this.getAccountViewers();
    }

    ngOnChange() {
        this.loading = true;

        this.getAccountViewers();
    }

    onSelect(item) {
        if (item.id > 0) {
            this.loading = true;
            this.userService.postUserAccountViewer(item.id, this.accountId).subscribe(result => {
                if (result.isSuccess) {
                    this.success = {
                        statusText: 'Success',
                        success: 'Sharing is successfully updated.'
                    };
                } else {
                    this.success = null;
                    this.error = {
                        statusText: 'Error',
                        error: result.message,
                    };
                }

                this.getAccountViewers();
            }, error => {
                this.success = null;
                this.error = error;
                this.getAccountViewers();
            });
        } else {
            const userObject = { ...item };
            userObject.timezoneIana = this.authenticationService.getTimeZoneIana();
            userObject.password = sha256(item.password);

            this.loading = true;

            this.userService.saveUser(userObject).subscribe(result => {
                this.getAccountViewers();
            }, error => {
                this.error = error;
                this.getAccountViewers();
            });
        }
    }

    assignGroups(item) {
        this.loading = true;
        this.userService.postAssetGroupsbyUserId(item).subscribe(assetGroupResult => {
            if (assetGroupResult.isSuccess) {
                this.success = {
                    statusText: 'Success',
                    success: 'Sharing is successfully updated.'
                };
            } else {
                this.success = null;
                this.error = {
                    statusText: 'Error',
                    error: assetGroupResult.message,
                };
            }

            this.sharedItems = assetGroupResult;
            this.loading = false;
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
        });
    }

    assignDriverGroups(item) {
        this.loading = true;
        this.userService.postDriverGroupsbyUserId(item).subscribe(driverGroupResult => {
            if (driverGroupResult.isSuccess) {
                this.success = {
                    statusText: 'Success',
                    success: 'Sharing is successfully updated.'
                };
            } else {
                this.success = null;
                this.error = {
                    statusText: 'Error',
                    error: driverGroupResult.message,
                };
            }

            this.sharedItems = driverGroupResult;
            this.loading = false;
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
        });
    }

    getAccountViewers() {
        this.userService.getViewersbyAccountId(this.accountId).subscribe(sharedItemResult => {
            
            this.sharedItems = sharedItemResult.sort((a, b) => a.emailLoginName.localeCompare(b.emailLoginName));
            this.totalItems = sharedItemResult?.length;
            this.loading = false;
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
        });
    }

    deleteAccountViewer(id) {
        this.loading = true;

        this.userService.deleteUserAccountViewer(this.authenticationService.getUserId(), id, this.accountId).subscribe(_ => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Account is not shared with this user anymore.'
            };

            this.getAccountViewers();
        }, error => {
            this.success = null;
            this.error = error;
            this.getAccountViewers();
        });
    }
}
