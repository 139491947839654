<ng-container *ngIf="permissions['Platform_IsAdministrator'] || !isUserConnected">
    <a aria-expanded="false" role="button" dropdownToggle>
        <span *ngIf="batches.length === 1"><i class="fa fa-sync fa-spin"></i> <span class="d-none d-md-inline-flex">{{
                'general.processing' | translate}}</span> {{ batches[0].percentage }} %
            <span class="caret" style="margin-left:-5px"></span>
        </span>
        <span *ngIf="batches.length > 1"><i class="fa fa-sync fa-spin"></i> <span class="d-none d-md-inline-flex">{{
                'general.processing' | translate}} </span> {{ batches.length }} <span class="d-none d-md-inline-flex">{{
                'general.batches' | translate}} </span>
            <span class="caret" style="margin-left:-5px"></span>
        </span>
        <span *ngIf="batches.length == 0"><i class="fa fa-link"
                [ngClass]="{ 'error': isUserConnected == false || !connection?.connectionId || !isConntected }"></i>
            <span class="hidden"> Hub: {{ hubUserStatusMessage }}</span>
            <span class="caret" style="margin-left:-5px"></span>
        </span>
    </a>
    <ul role="menu" *dropdownMenu class="dropdown-menu dropdown-menu-fix dropdown-menu-right">
        <li><a><i class="fa fa-link" style="margin-right: 5px;"></i> {{ 'general.connection' | translate}}: {{
                connection?.connectionId }}</a></li>
        <li><a><i class="fa fa-users" style="margin-right: 5px;"></i> {{ 'general.user' | translate}}: {{
                hubUserStatusMessage }}</a></li>
        <li><a (click)="sendTests()"><i class="fa fa-users"></i> {{'general.test'
                |translate }}</a></li>
        <!-- <li><a (click)="sendTest()"><i class="fa fa-users"></i> Direct: {{ hubStatusMessage }} | {{ 'general.test'
                    |translate }}</a></li> -->
        <li *ngIf="isUserConnected == false || !connection?.connectionId || !isConntected"><a (click)="reconnect()"><i
                    class="fa fa-link"></i> {{ 'general.reconnect' |
                translate }}</a></li>
        <li *ngFor="let batch of batches;"><a>{{ ('enums.batchAction.' + batch.batchActionType) | translate }} | {{ batch.percentage
                }}%</a></li>
    </ul>
</ng-container>

<ng-container *ngIf="!permissions['Platform_IsAdministrator'] && batches.length > 0">
    <a aria-expanded="false" role="button" dropdownToggle>
        <span *ngIf="batches.length === 1"><i class="fa fa-sync fa-spin"></i> <span class="d-none d-md-inline-flex">{{
                'general.processing' | translate}}</span> {{ batches[0].percentage }} %
            <span class="caret"></span>
        </span>
        <span *ngIf="batches.length > 1"><i class="fa fa-sync fa-spin"></i> <span class="d-none d-md-inline-flex">{{
                'general.processing' | translate}} </span> {{ batches.length }} <span class="d-none d-md-inline-flex">{{
                'general.batches' | translate}} </span>
            <span class="caret"></span>
        </span>
        &nbsp;
    </a>
    <ul role="menu" *dropdownMenu class="dropdown-menu dropdown-menu-fix dropdown-menu-right">
        <li *ngFor="let batch of batches;"><a>{{ 'enums.batchAction.' + batch.batchActionType }} | {{ batch.percentage
                }}%</a></li>
    </ul>
</ng-container>