<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/#/Accounts/Overview">{{'general.accounts' | translate }}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="account?.id">
                    <a href="/#/AccountDetails/Index/{{account?.id}}">{{account?.name}}</a>
                </li>
                <li class="breadcrumb-item active">{{'general.notifications' | translate }}</li>
            </ol>
        </nav>
    </div>
    <fh-account-details-navigation [accountId]="account?.id"></fh-account-details-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>

    <div class="row" data-equalizer="">
        <fh-notifications-list [notifications]="notifications" [loading]="loadingNotifications"
            (onDateChanged)="dateChanged($event)" [daterangepickerModel]="daterangepickerModel" [dpConfig]="dpConfig">
        </fh-notifications-list>
    </div>
</div>