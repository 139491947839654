import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getDefaultDpConfig, getGridLanguages, getGridButtons, createdCellCheckbox } from 'app/common/gridhelper';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { GridBase360Directive } from 'app/common/360Grid.base';
import { WarehouseService } from 'app/services/warehouse/warehouse.service';
import { Warehouse } from 'app/models/warehouse.model';

// Moment timezone
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { roundAsNumber, roundAsString, roundMinutes, roundSeconds } from 'app/common/globals';
import { AccountService } from 'app/services/account/account.service';
import { AccountInventory } from 'app/models/account.model';
import { FhChartService } from 'app/services/charts/charts.service';
import { DriverScoreType } from 'app/common/enums';


window['moment'] = Moment;
mTZ();

import * as Highcharts from 'highcharts';
import { StorageHelper } from 'app/common/storagehelper';
import { DistanceUnitService } from 'app/common/distanceunit.service';
import { ColorService } from 'app/services/common/color.service';

@Component({
    providers: [FhChartService],
    selector: 'fh-account-driverscore',
    templateUrl: 'driverscore.template.html'
})
export class AccountDriverScoreViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
    Highcharts: typeof Highcharts = Highcharts;

    loading: boolean;
    asset: any;
    sub: any;
    messages: any;

    theMarker;

    excludingColumns = ['timestamp'];

    // Datepicker
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    to: any;
    from: any;
    daterangepickerModel: any[];
    permissions: {};
    languageLoaded: boolean;

    maxDate = new Date();
    sensors = [];
    constructorName = 'AccountDriverScoreViewComponent';

    timezoneIana: string;
    token: string;

    error: any;
    warning: any;
    success: any;
    limit = 1000;

    loadingLocations = false;
    previousLookupTimestamp;
    locationSubscription: any;
    loadingLocation: boolean;

    updatesActive = true;
    isLoaded = false;
    randomKey: number;
    driverScoreType = DriverScoreType.Drivers;

    drivers = [];
    kpis;
    chartUtilization;
    chartDistance;

    filterZeroValues = true;

    selectedResellerId;
    selectedAccountId;
    selectedDriverGroup;

    chartViolationsPerType;

    translatedKm: any = "km";
    translatedKmh: any = "km/h";
    mySubscription: any;

    resellerChanged(resellerId) {
        this.selectedAccountId = null;

        this.selectedResellerId = resellerId;
    }

    accountChanged(accountId) {
        this.selectedAccountId = accountId;
        this.selectedDriverGroup = null;

        this.error = null;
        this.warning = null;

        if (accountId) {
            this.dateChanged(true);
        }
    }

    constructor(private accountService: AccountService, private distance: DistanceUnitService, private chartService: FhChartService, private translateService: TranslateService, private authenticationService: AuthenticationService, private route: ActivatedRoute, private router: Router, protected storageHelper: StorageHelper, private colorService: ColorService) {
        super(storageHelper);

        this.sensors = [];

        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().tz(this.timezoneIana).subtract(8, 'days').startOf('day').toDate(),
            Moment().tz(this.timezoneIana).subtract(1, 'days').startOf('day').toDate()
        ];

        this.translateService.get('general.date').subscribe(
            data => {
                this.translatedKm = this.translateService.instant(this.distance.getDistanceUnit());
                this.translatedKmh = this.translateService.instant(this.distance.getDistanceUnitPerHour());
            }
        );

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);
    }

    ngOnInit() {
        this.translateService.get('general.date').subscribe(value => {
            this.initGrid();
            this.languageLoaded = true;
        });
    }

    dateChanged(event) {
        const that = this;
        console.log('Changed date');
        if (event !== null) {
            this.loadingLocations = true;

            this.datatableElement?.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.ajax.reload();
            });
        }
    }

    cancel() {
        this.mySubscription.unsubscribe();

        this.loading = false;

        this.warning = {};
        this.warning.warning = 'Call was cancelled.'
        this.warning.statusText = 'Warning';
    }

    actualRound(value, decimals) {
        return roundAsString(value, decimals);
    }

    actualRoundMinutes(value) {
        return roundMinutes(value);
    }

    actualRoundSeconds(value) {
        return roundSeconds(value);
    }

    processData(data) {
        const that = this;

        if (!data || data.length === 0) {
            return [];
        }

        if (!this.selectedAccountId) {
            return [];
        }

        if (this.filterZeroValues) {
            data = data.filter(x => x.isActive === true);
        }

        if (this.selectedDriverGroup) {
            data = data.filter(x => x.driverGroups?.some(ag => this.selectedDriverGroup === +ag.id));
        }

        // Calculate totals
        this.kpis = {
            driverCount: data.length,
            driverCountWithTrips: data.filter(x => x.isActive === true).length,
            tripCount: data.reduce((partial_sum, d) => partial_sum + d.tripCount, 0),
            totalDistance: data.reduce((partial_sum, d) => partial_sum + d.segmentsDistance, 0),
            totalDuration: data.reduce((partial_sum, d) => partial_sum + d.workingHoursDurationInSeconds, 0),
            totalIdling: data.reduce((partial_sum, d) => partial_sum + d.idlingDurationInSeconds, 0),
            score: data.reduce((partial_sum, d) => partial_sum + d.score, 0),

            idlingScoreWeighted: data.reduce((partial_sum, d) => partial_sum + d.idlingScoreWeighted, 0),
            speedScoreWeighted: data.reduce((partial_sum, d) => partial_sum + d.speedScoreWeighted, 0),
            durationSeatBeltViolationInSeconds: data.reduce((partial_sum, d) => partial_sum + d.durationSeatBeltViolationInSeconds, 0),
            corneringEventScoreWeighted: data.reduce((partial_sum, d) => partial_sum + d.corneringEventScoreWeighted, 0),
            accellerationEventScoreWeighted: data.reduce((partial_sum, d) => partial_sum + d.accellerationEventScoreWeighted, 0),
            brakingEventScoreWeighted: data.reduce((partial_sum, d) => partial_sum + d.brakingEventScoreWeighted, 0),
            rpmEventScoreWeighted: data.reduce((partial_sum, d) => partial_sum + d.rpmEventScoreWeighted, 0),
        }

        const theDataUtilization = [];
        const theContentUtilization = [];
        const categoriesUtilization = [];

        const theDataDistance = [];
        const theContentDistance = [];
        const categoriesDistance = [];

        data.sort((a, b) => (a.score < b.score ? 1 : -1));
        data.forEach(driver => {
            categoriesUtilization.push(driver.driverName);
            theContentUtilization.push({ y: roundAsNumber(driver.score, 1), driverId: driver.driverId });
        });

        data.sort((a, b) => (a.segmentsDistance < b.segmentsDistance ? 1 : -1));
        data.forEach(driver => {
            categoriesDistance.push(driver.driverName);
            theContentDistance.push({ y: roundAsNumber(driver.segmentsDistance, 0), driverId: driver.driverId });
        });

        theDataUtilization.push({
            data: theContentUtilization,
            type: 'column',
            name: 'Score',
        });

        theDataDistance.push({
            data: theContentDistance,
            type: 'column',
            name: 'Distance',
        });

        const dataViolationsPerType = [{
            name: this.translateService.instant('general.violationsPerDay'),
            colorByPoint: true,
            minPointSize: 20,
            innerSize: '20%',
            zMin: 0,
            data: [{
                name: 'Idling',
                y: roundAsNumber(this.kpis?.idlingScoreWeighted, 1),
            }, {
                name: 'Speeding',
                y: roundAsNumber(this.kpis?.speedScoreWeighted, 1),
            }, {
                name: 'Seatbelt',
                y: roundAsNumber(this.kpis?.durationSeatBeltViolationInSeconds, 1),
            }, {
                name: 'Cornering',
                y: roundAsNumber(this.kpis?.corneringEventScoreWeighted, 1),
            }, {
                name: 'Accel',
                y: roundAsNumber(this.kpis?.accellerationEventScoreWeighted, 1),
            }, {
                name: 'Braking',
                y: roundAsNumber(this.kpis?.brakingEventScoreWeighted, 1),
            }, {
                name: 'RPM',
                y: roundAsNumber(this.kpis?.rpmEventScoreWeighted, 1),
            }],
        }];

        this.chartUtilization = this.chartService.generateUtilizationScoreChart(theDataUtilization, categoriesUtilization);
        this.chartDistance = this.chartService.generateUtilizationScoreChart(theDataDistance, categoriesDistance);
        this.chartViolationsPerType = this.chartService.generateVarPieChartDevice(dataViolationsPerType, {});

        return data;
    }

    initGrid(): void {
        console.log('Init grid');

        const that = this;

        const commonExportOptions = {
            modifier: {
                page: 'all',
                search: 'none'
            },
            columns: ['id_export:name', ':visible[tabindex]']
        };

        this.columns = [
            {
                name: 'accountId',
                data: 'accountId',
                visible: false,
                defaultContent: '-',
                title: this.translateService.instant('general.accountId')
            },
            {
                name: 'driverName',
                data: 'driverName',
                defaultContent: '-',
                title: this.translateService.instant('general.driver'),
                render: function (data, type, row) {
                    return '<a class=\'secondary link_bolderd\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/DriverDetails/Score/' + row.driverId + '\'>' + data + '</a>';
                }
            },
            {
                name: 'vehicleType',
                data: 'vehicleType',
                visible: true,
                defaultContent: '-',
                title: this.translateService.instant('general.vehicleType'),
                render: function (data, type, row) {
                    return that.translateService.instant(('enums.vehicleType.' + data));
                }
            },
            {
                name: 'rank',
                data: 'rank',
                title: this.translateService.instant('general.rank')
            },
            {
                name: 'isActive',
                data: 'isActive',
                type: 'checkBox',
                defaultContent: '',
                title: this.translateService.instant('general.active'),
                visible: true,
                createdCell: createdCellCheckbox,
                width: '40',
            }, {
                name: 'assetCount',
                data: 'assetCount',
                visible: false,
                title: this.translateService.instant('general.assetCount')
            }, {
                name: 'assignmentCount',
                data: 'assignmentCount',
                visible: false,
                title: this.translateService.instant('general.assignmentCount')
            }, {
                name: 'score',
                data: 'score',
                title: this.translateService.instant('general.score'),
                render: function (data, type, row) {
                    if (type && type === 'display') {

                        let scoreDescription = 'Excellent';
                        let scoreColor = 'green';

                        if (data < 2) {
                            scoreDescription = 'Excellent';
                            scoreColor = 'green';
                        } else if (data < 5) {
                            scoreDescription = 'Good';
                            scoreColor = 'greenyellow';
                        } else if (data < 10) {
                            scoreDescription = 'Not bad';
                            scoreColor = 'orange';
                        } else if (data < 15) {
                            scoreDescription = 'Bad';
                            scoreColor = 'orangered';
                        } else {
                            scoreDescription = 'Very Bad';
                            scoreColor = 'red';
                        }

                        return (data !== undefined && row.isActive) ? '<span style="font-weight: bold; color: ' + scoreColor + '" title="' + scoreDescription + '"><i class="fa fa-solid fa-circle"></i></span> ' + roundAsString(data, 1) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'segmentsDistance',
                data: 'segmentsDistance',
                type: 'num',
                title: this.translateService.instant('general.segmentsDistance'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundAsString(data, 0) + ' ' + that.translateService.instant(that.distance.getDistanceUnit()) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'totalDurationInSeconds',
                data: 'totalDurationInSeconds',
                visible: false,
                title: this.translateService.instant('general.totalDuration'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundSeconds(data) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'workingHoursDurationInSeconds',
                data: 'workingHoursDurationInSeconds',
                title: this.translateService.instant('general.workingHours'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundSeconds(data) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'pureDrivingDurationInSeconds',
                data: 'pureDrivingDurationInSeconds',
                title: this.translateService.instant('general.pureDriving'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundSeconds(data) : '0';
                    } else {
                        return data
                    };
                },
            },
            {
                name: 'idlingDurationInSeconds',
                data: 'idlingDurationInSeconds',
                title: this.translateService.instant('general.idlingDuration'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundSeconds(data) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'accelCount',
                data: 'accelCount',
                defaultContent: '0',
                title: this.translateService.instant('general.accelCount'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? data.toLocaleString() : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'corneringCount',
                data: 'corneringCount',
                defaultContent: '0',
                title: this.translateService.instant('general.corneringCount'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? data.toLocaleString() : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'decelCount',
                data: 'decelCount',
                defaultContent: '0',
                title: this.translateService.instant('general.decelCount'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? data.toLocaleString() : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'rpmCount',
                data: 'rpmCount',
                defaultContent: '0',
                title: this.translateService.instant('general.rpmCount'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? data.toLocaleString() : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'speedingDurationInSeconds',
                data: 'speedingDurationInSeconds',
                defaultContent: '0',
                title: this.translateService.instant('general.speedingDurationInSeconds'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundSeconds(data) : '-';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'durationSeatBeltViolationInSeconds',
                data: 'durationSeatBeltViolationInSeconds',
                defaultContent: '0',
                title: this.translateService.instant('general.durationSeatBeltViolationInSeconds'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundSeconds(data) : '-';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'brakingEventScore',
                data: 'brakingEventScore',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.brakingEventScore')
            }, {
                name: 'accellerationEventScore',
                data: 'accellerationEventScore',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.accellerationEventScore')
            }, {
                name: 'corneringEventScore',
                data: 'corneringEventScore',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.corneringEventScore')
            }, {
                name: 'rpmEventScore',
                data: 'rpmEventScore',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.rpmEventScore')
            }, {
                name: 'idlingScore',
                data: 'idlingScore',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.idlingScore'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundAsString(data, 1) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'seatBeltScore',
                data: 'seatBeltScore',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.seatBeltScore'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundAsString(data, 1) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'speedScore',
                data: 'speedScore',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.speedScore'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundAsString(data, 1) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'maxSpeed',
                data: 'maxSpeed',
                defaultContent: '-',
                title: this.translateService.instant('general.maxSpeed'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? (roundAsNumber(data, 0)).toLocaleString() + ' ' + that.translateService.instant(that.distance.getDistanceUnitPerHour()) : '-';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'accellerationEventScoreWeighted',
                data: 'accellerationEventScoreWeighted',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.accellerationEventScoreWeighted'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundAsString(data, 1) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'brakingEventScoreWeighted',
                data: 'brakingEventScoreWeighted',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.brakingEventScoreWeighted'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundAsString(data, 1) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'corneringEventScoreWeighted',
                data: 'corneringEventScoreWeighted',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.corneringEventScoreWeighted'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundAsString(data, 1) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'rpmEventScoreWeighted',
                data: 'rpmEventScoreWeighted',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.rpmEventScoreWeighted'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundAsString(data, 1) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'idlingScoreWeighted',
                data: 'idlingScoreWeighted',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.idlingScoreWeighted'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundAsString(data, 1) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'seatBeltScoreWeighted',
                data: 'seatBeltScoreWeighted',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.seatBeltScoreWeighted'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundAsString(data, 1) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'speedScoreWeighted',
                data: 'speedScoreWeighted',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.speedScoreWeighted'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundAsString(data, 1) : '0';
                    } else {
                        return data
                    };
                },
            }, {
                name: 'calculatorValue',
                data: 'calculatorValue',
                defaultContent: '0',
                visible: false,
                title: this.translateService.instant('general.calculatorValue'),
                render: function (data, type, row) {
                    if (type && type === 'display') {
                        return data ? roundAsString(data, 3) : '0';
                    } else {
                        return data
                    };
                },
            },
        ];

        this.dtOptions = {
            buttons: getGridButtons(this.commonExportOptions, 'driverscore_overview', this.translateService.instant('menu.messagesoverview'), this.colorService),
            pagingType: 'simple_numbers',
            serverSide: false,
            processing: false,
            scrollX: true,
            colReorder: { fixedColumnsLeft: 1 },
            order: [[1, 'asc']],
            stateSave: false,
            ajax: (dataTablesParameters: any, callback) => {
                $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));

                this.loading = true;
                this.mySubscription = this.accountService.getAccountDriverScore(this.selectedAccountId, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(resp => {
                    this.drivers = resp ? this.processData(resp) : [];
                    this.loading = false;

                    callback({
                        recordsTotal: this.drivers?.length,
                        recordsFiltered: this.drivers?.length,
                        data: this.drivers
                    });
                }, error => {
                    this.success = null;
                    this.error = error;
                    this.error.statusText = 'Error fetching data';
                    this.loading = false;
                });
            },
            initComplete: function (settings, json) {
                that.loading = false;
                that.loadingLocations = false;

                console.log('init complete');
                that.checkFilters();
                that.drawFilterRow();
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
            },
            columns: this.columns,
            pageLength: 25,
            lengthMenu: [[10, 17, 25, 50, 200, -1], [10, 17, 25, 50, 200, this.translateService.instant('general.all')]],
            language: getGridLanguages(this.translateService),
            rowCallback: (row, data) => { }
        };
    }
}


